/**
 * Created by Nico on 3/05/2018.
 */
import _ from 'lodash';
import PromiseActionType from '../../promiseActionType';

export const GET_ALL_VIM_GUIDES_PROFILES = new PromiseActionType('GET_ALL_VIM_GUIDES_PROFILES');
export const GET_VIM_GUIDE_BY_ID = new PromiseActionType('GET_VIM_GUIDE_BY_ID');
export const CREATE_VIM_GUIDE_PROFILE = new PromiseActionType('CREATE_VIM_GUIDE_PROFILE');
export const UPDATE_VIM_GUIDE_PROFILE = new PromiseActionType('UPDATE_VIM_GUIDE_PROFILE');

export function getAllVimGuideProfiles(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { vimGuideProfileApi } }) => ({
    type: GET_ALL_VIM_GUIDES_PROFILES.SOURCE,
    payload: {
      promise: vimGuideProfileApi.getAllVimGuideProfiles(skip, limit, search, sort, sortAscending)
    }
  });
}

export function getVimGuideById(id) {
  return ({ bkmdApi: { vimGuideProfileApi } }) => ({
    type: GET_VIM_GUIDE_BY_ID.SOURCE,
    payload: {
      promise: vimGuideProfileApi.getById(id)
    },
    meta: {
      tracker: GET_VIM_GUIDE_BY_ID.SOURCE,
    },
  });
}

export function createVimGuideProfile(profile, domains) {
  return ({ bkmdApi: { vimGuideProfileApi }, dispatch }) => ({
    type: CREATE_VIM_GUIDE_PROFILE.SOURCE,
    payload: {
      promise: vimGuideProfileApi.createProfile(profile, _.castArray(domains))
        .then(() => {
          dispatch(getAllVimGuideProfiles());
        }),
    },
    meta: {
      tracker: CREATE_VIM_GUIDE_PROFILE.SOURCE,
    },
  });
}

export function updateVimGuideProfile(profile) {
  return ({ bkmdApi: { vimGuideProfileApi }, dispatch }) => ({
    type: UPDATE_VIM_GUIDE_PROFILE.SOURCE,
    payload: {
      promise: vimGuideProfileApi.updateProfile(profile)
        .then(() => {
          dispatch(getVimGuideById(profile.id));
        }),
    },
    meta: {
      tracker: UPDATE_VIM_GUIDE_PROFILE.SOURCE,
    },
  });
}
