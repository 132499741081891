import React from 'react';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import { BkmdGridColumn, QueryStringGrid } from '../../../../../components/grid';
import { internalApi } from '../../internal-api';

function PatientList({ patientsQueryResult, onPatientClick, getPatients }) {
  return (
    <div>
      <QueryStringGrid
        data={patientsQueryResult.results}
        totalCount={patientsQueryResult.count}
        onQueryChange={(skip, limit, search, sort, sortAscending) => {
          getPatients({ take: limit, skip, search, sort, sortAscending });
        }}
        onRowClick={onPatientClick}
        allowSearch
        searchLabel="Search by: Patient Id / First Name / Last Name / Member Id"
      >
        <BkmdGridColumn id="id" title="Id" />
        <BkmdGridColumn id="memberId" title="Member ID" />
        <BkmdGridColumn id="firstName" title="First Name" />
        <BkmdGridColumn id="lastName" title="Last Name" />
      </QueryStringGrid>
    </div>
  );
}

PatientList.propTypes = {
  patientsQueryResult: PropTypes.object.isRequired,
  onPatientClick: PropTypes.func.isRequired,
  getPatients: PropTypes.func.isRequired,
};

export default compose(
  queryConnect(gridQueryParams, { limit: 10, skip: 0, sort: 'createdAt', sortAscending: 'false' }),
  withStateFetchersOnMount({
    getPatients: {
      handler: ({ params }) => (input = {}) =>
        internalApi.namespace(params.namespaceName).patients.getAllPatients(input),
      resultPropName: 'patientsQueryResult',
      isReady: ({ patientsQueryResult, getPatientsTracker }) =>
        !isEmpty(patientsQueryResult) && !getPatientsTracker.inProgress,
    },
  }),
  withRouter,
  withHandlers({
    onPatientClick: ({ router, params }) => patient => {
      router.push(`/secure/namespaceManager/${params.namespaceName}/patients/${patient.id}`);
    },
  }),
)(PatientList);
