/**
 * Created by chenrozenes on 09/04/2017.
 */
import Promise from 'bluebird';
import PromiseActionType from '../../promiseActionType';

export const GET_MEMBER_BY_ID = new PromiseActionType('GET_USER_BY_ID');
export const GET_ALL_MEMBERS = new PromiseActionType('GET_ALL_MEMBERS');
export const IMPORT_MEMBER_TO_DOMAIN = new PromiseActionType('IMPORT_MEMBER_TO_DOMAIN');
export const GET_ALL_RAW_MEMBERS = new PromiseActionType('GET_ALL_RAW_MEMBERS');
export const MATCH_MEMBER_NAME = new PromiseActionType('MATCH_MEMBER_NAME');
export const INVITE_MEMBER = new PromiseActionType('INVITE_MEMBER');
export const DEACTIVATE_MEMBER = new PromiseActionType('DEACTIVATE_MEMBER');
export const GET_FULL_MEMBER = new PromiseActionType('GET_FULL_MEMBER');
export const ELIGIBILITY_REQUEST = new PromiseActionType('ELIGIBILITY_REQUEST');
export const SEND_BULK_ELIGIBILITY_REQUEST = new PromiseActionType('SEND_BULK_ELIGIBILITY_REQUEST');
export const LOAD_DUMMY_MEMBERS = new PromiseActionType('LOAD_DUMMY_MEMBERS');
export const ATTACH_PAST_ACTIVITY = new PromiseActionType('ATTACH_PAST_ACTIVITY');
export const CLEAN_PAST_ACTIVITY_STATE = new PromiseActionType('CLEAN_PAST_ACTIVITY_STATE');

export function getAllMembers(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_ALL_MEMBERS.SOURCE,
    payload: {
      promise: membersApi
        .getAllUsers(skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export function getMatchingMembersByName(name) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: MATCH_MEMBER_NAME.SOURCE,
    meta: {
      tracker: MATCH_MEMBER_NAME.SOURCE,
    },
    payload: {
      promise: membersApi.getMatchingMembersByName(name).then(res => res.data),
    },
  });
}

export function getMember(id) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_MEMBER_BY_ID.SOURCE,
    payload: {
      promise: membersApi.getUser(id).then(res => res.data),
    },
    meta: {
      tracker: GET_MEMBER_BY_ID.SOURCE,
    },
  });
}

export function importMemberToDomain(fromDomain, fromDomainMemberId, toDomain, toDomainMemberId) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: IMPORT_MEMBER_TO_DOMAIN.SOURCE,
    meta: {
      tracker: IMPORT_MEMBER_TO_DOMAIN.SOURCE,
    },
    payload: {
      promise: membersApi
        .importMemberToDomain(fromDomain, fromDomainMemberId, toDomain, toDomainMemberId)
        .then(res => res.data),
    },
  });
}

export function attachPastActivityToMember(
  currentDomainMemberId,
  newDomainMemberId,
  validateMembers = true,
) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: ATTACH_PAST_ACTIVITY.SOURCE,
    meta: {
      tracker: ATTACH_PAST_ACTIVITY.SOURCE,
    },
    payload: {
      promise: membersApi
        .attachPastActivity(
          currentDomainMemberId,
          newDomainMemberId,
          validateMembers,
        )
        .then(res => res.data),
    },
  });
}

export function cleanPastActivityState() {
  return () => ({
    type: CLEAN_PAST_ACTIVITY_STATE.SOURCE,
    payload: {
      promise: Promise.resolve(),
    },
  });
}


export function getAllRawMembers(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_ALL_RAW_MEMBERS.SOURCE,
    payload: {
      promise: membersApi
          .getAllDomainMembers(skip, limit, search, sort, sortAscending)
          .then(res => res.data),
    },
  });
}

export function getFullMember(domainMemberId) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_FULL_MEMBER.SOURCE,
    meta: {
      tracker: GET_FULL_MEMBER.SOURCE,
    },
    payload: {
      promise: membersApi.getFullMemberForBackpack(domainMemberId),
    },
  });
}

export function deactivateMember(domainMemberId) {
  return ({ bkmdApi: { membersApi }, dispatch }) => ({
    type: DEACTIVATE_MEMBER.SOURCE,
    meta: {
      tracker: DEACTIVATE_MEMBER.SOURCE,
    },
    payload: {
      promise: membersApi
        .deactivateMember(domainMemberId)
        .tap(() => dispatch(getFullMember(domainMemberId))),
    },
  });
}
