/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, withHandlers, withState } from 'recompose';
import _ from 'lodash';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn } from '../../../../components/grid';
import { withFetchersOnMount } from '../../../../api/injectApi/withFetchersOnMount';
import injectNotification from '../../../../store/notification/injectNotification';
import QueryStringGrid, { gridQueryParams } from '../../../../components/grid/queryStringGrid';
import CreateGroupAction from './actions/createGroup/index';
import Apis from '../../../../api';

import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';

function UseGroupList(props) {
  return _.isNil(props.userGroups) ? (
    <ThreeBounceSpinner />
  ) : (
    <div>
      <div className="action-group">
        <CreateGroupAction onSuccess={props.onActionSucceed} />
      </div>
      <QueryStringGrid
        data={props.userGroups}
        totalCount={props.groupsCount}
        onQueryChange={props.getGroups}
        onRowClick={props.onGroupClick}
        allowSearch
        defaultLimit={10}
      >
        <BkmdGridColumn id="id" title="Id" />
        <BkmdGridColumn id="name" title="Name" />
        <BkmdGridColumn id="owningResourceType" title="Owner Type" />
        <BkmdGridColumn id="owningResourceId" title="Owner Id" />
      </QueryStringGrid>
    </div>
  );
}

UseGroupList.propTypes = {
  userGroups: PropTypes.array,
  groupsCount: PropTypes.number,
  onActionSucceed: PropTypes.func,
  getGroups: PropTypes.func.isRequired,
  onGroupClick: PropTypes.func.isRequired,
};

UseGroupList.defaultProps = {
  userGroups: null,
  groupsCount: null,
  onActionSucceed: _.noop,
};

export default compose(
  withRouter,
  injectNotification,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withState('userGroups', 'setUserGroups'),
  withState('groupsCount', 'setGroupsCount'),
  withFetchersOnMount({
    getGroups: {
      handler: ({ params }) => (skip, limit, search, sort, sortAscending) => {
        const query = _.assign({}, { skip, limit, search, sort, sortAscending }, params);
        return Apis.usersApi
          .getAllowedGroups(query.skip, query.limit, query.search, query.sort, query.sortAscending)
          .get('data');
      },
      onSuccess: ({ setUserGroups, setGroupsCount }, result) => {
        setUserGroups(result.data);
        setGroupsCount(result.count);
      },
      onError: ({ notification, setUserGroups }) => {
        setUserGroups([]);
        notification.error(
          'Failed get groups',
          'Could fetch users allowed groups duo to unexpected error',
        );
      },
    },
  }),
  withHandlers({
    onGroupClick: ({ router }) => rowData => router.push(`/secure/userGroups/${rowData.id}`),
    onActionSucceed: ({ getGroups }) => () => getGroups(),
  }),
)(UseGroupList);
