import PromiseActionType from '../../promiseActionType';

export const GET_ALL_ASO_GROUPS = new PromiseActionType('GET_ALL_ASO_GROUPS');

export function getAllAsoGroups() {
  return ({ bkmdApi: { asoGroup } }) => ({
    type: GET_ALL_ASO_GROUPS.SOURCE,
    payload: {
      promise: asoGroup.getAllAsoGroups(0, 100, undefined, undefined, false)
    }
  });
}
