/**
 * Created by chenrozenes on 08/01/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import AclGuard from '../../../../../store/auth/aclGuard';
import * as selectors from '../../../../../store/backoffice/clinicManagement/clinics/selectors';
import { listClinicNotification } from '../../../../../store/backoffice/clinicManagement/clinics/actions';
import { WRITE } from '../../../../../model/enum/permissionLevel';
import { BACK_OFFICE_CLINICS } from '../../../../../model/enum/aclResourceType';
import AddSubscriberAction from './actions/addSubscriber';
import RemoveSubscriberAction from './actions/removeSubscriber';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

@autobind
class ClinicNotificationList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    listClinicNotification: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    clinicNotifications: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.props.listClinicNotification(this.props.params.id);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.listClinicNotification(this.props.params.id, {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  render() {
    const { clinicNotifications, count, params } = this.props;
    return (
      <div>
        <AclGuard type={BACK_OFFICE_CLINICS} permission={WRITE}>
          <div className="action-group">
            <AddSubscriberAction clinicId={params.id} />
            <RemoveSubscriberAction clinicId={params.id} />
          </div>
        </AclGuard>
        <hr />
        <QueryStringGrid
          data={clinicNotifications}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="userId" title="User Id" />
          <BkmdGridColumn id="npi" title="NPI" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      clinicNotifications: selectors.getClinicNotificationData(state),
      count: selectors.getClinicNotificationCount(state),
    }),
    {
      listClinicNotification,
    },
  ),
)(ClinicNotificationList);
