import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import { getClinicMapping } from '../../../../../store/backoffice/clinicManagement/emr/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/emr/selectors';

import ImportMappingAction from './importMappingAction';
import CleanJSONInput from '../../../../../components/form/cleanJSONInput';

@autobind
class EMRMapping extends React.PureComponent {
  static propTypes = {
    mapping: PropTypes.object.isRequired,
    getClinicMapping: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      getClinicMapping,
      params: { id },
    } = this.props;
    getClinicMapping(id);
  }

  render() {
    const {
      mapping,
      params: { id },
    } = this.props;
    return (
      <div>
        <div className="action-group">
          <ImportMappingAction onActionSuccess={this.onActionsSuccess} clinicId={id} />
        </div>
        <br />
        {mapping.length}
        <CleanJSONInput value={mapping} readOnly spacePadding />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      mapping: selectors.getClinicMapping(state),
    }),
    {
      getClinicMapping,
    },
  ),
)(EMRMapping);
