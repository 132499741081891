import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CSVReader from 'react-csv-reader';
import { compose, withStateHandlers } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import injectNotification from '../../store/notification/injectNotification';
import ThreeBounceSpinner from '../../components/ui/spinner/threeBounceSpinner';
import './loadClinicFromCSV.less';
import CleanSelect from '../../components/form/cleanSelect';
import CleanCheckbox from '../../components/form/cleanCheckbox';
import { csvTypes, newClinicsCsvName } from './csvTypes/csvTypes';
import { withStateFetchers } from '../../api/injectApi/withStateFetchers';
import BookMdUtil from '../../utils/util';
import { ClinicSource } from '../../model/enum/clinicSource';

const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'Error: {error}',
    id: 'clinic.loadClinicFromCSV.errorMessage',
  },
  uploadCSV: {
    defaultMessage: 'Upload CSV',
    id: 'clinic.loadClinicFromCSV.uploadCSV',
  },
  errorCreateClinicTitle: {
    defaultMessage: 'Clinic creation failed',
    id: 'clinic.loadClinicFromCSV.errorCreateClinicTitle',
  },
  errorCreateClinicMessage: {
    defaultMessage: 'Error while trying to create a clinic, please contact VIM',
    id: 'clinic.loadClinicFromCSV.errorCreateClinicMessage',
  },
  chooseFile: {
    defaultMessage: 'Choose file',
    id: 'clinic.loadClinicFromCSV.chooseFile',
  },
  shouldSendLegalDocuments: {
    defaultMessage:
      "isLegalSignatureRequired - Check the box if you're loading clinics that need to sign Terms of Service (i.e. fax campaign)",
    id: 'clinic.loadClinicFromCSV.shouldSendLegalDocuments',
  },
  fullStateWarning: {
    defaultMessage:
      'This action expects full state and will delete entities that do not exist in the file.',
    id: 'clinic.loadClinicFromCSV.fullStateWarning',
  },
  beCautious: {
    defaultMessage: 'Be cautious!',
    id: 'clinic.loadClinicFromCSV.beCautious',
  },
});

export function getOptions() {
  return _.map(csvTypes.csvTypes, ({ name, displayName }) => ({
    value: name,
    label: displayName,
  }));
}

const AddCSVProviderResources = ({
  onFileLoaded,
  chooseType,
  error,
  typeValue,
  createClinicTracker,
  createClinic,
  shouldSendLegalDocuments,
  onShouldSendLegalDocumentsChange,
  clinicSource,
  onClinicSourceChange,
}) => (
  <div>
    {createClinicTracker.inProgress ? (
      <ThreeBounceSpinner />
    ) : (
      <div>
        <div className="title-24 font-color-brand-main md-margin text-strong text-center">
          Select CSV file type
        </div>
        <div>
          <div>Select CSV type</div>
          <CleanSelect
            options={getOptions()}
            value={typeValue}
            inputClassName="text-left"
            onChange={chooseType}
            name="type"
            track
          />
        </div>
        {typeValue && typeValue !== newClinicsCsvName && (
          <div className="text-16 text-strong font-color-brand-accent margin-bottom-30">
            <FormattedMessage {...messages.fullStateWarning} />
            <br />
            <FormattedMessage {...messages.beCautious} />
          </div>
        )}
        <div>
          <label htmlFor="clinic-csv" className="btn btn-small btn-outline load-btn">
            <i className="fa fa-upload" />
            &nbsp;
            <FormattedMessage {...messages.chooseFile} />
          </label>
        </div>
        <CSVReader
          cssClass="csv-input-uploader"
          cssInputClass="btn btn-small btn-light-blue"
          onFileLoaded={onFileLoaded}
          inputId="clinic-csv"
          parserOptions={{ skipEmptyLines: true }}
        />
        <div className="margin-top-20">
          <CleanCheckbox
            name="shouldSendLegalDocuments"
            className="left-checkbox-padding margin-top-10"
            id="should-send-legal-documents"
            value={shouldSendLegalDocuments}
            label={messages.shouldSendLegalDocuments}
            onChange={onShouldSendLegalDocumentsChange}
          />
          <div className="margin-top-10">Select marketing source (if relevant)</div>
          <div className="text-11">
            * FAX_CAMPAIGN - stands for a single provider clinic, that was onboarded via fax
            campaign
          </div>
          <div className="text-11">
            * FAX_CAMPAIGN_MULTIPLE_PROVIDERS - stands for a clinic with more than one provider,
            that was onboarded via fax campaign
          </div>
          <CleanSelect
            options={BookMdUtil.toSelectOptions(ClinicSource)}
            value={clinicSource}
            inputClassName="text-left"
            onChange={onClinicSourceChange}
            name="type"
            track
          />
        </div>
        {error && <FormattedMessage {...messages.errorMessage} values={{ error }} />}
        <button onClick={createClinic} className="btn btn-big btn-blue margin-top-20">
          <i className="icon-plus-fat i-va-fix-2" />
          &nbsp;
          <FormattedMessage {...messages.uploadCSV} />
        </button>
      </div>
    )}
  </div>
);

AddCSVProviderResources.propTypes = {
  onFileLoaded: PropTypes.func.isRequired,
  createClinic: PropTypes.func.isRequired,
  createClinicTracker: PropTypes.object.isRequired,
  error: PropTypes.string,
  typeValue: PropTypes.string,
  chooseType: PropTypes.func.isRequired,
  shouldSendLegalDocuments: PropTypes.bool.isRequired,
  onShouldSendLegalDocumentsChange: PropTypes.func.isRequired,
  clinicSource: PropTypes.string,
  onClinicSourceChange: PropTypes.func.isRequired,
};

AddCSVProviderResources.defaultProps = {
  error: '',
  clinic: undefined,
  shouldSendLegalDocuments: false,
};

export default compose(
  injectNotification,
  withStateHandlers(
    ({ medicalCodes }) => ({
      error: null,
      clinic: null,
      clinics: null,
      typeValue: null,
      clinicSource: null,
      validProviders: null,
      invalidProviders: null,
      medicalCodes,
    }),
    {
      chooseType: () => e => ({
        typeValue: e.value,
      }),
      onShouldSendLegalDocumentsChange: ({ shouldSendLegalDocuments }) => () => ({
        shouldSendLegalDocuments: !shouldSendLegalDocuments,
      }),
      onClinicSourceChange: () => e => ({
        clinicSource: e.value,
      }),
      onFileLoaded: ({ typeValue, medicalCodes }) => rows =>
        csvTypes.getType(typeValue).onFileLoaded(rows, medicalCodes),
    },
  ),
  withStateFetchers({
    createClinic: {
      handler: ({
        clinic,
        typeValue,
        clinics,
        clinicUniqueId,
        clinicIdArray,
        errorColumnsOrder,
        errorUniqueClinicId,
        errors,
        validProviders,
        shouldSendLegalDocuments,
        clinicSource,
      }) => () =>
        csvTypes.getType(typeValue).handler({
          clinic,
          clinics,
          clinicUniqueId,
          clinicIdArray,
          errorColumnsOrder,
          errorUniqueClinicId,
          errors,
          validProviders,
          shouldSendLegalDocuments,
          clinicSource,
        }),
      onSuccess: (
        {
          control,
          validProviders,
          invalidProviders,
          typeValue,
          clinicUniqueId,
          clinicIdArray,
          errors,
          clinics,
          errorColumnsOrder,
          errorUniqueClinicId,
          shouldSendLegalDocuments,
          clinicSource,
        },
        result,
      ) =>
        control.next({
          payload: {
            clinicUniqueId,
            clinicIdArray,
            errors,
            validProviders,
            invalidProviders,
            typeValue,
            clinics,
            errorColumnsOrder,
            errorUniqueClinicId,
            shouldSendLegalDocuments,
            clinicSource,
            loadedClinicsProviders: result,
          },
        }),
      onError: ({ notification }) =>
        notification.error(messages.errorCreateClinicTitle, messages.errorCreateClinicMessage),
    },
  }),
)(AddCSVProviderResources);
