/**
 * Created by chenrozenes on 18/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import { IntlString, MessageShape } from '../../../../../components/ui/intlString';
import FieldGenerator, { fieldsDefShape } from './fieldGenerator';
import validate from './schemaValidation';
import { hasWritePermissionSelector } from '../../../../../store/auth/selectors';

/**
 * Form component that generates itself by the "entity" prop provided using redux-form
 * Default schema can be overridden by the "schemaDef" prop
 * Default field definition can be overridden by the "fieldsDef" prop
 */
@autobind
class EntityForm extends React.Component {
  static propTypes = {
    /**
     * Name of the form. mandatory for redux-form to work
     */
    name: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    /**
     * Title in the top of the form
     */
    title: MessageShape,
    /**
     * Text to display in the submit buttons
     */
    submitText: MessageShape,
    /**
     * Text to display in the reset buttons
     */
    resetText: MessageShape,
    /**
     * The entity to generate the form by
     */
    entity: PropTypes.object,
    /**
     * Redux form field specification to override the default simple string input
     */
    fieldsDef: fieldsDefShape,
    /**
     * Object with schema field, to override the default string schema validation
     */
    schemaDef: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    /**
     * Optional validate function which override validate function in reduxForm,
     * can be used instead of schemaDef for custom validation
     */
    validate: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    /**
     * onSubmit function
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * reset function (from redux-form)
     */
    reset: PropTypes.func.isRequired,
    /**
     * When true, no submit and reset buttons will be displayed, and the form fields
     * by default will be readOnly
     */
    readOnly: PropTypes.bool,
    /**
     * Pass true when currently submitting the form (so the spinner button will spin)
     */
    isSubmitting: PropTypes.bool,
    /**
     * will set readonly according to the WRITE permission for the given acl resouece
     */
    readOnlyByAcl: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    /**
     * Pass false if buttons (update, reset) should not be shown no matter what
     */
    showButtons: PropTypes.bool,
    /**
     * Customize the form class using this prop
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    fieldsDef: {},
    schemaDef: {},
    entity: {},
    title: '',
    submitText: 'Update',
    resetText: 'Reset',
    readOnly: false,
    isSubmitting: false,
    readOnlyByAcl: null,
    showButtons: true,
    className: 'basic-entity-form',
    newPasswordValidationFeature: false,
    validate,
  };

  resetForm(e) {
    e.preventDefault();
    this.props.reset();
  }

  render() {
    const {
      handleSubmit,
      readOnly,
      title,
      entity,
      resetText,
      submitText,
      fieldsDef,
      isSubmitting,
      showButtons,
      className,
      newPasswordValidationFeature,
      verifyPasswordField,
      onStrengthChanged,
      change,
    } = this.props;
    return (
      entity && (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="card-body padding-box-30">
                <form onSubmit={handleSubmit} className={className} method="post">
                  <div className="row margin-bottom-clean-form">
                    <div className="col-xs-6">
                      <h2>
                        <IntlString message={title} />
                      </h2>
                    </div>
                    {readOnly || !showButtons ? (
                      <div className="col-xs-6" />
                    ) : (
                      <div className="col-xs-6">
                        <SpinnerButton
                          className="btn btn-small btn-green pull-right"
                          type="submit"
                          isLoading={isSubmitting}
                        >
                          <IntlString message={submitText} />
                        </SpinnerButton>
                        <button
                          className="btn btn-small btn-grey pull-right margin-right-20"
                          onClick={this.resetForm}
                        >
                          <IntlString message={resetText} />
                        </button>
                      </div>
                    )}
                  </div>
                  <FieldGenerator
                    entity={entity}
                    fieldsDef={fieldsDef}
                    readOnly={readOnly}
                    newPasswordValidationFeature={newPasswordValidationFeature}
                    verifyPasswordField={verifyPasswordField}
                    onStrengthChanged={value => onStrengthChanged(change, value)}
                  />
                  {readOnly || !showButtons ? null : (
                    <div className="row text-center margin-top margin-bottom-clean-form">
                      <div className="col-xs-6">
                        <button className="btn btn-big btn-grey pull-left" onClick={this.resetForm}>
                          <IntlString message={resetText} />
                        </button>
                      </div>
                      <div className="col-xs-6">
                        <SpinnerButton
                          isLoading={isSubmitting}
                          className="btn btn-big pull-right"
                          type="submit"
                        >
                          <IntlString message={submitText} />
                        </SpinnerButton>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default compose(
  connect((state, props) => ({
    initialValues: props.entity.asMutable ? props.entity.asMutable({ deep: true }) : props.entity,
    form: props.name,
    readOnly: props.readOnlyByAcl
      ? !hasWritePermissionSelector(state, props.readOnlyByAcl)
      : props.readOnly,
    validate: props.validate || validate,
  })),
  reduxForm({
    enableReinitialize: true,
  }),
)(EntityForm);
