/**
 * Created by chenrozenes on 06/06/2017.
 */

export const USER = 'USER';
export const PROVIDER = 'PROVIDER';
export const UNVERIFIED_USER = 'UNVERIFIED_USER';
export const INLINE = 'INLINE';
export const DOMAIN_MEMBER = 'DOMAIN_MEMBER';
export const NS_PATIENT = 'NS_PATIENT';
