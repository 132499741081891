/**
 * created by moran on 10/31/17
 */
import { combineReducers } from 'redux';
import genericReducer from '../../genericReducer';
import { GET_ALL_PROVIDER_PROFILES, GET_PROVIDER_BY_ID } from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  details: {},
  providerLocations: [],
  raw: {
    details: {},
  },
  taxonomies: [],
};

export default combineReducers({
  list: genericReducer(
    GET_ALL_PROVIDER_PROFILES,
    initialState.list,
    null,
    { keepStateOnStart: true }
  ),
  details: genericReducer(
    GET_PROVIDER_BY_ID,
    initialState.details,
    null,
    { keepStateOnStart: true }
  ),
});
