/**
 * created by moran on 11/28/17
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { BkmdGridColumn } from '../../../../components/grid';
import CreateMedicalCode from './actions/createMedicalCode';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';

import { listMedicalCodes } from '../../../../store/backoffice/medicalCodes/actions';
import * as selectors from '../../../../store/backoffice/medicalCodes/selectors';

@autobind
class MedicalCodeList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    listMedicalCodes: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    medicalCodes: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.getMedicalCodesByProps();
  }

  onClick(rowData) {
    this.props.router.push(`/secure/medicalCodes/${rowData.type}/${rowData.key}`);
  }

  getMedicalCodesByProps() {
    this.getMedicalCodes(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  getMedicalCodes(skip, limit, search, sort, sortAscending) {
    this.props.listMedicalCodes(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { medicalCodes, count } = this.props;
    return (
      <div>
        <div className="action-group">
          <CreateMedicalCode onSuccess={this.getMedicalCodesByProps} />
        </div>
        <QueryStringGrid
          data={medicalCodes}
          totalCount={count}
          onRowClick={this.onClick}
          onQueryChange={this.getMedicalCodes}
          allowSearch
        >
          <BkmdGridColumn id="key" title="key" />
          <BkmdGridColumn id="type" title="type" />
          <BkmdGridColumn id="x12ServiceType" title="x12 Service Type" />
          <BkmdGridColumn id="commonName" title="Common Name" />
          <BkmdGridColumn id="medicalName" title="Medical Name" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      medicalCodes: selectors.getMedicalCodeList(state),
      count: selectors.getMedicalCodeListCount(state),
    }),
    { listMedicalCodes },
  ),
)(MedicalCodeList);
