/**
 * Created by chenrozenes on 09/04/2017.
 */
import { combineReducers } from 'redux';
import providers from './providers/reducer';
import users from './users/reducer';
import communication from './communication/reducer';
import clinics from './clinicManagement/clinics/reducer';
import emr from './clinicManagement/emr/reducer';
import providerResource from './clinicManagement/providerResources/reducer';
import machine from './clinicManagement/machines/reducer';
import calendar from './clinicManagement/calendar/reducer';
import appointment from './clinicManagement/appointment/reducer';
import appointmentRequest from './appointmentRequest/reducer';
import appointmentTypes from './clinicManagement/appointmentTypes/reducer';
import clinicPermissions from './clinicManagement/permissions/reducer';
import members from './members/reducer';
import providerProfile from './providerProfile/reducer';
import vimGuideProfile from './vimGuideProfile/reducer';
import reviews from './reviews/reducer';
import medicalCodes from './medicalCodes/reducer';
import features from './features/reducer';
import sdk from './sdk/reducer';
import asoGroups from './asoGroups/reducer';

export default combineReducers({
  providers,
  users,
  communication,
  reviews,
  members,
  clinics,
  emr,
  providerResource,
  machine,
  calendar,
  appointment,
  providerProfile,
  vimGuideProfile,
  appointmentTypes,
  clinicPermissions,
  medicalCodes,
  features,
  sdk,
  appointmentRequest,
  asoGroups,
});
