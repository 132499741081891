import React from 'react';
import { autobind } from 'core-decorators';
import Action from '../../components/action';

@autobind
class DeleteAsoGroupTierAction extends React.PureComponent {
  render() {
    return (
      <Action name="deleteAsoGroupTier" title="Delete Tier" btnColor="red" {...this.props}>
        <div>delete tier and its data - cannot be reverted</div>
      </Action>
    );
  }
}

export default DeleteAsoGroupTierAction;
