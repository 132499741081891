import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-intl-redux';
import { match, Router, useRouterHistory } from 'react-router';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { createHistory } from 'history';
import moment from 'moment';
import configureStore from './store/configureStore';
import rootRoutes from './routes/rootRoute';
import { ThemeProvider, themes } from '../../../storybook/src/utils/theme';

window.moment = moment;

const target = document.querySelector('#root');

export default async function start() {
  // Create an enhanced history that syncs navigation events with the store
  let history = useRouterHistory(createHistory)({
    basename: '',
  });

  // TODO handle failure of configureStore and show error page to the user
  const store = await configureStore({
    platformMiddleware: [routerMiddleware(history)],
  });

  history = syncHistoryWithStore(history, store);
  match({ history, routes: rootRoutes }, (error, redirectLocation, renderProps) => {
    render(
      <ThemeProvider theme={themes.vim}>
        <Provider store={store}>
          <Router {...renderProps} />
        </Provider>
      </ThemeProvider>,
      target,
    );
  });
}
