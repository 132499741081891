import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import EntityForm from '../../components/entityForm/entityForm';
import * as AppointmentFormDefs from './appointmentFormDefs';
import {
  updateAppointment,
  getAppointmentById,
  deleteAppointment,
  DELETE_APPOINTMENT,
} from '../../../../../store/backoffice/clinicManagement/appointment/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/appointment/selectors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { appointmentType as type } from '../permissions/permissionMapper';

@autobind
class UpdateAppointment extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    updateAppointment: PropTypes.func.isRequired, // from redux
    getAppointmentById: PropTypes.func.isRequired, // from redux
    deleteAppointment: PropTypes.func.isRequired, // from redux
    appointment: PropTypes.object.isRequired, // from redux
    deleteTracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    if (this.props.params.appointmentId) {
      this.props.getAppointmentById(this.props.params.appointmentId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.gotoAppointments();
    }
  }

  gotoAppointments() {
    const { id, providerId, calendarId } = this.props.params;
    this.props.router.push(
      `/secure/clinics/${id}/providers/${providerId}/calendars/${calendarId}/appointments`,
    );
  }

  deleteAppointment() {
    const { appointment, deleteAppointment } = this.props;
    deleteAppointment(appointment.id);
  }

  render() {
    const { appointment } = this.props;
    const resourceId = appointment.id;
    return (
      <div>
        <div className="backoffice-action">
          <button
            onClick={this.gotoAppointments}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Back To Calendar׳s Appointments
          </button>
          <button
            onClick={() =>
              this.props.router.push(`/secure/clinics/permissions/${type}/${resourceId}`)}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Appointmnet׳s Permissions
          </button>
          <button
            className="btn btn-small btn-blue text-semibold action-button margin-13"
            onClick={this.deleteAppointment}
          >
            Delete Appointmnet
          </button>
        </div>
        <hr />
        <EntityForm
          name={AppointmentFormDefs.name}
          title="Update Appointment:"
          submitText="Update"
          schemaDef={AppointmentFormDefs.schema}
          fieldsDef={AppointmentFormDefs.fields}
          entity={appointment}
          onSubmit={this.props.updateAppointment}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker({
    deleteTracker: DELETE_APPOINTMENT.SOURCE,
  }),
  connect(
    state => ({
      appointment: selectors.getAppointment(state),
    }),
    { updateAppointment, getAppointmentById, deleteAppointment },
  ),
)(UpdateAppointment);
