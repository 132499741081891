/**
 * created by moran on 12/4/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../../components/action';
import AddAppointmentTypeForm from './addAppointmentTypeForm';
import { CREATE_APPOINTMENT_TYPE } from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/actions';
import { name } from './addAppointmentTypeDefs';

@autobind
class AddAppointmentType extends React.PureComponent {
  static propTypes = {
    onCreateAppointmentType: PropTypes.func.isRequired,
    medicalCodes: PropTypes.array.isRequired,
  };

  render() {
    return (
      <Action
        name="addAppointmentType"
        title="Add Appointment Type"
        actionTrackerId={CREATE_APPOINTMENT_TYPE.SOURCE}
        formName={name}
        {...this.props}
      >
        <AddAppointmentTypeForm
          onSubmit={this.props.onCreateAppointmentType}
          medicalCodes={this.props.medicalCodes}
        />
      </Action>
    );
  }
}

export default AddAppointmentType;
