/**
 * Created by ofirHartuv on 30/10/17.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { autobind } from 'core-decorators';

import EntityForm from '../../components/entityForm/entityForm';
import * as ProviderResourceFormDefs from './providerResourceFormDefs';
import {
  createProviderResource,
  CREATE_PROVIDER_RESOURCE,
} from '../../../../../store/backoffice/clinicManagement/providerResources/actions';
import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';

const providerResourceMock = {
  npi: '',
  clinicId: undefined,
  ehrProviderId: '',
};

@autobind
class CreateProviderResource extends React.Component {
  static propTypes = {
    createProviderResource: PropTypes.func.isRequired, // from redux
    tracker: PropTypes.shape(actionTrackerShape).isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    providerResourceMock.clinicId = props.params.id;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished && !nextProps.tracker.hasError) {
      const { id } = nextProps.params;
      this.props.router.push(`/secure/clinics/${id}/providers`);
    }
  }

  render() {
    const { params } = this.props;
    return (
      <div>
        <Link
          to={`/secure/clinics/${params.id}/providers/`}
          className="btn btn-inline-link blue-link text14"
        >
          Back To Providers
        </Link>
        <EntityForm
          name={ProviderResourceFormDefs.name}
          title="Create New Provider resource:"
          submitText="Create"
          schemaDef={ProviderResourceFormDefs.schema}
          fieldsDef={ProviderResourceFormDefs.fields}
          entity={providerResourceMock}
          onSubmit={this.props.createProviderResource}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker(
    {
      tracker: CREATE_PROVIDER_RESOURCE.SOURCE,
    },
    {
      tracker: {
        success: { title: '', text: 'Provider resource created' },
        error: {
          title: '',
          text: 'Make sure you are in Vim domain and provider exist',
        },
      },
    },
  ),
  connect(
    null,
    { createProviderResource },
  ),
)(CreateProviderResource);
