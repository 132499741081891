/* eslint-disable import/first */
/**
 * Created by asafdavid on 17/04/2016.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import requireAuthentication from '../../../../store/auth/authenticatedComponent';
import BackofficePage from '../layout/backofficePage';
import Home from '../app/home';
import ProvidersRoutes from '../providers/routes';
import { UsersRoutes, UserGroupsRoute } from '../users/routes';
import DomainMemberRoutes from '../domainMembers/routes';
import CommunicationRoutes from '../communication/routes';
import ReviewsRoutes from '../reviews/routes';
import ClinicsRoutes, {
  healthSystem as HealthSystemRoutes,
  schedulingCSI as SchedulingCSIRoutes,
} from '../clinicManagement/routes';
import ProviderProfileRoutes from '../providerProfile/routes';
import VimGuideProfileRoutes from '../vimGuideProfile/routes';
import MedicalCodesRoutes from '../medicalCode/routes';
import AppointmentRequestsRoutes from '../appointmentRequest/routes';
import SdkRoutes from '../apiKeys/routes';
import NamespaceManagerRoutes from '../namespaceManager/routes';
import productAppointments from '../clinicManagement/productUse/routes';
import FeatureRoutes from '../features/routes';
import AsoGroupsRoutes from '../asoGroup/routes';
import ReferralsRoutes from '../referrals/routes';

// Components
const Components = [
  ProvidersRoutes,
  UsersRoutes,
  UserGroupsRoute,
  DomainMemberRoutes,
  CommunicationRoutes,
  ReviewsRoutes,
  ProviderProfileRoutes,
  VimGuideProfileRoutes,
  HealthSystemRoutes,
  ClinicsRoutes,
  SchedulingCSIRoutes,
  MedicalCodesRoutes,
  productAppointments,
  FeatureRoutes,
  SdkRoutes,
  AppointmentRequestsRoutes,
  NamespaceManagerRoutes,
  AsoGroupsRoutes,
  ReferralsRoutes,
];

export default {
  path: 'secure',
  name: 'secure',
  getChildRoutes(location, cb) {
    cb(null, Components);
  },

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAuthentication()(BackofficePage),
  indexRoute: {
    name: 'backofficeHome',
    component: Home.default || Home,
  },
};
/* eslint-enable import/first */
