import React from 'react';
import { compose, defaultProps } from 'recompose';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import withFormikWrapper from 'Components/form/formik/withFormikWrapper';
import { Field } from 'formik';

import './index.less';

const isAlternativeProvidersEnabledFieldName = 'isAlternativeProvidersEnabled';
const isBookingExperienceEnabledFieldName = 'isBookingExperienceEnabled';
const isCommunicationAuthenticatedFieldName = 'isCommunicationAuthenticated';
const isSmsCommunicationEnabledFieldName = 'isSmsCommunicationEnabled';
const isEmailNotificationsEnabledFieldName = 'isEmailNotificationsEnabled';
const shouldCollectEmailFieldName = 'shouldCollectEmail';
const shouldSkipComStepWhenTokenHasDataFieldName = 'shouldSkipComStepWhenTokenHasData';
const isCommunicationWithProviderDataFieldName = 'isCommunicationWithProviderData';
const supportsApptLandingPageFieldName = 'supportsApptLandingPage';
const supportsOptOutFieldName = 'supportsOptOut';
const supportsApptReviewFieldName = 'supportsApptReview';
const supportsApptReminderFieldName = 'supportsApptReminder';
const supportsCashPayFieldName = 'supportsCashPay';
const inNetworkFieldName = 'inNetwork';
const hideBackButtonFieldName = 'hideBackButton';
const enablePeriodicApptSyncFieldName = 'enablePeriodicApptSync';
const isReasonForVisitRequiredFieldName = 'isReasonForVisitRequired';

export const schedulingConfigurationFields = [
  isAlternativeProvidersEnabledFieldName,
  isBookingExperienceEnabledFieldName,
  isCommunicationAuthenticatedFieldName,
  isSmsCommunicationEnabledFieldName,
  isEmailNotificationsEnabledFieldName,
  shouldCollectEmailFieldName,
  shouldSkipComStepWhenTokenHasDataFieldName,
  isCommunicationWithProviderDataFieldName,
  supportsApptLandingPageFieldName,
  supportsOptOutFieldName,
  supportsApptReviewFieldName,
  supportsApptReminderFieldName,
  supportsCashPayFieldName,
  inNetworkFieldName,
  hideBackButtonFieldName,
  enablePeriodicApptSyncFieldName,
  isReasonForVisitRequiredFieldName,
];

const handleCommunicationFlags = (form, isEmailOn, isSmsOn) => {
  if (!isEmailOn && !isSmsOn) {
    form.setFieldValue(isCommunicationWithProviderDataFieldName, false);
    form.setFieldValue(isCommunicationAuthenticatedFieldName, false);
    form.setFieldValue(supportsApptLandingPageFieldName, false);
    form.setFieldValue(supportsOptOutFieldName, false);
    form.setFieldValue(supportsApptReviewFieldName, false);
    form.setFieldValue(supportsApptReminderFieldName, false);
  }
};

function SchedulingConfigurationView({ values, onChange }) {
  const { isEmailNotificationsEnabled, isSmsCommunicationEnabled } = values;

  const shouldCommunicationFieldsBeDisabled =
    !isSmsCommunicationEnabled && !isEmailNotificationsEnabled;

  return (
    <div>
      <h3 className="config-section-title">Scheduling Configurations</h3>
      <div>
        <Field
          id="scheduling-inNetwork"
          name={inNetworkFieldName}
          label="Only in network"
          className="api-key-config-checkbox-field"
          component={CleanFormikCheckbox}
        />
        <div className="field-description">
          Providers will be bookable only for in-network members
        </div>
      </div>
      <div>
        <Field
          id="scheduling-enablePeriodicApptSyn"
          name={enablePeriodicApptSyncFieldName}
          label="Enable periodic appointment sync"
          className="api-key-config-checkbox-field"
          component={CleanFormikCheckbox}
        />
        <div className="field-description">
          Will sync all future appointments once a day. Relevant if the webhook configuration is
          turned on
        </div>
      </div>
      <div className="all-scheduling-configurations">
        <div className="communication-configs">
          <h4 className="scheduling-config-subtitle">Communication Configurations</h4>
          <div>
            <Field
              id="scheduling-com-enabled"
              name={isSmsCommunicationEnabledFieldName}
              label="SMS Communication"
              className="api-key-config-checkbox-field"
              component={props => (
                <CleanFormikCheckbox
                  {...props}
                  onChange={newValue => {
                    handleCommunicationFlags(
                      props.form,
                      values[isEmailNotificationsEnabledFieldName],
                      newValue,
                    );

                    onChange(newValue);
                  }}
                />
              )}
            />
            <div className="field-description">
              If checked, text messages will be sent to members on booking actions
            </div>
          </div>
          <div>
            <Field
              id="scheduling-isEmailNotificationsEnabled"
              name={isEmailNotificationsEnabledFieldName}
              label="Email Communication"
              className="api-key-config-checkbox-field"
              component={props => (
                <CleanFormikCheckbox
                  {...props}
                  onChange={newValue => {
                    handleCommunicationFlags(
                      props.form,
                      newValue,
                      values[isSmsCommunicationEnabledFieldName],
                    );

                    onChange(newValue);
                  }}
                />
              )}
            />
            <div className="field-description">
              If checked, emails will be sent out to members on booking actions
            </div>
          </div>
          <div>
            <Field
              id="scheduling-com-proviedr-data"
              name={isCommunicationWithProviderDataFieldName}
              label="Communication with provider data"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the selected provider's details will be added to text messages and emails
            </div>
          </div>
          <div>
            <Field
              id="scheduling-supportsOptOut"
              name={supportsOptOutFieldName}
              label="Supports Opt Out"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the text messages and/or emails sent out to members will include an opt
              out option
            </div>
          </div>
          <div>
            <Field
              id="scheduling-appt-landing"
              name={supportsApptLandingPageFieldName}
              label="Supports appointment landing page"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the text messages and/or emails sent out to members will contain a link to
              a landing page
            </div>
          </div>
          <div>
            <Field
              id="scheduling-supportsApptReminder"
              name={supportsApptReminderFieldName}
              label="Supports appointment reminder"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, an appointment reminder will be sent out to the members
            </div>
          </div>
          <div>
            <Field
              id="scheduling-supportsApptReview"
              name={supportsApptReviewFieldName}
              label="Supports appointment review"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, an appointment review will be sent to the member to provide a review for
              the appointment that took place
            </div>
          </div>
          <div>
            <Field
              id="scheduling-com-auth"
              name={isCommunicationAuthenticatedFieldName}
              label="Authenticated Communication"
              className="api-key-config-checkbox-field"
              disabled={shouldCommunicationFieldsBeDisabled}
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the member will be asked to enter their information, for authentication
              purposes, in order to view the appointment details
            </div>
          </div>
        </div>
        <div className="booking-flow-configs">
          <h4 className="scheduling-config-subtitle">Booking Flow Configurations</h4>
          <div>
            <Field
              id="scheduling-alternatives"
              name={isAlternativeProvidersEnabledFieldName}
              label="Alternative Providers"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, alternative providers will be searched for during the instant booking flow
              (in case the selected provider does not have any available slots or available slots in
              the next 3 days)
            </div>
          </div>
          <div>
            <Field
              id="scheduling-booking-experience"
              name={isBookingExperienceEnabledFieldName}
              label="Booking Experience"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the patient will be asked to rate their booking experience once the
              appointment is confirmed
            </div>
          </div>
          <div>
            <Field
              id="scheduling-shouldCollectEmail"
              name={shouldCollectEmailFieldName}
              label="Should collect email"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the members will be asked to fill in their email address as part of the
              booking flow
            </div>
          </div>
          <div>
            <Field
              id="scheduling-supportsCashPay"
              name={supportsCashPayFieldName}
              label="Supports self pay"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              If checked, the booking flow will include the 'Self Pay' option
            </div>
          </div>
          <div>
            <Field
              id="scheduling-hideBackButton"
              name={hideBackButtonFieldName}
              label="Hide back button in scheduling sdk"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">Relevant for mobile wrapper implementation</div>
          </div>
          <div>
            <Field
              id="scheduling-isReasonForVisitRequired"
              name={isReasonForVisitRequiredFieldName}
              label="Required reason for visit"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              if checked, the reason for visit step in the booking flow will be required and cannot
              be skipped
            </div>
          </div>
          <div>
            <Field
              id="scheduling-shouldSkipComStepWhenTokenHasData"
              name={shouldSkipComStepWhenTokenHasDataFieldName}
              label="Should skip communication step when token has communication data"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              Should skip communication step when token payload has communication data stored
            </div>
          </div>
          <div>
            <Field
              id="CSRConcierge"
              name="CSRConcierge"
              label="CSR Concierge"
              className="api-key-config-checkbox-field"
              component={CleanFormikCheckbox}
            />
            <div className="field-description">
              Should open the booking flow in CSR Concierge mode
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SchedulingConfiguration = compose(
  withFormikWrapper(),
  defaultProps({
    values: {
      inNetwork: false,
      isEmailNotificationsEnabled: false,
      supportsOptOut: false,
      supportsApptReview: false,
      supportsApptReminder: false,
      supportsCashPay: false,
      isAlternativeProvidersEnabled: false,
      isBookingExperienceEnabled: false,
      isCommunicationAuthenticated: false,
      isSmsCommunicationEnabled: false,
      isCommunicationWithProviderData: false,
      supportsApptLandingPage: false,
      hideBackButton: false,
      enablePeriodicApptSync: false,
      isReasonForVisitRequired: false,
      shouldCollectEmail: false,
      shouldSkipComStepWhenTokenHasData: false,
      CSRConcierge: false,
    },
  }),
)(SchedulingConfigurationView);

export default SchedulingConfiguration;
