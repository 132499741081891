/**
 * Created by chenrozenes on 09/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn } from '../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';

import { getAllProviders } from '../../../../store/backoffice/providers/actions';
import * as selectors from '../../../../store/backoffice/providers/selectors';

@autobind
class ProvidersList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllProviders: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    providersCount: PropTypes.number.isRequired, // from redux
    providers: PropTypes.array.isRequired, // from redux
  };

  onClick(rowData) {
    this.props.router.push(`/secure/providers/${rowData.npi}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllProviders(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { providers, providersCount } = this.props;
    return (
      <div>
        <QueryStringGrid
          data={providers}
          onRowClick={this.onClick}
          title="Providers"
          totalCount={providersCount}
          onQueryChange={this.onQueryChange}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="firstName" title="First name" />
          <BkmdGridColumn id="middleName" title="Middle name" />
          <BkmdGridColumn id="lastName" title="Last name" />
          <BkmdGridColumn id="npi" title="Npi" />
        </QueryStringGrid>
        <br />
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  fetchNotEmpty(
    ({ props }) =>
      getAllProviders(props.skip, props.limit, props.search, props.sort, props.sortAscending),
    selectors.getProvidersList,
  ),
  connect(
    state => ({
      providers: selectors.getProvidersList(state),
      providersCount: selectors.getProvidersCount(state),
    }),
    { getAllProviders },
  ),
)(ProvidersList);
