/**
 * Created by chenrozenes on 18/04/2017.
 */
import _ from 'lodash';

export const getFullMember = state => _.get(state, 'backoffice.members.full');
export const isMemberActivated = state => _.get(getFullMember(state), 'isActivated', false);
export const isMemberRegistered = state => _.get(getFullMember(state), 'isRegistered', false);
export const hasUser = state => !_.isEmpty(_.get(getFullMember(state), 'userId'));
export const hasLinkedEntities = state => {
  const member = getFullMember(state);

  return _.get(member, 'linkedEntities.hasAppointment', false) ||
    _.get(member, 'linkedEntities.hasReferral', false) ||
    _.get(member, 'linkedEntities.hasAppointmentRequest', false) ||
    _.get(member, 'linkedEntities.hasPcpSelection', false);
};

export const hasAssociatedData = state =>
  isMemberActivated(state) || isMemberRegistered(state)
  || hasUser(state)
  || hasLinkedEntities(state);

export const getMembersList = state => _.get(state, 'backoffice.members.list.data');
export const getMember = (state, props) => {
  const id = parseInt(_.get(props, 'routeParams.id'), 10);
  const list = getMembersList(state);
  return _.find(list, { id });
};
export const getRawMemberCount = state => _.get(state, 'backoffice.members.raw.list.count');
export const getRawMemberList = state => _.get(state, 'backoffice.members.raw.list.data');
export const getDomainMemberId = state => _.get(getFullMember(state), 'id');
export const getAllowDeactivateFlag = state =>
  _.get(state, 'config.domainMember.allowDeactivate', false);
export const getEligibilityResponse = state => _.get(state, 'backoffice.members.eligibility');
export const getBulkEligibilityResponse = state => _.get(state, 'backoffice.members.bulkEligibilityResponse.data', {});
export const getAttachPastActivityResponse = state => _.get(state, 'backoffice.members.attachPastActivity', {});
