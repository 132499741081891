/**
 * created by moran on 12/4/17
 */
import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import { reduxForm } from 'redux-form';
import * as addAppointmentTypeFormDefs from './addAppointmentTypeDefs';
import { createValidator } from '../../../../../../utils/joiValidator';
import FieldGenerator from '../../../components/entityForm/fieldGenerator';

@autobind
class AddAppointmentTypeForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    medicalCodes: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
  };

  getFieldsDef() {
    const { medicalCodes, entity } = this.props;
    const insertedType = _.get(entity, 'type', 'VIM');
    const possibleMedicalCodes = _.filter(medicalCodes, { type: insertedType });
    return addAppointmentTypeFormDefs.fields(possibleMedicalCodes);
  }

  render() {
    const fieldDef = this.getFieldsDef();
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="row">
          <FieldGenerator entity={fieldDef} fieldsDef={fieldDef} />
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: addAppointmentTypeFormDefs.name,
    validate: createValidator(Joi.object().keys(addAppointmentTypeFormDefs.schema)),
  }),
  connect(state => ({
    entity: _.get(state, `form.${addAppointmentTypeFormDefs.name}.values`),
  })),
)(AddAppointmentTypeForm);
