/* eslint-disable import/prefer-default-export */

import { reduxForm } from 'redux-form';
import Joi from 'joi-browser';
import _ from 'lodash';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { createValidator } from '../../../../../../utils/joiValidator';
import * as CreateUserFormDefs from './createUserFormDefs';

export const withCreateUserForm = (field, customValidator) =>
  compose(
    injectIntl,
    reduxForm({
      form: CreateUserFormDefs.name,
      validate: createValidator(
        Joi.object().keys(_.pick(CreateUserFormDefs.schema, field)),
        customValidator,
      ),
      destroyOnUnmount: false,
    }),
  );
