/**
 * Created by Nico on 3/05/2018.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import { withRouter } from 'react-router';
import { defineMessages } from 'react-intl';

import fetchNotEmpty from 'Store/tools/fetchNotEmpty';
import { getVimGuideProfile } from 'Store/backoffice/vimGuideProfile/selectors';
import {
  getVimGuideById,
  updateVimGuideProfile,
  UPDATE_VIM_GUIDE_PROFILE,
} from 'Store/backoffice/vimGuideProfile/actions';
import { getAllAsoGroups } from 'Store/backoffice/asoGroups/actions';
import { getAsoGroupsList } from 'Store/backoffice/asoGroups/selectors';
import EntityForm from '../components/entityForm/entityForm';
import * as CreateVimGuideFormDefs from './actions/createVimGuideFormDefs';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';

const messages = defineMessages({
  successTitle: {
    defaultMessage: 'Success',
    id: 'vimGuideProfile.update.successTitle',
  },
  errorTitle: {
    defaultMessage: 'Error',
    id: 'vimGuideProfile.update.errorTitle',
  },
  updateSucceededMessage: {
    defaultMessage: 'Vim guide successfully updated',
    id: 'vimGuideProfile.update.updateSucceededMessage',
  },
  updateErrorMessage: {
    defaultMessage: 'Failed to update Vim guide',
    id: 'vimGuideProfile.update.updateErrorMessage',
  },
});

const updateSchema = {
  id: Joi.number().required(),
  userId: Joi.number().required(),
  externalId: Joi.string()
    .allow('', null)
    .optional(),
  asoGroups: Joi.array()
    .items(Joi.string())
    .allow([], null)
    .optional(),
  roles: Joi.array()
    .items(Joi.string())
    .allow([], null)
    .optional(),
};

@autobind
class VimGuideProfilePage extends React.Component {
  static propTypes = {
    vimGuide: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    updateVimGuideProfile: PropTypes.func.isRequired,
    updateVimGuideProfileTracker: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { updateVimGuideProfileTracker } = nextProps;
    if (updateVimGuideProfileTracker.finished && !updateVimGuideProfileTracker.hasError) {
      this.props.router.push('/secure/vimGuideProfile');
    }
  }

  update(profile) {
    let roles = profile['csr-admin'] === true ? ['csr-admin'] : [];

    if (Array.isArray(this.props.vimGuide.roles)) {
      const anotherRoles = this.props.vimGuide.roles.filter(role => role !== 'csr-admin');
      roles = [...anotherRoles, ...roles];
    }
    return this.props.updateVimGuideProfile({ ..._.omit(profile, ['csr-admin']), roles });
  }

  goToUser() {
    const {
      vimGuide: { userId },
      router,
    } = this.props;
    router.push(`/secure/users/${userId}`);
  }

  renderGoToUser() {
    const {
      vimGuide: { userId },
    } = this.props;

    if (!userId) {
      return null;
    }

    return (
      <div className="backoffice-action">
        <button
          className="btn btn-small btn-blue text-semibold action-button"
          name="Go to user"
          onClick={this.goToUser}
        >
          Go to user
        </button>
      </div>
    );
  }

  render() {
    const { vimGuide } = this.props;
      
    return (
      <div>
        <h1>{`Vim Guide Profile: ${vimGuide.id}`}</h1>
        {this.renderGoToUser()}
        <hr />
        <EntityForm
          name="vim-guide-profile-form"
          title="Vim Guide Profile:"
          entity={{
            ..._.omit(vimGuide, ['createdAt', 'updatedAt', 'asoGroups', 'profilePicture', 'roles']),
            asoGroups: vimGuide.asoGroups?.asMutable(),
            profilePicture: vimGuide.profilePicture,
            'csr-admin': vimGuide.roles?.includes('csr-admin'),
          }}
          onSubmit={this.update}
          schemaDef={updateSchema}
          fieldsDef={CreateVimGuideFormDefs.fields(this.props.asoGroups.asMutable())}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(({ params }) => getVimGuideById(parseInt(params.id, 10)), getVimGuideProfile, {
    renderAlways: false,
    loading: true,
  }),
  fetchNotEmpty(
    () => getAllAsoGroups(),
    getAsoGroupsList,
    { renderAlways: false, loading: true },
  ),
  connect(
    (state, props) => ({
      vimGuide: getVimGuideProfile(state, props),
      asoGroups: getAsoGroupsList(state),
    }),
    { updateVimGuideProfile },
  ),
  actionTracker(
    {
      updateVimGuideProfileTracker: UPDATE_VIM_GUIDE_PROFILE.SOURCE,
    },
    {
      updateVimGuideProfileTracker: {
        success: {
          title: messages.successTitle,
          text: messages.updateSucceededMessage,
        },
        error: {
          title: messages.errorTitle,
          text: messages.updateErrorMessage,
        },
      },
    },
  ),
)(VimGuideProfilePage);
