import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { namespaceGraphqlFetcher } from '../../../internal-api/graphql/fetcher';
import { fullAppointmentRequestActionFragment } from '../../../internal-api/graphql/fragments';
import AppointmentRequestActionView from './appointmentRequestActionView';

export const messages = defineMessages({
  AppointmentRequestDetails: {
    defaultMessage: 'Appointment Request Action Details:',
    id: 'namespace.appointmentRequest.details',
  },
});

function Details({ queryResult }) {
  const appointmentRequestAction = get(queryResult, 'data.getAppointmentRequestAction[0]', {});

  return (
    <div className="namespace-details-card col-sm-6 col-sm-offset-3 margin-bottom-clean-form">
      <div className="row">
        <div className="col-xs-12 text-16 margin-top margin-bottom">
          <h4>
            <FormattedMessage {...messages.AppointmentRequestDetails} />
          </h4>
        </div>
      </div>
      <AppointmentRequestActionView appointmentRequestAction={appointmentRequestAction} />
    </div>
  );
}

Details.propTypes = {
  queryResult: PropTypes.object.isRequired,
};

export default compose(
  withStateFetchersOnMount({
    getFullAppointmentRequestAction: {
      handler: ({ params }) => () =>
        namespaceGraphqlFetcher.queryOrMutate({
          query: gql`
            ${fullAppointmentRequestActionFragment}

            query getFullAppointmentRequestAction($input: GetAppointmentRequestActionInput!) {
              getAppointmentRequestAction(input: $input) {
                ...fullAppointmentRequestAction
              }
            }
          `,
          variables: {
            input: { id: params.id },
          },
          context: {
            uri: `${params.namespaceName}/graphql`,
          },
        }),
      resultPropName: 'queryResult',
      defaultValue: {},
      isReady: ({ queryResult }) => !isEmpty(queryResult),
    },
  }),
)(Details);
