/**
 * Created by moran on 10/26/17.
 */
import PromiseActionType from '../promiseActionType';
import { getAllProviderProfiles } from '../backoffice/providerProfile/actions';

export const GET_PROVIDER_PROFILE = new PromiseActionType('GET_PROVIDER_PROFILE');
export const CREATE_PROVIDER_PROFILE = new PromiseActionType('CREATE_PROVIDER_PROFILE');

export function getProviderProfile() {
  return ({ bkmdApi: { providerProfileApi } }) => ({
    type: GET_PROVIDER_PROFILE.SOURCE,
    payload: {
      promise: providerProfileApi.getByUserId(),
    },
    meta: {
      tracker: GET_PROVIDER_PROFILE.SOURCE,
    },
  });
}

export function createProvider(profile, domains) {
  return ({ dispatch, bkmdApi: { providerProfileApi } }) => ({
    type: CREATE_PROVIDER_PROFILE.SOURCE,
    payload: {
      promise: providerProfileApi.createProfile(profile, domains)
        .then(() => {
          dispatch(getAllProviderProfiles());
        }),
    },
    meta: {
      tracker: CREATE_PROVIDER_PROFILE.SOURCE,
    },
  });
}
