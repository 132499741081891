import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import EntityForm from '../../components/entityForm/entityForm';
import * as MachineFormDefs from './machineFormDefs';
import {
  updateMachineResource,
  getMachineResource,
  deleteMachineResource,
  DELETE_MACHINE,
} from '../../../../../store/backoffice/clinicManagement/machines/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/machines/selectors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';

@autobind
class UpdateMachine extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    updateMachineResource: PropTypes.func.isRequired, // from redux
    getMachineResource: PropTypes.func.isRequired, // from redux
    deleteMachineResource: PropTypes.func.isRequired, // from redux
    machine: PropTypes.object.isRequired, // from redux
    deleteTracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      params: { resourceId },
    } = this.props;
    this.props.getMachineResource(resourceId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.goToMachines();
    }
  }

  goToMachines() {
    const { params, router } = this.props;
    router.push(`/secure/clinics/${params.id}/machines/`);
  }

  deleteMachineResource() {
    const { machine, deleteMachineResource } = this.props;
    deleteMachineResource(machine.id);
  }

  updateMachineResource(data) {
    const {
      updateMachineResource,
      params: { resourceId },
    } = this.props;
    updateMachineResource({ ...data, id: resourceId });
  }

  renderActions() {
    const {
      params: { id, resourceId },
      router,
    } = this.props;

    return (
      <div className="backoffice-action">
        <button
          className="btn btn-small btn-blue text-semibold action-button margin-13"
          onClick={this.deleteMachineResource}
        >
          Delete Machine
        </button>
        <button
          onClick={() =>
            this.props.router.push(`/secure/clinics/${id}/machines/${resourceId}/calendars`)}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Show Machine׳s Calendars
        </button>
        <button
          onClick={() => router.push(`/secure/clinics/${id}/machines/${resourceId}/services`)}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Show Machine Appointment Types
        </button>
      </div>
    );
  }

  render() {
    const { machine } = this.props;
    return (
      <div>
        {this.renderActions()}
        <hr />
        <EntityForm
          name={MachineFormDefs.name}
          title="Update Machine:"
          submitText="Update"
          schemaDef={MachineFormDefs.schema}
          fieldsDef={MachineFormDefs.fields}
          entity={machine}
          onSubmit={this.updateMachineResource}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker({
    deleteTracker: DELETE_MACHINE.SOURCE,
  }),
  connect(
    state => ({
      machine: selectors.getMachine(state),
    }),
    { updateMachineResource, getMachineResource, deleteMachineResource },
  ),
)(UpdateMachine);
