import { combineReducers } from 'redux';

import genericReducer from '../../../genericReducer';
import { GET_CLINIC_MAPPING, LOAD_CLINIC_MAPPING_FILE } from './actions';

const initialState = {
  mapping: {},
  mappingFromFile: {}
};

export default combineReducers({
  mapping: genericReducer(GET_CLINIC_MAPPING, initialState.mapping),
  mappingFromFile: genericReducer(LOAD_CLINIC_MAPPING_FILE, initialState.mappingFromFile)
});
