import PropTypes from 'prop-types';
import React from 'react';
import { omit, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { defineMessages } from 'react-intl';
import { compose, withPropsOnChange, lifecycle, withHandlers } from 'recompose';

import injectNotification from 'Store/notification/injectNotification';
import { updateSdkTheme, UPDATE_SDK_THEME, getSdkTheme } from 'Store/backoffice/sdk/actions';
import actionTracker from 'Store/tools/actionTracker/actionTrackerComponent';
import { getSdkTheme as getSdkThemeSelector } from 'Store/backoffice/sdk/selectors';
import EntityForm from '../../components/entityForm/entityForm';
import * as ApiThemeFormDefs from '../apiThemeFormDefs';

const messages = defineMessages({
  buttonBorderRadiusErrorTitle: {
    defaultMessage: 'Button border radius is required',
    id: 'backpack.sdk.updateTheme.buttomBorderRadiusErrorTitle',
  },
  buttonBorderRadiusError: {
    defaultMessage: 'Use 4px as default',
    id: 'backpack.sdk.updateTheme.buttomBorderRadiusError',
  },
});

const UpdateApiTheme = ({ updateSdkTheme, theme }) => (
  <div>
    <EntityForm
      name={ApiThemeFormDefs.name}
      title="Update API theme:"
      submitText="Update"
      schemaDef={omit(ApiThemeFormDefs.schema, 'name')}
      fieldsDef={omit(ApiThemeFormDefs.fields, 'name')}
      entity={omit(theme, 'name')}
      onSubmit={updateSdkTheme}
    />
  </div>
);
UpdateApiTheme.propTypes = {
  updateSdkTheme: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  injectNotification,
  actionTracker({
    tracker: UPDATE_SDK_THEME.SOURCE,
  }),
  withPropsOnChange(['tracker'], ({ router, tracker }) => {
    if (tracker.finished && !tracker.hasError) {
      router.push('/secure/apiKeys');
    }
  }),
  connect(
    state => ({
      theme: getSdkThemeSelector(state),
    }),
    { updateSdkTheme, getSdkTheme },
  ),
  withHandlers({
    updateSdkTheme: ({ updateSdkTheme, theme: { name }, notification }) => values => {
      if (isEmpty(values.buttonBorderRadius)) {
        notification.error(messages.buttonBorderRadiusErrorTitle, messages.buttonBorderRadiusError);
      } else {
        updateSdkTheme({ name, ...values });
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.getSdkTheme(this.props.params.name);
    },
  }),
);

export default enhance(UpdateApiTheme);
