/**
 * Created by asafdavid on 13/01/2016.
 */
import React from 'react';

import cleanInputWrapper from './cleanInputWrapper';

const inputFileComponent = props => <input {...props} type="file" />;

export default cleanInputWrapper(inputFileComponent);
