/**
 * Created by chenrozenes on 18/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router';
import { autobind } from 'core-decorators';
import DomainSelector from './domainSelector';
import './header.less';

@autobind
class Header extends React.PureComponent {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.logoImg = require('./vim-nav-logo.png');
  }

  render() {
    return (
      <div className="backoffice-header">
        <div className="header-logo">
          <Link to="/secure">
            <img src={this.logoImg} alt="BookMD" />
          </Link>
        </div>
        <div className="pull-left">
          <DomainSelector />
        </div>
        <div className="header-title text-center">
          <h3>
            Logged in as <b>{_.get(this.props.user, 'email')}</b>
          </h3>
        </div>
        <ul className="header-right-nav">
          <li>
            <a href="" onClick={this.props.onLogout} className="logout">
              Logout
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Header;
