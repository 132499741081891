import _ from 'lodash';
import { connect } from 'react-redux';

const rowDataSelector = (state, { griddleKey }) => {
  const data = state.get('data').find(rowMap => rowMap.get('griddleKey') === griddleKey);
  return _.invoke(data, 'toJSON');
};

const withRowData = connect((state, props) => ({
  rowData: rowDataSelector(state, props),
}));

export default withRowData;
