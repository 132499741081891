import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { get, find } from 'lodash';
import Formatter from '../../../../../../utils/formatter';
import commonFormats from '../../../../../../utils/formatter/commonFormats';

const messages = defineMessages({
  title: {
    defaultMessage: 'Appointment request action details:',
    id: 'namespace.appointmentRequestAction.title',
  },
  platformId: {
    defaultMessage: 'Platform id:',
    id: 'namespace.appointmentRequestAction.platformId',
  },
  fullName: {
    defaultMessage: 'Full name:',
    id: 'namespace.appointmentRequestAction.fullName',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth:',
    id: 'namespace.appointmentRequestAction.dateOfBirth',
  },
  address: {
    defaultMessage: 'Address:',
    id: 'namespace.appointmentRequestAction.address',
  },
  email: {
    defaultMessage: 'EMAIL:',
    id: 'namespace.appointmentRequestAction.email',
  },
  plan: {
    defaultMessage: 'Plan:',
    id: 'namespace.appointmentRequestAction.plan',
  },
  phoneNumber: {
    defaultMessage: 'Phone:',
    id: 'namespace.appointmentRequestAction.phoneNumber',
  },
  insurer: {
    defaultMessage: 'Insurer:',
    id: 'namespace.appointmentRequestAction.insurer',
  },
  status: {
    defaultMessage: 'Status:',
    id: 'namespace.appointmentRequestAction.status',
  },
  npi: {
    defaultMessage: 'NPI:',
    id: 'namespace.appointmentRequestAction.npi',
  },
  reasonForVisit: {
    defaultMessage: 'Reason For Visit:',
    id: 'namespace.appointmentRequestAction.reasonForVisit',
  },
  namespace: {
    defaultMessage: 'Namespace:',
    id: 'namespace.appointmentRequestAction.namespace',
  },
});

export default function AppointmentRequestActionView({ appointmentRequestAction: request }) {
  if (!request) return null;
  return (
    <div>
      <div className="row">
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.platformId} />
          </h4>
          {request.platformId}
        </div>
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.fullName} />
          </h4>
          {`${request.phi.firstName} ${request.phi.lastName}`}
        </div>
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.dateOfBirth} />
          </h4>
          {Formatter.dateUtc(request.phi.dateOfBirth, commonFormats.SHORT_MONTH_DAY_YEAR_DATE)}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.phoneNumber} />
          </h4>
          {get(
            find(request.phi.phoneNumbers, phoneNumber => phoneNumber.name === 'mobile'),
            'number',
          )}
        </div>
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.address} />
          </h4>
          {request.phi.patientAddress}
        </div>
        <div className="col-xs-4">
          <h4>
            <FormattedMessage {...messages.email} />
          </h4>
          {request.phi.email}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.insurer} />
          </h4>
          {request.insurer}
        </div>
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.plan} />
          </h4>
          {request.plan}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.status} />
          </h4>
          {request.status}
        </div>
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.npi} />
          </h4>
          {request.npi}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.reasonForVisit} />
          </h4>
          {request.phi.reasonForVisit}
        </div>
        <div className="col-xs-6">
          <h4>
            <FormattedMessage {...messages.namespace} />
          </h4>
          {request.namespace}
        </div>
      </div>
    </div>
  );
}
