import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import Flow from 'Components/wizard/flow/Flow';
import BkmdModal from 'Components/bkmdModal/bkmdModal';
import ModalHeader from 'Components/bkmdModal/modalHeader';
import flowDef from './flowDef';
import steps from '../steps';

import '../namespaceModal.less';

const messages = defineMessages({
  createVirtualNamespaceTitle: {
    defaultMessage: 'Virtual namespace creation',
    id: 'backpack.createVirtualNamespaceModal.title',
  },
});

const CreateVirtualNamespaceModal = ({ isOpen, handleClose, onSuccess }) => (
  <BkmdModal
    isOpen={isOpen}
    className="create-namespace-modal fixed-header"
    handleClose={handleClose}
  >
    <ModalHeader onClose={handleClose} title={messages.createVirtualNamespaceTitle} />
    <div className="dialog-body">
      <Flow
        name={flowDef.name}
        definitions={flowDef.definitions}
        initialValues={{
          handleClose,
          onSuccess,
        }}
        initialStepName={steps.CREATE_VIRTUAL_NAMESPACE}
      />
    </div>
  </BkmdModal>
);

CreateVirtualNamespaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

CreateVirtualNamespaceModal.defaultProps = {
  onSuccess: undefined,
};

export default CreateVirtualNamespaceModal;
