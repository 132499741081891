import React from 'react';
import { compose } from 'recompose';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { Field, Form } from 'formik';
import SpinnerButton from 'Components/ui/spinner/spinnerButton';
import _ from 'lodash';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import injectNotification from 'Store/notification/injectNotification';
import classNames from 'classnames';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../../api';
import SchedulingConfiguration from '../schedulingConfiguration/schedulingConfiguration';
import ReferralGuidanceConfiguration from '../referralGuidanceConfiguration';
import SearchConfiguration from '../searchConfiguration';
import { BkmdDomains } from '../../../../../../model/enum/bkmdDomain';

import './index.less';

export const API_KEY_FIELDS_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

function ApiKeyFields({
  pageTitle,
  healthSystems,
  namespaces,
  themes,
  insurers,
  onSubmit,
  submitButtonText,
  initialValues,
  pageMode,
}) {
  return (
    <div className="card">
      <div className={classNames('card-body', 'padding-box-30', 'api-key-fields')}>
        <WrappedFormik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={values => {
            const tenantToDomainMapping = {
              premera: BkmdDomains.PREMERA_BLUE_CROSS_ALASKA,
              anthem: BkmdDomains.ANTHEM,
              florida: BkmdDomains.FLORIDA_BLUE,
            };

            const tenant = _.get(values, 'tenant.value', values.tenant);
            const domain = _.includes(Object.keys(tenantToDomainMapping), tenant)
              ? tenantToDomainMapping[tenant]
              : BkmdDomains.VIM;

            const deletedAt = values.deletedAt ? new Date() : null;

            const permissions = {
              ...values,
              deletedAt,
              domain,
              tenant,
              theme: _.get(values, 'theme.value', values.theme),
              defaultInsurer: _.get(values, 'defaultInsurer.value', values.defaultInsurer),
            };

            onSubmit(permissions);
          }}
          component={({ values, handleChange }) => (
            <Form method="post">
              <div className={classNames('row', 'margin-bottom-clean-form')}>
                <div className="col-xs-6">
                  <h2 className="main-subtitle">{pageTitle}</h2>
                </div>
                <div className="col-xs-6">
                  <SpinnerButton
                    className={classNames('btn btn-small', 'btn-green pull-right')}
                    type="submit"
                  >
                    {submitButtonText}
                  </SpinnerButton>
                </div>
              </div>
              {pageMode === API_KEY_FIELDS_MODE.UPDATE && (
                  <div className="is-soft-deleted-section">
                    <div className="flex-one">
                    <Field
                      id="deletedAt"
                      name="deletedAt"
                      label="Soft Delete (if turned on requests with this key will be unauthorized)"
                      className={classNames('api-key-config-checkbox-field', 'is-soft-deleted-field')}
                      component={CleanFormikCheckbox}
                    />
                  </div>
                  </div>
                )}
              <div className="multiple-fields-section">
                <div className="flex-one">
                  <Field
                    name="apiKey"
                    label="Client API Key (Generated)"
                    className="api-key-field"
                    component={CleanFormikInput}
                    disabled
                  />
                </div>
                <div className="flex-one">
                  <Field
                    name="serverApiKey"
                    label="Server API Key (Generated)"
                    className="api-key-field"
                    component={CleanFormikInput}
                    disabled
                  />
                </div>
                {pageMode === API_KEY_FIELDS_MODE.UPDATE && (
                  <div className="flex-zero">
                    <Field
                      id="hasSecret"
                      name="hasSecret"
                      label="Has secret"
                      component={CleanFormikCheckbox}
                      disabled
                    />
                  </div>
                )}
              </div>
              {pageMode === API_KEY_FIELDS_MODE.CREATE && (
                <Field
                  name="secretApiKey"
                  label="Secret API Key (Generated) - THIS VALUE WON'T BE VISIBLE AGAIN. MAKE SURE YOU SAVE IT IN A SECURE PLACE"
                  className="secret-api-key-field"
                  component={CleanFormikInput}
                  disabled
                />
              )}
              <div className="multiple-fields-section">
                <div className="flex-one">
                  <Field
                    name="tenant"
                    label="Tenant"
                    component={CleanFormikSelect}
                    options={_.map(namespaces, namespace => ({
                      value: namespace,
                      label: namespace,
                    }))}
                    disabled={values.isMultiPayer}
                  />
                </div>
                <div className="flex-one">
                  <Field
                    name="defaultInsurer"
                    label="Insurer"
                    component={CleanFormikSelect}
                    options={_.map(insurers, ({ displayName, pokitdokTradingPartnerId }) => ({
                      value: pokitdokTradingPartnerId,
                      label: displayName,
                    }))}
                    disabled={values.isMultiPayer}
                  />
                </div>
                <div className="flex-zero">
                  <Field
                    id="isMultiPayer"
                    name="isMultiPayer"
                    label="isMultiPayer"
                    className={classNames('api-key-config-checkbox-field', 'is-multi-payer-field')}
                    component={CleanFormikCheckbox}
                    disabled={!!(values.defaultInsurer || values.tenant)}
                  />
                  <div className="field-description">
                    If turned on can not have neither insurer nor tenant
                  </div>
                </div>
              </div>
              <div className="multiple-fields-section">
                <div className="flex-one">
                  <Field name="clientId" label="Client ID" component={CleanFormikInput} />
                </div>
                <div className="flex-one">
                  <Field
                    name="theme"
                    label="Theme"
                    component={CleanFormikSelect}
                    options={_.map(themes, ({ name }) => ({
                      value: name,
                      label: name,
                    }))}
                  />
                </div>
              </div>
              {pageMode === API_KEY_FIELDS_MODE.UPDATE && (
                <div className="multiple-fields-section">
                  <div className="flex-one">
                    <Field
                      name="webhookUrl"
                      label="Webhook URL"
                      component={CleanFormikInput}
                      disabled
                    />
                  </div>
                  <div className="flex-one">
                    <Field
                      name="verificationToken"
                      label="Verification Token"
                      component={CleanFormikInput}
                      disabled
                    />
                  </div>
                  <div className="flex-zero">
                    <Field
                      id="isWebHookUrlVerify"
                      name="isWebHookUrlVerify"
                      label="Is URL Verified"
                      component={CleanFormikCheckbox}
                      disabled
                    />
                  </div>
                </div>
              )}
              <div className="configuration-section">
                <Field
                  name="schedulingConfiguration"
                  component={SchedulingConfiguration}
                  values={values}
                  onChange={handleChange}
                />
              </div>
              <div className="configuration-section">
                <Field
                  name="searchConfiguration"
                  component={SearchConfiguration}
                  healthSystems={healthSystems}
                />
              </div>
              <div className="configuration-section">
                <Field
                  name="referralGuidanceConfiguration"
                  component={ReferralGuidanceConfiguration}
                />
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: () => async () => (await Api.clinicsApi.getAllHealthSystems()).data,
      resultPropName: 'healthSystems',
      isReady: ({ healthSystems }) => !_.isEmpty(healthSystems),
    },
    getNamespaces: {
      handler: () => async () =>
        (await Api.namespaceManagerApi.getNamespaces()).map(namespace => namespace.name),
      resultPropName: 'namespaces',
      isReady: ({ namespaces }) => !_.isEmpty(namespaces),
    },
    getThemes: {
      handler: () => async () => (await Api.sdkApi.getAllSdkThemes()).data,
      resultPropName: 'themes',
      isReady: ({ themes }) => !_.isEmpty(themes),
    },
    getInsurers: {
      handler: () => async () => (await Api.eligibilityApi.getInsurers()).data,
      resultPropName: 'insurers',
      isReady: ({ insurers }) => !_.isEmpty(insurers),
    },
  }),
)(ApiKeyFields);
