/**
 * Created by chenrozenes on 26/04/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormCleanCheckbox } from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-user';

export const fields = {
  locked: {
    name: 'locked',
    id: 'locked',
    component: ReduxFormCleanCheckbox,
  },
  lockReason: {
    className: 'margin-top-30',
  },
  tags: false,
};

export const schema = {
  id: Joi.number().required(),
  username: Joi.string().required(),
  tags: Joi.array().items(Joi.string()),
  locked: Joi.boolean().required(),
};
