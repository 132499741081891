/**
 * Created by chenrozenes on 01/05/2017.
 */
import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanRadioGroup,
  ReduxFormCleanSelect,
} from '../../../../../../components/form/reduxForm/components';

export const name = 'backoffice-remove-permission';

export const fields = {
  resourceType: {
    name: 'resourceType',
    component: ReduxFormCleanSelect,
  },
  resourceId: {
    name: 'resourceId',
    component: ReduxFormCleanInput,
  },
  userId: {
    name: 'userId',
    component: ReduxFormCleanInput,
  },
  isGroup: {
    name: 'isGroup',
    component: ReduxFormCleanRadioGroup,
    simpleSelect: true,
    options: [{ value: false, label: 'User ID' }, { value: true, label: 'Group Id' }],
    wrapClass: 'two-lines-btn margin-bottom-clean-form',
    btnStyle: 'btn btn-small btn-pill btn-outline text-semibold',
    horizontal: false,
    equalBtnSize: true,
    marginBetweenOptions: 1,
  },
};

export const schema = {
  resourceType: Joi.string().required(),
  resourceId: Joi.string(),
  userId: Joi.string().required(),
};
