import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import CSVReader from 'react-csv-reader';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import { PROVIDER } from 'Model/enum/resourceTypes';
import Action from '../../../components/action';
import CsvAppointmentTypesResultsModal from './csvAppointmentTypesResultsModal';
import {
  createBulkAppointmentType,
  CREATE_BULK_APPOINTMENT_TYPE,
} from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/actions';
import '../../../../../../flows/steps/loadClinicFromCSV.less';

const messages = defineMessages({
  chooseFile: {
    defaultMessage: 'Choose file',
    id: 'csvLoader.chooseFile',
  },
  fullStateWarning: {
    defaultMessage:
      'This action expects full state (per provider per clinic) and will delete types that do not exist in the file.',
    id: 'appointmentTypes.loadFromCSV.fullStateWarning',
  },
});

@autobind
class AddCSVAppointmentTypes extends React.PureComponent {
  static propTypes = {
    createBulkAppointmentType: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { appointmentTypes: undefined };
  }

  onFileLoaded(rows) {
    // skip the headers and parse the rows
    const appointmentTypes = _.map(_.slice(rows, 1), (row, index) => ({
      npi: row[0],
      clinicId: row[1],
      clinicNpi: row[2],
      type: _.toUpper(row[3]),
      key: row[4],
      duration: row[5],
      isTelemedicine: row[6],
      ehrAppointmentType: row[7],
      minTimeBeforeBooking: row[8],
      interchangeableAppointmentTypes: row.length > 9 && row[9].length > 0 ? row[9] : undefined,
      rowNumber: index + 1,
      resourceType: row[10] && row[10].length > 0 ? row[10] : PROVIDER,
    }));
    const appointmentTypesWithDurationInMs = this.convertAppointmentTypesDuration(appointmentTypes);
    this.setState({ appointmentTypes: appointmentTypesWithDurationInMs });
  }

  convertAppointmentTypesDuration = appointmentTypes => {
    _.forEach(appointmentTypes, appt => {
      // eslint-disable-next-line no-param-reassign
      appt.duration *= 60000;
    });
    return appointmentTypes;
  };

  render() {
    return (
      <div>
        <Action
          name="addCSVAppointmentTypes"
          title="Add Appointment Types from CSV"
          onSubmit={() => this.props.createBulkAppointmentType(this.state.appointmentTypes)}
          actionTrackerId={CREATE_BULK_APPOINTMENT_TYPE.SOURCE}
        >
          <CSVReader
            cssClass="csv-input-uploader"
            label="Select appointment types from csv"
            onFileLoaded={this.onFileLoaded}
            inputId="appointment-types-csv"
          />
          <div className="margin-top-20">
            <label htmlFor="appointment-types-csv" className="btn btn-small btn-outline load-btn">
              <i className="fa fa-upload" />
              &nbsp;
              <FormattedMessage {...messages.chooseFile} />
            </label>
          </div>
          <div className="text-16 text-strong font-color-brand-accent margin-top-20">
            <FormattedMessage {...messages.fullStateWarning} />
          </div>
        </Action>
        <CsvAppointmentTypesResultsModal />
      </div>
    );
  }
}

export default compose(connect(null, { createBulkAppointmentType }))(AddCSVAppointmentTypes);
