/**
 * Created by asafdavid on 05/05/2016.
 */
import React from 'react';
import { Link } from 'react-router';
import AclGuard from '../../../../store/auth/aclGuard';
import {
  BACK_OFFICE_HEALTH_SYSTEM,
  BACK_OFFICE_CLINICS,
  BACK_OFFICE_FEATURES,
  BACK_OFFICE_MEDICAL_CODES,
  BACK_OFFICE_PROVIDER_PROFILE,
  BACK_OFFICE_PROVIDERS,
  BACK_OFFICE_SDK,
  BACK_OFFICE_USERS,
  ACL_GROUP,
  BACK_OFFICE_VIM_GUIDE_PROFILE,
  BACK_OFFICE_ELIGIBILITY,
  BACK_OFFICE_SIU_MESSAGES,
  BACK_OFFICE_NAMESPACES,
  BACK_OFFICE_ASO_GROUPS,
  BACK_OFFICE_PHI_ACCESS,
} from '../../../../model/enum/aclResourceType';

import './sidebar.less';

function Sidebar() {
  return (
    <div className="backoffice-sidebar">
      <ul className="sidebar-menu">
        <AclGuard type={BACK_OFFICE_PROVIDERS}>
          <li>
            <Link to="/secure/providers">Providers</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_USERS}>
          <li>
            <Link to="/secure/users">Users</Link>
          </li>
        </AclGuard>
        <AclGuard type={ACL_GROUP}>
          <li>
            <Link to="/secure/userGroups">User Groups</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/domainMember">Domain Members</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/communication">Communication</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/reviews">Reviews</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_HEALTH_SYSTEM}>
          <li>
            <Link to="/secure/healthSystems">Health systems</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_CLINICS}>
          <li>
            <Link to="/secure/clinics">Clinics</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/schedulingCSI">Scheduling CSI</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/appointmentRequests">Appointment Requests</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/productAppointments">Appointments</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PROVIDER_PROFILE}>
          <li>
            <Link to="/secure/providerProfile">Provider Profile</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_VIM_GUIDE_PROFILE}>
          <li>
            <Link to="/secure/vimGuideProfile">Vim Guide Profile</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_MEDICAL_CODES}>
          <li>
            <Link to="/secure/medicalCodes">Medical Codes</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_FEATURES}>
          <li>
            <Link to="/secure/features">Features</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_SDK}>
          <li>
            <Link to="/secure/apiKeys">API Keys</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_ELIGIBILITY}>
          <li>
            <Link to="/secure/eligibility">Eligibility</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_SIU_MESSAGES}>
          <li>
            <Link to="/secure/siuMessage">HL-7</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_NAMESPACES}>
          <li>
            <Link to="/secure/namespaceManager">Namespaces</Link>
          </li>
          </AclGuard>
        <AclGuard type={BACK_OFFICE_ASO_GROUPS}>
          <li>
            <Link to="/secure/asoGroups">Aso Groups</Link>
          </li>
        </AclGuard>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS}>
          <li>
            <Link to="/secure/referrals">Referrals</Link>
          </li>
        </AclGuard>
      </ul>
    </div>
  );
}

export default Sidebar;
