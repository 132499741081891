import React from 'react';
import { get } from 'lodash';
import { compose, withHandlers, withState } from 'recompose';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field, Form } from 'formik';
import { object, string, boolean, ValidationError } from 'yup';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import { back } from 'Components/wizard/flow/results';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import { ehrServicesAsArray } from '../../ehrServices';
import virtualSchedulerService from '../../ehrServices/services/puppeteer-vs-adapter';
import steps from './index';

const messages = defineMessages({
  bookingType: {
    defaultMessage: 'Supported booking types',
    id: 'backpack.createNamespaceModal.bookingTypes',
  },
  enterName: {
    defaultMessage: 'Enter name',
    id: 'backpack.createNamespaceModal.enterName',
  },
  ehrAdapter: {
    defaultMessage: 'Select EHR Adapter',
    id: 'backpack.createNamespaceModal.ehrAdapter',
  },
  solutions: {
    defaultMessage: 'Choose solutions',
    id: 'backpack.createNamespaceModal.solutions',
  },
});
/**
 * Note that the value object has properties with kebab-case
 */
const schema = object()
  .shape({
    namespaceName: string()
      .matches(/^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/) // kebab case regex
      .min(2)
      .max(20)
      .required('Service name required, in kebab case format'),
    referralGuidance: boolean(),
    scheduling: boolean(),
    instant: boolean(),
    rtb: boolean(),
    overrideRtbByEhr: boolean(),
    ehr: object({
      label: string(),
      value: string(),
    })
      .when('instant', {
        is: true,
        then: object({
          label: string().required(),
          value: string().required(),
        }).required('For instant book you must select an EHR adapter'),
      })
      .when('overrideRtbByEhr', {
        is: true,
        then: object({
          label: string().required(),
          value: string().required(),
        }).required('For RTB with EHR integration you must select an EHR adapter'),
      }),
    ehrName: string(),
  })
  .test('bookingType', undefined, value => {
    if (!value.instant && !value.rtb && value.scheduling)
      return new ValidationError('one of the booking types are required', undefined, 'bookingType');
    return true;
  })
  .test('ehrName', undefined, value => {
    if (value.ehr.label === virtualSchedulerService.label && !value.ehrName)
      return new ValidationError('ehr name is required for VS adapter', undefined, 'ehrName');
    return true;
  });

const NamespaceInfoForm = ({
  nextStep,
  schedulingOpened,
  setSchedulingOpened,
  isRtbChecked,
  setIsRtbChecked,
}) => (
  <WrappedFormik
    enableReinitialize
    onSubmit={nextStep}
    validationSchema={schema}
    render={({ isValid, errors, values }) => (
      <Form className="namespace-input-form">
        <div className="row namespace-name-input">
          <Field
            id="namespaceName"
            name="namespaceName"
            label="Namespace name"
            component={CleanFormikInput}
            autoFocus
          />
        </div>
        <div className="row">
          <div className="col-xs-12 text-16 text-center">
            <h4>
              <FormattedMessage {...messages.solutions} />
            </h4>
          </div>
        </div>
        <div
          id="solutions"
          name="solutions"
          className={classNames('checkbox-list', { 'has-error': errors.solutions })}
        >
          <Field
            id="patientChart"
            name="patientChart"
            label="Patient Chart"
            component={CleanFormikCheckbox}
          />
          <Field
            id="scheduling"
            name="scheduling"
            label="Scheduling"
            onChange={checked => setSchedulingOpened(checked)}
            component={CleanFormikCheckbox}
          />
          {errors.solutions ? <span className="validation-message">{errors.solutions}</span> : null}
        </div>
        <div id="scheduling-settings" hidden={!schedulingOpened}>
          <div className="row">
            <div className="col-xs-12 text-16 text-center">
              <h4>
                <FormattedMessage {...messages.bookingType} />
              </h4>
            </div>
          </div>
          <div className={classNames('checkbox-list', { 'has-error': errors.bookingType })}>
            <Field id="instant" name="instant" label="instant" component={CleanFormikCheckbox} />
            <Field
              id="rtb"
              name="rtb"
              label="request"
              component={CleanFormikCheckbox}
              onChange={checked => setIsRtbChecked(checked)}
            />
            {errors.bookingType ? (
              <span className="validation-message">{errors.bookingType}</span>
            ) : null}
          </div>
          {isRtbChecked && (
            <div className="checkbox-list configuration-margin">
              <Field
                id="overrideRtbByEhr"
                name="overrideRtbByEhr"
                label="Override clinic E-mailing and manage by EHR?"
                component={CleanFormikCheckbox}
              />
            </div>
          )}
          <div className="row">
            <div className="col-xs-12 text-16 text-center">
              <h4>
                <FormattedMessage {...messages.ehrAdapter} />
              </h4>
            </div>
          </div>
          <div className="column">
            <Field
              id="ehr"
              name="ehr"
              label="EHR Adapter"
              options={ehrServicesAsArray.map(service => ({
                label: service.label,
                value: service.id,
              }))}
              hotOptions
              component={CleanFormikSelect}
            />
          </div>
          {values.ehr && values.ehr.label === virtualSchedulerService.label && (
            <div className="column">
              <Field
                id="ehrName"
                name="ehrName"
                label="EHR Name"
                options={Object.keys(virtualSchedulerService.schemaByEhr).map(ehrName => ({
                  label: ehrName,
                  value: ehrName,
                }))}
                hotOptions
                component={CleanFormikSelect}
              />
            </div>
          )}
        </div>
        <Button
          type="submit"
          className={`btn btn-big btn-min-100 btn-block ${
            isValid ? 'bg-color-brand-button' : 'bg-color-brand-accent'
          }`}
        >
          {values.ehr ? 'Next' : 'Create'}
        </Button>
      </Form>
    )}
  />
);

const enhanced = compose(
  withHandlers({
    nextStep: ({ control }) => values => {
      control.next({ payload: values });
    },
  }),
  withState('schedulingOpened', 'setSchedulingOpened', false),
  withState('isRtbChecked', 'setIsRtbChecked', false),
)(NamespaceInfoForm);

export default {
  component: enhanced,
  transitions: {
    NEXT: ({ data }) => {
      if (data.ehr) return steps.EHR_FORM;
      return steps.CREATE_NAMESPACE;
    },
    PREV: back,
  },
  mapFlowDataToProps: () => ({}),
  mapPayloadToFlowData: values => ({
    ...values,
    ehr: get(values, 'ehr.value'),
    ehrName: get(values, 'ehrName.value'),
  }),
};
