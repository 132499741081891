import Joi from 'joi-browser';
import _ from 'lodash';
import {
  ReduxFormCleanCheckbox,
  ReduxFormCleanInput,
  ReduxFormCleanSelect,
} from '../../../../../components/form/reduxForm/components';
import * as AppointmentRequestType from '../../../../../model/enum/appointmentRequestType';
import * as AppointmentRequestStatus from '../../../../../model/enum/appointmentRequestStatus';

export const name = 'backoffice-create-appointment-request';

export const fields = {
  appointmentRequestType: {
    name: 'appointmentRequestType',
    component: ReduxFormCleanInput,
    options: _.map(AppointmentRequestType, (value, label) => ({
      value,
      label,
    })),
  },
  status: {
    options: _.map(AppointmentRequestStatus, (value, label) => ({
      value,
      label,
    })),
    name: 'status',
    component: ReduxFormCleanSelect,
  },
  reasonForVisit: {
    name: 'reasonForVisit',
    readOnly: true,
  },
  preferences: {
    name: 'preferences',
  },
  domainMemberId: {
    name: 'domainMemberId',
    readOnly: true,
  },
  domain: {
    name: 'domain',
    readOnly: true,
  },
  member: false,
  provider: false,
  appointment: false,
  appointmentId: false,
  requestedLocationId: false,
  isConcierge: {
    name: 'isConcierge',
    id: 'isConcierge',
    parse: value => !!value,
    component: ReduxFormCleanCheckbox,
  },
  requestExpeditedAppointment: {
    name: 'requestExpeditedAppointment',
    id: 'requestExpeditedAppointment',
    component: ReduxFormCleanCheckbox,
    parse: value => !!value,
  },
  sdkPermission: {
    component: ReduxFormCleanInput,
    name: 'sdkPermission',
    parse: value => _.toNumber(value),
  },
  createdAt: {
    className: 'margin-top-50',
  },
};

export const schema = {
  id: Joi.number().required(),
  appointmentRequestType: Joi.string().required(),
  status: Joi.string().required(),
  reasonForVisit: Joi.string()
    .allow('', null)
    .optional(),
  preferences: Joi.string().required(),
  domainMemberId: Joi.string().required(),
  domain: Joi.string().required(),
  isConcierge: Joi.boolean()
    .allow(null)
    .optional(),
  sdkPermission: Joi.number()
    .allow(null)
    .optional(),
};
