/**
 * created by moran on 11/28/17
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';

import Action from '../components/action';
import * as selectors from '../../../../store/backoffice/medicalCodes/selectors';
import * as UpdateCodeFormDefs from './actions/updateCodeFormDefs';
import EntityForm from '../components/entityForm/entityForm';
import {
  getMedicalCodeById,
  updateMedicalCode,
  DELETE_MEDICAL_CODE,
  deleteMedicalCode,
} from '../../../../store/backoffice/medicalCodes/actions';

@autobind
class UpdateMedicalCodePage extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired, // from router
    params: PropTypes.object.isRequired, // from router
    medicalCode: PropTypes.object.isRequired, // from redux
    getMedicalCodeById: PropTypes.func.isRequired, // from redux
    updateMedicalCode: PropTypes.func.isRequired, // from redux
    deleteMedicalCode: PropTypes.func.isRequired, // from redux
  };

  componentWillMount() {
    const { key, type } = this.props.params;
    this.props.getMedicalCodeById(key, type);
  }

  toMedicalCodes() {
    this.props.router.push('/secure/medicalCodes/');
  }

  deleteMedicalCode() {
    const {
      params: { key, type },
      deleteMedicalCode,
    } = this.props;
    deleteMedicalCode(key, type);
  }

  render() {
    const { medicalCode, updateMedicalCode } = this.props;
    return (
      <div>
        <div className="backoffice-action">
          <Action
            name="deleteMedicalCode"
            title="Delete Medical Code"
            actionTrackerId={DELETE_MEDICAL_CODE.SOURCE}
            onSubmit={this.deleteMedicalCode}
            onActionSuccess={this.toMedicalCodes}
          />
        </div>
        <EntityForm
          name={UpdateCodeFormDefs.name}
          title="Update Medical Code:"
          schemaDef={UpdateCodeFormDefs.schema}
          fieldsDef={UpdateCodeFormDefs.fields}
          entity={medicalCode}
          onSubmit={updateMedicalCode}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      medicalCode: selectors.getMedicalCode(state),
    }),
    { getMedicalCodeById, updateMedicalCode, deleteMedicalCode },
  ),
)(UpdateMedicalCodePage);
