/**
 * created by moran on 12/4/17
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_APPOINTMENT_TYPES_LIST,
  UPDATE_APPOINTMENT_TYPE,
  GET_APPOINTMENT_TYPE,
  CREATE_BULK_APPOINTMENT_TYPE,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  details: undefined,
  bulkAppointmentTypes: { success: [], failed: [] },
};

export default combineReducers({
  list: reduceReducers(
    genericReducer(GET_APPOINTMENT_TYPES_LIST, initialState.list),
  ),
  details: reduceReducers(
    genericReducer(UPDATE_APPOINTMENT_TYPE, initialState.details),
    genericReducer(GET_APPOINTMENT_TYPE, initialState.details),
  ),
  bulkAppointmentTypes: reduceReducers(
    genericReducer(CREATE_BULK_APPOINTMENT_TYPE, initialState.bulkAppointmentTypes),
  ),
});
