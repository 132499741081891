/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by chenrozenes on 25/04/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import {
  BACK_OFFICE_USERS,
  BACK_OFFICE_ACL,
  ACL_GROUP,
} from '../../../../model/enum/aclResourceType';
import UsersList from './usersList';
import UserPage from './userPage';
import UsersGroupPage from './userGroupPage';
import permissionsPage from './permissionsPage';
import GroupsList from './groupsList';
import userPermissionsViewModal from './userPermissionViewModal';
import groupPermissionsViewModal from './groupPermissionsViewModal';

const divComponent = props => <div>{props.children}</div>;
const PermissionsPage = permissionsPage.default || permissionsPage;

export const UsersRoutes = {
  path: 'users',
  name: 'users',
  childRoutes: [
    {
      path: ':id',
      name: 'userPage',
      component: UserPage.default || UserPage,
    },
    {
      path: ':id/permissions',
      name: 'userPermissions',
      component: requireAutherization({
        resourceType: BACK_OFFICE_ACL,
      })(props => <PermissionsPage viewModal={userPermissionsViewModal} {...props} />),
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_USERS })(divComponent),

  indexRoute: {
    name: 'usersList',
    component: UsersList.default || UsersList,
  },
};

export const UserGroupsRoute = {
  path: 'userGroups',
  name: 'userGroups',
  childRoutes: [
    {
      path: ':id',
      name: 'userGroupPage',
      component: UsersGroupPage.default || UsersGroupPage,
    },
    {
      path: ':id/permissions',
      name: 'groupPermissions',
      component: requireAutherization({
        resourceType: BACK_OFFICE_ACL,
      })(props => <PermissionsPage viewModal={groupPermissionsViewModal} {...props} />),
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: ACL_GROUP })(divComponent),

  indexRoute: {
    name: 'userGroupsList',
    component: GroupsList.default || GroupsList,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
