import auth0 from 'auth0-js';
import config from '../config';

export const auth0Client = new auth0.WebAuth({
  domain: config.AUTH0_DOMAIN,
  clientID: config.AUTH0_CLIENT_ID,
});

export const auth0Logout = () =>
  auth0Client.logout({
    clientID: config.AUTH0_CLIENT_ID,
    returnTo: window.location.origin,
  });
