import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, pure, withProps } from 'recompose';

import CleanCheckbox from '../../../../../components/form/cleanCheckbox';
import injectNotification from '../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import BkmdModelButton from '../../../../../components/bkmdModalButton/bkmdModalButton';
import Apis from '../../../../../api';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';

function DeleteGroupFormView({ deleteConfirmOn, deleteConfirmToggle, onSubmit, onSubmitTracker }) {
  return (
    <div>
      <div className="col-xs-12">
        <CleanCheckbox
          label="Are you sure you want to delete this user?"
          id="deleteUserGroup"
          value={deleteConfirmOn}
          onChange={deleteConfirmToggle}
        />
      </div>
      <div className="col-xs-12">
        <SpinnerButton
          className="btn btn-big btn-red btn-min-100 btn-blue margin-top-20"
          disabled={!deleteConfirmOn}
          onClick={onSubmit}
          isLoading={onSubmitTracker.inProgress}
        >
          Delete user
        </SpinnerButton>
      </div>
    </div>
  );
}

DeleteGroupFormView.propTypes = {
  userId: PropTypes.number.isRequired,
  ...createToggleStatePropTypes('deleteConfirm'),
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object,
  notification: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

DeleteGroupFormView.defaultProps = {
  onSubmitTracker: {},
};

export const DeleteUserForm = compose(
  createToggleState('deleteConfirm', false),
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: ({ deleteConfirmOn, userId }) => () => {
        if (!deleteConfirmOn) return null;
        return Apis.usersApi.deleteUser(userId);
      },
      onSuccess: ({ onSuccess, notification, closeModal }) => {
        notification.success('Delete user', 'User deleted successfully');
        closeModal();
        onSuccess();
      },
      onError({ notification }) {
        notification.error('Delete user', 'Could not delete user due to unexpected error');
      },
    },
  }),
)(DeleteGroupFormView);

export default compose(
  setDisplayName('DeleteUserAction'),
  pure,
  withProps({
    title: 'Delete User',
    buttonText: 'Delete User',
    component: DeleteUserForm,
    btnClassName: 'btn-small btn-red text-semibold action-button',
  }),
)(BkmdModelButton);
