import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { string, ref, object } from 'yup';
import { compose } from 'recompose';
import { Form, Field } from 'formik';
import { defineMessages, FormattedMessage } from 'react-intl';

import { WrappedFormik } from '../../components/form/formik/wrappedFormik';
import { CleanFormikPasswordInput } from '../../components/form/formik/cleanFormikPasswordInput';
import { CleanFormikRepeatPasswordInput } from '../../components/form/formik/cleanFormikRepeatPasswordInput';
import withPush from '../../components/router/withPush';
import queryConnect from '../../store/tools/queryString/queryConnect';
import injectNotification from '../../store/notification/injectNotification';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import withFeatureFlag from '../../utils/featureFlags/withFeatureFlag';
import { withFetchers } from '../../api/injectApi/withFetchers';
import * as Errors from '../../api/errors';
import Api from '../../api';

const HTTP_PREFIX = 'http://';
const HTTPS_PREFIX = 'https://';

const schema = object().shape({
  password: string()
    .nullable(false)
    .required(),
});

const withFeatureSchema = object().shape({
  password: string()
    .nullable(false)
    .required(),
  repeatPassword: string()
    .nullable(false)
    .required()
    .oneOf([ref('password'), null], 'Passwords are not identical'),
});

export const messages = defineMessages({
  makeItAGoodOne: {
    defaultMessage: 'Make it a good one',
    id: 'step.password.makeItAGoodOne',
  },
  resetMyPassword: {
    defaultMessage: 'Reset my password',
    id: 'step.password.resetMyPassword',
  },
  yourPassword: {
    defaultMessage: 'Your password',
    id: 'step.password.yourPassword',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'step.password.repeatPassword',
  },
  chooseANewPassword: {
    defaultMessage: 'Choose a new password',
    id: 'step.password.chooseANewPassword',
  },
  serverErrorTitle: {
    defaultMessage: 'Sorry, an error was occurred',
    id: 'step.password.error.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'step.password.error.serverErrorMessage',
  },
  weakPasswordErrorTitle: {
    defaultMessage: 'The password you entered is too weak',
    id: 'step.password.error.weakPasswordErrorTitle',
  },
  weakPasswordErrorMessage: {
    defaultMessage: 'Try another one',
    id: 'step.password.error.weakPasswordErrorMessage',
  },
  passwordWasInUseTitle: {
    defaultMessage: 'This password has been used already',
    id: 'step.password.error.passwordWasInUseTitle',
  },
  passwordWasInUseMessage: {
    defaultMessage: 'Try another one',
    id: 'step.password.error.passwordWasInUseMessage',
  },
  successTitle: {
    defaultMessage: 'Your password is updated',
    id: 'step.password.resetPassword.succeeded',
  },
  succeededMessage: {
    defaultMessage: 'You can sign in now with your new password.',
    id: 'step.password.resetPassword.succeededMessage',
  },
});

const Password = ({ resetPassword, newPasswordValidationFeature }) => (
  <div>
    <WrappedFormik
      initialValues={{}}
      onSubmit={resetPassword}
      validationSchema={newPasswordValidationFeature ? withFeatureSchema : schema}
      render={({ isValid }) => (
        <Form>
          <div>
            <div className="row">
              <div className="col-xs-12 text-center">
                <span className="text-18 text-light">
                  <FormattedMessage {...messages.makeItAGoodOne} />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 margin-top">
                <Field
                  type="tel"
                  label={messages.yourPassword}
                  name="password"
                  component={CleanFormikPasswordInput}
                  withFeatureFlag={newPasswordValidationFeature}
                  verifyPasswordField={
                    <div>
                      <Field
                        label={messages.repeatPassword}
                        className="password-input"
                        name="repeatPassword"
                        component={CleanFormikRepeatPasswordInput}
                      />
                    </div>
                  }
                  autoFocus
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 margin-top">
                <button
                  className="btn btn-big bg-color-brand-button"
                  type="submit"
                  disabled={!isValid}
                >
                  <FormattedMessage {...messages.resetMyPassword} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  </div>
);

Password.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

export default compose(
  queryConnect(query => ({
    next: query.next,
  })),
  injectNotification,
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
  withTranslatedMessages(messages),
  withPush,
  withFetchers({
    resetPassword: {
      handler: ({ identifier, verificationCode }) => ({ password }) =>
        Api.authApi.resetPassword(identifier, password, verificationCode),
      onSuccess: ({ notification, next, pushFunc }) => {
        // Try login after resetting the password
        notification.success(messages.successTitle, messages.succeededMessage);
        // this was added to support sending users from Atlas to Compass to reset their passwords
        if (next && typeof window !== 'undefined') {
          // most of the times the value of "next" is a route in our app so it has no http/s prefix
          // but when we want to send the user to an external url (like when we return them to Atlas
          // when changing their password) it has a http/s prefix.
          if (_.startsWith(next, HTTP_PREFIX) || _.startsWith(next, HTTPS_PREFIX)) {
            window.location.href = next;
          } else {
            pushFunc(next);
          }
        } else {
          pushFunc('/');
        }
      },
      onError: ({ notification }, error) => {
        const { responseMessage } = error;
        switch (responseMessage) {
          case Errors.WEAK_PASSWORD: {
            notification.error(messages.weakPasswordErrorTitle, messages.weakPasswordErrorMessage);
            break;
          }
          case Errors.PASSWORD_WAS_IN_USE: {
            notification.error(messages.passwordWasInUseTitle, messages.passwordWasInUseMessage);
            break;
          }
          default: {
            notification.error(messages.serverErrorTitle, messages.serverErrorMessage);
          }
        }
      },
    },
  }),
)(Password);
