import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import NavigationLayout from '../../../components/templates/NavigationLayout/navigationLayout';
import VerificationCodeForm from '../verificationCode';
import withGoBack from '../../../components/router/withGoBack';

const messages = defineMessages({
  enterYourCode: {
    defaultMessage: 'Enter your code',
    id: 'members.auth.verificationCode.enterYourCode'
  },
});

const NavigationLayoutWrappedVerificationCode = props => (
  <NavigationLayout {...props} title={messages.enterYourCode}>
    <VerificationCodeForm {...props} />
  </NavigationLayout>
);

NavigationLayoutWrappedVerificationCode.propTypes = {
  onBack: PropTypes.func.isRequired,
  withHeader: PropTypes.bool,
  caption: PropTypes.string,
  showLogos: PropTypes.bool,
  progress: PropTypes.bool,
  img: PropTypes.element,
  showRightNavInMobile: PropTypes.bool,
};

NavigationLayoutWrappedVerificationCode.defaultProps = {
  withHeader: false,
  onBack: undefined,
  caption: undefined,
  showLogos: false,
  progress: null,
  img: undefined,
  showRightNavInMobile: false,
};

export default compose(
  withGoBack,
  withProps(({ control, goBackFunc }) => ({
    onBack: control.hasPrev() ? control.prev : goBackFunc,
  })),
)(NavigationLayoutWrappedVerificationCode);
