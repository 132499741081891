/* eslint-disable import/first, react/jsx-filename-extension */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAccess from 'Store/auth/limitedAccessComponent';
import { BACK_OFFICE_SDK } from 'Model/enum/aclResourceType';
import ApiKeysPage from './components/apiKeysPage/apiKeysPage';
import UpdateApiKey from './components/updateApiKey';
import CreateApiKey from './components/createApiKey';
import UpdateApiTheme from './components/updateApiTheme';
import CreateApiTheme from './components/createApiTheme';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'apiKeys',
  name: 'API Keys',
  childRoutes: [
    {
      path: 'create',
      name: 'create',
      component: CreateApiKey.default || CreateApiKey,
    },
    {
      path: ':id',
      name: 'update',
      component: UpdateApiKey.default || UpdateApiKey,
    },
    {
      path: 'themes',
      name: 'themes',
      childRoutes: [
        {
          path: 'create',
          name: 'create',
          component: CreateApiTheme.default || CreateApiTheme,
        },
        {
          path: ':name',
          name: 'update',
          component: UpdateApiTheme.default || UpdateApiTheme,
        },
      ],
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAccess({ resourceType: BACK_OFFICE_SDK })(divComponent),

  indexRoute: {
    name: 'sdkPermissionsList',
    component: ApiKeysPage.default || ApiKeysPage,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
