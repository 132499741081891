import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import BkmdModalButton from 'Components/bkmdModalButton/bkmdModalButton';
import injectNotification from 'Store/notification/injectNotification';
import _ from 'lodash';
import { array, object, string } from 'yup';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { calculateCSVFileOptionsFields } from 'Utils/files';
import { Field, Form } from 'formik';
import FormikCSVReader from 'Components/form/formik/FormikCsvReader';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { FormikToggleButton } from 'Components/form/formik/formikToggleButton';
import FlaggedRender from 'Utils/FlaggedRender';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import SpinnerButton from 'Components/ui/spinner/spinnerButton';
import { withStateFetchers } from '../../../../../../api/injectApi/withStateFetchers';

const scheme = object().shape({
  csvRows: array()
    .min(1, 'CSV file must have rows')
    .required(''),
  npiField: string().required('You must choose a npi field'),
});

const initialValues = {
  hasHeaders: true,
  isWhitelist: true,
  csvRows: undefined,
  options: [],
  npiField: '',
};

const LoadSearchPreferencesView = ({ onSubmit }) => (
  <WrappedFormik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={scheme}
    render={({ values: { csvRows, hasHeaders }, isValid, isSubmitting }) => {
      const options = calculateCSVFileOptionsFields(hasHeaders, csvRows);
      return (
        <Form>
          <div style={{ 'margin-bottom': '2px' }}>
            <span>
              This action will delete the selected option (whitelist or blacklist) and set the
              values from the csv instead
            </span>
            <Field
              name="isWhitelist"
              leftLabel="whitelist"
              rightLabel="blacklist"
              component={FormikToggleButton}
              readOnly
            />
          </div>
          <Field inputId="csvRows" name="csvRows" component={FormikCSVReader} showInitialError />
          <Field
            id="hasHeaders"
            name="hasHeaders"
            label="has headers?"
            component={CleanFormikCheckbox}
          />
          <FlaggedRender shouldRender={options.length > 0}>
            {() => (
              <div className="col-xs-12">
                <Field
                  name="npiField"
                  placeholder="npi field"
                  options={options}
                  component={CleanFormikSelect}
                  hotOptions
                  searchable
                />
              </div>
            )}
          </FlaggedRender>
          <SpinnerButton
            className="btn btn-big btn-min-100 bg-color-brand-button"
            disabled={!isValid}
            isLoading={isSubmitting}
            type="submit"
          >
            Run Action
          </SpinnerButton>
        </Form>
      );
    }}
  />
);

LoadSearchPreferencesView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const LoadSearchPreferencesFromCsv = compose(
  pure,
  injectNotification,
  withStateFetchers({
    loadSearchPreferencesList: {
      handler: ({ resourceId, setSearchPreferences }) => (isWhitelist, list) =>
        setSearchPreferences(resourceId, isWhitelist, list),
      onSuccess: ({ notification, closeModal, onSuccess }, res) => {
        notification.success(
          'search preferences were created',
          `${res.length} search preferences were created`,
        );
        onSuccess();
        closeModal();
      },
      onError: ({ notification, closeModal }) => {
        notification.error(
          'Failed to load search preferences from csv',
          'an error occured while uploading the csv',
        );
        closeModal();
      },
    },
  }),
  withHandlers({
    onSubmit: ({ loadSearchPreferencesList }) => ({
      hasHeaders,
      isWhitelist,
      csvRows,
      npiField,
    }) => {
      const rows = hasHeaders ? _.tail(csvRows) : csvRows;
      const list = _.map(rows, element => ({
        npi: element[npiField.value],
      }));
      loadSearchPreferencesList(isWhitelist, list);
    },
  }),
)(LoadSearchPreferencesView);

export default withProps({
  title: 'load search preferences from CSV',
  component: LoadSearchPreferencesFromCsv,
})(BkmdModalButton);
