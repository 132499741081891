import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_APPOINTMENT,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_ALL_APPOINTMENTS_BY_CALENDAR,
} from './actions';

const initialState = {
  selectedlist: {
    count: 0,
    data: [],
  },
  details: {},
  raw: {},
  permissions: [],
};

export default combineReducers({
  selectedlist: reduceReducers(
    genericReducer(GET_ALL_APPOINTMENTS_BY_CALENDAR, initialState.selectedlist),
  ),
  details: reduceReducers(
    genericReducer(GET_APPOINTMENT, initialState.details),
    genericReducer(UPDATE_APPOINTMENT, initialState.details),
    genericReducer(CREATE_APPOINTMENT, initialState.details),
    genericReducer(DELETE_APPOINTMENT, initialState.details),
  ),
});
