/**
 * created by moran on 1/14/18
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';
import {
  ACCEPTED,
  REQUESTED,
  CHECKED_IN,
  ARCHIVED,
  PENDING_VERIFICATION,
} from './appointmentStatus';

export const messages = defineMessages({
  accepted: {
    defaultMessage: 'Accepted',
    id: 'appointmentStatus.accepted',
  },
  requested: {
    defaultMessage: 'Requested',
    id: 'appointmentStatus.requested',
  },
  canceled: {
    defaultMessage: 'Canceled',
    id: 'appointmentStatus.canceled',
  },
  checked_in: {
    defaultMessage: 'Checked in',
    id: 'appointmentStatus.checked_in',
  },
  archived: {
    defaultMessage: 'Archived',
    id: 'appointmentStatus.archived',
  },
  pendingVerification: {
    defaultMessage: 'Pending Verification',
    id: 'appointmentStatus.pendingVerification',
  },
});

export default new IntlEnum(
  { value: ACCEPTED, message: messages.accepted },
  { value: REQUESTED, message: messages.requested },
  { value: CHECKED_IN, message: messages.checked_in },
  { value: ARCHIVED, message: messages.archived },
  { value: PENDING_VERIFICATION, message: messages.pendingVerification },
);
