/**
 * created by kobi on 11/29/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import withRowData from 'Components/withRowData/withRowData';

const ActionLinkCell = ({ rowData, cellProperties: { onClick, propertyKey } }) => (
  <div
    className="btn btn-inline-link font-color-brand-link"
    onClick={e => {
      e.stopPropagation();
      return onClick(rowData);
    }}
  >
    {rowData[propertyKey]}
  </div>
);

ActionLinkCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  cellProperties: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    propertyKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRowData(ActionLinkCell);
