/* eslint-disable react/prop-types */
import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import map from 'lodash/map';
import entries from 'lodash/entries';
import { back } from '../../../../../../../components/wizard/flow/results';
import GridPicker from '../../../../../../../storybook/src/components/molecules/GridPicker/GridPicker';
import CommonInvestigation from './commonInvestigation';
import steps from '../index';

const investigationToStepMap = {
  [steps.RAW_DATA]: {
    display: 'Raw Data',
  },
  [steps.NAMESPACE_INVESTIGATION]: {
    display: 'Namespace',
  },
};

const InvestigationPicker = ({ options, onClick, providerCSI }) => (
  <div>
    <CommonInvestigation providerCSI={providerCSI} />
    <GridPicker options={options} onClick={onClick} />
  </div>
);

const enhanced = compose(
  withProps({
    options: map(entries(investigationToStepMap), entry => ({
      value: entry[0],
      display: entry[1].display,
    })),
  }),
  withHandlers({
    onClick: ({ control }) => option => {
      control.next({ payload: option });
    },
  }),
)(InvestigationPicker);

export default {
  component: enhanced,
  transitions: {
    NEXT: ({ data: { pickedInvestigation } }) => pickedInvestigation,
    PREV: back,
  },
  mapFlowDataToProps: ({ providerCSI }) => ({ providerCSI }),
  mapPayloadToFlowData: pickedInvestigation => ({ pickedInvestigation }),
};
