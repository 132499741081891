/**
 * created by moran on 11/28/17
 */
/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by chenrozenes on 25/04/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_MEDICAL_CODES } from '../../../../model/enum/aclResourceType';
import medicalCodeList from './medicalCodeList';
import medicalCodePage from './medicalCodePage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'medicalCodes',
  name: 'medicalCodes',
  childRoutes: [
    {
      path: ':type/:key',
      name: 'medicalCodePage',
      component: medicalCodePage.default || medicalCodePage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_MEDICAL_CODES })(divComponent),

  indexRoute: {
    name: 'medicalCodes',
    component: medicalCodeList.default || medicalCodeList,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
