import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, Form } from 'formik';
import { object, string } from 'yup';

import { BkmdGridColumn } from '../../../../../../components/grid';
import QueryStringGrid from '../../../../../../components/grid/queryStringGrid';
import { CleanFormikInput } from '../../../../../../components/form/formik/cleanFormikInput';
import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import DeleteActionButtonCell from '../../../components/deleteActionButtonCell';

import injectNotification from '../../../../../../store/notification/injectNotification';

import Api from '../../../../../../api';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';

const scheme = object().shape({
  identifier: string()
    .label('Identifier')
    .required(),
});

const ManageAutoCreatedTimeSlotsList = ({
  autoTimeSlotsData: { data, count },
  deleteByIdentifier,
  addByIdentifier,
}) => (
  <div>
    <WrappedFormik
      onSubmit={addByIdentifier}
      validationSchema={scheme}
      render={({ isValid }) => (
        <Form>
          <div className="row margin-top-30">
            <div className="col-xs-3">
              <Field
                name="identifier"
                label="Identifier"
                component={CleanFormikInput}
                inputClassName="margin-45"
              />
            </div>
            <div className="col-xs-8">
              <Button type="small" disabled={!isValid} text="Add" />
            </div>
          </div>
        </Form>
      )}
    />
    <QueryStringGrid
      data={data}
      totalCount={count}
      showHeader={false}
      showPagination={false}
      classNames={{ Table: 'bkmd-table auto-layout' }}
    >
      <BkmdGridColumn id="id" title="Id" sortField="id" />
      <BkmdGridColumn id="identifier" title="Resource Identifier" sortField="identifier" />
      <BkmdGridColumn
        id="deleteButton"
        title="Delete"
        customComponent={DeleteActionButtonCell}
        onClick={deleteByIdentifier}
        sortable={false}
        headerCssClassName="bkmd-th delete-col"
        cssClassName="bkmd-td delete-td text-left"
      />
    </QueryStringGrid>
  </div>
);

ManageAutoCreatedTimeSlotsList.propTypes = {
  addByIdentifier: PropTypes.func.isRequired,
  deleteByIdentifier: PropTypes.func.isRequired,
  autoTimeSlotsData: PropTypes.object.isRequired,
};

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getTimeSlotsAutoCreationList: {
      handler: () => () => Api.clinicResourceApi.getAllAutoTimeSlotsIdentifiers(),
      isReady: ({ autoTimeSlotsData }) => !_.isEmpty(autoTimeSlotsData),
      resultPropName: 'autoTimeSlotsData',
    },
  }),
  withFetchers({
    deleteByIdentifier: {
      handler: () => ({ identifier }) =>
        Api.clinicResourceApi.deleteResourceFromAutoTimeSlotsList(identifier),
      onSuccess: ({ getTimeSlotsAutoCreationList }) => getTimeSlotsAutoCreationList(),
    },
    addByIdentifier: {
      handler: () => data => Api.clinicResourceApi.addResourceToAutoTimeSlotsList(data.identifier),
      onSuccess: ({ getTimeSlotsAutoCreationList }) => getTimeSlotsAutoCreationList(),
    },
  }),
)(ManageAutoCreatedTimeSlotsList);
