/**
 * Created by chenrozenes on 18/04/2017.
 */
import { createSelector } from 'reselect';
import _ from 'lodash';

export const getUsersCount = state => state.backoffice.users.list.count;
export const getUsersList = state => state.backoffice.users.list.data;
export const getUser = state => state.backoffice.users.details;
export const getDeleteUserFlag = state => state.config.users.enableDeleteUser;
export const getPermissionsCount = state => state.backoffice.users.permissions.count;
export const getPermissions = state => state.backoffice.users.permissions.data;
export const getPermissionWithGroupIndicator = createSelector(
  getPermissions,
  permissions =>
    _.map(permissions, permission => ({
      ...permission,
      isGroupPermission: !_.isNil(permission.groupId),
    })),
);
