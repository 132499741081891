/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import { name as formName } from './createUserFormDefs';
import CreateUserForm from './createUserForm';
import { createUser, CREATE_USER } from '../../../../../../store/backoffice/users/actions';

@autobind
class CreateUserAction extends React.PureComponent {
  static propTypes = {
    createUser: PropTypes.func.isRequired, // From redux-form
  };

  callAction(data) {
    this.props.createUser(data.email, data.password, data.phoneNumber);
  }

  render() {
    return (
      <Action
        name="createUser"
        title="Create User"
        formName={formName}
        actionTrackerId={CREATE_USER.SOURCE}
        {...this.props}
      >
        <CreateUserForm onSubmit={this.callAction} />
      </Action>
    );
  }
}

export default connect(
  null,
  { createUser },
)(CreateUserAction);
