/* eslint-disable react/prop-types */
import React from 'react';
import { compose, withHandlers } from 'recompose';
import withControl from 'Components/wizard/flow/withControl';
import './providerInvestigationFlow.less';
import steps from './steps/index';
import flowDef from './flowDef';
import Flow from '../../../../../components/wizard/flow/Flow';
import Button from '../../../../../storybook/src/components/atoms/Button/Button';

const csiImg = require('./scheduling-csi.png');

function ProviderInvestigationFlow() {
  return (
    <Flow
      name={flowDef.name}
      definitions={flowDef.definitions}
      initialStepName={steps.PROVIDER_INPUT}
    />
  );
}

function FlowWrapper({ goBack, control }) {
  return (
    <div className="scheduling-csi">
      <img src={csiImg} alt="csi" />
      {control.hasPrev() ? (
        <Button className="margin-top-10" type="small" onClick={goBack}>
          back
        </Button>
      ) : null}
      <div className="flowContainer">
        <ProviderInvestigationFlow />
      </div>
    </div>
  );
}

const enhanced = compose(
  withControl(flowDef.name),
  withHandlers({
    goBack: ({ control }) => () => control.prev({}),
  }),
)(FlowWrapper);
export default enhanced;
