import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import injectNotification from 'Store/notification/injectNotification';
import { withRouter } from 'react-router';
import Api from '../../../../../api';
import { generateApiKeyInClient, generateSecretApiKeyInClient } from '../generateApiKeys';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import ApiKeyFields, { API_KEY_FIELDS_MODE } from './apiKeyFields/apiKeysFields';
import { referralGuidanceFields } from './referralGuidanceConfiguration';
import { searchFields } from './searchConfiguration';

const apiKeys = generateApiKeyInClient();
const secretApiKey = generateSecretApiKeyInClient();

function CreateApiKey({ onSubmit }) {
  return (
    <div>
      <ApiKeyFields
        pageTitle="Create Api Key"
        onSubmit={onSubmit}
        initialValues={{
          apiKey: apiKeys.clientApiKey,
          serverApiKey: apiKeys.serverApiKey,
          secretApiKey: secretApiKey.secretApiKey,
        }}
        pageMode={API_KEY_FIELDS_MODE.CREATE}
        submitButtonText="Create"
      />
    </div>
  );
}

const createSdkPermissions = async fullPermission => {
  const { allowedHealthSystemIds, ...permissions } = fullPermission;
  const referralGuidanceConfiguration = _.pick(permissions, referralGuidanceFields);
  const searchConfiguration = _.pick(permissions, searchFields);
  const cleanPermissions = _.omit(permissions, [
    'secretApiKey',
    'schedulingConfiguration',
    ...searchFields,
    ...referralGuidanceFields,
  ]);

  const allowedResources = _.map(allowedHealthSystemIds, ({ value }) => ({
    ownerId: value,
    ownerType: 'healthSystem',
  }));

  const newPermissions = {
    ...cleanPermissions,
    secret: secretApiKey.secret,
    allowedResources,
    searchConfiguration,
  };

  const referralGuidanceNewPermissions = {
    apiKey: permissions.apiKey,
    newConfig: _.omit(referralGuidanceConfiguration, 'communicationDisplayName'),
  };

  await Api.sdkApi.createSdkPermission(newPermissions);
  await Api.sdkApi.updateReferralGuidanceConfig(referralGuidanceNewPermissions);
};

export default compose(
  withRouter,
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: () => createSdkPermissions,
      onSuccess({ router, notification }) {
        router.push('/secure/apiKeys');

        return notification.success('Success!', 'New api key created');
      },
      onError({ notification }, error) {
        console.error(`Failed to create API key!`, { error });

        return notification.error('Oops', 'Failed to create api key');
      },
    },
  }),
)(CreateApiKey);
