/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { compose } from 'redux';

import { defineMessages } from 'react-intl';
import * as CreateUserFormDefs from './createUserFormDefs';
import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';
import { withCreateUserForm } from './withCreateUserForm';

const messages = defineMessages({
  password: {
    defaultMessage: 'Password',
    id: 'backoffice.createUser.password',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'backoffice.createUser.repeatPassword',
  },
  phone: {
    defaultMessage: 'Phone Number',
    id: 'backoffice.createUser.phone',
  },
  email: {
    defaultMessage: 'Email',
    id: 'backoffice.createUser.email',
  },
});

function CreateUserForm({ handleSubmit, newPasswordValidationFeature, change }) {
  const onPasswordStrengthChanged = strength => {
    if (newPasswordValidationFeature) {
      change(CreateUserFormDefs.fields.passwordStrength.name, strength);
    }
  };

  return (
    <form onSubmit={handleSubmit} method="post">
      <div className="row">
        <div className="col-xs-12">
          <Field label={messages.email} {...CreateUserFormDefs.fields.email} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field
            label={messages.password}
            {...CreateUserFormDefs.fields.password}
            onStrengthChanged={onPasswordStrengthChanged}
            withFeatureFlag={newPasswordValidationFeature}
            verifyPasswordField={
              <div>
                <Field
                  label={messages.repeatPassword}
                  className="password-input"
                  {...CreateUserFormDefs.fields.repeatPassword}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label={messages.phone} {...CreateUserFormDefs.fields.phoneNumber} />
        </div>
      </div>
    </form>
  );
}

CreateUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // from redux-form
};

const fieldNames = [
  CreateUserFormDefs.fields.email.name,
  CreateUserFormDefs.fields.repeatPassword.name,
  CreateUserFormDefs.fields.password.name,
  CreateUserFormDefs.fields.phoneNumber.name,
];

export default compose(
  withCreateUserForm(fieldNames, CreateUserFormDefs.customValidator),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(CreateUserForm);
