import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  pure,
  setPropTypes,
  setDisplayName,
} from 'recompose';
import { withStateFetchers } from '../../../../api/injectApi/withStateFetchers';
import Api from '../../../../api';
import LocationVerification from '../../../../components/molecules/LocationVerification/LocationVerification';
import FlaggedRender from '../../../../utils/FlaggedRender';

const messages = defineMessages({
  details: {
    defaultMessage: ' (By {user}, {verifiedTime})',
    id: 'backpack.LocationVerification.details',
  },
});

const LocationVerificationDetails = ({ location, user, verifiedTime }) => (
  <div>
    <LocationVerification isVerified={location.isVerified} />
    <FlaggedRender shouldRender={user && verifiedTime}>
      {() => (
        <FormattedMessage
          {...messages.details}
          values={{
            user,
            verifiedTime,
          }}
        />
      )}
    </FlaggedRender>
  </div>
);

LocationVerificationDetails.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.string,
  verifiedTime: PropTypes.string,
};
LocationVerificationDetails.defaultProps = {
  user: undefined,
  verifiedTime: undefined,
};

export default compose(
  setDisplayName('LocationVerificationDetails'),
  setPropTypes({
    location: PropTypes.object.isRequired,
  }),
  pure,

  withStateFetchers({
    getUser: {
      handler: () => Api.usersApi.getUser,
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getUser, location } = this.props;
      if (location.verifiedUserId) getUser(location.verifiedUserId);
    },
  }),
  withPropsOnChange(['getUserResult', 'location'], ({ getUserResult, location }) => {
    const email = _.get(getUserResult, 'data.email', '');
    return {
      user: email.substr(0, email.indexOf('@')),
      verifiedTime: moment(location.verifiedTime).calendar(null, {
        lastWeek: 'dddd',
        sameElse: 'MMM DD',
      }),
    };
  }),
)(LocationVerificationDetails);
