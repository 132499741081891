/**
 * Created by chenrozenes on 03/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import { name as formName } from './removePermissionFormDefs';
import RemovePermissionForm from './removePermissionForm';
import {
  removePermission,
  REMOVE_CLINIC_MANAGEMENT_PERMISSION,
} from '../../../../../../store/backoffice/clinicManagement/permissions/actions';

@autobind
class RemovePermissionAction extends React.PureComponent {
  static propTypes = {
    removePermission: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
  };

  callAction(data) {
    this.props.removePermission(
      this.props.entityType,
      data.userId,
      data.resourceType,
      this.props.resourceId,
      data.isGroup,
    );
  }

  render() {
    return (
      <Action
        name="removePermission"
        title="Remove Permission"
        formName={formName}
        actionTrackerId={REMOVE_CLINIC_MANAGEMENT_PERMISSION.SOURCE}
        {...this.props}
      >
        <RemovePermissionForm onSubmit={this.callAction} {...this.props} />
      </Action>
    );
  }
}

export default connect(
  null,
  { removePermission },
)(RemovePermissionAction);
