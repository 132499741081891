/**
 * Created by chenrozenes on 14/11/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import CleanInput from '../../../../../components/form/cleanInput';
import CleanDomainSelect from '../../../../../components/form/cleanDomainSelect';

@autobind
class CalendarLocationItem extends React.Component {
  static propTypes = {
    value: PropTypes.shape({
      domain: PropTypes.string,
      locationId: PropTypes.number,
    }),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: {
      domain: null,
      locationId: null,
    },
  };

  onLocationIdChange(e) {
    const locationId = e.target.value;
    this.propagateOnChange({ locationId });
  }

  onDomainChange(domain) {
    this.propagateOnChange({ domain });
  }

  propagateOnChange(obj) {
    this.props.onChange(_.extend({}, this.props.value, obj));
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-6">
          <CleanDomainSelect value={this.props.value.domain} onChange={this.onDomainChange} />
        </div>
        <div className="col-xs-6">
          <CleanInput
            value={this.props.value.locationId}
            label="locationId"
            onChange={this.onLocationIdChange}
          />
        </div>
      </div>
    );
  }
}

export default CalendarLocationItem;
