/**
 * Created by chenrozenes on 13/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import _ from 'lodash';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import { getDataMapping } from '../../../../../store/backoffice/communication/actions';
import * as selectors from '../../../../../store/backoffice/communication/selectors';
import { BkmdGrid, BkmdGridColumn } from '../../../../../components/grid';
import dataMappingType from './dataMappingType';
import { ArrayColumn } from '../../../../../components/grid/columns/index';

@autobind
class DataMapping extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    dataMapping: PropTypes.oneOfType([PropTypes.object, dataMappingType]).isRequired,
  };

  onRowClick(rowData) {
    this.props.router.push(`/secure/communication/mapping/${rowData.id}`);
  }

  render() {
    // Create an array with id out of the mapping object for the grid
    const data = _.map(this.props.dataMapping, (val, key) => ({
      ...val,
      id: key,
    }));

    return (
      <div>
        <BkmdGrid
          title="Entities"
          data={data}
          totalCount={data.length}
          onRowClick={this.onRowClick}
          local
        >
          <BkmdGridColumn id="title" title="Title" />
          <BkmdGridColumn id="description" title="Description" />
          <BkmdGridColumn id="params" title="Parameters" customComponent={ArrayColumn} />
        </BkmdGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(getDataMapping, selectors.dataMappingSelector, {
    renderAlways: false,
    loading: true,
  }),
  connect(state => ({
    dataMapping: selectors.dataMappingSelector(state),
  })),
)(DataMapping);
