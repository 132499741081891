import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import CollapsibleCard from '../../../../components/ui/collapsibleCard/collapsibleCard';
import DeleteAsoGroupTierAction from './deleteAsoGroupTier/deleteAsoGroupTierAction';
import EntityForm from '../components/entityForm/entityForm';
import * as AsoGroupTierFormDefs from './asoGroupTierFormDefs';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import Apis from '../../../../api';
import injectNotification from '../../../../store/notification/injectNotification';

class CollapsibleTier extends React.PureComponent {
  propTypes = {
    tier: PropTypes.object.isRequired,
    updateTier: PropTypes.func.isRequired,
    updateTierTracker: PropTypes.object.isRequired,
    deleteTier: PropTypes.func.isRequired,
  };

  render() {
    const { tier, updateTier, updateTierTracker, deleteTier } = this.props;
    return (
      <CollapsibleCard
        title={tier.name}
        id={tier.name}
        className="patient-details-card light-blue-title bg-grey-color"
      >
        <div className="action-group">
          <DeleteAsoGroupTierAction onSubmit={deleteTier} />
        </div>
        <EntityForm
          name={`${AsoGroupTierFormDefs.name}${tier.id}`}
          title="Data:"
          schemaDef={AsoGroupTierFormDefs.schema}
          fieldsDef={AsoGroupTierFormDefs.fields(tier.id)}
          entity={tier}
          onSubmit={updateTier}
          isSubmitting={updateTierTracker.inProgress === true}
        />
      </CollapsibleCard>
    );
  }
}

export default compose(
  injectNotification,
  withFetchers({
    updateTier: {
      handler: () => ({ id, asoGroupId, name, title, description, showInNetwork, overrideColor }) =>
        Apis.asoGroup.updateTier({
          id,
          asoGroupId,
          name,
          title,
          description,
          overrideColor: _.isEmpty(overrideColor) ? null : overrideColor,
          showInNetwork,
        }),
      onSuccess: ({ notification, getAsoGroup }) => {
        notification.success('', 'Success update tier');
        getAsoGroup();
      },
      onError: ({ notification }) => notification.error('', 'Error occurred while updating tier'),
    },
    deleteTier: {
      handler: ({ tier: { id } }) => () => Apis.asoGroup.deleteTier(id),
      onSuccess: ({ notification, getAsoGroup }) => {
        notification.success('', 'Success deleting tier');
        getAsoGroup();
      },
      onError: ({ notification }) => notification.error('', 'Error occurred while deleting tier'),
    },
  }),
)(CollapsibleTier);
