import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_ALL_CLINICS,
  GET_ALL_CALENDARS,
  GET_CLINIC,
  CREATE_CLINIC,
  UPDATE_CLINIC,
  DELETE_CLINIC,
  LIST_CLINIC_NOTIFICATIONS,
  LOAD_CLINIC_APPOINTMENTS_FILE,
  CREATE_BULK_APPOINTMENTS,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  clinicCalendars: {
    count: 0,
    data: [],
  },
  details: {},
  raw: {},
  permissions: [],
  notifications: {
    data: [],
    count: 0,
  },
  appointmentsFromFile: [],
  appointmentsFromFileReport: {},
};

export default combineReducers({
  list: reduceReducers(
    genericReducer(GET_ALL_CLINICS, initialState.list, null, { keepStateOnStart: true }),
  ),
  clinicCalendars: reduceReducers(genericReducer(GET_ALL_CALENDARS, initialState.clinicCalendars)),
  details: reduceReducers(
    genericReducer(GET_CLINIC, initialState.details),
    genericReducer(UPDATE_CLINIC, initialState.details),
    genericReducer(CREATE_CLINIC, initialState.details),
    genericReducer(DELETE_CLINIC, initialState.details),
  ),
  notifications: genericReducer(LIST_CLINIC_NOTIFICATIONS, initialState.notifications, null, {
    keepStateOnStart: true,
  }),
  appointmentsFromFile: genericReducer(
    LOAD_CLINIC_APPOINTMENTS_FILE,
    initialState.appointmentsFromFile,
  ),
  appointmentsFromFileReport: genericReducer(
    CREATE_BULK_APPOINTMENTS,
    initialState.appointmentsFromFileReport,
  ),
});
