/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import Action from '../components/action';
import {
  attachPastActivityToMember,
  cleanPastActivityState,
  ATTACH_PAST_ACTIVITY,
} from '../../../../store/backoffice/members/actions';
import AttachMemberPastActivityForm from './attachMemberPastActivityForm';
import { name as AttachPastActivityFormName } from './attachMemberPastActivityFormDefs';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import { getAttachPastActivityResponse } from '../../../../store/backoffice/members/selectors';
import { BkmdGrid, BkmdGridColumn } from '../../../../components/grid';

const errors = {
  OLD_MEMBER_NOT_FOUND: 'OldMemberNotFound',
  NEW_MEMBER_NOT_FOUND: 'NewMemberNotFound',
  MEMBER_VALIDATION_ERROR: 'MemberValidationError',
  UPDATE_ID_ERROR: 'UpdateDomainMemberIdError',
  UPDATE_ID_SERVICES_ERROR: 'UpdateDomainMemberIdServicesError',
};

const messages = defineMessages({
  memberNotFoundWarning: {
    defaultMessage: 'Could not match old member-id to new member. If you are sure, try again',
    id: 'backpack.domainMember.attachPastActivity.notFoundWarning',
  },
  memberNotFoundError: {
    defaultMessage: 'Could not find new domain member',
    id: 'backpack.domainMember.attachPastActivity.notFoundError',
  },
  memberValidationError: {
    defaultMessage: 'Member validation failed, contact dev team',
    id: 'backpack.domainMember.attachPastActivity.validationError',
  },
  servicesError: {
    defaultMessage: 'Process complete but some services failed, see below',
    id: 'backpack.domainMember.attachPastActivity.servicesError',
  },
  failed: {
    defaultMessage: 'Process failed, contact dev team',
    id: 'backpack.domainMember.attachPastActivity.failed',
  },
  success: {
    defaultMessage: 'Success!',
    id: 'backpack.domainMember.attachPastActivity.success',
  },
});

const errorToDisplay = {
  [errors.NEW_MEMBER_NOT_FOUND]: {
    bgColor: 'bg-providers-error-color',
    iconName: 'icon-x-2',
    message: messages.memberNotFoundError,
  },
  [errors.OLD_MEMBER_NOT_FOUND]: {
    bgColor: 'bg-orange-color',
    iconName: 'icon-warning',
    message: messages.memberNotFoundWarning,
  },
  [errors.MEMBER_VALIDATION_ERROR]: {
    bgColor: 'bg-providers-error-color',
    iconName: 'icon-x-2',
    message: messages.memberValidationError,
  },
  [errors.UPDATE_ID_SERVICES_ERROR]: {
    bgColor: 'bg-providers-error-color',
    iconName: 'icon-x-2',
    message: messages.servicesError,
  },
  [errors.UPDATE_ID_ERROR]: {
    bgColor: 'bg-providers-error-color',
    iconName: 'icon-x-2',
    message: messages.failed,
  },
};

@autobind
class AttachPastActivity extends React.Component {
  static propTypes = {
    domainMember: PropTypes.object.isRequired,
    attachPastActivityToMember: PropTypes.func.isRequired,
    cleanPastActivityState: PropTypes.func.isRequired,
    attachPastActivityTracker: PropTypes.object.isRequired,
    attachPastActivityResponse: PropTypes.object,
  };

  static defaultProps = {
    attachPastActivityResponse: {},
  };

  attachPastActivity({ oldDomainMemberId }) {
    // Domain member is the new record (currently in the roster)
    const {
      attachPastActivityToMember,
      domainMember,
      attachPastActivityTracker: { executed },
      attachPastActivityResponse: { error },
    } = this.props;

    const validateMembers = !executed || error !== errors.OLD_MEMBER_NOT_FOUND;
    attachPastActivityToMember(oldDomainMemberId, domainMember.id, validateMembers);
  }

  submitDisabled() {
    /*
      Submit button should be disabled in the following cases:
        * Success (to prevent double click)
        * Failure in member validation, or failure in the process

      In case validation cannot be performed (missing record in the activation table), we show
      a warning and give the user the option to execute anyway.
     */
    const {
      attachPastActivityTracker: { finished },
      attachPastActivityResponse: { error },
    } = this.props;
    return !!((finished && !error) || (error && error !== errors.OLD_MEMBER_NOT_FOUND));
  }

  renderMessage() {
    const {
      attachPastActivityResponse: { data, hasError, error },
    } = this.props;

    if (_.isEmpty(data) && !hasError) {
      return null;
    }

    const { bgColor, iconName, message } = hasError
      ? errorToDisplay[error]
      : {
          bgColor: 'bg-success-color',
          iconName: 'icon-check-s-2',
          message: messages.success,
        };

    return (
      <div className={`${bgColor} text-white text-center padding-box-30`}>
        <i className={`${iconName} inline-block title-36`} />
        <div>
          <FormattedMessage {...message} />
        </div>
      </div>
    );
  }

  renderResults() {
    // Displaying full overview of the process
    const { attachPastActivityResponse } = this.props;

    const services = _.assign(
      {},
      _.get(attachPastActivityResponse.data, 'domain', {}),
      _.get(attachPastActivityResponse.data, 'platform', {}),
    );
    const displayResults = _.map(services, (props, serviceName) => ({
      Service: serviceName,
      Status: props.hasError ? 'Failed' : 'Passed',
      Count: _.get(props.data, 'count'),
    }));
    return _.isEmpty(_.get(attachPastActivityResponse, 'data')) ? null : (
      <div>
        <div>
          <BkmdGrid title="Summary" data={displayResults} local>
            <BkmdGridColumn id="Service" title="Service" />
            <BkmdGridColumn id="Status" title="Status" />
            <BkmdGridColumn id="Count" title="Count" />
          </BkmdGrid>
        </div>
        <ReactJson
          src={services}
          displayDataTypes={false}
          displayObjectSize={false}
          collapsed
          theme="ashes"
        />
      </div>
    );
  }

  render() {
    const { cleanPastActivityState } = this.props;
    return (
      <Action
        name="attachPastActivity"
        title="Attach member past activity"
        label="Attach past activity"
        actionTrackerId={ATTACH_PAST_ACTIVITY.SOURCE}
        formName={AttachPastActivityFormName}
        closeOnSuccess={false}
        submitDisabled={this.submitDisabled()}
        displayNotifications={false}
        onClose={cleanPastActivityState}
      >
        <AttachMemberPastActivityForm onSubmit={this.attachPastActivity} />
        {this.renderMessage()}
        {this.renderResults()}
      </Action>
    );
  }
}

export default compose(
  connect(
    state => ({
      attachPastActivityResponse: getAttachPastActivityResponse(state),
    }),
    {
      attachPastActivityToMember,
      cleanPastActivityState,
    },
  ),
  actionTracker({
    attachPastActivityTracker: ATTACH_PAST_ACTIVITY.SOURCE,
  }),
)(AttachPastActivity);
