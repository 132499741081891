import { defineMessages } from 'react-intl';

export const name = 'backoffice-namespace-appointment-action';

export const patientMessages = defineMessages({
  title: {
    defaultMessage: 'Patient details',
    id: 'namespacePatient.patient.patientDetails',
  },
  memberId: {
    defaultMessage: 'Member id',
    id: 'namespaceAppointment.patient.memberId',
  },
  fullName: {
    defaultMessage: 'Full name',
    id: 'namespaceAppointment.patient.fullName',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    id: 'namespaceAppointment.patient.dateOfBirth',
  },
  address: {
    defaultMessage: 'Address',
    id: 'namespaceAppointment.patient.address',
  },
  plan: {
    defaultMessage: 'Plan',
    id: 'namespaceAppointment.patient.plan',
  },
  phoneNumber: {
    defaultMessage: 'Phone',
    id: 'namespaceAppointment.patient.phoneNumber',
  },
  email: {
    defaultMessage: 'Email',
    id: 'namespaceAppointment.patient.email',
  },
  insurer: {
    defaultMessage: 'Insurer',
    id: 'namespaceAppointment.patient.insurer',
  },
  ehrInsuranceId: {
    defaultMessage: 'Ehr insurance id',
    id: 'namespaceAppointment.patient.ehrInsuranceId',
  },
});
