import { withRouter } from 'react-router';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import queryConnect from 'Store/tools/queryString/queryConnect';
import injectNotification from 'Store/notification/injectNotification';
import { withTranslatedMessages } from 'Utils/withTranslatedMessages';
import Api from '../../../../../../../api';
import { withStateFetchersOnMount } from '../../../../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../../../../api/injectApi/withFetchers';

import PlanMappingList from './PlanMappingList';
import planMappingListMessages from './PlanMappingListMessages';

const loadPlanMapping = props => query => {
  const { skip, limit, sort, sortAscending } = query || props;
  return Api.clinicsApi.getPlanMappingsByHealthSystem({
    healthSystemId: props.healthSystemId,
    skip,
    limit,
    sort,
    sortAscending,
  });
};
const onSuccessLoadPlanMapping = ({ setResults }, results) => {
  setResults(results.data);
};

const onErrorLoadPlanMapping = ({ notification, loadErrorText }) => {
  notification.error('', loadErrorText);
};

const deletePlanMappingHandler = () => ({ ehrId }) => Api.clinicsApi.deletePlanMapping({ ehrId });

const onSuccessDeletePlanMapping = ({ notification, getPlanMappings, deleteSuccessText }) => {
  notification.success('', deleteSuccessText);
  getPlanMappings();
};

const onErrorDeletePlanMapping = ({ notification, deleteErrorText }) => {
  notification.error('', deleteErrorText);
};

const planMappingClickHandler = ({ router, healthSystemId }) => ({ ehrId }) => {
  router.push(`/secure/healthSystems/${healthSystemId}/planMapping/${ehrId}`);
};

const onQueryChangeHandler = ({ getPlanMappings }) => (skip, limit, search, sort, sortAscending) =>
  getPlanMappings({ skip, limit, search, sort, sortAscending });

const isPlanMappingReady = ({ getPlanMappingsTracker: { executed } }) => executed;
const planMappingCreatedHandler = ({ getPlanMappings }) => () => getPlanMappings();

const PlanMappingListContainer = compose(
  injectNotification,
  withRouter,
  withProps(props => ({
    healthSystemId: +props.params.id,
  })),
  withTranslatedMessages(planMappingListMessages),
  withState('results', 'setResults', { count: 0, data: [] }),
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withStateFetchersOnMount({
    getPlanMappings: {
      handler: loadPlanMapping,
      onSuccess: onSuccessLoadPlanMapping,
      isReady: isPlanMappingReady,
      onError: onErrorLoadPlanMapping,
    },
  }),
  withFetchers({
    onDeletePlanMapping: {
      handler: deletePlanMappingHandler,
      onSuccess: onSuccessDeletePlanMapping,
      onError: onErrorDeletePlanMapping,
    },
  }),
  withHandlers({
    onQueryChange: onQueryChangeHandler,
    onPlanMappingClick: planMappingClickHandler,
    onPlanMappingCreated: planMappingCreatedHandler,
  }),
)(PlanMappingList);

export default PlanMappingListContainer;
