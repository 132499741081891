/**
 * Created by chenrozenes on 09/04/2017.
 */
import { combineReducers } from 'redux';
import genericReducer from '../../genericReducer';
import {
  GET_ALL_PROVIDERS,
  GET_PROVIDER_FOR_BACKOFFICE,
  GET_PROVIDER_LOCATIONS,
  GET_ALL_TAXONOMIES,
  SEARCH_PROVIDERS,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  search: [],
  details: {},
  providerLocations: {
    data: [],
    count: 0,
  },
  raw: {
    details: {},
  },
  taxonomies: [],
};

export default combineReducers({
  list: genericReducer(GET_ALL_PROVIDERS, initialState.list, null, { keepStateOnStart: true }),
  search: genericReducer(SEARCH_PROVIDERS, initialState.search, null, { keepStateOnStart: false }),
  details: genericReducer(GET_PROVIDER_FOR_BACKOFFICE, initialState.details),
  providerLocations: genericReducer(GET_PROVIDER_LOCATIONS,
                                    initialState.providerLocations,
                                    null,
                                    { keepStateOnStart: true }),
  taxonomies: genericReducer(GET_ALL_TAXONOMIES, initialState.taxonomies)
});
