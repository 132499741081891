/**
 * Created by ofirHartuv on 01/11/17.
 */
import Joi from 'joi-browser';
import _ from 'lodash';
import * as AppointmentStatus from '../../../../../model/enum/appointmentStatus';
import bkmdDomain from '../../../../../model/enum/bkmdDomain';
import {
  ReduxFormDateTime,
  ReduxFormIntlSelect,
} from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-appointment';

export const fields = {
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  calendarId: {
    name: 'calendarId',
    readOnly: true,
  },
  isReviewRequired: {
    name: 'isReviewRequired',
    readOnly: true,
  },
  source: {
    name: 'source',
  },
  appointmentTypeId: {
    name: 'appointmentTypeId',
  },
  startTime: {
    name: 'startTime',
    component: ReduxFormDateTime,
  },
  endTime: {
    name: 'endTime',
    component: ReduxFormDateTime,
  },
  memberDomain: {
    name: 'memberDomain',
    component: ReduxFormIntlSelect,
    intlEnum: bkmdDomain,
  },
};

const statusSchema = Joi.string()
  .valid(_.values(AppointmentStatus))
  .options({
    language: {
      any: {
        required: `please enter valid status (${_.join(_.values(AppointmentStatus), '/')})`,
      },
    },
  });

export const schema = {
  id: Joi.number(),
  calendarId: Joi.number().required(),
  domainMemberId: Joi.number().required(),
  npi: Joi.string().required(),
  startTime: Joi.date().required(),
  endTime: Joi.date().required(),
  isReviewRequired: Joi.boolean().required(),
  locationId: Joi.number().required(),
  status: statusSchema,
  source: Joi.string(),
  appointmentTypeId: Joi.number()
    .allow('', null)
    .optional(),
};
