export const getClinicsCount = state => state.backoffice.clinics.list.count;
export const getClinicsList = state => state.backoffice.clinics.list.data;
export const getClinicCalendarsList = state => state.backoffice.clinics.clinicCalendars.data.data;
export const getClinicCalendarsListCount = state =>
  state.backoffice.clinics.clinicCalendars.data.count;
export const getClinic = state => state.backoffice.clinics.details;
export const getFacilityResources = state => state.backoffice.clinics.facilityResources;
export const getClinicNotificationData = state => state.backoffice.clinics.notifications.data;
export const getClinicNotificationCount = state => state.backoffice.clinics.notifications.count;
export const getAppointmentsFromFile = state => state.backoffice.clinics.appointmentsFromFile;
export const getAppointmentsFromFileReport = state =>
  state.backoffice.clinics.appointmentsFromFileReport;
