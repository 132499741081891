import React from 'react';
import PropTypes from 'prop-types';
import CleanInput from 'Components/form/cleanInput';
import CleanDateInput from 'Components/form/cleanDateInput';
import { patientMessages } from './formDefs';

export default function PatientDataClean({
  patient,
  memberId,
  fullName,
  dateOfBirth,
  phoneNumber,
  address,
  email,
  ehrInsuranceId,
  insurer,
  plan,
}) {
  return (
    <div className="patient-details-input-mid-grey">
      <div className="row">
        <div className="col-xs-3">
          <CleanInput label={memberId} value={patient.memberId} disabled/>
        </div>
        <div className="col-xs-3">
          <CleanInput label={fullName} value={patient.fullName} disabled/>
        </div>
        <div className="col-xs-6">
          <CleanInput label={email} value={patient.email} disabled/>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-3">
          <CleanInput label={phoneNumber} value={patient.phoneNumber} disabled/>
        </div>
        <div className="col-xs-6">
          <CleanInput label={address} value={patient.address} disabled/>
        </div>
        <div className="col-xs-3">
          <CleanDateInput
            label={dateOfBirth}
            value={patient.dateOfBirth}
            fixedLabel
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-3">
          <CleanInput label={insurer} value={patient.insurer} disabled/>
        </div>
        <div className="col-xs-3">
          <CleanInput label={plan} value={patient.plan} disabled/>
        </div>
        <div className="col-xs-6">
          <CleanInput label={ehrInsuranceId} value={patient.ehrInsuranceId} disabled/>
        </div>
      </div>
    </div>
  );
};

PatientDataClean.propTypes = {
  patient: PropTypes.object.isRequired,
  memberId: PropTypes.string,
  fullName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  ehrInsuranceId: PropTypes.string,
  insurer: PropTypes.string,
  plan: PropTypes.string,
};

PatientDataClean.defaultProps = {
  memberId: patientMessages.memberId.defaultMessage,
  fullName: patientMessages.fullName.defaultMessage,
  dateOfBirth: patientMessages.dateOfBirth.defaultMessage,
  phoneNumber: patientMessages.phoneNumber.defaultMessage,
  address: patientMessages.address.defaultMessage,
  email: patientMessages.email.defaultMessage,
  ehrInsuranceId: patientMessages.ehrInsuranceId.defaultMessage,
  insurer: patientMessages.insurer.defaultMessage,
  plan: patientMessages.plan.defaultMessage,
};
