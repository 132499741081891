import steps from '../steps';
import namespaceInfoStep from '../steps/namespaceInfoStep';
import ehrFormStep from '../steps/ehrFormStep';
import createNamespaceStep from '../steps/createNamespaceStep';

export default {
  name: 'ProviderNamespaceManagement',
  definitions: {
    [steps.NAMESPACE_INFO]: namespaceInfoStep,
    [steps.EHR_FORM]: ehrFormStep,
    [steps.CREATE_NAMESPACE]: createNamespaceStep,
  },
};
