import React from 'react';
import { compose } from 'recompose';
import { defineMessages } from 'react-intl';
import { isFunction } from 'lodash';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { ThreeBounceSpinner } from '../../../../../../components/ui/spinner/index';
import Api from '../../../../../../api';

const messages = defineMessages({
  successTitle: {
    defaultMessage: 'Success!',
    id: 'backpack.createNamespaceModal.successTitle',
  },
  successMessage: {
    defaultMessage: 'Successfully created a namespace',
    id: 'backpack.createNamespaceModal.successMessage',
  },
  errorTitle: {
    defaultMessage: 'Error!',
    id: 'backpack.createNamespaceModal.errorTitle',
  },
  errorMessage: {
    defaultMessage: 'Failed to create new namespace',
    id: 'backpack.createNamespaceModal.errorMessage',
  },
});

const enhanced = compose(
  injectNotification,
  withStateFetchersOnMount({
    createNs: {
      handler: ({ flowData }) => () =>
        Api.namespaceManagerApi.createNamespace({
          ...flowData,
        }),
      onSuccess: ({ onSuccess, handleClose, notification }) => {
        notification.success(messages.successTitle, messages.successMessage);
        if (isFunction(onSuccess)) onSuccess();

        handleClose();
      },
      onError: ({ notification }, error) => {
        console.log(messages.errorMessage.defaultMessage, { error });
        return notification.error(
          messages.errorTitle,
          `${messages.errorMessage.defaultMessage}. Reason: ${error.message}`,
        );
      },
    },
  }),
)(() => <ThreeBounceSpinner />);

export default {
  component: enhanced,
  transitions: {
    NEXT: null,
    PREV: null,
  },
  mapFlowDataToProps: flowData => ({
    flowData,
    handleClose: flowData.handleClose,
    onSuccess: flowData.onSuccess,
  }),
  mapPayloadToFlowData: () => ({}),
};
