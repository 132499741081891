/**
 * Created by anna on 24/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getReview } from '../../../../store/backoffice/reviews/actions';
import * as selectors from '../../../../store/backoffice/reviews/selectors';
import EntityForm from '../components/entityForm/entityForm';
import * as ReviewFormDefs from './reviewFormDef';
import EditReviewAction from './actions/editReviewAction';
import AcceptAction from './actions/changeStatusToAcceptedAction';
import DeclineAction from './actions/changeStatusToDeclinedAction';
import PendingAction from './actions/changeStatusToPendingAction';

@autobind
class ReviewPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired,
    getReview: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.getReview(parseInt(this.props.params.token, 10));
  }

  render() {
    const { review } = this.props;
    if (!review) return <div />;
    return (
      <div>
        <div className="action-group">
          <EditReviewAction review={review} />
          <AcceptAction review={review} />
          <DeclineAction review={review} />
          <PendingAction review={review} />
        </div>
        <EntityForm
          name={ReviewFormDefs.name}
          fieldsDef={ReviewFormDefs.fields}
          schemaDef={ReviewFormDefs.schema}
          entity={review}
          readOnly
        />
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      review: selectors.getReview(state),
    }),
    { getReview },
  ),
)(ReviewPage);
