import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Action from '../../../components/action';
import { ThreeBounceSpinner } from '../../../../../../components/ui/spinner';
import CleanFileInput from '../../../../../../components/form/cleanFileInput';
import CleanJSONInput from '../../../../../../components/form/cleanJSONInput';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  loadClinicAppointmentsFromFile,
  createBulkAppointments,
  CREATE_BULK_APPOINTMENTS,
  LOAD_CLINIC_APPOINTMENTS_FILE,
} from '../../../../../../store/backoffice/clinicManagement/clinics/actions';
import * as selectors from '../../../../../../store/backoffice/clinicManagement/clinics/selectors';

@autobind
class ImportAppointmentsAction extends React.Component {
  static propTypes = {
    clinicId: PropTypes.number.isRequired,
    createBulkAppointments: PropTypes.func.isRequired,
    loadClinicAppointmentsFromFile: PropTypes.func.isRequired,
    appointmentsFromFile: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    mapping: PropTypes.object.isRequired,
    loadClinicAppointmentsTracker: PropTypes.object.isRequired,
    createBulkAppointmentsTracker: PropTypes.object.isRequired,
  };

  onSubmit() {
    const { createBulkAppointments, appointmentsFromFile, clinicId } = this.props;
    createBulkAppointments(clinicId, appointmentsFromFile);
    return false;
  }

  handleUpload(event) {
    const { mapping, loadClinicAppointmentsFromFile } = this.props;
    const file = event.target.files[0];
    loadClinicAppointmentsFromFile(file, mapping);
  }

  render() {
    const { loadClinicAppointmentsTracker, report, createBulkAppointmentsTracker } = this.props;
    const errorMessage = loadClinicAppointmentsTracker.hasError
      ? 'CSV loading error. please check your file format and mapping.'
      : '';
    return (
      <Action
        name="importClinicAppointmentsFromFile"
        title="Import clinic appointments"
        actionTrackerId={CREATE_BULK_APPOINTMENTS.SOURCE}
        closeOnSuccess={false}
        onSubmit={this.onSubmit}
      >
        <div>
          <CleanFileInput onChange={this.handleUpload} accept=".csv" />
          <span style={{ color: 'red' }}>{errorMessage}</span>
        </div>
        {createBulkAppointmentsTracker.inProgress && !createBulkAppointmentsTracker.finished ? (
          <ThreeBounceSpinner />
        ) : (
          <CleanJSONInput value={report} readOnly spacePadding />
        )}
      </Action>
    );
  }
}
export default compose(
  connect(
    state => ({
      appointmentsFromFile: selectors.getAppointmentsFromFile(state),
      report: selectors.getAppointmentsFromFileReport(state),
    }),
    { createBulkAppointments, loadClinicAppointmentsFromFile },
  ),
  actionTracker({
    loadClinicAppointmentsTracker: LOAD_CLINIC_APPOINTMENTS_FILE.SOURCE,
    createBulkAppointmentsTracker: CREATE_BULK_APPOINTMENTS.SOURCE,
  }),
)(ImportAppointmentsAction);
