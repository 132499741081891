export const MANUAL = 'MANUAL';
export const VIM_CLINIC_FILE = 'VIM_CLINIC_FILE';
export const VIM_CLICK_TO_CALL = 'VIM_CLICK_TO_CALL';
export const VIM_SCHEDULING = 'VIM_SCHEDULING';
export const VIM_BACKPACK = 'VIM_BACKPACK';
export const VIM_PROVIDER_APPLICATION = 'VIM_PROVIDER_APPLICATION';
export const ADT = 'ADT';
export const DOMAIN_CLAIM = 'DOMAIN_CLAIM';
export const VIM_SDK_BOOK = 'VIM_SDK_BOOK';
export const VIM_GUIDE_BOOK = 'VIM_GUIDE_BOOK';
export const EHR = 'EHR';
