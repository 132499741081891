import '@babel/polyfill';
import syncVersion from 'Utils/versionSync';

// THIS SHOULD BE BEFORE OTHER IMPORTS!
// We are adding default HOCs to all the withFetchers,
// and if the component will be imported before this it will not work
import './redux-api/configureApi';

import start from './start';

if (!global.Intl && typeof require.ensure === 'function') {
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], require => {
    require('intl');
    require('intl/locale-data/jsonp/en.js');

    start();
  });
} else {
  start();
}

// 5 minutes
syncVersion(300000);
