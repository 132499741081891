import { AppointmentsApi } from './appointments';
import { AppointmentRequestActionsApi } from './appointment-request-actions';
import { PatientsApi } from './patients';
import { PuppeteerVsAdapterApi } from './puppeteer-vs-adapter';

export function namespaceAPI(namespaceNameRaw) {
  const namespace = namespaceNameRaw.toLowerCase();

  return {
    appointments: new AppointmentsApi(namespace),
    patients: new PatientsApi(namespace),
    appointmentRequestActions: new AppointmentRequestActionsApi(namespace),
    puppeteerVsAdapter: new PuppeteerVsAdapterApi(namespace),
  };
}
