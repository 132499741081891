/**
 * Created by chenrozenes on 23/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import CreateTemplateAction from './actions/createTemplateAction';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import { listTemplates } from '../../../../../store/backoffice/communication/actions';
import { BkmdGridColumn } from '../../../../../components/grid';
import {
  listTemplatesCount,
  listTemplatesData,
} from '../../../../../store/backoffice/communication/templateSelectors';

@autobind
class TemplateList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    listTemplates: PropTypes.func.isRequired, // from redux
    templates: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { listTemplates, skip, limit, search, sort, sortAscending } = this.props;
    listTemplates({ skip, limit, search, sort, sortAscending });
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.listTemplates({ skip, limit, search, sort, sortAscending });
  }

  onClick(rowData) {
    this.props.router.push(`/secure/communication/templates/${rowData.templateId}`);
  }

  render() {
    return (
      <div className="margin-bottom-clean-form">
        <div className="action-group">
          <CreateTemplateAction templates={this.props.templates} />
        </div>

        <QueryStringGrid
          data={this.props.templates}
          totalCount={this.props.count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="templateId" title="Template Id" />
          <BkmdGridColumn id="alias" title="Alias" />
          <BkmdGridColumn id="notificationType" title="Notification Type" />
          <BkmdGridColumn id="channel" title="channel" />
          <BkmdGridColumn id="content" title="content" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      templates: listTemplatesData(state),
      count: listTemplatesCount(state),
    }),
    { listTemplates },
  ),
)(TemplateList);
