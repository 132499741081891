import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { defineMessages } from 'react-intl';

import * as CalendarFormDefs from './calendarFormDefs';
import {
  updateCalendar,
  getCalendarById,
  deleteCalendar,
  DELETE_CALENDAR,
} from '../../../../../store/backoffice/clinicManagement/calendar/actions';
import { getClinicById } from '../../../../../store/backoffice/clinicManagement/clinics/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/calendar/selectors';
import * as clinicSelectors from '../../../../../store/backoffice/clinicManagement/clinics/selectors';
import routingSelectors from '../../../../../store/routing/selectors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../../store/notification/injectNotification';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import FlaggedRender from '../../../../../utils/FlaggedRender';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import Api from '../../../../../api';
import EntityForm from '../../components/entityForm/entityForm';
import { calendarType as type } from '../permissions/permissionMapper';
import Button from '../../../../../storybook/src/components/atoms/Button/Button';

const messages = defineMessages({
  actionSuccessful: {
    defaultMessage: 'Action successful',
    id: 'updateCalendar.backpack.success',
  },
  actionFailed: {
    defaultMessage: 'Action failed',
    id: 'updateCalendar.backpack.failure',
  },
  syncSuccessful: {
    defaultMessage: 'Sync calendar request sent successfully',
    id: 'updateCalendar.backpack.syncSuccessful',
  },
  syncSuccessfulDetails: {
    defaultMessage: 'Please check the calendar in a few minutes',
    id: 'updateCalendar.backpack.syncSuccessfulDetails',
  },
  syncFailed: {
    defaultMessage: 'Sync calendar request failed',
    id: 'updateCalendar.backpack.syncFailed',
  },
});

@autobind
class UpdateCalendar extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    updateCalendar: PropTypes.func.isRequired, // from redux
    getCalendarById: PropTypes.func.isRequired, // from redux
    getClinicById: PropTypes.func.isRequired, // from redux
    deleteCalendar: PropTypes.func.isRequired, // from redux
    calendar: PropTypes.object.isRequired, // from redux
    deleteTracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    baseUrl: PropTypes.string.isRequired,
    allowDeleteAllCalendarTimeSlots: PropTypes.bool.isRequired,
    deleteAllTimeSlotsByCalendarId: PropTypes.func.isRequired,
    syncCalendarEhrAppointments: PropTypes.func.isRequired,
    syncCalendarEhrAppointmentsTracker: PropTypes.object.isRequired,
    clinic: PropTypes.object.isRequired,
  };

  componentWillMount() {
    if (this.props.params.calendarId) {
      this.props.getCalendarById(this.props.params.calendarId);
      this.props.getClinicById(this.props.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.props.router.goBack();
    }
  }

  deleteCalendar() {
    const { calendar, deleteCalendar } = this.props;
    deleteCalendar(calendar.id);
  }

  goToAppointments() {
    const { baseUrl } = this.props;
    this.props.router.push(`${baseUrl}/appointments`);
  }

  render() {
    const {
      calendar,
      allowDeleteAllCalendarTimeSlots,
      deleteAllTimeSlotsByCalendarId,
      syncCalendarEhrAppointments,
      syncCalendarEhrAppointmentsTracker,
      clinic,
    } = this.props;
    const resourceId = calendar.id;

    return (
      <div>
        <div className="backoffice-action">
          <button
            onClick={this.goToAppointments}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Show Calendar׳s Appointments
          </button>
          {clinic.ehrLocationId && (
            <Button
              type="small"
              className="btn-blue text-semibold action-button"
              onClick={syncCalendarEhrAppointments}
              isLoading={_.get(syncCalendarEhrAppointmentsTracker, 'inProgress')}
            >
              Sync EHR Appointments
            </Button>
          )}
          <button
            onClick={() =>
              this.props.router.push(`/secure/clinics/permissions/${type}/${resourceId}`)}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Calendar׳s Permissions
          </button>
          <button
            className="btn btn-small btn-blue text-semibold action-button margin-13"
            onClick={this.deleteCalendar}
          >
            Delete Calendar
          </button>
          <FlaggedRender shouldRender={allowDeleteAllCalendarTimeSlots}>
            {() => (
              <button
                className="btn btn-small btn-red text-semibold action-button margin-13"
                onClick={deleteAllTimeSlotsByCalendarId}
              >
                Delete This Calendar&apos;s Time Slots [DEV ONLY]
              </button>
            )}
          </FlaggedRender>
        </div>
        <hr />
        <EntityForm
          name={CalendarFormDefs.name}
          title="Update Calendar:"
          submitText="Update"
          schemaDef={CalendarFormDefs.schema}
          fieldsDef={CalendarFormDefs.fields}
          entity={calendar}
          onSubmit={this.props.updateCalendar}
        />
      </div>
    );
  }
}

export default compose(
  withFeatureFlag({
    key: 'backpack.allowDeleteAllCalendarTimeSlots',
    prop: 'allowDeleteAllCalendarTimeSlots',
    defaultValue: false,
  }),
  withRouter,
  injectNotification,
  actionTracker({
    deleteTracker: DELETE_CALENDAR.SOURCE,
  }),
  connect(
    state => ({
      clinic: clinicSelectors.getClinic(state),
      calendar: selectors.getCalendar(state),
      baseUrl: routingSelectors.getCurrentUrl(state),
    }),
    { updateCalendar, getCalendarById, deleteCalendar, getClinicById },
  ),
  withFetchers({
    deleteAllTimeSlotsByCalendarId: {
      handler: ({ calendar }) => () => Api.calendarsApi.deleteAllTimeSlotsByCalendarId(calendar.id),
      onSuccess: ({ notification }) => {
        notification.success('', messages.actionSuccessful);
      },
      onError: ({ notification }) => {
        notification.error('', messages.actionFailed);
      },
    },
    syncCalendarEhrAppointments: {
      handler: ({ calendar }) => () => Api.calendarsApi.syncCalendarEhrAppointments(calendar.id),
      onSuccess: ({ notification }) => {
        notification.success(messages.syncSuccessful, messages.syncSuccessfulDetails);
      },
      onError: ({ notification }) => {
        notification.error('', messages.syncFailed);
      },
    },
  }),
)(UpdateCalendar);
