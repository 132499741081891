/**
 * Created by anna on 26/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../components/action';
import {
  updateToAcceptedStatus,
  UPDATE_REVIEW_STATUS_ACCEPTED,
} from '../../../../../store/backoffice/reviews/actions';
import { ACCEPTED } from '../../../../../model/enum/appointmentReviewStatus';

@autobind
class AcceptAction extends React.PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired,
    updateStatus: PropTypes.func.isRequired,
  };

  callAction() {
    this.props.updateStatus(this.props.review.token, ACCEPTED);
    return true;
  }

  render() {
    return (
      <Action
        name="changeStatusToAccepted"
        title="Accept"
        label="Accept"
        actionTrackerId={UPDATE_REVIEW_STATUS_ACCEPTED.SOURCE}
        onSubmit={this.callAction}
      >
        Accepting the appointment review will automatically show it in the provider profile, are you
        sure?
      </Action>
    );
  }
}

export default connect(
  null,
  { updateStatus: updateToAcceptedStatus },
)(AcceptAction);
