import allscriptsAdapter from './allscriptsAdapter';
import athena from './athena';
import cerner from './cerner';
import epicAdapter from './epicAdapter';
// PLOP: Generated namespace service management import (DO NOT REMOVE THIS COMMENT!):
import walgreensAdapter from './walgreens-adapter';
import puppeteerVsAdapter from './puppeteer-vs-adapter';
import mockEhrAdapter from './mock-ehr-adapter';

export default {
  allscriptsAdapter,
  athena,
  cerner,
  epicAdapter,
  // PLOP: Generated namespace service management export (DO NOT REMOVE THIS COMMENT!):
  walgreensAdapter,
  puppeteerVsAdapter,
  mockEhrAdapter,
};
