import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose, setDisplayName, pure, withProps } from 'recompose';

import CleanCheckbox from '../../../../../components/form/cleanCheckbox';
import injectNotification from '../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import BkmdModelButton from '../../../../../components/bkmdModalButton/bkmdModalButton';
import Apis from '../../../../../api';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';

function DeleteGroupFormView(props) {
  return (
    <div>
      <div className="col-xs-12">
        <CleanCheckbox
          label="Are you sure you want to delete this group?"
          id="deleteUserGroup"
          value={props.deleteConfirmOm}
          onChange={props.deleteConfirmToggle}
        />
      </div>
      <div className="col-xs-12">
        <SpinnerButton
          className="btn btn-big btn-min-100 btn-blue margin-top-20"
          disabled={!props.deleteConfirmOn}
          onClick={props.onSubmit}
          isLoading={props.onSubmitTracker.inProgress}
        >
          Delete Group
        </SpinnerButton>
      </div>
    </div>
  );
}

DeleteGroupFormView.propTypes = {
  groupId: PropTypes.number.isRequired,
  ...createToggleStatePropTypes('deleteConfirm'),
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  notification: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

DeleteGroupFormView.defaultProps = {
  onSuccess: _.noop,
};

export const DeleteGroupForm = compose(
  createToggleState('deleteConfirm', false),
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: ({ deleteConfirmOn, groupId }) => () => {
        if (!deleteConfirmOn) return null;
        return Apis.usersApi.deleteGroup(groupId);
      },
      onSuccess: ({ notification, onSuccess, closeModal }, result) => {
        notification.success('Delete Group', 'Group deleted successfully');
        closeModal();
        onSuccess(result);
      },
      onError({ notification }) {
        notification.error('Delete Group', 'Could not delete group duo to unexpected error');
      },
    },
  }),
)(DeleteGroupFormView);

export default compose(
  setDisplayName('DeleteGroupAction'),
  pure,
  withProps({
    title: 'Delete Group',
    buttonText: 'Delete Group',
    component: DeleteGroupForm,
  }),
)(BkmdModelButton);
