import React from 'react';
import _ from 'lodash';
import { compose, withProps } from 'recompose';
import injectNotification from 'Store/notification/injectNotification';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../api';
import UpdateAndVerifyWebhookUrlAction from './veriftWebhookUrl/UpdateAndVerifyWebhookUrlAction';
import ApiKeyFields, { API_KEY_FIELDS_MODE } from './apiKeyFields/apiKeysFields';
import { referralGuidanceFields } from './referralGuidanceConfiguration';
import { searchFields } from './searchConfiguration';

const UpdateApiKey = ({ onSubmit, permission, updateWebhookUrl }) => (
  <div>
    <div className="action-group">
      <UpdateAndVerifyWebhookUrlAction
        permission={permission}
        updatePermissions={updateWebhookUrl}
      />
    </div>
    <ApiKeyFields
      pageTitle="Update Api Key"
      onSubmit={onSubmit}
      initialValues={permission}
      pageMode={API_KEY_FIELDS_MODE.UPDATE}
      submitButtonText="Update"
    />
  </div>
);

function updateApiKeyConfig(fullPermissions) {
  const { allowedHealthSystemIds, permission, webhookFields } = fullPermissions;
  const searchConfiguration = _.pick(permission, searchFields);

  const isAllowedHealthSystemIdsEmpty = _.isEmpty(allowedHealthSystemIds.filter(Boolean));
  const allowedResources = !isAllowedHealthSystemIdsEmpty
    ? _.map(allowedHealthSystemIds, props => ({
        ownerId: typeof props === 'number' ? props : props.value,
        ownerType: 'healthSystem',
      }))
    : [];

  const cleanPermissions = _.omit(permission, [
    ...searchFields,
    ...referralGuidanceFields,
    'schedulingConfiguration',
    'referralGuidanceConfiguration',
    'allowedHealthSystemIds',
    'hasSecret',
  ]);

  const apiAndSchedulingConfigs = {
    ...cleanPermissions,
    allowedResources,
    ...webhookFields,
  };

  // server's updateSdkPermission input expects the following structure:
  // {
  //   ...apiKeyConfigurationTableFields,
  //   ...schedulingApiKeyConfigurationTableFields,
  //   searchConfiguration: { ...searchConfigurationTableFields }
  // }
  const updatedPermissions = {
    ...apiAndSchedulingConfigs,
    searchConfiguration,
  };

  return Api.sdkApi.updateSdkPermission(updatedPermissions);
}

function updateReferralGuidanceConfig({ id, referralGuidanceConfiguration }) {
  return Api.sdkApi.updateReferralGuidanceConfig({
    id,
    newConfig: _.omit(referralGuidanceConfiguration, 'communicationDisplayName'),
  });
}

async function updateSdkPermission({ id, allowedHealthSystemIds, permission, webhookFields }) {
  const referralGuidanceConfiguration = _.pick(permission, referralGuidanceFields);

  await Promise.all([
    updateApiKeyConfig({
      allowedHealthSystemIds,
      permission,
      webhookFields,
    }),
    updateReferralGuidanceConfig({ id, referralGuidanceConfiguration }),
  ]);
}

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getSdkPermission: {
      handler: ({ params }) => async () => {
        const rawPermission = await Api.sdkApi.backofficeGetSdkPermissions(params.id);
        const searchConfiguration = _.get(rawPermission, 'searchConfiguration', {});

        return {
          ...searchConfiguration,
          ..._.omit(rawPermission, ['searchConfiguration']),
        };
      },
      resultPropName: 'permission',
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while fetching api key data'),
    },
    getReferralGuidanceConfig: {
      handler: ({ params }) => () => Api.sdkApi.getReferralGuidanceConfig({ id: params.id }),
      resultPropName: 'referralGuidanceConfiguration',
      isReady: ({ getReferralGuidanceConfigTracker }) => getReferralGuidanceConfigTracker.executed,
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while fetching referral guidance configuration'),
    },
  }),
  withProps(({ permission, referralGuidanceConfiguration }) => ({
    permission: {
      ...permission,
      ...referralGuidanceConfiguration,
    },
  })),
  withFetchers({
    onSubmit: {
      handler: ({ params }) => ({ allowedHealthSystemIds, ...permission }) =>
        updateSdkPermission({
          id: params.id,
          allowedHealthSystemIds,
          permission,
        }),
      onSuccess: ({ notification, getSdkPermission, getReferralGuidanceConfig }) => {
        Promise.all([getSdkPermission(), getReferralGuidanceConfig()]);

        return notification.success('Success!', 'Api key updated');
      },
      onError({ notification }, error) {
        console.error(`Failed to update SDK config!`, { error });

        return notification.error('Oops', 'An error occurred when submitting form');
      },
    },
    updateWebhookUrl: {
      handler: ({ params }) => ({ webhookFields, permission }) =>
        updateSdkPermission({
          id: params.id,
          allowedHealthSystemIds: permission.allowedHealthSystemIds,
          permission,
          webhookFields,
        }),
      onSuccess: ({ notification }) => {
        notification.success(
          'Success!',
          'Webhook data successfuly updated, Please refresh the page',
        );
      },
      onError: ({ notification }) => {
        notification.error('Oops', 'Failed to update Webhook data');
      },
    },
  }),
)(UpdateApiKey);
