import React from 'react';
import { compose } from 'recompose';
import { defineMessages } from 'react-intl';
import { isFunction } from 'lodash';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { ThreeBounceSpinner } from '../../../../../../components/ui/spinner/index';
import Api from '../../../../../../api';

const messages = defineMessages({
  successTitle: {
    defaultMessage: 'Success!',
    id: 'backpack.updateNamespaceModal.successTitle',
  },
  successMessage: {
    defaultMessage: 'Successfully updated the provider namespace',
    id: 'backpack.updateNamespaceModal.successMessage',
  },
  errorTitle: {
    defaultMessage: 'Error!',
    id: 'backpack.updateNamespaceModal.errorTitle',
  },
  errorMessage: {
    defaultMessage: 'Failed to update namespace',
    id: 'backpack.updateNamespaceModal.errorMessage',
  },
});

const enhanced = compose(
  injectNotification,
  withStateFetchersOnMount({
    updateNs: {
      handler: ({ flowData }) => () =>
        Api.namespaceManagerApi.updateNamespace({
          ...flowData,
        }),
      onSuccess: ({ onSuccess, handleClose, notification }) => {
        notification.success(messages.successTitle, messages.successMessage);
        if (isFunction(onSuccess)) onSuccess();

        handleClose();
      },
      onError: ({ notification }) => notification.error(messages.errorTitle, messages.errorMessage),
    },
  }),
)(() => <ThreeBounceSpinner />);

export default {
  component: enhanced,
  transitions: {
    NEXT: null,
    PREV: null,
  },
  mapFlowDataToProps: flowData => ({
    flowData,
    handleClose: flowData.handleClose,
    onSuccess: flowData.onSuccess,
  }),
  mapPayloadToFlowData: () => ({}),
};
