import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { Field, Form } from 'formik';
import { object, string } from 'yup';

import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import Api from '../../../../../../api';

import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import BkmdModalButton from '../../../../../../components/bkmdModalButton/bkmdModalButton';
import injectNotification from '../../../../../../store/notification/injectNotification';

import FormActionButtons from '../../../../../../components/molecules/FormSubmissionButtons/FormActionButtons';
import { CleanFormikInput } from '../../../../../../components/form/formik/cleanFormikInput';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { CleanFormikSelect } from '../../../../../../components/form/formik/cleanFormikSelect';

const schema = object().shape({
  name: string().required('Health system name is required'),
  ehrIntegrationId: string().nullable(true),
  namespace: string().required('Health system namespace is required'),
  integrationType: string().required('Integration type is required'),
});

const AddHealthSystemView = ({ addHealthSystem, initialValues, allNamespaces }) => (
  <div>
    <WrappedFormik
      initialValues={initialValues}
      onSubmit={addHealthSystem}
      validationSchema={schema}
      render={({ isValid }) => (
        <Form>
          <Field name="name" label="Health system name" component={CleanFormikInput} />
          <Field
            name="integrationType"
            placeholder="Integration type"
            options={allNamespaces.healthSystemIntegrationTypes}
            component={CleanFormikSelect}
            hotOptions
          />
          <Field
            name="namespace"
            placeholder="Namespace"
            options={allNamespaces.healthSystemActualNamespaces}
            component={CleanFormikSelect}
            hotOptions
          />
          <FormActionButtons primaryButtonText="Submit" primaryDisabled={!isValid} />
        </Form>
      )}
    />
  </div>
);

AddHealthSystemView.propTypes = {
  addHealthSystem: PropTypes.func.isRequired,
  allNamespaces: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
};

AddHealthSystemView.defaultProps = {
  initialValues: { name: '' },
};

const AddHealthSystemForm = compose(
  injectNotification,
  withStateFetchersOnMount({
    getHealthSystemIntegrationTypes: {
      handler: () => async () => {
        const singleTenantNamespaces = await Api.namespaceManagerApi.getSingleTenantNamespaces();
        const virtualNamespaces = await Api.namespaceManagerApi.getVirtualNamespaces();
        
        let allVirtualNamespaces = [];
        Object.values(virtualNamespaces).forEach(domain => {
          allVirtualNamespaces = _.concat(allVirtualNamespaces, domain);
        });

        let multiTenantNamespaces = [];
        Object.keys(virtualNamespaces).forEach(tenant => {
          multiTenantNamespaces = _.concat(multiTenantNamespaces, tenant);
        });
        
        const formattedNamespaces = singleTenantNamespaces.map(ns => 
          ({ value: ns.name, label: ns.name }),
        );
        const formattedVirtualNamespaces = allVirtualNamespaces.map(virtualNs => ({
          value: virtualNs,
          label: virtualNs,
        }));
        const formattedMultiTenantNamespaces = multiTenantNamespaces.map(multiTenantNs => ({
          value: multiTenantNs,
          label: multiTenantNs,
        }));

        const healthSystemIntegrationTypes = _.concat(formattedNamespaces, formattedVirtualNamespaces);
        const healthSystemActualNamespaces =  _.concat(formattedMultiTenantNamespaces, formattedNamespaces);

        return {
          healthSystemIntegrationTypes,
          healthSystemActualNamespaces,
        }
      },
      resultPropName: 'allNamespaces',
      isReady: ({ allNamespaces }) => !_.isEmpty(allNamespaces),
    },
  }),
  withFetchers({
    addHealthSystem: {
      handler: () => data => {
        const newData = {
          ...data,
          integrationType: _.get(data.integrationType, 'value', null),
          namespace: _.get(data.namespace, 'value', null),
        };

        return Api.clinicsApi.createHealthSystem(newData);
      },
      onSuccess: ({ onCreateHealthSystem, closeModal }, result) => {
        onCreateHealthSystem(result);
        closeModal();
      },
      onError: ({ notification }) =>
        notification.error(
          'Create Health system failed',
          'Please check if this health system already exists',
        ),
    },
  }),
)(AddHealthSystemView);

AddHealthSystemForm.propTypes = {
  onCreateHealthSystem: PropTypes.func,
};

AddHealthSystemForm.defaultProps = {
  onCreateHealthSystem: _.noop,
};

export default compose(
  withProps({
    title: 'Add health system',
    buttonText: 'Create',
    component: AddHealthSystemForm,
  }),
)(BkmdModalButton);
