/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import _ from 'lodash';
import { reduxForm, Field } from 'redux-form';
import * as RemovePermissionFormDefs from './removePermissionFormDefs';
import * as AclResourceType from '../../../../../../model/enum/aclResourceType';
import { createValidator } from '../../../../../../utils/joiValidator';

const resourceTypeOptions = function resourceTypeOptions(hasPhiAccess) {
  return _.map(AclResourceType, (value, label) => ({ value, label })).filter(resource =>
    hasPhiAccess ? resource : resource.value !== AclResourceType.BACK_OFFICE_PHI_ACCESS,
  );
};

@autobind
class RemovePermissionForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    hasPhiAccess: PropTypes.bool.isRequired,
  };

  render() {
    const { hasPhiAccess, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field
              options={resourceTypeOptions(hasPhiAccess)}
              placeholder="Resource Type"
              {...RemovePermissionFormDefs.fields.resourceType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field label="Resource Id (Optional)" {...RemovePermissionFormDefs.fields.resourceId} />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: RemovePermissionFormDefs.name,
  validate: createValidator(Joi.object().keys(RemovePermissionFormDefs.schema)),
})(RemovePermissionForm);
