/**
 * Created by chenro on 31/08/2017.
 */
import _ from 'lodash';
import React from 'react';
import Joi from 'joi-browser';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import BookMdUtil from '../../../../../utils/util';
import * as SendManualFormDef from './sendManualFormDef';
import { createValidator } from '../../../../../utils/joiValidator';

@autobind
class SendManualForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    metadata: PropTypes.object.isRequired,
    selectedChannel: PropTypes.string,
    selectedTemplate: PropTypes.string,
    selectedRecipientType: PropTypes.string,
  };

  static defaultProps = {
    selectedChannel: undefined,
    selectedTemplate: undefined,
    selectedRecipientType: undefined,
  };

  render() {
    const {
      selectedChannel,
      selectedTemplate,
      selectedRecipientType,
      metadata,
      handleSubmit,
    } = this.props;
    const _selected = selectedChannel || _.first(_.keys(metadata.channel));
    return (
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field
              label="Channel"
              options={BookMdUtil.toSelectOptions(metadata.channels)}
              {...SendManualFormDef.fields.channel}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label="Template Id"
              options={BookMdUtil.toSelectOptions(metadata.templates[_selected])}
              {...SendManualFormDef.fields.templateId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label="Recipient Type"
              options={BookMdUtil.toSelectOptions(metadata.recipientTypes)}
              {...SendManualFormDef.fields.recipientType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label="Params object"
              {...SendManualFormDef.fields.params}
              channel={selectedChannel}
              templateId={selectedTemplate}
              recipient={selectedRecipientType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field label="Entity Identifier" {...SendManualFormDef.fields.entityIdentifier} />
          </div>
        </div>
      </form>
    );
  }
}

const fieldSelector = formValueSelector(SendManualFormDef.name);

export default compose(
  reduxForm({
    form: SendManualFormDef.name,
    validate: createValidator(Joi.object().keys(SendManualFormDef.schema)),
  }),
  connect(state => ({
    selectedChannel: fieldSelector(state, SendManualFormDef.fields.channel.name),
    selectedTemplate: fieldSelector(state, SendManualFormDef.fields.templateId.name),
    selectedRecipientType: fieldSelector(state, SendManualFormDef.fields.recipientType.name),
  })),
)(SendManualForm);
