import Joi from 'joi-browser';
import { ReduxFormCleanCheckbox } from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-aso-group-tier';

export const fields = id => ({
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  overrideColor: {
    className: 'margin-top-30',
    name: 'overrideColor',
  },
  showInNetwork: {
    name: 'showInNetwork',
    id: `showInNetwork${id}`,
    component: ReduxFormCleanCheckbox,
    parse: value => !!value,
  },
});

export const schema = {
  id: Joi.number().required(),
  asoGroupId: Joi.number().required(),
  name: Joi.string().required(),
  title: Joi.string().required(),
  overrideColor: Joi.string(),
  description: Joi.string().required(),
  showInNetwork: Joi.boolean().required(),
};
