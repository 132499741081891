/* eslint-disable react/prop-types */
import React from 'react';
import map from 'lodash/map';

export default function AlertsContainer({ infos, errors, warnings }) {
  return (
    <div className="alerts-container">
      {map(errors, Error => (
        <div className="alert alert-danger" role="alert">
          <Error />
        </div>
      ))}
      {map(warnings, Warning => (
        <div className="alert alert-warning" role="alert">
          <Warning />
        </div>
      ))}
      {map(infos, Info => (
        <div className="panel panel-default">
          <div className="panel-body">
            <Info />
          </div>
        </div>
      ))}
    </div>
  );
}
