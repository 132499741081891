import React from 'react';
import { withRouter } from 'react-router';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import { BkmdDomains } from '../../../../../../model/enum/bkmdDomain';

import './memberGoTo.less';

const gotoMessages = defineMessages({
  goToMember: {
    defaultMessage: 'Go to Vim Member',
    id: 'appointmentProductForm.member.goToMember',
  },
  goToPayerMember: {
    defaultMessage: 'Go to Payer Member',
    id: 'appointmentProductForm.member.goToPayerMember',
  },
});

const VimMemberGoTo = ({ member, router }) => {
  if (!member) return null;

  return (
    <div className="member-go-to">
      <button
        onClick={() => router.push(`/secure/domainMember/${member.id}`)}
        className="btn btn-small btn-blue text-semibold action-button"
      >
        <FormattedMessage {...gotoMessages.goToMember} />
      </button>
      {member.externalId && (
        <button
          onClick={() =>
            router.push({
              pathname: `/secure/domainMember/${member.externalId}`,
              search: `?targetDomain=${member.externalIdDomain}`,
            })}
          className="btn btn-small btn-blue text-semibold action-button"
        >
          <FormattedMessage {...gotoMessages.goToPayerMember} />
        </button>
      )}
    </div>
  );
};

const PayerMemberGoTo = ({ member, router }) => {
  if (!member) return null;

  return (
    <div className="member-go-to">
      <button
        onClick={() => router.push(`/secure/domainMember/${member.id}`)}
        className="btn btn-small btn-blue text-semibold action-button"
      >
        <FormattedMessage {...gotoMessages.goToPayerMember} />
      </button>
    </div>
  );
};

export default compose(
  withRouter,
  branch(({ member }) => member.domain !== BkmdDomains.VIM, renderComponent(PayerMemberGoTo)),
)(VimMemberGoTo);
