import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import actionTracker from 'Store/tools/actionTracker/actionTrackerComponent';
import { MICROSERVICE_ACCESS } from 'Model/enum/aclResourceType';
import { WRITE } from 'Model/enum/permissionLevel';
import Button from '../../../../storybook/src/components/atoms/Button/Button';
import injectNotification from '../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import AclGuard from '../../../../store/auth/aclGuard';
import AttachAppointmentAction from '../clinicManagement/referral/actions/attachAppointment';
import Action from '../components/action';
import * as selectors from '../../../../store/backoffice/appointmentRequest/selectors';
import * as UpdateAppointmentRequestDefs from './actions/appointmentRequestFormDefs';
import EntityForm from '../components/entityForm/entityForm';
import {
  resendEmailRequest,
  RESEND_EMAIL_REQUEST,
  getAppointmentRequestById,
  GET_APPOINTMENT_REQUEST_BY_ID,
  updateAppointmentRequest,
  checkAndUpdateAppointmentRequestStatus,
  CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS,
  attachAppointmentToRequest,
  ATTACH_APPOINTMENT_REQUEST,
  deleteAppointmentRequest,
  DELETE_APPOINTMENT_REQUEST,
  resendAppointmentAction,
} from '../../../../store/backoffice/appointmentRequest/actions';

import CollapsibleAppointment from '../clinicManagement/productUse/components/collapsibleAppointment';
import CollapsibleProvider from '../clinicManagement/productUse/components/collapsibleProvider';
import CollapsiblePatient from '../clinicManagement/productUse/components/collapsiblePatient';

const messages = defineMessages({
  errorMassage: {
    defaultMessage: 'Error occurred when updating status request',
    id: 'backpack.error.submit',
  },
  successMassage: {
    defaultMessage: 'Success  updating status request',
    id: 'backpack.success.submit',
  },
  successRequestMassage: {
    defaultMessage: 'Success sending appointment request',
    id: 'backpack.success.sendRequest',
  },
});

@autobind
class UpdateAppointmentRequestPage extends React.Component {
  static propTypes = {
    onCheckStatus: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired, // from router
    params: PropTypes.object.isRequired, // from router
    appointmentRequest: PropTypes.object.isRequired, // from redux
    getAppointmentRequestById: PropTypes.func.isRequired, // from redux
    getAppointmentRequestByIdTracker: PropTypes.object.isRequired, // from redux
    attachAppointmentToRequest: PropTypes.func.isRequired, // from redux
    deleteAppointmentRequest: PropTypes.func.isRequired, // from redux
    resendEmailRequest: PropTypes.func.isRequired,
    resendAppointmentRequestEmail: PropTypes.func.isRequired,
    updateAppointmentRequest: PropTypes.func.isRequired, // from redux
  };

  componentWillMount() {
    const { id } = this.props.params;
    this.props.getAppointmentRequestById(id);
  }

  componentWillReceiveProps(nextProps) {
    const { getAppointmentRequestByIdTracker, router } = nextProps;
    if (getAppointmentRequestByIdTracker.hasError) {
      router.push('/secure/appointmentRequests/');
    }
  }

  onAttachAppointment(attachAppointmentData) {
    const { attachAppointmentToRequest, appointmentRequest } = this.props;
    attachAppointmentToRequest({
      appointmentRequestId: appointmentRequest.id,
      domainMemberId: appointmentRequest.domainMemberId,
      memberDomain: appointmentRequest.domain,
      date: attachAppointmentData.date,
      timezone: attachAppointmentData.timezone,
      targetNpi: attachAppointmentData.targetNpi,
      locationId: attachAppointmentData.locationId,
    });
  }

  deleteRequest() {
    const { deleteAppointmentRequest, appointmentRequest } = this.props;
    deleteAppointmentRequest(appointmentRequest.id);
  }

  resendEmailRequest() {
    const { resendEmailRequest, appointmentRequest } = this.props;
    resendEmailRequest(appointmentRequest.id, appointmentRequest.domain);
  }

  render() {
    const {
      appointmentRequest,
      updateAppointmentRequest,
      attachAppointmentToRequest,
      onCheckStatus,
      resendAppointmentRequestEmail,
    } = this.props;
    return (
      <div>
        <div className="action-group">
          <AttachAppointmentAction
            attachAppointment={attachAppointmentToRequest}
            attachAppointmentAction={ATTACH_APPOINTMENT_REQUEST}
            onAttachAppointment={this.onAttachAppointment}
          />
          <Action
            name="deleteAppointmentRequest"
            title="Delete request"
            actionTrackerId={DELETE_APPOINTMENT_REQUEST.SOURCE}
            onSubmit={this.deleteRequest}
          />
          <AclGuard type={MICROSERVICE_ACCESS} permission={WRITE} id="SCHEDULING">
            <Action
              name="resendAppointmentRequest"
              title="Resend request"
              message=""
              actionTrackerId={RESEND_EMAIL_REQUEST.SOURCE}
              onSubmit={resendAppointmentRequestEmail}
            />
          </AclGuard>
          {appointmentRequest.trackingId ? (
            <Button type="small" className="btn-blue action-button" onClick={onCheckStatus}>
              Check status
            </Button>
          ) : null}
        </div>
        <CollapsiblePatient member={appointmentRequest.member} />
        <CollapsibleProvider
          provider={appointmentRequest.provider}
          locationId={appointmentRequest.requestedLocationId}
        />
        <CollapsibleAppointment appointment={appointmentRequest.appointment} />
        <EntityForm
          name={UpdateAppointmentRequestDefs.name}
          title="Update Appointment Request:"
          schemaDef={UpdateAppointmentRequestDefs.schema}
          fieldsDef={UpdateAppointmentRequestDefs.fields}
          entity={appointmentRequest}
          onSubmit={updateAppointmentRequest}
        />
      </div>
    );
  }
}

export default compose(
  injectNotification,
  withRouter,
  connect(
    state => ({
      appointmentRequest: selectors.getAppointmentRequest(state),
    }),
    {
      getAppointmentRequestById,
      checkAndUpdateAppointmentRequestStatus,
      updateAppointmentRequest,
      attachAppointmentToRequest,
      deleteAppointmentRequest,
      resendEmailRequest,
    },
  ),
  actionTracker({
    getAppointmentRequestByIdTracker: GET_APPOINTMENT_REQUEST_BY_ID.SOURCE,
    checkAndUpdateAppointmentRequestIdTracker: CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS.SOURCE,
  }),
  withFetchers({
    onCheckStatus: {
      handler: ({ checkAndUpdateAppointmentRequestStatus, appointmentRequest }) => () =>
        checkAndUpdateAppointmentRequestStatus(appointmentRequest.id),
      onError: ({ notification }) => notification.error('', messages.errorMassage),
      onSuccess: ({ notification }) => notification.success('', messages.successMassage),
    },
    resendAppointmentRequestEmail: {
      handler: ({ appointmentRequest }) => () =>
        resendAppointmentAction(
          appointmentRequest.token,
        ),
      onSuccess: ({ notification }) => {
        notification.success('', messages.successRequestMassage);
      },
      onError: ({ notification }, err) => {
        notification.error('', `Error Sending Mail: ${err?.message}`);
      },
    },
  }),
)(UpdateAppointmentRequestPage);
