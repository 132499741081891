/**
 * Created by chenrozenes on 13/06/2017.
 */
import React from 'react';
import { Link } from 'react-router';
import SendManualAction from './manual/sendManualAction';

import './communication.less';

export default function CommunicationPage() {
  return (
    <div>
      <h1>Communication</h1>
      <br />
      <br />
      <div className="action-group">
        <SendManualAction />
      </div>
      <div>
        <Link to="/secure/communication/chat" className="comm-page-link">
          <i className="fa fa-random title-24" />
          &nbsp; &nbsp;
          <span className="text-18">Chat</span>
        </Link>
        <br />
        <Link to="/secure/communication/mapping" className="comm-page-link">
          <i className="fa fa-random title-24" />
          &nbsp; &nbsp;
          <span className="text-18">Data Mapping</span>
        </Link>
        <br />
        <Link to="/secure/communication/history" className="comm-page-link">
          <i className="fa fa-history title-24" />
          &nbsp; &nbsp;
          <span className="text-18">History</span>
        </Link>
        <br />
        <Link to="/secure/communication/templates" className="comm-page-link">
          <i className="fa fa-comments title-24" />
          &nbsp; &nbsp;
          <span className="text-18">Templates</span>
        </Link>
      </div>
    </div>
  );
}
