/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withPropsOnChange, compose, withState, pure } from 'recompose';
import Chat from './chat';
import MemberSearch from './memberSearch';
import { getChatHistoryOfMember } from '../../../../../store/backoffice/communication/actions';
import * as selectors from '../../../../../store/backoffice/communication/selectors';
import ThreeBounceSpinner from '../../../../../components/ui/spinner/threeBounceSpinner';
import ChatMessageType from './chatMessageType';

const renderChat = ({ chatHistory, reloadChatHistory, isLoading, ...props }) => {
  const domainMemberId = _.get(props, 'params.domainMemberId');
  if (!domainMemberId) return null;
  if (isLoading) return <ThreeBounceSpinner />;
  if (!chatHistory) return <div>Failed to load this chat</div>;

  return (
    <div>
      <h3>{chatHistory.fullName}</h3>
      <Chat
        messages={chatHistory.messages}
        reloadMessages={reloadChatHistory}
        domainMemberId={domainMemberId}
      />
    </div>
  );
};

const ChatPage = props => (
  <div className="chat-page">
    <MemberSearch />
    {renderChat(props)}
  </div>
);

ChatPage.propTypes = {
  chatHistory: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(ChatMessageType.isRequired).isRequired,
  }),
  reloadChatHistory: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    domainMemberId: PropTypes.string,
  }),
};
ChatPage.defaultProps = {
  chatHistory: null,
  reloadChatHistory: null,
  params: null,
};

const loadMessagesOnMemberChange = withPropsOnChange(
  (old, next) => _.get(old, 'params.domainMemberId') !== _.get(next, 'params.domainMemberId'),
  ({ getChatHistoryOfMember, setIsLoading, ...props }) => {
    const domainMemberId = _.get(props, 'params.domainMemberId');
    if (!domainMemberId) return null;

    const reloadChatHistory = () => {
      setIsLoading(true);
      return getChatHistoryOfMember(domainMemberId).finally(() => setIsLoading(false));
    };
    reloadChatHistory();

    return { reloadChatHistory };
  },
);

const enhance = compose(
  pure,
  connect(
    state => ({
      chatHistory: selectors.getChatHistory(state),
    }),
    { getChatHistoryOfMember },
  ),
  withState('isLoading', 'setIsLoading', false),
  loadMessagesOnMemberChange,
);

export default enhance(ChatPage);
