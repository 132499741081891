/**
 * Created by moran on 5/29/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { injectIntl, intlShape } from 'react-intl';
import CleanSelect from './cleanSelect';
import BkmdDomain from '../../model/enum/bkmdDomain';

@autobind
class CleanDomainSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  onChange(e) {
    this.props.onChange(e.value);
  }

  render() {
    const { intl, ...other } = this.props;
    return (
      <CleanSelect
        label="Domain"
        {...other}
        options={BkmdDomain.toSelectOptions(intl)}
        onChange={this.onChange}
      />
    );
  }
}

export default injectIntl(CleanDomainSelect);
