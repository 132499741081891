import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import Action from '../../components/action';
import AclGuard from '../../../../../store/auth/aclGuard';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import EntityForm from '../../components/entityForm/entityForm';
import * as TemplateFormDefs from './actions/templateFormDef';
import { BACK_OFFICE_PHI_ACCESS } from '../../../../../model/enum/aclResourceType';
import {
  DELETE_TEMPLATE,
  deleteTemplate,
  getTemplateById,
  UPDATE_TEMPLATE,
  updateTemplate,
  getMetadata as getCommunicationMetaData,
} from '../../../../../store/backoffice/communication/actions';
import { getEnrichedCurrTemplate } from '../../../../../store/backoffice/communication/templateSelectors';
import { getMetadata } from '../../../../../store/backoffice/communication/selectors';

@autobind
class UpdateTemplate extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    getTemplateById: PropTypes.func.isRequired,
    template: PropTypes.object.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    deleteTracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      params: { templateId },
    } = this.props;
    if (templateId) this.props.getTemplateById(templateId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.props.router.push('/secure/communication/templates');
    }
  }

  onSubmit(form) {
    const data = _.pick(form, [
      'templateId',
      'alias',
      'notificationType',
      'channel',
      'content',
      'dataFamilies',
    ]);
    this.props.updateTemplate({ data, id: this.props.template.id });
  }

  delete() {
    this.props.deleteTemplate({ id: this.props.template.id });
  }

  render() {
    const { template } = this.props;
    return (
      <div>
        <AclGuard type={BACK_OFFICE_PHI_ACCESS} permission={READ}>
          <Action
            name="Delete"
            title="Delete"
            actionTrackerId={DELETE_TEMPLATE.SOURCE}
            onSubmit={this.delete}
          />
        </AclGuard>

        <EntityForm
          name={TemplateFormDefs.name}
          title="Update Template:"
          submitText="Update"
          schemaDef={TemplateFormDefs.schema}
          fieldsDef={TemplateFormDefs.fields}
          entity={template}
          onSubmit={this.onSubmit}
          readOnlyByAcl={BACK_OFFICE_PHI_ACCESS}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(getCommunicationMetaData, getMetadata),
  actionTracker({
    deleteTracker: DELETE_TEMPLATE.SOURCE,
    updateTracker: UPDATE_TEMPLATE.SOURCE,
  }),
  connect(
    state => ({
      template: getEnrichedCurrTemplate(state),
    }),
    { updateTemplate, getTemplateById, deleteTemplate },
  ),
)(UpdateTemplate);
