import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages } from 'react-intl';
import { compose } from 'redux';
import { withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';

import BkmdModal from '../../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import Loader from '../../../../../../storybook/src/components/atoms/Loader/Loader';
import { BkmdGrid, BkmdGridColumn } from '../../../../../../components/grid';
import {
  getFailedAppointmentTypes,
  getInsertedAppointmentTypes,
  getUpdatedAppointmentTypes,
  getDisabledAppointmentTypes,
} from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/selectors';
import { CREATE_BULK_APPOINTMENT_TYPE } from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/actions';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';

const messages = defineMessages({
  title: {
    defaultMessage: 'Load CSV appointment types results',
    id: 'csvAppointment.results.title',
  },
  successInsert: {
    defaultMessage: '{insertedNum} appointment types added successfully',
    id: 'csvAppointment.results.successInsert',
  },
  successUpdate: {
    defaultMessage: '{updatedNum} appointment types updated successfully',
    id: 'csvAppointment.results.successUpdate',
  },
  successDisabled: {
    defaultMessage: '{disabledNum} appointment types disabled successfully',
    id: 'csvAppointment.results.successUpdate',
  },
  failed: {
    defaultMessage: 'failed Appointment Types:',
    id: 'csvAppointment.results.failed',
  },
  finished: {
    defaultMessage: 'finished',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.finish',
  },
});

@autobind
class CsvAppointmentTypesResultsModal extends React.PureComponent {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    insertedNum: PropTypes.number.isRequired,
    updatedNum: PropTypes.number.isRequired,
    disabledNum: PropTypes.number.isRequired,
    failedAppointmentTypes: PropTypes.number.isRequired,
    createBulkAppointmentTypeTracker: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.createBulkAppointmentTypeTracker.inProgress ||
      (nextProps.createBulkAppointmentTypeTracker.finished &&
        !nextProps.createBulkAppointmentTypeTracker.hasError)
    ) {
      this.setState({ isOpen: true });
    }
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      failedAppointmentTypes,
      insertedNum,
      updatedNum,
      disabledNum,
      createBulkAppointmentTypeTracker,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <BkmdModal
        isOpen={isOpen}
        closeOnBackdrop
        handleClose={this.handleClose}
        name="name"
        className="fixed-header overflow-visible extra-large-modal"
      >
        <ModalHeader title={messages.title} onClose={this.handleClose} />
        <div className="dialog-body">
          {createBulkAppointmentTypeTracker.inProgress ? (
            <Loader type="dots" />
          ) : (
            <div>
              {insertedNum > 0 && (
                <div className="text-18 text-center text-semibold text-dark">
                  <FormattedMessage {...messages.successInsert} values={{ insertedNum }} />
                </div>
              )}
              {updatedNum > 0 && (
                <div className="text-18 text-center text-semibold text-dark">
                  <FormattedMessage {...messages.successUpdate} values={{ updatedNum }} />
                </div>
              )}
              {disabledNum > 0 && (
                <div className="text-18 text-center text-semibold text-dark">
                  <FormattedMessage {...messages.successDisabled} values={{ disabledNum }} />
                </div>
              )}
              <div>
                <div className="text-16 text-center text-semibold text-dark">
                  <FormattedMessage {...messages.failed} />
                </div>
                <BkmdGrid data={failedAppointmentTypes} totalCount={failedAppointmentTypes.length}>
                  <BkmdGridColumn id="rowNumber" title="rowNumber" />
                  <BkmdGridColumn id="isTelemedicine" title="isTelemedicine" />
                  <BkmdGridColumn id="type" title="type" />
                  <BkmdGridColumn id="key" title="key" />
                  <BkmdGridColumn id="duration" title="duration (in minutes)" />
                  <BkmdGridColumn id="minTimeBeforeBooking" title="minTimeBeforeBooking" />
                  <BkmdGridColumn id="error" title="error" width={7000} />
                </BkmdGrid>
              </div>
            </div>
          )}
        </div>
      </BkmdModal>
    );
  }
}

export default compose(
  connect(state => ({
    failedAppointmentTypes: getFailedAppointmentTypes(state),
    insertedNum: _.get(getInsertedAppointmentTypes(state), 'length', 0),
    updatedNum: _.get(getUpdatedAppointmentTypes(state), 'length', 0),
    disabledNum: _.get(getDisabledAppointmentTypes(state), 'length', 0),
  })),
  actionTracker({
    createBulkAppointmentTypeTracker: CREATE_BULK_APPOINTMENT_TYPE.SOURCE,
  }),
  withPropsOnChange(['failedAppointmentTypes'], ({ failedAppointmentTypes }) => {
    const mapDurationToMinutes = apptType => ({
      ...apptType,
      duration: apptType.duration ? apptType.duration / 60000 : undefined,
    });

    return {
      failedAppointmentTypes: _.map(failedAppointmentTypes, mapDurationToMinutes),
    };
  }),
)(CsvAppointmentTypesResultsModal);
