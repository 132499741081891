/**
 * Created by guyavarham on 21/12/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import CleanTagList from '../../../../../components/form/cleanTagList';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import { dataMappingSelector } from '../../../../../store/backoffice/communication/selectors';
import { getDataMapping } from '../../../../../store/backoffice/communication/actions';

// TODO: backpack
const values = function getValues(enumm) {
  return _.map(enumm, key => ({ value: key, label: key }));
};
/**
 * Multiple selection for dataFamilies per template. Based on CommunicationManage DataMapping.
 */
@autobind
class DataFamiliesPicker extends React.PureComponent {
  static propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    dataMappingKeys: PropTypes.array.isRequired,
    label: PropTypes.string,
  };

  static defaultProps = {
    label: 'Choose Data Families',
  };

  onChange(newState) {
    this.props.onChange(_.map(newState, ({ value }) => value));
  }

  render() {
    const { dataMappingKeys, value, label } = this.props;

    return (
      <CleanTagList
        placeholder={label}
        label={label}
        suggestions={values(dataMappingKeys)}
        value={values(value)}
        onChange={this.onChange}
      />
    );
  }
}

export default compose(
  fetchNotEmpty(getDataMapping, dataMappingSelector, {
    renderAlways: false,
    loading: true,
  }),
  connect(state => ({
    dataMappingKeys: _.map(_.keys(dataMappingSelector(state)), _.toUpper),
  })),
)(DataFamiliesPicker);
