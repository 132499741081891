import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import EntityForm from '../../components/entityForm/entityForm';
import { getHistoryRecord } from '../../../../../store/backoffice/communication/actions';
import * as selectors from '../../../../../store/backoffice/communication/selectors';
import * as FormDefs from './historyRecordFormDef';

@autobind
class HistoryPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    getHistoryRecord: PropTypes.func.isRequired,
    historyRecord: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      params: { historyId },
    } = this.props;
    this.props.getHistoryRecord(historyId);
  }

  render() {
    const { historyRecord } = this.props;
    return (
      <div>
        <EntityForm
          name={FormDefs.name}
          title="History record"
          schemaDef={FormDefs.schema}
          fieldsDef={FormDefs.fields}
          entity={historyRecord}
          readOnly
        />
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      historyRecord: selectors.getHistoryRecord(state),
    }),
    { getHistoryRecord },
  ),
)(HistoryPage);
