import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withHandlers } from 'recompose';
import CleanCheckbox from '../../../../../../../components/form/cleanCheckbox';
import ClickableDiv from '../../../../../../../components/clickableElement/clickableDiv';

import './picturesToggle.less';

function PictureView({ npi, url, picturesTimestamp, onClick }) {
  return (
    <div key={npi} className="pic-review">
      <ClickableDiv className="pull-left" clickHandler={onClick}>
        <img src={`${url}?${picturesTimestamp}`} alt={npi} />
      </ClickableDiv>
      <div className="text-center">{npi}</div>
    </div>
  );
}

PictureView.propTypes = {
  npi: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  picturesTimestamp: PropTypes.number.isRequired,
};

const ToggledPicture = withHandlers({
  onChange: ({ npi, onChange = _.noop }) => () => onChange(npi),
  onClick: ({ npi, onClick = _.noop }) => () => onClick(npi),
})(PictureView);

function PicturesToggle({ data, picturesTimestamp }) {
  return (
    <div className="pictures-container">
      {_.map(data, ({ npi, url }) => (
        <ToggledPicture
          key={npi}
          npi={npi}
          url={url}
          picturesTimestamp={picturesTimestamp}
        />
      ))}
    </div>
  );
}

PicturesToggle.propTypes = {
  data: PropTypes.object,
  picturesTimestamp: PropTypes.number.isRequired,
};

PicturesToggle.defaultProps = {
  data: [],
};

export default PicturesToggle;
