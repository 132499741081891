/**
 * Created by anna on 26/07/2017.
 */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import * as EditReviewFormDef from './editReviewFormDef';
import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';

class EditReviewForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field name="review" component={ReduxFormCleanInput} />
          </div>
        </div>
      </form>
    );
  }
}

export default compose(
  connect(state => ({
    initialValues: state.backoffice.reviews.details,
  })),
  reduxForm({
    form: EditReviewFormDef.name,
  }),
)(EditReviewForm);
