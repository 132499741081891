import steps from '../steps';
import fetchNsStep from '../steps/fetchNsStep';
import ehrFormStep from '../steps/ehrFormStep';
import updateNamespaceStep from '../steps/updateNamespaceStep';

export default {
  name: 'UpdateProviderNamespaceManagement',
  definitions: {
    [steps.FETCH_NS_DETAILS]: fetchNsStep,
    [steps.EHR_FORM]: ehrFormStep,
    [steps.UPDATE_NAMESPACE]: updateNamespaceStep,
  },
};
