import App from '../app/App';
import NotFound from '../../../../components/pages/notfound/pageNotFound';
import Unauthorized from '../../../../components/pages/auth/unauthorized';
import BlockedAccount from '../../../../components/pages/auth/blockedAccount';
import LoginPage from '../auth/loginPage';
import LoadingPage from '../../../../components/ui/loadingPage/loadingPage';
import ResetPassword from '../../../../flows/resetPasswordFlow';
import SecureRoutes from './secureRoutes';

export default {
  path: '/',
  component: App,
  childRoutes: [
    SecureRoutes,
    { path: 'signIn', name: 'signIn', component: LoginPage.default || LoginPage },
    {
      path: 'loading',
      name: 'loading',
      component: LoadingPage.default || LoadingPage,
    },
    {
      name: 'unauthorized',
      path: 'unauthorized',
      component: Unauthorized.default || Unauthorized,
    },
    {
      name: 'blockedAccount',
      path: 'blockedAccount',
      component: BlockedAccount.default || BlockedAccount,
    },
    {
      path: 'resetPassword',
      name: 'resetPassword',
      component: ResetPassword,
    },
    { path: '*', name: 'NotFound', component: NotFound.default || NotFound },
  ],
  indexRoute: {
    name: 'backoffice Landing',
    component: LoginPage.default || LoginPage,
  },
};
