import _ from 'lodash';
import { analyzeClinicsCsvRows } from '../../../utils/clinicManagement/analyzeClinicCsvRows';
import Api from '../../../api';

export const name = 'fullStateClinicsCsvType';

export const fullStateClinicsCsvType = {
  name,
  displayName: 'Clinics (Full state)',
  onFileLoaded: rows => analyzeClinicsCsvRows(rows, true),
  handler: ({ clinics, shouldSendLegalDocuments, clinicSource, errorColumnsOrder }) => {
    if (errorColumnsOrder) {
      return { data: { errorColumnsOrder } };
    }

    return clinics.map(clinic => {
      clinic.isLegalSignatureRequired = shouldSendLegalDocuments;
      clinic.source = clinicSource;
      return clinic;
    });
  },
  onSubmit: async ({ payer, clinics, errorColumnsOrder, healthSystemId }) => {
    const payers = [];
    if (errorColumnsOrder) {
      return { data: { errorColumnsOrder } };
    }
    _.map(payer, data => {
      payers.push(data.value);
    });
    _.forEach(clinics, clinic => {
      clinic.payers = payers;
    });
    return Api.clinicsApi.clinicsOnBoarding(healthSystemId, clinics);
  },
};

export function addType(csvType) {
  csvType.addType(name, fullStateClinicsCsvType);
}
