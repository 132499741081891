import React from 'react';
import { isEmpty, find } from 'lodash';
import { compose } from 'recompose';
import { defineMessages } from 'react-intl';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { ThreeBounceSpinner } from '../../../../../../components/ui/spinner/index';
import Api from '../../../../../../api';
import { servicesAsArray } from '../../servicesConfig';
import steps from './index';

const messages = defineMessages({
  fetchErrorTitle: {
    defaultMessage: 'Error!',
    id: 'backpack.createNamespaceModal.errorTitle',
  },
  fetchErrorMessage: {
    defaultMessage: 'Failed to fetch details about existing namespace',
    id: 'backpack.createNamespaceModal.errorMessage',
  },
  noEhrErrorTitle: {
    defaultMessage: 'Error!',
    id: 'backpack.editNamespaceModal.noEhrConfigurationTitle',
  },
  noEhrErrorMessage: {
    defaultMessage: 'No EHR configurations found for this namespace',
    id: 'backpack.editNamespaceModal.noEhrConfigurationMessage',
  },
});

const enhanced = compose(
  injectNotification,
  withStateFetchersOnMount({
    fetchNsDetails: {
      handler: ({ namespace }) => () => Api.namespaceManagerApi.getNamespaceConfigs(namespace),
      onSuccess: ({ control, notification, handleClose }, nsData) => {
        const ehr = find(servicesAsArray, service => {
          const result =
            service.isEhr &&
            find(Object.keys(nsData), nsService => service.relatedService === nsService);
          return !isEmpty(result);
        });

        if (!ehr) {
          notification.error(messages.noEhrErrorTitle, messages.noEhrErrorMessage);
          handleClose();
        }

        control.next({
          payload: {
            existingNs: true,
            ehr: ehr.id,
            ehrConfiguration: nsData[ehr.relatedService].envs,
          },
        });
      },
      onError: ({ notification, handleClose }) => {
        notification.error(messages.fetchErrorTitle, messages.fetchErrorMessage);
        handleClose();
      },
    },
  }),
)(() => <ThreeBounceSpinner />);

export default {
  component: enhanced,
  transitions: {
    NEXT: steps.EHR_FORM,
    PREV: null,
  },
  mapFlowDataToProps: ({ namespace, handleClose }) => ({
    namespace,
    handleClose,
  }),
  mapPayloadToFlowData: nsData => nsData,
};
