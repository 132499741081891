import gql from 'graphql-tag';

export const fullPatientFragment = gql`
  fragment fullPatient on Patient {
    id
    firstName
    middleName
    lastName
    dateOfBirth
    homePhoneNumber
    officePhoneNumber
    mobilePhoneNumber
    address
    gender
    email
    memberId
    plan
    insurer
    ehrInsuranceId
  }
`;

export const fullAppointmentRequestActionFragment = gql`
  fragment fullRequestPhi on AppointmentRequestPhi {
    firstName
    middleName
    lastName
    dateOfBirth
    email
    patientAddress
    reasonForVisit
    memberId
    memberIdPrefix
    gender
    plan
    phoneNumbers {
      name
      number
    }
  }

  fragment fullAppointmentRequestAction on AppointmentRequestAction {
    id
    platformId
    status
    npi
    appointmentType
    plan
    insurer
    patientId
    namespace
    phi {
      ...fullRequestPhi
    }
  }
`;

export const fullAppointmentFragment = gql`
  fragment fullAppointment on Appointment {
    id
    status
    npi
    startTime
    endTime
    npiType
    reasonForVisit
    createdAt
    updatedAt
    ehrLocationId
    appointmentType
    platformId
    timezone
    patientId
  }
`;

export const fullReferral = gql`
  ${fullPatientFragment}

  fragment fullReferral on Referral {
    id
    status
    createdAt
    isUrgent
    sourceNpi
    sourceAddress
    targetNpi
    targetAddress
    patient {
      ...fullPatient
    }
  }
`;

export const fullAppointmentActionFragment = gql`
  fragment fullPhi on AppointmentPhi {
    firstName
    middleName
    lastName
    dateOfBirth
    email
    patientAddress
    reasonForVisit
    memberId
    memberIdPrefix
    gender
    plan
    phoneNumbers {
      name
      number
    }
  }

  fragment fullAppointmentAction on AppointmentAction {
    platformId
    createdAt
    updatedAt
    actionType
    status
    npi
    appointmentType
    interchangeableAppointmentTypes
    startTime
    endTime
    ehrLocationId
    ehrProviderId
    npiType
    plan
    insurer
    patientId
    appointmentId
    timezone
    ehrInsuranceId
    phi {
      ...fullPhi
    }
  }
`;
