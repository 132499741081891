/**
 * Created by guyavraham on 05/10/2017
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import Action from '../../components/action';
import { deactivateUser, DEACTIVATE_USER } from '../../../../../store/backoffice/users/actions';

@autobind
class DeactivateAction extends React.PureComponent {
  static propTypes = {
    deactivateUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: null,
  };

  callAction() {
    this.props.deactivateUser(this.props.userId);
    return true;
  }

  render() {
    return (
      <Action
        name="deactivation"
        actionTrackerId={DEACTIVATE_USER.SOURCE}
        onSubmit={this.callAction}
      >
        <div>
          <div>- Opts out from communication</div>
          <div>- Locks the user</div>
        </div>
      </Action>
    );
  }
}

export default connect(
  () => ({}),
  {
    deactivateUser,
  },
)(DeactivateAction);
