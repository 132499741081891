import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_CLINIC_PERMISSIONS,
  GET_PROVIDER_RESOURCE_PERMISSIONS,
  GET_CALENDAR_PERMISSIONS,
  GET_APPOINTMENT_PERMISSIONS,
  GET_HEALTH_SYSTEM_PERMISSIONS,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
};

export default combineReducers({
  list: reduceReducers(
    genericReducer(GET_HEALTH_SYSTEM_PERMISSIONS, initialState.list),
    genericReducer(GET_CLINIC_PERMISSIONS, initialState.list),
    genericReducer(GET_PROVIDER_RESOURCE_PERMISSIONS, initialState.list),
    genericReducer(GET_CALENDAR_PERMISSIONS, initialState.list),
    genericReducer(GET_APPOINTMENT_PERMISSIONS, initialState.list),
  ),
});
