import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { loadingUntil } from 'Components/recompose/loadingUntil';
import ThreeBounceSpinner from 'Components/ui/spinner/threeBounceSpinner';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { gridQueryParams } from '../../../../components/grid/queryStringGrid';
import injectNotification from 'Store/notification/injectNotification';
import { getAllAsoGroups } from 'Store/backoffice/asoGroups/actions';
import EntityForm from '../components/entityForm/entityForm';
import * as AsoGroupFormDefs from './asoGroupFormDefs';
import Apis from '../../../../api';
import { withStateFetchersOnMount } from '../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import CreateAsoGroupTierAction from './createAsoGroupTier/createAsoGroupTierAction';
import CollapsibleTier from './collapsibleTier';
import DeleteAsoGroupAction from './deleteAsoGroup/deleteAsoGroupAction';

class AsoGroupPage extends React.PureComponent {
  propTypes = {
    asoGroup: PropTypes.object.isRequired,
    collapsibleTiers: PropTypes.array.isRequired,
    updateAsoGroupTracker: PropTypes.object.isRequired,
    updateAsoGroup: PropTypes.func.isRequired,
    deleteAsoGroup: PropTypes.func.isRequired,
    createTier: PropTypes.func.isRequired,
  };

  render() {
    const {
      asoGroup,
      updateAsoGroup,
      updateAsoGroupTracker,
      deleteAsoGroup,
      createTier,
      collapsibleTiers,
    } = this.props;
    return (
      <div>
        <h1>{`Group Id: ${asoGroup.groupId}`}</h1>
        <hr />
        <div className="action-group">
          <CreateAsoGroupTierAction createAsoGroupTier={createTier} />
          <DeleteAsoGroupAction onSubmit={deleteAsoGroup} />
        </div>
        <div>{collapsibleTiers}</div>
        <EntityForm
          name={AsoGroupFormDefs.name}
          title="Data:"
          schemaDef={AsoGroupFormDefs.schema}
          fieldsDef={AsoGroupFormDefs.fields(asoGroup.tiers)}
          entity={_.omit(asoGroup, ['tiers'])}
          onSubmit={updateAsoGroup}
          isSubmitting={updateAsoGroupTracker.inProgress === true}
        />
        <hr />
      </div>
    );
  }
}

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getAsoGroup: {
      handler: props => () =>
        Apis.asoGroup
          .getById(parseInt(props.params.id, 10))
          .then(group => _.omit(group, 'defaultTier')),
      resultPropName: 'asoGroup',
    },
  }),
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(null, { getAllAsoGroups }),
  withFetchers({
    updateAsoGroup: {
      handler: () => ({
        id,
        groupId,
        name,
        emailAddress,
        phoneNumber,
        websiteLink,
        openHours,
        defaultTierId,
        isActivated,
        outOfCountryPhone,
      }) =>
        Apis.asoGroup.updateAsoGroup({
          id,
          groupId,
          name,
          emailAddress,
          phoneNumber,
          websiteLink: _.isEmpty(websiteLink) ? null : websiteLink,
          openHours: _.isEmpty(openHours) ? null : openHours,
          defaultTierId,
          isActivated,
          outOfCountryPhone,
        }),
      onSuccess: ({ getAsoGroup, notification }) => {
        notification.success('', 'Success updating aso group');
        getAsoGroup();
      },
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while updating aso group'),
    },
    createTier: {
      handler: ({ asoGroup: { id } }) => data =>
        Apis.asoGroup.createTier({ ...data, asoGroupId: id }),
      onSuccess: ({ notification, getAsoGroup }) => {
        notification.success('', 'Success creating tier');
        getAsoGroup();
      },
      onError: ({ notification }) => notification.error('', 'Error occurred while creating tier'),
    },
    deleteAsoGroup: {
      handler: ({ asoGroup: { id } }) => () => Apis.asoGroup.deleteAsoGroup(id),
      onSuccess: ({ notification, router, getAllAsoGroups, skip, limit, search, sort, sortAscending }) => {
        notification.success('', 'Success delete ASO group');
        router.push('/secure/asoGroups');
        getAllAsoGroups(skip, limit, search, sort, sortAscending);
      },
      onError: ({ notification }) => notification.error('', 'Error occurred while deleting group'),
    },
  }),
  loadingUntil(
    ({ getAsoGroupTracker }) => getAsoGroupTracker.inProgress === false,
    ThreeBounceSpinner,
  ),
  withPropsOnChange(['asoGroup'], ({ getAsoGroup, asoGroup = {} }) => {
    const collapsibleTiers = _.map(asoGroup.tiers, tier => (
      <CollapsibleTier tier={tier} getAsoGroup={getAsoGroup} />
    ));

    return { collapsibleTiers };
  }),
)(AsoGroupPage);
