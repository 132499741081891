/**
 * Created by chenrozenes on 12/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router';
import { autobind } from 'core-decorators';
import { getProviderByNpi } from '../../../../store/backoffice/providers/actions';
import { getProvider } from '../../../../store/backoffice/providers/selectors';
import EntityForm from '../components/entityForm/entityForm';
import * as ProviderFormDefs from './providerFormDefs';
import ProviderVideoAction from './actions/providerVideoAction';
import PeakCareAcceptNewPatientsAction from './actions/peakCareAcceptNewPatientsAction';

@autobind
class ProvidersPage extends React.Component {
  static propTypes = {
    npi: PropTypes.string.isRequired,
    provider: PropTypes.object.isRequired, // from redux
    getProviderByNpi: PropTypes.func.isRequired, // from redux
  };

  componentWillMount() {
    this.props.getProviderByNpi(this.props.npi);
  }

  render() {
    const { provider, npi } = this.props;
    const { videoUrl, videoPreviewImg, videoQuote } = provider;
    return (
      <div>
        <div className="action-group">
          <Link to={`/secure/providers/${npi}/locations`} className="backoffice-action">
            <button className="btn btn-small btn-blue text-semibold small-margin action-button">
              Locations
            </button>
          </Link>
          <ProviderVideoAction
            videoObj={{
              videoUrl,
              videoPreviewImg,
              videoQuote,
            }}
            npi={npi}
          />
          <PeakCareAcceptNewPatientsAction provider={provider} />
        </div>
        <EntityForm
          name={ProviderFormDefs.name}
          schemaDef={ProviderFormDefs.schema}
          fieldsDef={ProviderFormDefs.fields}
          title={`Provider: ${npi}`}
          entity={provider}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    (state, props) => ({
      provider: getProvider(state),
      npi: props.params.npi,
    }),
    { getProviderByNpi },
  ),
)(ProvidersPage);
