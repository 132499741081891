import React from 'react';

import PropTypes from 'prop-types';
import PlanMappingActions from './PlanMappingActions/PlanMappingActions';
import PlanMappingGrid from './PlanMappingGrid/PlanMappingGrid';

const PlanMappingList = ({
  healthSystemId,
  results,
  onQueryChange,
  onPlanMappingClick,
  onPlanMappingCreated,
  onDeletePlanMapping,
}) => (
  <div>
    <PlanMappingActions
      onPlanMappingCreated={onPlanMappingCreated}
      healthSystemId={healthSystemId}
    />
    <hr />
    <PlanMappingGrid
      results={results}
      onQueryChange={onQueryChange}
      onPlanMappingClick={onPlanMappingClick}
      onDeletePlanMapping={onDeletePlanMapping}
    />
  </div>
);

PlanMappingList.propTypes = {
  healthSystemId: PropTypes.number.isRequired,
  results: PropTypes.object.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onPlanMappingClick: PropTypes.func.isRequired,
  onPlanMappingCreated: PropTypes.func.isRequired,
  onDeletePlanMapping: PropTypes.func.isRequired,
};

export default PlanMappingList;
