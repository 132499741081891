/**
 * Created by guyavraham on 15/06/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_MACHINE,
  CREATE_MACHINE,
  UPDATE_MACHINE,
  DELETE_MACHINE,
  GET_ALL_MACHINES,
} from './actions';

const initialState = {
  selectedlist: {
    count: 0,
    data: [],
  },
  details: {},
  raw: {},
};

export default combineReducers({
  selectedlist: reduceReducers(
    genericReducer(GET_ALL_MACHINES, initialState.selectedlist),
  ),
  details: reduceReducers(
    genericReducer(GET_MACHINE, initialState.details),
    genericReducer(UPDATE_MACHINE, initialState.details),
    genericReducer(CREATE_MACHINE, initialState.details),
    genericReducer(DELETE_MACHINE, initialState.details),
  ),
});
