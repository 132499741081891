import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { pure, compose, setPropTypes, defaultProps, withHandlers } from 'recompose';
import BkmdModal from '../bkmdModal/bkmdModal';
import { createToggleState, createToggleStatePropTypes } from '../recompose/toggleStateComponent';
import ModalHeader from '../bkmdModal/modalHeader';
import FlaggedRender from '../../utils/FlaggedRender';
import { MessageShape } from '../ui/intlString';
import TriggerButton from './triggerButton';
import './action.less';

const BkmdModalButton = props => {
  const {
    title,
    component: Component,
    modalOn,
    modalShow,
    modalHide,
    baseClassName,
    className,
    containerClassName,
    ...otherProps
  } = props;
  return (
    <span className={containerClassName}>
      <TriggerButton {...props} />
      <FlaggedRender shouldRender={modalOn}>
        {() => (
          <BkmdModal
            baseClassName={baseClassName}
            className={className}
            handleClose={modalHide}
            isOpen
          >
            <ModalHeader title={title} onClose={modalHide} />
            <div className="dialog-body">
              <Component closeModal={modalHide} showModal={modalShow} {...otherProps} />
            </div>
          </BkmdModal>
        )}
      </FlaggedRender>
    </span>
  );
};

BkmdModalButton.defaultProps = {
  baseClassName: '',
  containerClassName: '',
  title: '',
};

BkmdModalButton.propTypes = {
  title: MessageShape,
  component: PropTypes.func.isRequired,
  baseClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  ...createToggleStatePropTypes('modal'),
};

export default compose(
  pure,
  setPropTypes({
    onModalShow: PropTypes.func,
    onModalClose: PropTypes.func,
  }),
  defaultProps({
    onModalShow: _.noop,
    onModalClose: _.noop,
  }),
  createToggleState('modal', false),
  withHandlers({
    modalShow: ({ onModalShow, modalShow }) => () => {
      onModalShow();
      modalShow();
    },
    modalHide: ({ onModalClose, modalHide }) => () => {
      onModalClose();
      modalHide();
    },
  }),
)(BkmdModalButton);
