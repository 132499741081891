import _ from 'lodash';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import withRowData from 'Components/withRowData/withRowData';

const TurnAllowedProviderButton = props => {
  const properties = _.get(props.rowData, 'isAllowed')
    ? { className: 'btn btn-red btn-small', title: 'Turn off' }
    : { className: 'btn btn-green btn-small', title: 'Turn on' };
  return (
    <button onClick={props.onButtonClick} className={properties.className}>
      {properties.title}
    </button>
  );
};

TurnAllowedProviderButton.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  cellProperties: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withRowData,
  withHandlers({
    onButtonClick: ({ rowData, cellProperties: { onClick } }) => e => {
      e.stopPropagation();
      return onClick(rowData.id, !rowData.isAllowed);
    },
  }),
)(TurnAllowedProviderButton);
