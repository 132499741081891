/* eslint-disable import/first, react/jsx-filename-extension */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_ASO_GROUPS } from '../../../../model/enum/aclResourceType';
import AsoGroups from './asoGroups';
import AsoGroupPage from './asoGroupPage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'asoGroups',
  name: 'asoGroups',
  childRoutes: [
    {
      path: ':id',
      name: 'asoGroup',
      component: AsoGroupPage.default || AsoGroupPage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_ASO_GROUPS })(divComponent),

  indexRoute: {
    name: 'asoGroups',
    component: AsoGroups.default || AsoGroups,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
