import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import BkmdModal from 'Components/bkmdModal/bkmdModal';
import ModalHeader from 'Components/bkmdModal/modalHeader';
import { defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import { withTranslatedMessages } from 'Utils/withTranslatedMessages';

const messages = defineMessages({
  title: {
    defaultMessage: 'Delete appointment',
    id: 'providers.components.deleteAppointmentModal.title',
  },

  deleteThisAppointmentQuestion: {
    defaultMessage: 'Are you sure you want to delete this appointment?',
    id: 'providers.components.deleteAppointmentModal.deleteThisAppointmentQuestion',
  },
  no: {
    defaultMessage: 'No',
    id: 'providers.components.deleteAppointmentModal.no',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'providers.components.deleteAppointmentModal.yes',
  },
});

@autobind
class DeleteAppointmentModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDeleteAppointment: PropTypes.func.isRequired,
    deleteThisAppointmentQuestion: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    yes: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
  };

  deleteAppointmentAndClose() {
    this.props.onDeleteAppointment();
    this.props.onClose();
  }

  render() {
    const { isOpen, onClose, deleteThisAppointmentQuestion, title, yes, no } = this.props;

    return (
      <BkmdModal
        handleClose={onClose}
        isOpen={isOpen}
        className="decline-appt-modal md-modal fixed-header"
      >
        <ModalHeader onClose={onClose} title={title} />
        <div className="dialog-body">
          <div className="row">
            <div className="col-xs-12">
              <div className="title-24 text-strong-main text-center no-margin">
                {deleteThisAppointmentQuestion}
              </div>
            </div>
          </div>
          <div className="row tiny-gutter margin-top-20">
            <div className="col-xs-6">
              <button className="btn btn-big btn-grey btn-min-100 pull-right" onClick={onClose}>
                {no}
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-big btn-min-100 pull-left"
                onClick={this.deleteAppointmentAndClose}
              >
                {yes}
              </button>
            </div>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default compose(withTranslatedMessages(messages))(DeleteAppointmentModal);
