/**
 * Created by chenrozenes on 23/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn, Columns } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import * as selectors from '../../../../../store/backoffice/communication/selectors';
import { getHistory } from '../../../../../store/backoffice/communication/actions';
import ActionLinkCell from '../../components/actionLinkCell';
import FilterByBundleAction from './actions/filterByBundleAction';

@autobind
class HistoryList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getHistory: PropTypes.func.isRequired, // from redux
    history: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentWillMount() {
    const { getHistory, skip, limit, search, sort, sortAscending, filter } = this.props;
    getHistory({ skip, limit, search, sort, sortAscending, filter });
  }

  onQueryChange(skip, limit, search, sort, sortAscending, filter) {
    this.props.getHistory({ skip, limit, search, sort, sortAscending, filter });
  }

  onRowClick(data) {
    this.props.router.push(`/secure/communication/history/${data.id}`);
  }

  clickTemplateName(data) {
    this.props.router.push(`/secure/communication/templates/${data.templateId}`);
  }

  render() {
    const { skip, limit, search, sort, sortAscending, filter } = this.props;

    return (
      <div>
        <FilterByBundleAction queryParams={{ skip, limit, search, sort, sortAscending, filter }} />
        <QueryStringGrid
          data={this.props.history}
          totalCount={this.props.count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="status" title="Status" />
          <BkmdGridColumn id="channel" title="Channel" />
          <BkmdGridColumn
            id="templateId"
            title="Template Id"
            onClick={this.clickTemplateName}
            propertyKey="templateId"
            customComponent={ActionLinkCell}
          />
          <BkmdGridColumn id="recipientType" title="Recipient Type" />
          <BkmdGridColumn
            id="phoneNumber"
            title="Phone number"
            customComponent={Columns.PhoneNumber}
          />
          <BkmdGridColumn id="email" title="Email" />
          <BkmdGridColumn id="sentAt" title="Sent at" />
          <BkmdGridColumn id="failedAt" title="Failed at" />
          <BkmdGridColumn id="entityIdentifier" title="Identifier" />
          <BkmdGridColumn id="strategy" title="Strategy" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      history: selectors.getHistoryData(state),
      count: selectors.getHistoryCount(state),
    }),
    { getHistory },
  ),
)(HistoryList);
