/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import { name as formName } from './addPermissionFormDefs';
import AddPermissionForm from './addPermissionForm';
import {
  addPermission,
  ADD_CLINIC_MANAGEMENT_PERMISSION,
} from '../../../../../../store/backoffice/clinicManagement/permissions/actions';

@autobind
class AddPermissionAction extends React.PureComponent {
  static propTypes = {
    addPermission: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
  };

  callAction(data) {
    this.props.addPermission(
      this.props.entityType,
      data.userId,
      data.permission,
      data.resourceType,
      this.props.resourceId,
      data.isGroup,
    );
  }

  render() {
    return (
      <Action
        name="addPermission"
        title="Add Permission"
        formName={formName}
        actionTrackerId={ADD_CLINIC_MANAGEMENT_PERMISSION.SOURCE}
        {...this.props}
      >
        <AddPermissionForm onSubmit={this.callAction} {...this.props} />
      </Action>
    );
  }
}

export default connect(
  null,
  { addPermission },
)(AddPermissionAction);
