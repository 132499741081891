import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isNil } from 'lodash';
import { withStateFetchersOnMount } from '../../../../api/injectApi/withStateFetchersOnMount';
import QueryStringGrid, { gridQueryParams } from '../../../../components/grid/queryStringGrid';
import { BkmdGridColumn } from '../../../../components/grid';
import * as Columns from '../../../../components/grid/columns';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { internalApi } from '../internal-api';

const defaultQueryRequest = {
  skip: 0,
  limit: 10,
  search: '',
  sort: 'createdAt',
  sortAscending: false,
}

const Referrals = ({ referrals, getReferrals, router }) => {
  const { results: data, count } = referrals;

  return (
    <div className="clickable">
        <QueryStringGrid 
          data={data} 
          totalCount={count} 
          onQueryChange={(skip, limit, search, sort, sortAscending) => {
            getReferrals({ take: limit, skip, search, sort, sortAscending });
          }}
          onRowClick={(rowData) => router.push(`/secure/referrals/${rowData.id}`)}
          allowSearch 
        >
          <BkmdGridColumn
            id="createdAt"
            title="Created At"
            sortField="createdAt"
            customComponent={Columns.DateColumn}
          />
          <BkmdGridColumn id="organizationName" title="Organization Name" />
          <BkmdGridColumn id="referralId" title="Referral Id" />
          <BkmdGridColumn id="insurer" title="Insurer" />
          <BkmdGridColumn id="specialty" title="Specialty" />
          <BkmdGridColumn id="referringProviderNpi" title="Referring Provider Npi" />
          <BkmdGridColumn id="targetProviderNpi" title="Target Provider Npi" />
          <BkmdGridColumn id="status" title="Status" />
        </QueryStringGrid>
    </div>
)};

Referrals.propTypes = {
  router: PropTypes.object.isRequired,
  getReferrals: PropTypes.func.isRequired,
};

export default compose(
  withStateFetchersOnMount({
    getReferrals: {
      handler: data => input => {
        const { limit, skip, search, sort, sortAscending } = defaultQueryRequest;
        return internalApi.referralsAggregator.getAllReferrals({
          take: limit,
          skip,
          search,
          sort,
          sortAscending,
          ...(input ?? {}),
        });
      },
      resultPropName: 'referrals',
      defaultValue: null,
      isReady: ({ referrals }) => !isNil(referrals),
    },
  }),
  queryConnect(gridQueryParams, defaultQueryRequest),
  withRouter,
)(Referrals);
