/**
 * Created by ofirHartuv on 30/10/17.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import * as selectors from '../../../../../store/backoffice/clinicManagement/calendar/selectors';
import * as clinicSelectors from '../../../../../store/backoffice/clinicManagement/clinics/selectors';
import {
  getAllCalendarsForProvider,
  createCalendar,
} from '../../../../../store/backoffice/clinicManagement/calendar/actions';
import { getAllCalendars } from '../../../../../store/backoffice/clinicManagement/clinics/actions';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import routingSelectors from '../../../../../store/routing/selectors';
import AddCalendarAction from './addCalendar';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import Api from '../../../../../api';
import injectNotification from '../../../../../store/notification/injectNotification';

const onQueryChange = function onQueryChange() {};

@autobind
class CalendarList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    createCalendar: PropTypes.func.isRequired, // from redux
    getAllCalendars: PropTypes.func.isRequired, // from redux
    getCalendars: PropTypes.func.isRequired, // from redux
    getAllCalendarsByProvider: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    calendars: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
  };

  componentWillMount() {
    this.props.getCalendars();
  }

  onClick(rowData) {
    const calendarId = rowData.id;
    const { router, baseUrl } = this.props;
    router.push(`${baseUrl}/${calendarId}`);
  }

  backToClinic() {
    const {
      params: { id },
      router,
    } = this.props;
    router.push(`/secure/clinics/${id}`);
  }

  createCalendar(data) {
    this.props.createCalendar({ resourceId: this.props.params.resourceId, ...data });
  }

  renderProviderModeActions() {
    return (
      <div className="col-xs-12">
        <AddCalendarAction
          onCreateCalendar={this.createCalendar}
          onFinish={this.props.getCalendars}
        />
      </div>
    );
  }

  renderActions() {
    const { params } = this.props;
    return (
      <div className="backoffice-action">
        <div className="row">
          <div className="col-xs-12">
            <button
              onClick={this.backToClinic}
              className="btn btn-small btn-blue text-semibold action-button margin-13"
            >
              Back To Clinic
            </button>
            {params.resourceId ? this.renderProviderModeActions() : null}
          </div>
          <div />
        </div>
      </div>
    );
  }

  render() {
    const { calendars, count, params } = this.props;
    return (
      <div>
        {this.renderActions()}
        <hr />
        <QueryStringGrid
          data={calendars}
          totalCount={count}
          onQueryChange={onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="resourceId" title="Resource Id" />
          {!params.resourceId ? <BkmdGridColumn id="identifier" title="Provider Npi" /> : null}
          <BkmdGridColumn id="timezone" title="Timezone" />
          <BkmdGridColumn id="integrationLevel" title="integrationLevel" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectNotification,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    (state, props) => ({
      calendars: props.params.resourceId
        ? selectors.getCalendarsList(state)
        : clinicSelectors.getClinicCalendarsList(state),
      count:
        selectors.getCalendarsListCount(state) ||
        clinicSelectors.getClinicCalendarsListCount(state),
      baseUrl: routingSelectors.getCurrentUrl(state),
    }),
    (dispatch, props) => ({
      getCalendars() {
        // get calendars depends on the url params
        if (!props.params.resourceId) dispatch(getAllCalendars(props.params.id));
        else dispatch(getAllCalendarsForProvider(0, 10, props.params.resourceId));
      },
      createCalendar(data) {
        dispatch(createCalendar(data));
      },
    }),
  ),
  withFetchers({
    updateCalendar: {
      handler: () => data => Api.calendarsApi.updateCalendar(data),
      onSuccess: ({ getCalendars }) => getCalendars(),
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while updating calendar'),
    },
    updateAllCalendars: {
      handler: ({ calendars }) => data => {
        const ids = _.map(calendars, 'id');
        return Api.calendarsApi.updateAllCalendars(ids, data);
      },
      onSuccess: ({ getCalendars }) => getCalendars(),
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while updating all calendars'),
    },
  }),
)(CalendarList);
