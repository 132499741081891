/**
 * Created by chenro on 31/08/2017.
 */
import Joi from 'joi-browser';

import {
  ReduxFormCleanPhoneNumber,
  ReduxFormJSONInput,
} from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-communication-history-record';

export const fields = {
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  data: {
    name: 'data',
    component: ReduxFormJSONInput,
  },
  paramsSent: {
    name: 'paramsSent',
    component: ReduxFormJSONInput,
  },
  paramsReceived: {
    name: 'paramsReceived',
    component: ReduxFormJSONInput,
  },
};

export const schema = {
  channel: Joi.string().required(),
  templateId: Joi.string().required(),
  recipientType: Joi.string().required(),
  entityIdentifier: Joi.object().keys({
    identifier: Joi.string().required(),
    type: Joi.string().optional(),
    microservice: Joi.string().required(),
  }),
  params: Joi.object().required(),
};
