import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import Header from '../../../components/ui/header/header';
import CoOpLogo from '../../../components/branding/co_opLogo';
import { IntlString } from '../../../components/ui/intlString';
import FeatureFlagged from '../../../components/features/featureFlagged';
import LanguageMenu from '../../../components/molecules/LanguageMenu/LanguageMenu';
import FlaggedRender from '../../../utils/FlaggedRender';

const SimpleNavigationLayout = props => {
  const {
    title,
    className,
    contentClassName,
    showLogos,
    externalPageLogo,
    showRightNavInMobile,
    children,
    progress,
    onBack,
  } = props;
  const rightNavClassName = showRightNavInMobile ? 'header-right-nav show-xs' : 'header-right-nav';
  return (
    <div className={className}>
      <Header>
        <FlaggedRender shouldRender={onBack}>
          {() => (
            <button className="btn-inline-link header-back-btn" onClick={onBack} aria-label="Back">
              <i className="icon-back-arrow" />
            </button>
          )}
        </FlaggedRender>
        <FlaggedRender shouldRender={showLogos}>
          {() => <CoOpLogo className={'progress-logos'} externalPageLogo={externalPageLogo} />}
        </FlaggedRender>
        <h2 className="header-title-grey">
          <IntlString message={title} />
        </h2>
        <ul className={rightNavClassName}>
          <FeatureFlagged renderLi uri="layout.changeLanguage">
            <LanguageMenu id="languageMenuProgress" />
          </FeatureFlagged>
        </ul>
        <FlaggedRender shouldRender={progress}>
          {() => <ProgressBar bsStyle={'warning'} now={progress} className={'small-progress'} />}
        </FlaggedRender>
      </Header>
      <div className="container container-1040">
        <div className="row no-margin">
          <div className="col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2 no-padding">
            <div className={contentClassName}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleNavigationLayout.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.bool,
  children: PropTypes.element,
  showLogos: PropTypes.bool,
  externalPageLogo: PropTypes.string,
  contentClassName: PropTypes.string,
  showRightNavInMobile: PropTypes.bool,
  onBack: PropTypes.func,
};

SimpleNavigationLayout.defaultProps = {
  title: undefined,
  progress: false,
  children: undefined,
  showLogos: false,
  externalPageLogo: undefined,
  showRightNavInMobile: true,
  onBack: undefined,
  contentClassName: undefined,
};

export default SimpleNavigationLayout;
