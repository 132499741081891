import PlanMappingDetailsContainer from './PlanMappingDetails/PlanMappingDetailsContainer';
import PlanMappingListContainer from './PlanMappingList/PlanMappingListContainer';

const PlanMappingRoute = {
  path: 'planMapping',
  name: 'planMapping',
  childRoutes: [
    {
      path: ':ehrId',
      name: 'details',
      component: PlanMappingDetailsContainer.default || PlanMappingDetailsContainer,
    },
  ],
  indexRoute: {
    name: 'planMapping',
    component: PlanMappingListContainer.default || PlanMappingListContainer,
  },
};

export default PlanMappingRoute;
