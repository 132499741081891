import { defineMessages } from 'react-intl';

const planMappingDetailsMessages = defineMessages({
  loadErrorText: {
    defaultMessage: 'Error occurred while getting plan mapping',
    id: 'clinics.healthSystem.planMapping.details.loadErrorText',
  },
  updateSuccessText: {
    defaultMessage: 'Updated the plan mapping successfully!',
    id: 'clinics.healthSystem.planMapping.details.updateSuccessText',
  },
  updateErrorText: {
    defaultMessage: 'Error occurred while updating plan mapping',
    id: 'clinics.healthSystem.planMapping.details.updateErrorText',
  },
});

export default planMappingDetailsMessages;
