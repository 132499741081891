import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withHandlers, lifecycle, setPropTypes } from 'recompose';
import QueryStringGrid from './queryStringGrid';
import BkmdGridColumn from './bkmdGridColumn';
import queryConnect from '../../store/tools/queryString/queryConnect';
import fetchNotEmpty from '../../store/tools/fetchNotEmpty';
import { loadingUntil } from '../../components/recompose/loadingUntil';

const queryParams = query => ({
  limit: parseInt(query.limit, 10),
  skip: parseInt(query.skip, 10),
  search: query.search,
  sort: query.sort,
  sortAscending: query.sortAscending === 'true',
  filter: query.filter,
});

// withProps on change only!
const renderColumns = function renderColumns(columns) {
  return _.map(columns, colDef => (
    <BkmdGridColumn key={colDef.id} {...colDef} />
  ));
};

const PaginatedGrid = props => {
  const { data, title, totalCount, limit, onQueryChange, onClick, columns, local } = props;
  const renderedColumms = renderColumns(columns);
  return (
    <QueryStringGrid
      data={data}
      title={title}
      totalCount={totalCount}
      limit={limit}
      onQueryChange={onQueryChange}
      onRowClick={onClick}
      local={local}
    >
      {renderedColumms}
    </QueryStringGrid>
  );
};

PaginatedGrid.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  local: PropTypes.bool,
};

PaginatedGrid.defaultProps = {
  onClick: _.noop,
  local: false,
};

export default compose(
  queryConnect(queryParams, { limit: 10, skip: 0 }),
  setPropTypes({
    actionCreator: PropTypes.func.isRequired,
    dataSelector: PropTypes.func.isRequired,
    countSelector: PropTypes.func.isRequired,
    clearActionCreator: PropTypes.func.isRequired,
    skip: PropTypes.number,
    limit: PropTypes.number,
    sort: PropTypes.string,
    sortAscending: PropTypes.bool,
    filter: PropTypes.object,
  }),
  fetchNotEmpty(
    ({ props }) =>
      props.actionCreator({
        offset: props.skip,
        limit: props.limit,
        sort: props.sort,
        sortAscending: props.sortAscending,
        filter: props.filter,
      }),
    [(state, props) => props.dataSelector(state)],
  ),
  withHandlers({
    onQueryChange: ({ actionCreator, dispatch }) => (
      offset,
      limit,
      search,
      sort,
      sortAscending,
      filter,
    ) => dispatch(actionCreator({ offset, limit, sort, sortAscending, filter })),
  }),
  connect((state, props) => ({
    data: props.dataSelector(state),
    totalCount: props.countSelector(state),
  })),
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch(this.props.clearActionCreator());
    },
  }),
  loadingUntil(({ data }) => !_.isEmpty(data)),
)(PaginatedGrid);
