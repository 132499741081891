export default {
  label: 'AllScripts-Adapter',
  schema: {
    properties: {
      allscripts__base_url: {
        title: 'Base url',
      },
      allscripts__app_name: {
        title: 'App name',
      },
      allscripts__username: {
        title: 'User name',
      },
      allscripts__password: {
        title: 'Password',
      },
      allscripts__ehr_credentials__user_id: {
        title: 'Ehr user id',
      },
      allscripts__ehr_credentials__password: {
        title: 'Ehr user password',
      },
      allscripts__scheduling_department: {
        title: 'Ehr scheduling department',
      },
      allscripts__policy_carrier_abbreviation: {
        title: 'Ehr policy carrier abbreviation',
      },
      allscripts__is_visit_type_required: {
        title: 'Is visit type required? (true/false)',
      },
    },
  },
};
