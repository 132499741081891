import { defineMessages } from 'react-intl';
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { get, isEmpty } from 'lodash';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import injectNotification from '../../../../../store/notification/injectNotification';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import { namespaceGraphqlFetcher } from '../../internal-api/graphql/fetcher';
import AppointmentActionsList from './view';

const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'Error occurred while retriving data',
    id: 'namespaceAppointmentActions.appointmentActions.errorMessage',
  },
});

export default compose(
  injectNotification,
  queryConnect(gridQueryParams, {
    limit: 10,
    skip: 0,
    sort: 'createdAt',
    sortAscending: 'false',
  }),
  withStateFetchersOnMount({
    getAppointmentActions: {
      handler: data => input => {
        const { params } = data;
        const { search = '', skip, limit: take, sortAscending, filter } = input || data;
        const receivedFilter = { search, skip, take, sortAscending };
        return namespaceGraphqlFetcher.queryOrMutate({
          query: gql`
            query getAllAppointmentActions($input: GetAllAppointmentActionsInput!) {
              getAllAppointmentActions(input: $input) {
                count
                results {
                  id
                  platformId
                  phi {
                    firstName
                    lastName
                  }
                  status
                  npi
                  startTime
                  endTime
                  actionType
                }
              }
            }
          `,
          variables: {
            input: { filter, ...receivedFilter },
          },
          context: {
            uri: `${params.namespaceName}/graphql`,
          },
        });
      },
      resultPropName: 'appointmentActionsQueryResult',
      defaultValue: {},
      isReady: ({ appointmentActionsQueryResult }) => !isEmpty(appointmentActionsQueryResult),
      onError: ({ notification }) => notification.error('', messages.errorMessage),
    },
  }),
  withProps(({ appointmentActionsQueryResult }) => ({
    appointmentActions: get(
      appointmentActionsQueryResult,
      'data.getAllAppointmentActions.results',
      [],
    ),
    totalCount: get(appointmentActionsQueryResult, 'data.getAllAppointmentActions.count', 0),
  })),
  withPropsOnChange(['appointmentActions'], ({ appointmentActions }) => ({
    appointmentActions: appointmentActions.map(appointmentAction => ({
      id: appointmentAction.id,
      platformId: appointmentAction.platformId,
      firstName: get(appointmentAction, 'phi.firstName'),
      lastName: get(appointmentAction, 'phi.lastName'),
      status: appointmentAction.status,
      npi: appointmentAction.npi,
      startTime: appointmentAction.startTime,
      endTime: appointmentAction.endTime,
      actionType: appointmentAction.actionType,
    })),
  })),
  withRouter,
  withHandlers({
    onAppointmentActionClick: ({ router, params }) => appointmentAction => {
      router.push(
        `/secure/namespaceManager/${params.namespaceName}/appointmentActions/${appointmentAction.id}`,
      );
    },
  }),
)(AppointmentActionsList);
