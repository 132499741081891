import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Field, Form } from 'formik';
import { compose, withPropsOnChange } from 'recompose';
import { object, string } from 'yup';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../../../../utils/withTranslatedMessages';
import { WrappedFormik } from '../../../../../components/form/formik/wrappedFormik';
import { CleanFormikInput } from '../../../../../components/form/formik/cleanFormikInput';
import Button from '../../atoms/Button/Button';

const messages = defineMessages({
  title: {
    defaultMessage: 'Please enter provider information',
    id: 'scheduling.providerInput.title',
  },
  next: {
    defaultMessage: 'Next',
    id: 'scheduling.providerInput.next',
  },
  npi: {
    defaultMessage: 'Npi',
    id: 'scheduling.providerInput.npi',
  },
  address: {
    defaultMessage: 'Address',
    id: 'scheduling.providerInput.address',
  },
});

const schema = object().shape({
  npi: string()
    .required('Provider NPI is required')
    .label('Provider NPI'),
  address: string()
    .required('Provider address is required')
    .label('Provider Address'),
});

function ProviderInput({ onSubmit, next, title, isLoading, providerInfo }) {
  return (
    <WrappedFormik
      onSubmit={onSubmit}
      validationSchema={schema}
      initialValues={providerInfo}
      render={data => (
        <Form>
          <h1 className="title-24 text-center">{title}</h1>
          <div className="row">
            <div className="col-xs-12">
              <Field
                name="npi"
                component={CleanFormikInput}
                label={messages.npi}
                inputClassName="margin-45"
              />
            </div>
          </div>
          <div className="row margin-top-30">
            <div className="col-xs-12">
              <Field
                name="address"
                component={CleanFormikInput}
                label={messages.address}
                inputClassName="margin-45"
              />
            </div>
          </div>
          <div className="row margin-top-30">
            <div className="col-xs-12">
              <Button
                type="large"
                width="block"
                isLoading={isLoading}
                disabled={!data.isValid}
                text={next}
              />
            </div>
          </div>
        </Form>
      )}
    />
  );
}

ProviderInput.propTypes = {
  providerInfo: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  next: PropTypes.string.isRequired,
};

ProviderInput.defaultProps = {
  providerInfo: undefined,
};

export default compose(
  withTranslatedMessages(messages),
  withPropsOnChange(['onSubmitTracker'], ({ onSubmitTracker }) => ({
    isLoading: get(onSubmitTracker, 'inProgress', false),
  })),
)(ProviderInput);
