/**
 * Created by chenrozenes on 19/02/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const PREMERA_BLUE_CROSS_ALASKA = 'premera';
export const UNITED_HEALTH_CARE_ALLSAVERS = 'united';
export const ANTHEM = 'anthem';
export const FLORIDA_BLUE = 'florida';
export const CANOPY = 'canopy';
export const CLOVER = 'clover';
export const BCBST = 'bcbst';
export const BSC = 'bsc';

export const BkmdPayers = {
  PREMERA_BLUE_CROSS_ALASKA,
  ANTHEM,
  FLORIDA_BLUE,
  CANOPY,
  CLOVER,
  BCBST,
  BSC,
};

export const messages = defineMessages({
  premeraBlueCrossAlaska: {
    defaultMessage: 'Premera Blue Cross Alaska',
    id: 'bkmdPayer.premeraBlueCrossAlaska',
    value: PREMERA_BLUE_CROSS_ALASKA,
  },
  unitedHealthCareAllsavers: {
    defaultMessage: 'UnitedHealthcare All Savers',
    id: 'bkmdDomain.unitedHealthCareAllsavers',
    value: UNITED_HEALTH_CARE_ALLSAVERS,
  },
  anthem: {
    defaultMessage: 'Anthem',
    id: 'bkmdPayer.anthem',
    value: ANTHEM,
  },
  floridaBlue: {
    defaultMessage: 'Florida Blue',
    id: 'bkmdPayer.floridaBlue',
    value: FLORIDA_BLUE,
  },
  canopy: {
    defaultMessage: 'Canopy',
    id: 'bkmdPayer.canopy',
    value: CANOPY,
  },
  clover: {
    defaultMessage: 'Clover',
    id: 'bkmdPayer.clover',
    value: CLOVER,
  },
  bcbst: {
    defaultMessage: 'Blue Cross Blue shield of Tennessee',
    id: 'bkmdPayer.bcbst',
    value: BCBST,
  },
  bsc: {
    defaultMessage: 'Blue Shield of California',
    id: 'bkmdPayer.bsc',
    value: BSC,
  },
});

export default new IntlEnum(
  { value: PREMERA_BLUE_CROSS_ALASKA, message: messages.premeraBlueCrossAlaska },
  { value: UNITED_HEALTH_CARE_ALLSAVERS, message: messages.unitedHealthCareAllsavers },
  { value: ANTHEM, message: messages.anthem },
  { value: FLORIDA_BLUE, message: messages.floridaBlue },
  { value: CANOPY, message: messages.canopy },
  { value: CLOVER, message: messages.clover },
  { value: BCBST, message: messages.bcbst },
  { value: BSC, message: messages.bsc },
);
