import { defineMessages } from 'react-intl';

const planMappingCreateMessages = defineMessages({
  createSuccessText: {
    defaultMessage: 'The plan mapping was created successfully!',
    id: 'clinics.healthSystem.planMapping.create.successText',
  },
  createErrorText: {
    defaultMessage: 'An Error occurred while creating plan mapping',
    id: 'clinics.healthSystem.planMapping.create.errorText',
  },
});

export default planMappingCreateMessages;
