/**
 * Created by ofirHartuv on 30/10/17.
 */

import React from 'react';
import { autobind } from 'core-decorators';
import IntlDropdown from '../../../../components/intlBased/intlDropdown';
import BkmdDomain, { VIM, messages, getValueForMsgId } from '../../../../model/enum/bkmdDomain';
import { getDomain, storeDomain } from '../../../../utils/domain/storage';

const onChange = function onChange(msgId) {
  const domain = getValueForMsgId(msgId);
  storeDomain(domain);
  window.location.reload();
};

@autobind
class DomainSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      domain: VIM,
    };
  }

  componentWillMount() {
    this.acceptDomainValue();
  }

  acceptDomainValue() {
    // Getting domain from session storage
    const domain = getDomain();
    if (domain) {
      this.setState({ domain });
    } else {
      storeDomain(this.state.domain);
    }
  }

  render() {
    return (
      <div>
        <IntlDropdown
          id="domain-select-dropdown"
          options={messages}
          value={BkmdDomain.getMessageByValue(this.state.domain)}
          onChange={onChange}
          className="sort-btn btn btn-transparent pull-right"
        />
      </div>
    );
  }
}

export default DomainSelector;
