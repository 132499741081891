import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Action from '../../components/action';
import CleanFileInput from '../../../../../components/form/cleanFileInput';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  loadClinicMappingFromFile,
  updateClinicMapping,
  UPDATE_CLINIC_MAPPING,
  LOAD_CLINIC_MAPPING_FILE,
} from '../../../../../store/backoffice/clinicManagement/emr/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/emr/selectors';

@autobind
class ImportMappingAction extends React.Component {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    updateClinicMapping: PropTypes.func.isRequired,
    loadClinicMappingFromFile: PropTypes.func.isRequired,
    mappingFromFile: PropTypes.array.isRequired,
    tracker: PropTypes.object.isRequired,
  };

  onSubmit() {
    const { updateClinicMapping, mappingFromFile, clinicId } = this.props;
    updateClinicMapping(clinicId, mappingFromFile);
    return true;
  }

  handleUpload(event) {
    const file = event.target.files[0];
    this.props.loadClinicMappingFromFile(file);
  }

  render() {
    const { tracker } = this.props;
    const errorMessage = tracker.hasError
      ? 'CSV loading error. please check your file format and mapping.'
      : '';
    return (
      <Action
        name="importClinicMappingFromFile"
        title="Import clinic mapping"
        actionTrackerId={UPDATE_CLINIC_MAPPING.SOURCE}
        onSubmit={this.onSubmit}
      >
        <div>
          <CleanFileInput onChange={this.handleUpload} accept=".json" />
          <span style={{ color: 'red' }}>{errorMessage}</span>
        </div>
      </Action>
    );
  }
}
export default compose(
  connect(
    state => ({
      mappingFromFile: selectors.getClinicMappingFromFile(state),
    }),
    { updateClinicMapping, loadClinicMappingFromFile },
  ),
  actionTracker({
    tracker: LOAD_CLINIC_MAPPING_FILE.SOURCE,
  }),
)(ImportMappingAction);
