import PromiseActionType from '../../../promiseActionType';
import {
  SCHEDULE_CLINIC,
  SCHEDULE_CALENDAR,
  SCHEDULE_PROVIDER_RESOURCE,
  SCHEDULE_HEALTH_SYSTEM
} from '../../../../model/enum/aclResourceType';

export const GET_HEALTH_SYSTEM_PERMISSIONS = new PromiseActionType('GET_HEALTH_SYSTEM_PERMISSIONS');
export const GET_CLINIC_PERMISSIONS = new PromiseActionType('GET_CLINIC_PERMISSIONS');
export const GET_PROVIDER_RESOURCE_PERMISSIONS = new PromiseActionType('GET_PROVIDER_RESOURCE_PERMISSIONS');
export const GET_CALENDAR_PERMISSIONS = new PromiseActionType('GET_CALENDAR_PERMISSIONS');
export const GET_APPOINTMENT_PERMISSIONS = new PromiseActionType('GET_APPOINTMENT_PERMISSIONS');

export const ADD_CLINIC_MANAGEMENT_PERMISSION = new PromiseActionType('ADD_CLINIC_MANAGEMENT_PERMISSION');
export const REMOVE_CLINIC_MANAGEMENT_PERMISSION = new PromiseActionType('REMOVE_CLINIC_MANAGEMENT_PERMISSION');

const permissionsActionsMapper = {
  healthSystem: {
    actionType: GET_HEALTH_SYSTEM_PERMISSIONS,
    permissionsTypes: [SCHEDULE_HEALTH_SYSTEM]
  },
  clinic: {
    actionType: GET_CLINIC_PERMISSIONS,
    permissionsTypes: [SCHEDULE_CLINIC]
  },
  providerResource: {
    actionType: GET_PROVIDER_RESOURCE_PERMISSIONS,
    permissionsTypes: [SCHEDULE_PROVIDER_RESOURCE]
  },
  calendar: {
    actionType: GET_CALENDAR_PERMISSIONS,
    permissionsTypes: [SCHEDULE_CALENDAR]
  },
  appointment: {
    actionType: GET_APPOINTMENT_PERMISSIONS,
    permissionsTypes: [SCHEDULE_CALENDAR]
  },
};

export function getPermissions(type, id, queryPayload) {
  const item = permissionsActionsMapper[type];

  return ({ bkmdApi: { aclApi } }) => ({
    type: item.actionType.SOURCE,
    meta: {
      tracker: item.actionType.SOURCE,
    },
    payload: {
      promise: aclApi.getPermissionsByResourceId(id, item.permissionsTypes, queryPayload)
        .then(res => res.data)
    }
  });
}

export function addPermission(type, userId, permission, resourceType, resourceId, isGroup) {
  return ({ bkmdApi: { clinicPermissionsApi } }) => ({
    type: ADD_CLINIC_MANAGEMENT_PERMISSION.SOURCE,
    payload: {
      promise: clinicPermissionsApi.addPermission(
        type,
        userId,
        permission,
        resourceType,
        resourceId,
        isGroup,
      ),
    },
    meta: {
      tracker: ADD_CLINIC_MANAGEMENT_PERMISSION.SOURCE,
    }
  });
}

export function removePermission(type, userId, resourceType, resourceId, isGroup) {
  return ({ bkmdApi: { clinicPermissionsApi } }) => ({
    type: REMOVE_CLINIC_MANAGEMENT_PERMISSION.SOURCE,
    payload: {
      promise: clinicPermissionsApi.removePermission(
        type,
        userId,
        resourceType,
        resourceId,
        isGroup,
      ),
    },
    meta: {
      tracker: REMOVE_CLINIC_MANAGEMENT_PERMISSION.SOURCE,
    }
  });
}

