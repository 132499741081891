import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { compose, withPropsOnChange } from 'recompose';

import FlowDefs from './definitions/loadClinicFromCsvFlowDef';
import Flow from '../components/wizard/flow/Flow';
import ModalHeader from '../components/bkmdModal/modalHeader';
import BkmdModal from '../components/bkmdModal/bkmdModal';

const modalMessages = defineMessages({
  flowTitle: {
    defaultMessage: 'Load from CSV',
    id: 'referral.response.header.flowTitle',
  },
});

const LoadFromCsvFlow = ({
  clinicId,
  persist,
  isOpen,
  onClose,
  onSuccess,
  medicalCodes,
  initialStepName,
  providersOnly,
}) => (
  <BkmdModal
    isOpen={isOpen}
    name="loadClinicFlowModal"
    className="sidebar-modal md-modal fixed-header"
    handleClose={onClose}
  >
    <ModalHeader title={modalMessages.flowTitle} onClose={onClose} />
    <div className="dialog-body">
      <Flow
        name={FlowDefs.name}
        definitions={FlowDefs.flow}
        initialStepName={initialStepName}
        persist={persist}
        initialValues={{ clinicId, medicalCodes, onSuccess, providersOnly }}
      />
    </div>
  </BkmdModal>
);

LoadFromCsvFlow.propTypes = {
  persist: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  medicalCodes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // relevant when we want to load only providers from csv
  clinicId: PropTypes.string,
  initialStepName: PropTypes.string,
  providersOnly: PropTypes.bool,
};

LoadFromCsvFlow.defaultProps = {
  persist: false,
  providersOnly: false,
  clinicId: '',
  initialStepName: FlowDefs.steps.LOAD_FROM_CSV,
};

export default compose(
  withPropsOnChange(['providersOnly'], ({ providersOnly }) => ({
    initialStepName: providersOnly ? FlowDefs.steps.SELECT_DOMAINS : FlowDefs.steps.LOAD_FROM_CSV,
  })),
)(LoadFromCsvFlow);
