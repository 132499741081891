import { defineMessages } from 'react-intl';
import { object, string } from 'yup';

import { CleanFormikRadioGroup } from '../../../../../../components/form/formik/cleanFormikRadioGroup';
import { CleanFormikInput } from '../../../../../../components/form/formik/cleanFormikInput';

export const initialValues = {
  id: '',
  addByEmail: false,
};

const messages = defineMessages({
  noUserId: {
    defaultMessage: 'User identifier is required',
    id: 'backpack.addUserGroupForm.noUserId',
  },
});

export const fields = {
  addByEmail: {
    name: 'addByEmail',
    component: CleanFormikRadioGroup,
    options: [{ value: false, label: 'Id' }, { value: true, label: 'Email' }],
  },
  id: {
    name: 'id',
    component: CleanFormikInput,
  },
};

export const formSchema = object().shape({
  id: string().required(messages.noUserId),
});
