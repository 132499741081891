import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { ReduxFormCleanInput } from '../../../../../../components/form/reduxForm/components';


function SaveAndVerifyWebhookUrlForm({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit} method="post">
      <div className="row webhook-name-input">
        <Field
          id="webhookUrl"
          name="webhookUrl"
          label="Webhook URL"
          component={ReduxFormCleanInput}
        />
      </div>
      <div className="row webhook-name-input">
        <Field
          id="verificationToken"
          name="verificationToken"
          label="Verification Token"
          component={ReduxFormCleanInput}
        />
      </div>
    </form>
  );
}

SaveAndVerifyWebhookUrlForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SaveAndVerifyWebhookUrlForm',
})(SaveAndVerifyWebhookUrlForm);
