import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, setDisplayName, pure, withProps, withHandlers } from 'recompose';
import _ from 'lodash';

import CleanInput from '../../../../../components/form/cleanInput';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../store/notification/injectNotification';
import Apis from '../../../../../api';
import BkmdModelButton from '../../../../../components/bkmdModalButton/bkmdModalButton';

function RenameGroupFormView(props) {
  return (
    <div>
      <div className="col-xs-12">
        <CleanInput label="Group Name" value={props.newName} onChange={props.onNameChanged} />
      </div>
      <div className="col-xs-12">
        <SpinnerButton
          className="btn btn-big btn-min-100 btn-blue margin-top-20"
          onClick={props.onSubmit}
          isLoading={props.onSubmitTracker.inProgress}
          disabled={_.isEmpty(props.newName)}
        >
          Run Action
        </SpinnerButton>
      </div>
    </div>
  );
}

RenameGroupFormView.propTypes = {
  newName: PropTypes.string.isRequired,
  onNameChanged: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  groupId: PropTypes.number.isRequired,
  currentName: PropTypes.string.isRequired,
  notification: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
};

RenameGroupFormView.defaultProps = {
  onSuccess: _.noop,
};

export const RenameGroupForm = compose(
  withState('newName', 'setName', ({ currentName }) => currentName),
  withHandlers({
    onNameChanged: ({ setName }) => event => setName(_.get(event, 'target.value')),
  }),
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: ({ groupId, newName }) => () => Apis.usersApi.renameGroup(groupId, newName),
      onSuccess: ({ notification, closeModal, onSuccess }, result) => {
        notification.success('Rename Group', 'Group renamed successfully');
        closeModal();
        onSuccess(result);
      },
      onError: ({ notification }) =>
        notification.error('Rename Group', 'Group renamed failed duo to an unexpected error'),
    },
  }),
)(RenameGroupFormView);

export default compose(
  setDisplayName('RenameGroupAction'),
  pure,
  withProps({
    title: 'Rename Group',
    buttonText: 'Rename Group',
    component: RenameGroupForm,
  }),
)(BkmdModelButton);
