/**
 * Created by chenro on 31/08/2017.
 */
import Joi from 'joi-browser';

import TemplateParams from '../paramsGeneration/templateParams';
import FieldWrapper from '../../../../../components/form/reduxForm/fieldWrapper';
import {
  ReduxFormCleanSelect,
  ReduxFormCleanTextArea,
} from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-send-manual-communication';

export const fields = {
  channel: {
    name: 'channel',
    component: ReduxFormCleanSelect,
  },
  templateId: {
    name: 'templateId',
    component: ReduxFormCleanSelect,
  },
  recipientType: {
    name: 'recipientType',
    component: ReduxFormCleanSelect,
  },
  entityIdentifier: {
    name: 'entityIdentifier',
    component: ReduxFormCleanTextArea,
  },
  params: {
    name: 'params',
    component: FieldWrapper(TemplateParams),
  },
};

export const schema = {
  channel: Joi.string().required(),
  templateId: Joi.string().required(),
  recipientType: Joi.string().required(),
  entityIdentifier: Joi.object().keys({
    identifier: Joi.string().required(),
    type: Joi.string().optional(),
    microservice: Joi.string().required(),
  }),
  params: Joi.object().required(),
};
