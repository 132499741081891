/**
 * Created by chenrozenes on 03/01/2018.
 */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import CleanInput from '../../../../../../components/form/cleanInput';
import DateTimeComponent from '../../../../../../components/form/dateTimeComponent';
import NpiTypeahead from '../../../../../../components/npi/npiTypeaheadWrapper';
import { getProvidersSearch } from '../../../../../../store/backoffice/providers/selectors';
import LocationSelectByNpi from '../../../../../../components/npi/stringifiedLocationSelectByNpi';

const isDateValid = date => moment(date).isAfter('1969', 'year');

@autobind
class AttachAppointmentAction extends React.Component {
  static propTypes = {
    onAttachAppointment: PropTypes.func.isRequired,
    attachAppointmentAction: PropTypes.object.isRequired,
    interval: PropTypes.number,
    searchResultProviders: PropTypes.array,
    message: PropTypes.string,
  };

  static defaultProps = {
    interval: 15,
    searchResultProviders: [],
    message: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      timezone: null,
      appointmentId: null,
      targetNpi: null,
      locationId: null,
      message: this.props.message,
    };
  }

  onAppointmentIdChange(e) {
    const val = e.target.value;
    const appointmentId = _.isEmpty(val) ? null : val;
    this.setState({ appointmentId });
  }

  onDateChange(date) {
    let message = 'Date should be valid';
    if (isDateValid(date)) message = '';

    this.setState({ date, message });
  }

  onProviderNpiChanged(targetNpi) {
    this.setState({ targetNpi });
  }

  onLocationIdChanged({ id, timezone }) {
    const message = '';
    this.setState({ locationId: id, timezone, message });
  }

  onSubmit() {
    const { date, timezone, appointmentId, targetNpi, locationId } = this.state;
    if (!timezone || !date || !locationId || !targetNpi || !isDateValid(date)) return false;
    this.props.onAttachAppointment({
      date,
      timezone,
      appointmentId,
      targetNpi,
      locationId,
    });
    return true;
  }

  render() {
    const { searchResultProviders, attachAppointmentAction } = this.props;
    const provider = _.find(searchResultProviders, {
      npi: this.state.targetNpi,
    });
    const locations = _.get(provider, 'locations', []);

    return (
      <Action
        name="attachAppointment"
        title="Attach appointment"
        actionTrackerId={attachAppointmentAction.SOURCE}
        onSubmit={this.onSubmit}
        {...this.props}
      >
        <div className="row">
          <div className="col-xs-12">
            <CleanInput
              value={this.state.appointmentId}
              inputClassName="text-left"
              label="Target appointment Id (optional)"
              onChange={this.onAppointmentIdChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <NpiTypeahead onChange={this.onProviderNpiChanged} />
          </div>
          <div className="col-xs-12">
            <LocationSelectByNpi
              label="Target location Id"
              locations={locations}
              npi={this.state.targetNpi}
              value={this.state.locationId}
              onChange={this.onLocationIdChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <DateTimeComponent
              disabled={!this.state.timezone}
              timeZone={this.state.timezone}
              value={this.state.date}
              onChange={this.onDateChange}
              interval={this.props.interval}
            />
            {this.state.message}
          </div>
        </div>
      </Action>
    );
  }
}

export default connect(state => ({
  searchResultProviders: getProvidersSearch(state),
}))(AttachAppointmentAction);
