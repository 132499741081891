/**
 * Created by chenrozenes on 14/06/2017.
 */
import PropTypes from 'prop-types';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
});
