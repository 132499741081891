import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { withInfoAlert } from '../../investigationUtils/alerts';

const withCalendarInfo = withInfoAlert(({ providerCSI: { providerCalendarData } }) => {
  if (!isEmpty(providerCalendarData))
    return (
      <div className="info-container">
        <h4>Matched calendar info</h4>
        <span>
          Id: <strong>{providerCalendarData.calendarId}</strong>
        </span>
        <span>
          TimeZone: <strong>{providerCalendarData.timezone}</strong>
        </span>
        <span>
          Time slot block duration: <strong>{providerCalendarData.blockSize}</strong>
        </span>
      </div>
    );
  return undefined;
});

export default withCalendarInfo;
