/**
 * Created by chenrozenes on 24/07/2017.
 */
import Joi from 'joi-browser';
import _ from 'lodash';
import { createValidator } from '../../../../../utils/joiValidator';

const DEFAULT_FIELD_SCHEMA = Joi.string().allow('', null);

/**
 * Creates recursively a Joi schema for an entity object
 * @param entity
 * @param schemaDef
 * @returns {*}
 */
export function makeSchema(entity = {}, schemaDef = {}) {
  return _.mapValues(entity, (value, key) => {
    if (_.isPlainObject(value) && _.isPlainObject(schemaDef[key])) {
      return Joi.object().keys(makeSchema(value, schemaDef[key]));
    }

    return schemaDef[key] || DEFAULT_FIELD_SCHEMA;
  });
}

/**
 * Creates a json schema validator with default schema for fields of the entity
 * that haven't been overridden in the schemaDef prop
 * @param values
 * @param props
 * @returns {*}
 */
export default function validate(values, props) {
  return createValidator(Joi.object().keys(makeSchema(props.entity, props.schemaDef)))(
    values,
    props,
  );
}
