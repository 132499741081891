/**
 * Created by guyavraham on 15/06/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_PROVIDER_RESOURCE,
  CREATE_PROVIDER_RESOURCE,
  UPDATE_PROVIDER_RESOURCE,
  DELETE_PROVIDER_RESOURCE,
  GET_ALL_PROVIDER_RESOURCES_BY_CLINIC,
} from './actions';

const initialState = {
  selectedlist: {
    count: 0,
    data: [],
  },
  details: {},
  raw: {},
  permissions: [],
};

export default combineReducers({
  selectedlist: reduceReducers(
    genericReducer(GET_ALL_PROVIDER_RESOURCES_BY_CLINIC, initialState.selectedlist),
  ),
  details: reduceReducers(
    genericReducer(GET_PROVIDER_RESOURCE, initialState.details),
    genericReducer(UPDATE_PROVIDER_RESOURCE, initialState.details),
    genericReducer(CREATE_PROVIDER_RESOURCE, initialState.details),
    genericReducer(DELETE_PROVIDER_RESOURCE, initialState.details),
  ),
});
