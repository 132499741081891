/**
 * Created by rom on 11/08/2016.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit, hasSubmitSucceeded } from 'redux-form';
import _ from 'lodash';
import ActionModal from './actionModal';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../store/notification/injectNotification';
import { MessageShape } from '../../../../components/ui/intlString';

import './action.less';

const messages = defineMessages({
  errorTitle: {
    defaultMessage: 'Error occurred',
    id: 'backoffice.action.errorTitle',
  },
  errorMessage: {
    defaultMessage: 'Action {name} failed. Refresh, or call the best team in the company',
    id: 'backoffice.action.errorMessage',
  },
  successTitle: {
    defaultMessage: 'Success!',
    id: 'backoffice.action.successTitle',
  },
  successMessage: {
    defaultMessage: 'Action {name} succeeded. Go and thank the dev team',
    id: 'backoffice.action.successMessage',
  },
  openModalError: {
    defaultMessage: 'Cant open action',
    id: 'backoffice.action.openModalError',
  },
});

/**
 * Create an action for the backoffice
 * Add a button and the modal per action
 * @type {*|Function}
 */
@autobind
class Action extends React.Component {
  static propTypes = {
    /**
     * Action name
     */
    name: PropTypes.string.isRequired,
    /**
     * Button label
     */
    label: PropTypes.string,
    /**
     * If true (by default) the component will track action using action tracker
     * by the "actionTrackerId" prop and will display notification for success and failure
     */
    trackAction: PropTypes.bool,
    /**
     * Error notification title when action fails
     */
    errorTitle: MessageShape,
    /**
     * Error notification message when action fails
     */
    errorMessage: MessageShape,
    /**
     * Success notification title when action succeed
     */
    successTitle: MessageShape,
    /**
     * Success notification message when action succeed
     */
    successMessage: MessageShape,
    /**
     * String for tracking the action using the actionTracker
     */
    actionTrackerId: PropTypes.string,
    /**
     * Hook callback when action succeed
     */
    onActionSuccess: PropTypes.func,
    /**
     * Hook callback when action fails
     */
    onActionError: PropTypes.func,
    /**
     * The name of the redux form in the action modal (as a child) if exists
     */
    formName: PropTypes.string,
    /**
     * From redux form for inner usage - when form submit succeeded
     */
    submitSucceeded: PropTypes.bool.isRequired,
    /**
     * actionTracker object
     */
    actionTracking: PropTypes.object.isRequired,
    /**
     * Injected notification object for notifying on success and failure of the action
     */
    notification: PropTypes.object.isRequired,
    /**
     * From redux form for inner usage - function to submit the form externally
     */
    submit: PropTypes.func.isRequired,
    /**
     * String for the modal title
     */
    title: PropTypes.string,
    /**
     * Callback function when user pressed the submit button
     */
    onSubmit: PropTypes.func,
    /**
     * Callback function when user closes the modal
     */
    onClose: PropTypes.func,
    /**
     * Callback function when user pressed the submit button
     */
    customHandleResponse: PropTypes.func,

    /**
     * when false, will not open the modal, and show error notification
     */
    canShowModal: PropTypes.bool,
    /**
     * shown when canShowModal is false and user tries to show the modal
     */
    showModalError: PropTypes.object,
    /**
     * custom color for the button within the Action
     */
    btnColor: PropTypes.string,
    /**
     * Should you close modal on action success, or keep it open.
     */
    closeOnSuccess: PropTypes.bool,
    /**
     * Should button be disabled
     */
    buttonDisabled: PropTypes.bool,
    /**
     * Title show when mouse hover over button
     */
    buttonTitle: PropTypes.string,
    /**
     * className to the wrapper
     */
    wrapClassName: PropTypes.string,
    /**
     * ClassName to the icon
     */
    iconClassName: PropTypes.string,
    /**
     * ClassName to the button
     */
    buttonClassName: PropTypes.string,
    /**
     * Disable submit button in ActionModal
     */
    submitDisabled: PropTypes.bool,
    /**
     * Disable notifications
     */
    displayNotifications: PropTypes.bool,
  };

  static defaultProps = {
    trackAction: true,
    errorTitle: messages.errorTitle,
    errorMessage: messages.errorMessage,
    successTitle: messages.successTitle,
    successMessage: messages.successMessage,
    actionTrackerId: '',
    label: undefined,
    onActionSuccess: undefined,
    onActionError: undefined,
    formName: '',
    title: '',
    onSubmit: undefined,
    customHandleResponse: undefined,
    closeOnSuccess: true,
    onClose: () => {},
    canShowModal: true,
    showModalError: undefined,
    btnColor: 'blue',
    buttonDisabled: false,
    buttonTitle: null,
    iconClassName: null,
    wrapClassName: null,
    buttonClassName: null,
    submitDisabled: false,
    displayNotifications: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      submitSucceeded,
      trackAction,
      actionTracking,
      customHandleResponse,
      closeOnSuccess,
    } = nextProps;
    const { notification } = this.props;

    if (trackAction && actionTracking.finished) {
      if (customHandleResponse) customHandleResponse(actionTracking, notification);
      else this.handleActionResponse(actionTracking, notification);
    }
    if (submitSucceeded) {
      if (closeOnSuccess) this.closeModal();
    }
  }

  onSubmit() {
    const { onSubmit, submit, formName } = this.props;
    if (formName) {
      submit(formName);
    } else if (onSubmit) {
      const shouldClose = onSubmit();
      if (shouldClose) this.closeModal();
    }
  }

  handleActionResponse(tracking, notification) {
    const {
      errorTitle,
      errorMessage,
      successTitle,
      successMessage,
      name: _name,
      title,
      closeOnSuccess,
      onActionSuccess,
      onActionError,
      displayNotifications,
    } = this.props;

    const name = title || _.startCase(_name);
    if (tracking.hasError) {
      if (displayNotifications)
        notification.error(errorTitle, errorMessage, { messageParams: { name } });
      if (onActionError) onActionError();
    } else {
      if (displayNotifications)
        notification.success(successTitle, successMessage, {
          messageParams: { name },
        });
      if (onActionSuccess) {
        onActionSuccess();
      }
      if (closeOnSuccess) {
        this.closeModal();
      }
    }
  }

  showModal() {
    if (this.props.canShowModal) {
      this.setState({ showModal: true });
    } else if (this.props.displayNotifications) {
        this.props.notification.error(messages.openModalError, this.props.showModalError);
    }
  }

  /**
   * Close modal, supposed to receive the name of the action
   */
  closeModal() {
    this.props.onClose();
    this.setState({ showModal: false });
  }

  render() {
    const {
      actionTracking,
      name,
      label,
      iconClassName,
      wrapClassName,
      buttonClassName,
      children,
      hideFooter,
      submitDisabled,
      ...other
    } = this.props;
    const isLoading = _.get(actionTracking, 'inProgress');
    return (
      <div key={name} className={classNames('backoffice-action', wrapClassName)}>
        <button
          disabled={this.props.buttonDisabled}
          title={this.props.buttonTitle}
          className={
            buttonClassName ||
            classNames('btn btn-small', `btn-${this.props.btnColor}`, 'text-semibold action-button')
          }
          onClick={this.showModal}
        >
          {iconClassName && (
            <span>
              <i className={iconClassName} />
              &nbsp;
            </span>
          )}
          {label || _.startCase(name)}
        </button>
        <ActionModal
          name={name}
          isOpen={this.state.showModal}
          handleClose={this.closeModal}
          {...other}
          onSubmit={this.onSubmit}
          loading={isLoading}
          hideFooter={hideFooter}
          submitDisabled={submitDisabled}
        >
          {children}
        </ActionModal>
      </div>
    );
  }
}

export default compose(
  injectNotification,
  connect(
    (state, props) => ({
      submitSucceeded: hasSubmitSucceeded(props.formName)(state),
    }),
    { submit },
  ),
  actionTracker({
    actionTracking: (state, props) => props.actionTrackerId,
  }),
)(Action);
