/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by anna on 24/07/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAccess from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PHI_ACCESS } from '../../../../model/enum/aclResourceType';
import ReviewsList from './reviewsList';
import ReviewPage from './reviewPage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'reviews',
  name: 'reviews',
  childRoutes: [
    {
      path: ':token',
      name: 'reviewPage',
      component: ReviewPage.default || ReviewPage,
    },
  ],

  component: requireAccess({ resourceType: BACK_OFFICE_PHI_ACCESS })(divComponent),

  indexRoute: {
    name: 'reviewsList',
    component: ReviewsList.default || ReviewsList,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
