import gql from 'graphql-tag';
import { microServiceGraphqlFetcher } from '../graphql/fetcher';

export class ReferralsAggregatorApi {
  constructor() {
    this.service = 'referrals-aggregator';
    this.uri = `${this.service}/api/graphql`;
  }

  async getAllReferrals(options) {
    const result = await microServiceGraphqlFetcher.queryOrMutate({
      query: gql`
        query getAllReferrals($input: GetAllReferralsInput) {
          getAllReferrals(input: $input) {
            count
            results {
              id
              createdAt
              organizationId
              organizationName
              referralId
              insurer
              specialty
              referringProviderNpi
              targetProviderNpi
              status
            }
          }
        }
      `,
      variables: { input: options },
      context: { uri: this.uri },
    });

    return {
      count: result.data.getAllReferrals.count,
      results: result.data.getAllReferrals.results,
    };
  }

  async getReferralById(id) {
    const result = await microServiceGraphqlFetcher.queryOrMutate({
      query: gql`
        query GetAggregatedReferralByKeys($input: GetAggregatedReferralByKeysInput!) {
          getAggregatedReferralByKeys(input: $input) {
            createdAt
            updatedAt
            organizationId
            organizationName
            referralId
            insurer
            referringProviderNpi
            targetProviderAddress
            targetProviderName
            targetProviderNpi
            taxonomies
            specialty
            icd
            cpt
            status
            referringProviderEhrId
            startDate
            endDate
            ehrInsurer
            targetProviderEhrId
            domainMemberId
          }
        }
      `,
      variables: { input: { id } },
      context: { uri: this.uri }, 
    });

    return result.data.getAggregatedReferralByKeys;
  }
}
