import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';

import Action from '../../../components/action';
import * as selectors from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/selectors';
import * as UpdateFormDefs from './updateAppointmentTypeDefs';
import EntityForm from '../../../components/entityForm/entityForm';
import {
  updateAppointmentType,
  UPDATE_APPOINTMENT_TYPE,
  getAppointmentType,
} from '../../../../../../store/backoffice/clinicManagement/appointmentTypes/actions';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';

import './updateAppointmentType.less';

const messages = defineMessages({
  updateErrorTitle: {
    defaultMessage: 'Error',
    id: 'backpack.appointmentType.update.updateErrorTitle',
  },
  updateErrorText: {
    defaultMessage: 'Cant update appointmentType with invalid data',
    id: 'backpack.appointmentType.update.updateErrorText',
  },
});

@autobind
class UpdateMedicalCodePage extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired, // from router
    params: PropTypes.object.isRequired, // from router
    updateAppointmentTypeTracker: PropTypes.object.isRequired, // from redux
    appointmentType: PropTypes.object.isRequired, // from redux
    getAppointmentType: PropTypes.func.isRequired, // from redux
    updateAppointmentType: PropTypes.func.isRequired, // from redux
  };

  componentWillMount() {
    const { appointmentTypeId } = this.props.params;
    this.props.getAppointmentType(appointmentTypeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateAppointmentTypeTracker.hasError) {
      this.goToAppointmentsTypes();
    }
  }

  updateDeteleAppointmentType() {
    const { appointmentType, updateAppointmentType } = this.props;
    updateAppointmentType({
      ...appointmentType,
      isDeleted: !appointmentType.isDeleted,
    });
  }

  goToAppointmentsTypes() {
    const {
      params: { id, providerId },
    } = this.props;
    this.props.router.push(`/secure/clinics/${id}/providers/${providerId}/services`);
  }

  render() {
    const {
      appointmentType: { description, ...appointmentType },
      updateAppointmentType,
    } = this.props;
    return (
      <div>
        <div className="action-group">
          <div className="backoffice-action backoffice-extra-padding">
            <div className="action-button">
              <Action
                buttonClassName={`btn btn-small text-semibold action-button btn-${
                  appointmentType.isDeleted ? 'blue' : 'red'
                }`}
                name={
                  appointmentType.isDeleted
                    ? 'Enable Appointment Type'
                    : 'Soft Delete Appointment Type'
                }
                title="softDeleteAppointmentType"
                actionTrackerId={UPDATE_APPOINTMENT_TYPE.SOURCE}
                onSubmit={this.updateDeteleAppointmentType}
              />
            </div>
          </div>
        </div>
        <EntityForm
          name={UpdateFormDefs.name}
          title="Update Appointment Type:"
          schemaDef={UpdateFormDefs.schema}
          fieldsDef={UpdateFormDefs.fields}
          entity={appointmentType}
          onSubmit={updateAppointmentType}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      appointmentType: selectors.getCurrentAppointmentType(state),
    }),
    { updateAppointmentType, getAppointmentType },
  ),
  actionTracker(
    {
      updateAppointmentTypeTracker: UPDATE_APPOINTMENT_TYPE.SOURCE,
    },
    {
      updateAppointmentTypeTracker: {
        error: {
          title: messages.updateErrorTitle,
          text: messages.updateErrorText,
        },
      },
    },
  ),
)(UpdateMedicalCodePage);
