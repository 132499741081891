import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../../components/action';
import Api from '../../../../../../api';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import { getDomain } from '../../../../../../utils/domain/storage';
import CleanRadioButton from '../../../../../../components/form/cleanRadioButton';

@autobind
class TurnAllowedProviders extends React.PureComponent {
  static propTypes = {
    turnAllowedProviders: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isAllowed: false };
  }

  setFlag(isAllowed) {
    return () => this.setState({ isAllowed });
  }

  render() {
    return (
      <Action
        name="Turn on/off"
        title="Turn feature on/off for all providers"
        onSubmit={() => this.props.turnAllowedProviders(this.state)}
        wrapClassName="create-allowed-provider-btn"
        buttonClassName="btn btn-big pull-right btn-round-plus action-button"
      >
        <div className="row">
          <div className="col-xs-12">
            <CleanRadioButton
              id="1"
              value={this.state.isAllowed}
              onChange={this.setFlag(true)}
              label="Turn on"
              radioValue
              radioGroup="turn"
            />
            <CleanRadioButton
              id="0"
              value={!this.state.isAllowed}
              onChange={this.setFlag(false)}
              label="Turn off"
              radioValue={false}
              radioGroup="turn"
            />
          </div>
        </div>
      </Action>
    );
  }
}

const TurnAllowedProvidersAction = compose(
  withFetchers({
    turnAllowedProviders: {
      handler: () => ({ isAllowed }) =>
        Api.appointmentRequestsApi.turnAllowedProviders({ isAllowed, domain: getDomain() }),
      onSuccess: ({ onSuccess }) => onSuccess(),
    },
  }),
)(TurnAllowedProviders);

TurnAllowedProvidersAction.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default TurnAllowedProvidersAction;
