import _ from 'lodash';
import analyzeProvidersCsvRows, {
  CLINIC_ID,
  CLINIC_NPI,
} from '../../../utils/clinicManagement/analyzeProvidersCsvRows';
import Api from '../../../api';

const name = 'Providers';

export const providersCsvType = {
  name,
  displayName: name,
  onFileLoaded: (rows, medicalCodes) => {
    const rowsWithoutEmpty = _.filter(rows, row => !_.isEmpty(_.compact(row)));
    const {
      clinicUniqueId,
      validProviders,
      invalidProviders,
      clinicIdArray,
      errorColumnsOrder,
      errorUniqueClinicId,
      errors,
    } = analyzeProvidersCsvRows(rowsWithoutEmpty, medicalCodes);

    return {
      clinicUniqueId,
      validProviders,
      invalidProviders,
      clinicIdArray,
      errorColumnsOrder,
      errorUniqueClinicId,
      errors,
    };
  },
  handler: async ({
    clinicUniqueId,
    clinicIdArray,
    errorColumnsOrder,
    errorUniqueClinicId,
    validProviders,
    errors,
  }) => {
    if (errorColumnsOrder || errorUniqueClinicId) {
      return { clinicIdArray, errors };
    }

    let idField;

    if (clinicUniqueId === CLINIC_ID) {
      idField = 'clinicId';
    } else if (clinicUniqueId === CLINIC_NPI) {
      idField = 'clinicNpi';
    } else {
      throw new Error('Couldnt get clinic unique id');
    }

    const providersGroupByClinic = _.groupBy(validProviders, idField);
    const response = await Api.clinicsApi.loadProvidersByClinicsArray(
      providersGroupByClinic,
      idField,
    );

    return response.data;
  },
};

export function addType(csvType) {
  csvType.addType(name, providersCsvType);
}
