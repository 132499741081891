import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import injectNotification from 'Store/notification/injectNotification';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import Action from '../../../components/action';
import { VERIFY_WEBHOOK_URL } from '../../../../../../store/backoffice/sdk/actions';
import Api from '../../../../../../api';
import SaveAndVerifyWebhookUrlForm from './saveAndVerifyWebhookUrlForm';

const UpdateAndVerifyWebhookUrlAction = ({ updateAndVerifyWebhookUrl, permission }) => (
  <Action
    name="UpdateAndVerifyWebhookUrl"
    actionTrackerId={VERIFY_WEBHOOK_URL.SOURCE}
    onSubmit={updateAndVerifyWebhookUrl}
    formName="SaveAndVerifyWebhookUrlForm"
  >
    <SaveAndVerifyWebhookUrlForm apiKey={permission.apiKey} onSubmit={updateAndVerifyWebhookUrl} />
  </Action>
);

UpdateAndVerifyWebhookUrlAction.propTypes = {
  updateAndVerifyWebhookUrl: PropTypes.func.isRequired,
  permission: PropTypes.object.isRequired,
};

const enhance = compose(
  injectNotification,
  withFetchers({
    updateAndVerifyWebhookUrl: {
      handler: ({ permission, updatePermissions }) => async data => {
        const { webhookUrl, verificationToken } = data;
        const isVerifyData = await Api.sdkApi.verifyWebhookUrl({ webhookUrl, verificationToken });
        const verifyWebhookUrlRes = isVerifyData.data;
        const isWebHookUrlVerify = !(
          verifyWebhookUrlRes.data.verifyWebhookUrl === false ||
          verifyWebhookUrlRes.data.verifyWebhookUrl === null
        );

        const webhookFields = {
          isWebHookUrlVerify,
          webhookUrl,
          verificationToken,
        };

        const allowedHealthSystemIds = permission.allowedHealthSystemIds.map(id => ({
          value: id,
        }));

        updatePermissions({ webhookFields, permission: { ...permission, allowedHealthSystemIds } });
      },
    },
  }),
);

export default enhance(UpdateAndVerifyWebhookUrlAction);
