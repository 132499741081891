import _ from 'lodash';
import { string, object } from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { Field, Form } from 'formik';
import { defineMessages, FormattedMessage } from 'react-intl';
import { WrappedFormik } from '../../components/form/formik/wrappedFormik';
import { CleanFormikPositiveInteger } from '../../components/form/formik/cleanFormikPositiveInteger';
import { SpinnerButton } from '../../components/ui/spinner';
import SvgCodeStars from '../../assets/svg/codeStars';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import { withFetchers } from '../../api/injectApi/withFetchers';
import * as Errors from '../../api/errors';
import Recaptcha from '../../components/verification/bkmdRecaptcha';
import { resetRecaptch } from '../../store/recaptcha/actions';
import Api from '../../api';
import '../../components/verification/verification.less';
import injectNotification from '../../store/notification/injectNotification';

export const messages = defineMessages({
  enterYourCode: {
    defaultMessage: 'Enter your verification code',
    id: 'step.verificationCode.enterYourCode',
  },
  weSentItTo: {
    defaultMessage: 'We sent it to',
    id: 'step.verificationCode.weSentItTo',
  },
  confirmBtn: {
    defaultMessage: 'Confirm',
    id: 'step.verificationCode.confirmBtn',
  },
  resendMyCode: {
    defaultMessage: 'Resend my code',
    id: 'step.verificationCode.resendMyCode',
  },
  yourCode: {
    defaultMessage: 'Your verification code',
    id: 'step.verificationCode.yourCode',
  },
  verificationErrorTitle: {
    defaultMessage: 'Cannot verify your code',
    id: 'step.verificationCode.verificationErrorTitle',
  },
  verificationErrorMsg: {
    defaultMessage: 'Your code has expired, please resend it',
    id: 'step.verificationCode.verificationErrorMsg',
  },
  maxAttemptsErrorMessage: {
    defaultMessage:
      "You've reached the maximum attempts for resetting your password, " +
      'please contact our customer service for unlocking your account.',
    id: 'step.verificationCode.resetPassword.error.maxAttemptsErrorMessage',
  },
  recaptchaErrorMessage: {
    defaultMessage: "Please confirm you're not a robot",
    id: 'step.verificationCode.error.recaptcha.message',
  },
  wrongCodeMessage: {
    defaultMessage: "Let's try again, that doesn't seem to match the code we sent.",
    id: 'step.verificationCode.error.wrongCodeMessage',
  },
});

const schema = object().shape({
  verificationCode: string()
    .length(6)
    .matches(/^[0-9]+$/, { message: 'Please only enter digits', excludeEmptyString: true })
    .required("Please enter the 6 digit verification code you've received"),
});

const VerificationCodeForm = ({
  loading,
  identifier,
  withRecaptcha,
  resendCode,
  verifyCode,
  showRecaptcha,
}) => {
  const phoneOrEmail = identifier;
  return (
    <div>
      <WrappedFormik
        onSubmit={verifyCode}
        validationSchema={schema}
        render={({ isValid }) => (
          <Form>
            <div className="activation-verification-code-wrap">
              <div className="top-section">
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <SvgCodeStars />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <h4 className="title-24 md-margin text-strong font-color-brand-main">
                      <FormattedMessage {...messages.enterYourCode} />
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <span className="text-18 text-light">
                      <FormattedMessage {...messages.weSentItTo} />
                    </span>
                  </div>
                </div>
                &nbsp;
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <div className="text-18 text-semibold">{phoneOrEmail}</div>
                  </div>
                </div>
              </div>

              <div className="row margin-top-30">
                <div className="col-xs-12">
                  <Field
                    name="verificationCode"
                    component={CleanFormikPositiveInteger}
                    inputClassName="margin-45"
                    label={messages.yourCode}
                    maxLength="6"
                    autoFocus
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <SpinnerButton
                    className="btn btn-big bg-color-brand-button"
                    type="submit"
                    disabled={!isValid}
                    isLoading={loading}
                  >
                    <FormattedMessage {...messages.confirmBtn} />
                  </SpinnerButton>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 text-center text-14 margin-top-5 margin-45 margin-bottom-45">
                  <button
                    className="btn btn-small btn-outline xs-block btn-max-300"
                    data-id="resend-code-button"
                    onClick={resendCode}
                    type="button"
                  >
                    <FormattedMessage {...messages.resendMyCode} />
                  </button>
                </div>
              </div>

              {showRecaptcha && (
                <div className={classNames('row', { hidden: !withRecaptcha })}>
                  <div className="col-xs-12 text-center text-14 margin-top-5">
                    <Recaptcha />
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      />
    </div>
  );
};

VerificationCodeForm.propTypes = {
  /**
   * Function for resending the verification code
   */
  resendCode: PropTypes.func.isRequired,
  /**
   * True if form is currently submitting
   */
  loading: PropTypes.bool,
  /**
   * The identifier - phone number or email
   */
  identifier: PropTypes.object.isRequired,
  /**
   * Should the form include a Recaptcha validation
   */
  verifyCode: PropTypes.func.isRequired,
  /**
   * withRecaptcha: a "component level" prop - should we show the recaptcha or hide it
   */
  withRecaptcha: PropTypes.bool,
  /**
   * showRecaptcha: a "flow level" prop - should we render the recaptcha throughout the flow or not
   */
  showRecaptcha: PropTypes.bool,
};

VerificationCodeForm.defaultProps = {
  verificationCode: undefined,
  loading: false,
  withRecaptcha: true,
  showRecaptcha: true,
};

export default compose(
  connect(
    null,
    { resetRecaptch },
  ),
  injectNotification,
  withFetchers({
    resendCode: {
      handler: ({ identifier, withRecaptcha, resetRecaptch, showRecaptcha }) => () => {
        const type = _.includes(identifier, '@') ? 'email' : 'phone';
        const identifierObj = { identifier, type };
        return Api.authApi
          .resendResetPasswordRequest(identifierObj)
          .then(() => showRecaptcha && withRecaptcha && resetRecaptch());
      },
      onSuccess: ({ notification, identifier }) =>
        notification.success(messages.weSentItTo, identifier),

      onError: ({ notification }, { message }) => {
        switch (message) {
          case Errors.MAX_RESET_PASSWORD_ATTEMPTS: {
            return notification.error(
              messages.verificationErrorTitle,
              messages.maxAttemptsErrorMessage,
              { autoDismiss: 15 },
            );
          }
          default: {
            return notification.error(
              messages.verificationErrorTitle,
              messages.verificationErrorMsg,
              { autoDismiss: 15 },
            );
          }
        }
      },
    },
    verifyCode: {
      handler: ({ identifier }) => async ({ verificationCode }) => {
        await Api.authApi.verifyResetToken(identifier, verificationCode);
        return {
          verificationCode,
        };
      },
      onSuccess: ({ control }, result) =>
        control.next({
          payload: {
            ...result,
          },
        }),
      onError: ({ notification, resetRecaptch }, error) => {
        resetRecaptch();
        let errorMsg;
        const { responseMessage } = error;

        switch (responseMessage) {
          case Errors.INVALID_CODE: {
            errorMsg = messages.wrongCodeMessage;
            break;
          }
          case Errors.RECAPTCHA_FAILED:
            errorMsg = messages.recaptchaErrorMessage;
            break;
          default: {
            errorMsg = messages.verificationErrorMsg;
          }
        }
        notification.error(messages.verificationErrorTitle, errorMsg, { autoDismiss: 15 });
      },
    },
  }),
  withTranslatedMessages(messages),
)(VerificationCodeForm);
