import React from 'react';
import PropTypes from 'prop-types';

import IntlString from '../../ui/intlString/intlString';

export default function EnumColumn(enumDefinition) {
  function _enumComponent ({ value }) {
    return <IntlString message={enumDefinition[value]} />;
  }

  _enumComponent.propTypes = {
    value: PropTypes.any
  };

  _enumComponent.defaultProps = {
    value: ''
  };

  return _enumComponent;
}
