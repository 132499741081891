/**
 * created by moran on 12/4/17
 */
export const getAppointmentTypesCount = state => state.backoffice.appointmentTypes.list.count;
export const appointmentTypesListSelector = state => state.backoffice.appointmentTypes.list.data;
export const getCurrentAppointmentType = state => state.backoffice.appointmentTypes.details;

export const getInsertedAppointmentTypes = state =>
  state.backoffice.appointmentTypes.bulkAppointmentTypes.inserted;
export const getUpdatedAppointmentTypes = state =>
  state.backoffice.appointmentTypes.bulkAppointmentTypes.updated;
export const getDisabledAppointmentTypes = state =>
  state.backoffice.appointmentTypes.bulkAppointmentTypes.disabled;
export const getFailedAppointmentTypes = state =>
  state.backoffice.appointmentTypes.bulkAppointmentTypes.failed;
