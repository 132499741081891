import gql from 'graphql-tag';
import { namespaceGraphqlFetcher } from '../graphql/fetcher';
import { get } from 'lodash';
import { fullPatientFragment } from '../graphql/fragments';

export class PatientsApi {
  constructor(namespace) {
    this.namespace = namespace;
    this.service = 'patients';
    this.uri = `${this.namespace}/${this.service}/graphql`;
  }

  async getPatient({ id }) {
    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        ${fullPatientFragment}

        query getPatient($id: ID) {
          getPatient(id: $id) {
            ...fullPatient
          }
        }
      `,
      variables: { id },
      context: {
        uri: this.uri,
      },
    });

    return get(result, 'data.getPatient[0]', {});
  }

  async getAllPatients(input = {}) {
    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        query getAllPatients($input: GetAllPatientsInput!) {
          getAllPatients(input: $input) {
            results {
              id
              memberId
              firstName
              lastName
            }
            count
          }
        }
      `,
      variables: { input },
      context: {
        uri: this.uri,
      },
    });

    return {
      results: get(result, 'data.getAllPatients.results', []),
      count: get(result, 'data.getAllPatients.count', 0),
    };
  }
}
