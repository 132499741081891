import _ from 'lodash';
import IdentificationStep from '../steps/navigationLayoutWrappedSteps/identification';
import VerificationCodeStep from '../steps/navigationLayoutWrappedSteps/verificationCode';
import PasswordStep from '../steps/navigationLayoutWrappedSteps/password';
import { back } from '../../components/wizard/flow/results';
import Formatter from '../../utils/formatter';

const name = 'resetPasswordFlow';

const steps = {
  IDENTIFICATION_STEP: 'identificationStep',
  VERIFICATION_CODE: 'verificationCode',
  PASSWORD: 'password',
};

const flow = {
  [steps.IDENTIFICATION_STEP]: {
    component: IdentificationStep,
    transitions: {
      NEXT: steps.VERIFICATION_CODE,
      PREV: back,
    },
    mapFlowDataToProps: (flowData, flowProps) => ({
      onBack: flowProps.onBack,
      byEmail: flowProps.byEmail,
      byPhone: flowProps.byPhone,
      showRecaptcha: flowProps.showRecaptcha,
    }),
    mapPayloadToFlowData: ({ identifier }) => ({
      identifier: _.includes(identifier, '@')
        ? identifier
        : Formatter.phoneNumberDefault(identifier),
    }),
  },
  [steps.VERIFICATION_CODE]: {
    component: VerificationCodeStep,
    transitions: {
      NEXT: steps.PASSWORD,
      PREV: back,
    },
    mapFlowDataToProps: (flowData, flowProps) => ({
      identifier: flowData.identifier,
      onBack: flowProps.onBack,
      showRecaptcha: flowProps.showRecaptcha,
    }),
    mapPayloadToFlowData: flowData => ({
      verificationCode: flowData.verificationCode,
    }),
  },
  [steps.PASSWORD]: {
    component: PasswordStep,
    transitions: {},
    mapFlowDataToProps: ({ identifier, verificationCode }) => ({
      identifier,
      verificationCode,
    }),
    mapPayloadToFlowData: () => ({}),
  },
};

const initialStepName = steps.IDENTIFICATION_STEP;

export default { name, steps, flow, initialStepName };
