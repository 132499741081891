import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, withState, withHandlers } from 'recompose';
import { BkmdGridColumn, Columns } from '../../../../../components/grid';
import AddHealthSystem from './actions/addHealthSystem';
import QueryStringGrid, { gridQueryParams } from '../../../../../components/grid/queryStringGrid';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import Api from '../../../../../api';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import DeleteActionButtonCell from '../../components/deleteActionButtonCell';
import ActionButtonCell from '../../components/actionButtonCell';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import AttachClinicsModal from './actions/attachClinics';
import { healthSystemType as type } from '../permissions/permissionMapper';
import ScanProfilePicsFromS3 from './actions/scanProfilePictures/scanPicturesToS3Action';

import './healthSystemList.less';

const HealthSystemList = ({
  results: { data },
  onHealthSystemClick,
  activeHealthSystem,
  onQueryChange,
  getHealthSystems,
  deleteHealthSystem,
  attachClinicModalHide,
  attachClinicModalShow,
  attachClinicModalOn,
  goToHealthSystemPermissions,
}) => (
  <div>
    <div className="backoffice-action">
      <AddHealthSystem onCreateHealthSystem={getHealthSystems} />
      <ScanProfilePicsFromS3 />
    </div>
    <hr />
    <QueryStringGrid
      data={data.data}
      totalCount={data.count}
      onQueryChange={onQueryChange}
      onRowClick={onHealthSystemClick}
      classNames={{ Table: 'bkmd-table auto-layout health-systems-grid' }}
      allowSearch
    >
      <BkmdGridColumn id="id" title="Id" headerCssClassName="bkmd-th id-col" />
      <BkmdGridColumn id="name" title="Name" headerCssClassName="bkmd-th name-col" />
      <BkmdGridColumn
        id="integrationType"
        title="Integration type"
        headerCssClassName="bkmd-th name-col"
      />
      <BkmdGridColumn
        id="shouldGetAvailabilityFromEhr"
        title="Should get availability data from EHR"
        headerCssClassName="bkmd-th name-col"
        customComponent={Columns.ToString}
      />
      <BkmdGridColumn
        id="isDisabled"
        title="Is Disabled"
        headerCssClassName="bkmd-th name-col"
        customComponent={Columns.ToString}
      />
      <BkmdGridColumn
        id="deleteAction"
        title="Delete"
        sortable={false}
        customComponent={DeleteActionButtonCell}
        onClick={deleteHealthSystem}
        headerCssClassName="bkmd-th delete-col"
        cssClassName="bkmd-td delete-td text-center"
      />
      <BkmdGridColumn
        id="attachAction"
        title="Attach"
        buttonTitle="Attach Clinics"
        sortable={false}
        customComponent={ActionButtonCell}
        onClick={attachClinicModalShow}
        headerCssClassName="bkmd-th attach-col"
        cssClassName="bkmd-td attach-td"
      />
      <BkmdGridColumn
        id="permissions"
        title="Permissions"
        buttonTitle="Permissions"
        sortable={false}
        customComponent={ActionButtonCell}
        onClick={goToHealthSystemPermissions}
        headerCssClassName="bkmd-th permissions-col"
        cssClassName="bkmd-td permissions-td"
      />
    </QueryStringGrid>
    <AttachClinicsModal
      handleClose={attachClinicModalHide}
      isOpen={attachClinicModalOn}
      healthSystemId={_.get(activeHealthSystem, 'id')}
    />
  </div>
);

HealthSystemList.propTypes = {
  results: PropTypes.object.isRequired,
  onHealthSystemClick: PropTypes.func.isRequired,
  activeHealthSystem: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired,
  deleteHealthSystem: PropTypes.func.isRequired,
  getHealthSystems: PropTypes.func.isRequired,
  goToHealthSystemPermissions: PropTypes.func.isRequired,
  ...createToggleStatePropTypes('attachClinicModal'),
};

HealthSystemList.defaultProps = {
  activeHealthSystem: undefined,
};

export default compose(
  withRouter,
  createToggleState('attachClinicModal'),
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withState('results', 'setResults', { count: 0, data: [] }),
  withState('activeHealthSystem', 'setActiveHealthSystem', null),
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: props => query => {
        const { skip, limit, search, sort, sortAscending } = query || props;
        return Api.clinicsApi.getHealthSystems(skip, limit, search, sort, sortAscending);
      },
      onSuccess: ({ setResults }, results) => setResults(results),
    },
  }),
  withFetchers({
    deleteHealthSystem: {
      handler: () => ({ id }) => Api.clinicsApi.deleteHealthSystem(id),
      onSuccess: ({ getHealthSystems }) => getHealthSystems(),
    },
  }),
  withHandlers({
    onQueryChange: ({ getHealthSystems }) => (skip, limit, search, sort, sortAscending) =>
      getHealthSystems({ skip, limit, search, sort, sortAscending }),
    onHealthSystemClick: ({ router }) => ({ id }) => {
      router.push(`/secure/healthSystems/${id}`);
    },
    attachClinicModalShow: ({ attachClinicModalShow, setActiveHealthSystem }) => rowData => {
      setActiveHealthSystem(rowData);
      attachClinicModalShow();
    },
    goToHealthSystemPermissions: ({ router }) => ({ id }) => {
      router.push(`/secure/healthSystems/permissions/${type}/${id}`);
    },
  }),
)(HealthSystemList);
