import { defineMessages } from 'react-intl';

import { CleanFormikDateInput } from 'Components/form/formik/cleanFormikDateInput';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';

export const name = 'backoffice-namespace-appointment';

export const appointmentMessages = defineMessages({
  title: {
    defaultMessage: 'Appointment details:',
    id: 'namespaceAppointment.appointment.title',
  },
  id: {
    defaultMessage: 'Id',
    id: 'namespaceAppointment.appointment.id',
  },
  startTime: {
    defaultMessage: 'Start time',
    id: 'namespaceAppointment.appointment.startTime',
  },
  endTime: {
    defaultMessage: 'End time',
    id: 'namespaceAppointment.appointment.endTime',
  },
  status: {
    defaultMessage: 'Status',
    id: 'namespaceAppointment.appointment.status',
  },
  appointmentType: {
    defaultMessage: 'Appointment type',
    id: 'namespaceAppointment.appointment.appointmentType',
  },
  npiType: {
    defaultMessage: 'NPI type',
    id: 'namespaceAppointment.appointment.npiType',
  },
  ehrLocationId: {
    defaultMessage: 'Ehr Location Id',
    id: 'namespaceAppointment.appointment.ehrLocationId',
  },
  npi: {
    defaultMessage: 'NPI',
    id: 'namespaceAppointment.appointment.npi',
  },
  platformId: {
    defaultMessage: 'Platform Id',
    id: 'namespaceAppointment.appointment.platformId',
  },
  reasonForVisit: {
    defaultMessage: 'Reason For Visit',
    id: 'namespaceAppointment.appointment.reasonForVisit',
  },
});

export const fields = {
  startTime: {
    name: 'startTime',
    component: CleanFormikDateInput,
  },
  endTime: {
    name: 'endTime',
    component: CleanFormikDateInput,
  },
  ehrLocationId: {
    name: 'ehrLocationId',
    component: CleanFormikInput,
    readOnly: true,
  },
};
