import React from 'react';
import PropTypes from 'prop-types';
import { pure, compose, withHandlers, setPropTypes, withProps, withStateHandlers } from 'recompose';
import _ from 'lodash';
import { Field, Form } from 'formik';
import { string, object, array } from 'yup';
import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import FormikCSVReader from '../../../../../../components/form/formik/FormikCsvReader';
import BkmdModalButton from '../../../../../../components/bkmdModalButton/bkmdModalButton';
import FlaggedRender from '../../../../../../utils/FlaggedRender';
import Api from '../../../../../../api/index';
import { withStateFetchers } from '../../../../../../api/injectApi/withStateFetchers';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { calculateCSVFileOptionsFields } from '../../../../../../utils/files';
import ToggleButton from '../../../../../../storybook/src/components/atoms/ToggleButton/toggleButton';
import { CleanFormikSelect } from '../../../../../../components/form/formik/cleanFormikSelect';
import { CleanFormikCheckbox } from '../../../../../../components/form/formik/cleanFormikCheckbox';

const scheme = object().shape({
  csvRows: array()
    .min(1, 'CSV file must have rows')
    .required(''),
  clinicIdField: string().required('You must choose ID field'),
  npiField: string().required('You must choose npi field'),
  startDateField: string().required('You must start date field'),
  startTimeField: string().required('You must start time field'),
  endDateField: string().required('You must end date field'),
  endTimeField: string().required('You must end time field'),
});

const formatAppointmentTypesKeys = keys => {
  const formatted = _.split(keys, ',').map(x => _.trimEnd(_.trimStart(x)));
  return formatted[0] ? formatted : null;
};

const displayErrors = (openTimeSlotResult, openTimeSlotTracker) => {
  if (_.get(openTimeSlotTracker, 'hasError')) {
    return <div>{`Error open time slot: ${openTimeSlotTracker.error.message}`}</div>;
  }
  if (_.size(_.get(openTimeSlotResult, 'failedTimeSlots')) > 0) {
    return (
      <div>
        Could not load the flowing time slot:
        <ul className="error-list">
          {openTimeSlotResult.failedTimeSlots.map(err => {
            const { error, data } = err;
            const { npi, clinicId, startDate, startTime, endDate, endTime } = data;
            return (
              <li key={`${npi}|${startDate}`}>
                {`clinic id: ${clinicId}, npi: ${npi} startDate: ${startDate} startTime: ${startTime} endData: ${endDate} endTime: ${endTime} `}
                {`Error: ${error}`}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return null;
};

const initialValues = {
  hasHeaders: true,
};

const LoadTimeSlotsView = ({
  onSubmit,
  loadTimeSlotsListTracker,
  loadTimeSlotsListResult,
  isFromRoster,
  onFromRosterChanged,
}) => (
  <WrappedFormik
    initialValues={initialValues}
    enableReinitialize
    onSubmit={onSubmit}
    validationSchema={scheme}
    render={({ values: { csvRows, hasHeaders }, isValid }) => {
      const options = calculateCSVFileOptionsFields(hasHeaders, csvRows);
      return (
        <Form>
          <Field inputId="csvRows" name="csvRows" component={FormikCSVReader} showInitialError />
          <Field
            id="hasHeaders"
            name="hasHeaders"
            label="has headers?"
            component={CleanFormikCheckbox}
          />
          <FlaggedRender shouldRender={options.length > 0}>
            {() => (
              <div>
                <div className="padding-v-20">
                  <ToggleButton
                    value={isFromRoster}
                    onChange={onFromRosterChanged}
                    rightLabel="From EHR"
                    leftLabel="From Roster"
                    isOnOffMode
                  />
                </div>
                <div className="col-xs-12 margin-top-30">
                  <Field
                    name="clinicIdField"
                    label={`${isFromRoster ? 'Clinic' : 'EHR location'} id field`}
                    options={options}
                    initialValue={options[0]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  <Field
                    name="npiField"
                    label="NPI field"
                    options={options}
                    initialValue={options[1]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  <Field
                    name="startDateField"
                    label="Start date field"
                    options={options}
                    initialValue={options[2]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  <Field
                    name="startTimeField"
                    label="Start time field"
                    options={options}
                    initialValue={options[3]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  <Field
                    name="endDateField"
                    label="End date field"
                    options={options}
                    initialValue={options[4]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  <Field
                    name="endTimeField"
                    label="End time field"
                    options={options}
                    initialValue={options[5]}
                    hotOptions
                    component={CleanFormikSelect}
                  />
                  {options[6] && (
                    <Field
                      name="appointmentTypesKeysField"
                      label="Appointment type keys field"
                      options={options}
                      initialValue={options[6]}
                      hotOptions
                      component={CleanFormikSelect}
                    />
                  )}
                  {displayErrors(loadTimeSlotsListResult, loadTimeSlotsListTracker)}
                </div>
              </div>
            )}
          </FlaggedRender>
          <SpinnerButton
            className="btn btn-big btn-min-100 bg-color-brand-button"
            disabled={!isValid}
            type="submit"
            isLoading={_.get(loadTimeSlotsListTracker, 'inProgress')}
          >
            Run Action
          </SpinnerButton>
        </Form>
      );
    }}
  />
);

LoadTimeSlotsView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFromRoster: PropTypes.bool.isRequired,
  onFromRosterChanged: PropTypes.func.isRequired,
  loadTimeSlotsListTracker: PropTypes.object,
  loadTimeSlotsListResult: PropTypes.object,
};

LoadTimeSlotsView.defaultProps = {
  loadTimeSlotResult: null,
  loadTimeSlotsListTracker: undefined,
  loadTimeSlotsListResult: undefined,
};

const LoadTimeSlotsFromCsv = compose(
  pure,
  setPropTypes({
    onSuccess: PropTypes.func.isRequired,
  }),
  injectNotification,
  withStateFetchers({
    loadTimeSlotsList: {
      handler: ({ isFromRoster }) => list =>
        Api.calendarsApi.openTimeSlotsList(list, !isFromRoster),
      onSuccess: ({ notification, closeModal, onSuccess }, res) => {
        if (res.error) {
          notification.error('Failed to load time slots from csv', res.error);
        } else {
          notification.success(
            `Time slots successfully loaded !`,
            `${res.affected} new time slots created.`,
          );
          onSuccess();
          closeModal();
        }
      },
      onError: ({ notification }) => {
        notification.error('Failed to load time slots from csv', 'Internal server error.');
      },
    },
  }),
  withStateHandlers(
    {
      isFromRoster: true,
    },
    {
      onFromRosterChanged: ({ isFromRoster }) => () => ({ isFromRoster: !isFromRoster }),
    },
  ),
  withHandlers({
    onSubmit: ({ loadTimeSlotsList, isFromRoster }) => ({
      hasHeaders,
      csvRows,
      clinicIdField,
      npiField,
      startDateField,
      startTimeField,
      endDateField,
      endTimeField,
      appointmentTypesKeysField,
    }) => {
      const rows = hasHeaders ? _.tail(csvRows) : csvRows;
      const appTypesHeader = _.get(appointmentTypesKeysField, 'value');
      const identifier = isFromRoster ? 'clinicId' : 'ehrLocationId';
      const list = _.chain(rows)
        .map(element => {
          let appointmentTypesKeys = [];

          if (appTypesHeader) {
            const csvAppTypeKeys = element[appTypesHeader];
            appointmentTypesKeys = csvAppTypeKeys ? formatAppointmentTypesKeys(csvAppTypeKeys) : [];
          }

          return {
            [identifier]: element[clinicIdField.value],
            npi: element[npiField.value],
            startDate: element[startDateField.value],
            startTime: element[startTimeField.value],
            endDate: element[endDateField.value],
            endTime: element[endTimeField.value],
            appointmentTypesKeys,
          };
        })
        // Filter empty rows.
        .filter(row => row[identifier])
        .value();

      loadTimeSlotsList(list);
    },
  }),
)(LoadTimeSlotsView);

export default withProps({
  title: 'Load Time Slots From Csv',
  component: LoadTimeSlotsFromCsv,
})(BkmdModalButton);
