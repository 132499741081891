export const servicesConfig = {
  patientsService: {
    relatedService: 'patients',
  },
  appointmentsService: {
    relatedService: 'appointments',
  },
  appointmentRequestService: {
    relatedService: 'appointment-request-actions',
  },
  appointmentActionsService: {
    relatedService: 'appointment-actions',
  },
  sanitas: {
    isEhr: true,
    relatedService: 'sanitas-ehr-integration',
  },
  epic: {
    isEhr: true,
    relatedService: 'epic-ehr-integration',
  },
  epicAdapter: {
    isEhr: true,
    relatedService: 'epic-adapter',
  },
  allscripts: {
    isEhr: true,
    relatedService: 'allscripts-ehr',
  },
  athena: {
    isEhr: true,
    relatedService: 'athena-app-adapter',
  },
  cerner: {
    isEhr: true,
    relatedService: 'cerner-ehr-integration',
  },
  vivacity: {
    isEhr: true,
    relatedService: 'vivacity-ehr-integration',
  },
  // PLOP: Generated namespace service management (DO NOT REMOVE THIS COMMENT!):
  walgreensAdapter: {
    isEhr: true,
    relatedService: 'walgreens-adapter',
    label: 'walgreens EHR adapter',
  },
  puppeteerVsAdapter: {
    isEhr: true,
    relatedService: 'puppeteer-vs-adapter',
    label: 'Virtual-Scheduler puppeteer EHR adapter',
  },
  mockEhrAdapter: {
    isEhr: true,
    relatedService: 'mock-ehr-adapter',
    label: 'Mock EHR adapter',
  },
};

export const servicesAsArray = Object.keys(servicesConfig).reduce(
  (servicesArr, service) => [...servicesArr, { id: service, ...servicesConfig[service] }],
  [],
);
