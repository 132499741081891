import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadRecaptchaApi } from '../../../../store/recaptcha/actions';
import injectAnalytics from '../../../../store/tools/analytics/injectAnalytics';
import NotificationContainer from '../../../../components/notification/notificationsContainer';
import { loadGooglePlacesAutoCompleteApi } from '../../../../store/help/actions';
import '../../../../assets/less/bookmd-app.less';
import '../assets/premera.css';
import '../assets/favicon.ico';
import '../../../../theme/vim.less';

import { updateLocaleFromStorage } from '../../../../store/app/actions';
import { withRolloutFlagsView } from '../../../../components/withRolloutFlagsView/withRolloutFlagsView';

class App extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    loadRecaptchaApi: PropTypes.func.isRequired,
    updateLocaleFromStorage: PropTypes.func.isRequired,
    loadGooglePlacesAutoCompleteApi: PropTypes.func.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    location: PropTypes.object,
  };

  getChildContext() {
    // Location can be accessed from all sub components that declare this context
    return { location: this.props.location };
  }

  componentDidMount() {
    const { routes, location, auth, updateLocaleFromStorage } = this.props;
    // Client side changes must be dispatched after componentDidMount.
    const userId = _.get(auth, 'user.id');
    this.props.loadRecaptchaApi();
    this.props.loadGooglePlacesAutoCompleteApi();
    this.context.analytics.reportViewedPage(routes, location.pathname, userId);
    updateLocaleFromStorage();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      const { routes, location, auth } = nextProps;
      const userId = _.get(auth, 'user.id');
      this.context.analytics.reportViewedPage(routes, location.pathname, userId);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div className="page">
        <NotificationContainer />
        {children}
      </div>
    );
  }
}
// Inject initialized analytics to context
// Just inject dispatch.

export default compose(
  injectAnalytics('Backpack'),
  withRolloutFlagsView,
  connect(
    state => ({ auth: state.auth }),
    { loadRecaptchaApi, updateLocaleFromStorage, loadGooglePlacesAutoCompleteApi },
  ),
)(App);
