/**
 * Created by chenro on 31/08/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Action from '../../components/action';
import { name as formName } from './sendManualFormDef';
import SendManualForm from './sendManualForm';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import {
  manualNotify,
  getMetadata,
  MANUAL_NOTIFY,
} from '../../../../../store/backoffice/communication/actions';
import * as selectors from '../../../../../store/backoffice/communication/selectors';

@autobind
class SendManualAction extends React.PureComponent {
  static propTypes = {
    manualNotify: PropTypes.func.isRequired,
    metadata: PropTypes.object.isRequired,
  };

  callAction(data) {
    this.props.manualNotify(data);
  }

  render() {
    const { metadata, ...other } = this.props;
    return (
      <Action
        name="sendManualAction"
        title="Send Manual"
        actionTrackerId={MANUAL_NOTIFY.SOURCE}
        formName={formName}
        {...other}
      >
        <SendManualForm metadata={metadata} onSubmit={this.callAction} />
      </Action>
    );
  }
}

export default compose(
  fetchNotEmpty(getMetadata, selectors.getMetadata, {
    renderAlways: false,
    loading: true,
  }),
  connect(
    state => ({
      metadata: selectors.getMetadata(state),
    }),
    { manualNotify },
  ),
)(SendManualAction);
