/**
 * Created by anna on 15/06/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import { getProviderLocations } from '../../../../store/backoffice/providers/actions';
import * as selectors from '../../../../store/backoffice/providers/selectors';
import EntityForm from '../components/entityForm/entityForm';
import * as FormDefs from './locationEntityFormDefs';
import LocationVerificationDetails from '../components/locationVerificationDetails';

@autobind
class ProviderLocation extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    params: PropTypes.object.isRequired, // from router
  };

  static defaultProps = {
    locations: [],
  };

  render() {
    const {
      locations,
      params: { id },
    } = this.props;

    const locationId = parseInt(id, 10);
    const location = _.find(locations, { id: locationId });
    if (!location) return <ThreeBounceSpinner />;
    return (
      <div>
        <div className="action-group" />
        <LocationVerificationDetails location={location} />
        <EntityForm
          name="location"
          title="Location"
          schemaDef={FormDefs.schema}
          fieldsDef={FormDefs.fields}
          entity={location}
          readOnly
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(({ params }) => getProviderLocations(params.npi), selectors.getProviderLocations, {
    renderAlways: false,
    loading: true,
  }),
  connect(state => ({
    locations: selectors.getProviderLocations(state),
  })),
)(ProviderLocation);
