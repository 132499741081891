/**
 * Created by chenrozenes on 03/01/2018.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import CleanInput from '../../../../../../components/form/cleanInput';
import {
  removeClinicSubscriber,
  REMOVE_CLINIC_SUBSCRIBER,
} from '../../../../../../store/backoffice/clinicManagement/clinics/actions';

@autobind
class RemoveSubscriberAction extends React.Component {
  static propTypes = {
    clinicId: PropTypes.number.isRequired,
    removeClinicSubscriber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      // npi: null, TODO: do u need this?
    };
  }

  onUserIdChange(e) {
    const val = e.target.value;
    const userId = _.isEmpty(val) ? null : val;
    this.setState({ userId });
  }

  onSubmit() {
    this.props.removeClinicSubscriber(this.props.clinicId, this.state.userId);
    return true;
  }

  render() {
    return (
      <Action
        name="removeClinicSubscriber"
        title="Remove clinic subscriber"
        actionTrackerId={REMOVE_CLINIC_SUBSCRIBER.SOURCE}
        onSubmit={this.onSubmit}
      >
        <div className="row">
          <div className="col-xs-12">
            <CleanInput
              value={this.state.userId}
              inputClassName="text-left"
              label="User Id"
              onChange={this.onUserIdChange}
            />
          </div>
        </div>
      </Action>
    );
  }
}

export default connect(
  null,
  { removeClinicSubscriber },
)(RemoveSubscriberAction);
