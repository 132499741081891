/**
 * Created by Nico on 3/05/2018.
 */

import _ from 'lodash';

export const getVimGuideProfilesList =
    state => _.get(state, 'backoffice.vimGuideProfile.list.data');
export const getVimGuideProfilesCount =
    state => _.get(state, 'backoffice.vimGuideProfile.list.count');
export const getVimGuideProfile = (state, props) => {
  const id = parseInt(_.get(props, 'params.id'), 0);
  const list = getVimGuideProfilesList(state);
  return _.find(list, { id });
};
