/**
 * Created by chenrozenes on 12/11/2017.
 */
import _ from 'lodash';
import PromiseActionType from '../../../promiseActionType';
import { loadCsv } from '../../../../utils/files';
import { outreachFileSchema, tokenizeFileSchema } from './outreachCsvSchema';
import * as Channel from '../../../../model/enum/communicationChannel';
import Formatter from '../../../../utils/formatter';

export const LOAD_OUTREACH_FILE = new PromiseActionType('LOAD_OUTREACH_FILE');
export const LOAD_TOKENIZE_FILE = new PromiseActionType('LOAD_TOKENIZE_FILE');
export const SEND_BUNDLE = new PromiseActionType('SEND_BUNDLE');
export const GET_METADATA = new PromiseActionType('bo-outreach/GET_METADATA');

export const CREATE_MLQ = new PromiseActionType('bo-outreach/CREATE_MLQ');
export const LIST_MLQS = new PromiseActionType('bo-outreach/LIST_MLQS');
export const GET_MLQ = new PromiseActionType('bo-outreach/GET_MLQ');
export const EXECUTE_MLQ = new PromiseActionType('bo-outreach/EXECUTE_MLQ');
export const CLEAR_MLQ_RESULTS = 'bo-outreach/CLEAR_MLQ_RESULTS';

export const LIST_STEPS = new PromiseActionType('bo-outreach/LIST_STEPS');
export const GET_STEP = new PromiseActionType('bo-outreach/GET_STEP');
export const CREATE_STEP = new PromiseActionType('bo-outreach/CREATE_STEP');
export const UPDATE_STEP = new PromiseActionType('bo-outreach/UPDATE_STEP');
export const EXECUTE_STEP = new PromiseActionType('bo-outreach/EXECUTE_STEP');
export const CLEAR_STEP_RESULTS = 'bo-outreach/CLEAR_STEP_RESULTS';

export const ADD_STEP_TO_MLQ = new PromiseActionType('bo-outreach/ADD_STEP_TO_MLQ');
export const REMOVE_STEP_FROM_MLQ = new PromiseActionType('bo-outreach/REMOVE_STEP_FROM_MLQ');
export const SWITCH_STEPS = new PromiseActionType('bo-outreach/SWITCH_STEPS');

const OUTREACH_IDENTIFIER = 'OUTREACH';

export const LIST_RECIPES = new PromiseActionType('bo-outreach/LIST_RECIPES');
export const GET_RECIPE = new PromiseActionType('bo-outreach/GET_RECIPE');
export const CREATE_RECIPE = new PromiseActionType('bo-outreach/CREATE_RECIPE');
export const UPDATE_RECIPE = new PromiseActionType('bo-outreach/UPDATE_RECIPE');
export const EXECUTE_RECIPE = new PromiseActionType('bo-outreach/EXECUTE_RECIPE');
export const DELETE_RECIPE = new PromiseActionType('bo-outreach/DELETE_RECIPE');
export const GET_RECIPE_HISTORY = new PromiseActionType('bo-outreach/GET_RECIPE_HISTORY');
export const GET_RECIPE_STATUS = new PromiseActionType('bo-outreach/GET_RECIPE_STATUS');

export function loadOutreachFile(file) {
  return {
    type: LOAD_OUTREACH_FILE.SOURCE,
    payload: {
      promise: loadCsv(file, outreachFileSchema),
    },
  };
}

export function loadTokenizeFile(file) {
  return {
    type: LOAD_TOKENIZE_FILE.SOURCE,
    payload: {
      promise: loadCsv(file, tokenizeFileSchema),
    },
  };
}

function buildIdentifier(recipient, data) {
  const entityIdentifier = {
    type: data.templateId,
    microservice: OUTREACH_IDENTIFIER,
  };

  switch (data.channel) {
    case Channel.SMS: {
      entityIdentifier.identifier = Formatter.phoneNumberSimple(recipient.phoneNumber);
      break;
    }

    case Channel.EMAIL: {
      entityIdentifier.identifier = recipient.email;
      break;
    }

    case Channel.FAX: {
      entityIdentifier.identifier = recipient.fax;
      break;
    }
  }

  return entityIdentifier;
}

export function sendBundle(file, data, shouldBuildIdentifier = false, attachedParams = []) {
  const bundle = _.map(file, recipient => {
    const payload = {};

    // Building entityIdentifier
    if (shouldBuildIdentifier) {
      payload.entityIdentifier = _.extend(buildIdentifier(recipient, data), data.entityIdentifier);
    }

    // Getting params from file
    _.map(attachedParams, param => {
      _.set(payload, `params.${param}`, recipient[param]);
    });

    _.extend(payload, { options: { bypass: true } });

    return payload;
  });

  return ({ bkmdApi: { communicationApi } }) => ({
    type: SEND_BUNDLE.SOURCE,
    payload: {
      promise: communicationApi.notifyBundle(data, bundle),
    },
    meta: {
      tracker: SEND_BUNDLE.SOURCE,
    },
  });
}

export function getMetadata() {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_METADATA.SOURCE,
    payload: {
      promise: outreachApi.getMetadata(),
    },
  });
}

export function listMlqs(queryPayload = { limit: 0, skip: 0 }) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: LIST_MLQS.SOURCE,
    payload: {
      promise: outreachApi.listMlqs(queryPayload),
    },
  });
}

export function createMlq(name) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: CREATE_MLQ.SOURCE,
    payload: {
      promise: outreachApi.createMlq(name).then(() => {
        dispatch(listMlqs({ limit: 10, skip: 0 }));
      }),
    },
    meta: {
      tracker: CREATE_MLQ.SOURCE,
    },
  });
}

export function getMlq(id) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_MLQ.SOURCE,
    payload: {
      promise: outreachApi.getMlq(id),
    },
  });
}

export function executeMlq(id, startIndex, endIndex) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: EXECUTE_MLQ.SOURCE,
    payload: {
      promise: outreachApi.executeMlq(id, startIndex, endIndex).then(payload => payload.data),
    },
    meta: {
      tracker: EXECUTE_MLQ.SOURCE,
    },
  });
}

export function clearMlqResults() {
  return { type: CLEAR_MLQ_RESULTS };
}

export function listSteps(queryPayload) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: LIST_STEPS.SOURCE,
    payload: {
      promise: outreachApi.listSteps(queryPayload),
    },
  });
}

export function getStep(id) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_STEP.SOURCE,
    payload: {
      promise: outreachApi.getStep(id),
    },
  });
}

export function createStep(name) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: CREATE_STEP.SOURCE,
    payload: {
      promise: outreachApi.createStep(name).then(() => {
        dispatch(listSteps({ limit: 10, skip: 0 }));
      }),
    },
    meta: {
      tracker: CREATE_STEP.SOURCE,
    },
  });
}

export function updateStep(id, data) {
  data = _.omit(data, ['id', 'createdAt', 'updatedAt']);
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: UPDATE_STEP.SOURCE,
    payload: {
      promise: outreachApi.updateStep(id, data).then(() => {
        dispatch(getStep(id));
      }),
    },
    meta: {
      tracker: UPDATE_STEP.SOURCE,
    },
  });
}

export function executeStep(id, dependentData) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: EXECUTE_STEP.SOURCE,
    payload: {
      promise: outreachApi.executeStep(id, dependentData).then(payload => payload.data),
    },
    meta: {
      tracker: EXECUTE_STEP.SOURCE,
    },
  });
}

export function clearStepResults() {
  return { type: CLEAR_STEP_RESULTS };
}

export function addStepToMlq(mlqId, stepId, dependencies) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: ADD_STEP_TO_MLQ.SOURCE,
    payload: {
      promise: outreachApi
        .addStepToMlq(mlqId, stepId, dependencies)
        .then(() => dispatch(getMlq(mlqId))),
    },
    meta: {
      tracker: ADD_STEP_TO_MLQ.SOURCE,
    },
  });
}

export function removeStepFromMlq(mlqId, stepId) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: REMOVE_STEP_FROM_MLQ.SOURCE,
    payload: {
      promise: outreachApi.removeStepFromMlq(mlqId, stepId).then(() => dispatch(getMlq(mlqId))),
    },
    meta: {
      tracker: REMOVE_STEP_FROM_MLQ.SOURCE,
    },
  });
}

export function switchSteps(mlqId, stepId1, stepId2) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: SWITCH_STEPS.SOURCE,
    payload: {
      promise: outreachApi.switchSteps(mlqId, stepId1, stepId2).then(() => dispatch(getMlq(mlqId))),
    },
    meta: {
      tracker: SWITCH_STEPS.SOURCE,
    },
  });
}

/* Recipes */

export function listRecipes(queryPayload = { limit: 0, skip: 0 }) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: LIST_RECIPES.SOURCE,
    payload: {
      promise: outreachApi.listRecipes(queryPayload),
    },
  });
}

export function getRecipe(id) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_RECIPE.SOURCE,
    payload: {
      promise: outreachApi.getRecipe(id),
    },
  });
}

export function deleteRecipe(id) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: DELETE_RECIPE.SOURCE,
    payload: {
      promise: outreachApi.deleteRecipe(id),
    },
    meta: {
      tracker: DELETE_RECIPE.SOURCE,
    },
  });
}

export function createRecipe(payload) {
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: CREATE_RECIPE.SOURCE,
    payload: {
      promise: outreachApi.createRecipe(payload).then(() => {
        dispatch(listRecipes({ limit: 10, skip: 0 }));
      }),
    },
    meta: {
      tracker: CREATE_RECIPE.SOURCE,
    },
  });
}

export function updateRecipe(id, data) {
  data = _.omit(data, ['id', 'createdAt', 'updatedAt']);
  return ({ dispatch, bkmdApi: { outreachApi } }) => ({
    type: UPDATE_RECIPE.SOURCE,
    payload: {
      promise: outreachApi.updateRecipe(id, data).then(() => {
        dispatch(getRecipe(id));
      }),
    },
    meta: {
      tracker: UPDATE_RECIPE.SOURCE,
    },
  });
}

export function executeRecipe(id) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: EXECUTE_RECIPE.SOURCE,
    payload: {
      promise: outreachApi.executeRecipe(id),
    },
    meta: {
      tracker: EXECUTE_RECIPE.SOURCE,
    },
  });
}

export function getRecipeHistory(id, queryParams = { limit: 0, skip: 0 }) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_RECIPE_HISTORY.SOURCE,
    payload: {
      promise: outreachApi.getRecipeHistory(id, queryParams),
    },
  });
}

export function getRecipeStatus(historyId) {
  return ({ bkmdApi: { outreachApi } }) => ({
    type: GET_RECIPE_STATUS.SOURCE,
    payload: {
      promise: outreachApi.getRecipeStatus(historyId),
    },
    meta: {
      tracker: GET_RECIPE_STATUS.SOURCE,
    },
  });
}
