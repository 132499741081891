/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import _ from 'lodash';

import { EnumComponent } from 'Components/grid/columns';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import { getPermissions } from '../../../../../store/backoffice/clinicManagement/permissions/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/permissions/selectors';
import { BkmdGridColumn } from '../../../../../components/grid';
import * as PermissionLevel from '../../../../../model/enum/permissionLevel';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import AddPermissionAction from './addPermission/addPermissionAction';
import RemovePermissionAction from './removePermission/removePermissionAction';

const permissionEnumColumn = EnumComponent(_.invert(PermissionLevel));

@autobind
class Permissions extends React.Component {
  static propTypes = {
    permissions: PropTypes.array,
    count: PropTypes.number,
    getPermissions: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    ...gridQueryParamsProps,
  };

  static defaultProps = {
    permissions: [],
    count: 0,
  };

  componentWillMount() {
    this.onActionsSuccess();
  }

  onActionsSuccess() {
    const {
      params: { type, resourceId },
      getPermissions,
      skip,
      limit,
      search,
      sort,
      sortAscending,
    } = this.props;
    getPermissions(type, resourceId, { skip, limit, search, sort, sortAscending });
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    const {
      params: { type, resourceId },
      getPermissions,
    } = this.props;
    getPermissions(type, resourceId, { skip, limit, search, sort, sortAscending });
  }

  render() {
    const {
      permissions,
      count,
      params: { type, resourceId },
    } = this.props;
    return (
      <div>
        <div className="action-group">
          <AddPermissionAction
            onActionSuccess={this.onActionsSuccess}
            resourceId={resourceId}
            entityType={type}
          />
          <RemovePermissionAction
            onActionSuccess={this.onActionsSuccess}
            resourceId={resourceId}
            entityType={type}
          />
        </div>
        <br />
        <QueryStringGrid
          data={permissions}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          allowSearch
        >
          <BkmdGridColumn id="user.email" title="User Email" sortable={false} />
          <BkmdGridColumn id="groupId" title="Group Id" />
          <BkmdGridColumn
            id="permission"
            title="Permission"
            customComponent={permissionEnumColumn}
          />
          <BkmdGridColumn id="resourceType" title="Resource Type" />
          <BkmdGridColumn id="resourceId" title="Resource Id" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withRouter,
  connect(
    state => ({
      permissions: selectors.getCurrentPermissions(state),
      count: selectors.getCurrentPermissionsCount(state),
    }),
    { getPermissions },
  ),
)(Permissions);
