/**
 * created by moran on 10/31/17
 */

import _ from 'lodash';
import Joi from 'joi-browser';
import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';

import {
  ReduxFormCleanDateInput,
  ReduxFormGenderSelect,
  ReduxFormCleanInput,
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanPasswordInput,
  ReduxFormCleanRepeatPasswordInput,
  ReduxFormCleanSelect,
  ReduxFormCleanSwitch,
} from '../../../../../components/form/reduxForm/components';
import { BkmdDomains } from '../../../../../model/enum/bkmdDomain';
import BookMdUtil from '../../../../../utils/util';
import { PasswordSchema } from '../../../../../utils/commonSchema';

const MINIMAL_PASSWORD_STRENGTH = 2;
export const name = 'backoffice-create-provider-profile';

export const defaultFields = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
  phoneNumber: '',
  email: '',
  password: '',
  npi: '',
  tin: '',
  domains: '',
  isTemporaryPassword: false,
};

export const fields = {
  firstName: {
    name: 'firstName',
    component: ReduxFormCleanInput,
  },
  middleName: {
    name: 'middleName',
    component: ReduxFormCleanInput,
  },
  lastName: {
    name: 'lastName',
    component: ReduxFormCleanInput,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    component: ReduxFormCleanDateInput,
  },
  gender: {
    name: 'gender',
    component: ReduxFormGenderSelect,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  email: {
    name: 'email',
    component: ReduxFormCleanInput,
  },
  password: {
    name: 'password',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  npi: {
    name: 'npi',
    component: ReduxFormCleanInput,
  },
  tin: {
    name: 'tin',
    component: ReduxFormCleanInput,
  },
  domains: {
    name: 'domains',
    component: ReduxFormCleanSelect,
    options: BookMdUtil.toSelectOptions(BkmdDomains),
    clearable: true,
    multi: true,
  },
  isTemporaryPassword: {
    id: 'isTemporaryPassword',
    name: 'isTemporaryPassword',
    readOnly: false,
    component: ReduxFormCleanSwitch,
  },
  /**
   * This field is a computed field that we never render (hidden field)
   * We use this field for validating the password field
   * (must be stronger than the minimal strength).
   */
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
};

export const schema = {
  firstName: Joi.string().required(),
  middleName: Joi.string()
    .allow('', null)
    .optional(),
  lastName: Joi.string().required(),
  dateOfBirth: Joi.string()
    .allow('', null)
    .optional(),
  gender: Joi.string()
    .allow('', null)
    .optional(),
  password: Joi.string().required(),
  email: Joi.string().required(),
  phoneNumber: Joi.object()
    .keys({
      number: Joi.string().optional(),
      countryDialingCode: Joi.string().optional(),
    })
    .required(),
  npi: Joi.string()
    .allow('', null)
    .optional(),
  tin: Joi.string()
    .allow('', null)
    .optional(),
  isTemporaryPassword: Joi.boolean().required(),
  domains: Joi.array()
    .items(Joi.string().valid(_.values(BkmdDomains)))
    .min(1)
    .required(),
  repeatPassword: PasswordSchema.required(),
  passwordStrength: Joi.object().keys({
    score: Joi.number(),
    deprecatedCharacters: Joi.number(),
  }),
};

export const customValidator = {
  [fields.password.name]: (value, values) => {
    if (!values[fields.passwordStrength.name]) return undefined;

    const { deprecatedCharacters } = values[fields.passwordStrength.name];
    const passwordStrength = values[fields.passwordStrength.name].score;

    if (deprecatedCharacters) {
      return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
    }
    if (!passwordStrength || passwordStrength < MINIMAL_PASSWORD_STRENGTH) {
      return 'Password is too weak';
    }
    return undefined;
  },
  [fields.repeatPassword.name]: (value, values) => {
    if (values[fields.password.name] !== values[fields.repeatPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
};
