import { defineMessages } from 'react-intl';
import { object, string } from 'yup';
import _ from 'lodash';

import { CleanFormikInput } from '../../../../../../components/form/formik/cleanFormikInput';
import { CleanFormikSelect } from '../../../../../../components/form/formik/cleanFormikSelect';
import { SCHEDULING_RESOURCES } from '../../../../../../model/enum/aclResourceType';

const messages = defineMessages({
  noName: {
    defaultMessage: 'Group name is required',
    id: 'backpack.createGroup.noName',
  },
  missingOwnerInfo: {
    defaultMessage: 'Owner type & owner id must be defined together',
    id: 'backpack.createGroup.missingOwnerInfo',
  },
});

export const fields = {
  name: {
    name: 'name',
    component: CleanFormikInput,
  },
  ownerType: {
    name: 'ownerType',
    component: CleanFormikSelect,
    options: _.map(SCHEDULING_RESOURCES, item => ({ label: item, value: item })),
  },
  ownerId: {
    name: 'ownerId',
    component: CleanFormikInput,
  },
};

export const schema = object().shape({
  name: string().required(messages.noName),
  ownerType: string().test('is required', messages.missingOwnerInfo, function test(value) {
    return _.isNil(this.parent.ownerId) || !_.isNil(value);
  }),
  ownerId: string().test('is required', messages.missingOwnerInfo, function test(value) {
    return _.isNil(this.parent.ownerType) || !_.isNil(value);
  }),
});
