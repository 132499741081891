import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../store/notification/injectNotification';

import Action from '../../components/action';
import { EXPIRE_USER_PASSWORD } from '../../../../../store/backoffice/users/actions';
import Api from '../../../../../api';

const ExpirePasswordAction = ({ resetUserPassword }) => (
  <Action
    name="expireUserPassword"
    actionTrackerId={EXPIRE_USER_PASSWORD.SOURCE}
    onSubmit={resetUserPassword}
  >
    <div>
      <div>- expires the user&apos;s password</div>
    </div>
  </Action>
);

ExpirePasswordAction.propTypes = {
  resetUserPassword: PropTypes.func.isRequired,
};

export default compose(
  injectNotification,
  withFetchers({
    resetUserPassword: {
      handler: ({ userId }) => () => Api.usersApi.expireUserPassword(userId),
      onSuccess: ({ notification }) => notification.success('', 'Password set as expired!'),
      onError: ({ notification }) =>
        notification.error('Something went wrong, talk to the dev team'),
    },
  }),
)(ExpirePasswordAction);
