import React from 'react';
import { compose, withHandlers } from 'recompose';
import { Field, Form } from 'formik';
import { object, string } from 'yup';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import Api from '../../../../../../api';

/**
 * Note that the value object has properties with kebab-case
 */
const schema = object()
  .shape({
    virtualNamespaceName: string()
      .matches(/^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/) // kebab case regex
      .min(2)
      .max(20)
      .required('Service name required, in kebab case format'),
  });

const NamespaceInfoForm = ({
  createVirtualNs,
}) => (
  <WrappedFormik
    enableReinitialize
    onSubmit={createVirtualNs}
    validationSchema={schema}
    render={({ isValid }) => (
      <Form className="namespace-input-form">
        <div className="row namespace-name-input">
          <Field
            id="virtualNamespaceName"
            name="virtualNamespaceName"
            label="Virtual namespace name"
            component={CleanFormikInput}
            autoFocus
          />
        </div>
        <Button
          type="submit"
          className={`btn btn-big btn-min-100 btn-block ${
            isValid ? 'bg-color-brand-button' : 'bg-color-brand-accent'
          }`}
        >
          Create
        </Button>
      </Form>
    )}
  />
);

const enhanced = compose(
  withHandlers({
    createVirtualNs: () => values => {
      Api.namespaceManagerApi.createVirtualNamespace({
        namespaceName: values.virtualNamespaceName,
      });
    },
  }),
)(NamespaceInfoForm);

export default {
  component: enhanced,
  mapFlowDataToProps: () => ({}),
  mapPayloadToFlowData: values => ({
    ...values,
  }),
};
