import { combineReducers } from 'redux';
import genericReducer from '../../genericReducer';
import {
  GET_ALL_SDK_PERMISSIONS,
  BACKOFFICE_GET_SDK_PERMISSION,
  GET_ALL_SDK_THEMES,
  GET_SDK_THEME,
} from './actions';

const initialState = {
  permissions: [],
  permission: {},
  themes: [],
  theme: {},
};

export default combineReducers({
  permissions: genericReducer(GET_ALL_SDK_PERMISSIONS, initialState.permissions, null),
  permission: genericReducer(BACKOFFICE_GET_SDK_PERMISSION, initialState.permission, null),
  themes: genericReducer(GET_ALL_SDK_THEMES, initialState.themes, null),
  theme: genericReducer(GET_SDK_THEME, initialState.theme, null),
});
