/**
 * Created by chenrozenes on 09/04/2017.
 */
import PromiseActionType from '../../promiseActionType';

export const GET_USER_BY_ID = new PromiseActionType('GET_USER_BY_ID');
export const GET_ALL_USERS = new PromiseActionType('GET_ALL_USERS');
export const GET_ALLOWED_GROUPS = new PromiseActionType('GET_ALLOWED_GROUPS');
export const CHANGE_PASSWORD = new PromiseActionType('CHANGE_PASSWORD');
export const GET_PERMISSIONS_PAGE = new PromiseActionType('GET_PERMISSIONS_PAGE');
export const ADD_PERMISSION = new PromiseActionType('ADD_PERMISSION');
export const REMOVE_PERMISSION = new PromiseActionType('REMOVE_PERMISSION');
export const CREATE_USER = new PromiseActionType('CREATE_USER');
export const UPDATE_TAGS = new PromiseActionType('UPDATE_TAGS');
export const LOCK_USER = new PromiseActionType('LOCK_USER');
export const UNLOCK_USER = new PromiseActionType('UNLOCK_USER');
export const MARK_USER_VERIFIED = new PromiseActionType('MARK_USER_VERIFIED');
export const DEACTIVATE_USER = new PromiseActionType('DEACTIVATE_USER');
export const REACTIVATE_USER = new PromiseActionType('REACTIVATE_USER');
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const CLEAR_USER_PERMISSIONS = 'CLEAR_USER_PERMISSIONS';
export const EXPIRE_USER_PASSWORD = 'EXPIRE_USER_PASSWORD';

export function getAllUsers(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: GET_ALL_USERS.SOURCE,
    payload: {
      promise: usersApi.getAllUsers(skip, limit, search, sort, sortAscending).then(res => res.data),
    },
  });
}

export function getUser(id) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: GET_USER_BY_ID.SOURCE,
    payload: {
      promise: usersApi.getUser(id).then(res => res.data),
    },
  });
}

export function changePassword(userId, password, isTemporaryPassword) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: CHANGE_PASSWORD.SOURCE,
    payload: {
      promise: usersApi.changePassword(userId, password, isTemporaryPassword).then(res => res.data),
    },
    meta: {
      tracker: CHANGE_PASSWORD.SOURCE,
    },
  });
}

export function getUserPermissions(userId, options) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: GET_PERMISSIONS_PAGE,
    payload: {
      promise: usersApi.getUserPermissions(userId, options).then(res => res.data),
    },
  });
}

export function getGroupPermissions(groupId, options) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: GET_PERMISSIONS_PAGE,
    payload: {
      promise: usersApi.getGroupPermissions(groupId, options).then(res => res.data),
    },
  });
}

export function clearPermissions() {
  return {
    type: CLEAR_USER_PERMISSIONS,
  };
}

export function addPermission(promoteOnly, userId, permission, resourceType, resourceId, isGroup) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: ADD_PERMISSION,
    payload: {
      promise: usersApi
        .addPermission(promoteOnly, userId, permission, resourceType, resourceId, isGroup)
        .then(res => res.data),
    },
    meta: {
      tracker: ADD_PERMISSION.SOURCE,
    },
  });
}

export function removePermission(userId, resourceType, resourceId, isGroup) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: REMOVE_PERMISSION,
    payload: {
      promise: usersApi
        .removePermission(userId, resourceType, resourceId, isGroup)
        .then(res => res.data),
    },
    meta: {
      tracker: REMOVE_PERMISSION.SOURCE,
    },
  });
}

export function createUser(email, password, phoneNumber) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: CREATE_USER.SOURCE,
    payload: {
      promise: usersApi
        .createUserFromBackoffice(email, password, phoneNumber)
        .then(res => res.data),
    },
    meta: {
      tracker: CREATE_USER.SOURCE,
    },
  });
}

export function updateTags(userId, tags) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: UPDATE_TAGS.SOURCE,
    payload: {
      promise: usersApi.updateTags(userId, tags).then(res => res.data),
    },
    meta: {
      tracker: UPDATE_TAGS.SOURCE,
    },
  });
}

export function markVerified(userId) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: MARK_USER_VERIFIED.SOURCE,
    payload: {
      promise: usersApi.markVerified(userId).then(res => res.data),
    },
    meta: {
      tracker: MARK_USER_VERIFIED.SOURCE,
    },
  });
}

export function lockUser(userId) {
  return ({ bkmdApi: { usersApi }, dispatch }) => ({
    type: LOCK_USER.SOURCE,
    payload: {
      promise: usersApi
        .lock(userId)
        .tap(() => {
          dispatch(getUser(userId));
        })
        .then(res => res.data),
    },
  });
}

export function unlockUser(userId) {
  return ({ bkmdApi: { usersApi }, dispatch }) => ({
    type: UNLOCK_USER.SOURCE,
    payload: {
      promise: usersApi
        .unlock(userId)
        .tap(() => {
          dispatch(getUser(userId));
        })
        .then(res => res.data),
    },
  });
}

export function deactivateUser(userId) {
  return ({ bkmdApi: { backofficeApi }, dispatch }) => ({
    type: DEACTIVATE_USER.SOURCE,
    payload: {
      promise: backofficeApi
        .executeAction('Activation', 'deactivateUser', { userId })
        .tap(() => {
          dispatch(getUser(userId));
        })
        .then(res => res.data),
    },
    meta: {
      tracker: DEACTIVATE_USER.SOURCE,
    },
  });
}

export function expireUserPassword(userId) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: EXPIRE_USER_PASSWORD.SOURCE,
    payload: {
      promise: usersApi.expireUserPassword(userId).then(res => res.data),
    },
    meta: {
      tracker: EXPIRE_USER_PASSWORD.SOURCE,
    },
  });
}

export function reactivateUser(userId) {
  return ({ bkmdApi: { backofficeApi }, dispatch }) => ({
    type: REACTIVATE_USER.SOURCE,
    payload: {
      promise: backofficeApi
        .executeAction('Activation', 'reactivateUser', { userId })
        .tap(() => {
          dispatch(getUser(userId));
        })
        .then(res => res.data),
    },
    meta: {
      tracker: REACTIVATE_USER.SOURCE,
    },
  });
}
