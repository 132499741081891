import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import Api from '../../../../../api';
import CreateAllowedProvider from './components/createAllowedProvider';
import TurnAllowedProviderButton from './components/turnAllowedProviderButton';
import TurnAllowedProviders from './components/turnAllowedProviders';
import DeleteActionButtonCell from '../../components/deleteActionButtonCell';
import './allowedProvidersList.less';
import { getDomain } from '../../../../../utils/domain/storage';

@autobind
class AllowedProvidersList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    listAllowedProvider: PropTypes.func.isRequired,
    deleteAllowedProvider: PropTypes.func.isRequired,
    updateAllowedProvider: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    allowedProviders: PropTypes.object.isRequired,
  };

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.listAllowedProvider(skip, limit, search, sort, sortAscending);
  }

  render() {
    const {
      allowedProviders,
      listAllowedProvider,
      deleteAllowedProvider,
      updateAllowedProvider,
    } = this.props;
    return (
      <div className="allowed-providers-page">
        <div className="backoffice-action">
          <TurnAllowedProviders onSuccess={listAllowedProvider} />
          <br />
          <br />
          <br />
          <CreateAllowedProvider onSuccess={listAllowedProvider} />
        </div>
        <QueryStringGrid
          data={_.get(allowedProviders, 'data.data', [])}
          totalCount={_.get(allowedProviders, 'data.count', 0)}
          onQueryChange={this.onQueryChange}
          allowSearch
          classNames={{ Table: 'bkmd-table auto-layout' }}
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="officeName" title="Office Name" />
          <BkmdGridColumn id="npi" title="Npi" />
          <BkmdGridColumn id="providerTitle" title="Provider" />
          <BkmdGridColumn
            id="updateAction"
            title="&nbsp;"
            sortable={false}
            customComponent={TurnAllowedProviderButton}
            onClick={updateAllowedProvider}
          />
          <BkmdGridColumn
            id="deleteAction"
            title="Delete"
            sortable={false}
            customComponent={DeleteActionButtonCell}
            onClick={deleteAllowedProvider}
          />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withStateFetchersOnMount({
    listAllowedProvider: {
      handler: ({ skip, limit, search, sort, sortAscending }) => () =>
        Api.appointmentRequestsApi.getAllAllowedProviders(
          getDomain(),
          skip,
          limit,
          search,
          sort,
          sortAscending,
        ),
      resultPropName: 'allowedProviders',
      isReady: ({ allowedProviders }) => !_.isEmpty(allowedProviders),
    },
  }),
  withFetchers({
    deleteAllowedProvider: {
      handler: () => ({ id }) => Api.appointmentRequestsApi.deleteAllowedProvider(id),
      onSuccess: ({ listAllowedProvider }) => listAllowedProvider(),
    },
    updateAllowedProvider: {
      handler: () => (id, allowedUpdate) =>
        Api.appointmentRequestsApi.updateAllowedProvider({ id, isAllowed: allowedUpdate }),
      onSuccess: ({ listAllowedProvider }) => listAllowedProvider(),
    },
  }),
)(AllowedProvidersList);
