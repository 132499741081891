/**
 * Created by guyavraham on 15/06/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../../genericReducer';
import {
  GET_CALENDAR,
  CREATE_CALENDAR,
  UPDATE_CALENDAR,
  DELETE_CALENDAR,
  GET_ALL_CALENDARS_BY_CLINIC,
  GET_ALL_CALENDARS_BY_PROVIDER,
} from './actions';

const initialState = {
  selectedlist: {
    count: 0,
    data: [],
  },
  details: {},
  raw: {},
  permissions: {
    count: 0,
    data: [],
  }
};

export default combineReducers({
  selectedlist: reduceReducers(
    genericReducer(GET_ALL_CALENDARS_BY_PROVIDER, initialState.selectedlist),
    genericReducer(GET_ALL_CALENDARS_BY_CLINIC, initialState.selectedlist),
  ),
  details: reduceReducers(
    genericReducer(GET_CALENDAR, initialState.details),
    genericReducer(UPDATE_CALENDAR, initialState.details),
    genericReducer(CREATE_CALENDAR, initialState.details),
    genericReducer(DELETE_CALENDAR, initialState.details),
  ),
});
