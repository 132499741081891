/**
 * Created by ofirHartuv on 02/11/17.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { defineMessages, FormattedMessage } from 'react-intl';

import { BkmdGridColumn, Columns } from '../../../../../../components/grid';
import queryConnect from '../../../../../../store/tools/queryString/queryConnect';
import QueryStringGrid, {
  gridQueryParamsProps,
} from '../../../../../../components/grid/queryStringGrid';
import * as selectors from '../../../../../../store/backoffice/clinicManagement/appointment/selectors';
import {
  getAllAppointmentsForCalendar,
  GET_ALL_APPOINTMENTS_BY_CALENDAR,
} from '../../../../../../store/backoffice/clinicManagement/appointment/actions';
import displayProvider from '../../../../../../utils/displayProvider';
import Formmater from '../../../../../../utils/formatter';
import commonFormats from '../../../../../../utils/formatter/commonFormats';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import ThreeBounceSpinner from '../../../../../../components/ui/spinner/threeBounceSpinner';

export const messages = defineMessages({
  searchExplanation: {
    defaultMessage: 'You can search by appointment id, status, provider npi and appointment token',
    id: 'appointmentProductList.appointment.searchExplanation',
  },
});

// TODO: backpack
const formatAppointments = function formatAppointemtsData(appointments) {
  if (_.isEmpty(appointments)) return [];
  return _.map(appointments, appointment => {
    const providerName =
      appointment.provider &&
      displayProvider(
        _.get(appointment, 'provider.firstName'),
        _.get(appointment, 'provider.lastName'),
        _.get(appointment, 'provider.suffix'),
      );

    let patientName;
    if (appointment.namespacePatient) {
      const { firstName, middleName, lastName } = appointment.namespacePatient;
      patientName = `${firstName} ${middleName || ''} ${lastName}`;
    }

    return {
      ...appointment,
      providerName,
      patientName,
      startTime: Formmater.date(
        appointment.timeZone
          ? moment(appointment.startTime).tz(appointment.timeZone)
          : appointment.startTime,
        commonFormats.DATE_AND_TIME,
      ),
    };
  });
};
@autobind
class ProductAppointmentList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllAppointmentsForCalendar: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    appointments: PropTypes.array.isRequired,
    getAppointmentsTracker: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.getAllAppointmentsForCalendar(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  onClick(rowData) {
    const appointmentId = rowData.id;
    this.props.router.push(`/secure/productAppointments/${appointmentId}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllAppointmentsForCalendar(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { appointments, count, getAppointmentsTracker } = this.props;
    const isLoading = _.get(getAppointmentsTracker, 'inProgress', false);
    return (
      <div>
        {isLoading ? (
          <ThreeBounceSpinner />
        ) : (
          <div>
            <FormattedMessage {...messages.searchExplanation} />
            <hr />
            <QueryStringGrid
              data={formatAppointments(appointments)}
              totalCount={count}
              onQueryChange={this.onQueryChange}
              onRowClick={this.onClick}
              allowSearch
            >
              <BkmdGridColumn id="id" title="appointment Id" />
              <BkmdGridColumn id="startTime" title="Appointment time" />
              <BkmdGridColumn id="source" title="source" />
              <BkmdGridColumn id="patientName" title="Patient Name" />
              <BkmdGridColumn id="providerName" title="Provider’s name" />
              <BkmdGridColumn id="status" title="Status" />
              <BkmdGridColumn
                id="requestExpeditedAppointment"
                title="Requested to be expedited"
                customComponent={Columns.BooleanColumn}
              />
            </QueryStringGrid>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  queryConnect(
    query => ({
      limit: parseInt(query.limit, 10),
      skip: parseInt(query.skip, 10),
      search: query.search,
      sort: query.sort,
      sortAscending: query.sortAscending,
    }),
    { limit: 10, skip: 0, search: '', sort: 'startTime', sortAscending: true },
  ),
  withRouter,
  connect(
    state => ({
      appointments: selectors.getAppointmentsList(state),
      count: selectors.getAppointmentsListCount(state),
    }),
    { getAllAppointmentsForCalendar },
  ),
  actionTracker({
    getAppointmentsTracker: GET_ALL_APPOINTMENTS_BY_CALENDAR.SOURCE,
  }),
)(ProductAppointmentList);
