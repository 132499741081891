/**
 * Created by chenrozenes on 24/04/2017.
 */
import React from 'react';
import './home.less';

export default function Home() {
  return (
    <div className="backoffice-home">
      <div className="row text-center">
        <div className="col-xs-12">
          <h1>Vim`s Backoffice</h1>
          <br />
          <img
            className="main-img"
            src="https://s3.amazonaws.com/bookmd-photos-development/photo/maintenance/backoffice-justice-league.jpg"
            alt="Backoffice"
          />
          <br />
          <br />
          Be cautious, dev team is watching....
        </div>
      </div>
    </div>
  );
}
