import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompose';
import Text from '../../../storybook/src/components/atoms/Text/Text';

export const messages = defineMessages({
  unverified: {
    defaultMessage: 'Unverified Location',
    id: 'backpack.LocationVerification.unverified',
  },
  verified: {
    defaultMessage: 'Verified Location',
    id: 'backpack.LocationVerification.verified',
  },
});

const LocationVerification = ({ isVerified }) => {
  let iconClass;
  let message;
  if (isVerified) {
    iconClass = 'text-success icon-map-pin-v';
    message = messages.verified;
  } else {
    iconClass = 'text-danger icon-map-pin-x';
    message = messages.unverified;
  }
  iconClass += ' i-va-fix-2';

  return (
    <span>
      <Text size="14px" inline>
        <i className={iconClass} />
      </Text>
      &nbsp;
      <Text size="12px" inline>
        <FormattedMessage {...message} />
      </Text>
    </span>
  );
};

LocationVerification.propTypes = {
  isVerified: PropTypes.bool,
};
LocationVerification.defaultProps = {
  isVerified: false,
};

export default compose(
  setDisplayName('LocationVerification'),
  onlyUpdateForKeys(['isVerified']),
)(LocationVerification);
