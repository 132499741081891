/* eslint-disable consistent-return */
import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import AlertsContainer from '../../../../../../../../storybook/src/components/molecules/AlertsContainer/AlertsContainer';
import withClinicInfo from './clinicInfo';
import withCalendarInfo from './calendarInfo';
import { withWarningAlert, withErrorAlert } from '../../investigationUtils/alerts';

const CommonInvestigation = compose(
  withRouter,
  withHandlers({
    goToClinic: ({ router }) => clinicId => {
      router.push(`/secure/clinics/${clinicId}`);
    },
  }),
  withClinicInfo,
  withCalendarInfo,
  withWarningAlert(({ providerCSI: { clinicMatchingData } }) => {
    const clinicsCloseToAddress = filter(
      clinicMatchingData.possibleClinics,
      clinic => clinic.distanceFromAddress <= 500,
    );
    if (clinicsCloseToAddress.length > 1)
      return (
        <span>
          There are <strong>{clinicsCloseToAddress.length}</strong> matched clinics that are less
          than 500 meter from the provided address
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { clinicMatchingData } }) => {
    if (isEmpty(clinicMatchingData.foundClinic)) return <span>Could not match clinic!</span>;
  }),
  withErrorAlert(({ providerCSI: { providerCalendarData } }) => {
    if (isEmpty(providerCalendarData))
      return (
        <span>
          <strong>Calendar missing</strong> from the provider resource!
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { clinicMatchingData } }) => {
    const matchedClinic = clinicMatchingData.foundClinic;
    if (get(matchedClinic, 'providerResource.length') > 1)
      return (
        <span>
          found <strong>{matchedClinic.providerResource.length}</strong> provider resources in same
          clinic! should only be one
        </span>
      );
  }),
)(AlertsContainer);

export default CommonInvestigation;
