import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import Flow from 'Components/wizard/flow/Flow';
import BkmdModal from 'Components/bkmdModal/bkmdModal';
import ModalHeader from 'Components/bkmdModal/modalHeader';
import flowDef from './flowDef';
import steps from '../steps';

import './index.less';

const messages = defineMessages({
  updateNamespaceTitle: {
    defaultMessage: 'Update {namespace} provider namespace',
    id: 'backpack.editNamespaceModal.title',
  },
});

const EditNamespaceModal = ({ isOpen, handleClose, onSuccess, namespace }) => (
  <BkmdModal
    isOpen={isOpen}
    className="edit-namespace-modal fixed-header"
    handleClose={handleClose}
  >
    <ModalHeader
      onClose={handleClose}
      title={{ ...messages.updateNamespaceTitle, values: { namespace } }}
    />
    <div className="dialog-body">
      <Flow
        name={flowDef.name}
        definitions={flowDef.definitions}
        initialValues={{
          handleClose,
          onSuccess,
          namespace,
        }}
        initialStepName={steps.FETCH_NS_DETAILS}
      />
    </div>
  </BkmdModal>
);

EditNamespaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

EditNamespaceModal.defaultProps = {
  onSuccess: undefined,
};

export default EditNamespaceModal;
