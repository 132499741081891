import React from 'react';
import { autobind } from 'core-decorators';
import Action from '../../components/action';

@autobind
class DeleteAsoGroupAction extends React.PureComponent {
  render() {
    return (
      <Action name="deleteAsoGroup" title="Delete Aso Group" btnColor="red" {...this.props}>
        <div>delete ASO group and its tiers - action can not be reverted</div>
      </Action>
    );
  }
}

export default DeleteAsoGroupAction;
