/**
 * created by moran on 11/28/17
 */
import { combineReducers } from 'redux';
import genericReducer from '../../genericReducer';
import {
  LIST_MEDICAL_SERVICES,
  GET_MEDICAL_CODE_BY_ID
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  details: {},
};

export default combineReducers({
  list: genericReducer(LIST_MEDICAL_SERVICES, initialState.list, null, { keepStateOnStart: true }),
  details: genericReducer(GET_MEDICAL_CODE_BY_ID, initialState.details),
});
