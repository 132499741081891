import { compose, withPropsOnChange } from 'recompose';
import { isEmpty, omit } from 'lodash';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { withTranslatedMessages } from '../../../../../../utils/withTranslatedMessages';
import PatientView from './patientView';
import { patientMessages } from './formDefs';
import { internalApi } from '../../../internal-api';

const getAdaptedPatientData = result => {
  return {
    ...omit(result, ['firstName', 'lastName', 'mobilePhoneNumber']),
    phoneNumber: result.mobilePhoneNumber,
    fullName: `${result.firstName} ${result.lastName}`,
  };
};

export default compose(
  withStateFetchersOnMount({
    getFullPatientDetails: {
      handler: ({ params }) => () =>
        internalApi.namespace(params.namespaceName).patients.getPatient({ id: params.id }),
      resultPropName: 'patientQueryResult',
      defaultValue: {},
      isReady: ({ patientQueryResult }) => !isEmpty(patientQueryResult),
    },
  }),
  withPropsOnChange(['patientQueryResult'], ({ patientQueryResult }) => ({
    patient: getAdaptedPatientData(patientQueryResult),
  })),
  withTranslatedMessages(patientMessages),
)(PatientView);
