import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import { ApolloLink, execute, makePromise } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import BkmdApi from '../../../../../api/bkmdApi';

const FETCH_TYPE = {
  namespace: 'namespace',
  microService: 'microService',
};

class GraphqlFetcher {
  constructor(fetchType) {
    const middlewareLink = new ApolloLink((operation, forward) => {
      // Add auth, general headers to request
      operation.setContext({
        headers: _.omit(BkmdApi._headers(), ['Accept', 'Content-Type']),
      });

      return forward(operation);
    });

    const serviceType = fetchType === FETCH_TYPE.namespace ? 'namespace' : 'microServices';

    const httpLink = createHttpLink({
      fetch: (uri, options) => fetch(`/api/v1/${serviceType}/${uri}`, options),
    });

    this.link = middlewareLink.concat(httpLink);
  }

  async queryOrMutate(graphqlRequest) {
    return makePromise(execute(this.link, graphqlRequest)).catch(error => {
      console.error(`Graphql Error on operation '${graphqlRequest.operationName}' `, { error });
      throw new Error(`Failed to execute graphql api request '${graphqlRequest.operationName}'`);
    });
  }
}

export const namespaceGraphqlFetcher = new GraphqlFetcher(FETCH_TYPE.namespace);
export const microServiceGraphqlFetcher = new GraphqlFetcher(FETCH_TYPE.microService);
