/**
 * created by moran on 12/4/17
 */
import _ from 'lodash';
import PromiseActionType from '../../../promiseActionType';
import Formatter from '../../../../utils/formatter';

export const DELETE_APPOINTMENT_TYPE = new PromiseActionType('DELETE_APPOINTMENT_TYPE');
export const GET_APPOINTMENT_TYPE = new PromiseActionType('GET_APPOINTMENT_TYPE');
export const GET_APPOINTMENT_TYPES_LIST = new PromiseActionType('GET_APPOINTMENT_TYPES_LIST');
export const GET_APPOINTMENT_TYPES_BY_CALENDAR_ID = new PromiseActionType(
  'GET_APPOINTMENT_TYPES_BY_CALENDAR_ID',
);
export const CREATE_APPOINTMENT_TYPE = new PromiseActionType('CREATE_APPOINTMENT_TYPE');
export const CREATE_BULK_APPOINTMENT_TYPE = new PromiseActionType('CREATE_BULK_APPOINTMENT_TYPE');
export const UPDATE_APPOINTMENT_TYPE = new PromiseActionType('UPDATE_APPOINTMENT_TYPE');

export function getAppointmentType(id) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_APPOINTMENT_TYPE.SOURCE,
    meta: {
      tracker: GET_APPOINTMENT_TYPE.SOURCE,
    },
    payload: {
      promise: resourceApi.getAppointmentType(id).then(res => {
        // converting the duration to minutes for display
        res.data.duration = Formatter.msToMinutes(res.data.duration);
        return res.data;
      }),
    },
  });
}

export function getAppointmentTypesList(resourceId, includeSoftDeleted) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_APPOINTMENT_TYPES_LIST.SOURCE,
    meta: {
      tracker: GET_APPOINTMENT_TYPES_LIST.SOURCE,
    },
    payload: {
      promise: resourceApi.getAppointmentTypesList(resourceId, includeSoftDeleted).then(res => {
        const apptTypesWithDurationInMinutes = _.map(res.data.data, apptType => ({
          ...apptType,
          duration: Formatter.msToMinutes(apptType.duration),
        }));
        return {
          ...res.data,
          data: apptTypesWithDurationInMinutes,
        };
      }),
    },
  });
}

export function getCalendarAppointmentTypes(calendarId) {
  return ({ bkmdApi: { scheduleApi } }) => ({
    type: GET_APPOINTMENT_TYPES_BY_CALENDAR_ID.SOURCE,
    meta: {
      tracker: GET_APPOINTMENT_TYPES_BY_CALENDAR_ID.SOURCE,
    },
    payload: {
      promise: scheduleApi.getCalendarAppointmentTypes(calendarId).then(res => res.data),
    },
  });
}

export function createAppointmentType(data) {
  // converting the duration to ms because we display it as minutes
  data.duration = Formatter.minutesToMs(data.duration);
  return ({ bkmdApi: { resourceApi }, dispatch }) => ({
    type: CREATE_APPOINTMENT_TYPE.SOURCE,
    meta: {
      tracker: CREATE_APPOINTMENT_TYPE.SOURCE,
    },
    payload: {
      promise: resourceApi
        .createAppointmentType(data)
        .then(res => res.data)
        .tap(() => {
          dispatch(getAppointmentTypesList(data.resourceId));
        }),
    },
  });
}

export function createBulkAppointmentType(data) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: CREATE_BULK_APPOINTMENT_TYPE.SOURCE,
    meta: {
      tracker: CREATE_BULK_APPOINTMENT_TYPE.SOURCE,
    },
    payload: {
      promise: resourceApi.createProviderResourceBulkAppointmentType(data).then(res => res.data),
    },
  });
}

export function updateAppointmentType(data) {
  // converting the duration to ms because we display it as minutes
  data.duration = Formatter.minutesToMs(data.duration);
  return ({ bkmdApi: { resourceApi } }) => ({
    type: UPDATE_APPOINTMENT_TYPE.SOURCE,
    meta: {
      tracker: UPDATE_APPOINTMENT_TYPE.SOURCE,
    },
    payload: {
      promise: resourceApi.updateProviderResourceAppointmentType(data).then(res => {
        // converting the duration to minutes for display
        res.data.duration = Formatter.msToMinutes(res.data.duration);
        return res.data;
      }),
    },
  });
}

export function deleteAppointmentType(id) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: DELETE_APPOINTMENT_TYPE.SOURCE,
    meta: {
      tracker: DELETE_APPOINTMENT_TYPE.SOURCE,
    },
    payload: {
      promise: resourceApi.deleteAppointmentType(id).then(res => res.data),
    },
  });
}
