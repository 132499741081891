/* eslint-disable class-methods-use-this */
/**
 * created by moran on 10/31/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import _ from 'lodash';
import * as CreateProviderFormDef from './createProfieFormDefs';
import Action from '../../components/action';
import {
  CREATE_PROVIDER_PROFILE,
  createProvider,
} from '../../../../../store/providerProfile/actions';
import CreateProviderProfileActionForm from './createProviderProfileForm';
import { name } from './createProfieFormDefs';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';

@autobind
class CreateProviderProfileAction extends React.PureComponent {
  static propTypes = {
    createProvider: PropTypes.func.isRequired,
  };

  onPasswordStrengthChanged(fn, value) {
    fn(CreateProviderFormDef.fields.passwordStrength.name, value);
  }

  callAction(data) {
    this.props.createProvider(
      _.omit(data, 'domains', 'repeatPassword', 'passwordStrength'),
      data.domains,
    );
  }

  render() {
    const { newPasswordValidationFeature } = this.props;
    return (
      <Action
        name="createProfile"
        title="Create Profile"
        actionTrackerId={CREATE_PROVIDER_PROFILE.SOURCE}
        formName={name}
        {...this.props}
      >
        <CreateProviderProfileActionForm
          onStrengthChanged={this.onPasswordStrengthChanged}
          newPasswordValidationFeature={newPasswordValidationFeature}
          onSubmit={this.callAction}
        />
      </Action>
    );
  }
}

export default compose(
  connect(null, { createProvider }),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(CreateProviderProfileAction);
/* eslint-enable class-methods-use-this */
