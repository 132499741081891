import _ehrServices from './services';

export const ehrServices = {
  ..._ehrServices,
};

export const ehrServicesAsArray = Object.keys(ehrServices).reduce(
  (servicesArr, service) => [...servicesArr, { id: service, ...ehrServices[service] }],
  [],
);
