import React from 'react';
import { compose, withHandlers, setPropTypes, defaultProps } from 'recompose';
import map from 'lodash/map';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import './GridPicker.less';
import GridOption from '../../atoms/GridOption/GridOption';

const GridPicker = ({ options, onClick }) => (
  <div className="grid-picker">
    {map(options, option => (
      <GridOption option={option} onClick={onClick} />
    ))}
  </div>
);

GridPicker.propTypes = {
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

const enhanced = compose(
  defaultProps({
    onClick: noop,
  }),
  setPropTypes({
    options: PropTypes.array.isRequired,
    onClick: PropTypes.func,
  }),
  withHandlers({
    onClick: ({ onClick }) => onClick,
  }),
)(GridPicker);

export default enhanced;
