import Joi from 'joi-browser';
import { defineMessages } from 'react-intl';

import * as AppointmentStatus from '../../../../../../model/enum/appointmentStatus';
import AppointmentStatusIntl from '../../../../../../model/enum/appointmentStatusIntlEnum';
import ArchiveReasonsIntl from '../../../../../../model/enum/archiveReasonsIntlEnum';
import { enumSchema } from '../../../../../../utils/commonSchema';
import {
  ReduxFormCleanCheckbox,
  ReduxFormCleanInput,
  ReduxFormDateTime,
  ReduxFormIntlSelect,
} from '../../../../../../components/form/reduxForm/components';

export const name = 'backoffice-product-appointment';

export const appointmentMessages = defineMessages({
  title: {
    defaultMessage: 'Appointment details:',
    id: 'appointmentProductForm.appointment.title',
  },
  id: {
    defaultMessage: 'Id',
    id: 'appointmentProductForm.appointment.id',
  },
  externalId: {
    defaultMessage: 'External Id',
    id: 'appointmentProductForm.appointment.externalId',
  },
  source: {
    defaultMessage: 'Source',
    id: 'appointmentProductForm.appointment.source',
  },
  memberDomain: {
    defaultMessage: 'Domain member',
    id: 'appointmentProductForm.appointment.memberDomain',
  },
  startTime: {
    defaultMessage: 'Start time',
    id: 'appointmentProductForm.appointment.startTime',
  },
  fullName: {
    defaultMessage: 'Provider Name',
    id: 'appointmentProductForm.appointment.fullName',
  },
  endTime: {
    defaultMessage: 'End time',
    id: 'appointmentProductForm.appointment.endTime',
  },
  status: {
    defaultMessage: 'Status',
    id: 'appointmentProductForm.appointment.status',
  },
  archiveReason: {
    defaultMessage: 'Archive reason',
    id: 'appointmentProductForm.appointment.archiveReason',
  },
  requestExpeditedAppointment: {
    defaultMessage: 'Requested to be expedited',
    id: 'appointmentProductForm.appointment.requestExpeditedAppointment',
  },
  originAppointmentId: {
    defaultMessage: 'Rescheduled from appointment',
    id: 'appointmentProductForm.appointment.originAppointmentId',
  },
  token: {
    defaultMessage: 'Token',
    id: 'appointmentProductForm.appointment.token',
  },
  appointmentType: {
    defaultMessage: 'Appointment type',
    id: 'appointmentProductForm.appointment.appointmentType',
  },
  ehrPatientId: {
    defaultMessage: 'Ehr Patient Id',
    id: 'appointmentProductForm.appointment.ehrPatientId',
  },
  createdAt: {
    defaultMessage: 'Created at',
    id: 'appointmentProductForm.appointment.createdAt',
  },
  updatedAt: {
    defaultMessage: 'Updated at',
    id: 'appointmentProductForm.appointment.updatedAt',
  },
  lastEhrSync: {
    defaultMessage: 'Last ehr sync',
    id: 'appointmentProductForm.appointment.lastEhrSync',
  },
  appointmentNotSynced: {
    defaultMessage: 'This appointment was never synced with the ehr',
    id: 'appointmentProductForm.appointment.appointmentNotSynced',
  },
});

export const fields = {
  lastEhrSync: {
    name: 'lastEhrSync',
    component: ReduxFormDateTime,
  },
  startTime: {
    name: 'startTime',
    component: ReduxFormDateTime,
  },
  endTime: {
    name: 'endTime',
    component: ReduxFormDateTime,
  },
  createdAt: {
    name: 'createdAt',
    component: ReduxFormDateTime,
  },
  updatedAt: {
    name: 'updatedAt',
    component: ReduxFormDateTime,
  },
  ehrPatientId: {
    name: 'ehrPatientId',
    component: ReduxFormCleanInput,
    readOnly: false,
  },
  externalId: {
    name: 'externalId',
    component: ReduxFormCleanInput,
    readOnly: false,
  },
  status: {
    name: 'status',
    component: ReduxFormIntlSelect,
    intlEnum: AppointmentStatusIntl,
  },
  archiveReason: {
    name: 'archiveReason',
    component: ReduxFormIntlSelect,
    intlEnum: ArchiveReasonsIntl,
  },
  requestExpeditedAppointment: {
    name: 'requestExpeditedAppointment',
    id: 'requestExpeditedAppointment',
    component: ReduxFormCleanCheckbox,
    parse: value => !!value,
  },
};
export const schema = {
  startTime: Joi.date(),
  endTime: Joi.date(),
  status: enumSchema(AppointmentStatus),
};
