import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';
import {
  NOSHOW,
  DECLINED_BY_PROVIDER,
  DECLINED_BY_EHR,
  CANCELED_BY_PROVIDER,
  CANCELED_BY_MEMBER,
  CANCELED_BY_EHR,
  RESCHEDULED_BY_EHR,
} from './archiveReasons';

export const messages = defineMessages({
  noShow: {
    defaultMessage: 'No Show',
    id: 'archiveReason.accepted',
  },
  canceledByProvider: {
    defaultMessage: 'Canceled By Provider',
    id: 'archiveReason.canceledByProvider',
  },
  canceledByMember: {
    defaultMessage: 'Canceled By Member',
    id: 'archiveReason.canceledByMember',
  },
  canceledByEhr: {
    defaultMessage: 'Canceled By Ehr',
    id: 'archiveReason.canceledByEhr',
  },
  declinedByProvider: {
    defaultMessage: 'Declined By Provider',
    id: 'archiveReason.declinedByProvider',
  },
  declinedByEhr: {
    defaultMessage: 'Declined By Ehr',
    id: 'archiveReason.declinedByEhr',
  },
  rescheduledByEhr: {
    defaultMessage: 'Rescheduled By Ehr',
    id: 'archiveReason.rescheduledByEhr',
  },
});

export default new IntlEnum(
  { value: NOSHOW, message: messages.noShow },
  { value: CANCELED_BY_PROVIDER, message: messages.canceledByProvider },
  { value: CANCELED_BY_MEMBER, message: messages.canceledByMember },
  { value: CANCELED_BY_EHR, message: messages.canceledByEhr },
  { value: RESCHEDULED_BY_EHR, message: messages.rescheduledByEhr },
  { value: DECLINED_BY_PROVIDER, message: messages.declinedByProvider },
  { value: DECLINED_BY_EHR, message: messages.declinedByEhr },
);
