/**
 * Created by ofirHartuv on 01/11/17.
 */
import Joi from 'joi-browser';

export const name = 'backoffice-providerResource';

export const fields = {
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  clinicId: {
    name: 'clinicId',
    readOnly: true,
  },
  ehrProviderId: {
    name: 'ehrProviderId',
    label: 'Ehr Provider Id (Required for instant booking)',
  },
  isDeleted: {
    name: 'isDeleted',
    readOnly: true,
  },
};

export const schema = {
  id: Joi.number(),
  npi: Joi.string().required(),
  clinicId: Joi.number().required(),
  ehrProviderId: Joi.string()
    .allow(null)
    .optional(),
  referralTarget: Joi.number()
    .allow(null)
    .optional(),
  timeSlotTarget: Joi.number()
    .allow(null)
    .optional(),
  isDeleted: Joi.boolean(),
};
