import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../components/form/reduxForm/components/index';

export const name = 'backoffice-attach-member-past-activity';

export const fields = {
  oldDomainMemberId: {
    name: 'oldDomainMemberId',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  oldDomainMemberId: Joi.string(),
};
