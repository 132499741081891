import React from 'react';
import { compose, withHandlers, setPropTypes } from 'recompose';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import './GridOption.less';

const GridOption = compose(
  setPropTypes({
    option: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  withHandlers({
    onClick: ({ option, onClick }) => () => {
      onClick(get(option, 'value', option));
    },
  }),
)(({ option, onClick }) => (
  <div className="option" onClick={onClick}>
    {get(option, 'display', option)}
  </div>
));

export default GridOption;
