/**
 * Created by chenrozenes on 09/04/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../genericReducer';
import {
  GET_ALL_USERS,
  GET_USER_BY_ID,
  GET_PERMISSIONS_PAGE,
  CLEAR_USER_PERMISSIONS,
} from './actions';

const initialState = {
  list: { count: 0, data: [] },
  groupsList: { count: 0, data: [] },
  details: {},
  groupDetails: {},
  permissions: [],
};

export default combineReducers({
  list: genericReducer(GET_ALL_USERS, initialState.list, null, { keepStateOnStart: true }),
  details: genericReducer(GET_USER_BY_ID, initialState.details),
  permissions: reduceReducers(
    genericReducer(GET_PERMISSIONS_PAGE, initialState.permissions),
    (state, action) => (action.type === CLEAR_USER_PERMISSIONS ? initialState.permissions : state),
  ),
});
