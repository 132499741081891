import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { compose, setDisplayName, pure, withProps } from 'recompose';
import _ from 'lodash';

import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import * as formDef from './createGroupFormDef';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import Apis from '../../../../../../api';
import BkmdModelButton from '../../../../../../components/bkmdModalButton/bkmdModalButton';

function CreateGroupFormView(props) {
  return (
    <div>
      <WrappedFormik
        initialValues={{}}
        onSubmit={props.onSubmit}
        validationSchema={formDef.schema}
        render={({ isValid }) => (
          <Form>
            <div className="row">
              <Field label="Name" {...formDef.fields.name} />
            </div>
            <div className="row">
              <Field label="Owning Resource Type" {...formDef.fields.ownerType} />
            </div>
            <div className="row">
              <Field label="Owning Resource Id" {...formDef.fields.ownerId} />
            </div>
            <SpinnerButton
              type="submit"
              className="btn btn-big btn-min-100 btn-blue"
              disabled={!isValid}
              isLoading={props.onSubmitTracker.inProgress}
            >
              Create Group
            </SpinnerButton>
          </Form>
        )}
      />
    </div>
  );
}

CreateGroupFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  notification: PropTypes.object.isRequired,
};

CreateGroupFormView.defaultProps = {
  onSuccess: _.noop,
};

export const CreateGroupForm = compose(
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: () => values => {
        const group = _.omitBy(values, _.isEmpty); // replace empty strings with null
        return Apis.usersApi.createUserGroup(
          group.name,
          _.get(group, 'ownerType.value'),
          group.ownerId,
        );
      },
      onSuccess({ notification, closeModal, onSuccess }, result) {
        notification.success('Create Group', 'Group created successfully');
        onSuccess(result);
        closeModal();
      },
      onError: ({ notification }) =>
        notification.error('Create Group', 'Create group failed due to an unexpected error'),
    },
  }),
)(CreateGroupFormView);

export default compose(
  setDisplayName('CreateGroupAction'),
  pure,
  withProps({
    title: 'Create Group',
    buttonText: 'Create Group',
    component: CreateGroupForm,
  }),
)(BkmdModelButton);
