import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { BkmdGridColumn, Columns, QueryStringGrid } from 'Components/grid';

export default function AppointmentsList({
  appointments,
  onAppointmentClick,
  totalCount,
  getAppointments,
}) {
  const parsedAppointments = appointments.map(appointment => ({
    ...appointment,
    patientId: get(appointment, 'patientId'),
  }));

  return (
    <div>
      <QueryStringGrid
        data={parsedAppointments}
        totalCount={totalCount}
        onQueryChange={(skip, limit, search, sort, sortAscending) => {
          getAppointments({ take: limit, skip, search, sort, sortAscending });
        }}
        onRowClick={onAppointmentClick}
        allowSearch
        searchLabel="Search by: Appointment Id / NPI / Patient Id"
      >
        <BkmdGridColumn id="id" title="Id" />
        <BkmdGridColumn id="status" title="Status" />
        <BkmdGridColumn id="npi" title="NPI" />
        <BkmdGridColumn id="startTime" title="Start time" customComponent={Columns.DateColumn} />
        <BkmdGridColumn id="endTime" title="End time" customComponent={Columns.DateColumn} />
        <BkmdGridColumn id="patientId" title="Patient ID" />
      </QueryStringGrid>
    </div>
  );
}

AppointmentsList.propTypes = {
  appointments: PropTypes.array.isRequired,
  onAppointmentClick: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  getAppointments: PropTypes.func.isRequired,
};
