/**
 * Created by chenrozenes on 15/12/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { READ } from '../../model/enum/permissionLevel';
import { getPermissions } from './selectors';
import { validatePermissions } from './util';

/**
 * Wrapper component that will render its children only
 * if the user has the provided acl permissions
 */
@autobind
class AclGuard extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
    permission: PropTypes.number,
    userPermissions: PropTypes.array, // from redux
    noPermissionComponent: PropTypes.func,
  };

  static defaultProps = {
    id: undefined,
    permission: READ,
    userPermissions: undefined,
    noPermissionComponent: null,
  };

  hasPermission() {
    const { permission, userPermissions, type, id } = this.props;
    if (!userPermissions) return false;
    return validatePermissions(userPermissions, permission, type, id);
  }

  render() {
    const { noPermissionComponent: NoPermissionComponent } = this.props;
    if (!this.hasPermission()) return NoPermissionComponent ? <NoPermissionComponent /> : null;

    return <div className="display-contents">{this.props.children}</div>;
  }
}

export default connect((state) => ({
  userPermissions: getPermissions(state),
}))(AclGuard);
