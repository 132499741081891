import PromiseActionType from '../../promiseActionType';

export const GET_ALL_SDK_PERMISSIONS = new PromiseActionType('GET_ALL_SDK_PERMISSIONS');
export const CREATE_SDK_PERMISSION = new PromiseActionType('CREATE_SDK_PERMISSION');
export const VERIFY_WEBHOOK_URL = new PromiseActionType('VERIFY_WEBHOOK_URL');
export const UPDATE_SDK_PERMISSION = new PromiseActionType('UPDATE_SDK_PERMISSION');
export const BACKOFFICE_GET_SDK_PERMISSION = new PromiseActionType('BACKOFFICE_GET_SDK_PERMISSION');
export const GET_ALL_SDK_THEMES = new PromiseActionType('GET_ALL_SDK_THEMES');
export const CREATE_SDK_THEME = new PromiseActionType('CREATE_SDK_THEME');
export const UPDATE_SDK_THEME = new PromiseActionType('UPDATE_SDK_THEME');
export const GET_SDK_THEME = new PromiseActionType('GET_SDK_THEME');

export function getAllSdkPermissions() {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: GET_ALL_SDK_PERMISSIONS.SOURCE,
    payload: {
      promise: sdkApi.getAllSdkPermissions().then(res => res.data),
    },
  });
}

export function createSdkPermission(sdkPermission) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: CREATE_SDK_PERMISSION.SOURCE,
    payload: {
      promise: sdkApi.createSdkPermission(sdkPermission).then(res => res.data),
    },
    meta: {
      tracker: CREATE_SDK_PERMISSION.SOURCE,
    },
  });
}
export function verifyWebhookUrl(webhookUrl, verificationToken) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: VERIFY_WEBHOOK_URL.SOURCE,
    payload: {
      promise: sdkApi.verifyWebhookUrl(webhookUrl, verificationToken).then(res => res.data),
    },
    meta: {
      tracker: VERIFY_WEBHOOK_URL.SOURCE,
    },
  });
}

export function updateSdkPermission(sdkPermission) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: UPDATE_SDK_PERMISSION.SOURCE,
    payload: {
      promise: sdkApi.updateSdkPermission(sdkPermission).then(res => res.data),
    },
    meta: {
      tracker: UPDATE_SDK_PERMISSION.SOURCE,
    },
  });
}

export function getAllSdkThemes() {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: GET_ALL_SDK_THEMES.SOURCE,
    payload: {
      promise: sdkApi.getAllSdkThemes().then(res => res.data),
    },
  });
}

export function createSdkTheme(sdkTheme) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: CREATE_SDK_THEME.SOURCE,
    payload: {
      promise: sdkApi.createSdkTheme(sdkTheme).then(res => res.data),
    },
    meta: {
      tracker: CREATE_SDK_THEME.SOURCE,
    },
  });
}

export function updateSdkTheme(sdkTheme) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: UPDATE_SDK_THEME.SOURCE,
    payload: {
      promise: sdkApi.updateSdkTheme(sdkTheme).then(res => res.data),
    },
    meta: {
      tracker: UPDATE_SDK_THEME.SOURCE,
    },
  });
}

export function getSdkTheme(apiKey) {
  return ({ bkmdApi: { sdkApi } }) => ({
    type: GET_SDK_THEME.SOURCE,
    payload: {
      promise: sdkApi.getSdkTheme(apiKey).then(res => res.data),
    },
  });
}
