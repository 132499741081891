/**
 * Created by noahguld on 25/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import Action from '../../components/action';
import CleanTagList from '../../../../../components/form/cleanTagList';
import { updateTags, UPDATE_TAGS } from '../../../../../store/backoffice/users/actions';
import * as ValidUserTags from '../../../../../model/enum/userTags';

@autobind
class UpdateTagAction extends React.Component {
  static propTypes = {
    tags: PropTypes.array,
    updateTags: PropTypes.func.isRequired,
    userId: PropTypes.number,
    onChanged: PropTypes.func,
  };

  static defaultProps = {
    userId: null,
    onChanged: null,
    tags: [],
  };

  constructor(props) {
    super(props);
    this.formatedUserTagSuggestions = _.chain(ValidUserTags)
      .values()
      .map(tag => ({ value: tag, label: tag }))
      .value();
    this.state = {
      tags: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tags: nextProps.tags.map(tag => ({ value: tag, label: tag })),
    });
  }

  onTagsChanged(tags) {
    this.setState({ tags });
  }

  onSubmit() {
    this.props.updateTags(this.props.userId, this.state.tags.map(tag => tag.value));
    if (this.props.onChanged) {
      this.props.onChanged();
    }
    return true;
  }

  render() {
    return (
      <Action
        name="updateTag"
        title="Update Tag"
        actionTrackerId={UPDATE_TAGS.SOURCE}
        onSubmit={this.onSubmit}
      >
        <CleanTagList
          value={this.state.tags}
          suggestions={this.formatedUserTagSuggestions}
          onChange={this.onTagsChanged}
        />
      </Action>
    );
  }
}

export default connect(
  null,
  { updateTags },
)(UpdateTagAction);
