/**
 * Created by chenrozenes on 09/04/2017.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import {
  GET_ALL_MEMBERS,
  GET_MEMBER_BY_ID,
  GET_ALL_RAW_MEMBERS,
  GET_FULL_MEMBER,
  ELIGIBILITY_REQUEST,
  SEND_BULK_ELIGIBILITY_REQUEST,
  ATTACH_PAST_ACTIVITY,
  CLEAN_PAST_ACTIVITY_STATE,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  permissions: [],
  raw: {
    list: {
      count: 0,
      data: [],
    },
  },
  full: {},
  bulkEligibilityResponse: {},
  attachPastActivity: {},
};

export default function reducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_ALL_MEMBERS.START:
      return state.set('list', initialState.list);
    case GET_ALL_MEMBERS.SUCCESS:
      return state.set('list', action.payload);
    case GET_MEMBER_BY_ID.SUCCESS: {
      const idOfMember = action.payload.id;
      if (!_.find(state.list.data, ['id', idOfMember])) {
        const newList = [action.payload];
        return state.merge({ list: { data: newList } }, { deep: true });
      }
      return state;
    }
    case GET_ALL_RAW_MEMBERS.SUCCESS: {
      const list = action.payload;
      return state.setIn(['raw', 'list'], list);
    }
    case GET_FULL_MEMBER.START: {
      return state.set('full', initialState.full);
    }
    case GET_FULL_MEMBER.SUCCESS: {
      return state.set('full', action.payload);
    }
    case ELIGIBILITY_REQUEST.SUCCESS: {
      return state.set('eligibility', action.payload);
    }
    case SEND_BULK_ELIGIBILITY_REQUEST.SUCCESS: {
      return state.set('bulkEligibilityResponse', action.payload);
    }
    case ATTACH_PAST_ACTIVITY.START: {
      return state.set('attachPastActivity', initialState.attachPastActivity);
    }
    case ATTACH_PAST_ACTIVITY.SUCCESS: {
      return state.set('attachPastActivity', action.payload);
    }
    case CLEAN_PAST_ACTIVITY_STATE.SUCCESS: {
      return state.set('attachPastActivity', initialState.attachPastActivity);
    }
  }

  return state;
}
