export default {
  label: 'Walgreens adapter',
  schema: {
    properties: {
      example__username: {
        title: 'Example - change me',
      },
    },
  },
};
