/**
 * Created by chenrozenes on 01/05/2017.
 */
import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanSelect,
  ReduxFormCleanSwitch,
  ReduxFormCleanRadioGroup,
} from '../../../../../../components/form/reduxForm/components';

export const name = 'backoffice-clinicManagement-add-permission';

export const fields = {
  promoteOnly: {
    name: 'promoteOnly',
    component: ReduxFormCleanSwitch,
  },
  permission: {
    name: 'permission',
    component: ReduxFormCleanSelect,
  },
  resourceType: {
    name: 'resourceType',
    component: ReduxFormCleanSelect,
  },
  resourceId: {
    name: 'resourceId',
    component: ReduxFormCleanInput,
    readOnly: true,
  },
  userId: {
    name: 'userId',
    component: ReduxFormCleanInput,
  },
  isGroup: {
    name: 'isGroup',
    component: ReduxFormCleanRadioGroup,
    options: [{ value: false, label: 'User ID' }, { value: true, label: 'Group Id' }],
  },
};

export const schema = {
  promoteOnly: Joi.boolean(),
  permission: Joi.number().required(),
  resourceType: Joi.string().required(),
  userId: Joi.string().required(),
  resourceId: Joi.string(),
};
