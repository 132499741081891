/**
 * created by moran on 10/31/17
 */
import PromiseActionType from '../../promiseActionType';

export const GET_ALL_PROVIDER_PROFILES = new PromiseActionType('GET_ALL_PROVIDER_PROFILES');
export const GET_PROVIDER_BY_ID = new PromiseActionType('GET_PROVIDER_BY_ID');

export function getAllProviderProfiles(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { providerProfileApi } }) => ({
    type: GET_ALL_PROVIDER_PROFILES.SOURCE,
    payload: {
      promise: providerProfileApi.getAllProviderProfiles(skip, limit, search, sort, sortAscending)
        .then(res => res.data)
    }
  });
}

export function getProviderById(id) {
  return ({ bkmdApi: { providerProfileApi } }) => ({
    type: GET_PROVIDER_BY_ID.SOURCE,
    payload: {
      promise: providerProfileApi.getById(id)
        .then(res => res.data)
    }
  });
}
