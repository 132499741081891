import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import { reduxForm } from 'redux-form';
import { createValidator } from '../../../../utils/joiValidator';
import FieldGenerator from '../components/entityForm/fieldGenerator';
import * as Definition from './attachMemberPastActivityFormDefs';

@autobind
class AttachMemberPastActivityForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="margin-bottom-30">
          Please provide old public member id (generated by Vim)
        </div>
        <FieldGenerator
          entity={Definition.fields}
          fieldsDef={Definition.fields}
          inputClassName="no-margin"
        />
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: Definition.name,
    validate: createValidator(Joi.object().keys(Definition.schema)),
  }),
)(AttachMemberPastActivityForm);
