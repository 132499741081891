import React from 'react';
import NamespaceList from './namespacesList';
import NamespaceDetails from './namespacesList/namespaceDetails';
import AppointmentList from './appointmentsList';
import AppointmentDetails from './appointmentsList/appointmentDetails';
import PatientsList from './patientsList';
import PatientDetails from './patientsList/patientDetails';
import AppointmentRequestsList from './appointmentRequestList';
import AppointmentRequestDetails from './appointmentRequestList/appointmentRequestDetails';
import AppointmentActions from './appointmentActionsList';
import AppointmentActionDetails from './appointmentActionsList/appointmentActionDetails';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import EhrUser from './ehrUser';

import { BACK_OFFICE_PHI_ACCESS } from '../../../../model/enum/aclResourceType';

import './index.less';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'namespaceManager',
  name: 'Namespace manager',
  component: divComponent,

  indexRoute: {
    name: 'Namespaces list',
    component: NamespaceList,
  },

  childRoutes: [
    {
      path: ':namespaceName',
      name: 'Namespace details',
      indexRoute: {
        name: 'Namespace details',
        component: NamespaceDetails.default || NamespaceDetails,
      },
      childRoutes: [
        {
          path: 'appointments',
          name: 'Appointments list',
          indexRoute: {
            name: 'Appointments list',
            component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
              AppointmentList.default || AppointmentList,
            ),
          },
          childRoutes: [
            {
              path: ':id',
              name: 'Appointment details',
              component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
                AppointmentDetails.default || AppointmentDetails,
              ),
            },
          ],
        },
        {
          path: 'unlockEhrUser',
          name: 'Unlock EHR User',
          indexRoute: {
            name: 'Unlock EHR User',
            component: EhrUser.default || EhrUser,
          },
        },
        {
          path: 'patients',
          name: 'Patients list',
          indexRoute: {
            name: 'Patients list',
            component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
              PatientsList.default || PatientsList,
            ),
          },
          childRoutes: [
            {
              path: ':id',
              name: 'Patient details',
              component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
                PatientDetails.default || PatientDetails,
              ),
            },
          ],
        },
        {
          path: 'appointmentRequests',
          name: 'Appointment Requests list',
          indexRoute: {
            name: 'Appointment Requests list',
            component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
              AppointmentRequestsList.default || AppointmentRequestsList,
            ),
          },
          childRoutes: [
            {
              path: ':id',
              name: 'Appointment Requests details',
              component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
                AppointmentRequestDetails.default || AppointmentRequestDetails,
              ),
            },
          ],
        },
        {
          path: 'appointmentActions',
          name: 'Appointment Actions list',
          indexRoute: {
            name: 'Appointments actions list',
            component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
              AppointmentActions.default || AppointmentActions,
            ),
          },
          childRoutes: [
            {
              path: ':id',
              name: 'Appointment Action details',
              component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(
                AppointmentActionDetails.default || AppointmentActionDetails,
              ),
            },
          ],
        },
      ],
    },
  ],
};
