import gql from 'graphql-tag';
import { pickBy, identity, get } from 'lodash';
import { namespaceGraphqlFetcher } from '../graphql/fetcher';
import { fullAppointmentFragment } from '../graphql/fragments';

export class AppointmentsApi {
  constructor(namespace) {
    this.namespace = namespace;
    this.service = 'appointments';
    this.uri = `${this.namespace}/${this.service}/graphql`;
  }

  async getAllAppointments(input = {}) {
    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        query getAllAppointments($input: GetAllAppointmentsInput!) {
          getAllAppointments(input: $input) {
            results {
              id
              status
              npi
              startTime
              endTime
              patientId
            }
            count
          }
        }
      `,
      variables: { input },
      context: {
        uri: this.uri,
      },
    });

    return {
      results: get(result, 'data.getAllAppointments.results', []),
      count: get(result, 'data.getAllAppointments.count', 0),
    };
  }

  async getFullAppointment({ id, npi }) {
    const where = pickBy({ id, npi }, identity);

    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        ${fullAppointmentFragment}

        query getFullAppointment($input: AppointmentInput!) {
          getAppointment(input: $input) {
            ...fullAppointment
          }
        }
      `,
      variables: {
        input: where,
      },
      context: {
        uri: this.uri,
      },
    });

    return get(result, 'data.getAppointment[0]', {});
  }
}
