/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import AlertsContainer from '../../../../../../storybook/src/components/molecules/AlertsContainer/AlertsContainer';
import { withInfoAlert, withErrorAlert } from './investigationUtils/alerts';
import steps from './index';

const enhanced = compose(
  withInfoAlert(({ providerCSI: { healthSystemData } }) => (
    <span>
      Health system integration type: <strong>{get(healthSystemData, 'integrationType')}</strong>
    </span>
  )),
  withInfoAlert(({ providerCSI: { healthSystemData } }) => (
    <span>
      Namespace handles its own availability?{' '}
      <strong>{get(healthSystemData, 'shouldGetAvailabilityFromEhr') ? 'True' : 'False'}</strong>
    </span>
  )),
  withErrorAlert(({ providerCSI: { healthSystemData } }) => {
    if (isEmpty(get(healthSystemData, 'integrationType')))
      return (
        <span>
          Health system does not have an <strong>integration type</strong>
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { clinicMatchingData } }) => {
    if (isEmpty(get(clinicMatchingData, 'foundClinic.ehrLocationId')))
      return (
        <span>
          Clinic does not have <strong>EHR location id</strong>
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { providerCalendarData } }) => {
    if (isEmpty(get(providerCalendarData, 'providerResource.ehrProviderId')))
      return (
        <span>
          Provider resource is missing <strong>EHR Provider ID!</strong>
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { providerCalendarData } }) => {
    if (isEmpty(get(providerCalendarData, 'appointmentTypes')))
      return (
        <span>
          Appointment types <strong>missing</strong> from provider resource!
        </span>
      );
  }),
  withErrorAlert(({ providerCSI: { providerCalendarData } }) =>
    map(
      filter(get(providerCalendarData, 'appointmentTypes'), apptType =>
        isEmpty(apptType.ehrAppointmentType),
      ),
      apptType => (
        <span>
          Appointment type <strong>{apptType.key}</strong> does not have related{' '}
          <strong>EHR appointment type</strong>
        </span>
      ),
    ),
  ),
)(AlertsContainer);

export default {
  component: enhanced,
  transitions: {
    NEXT: steps.INVESTIGATION_PICKER,
    PREV: steps.INVESTIGATION_PICKER,
  },
  mapFlowDataToProps: ({ providerCSI }) => ({ providerCSI }),
  mapPayloadToFlowData: () => ({}),
};
