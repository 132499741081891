import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../../components/action';
import Api from '../../../../../../api';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import NpiTypeahead from '../../../../../../components/npi/npiTypeaheadWrapper';
import { getProvidersSearch } from '../../../../../../store/backoffice/providers/selectors';
import LocationSelectByNpi from '../../../../../../components/npi/stringifiedLocationSelectByNpi';
import { getDomain } from '../../../../../../utils/domain/storage';

@autobind
class CreateAllowedProvider extends React.PureComponent {
  static propTypes = {
    createAllowedProvider: PropTypes.func.isRequired,
    searchResultProviders: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { npi: null, locationId: null, isAllowed: true };
  }

  onProviderNpiChanged(npi) {
    this.setState({ npi });
  }

  onLocationIdChanged({ id }) {
    this.setState({ locationId: id });
  }

  render() {
    const { searchResultProviders } = this.props;
    const provider = _.find(searchResultProviders, { npi: this.state.npi });
    const locations = _.get(provider, 'locations', []);

    return (
      <Action
        name="Add Provider"
        title="Add Provider"
        submitDisabled={!this.state.npi || !this.state.locationId}
        onSubmit={() => this.props.createAllowedProvider(this.state)}
        wrapClassName="create-allowed-provider-btn"
        buttonClassName="btn btn-big pull-right btn-round-plus action-button"
      >
        <div className="row">
          <div className="col-xs-12">
            <NpiTypeahead onChange={this.onProviderNpiChanged} />
          </div>
          <div className="col-xs-12">
            <LocationSelectByNpi
              label="Target location Id"
              locations={locations}
              npi={this.state.npi}
              value={this.state.locationId}
              onChange={this.onLocationIdChanged}
            />
          </div>
        </div>
      </Action>
    );
  }
}

const CreateAllowedProviderAction = compose(
  connect(state => ({
    searchResultProviders: getProvidersSearch(state),
  })),
  withFetchers({
    createAllowedProvider: {
      handler: () => data =>
        Api.appointmentRequestsApi.createAllowedProvider({ ...data, domain: getDomain() }),
      onSuccess: ({ onSuccess }) => onSuccess(),
    },
  }),
)(CreateAllowedProvider);

CreateAllowedProviderAction.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CreateAllowedProviderAction;
