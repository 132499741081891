import fetch from 'isomorphic-fetch';
import { from, execute, makePromise } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import headerConsts from '../../../../utils/headers';
import BkmdApi from '../../../../api/bkmdApi';

class SchedulingGraphqlFetcher {
  constructor() {
    this.link = from([
      setContext(() => {
        const headers = {
          [headerConsts.recaptcha]: BkmdApi._headers().recaptcha,
          [headerConsts.authorization]: BkmdApi._headers().authorization,
        };
        return { headers };
      }),
      new HttpLink({
        uri: `/api/v1/scheduling/graphql`,
        fetch,
      }),
    ]);
  }

  async queryOrMutate(graphqlRequest, context = {}) {
    const result = await makePromise(execute(this.link, { ...graphqlRequest, context })).catch(
      error => {
        throw new Error(`Failed to execute graphql api request '${graphqlRequest.operationName}', error:${error}`);
      },
    );

    if (result.errors && result.errors.length > 0) {
      throw result;
    }
    return result;
  }
}

const schedulingGraphqlFetcher = new SchedulingGraphqlFetcher();
export default schedulingGraphqlFetcher;
