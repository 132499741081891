import React from 'react';
import Referrals  from './index'
import ReferralDetails from './referralDetails';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'referrals',
  name: 'Referrals',
  component: divComponent,

  indexRoute: {
    name: 'Referrals',
    component: Referrals,
  },

  childRoutes: [
    {
      path: ':id',
      name: 'Referral details',
      indexRoute: {
        name: 'Referral details',
        component: ReferralDetails.default || ReferralDetails,
      }
    }
  ]
};
