import _ from 'lodash';
import Joi from 'joi-browser';
import * as ServiceKeyType from '../../../../../../model/enum/serviceKeyType';
import {
  ReduxFormCleanSwitch,
  ReduxFormCleanSelect,
  ReduxFormCleanInput,
  ReduxFormCleanChipInput,
} from '../../../../../../components/form/reduxForm/components';
import { enumSchema } from '../../../../../../utils/commonSchema';

export const name = 'backoffice-add-appointment-type';

export const fields = medicalCodes => ({
  duration: {
    name: 'duration',
  },
  ehrAppointmentType: {
    name: 'ehrAppointmentType',
    label: 'EHR appointment type',
    component: ReduxFormCleanInput,
  },
  interchangeableAppointmentTypes: {
    label: 'Interchangeable EHR appointment types',
    id: 'interchangeableAppointmentTypes',
    name: 'interchangeableAppointmentTypes',
    component: ReduxFormCleanChipInput,
    readOnly: false,
  },
  type: {
    options: _.map(ServiceKeyType, (value, label) => ({ value, label })),
    name: 'type',
    component: ReduxFormCleanSelect,
  },
  key: {
    name: 'key',
    options: _.map(medicalCodes, value => ({
      value: value.key,
      label: value.commonName,
    })),
    component: ReduxFormCleanSelect,
  },
  minTimeBeforeBooking: {
    label: 'Minimum time before booking (in minutes)',
    name: 'minTimeBeforeBooking',
  },
  isTelemedicine: {
    name: 'isTelemedicine',
    label: 'isTelemedicine',
    id: 'isTelemedicine',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
});

export const schema = {
  key: Joi.string().required(),
  type: enumSchema(ServiceKeyType).required(),
  duration: Joi.number().required(),
  ehrAppointmentType: Joi.string()
    .allow('', null)
    .optional(),
  interchangeableAppointmentTypes: Joi.array().optional(),
  minTimeBeforeBooking: Joi.number().optional(),
  isTelemedicine: Joi.boolean().default(false),
};
