import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { Field } from 'formik';
import withFormikWrapper from 'Components/form/formik/withFormikWrapper';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import classNames from 'classnames';

const displayShareButtonFieldName = 'displayShareButton';
const displayBookButtonFieldName = 'displayBookButton';
const communicationDisplayNameFieldName = 'communicationDisplayName';

export const referralGuidanceFields = [displayShareButtonFieldName, displayBookButtonFieldName];

function ReferralGuidanceConfigurationView() {
  return (
    <div>
      <h3 className="config-section-title">Referral Guidance configuration</h3>
      <Field
        id="search-conf-display-share-btn"
        name={displayShareButtonFieldName}
        label="Display share button"
        className="margin-top-10"
        component={CleanFormikCheckbox}
      />
      <Field
        id="search-conf-display-book-btn"
        name={displayBookButtonFieldName}
        label="Display book button"
        className="margin-top-10"
        component={CleanFormikCheckbox}
      />
      <Field
        id="communication-display-name"
        name={communicationDisplayNameFieldName}
        label="Communication display name"
        className={classNames('margin-top-30', 'communication-display-name-field')}
        component={CleanFormikInput}
      />
    </div>
  );
}

ReferralGuidanceConfigurationView.propTypes = {
  value: PropTypes.object.isRequired,
};

const ReferralGuidanceConfiguration = compose(
  withFormikWrapper(),
  setPropTypes({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      displayShareButton: PropTypes.bool,
      displayBookButton: PropTypes.bool,
    }),
  }),
)(ReferralGuidanceConfigurationView);

export default ReferralGuidanceConfiguration;
