import gql from 'graphql-tag';
import { namespaceGraphqlFetcher } from '../graphql/fetcher';
import { get } from 'lodash';

export class PuppeteerVsAdapterApi {
  constructor(namespace) {
    this.namespace = namespace;
    this.service = 'puppeteer-vs-adapter';
    this.uri = `${this.namespace}/${this.service}/graphql`;
  }

  async unlockEhrUser(input = {}) {
    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        query unlockEhrUser {
          unlockEhrUser {
            success
          }
        }
      `,
      variables: { input },
      context: {
        uri: this.uri,
      },
    });

    return {
      results: get(result, 'data.unlockEhrUser', {}),
    };
  }
}
