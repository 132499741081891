/**
 * Created by ofirHartuv on 30/10/17.
 */
import _ from 'lodash';
import Joi from 'joi-browser';
import { PhoneNumberSchema, EmailSchema } from '../../../../../utils/commonSchema';
import CleanPhoneNumber from '../../../../../components/form/cleanPhoneNumber';
import BkmdDomains  from '../../../../../model/enum/bkmdDomain';
import BkmdPayers  from '../../../../../model/enum/bkmdPayers';
import { ClinicSource } from '../../../../../model/enum/clinicSource';
import {
  ReduxFormCleanPhoneNumber,
  ReduxFormStateSelect,
  ReduxFormCleanGeoPoint,
  ReduxFormItemList,
  ReduxFormCleanSelect,
  ReduxFormCleanSwitch,
  ReduxFormCleanInput,
  ReduxFormCleanTimezoneSelect,
  ReduxFormCleanChipInput,
} from '../../../../../components/form/reduxForm/components';
import BookMdUtil from '../../../../../utils/util';

export const name = 'backoffice-clinc';

export const fields = healthSystems => ({
  id: {
    name: 'id',
    readOnly: true,
  },
  isReceivingOnlineReferral: {
    name: 'isReceivingOnlineReferral',
    label: 'Is Receiving Online Referral',
    id: 'isReceivingOnlineReferral',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
  isDisabled: {
    name: 'isDisabled',
    label: 'Is Disabled',
    id: 'isDisabled',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  city: {
    name: 'city',
  },
  state: {
    name: 'state',
    component: ReduxFormStateSelect,
  },
  phone: {
    name: 'phone',
    component: ReduxFormCleanPhoneNumber,
  },

  fax: {
    name: 'fax',
    component: ReduxFormCleanPhoneNumber,
  },
  payers: {
    name: 'payers',
    component: ReduxFormCleanSelect,
    options: BkmdPayers.toSelectOptions(),
    clearable: true,
    multi: true,
  },
  additionalFaxes: {
    name: 'additionalFaxes',
    component: ReduxFormItemList,
    RowType: CleanPhoneNumber,
  },
  email: {
    name: 'email',
    label: 'Primary Email',
    component: ReduxFormCleanInput,
  },
  secondaryEmails: {
    id: 'secondaryEmails',
    name: 'secondaryEmails',
    label: 'Secondary Emails List',
    component: ReduxFormCleanChipInput,
    readOnly: false,
  },
  geo: {
    name: 'geo',
    component: ReduxFormCleanGeoPoint,
  },
  healthSystemId: {
    name: 'healthSystemId',
    label: 'Health System',
    options: _.map(healthSystems, value => ({
      value: value.id,
      label: value.name,
    })),
    component: ReduxFormCleanSelect,
  },
  ehrLocationId: {
    name: 'ehrLocationId',
    label: 'Ehr location id',
    component: ReduxFormCleanInput,
  },
  poolId: {
    name: 'poolId',
    label: 'Pool ID',
    component: ReduxFormCleanInput,
  },
  supportsFacilityBooking: {
    name: 'supportsFacilityBooking',
    label:
      "Does clinic support facility booking (Can be turned on only when clinic NPI is set. Creates a clinic resource with the clinic's NPI and a calendar)",
    id: 'supportsFacilityBooking',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
  timezone: {
    name: 'timezone',
    component: ReduxFormCleanTimezoneSelect,
  },
  isLegalSignatureRequired: {
    id: 'isLegalSignatureRequired',
    name: 'isLegalSignatureRequired',
    label:
      'Is Legal Documents Signature Required (turning this on means the clinic shall check \'Terms of Service\' checkbox before accessing their appointment requests)',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
  source: {
    name: 'source',
    label: 'Marketing source (if onboarded via campaign)',
    component: ReduxFormCleanSelect,
    options: BookMdUtil.toSelectOptions(ClinicSource),
  },
  facilityResource: {
    id: 'facilityResource',
    label: '',
    hidden: true,
  },
});

export const schema = {
  id: Joi.number().optional(),
  city: Joi.string().required(),
  state: Joi.string().required(),
  addressLine1: Joi.string().required(),
  addressLine2: Joi.string()
    .allow('', null)
    .optional(),
  zip: Joi.string().required(),
  npi: Joi.string()
    .allow('', null)
    .optional(),
  payers: Joi.array()
    .items(Joi.string())
    .allow([], null)
    .optional(),
  officeName: Joi.string().required(),
  phone: PhoneNumberSchema.allow('', null).optional(),
  fax: PhoneNumberSchema.allow('', null).optional(),
  additionalFaxes: Joi.array()
    .items(PhoneNumberSchema)
    .allow([], null)
    .optional(),
  email: EmailSchema.allow('', null).optional(),
  secondaryEmails: Joi.array()
    .items(EmailSchema.allow('', null))
    .allow([], null)
    .optional(),
  ehrLocationId: Joi.string()
    .allow('', null)
    .optional(),
  geo: Joi.object()
    .keys({
      latitude: Joi.number().required(),
      longitude: Joi.number().required(),
    })
    .allow('', null)
    .optional(),
  timezone: Joi.string()
    .allow('', null)
    .required(),
  healthSystemId: Joi.string().optional(),
  isReceivingOnlineReferral: Joi.boolean().optional(),
  supportsFacilityBooking: Joi.boolean().optional(),
  isDisabled: Joi.boolean().optional(),
  isLegalSignatureRequired: Joi.boolean().optional(),
  source: Joi.string().optional(),
};
