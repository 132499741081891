/**
 * Created by anna on 26/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../components/action';
import {
  updateToDeclinedStatus,
  UPDATE_REVIEW_STATUS_DECLINED,
} from '../../../../../store/backoffice/reviews/actions';
import { DECLINED } from '../../../../../model/enum/appointmentReviewStatus';

@autobind
class DeclineAction extends React.PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired,
    updateStatus: PropTypes.func.isRequired,
  };

  callAction() {
    this.props.updateStatus(this.props.review.token, DECLINED);
    return true;
  }

  render() {
    return (
      <Action
        name="changeStatusToDecline"
        title="Decline"
        label="Decline"
        actionTrackerId={UPDATE_REVIEW_STATUS_DECLINED.SOURCE}
        onSubmit={this.callAction}
      >
        Declining the appointment review will automatically hide it from the provider profile, Are
        you sure?
      </Action>
    );
  }
}

export default connect(
  null,
  { updateStatus: updateToDeclinedStatus },
)(DeclineAction);
