import uuid from 'uuid';
import bcrypt from 'bcryptjs';

export const generateApiKeyInClient = () => ({ clientApiKey: uuid(), serverApiKey: uuid() });

export const generateSecretApiKeyInClient = () => {
  const secretApiKey = uuid();

  return { secretApiKey, secret: bcrypt.hashSync(secretApiKey, 10) };
};
