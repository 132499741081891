import {
  ReduxFormCleanDateInput,
  ReduxFormCleanInput,
  ReduxFormCleanPhoneNumber,
  ReduxFormGenderSelect,
} from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-domain-member-page';

export const fields = {
  address: {
    name: 'address',
    component: ReduxFormCleanInput,
  },
  coverageEffectiveDate: {
    name: 'coverageEffectiveDate',
    component: ReduxFormCleanDateInput,
  },
  coverageTerminationDate: {
    name: 'coverageTerminationDate',
    component: ReduxFormCleanDateInput,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    component: ReduxFormCleanDateInput,
  },
  email: {
    name: 'email',
    component: ReduxFormCleanInput,
  },
  firstName: {
    name: 'firstName',
    component: ReduxFormCleanInput,
  },
  gender: {
    name: 'gender',
    component: ReduxFormGenderSelect,
  },
  groupId: {
    name: 'groupId',
    component: ReduxFormCleanInput,
  },
  id: {
    name: 'id',
    component: ReduxFormCleanInput,
  },
  insurer: {
    name: 'insurer',
    component: ReduxFormCleanInput,
  },
  isActivated: {
    name: 'isActivated',
    component: ReduxFormCleanInput,
  },
  isRegistered: {
    name: 'isRegistered',
    component: ReduxFormCleanInput,
  },
  confirmedMemberId: {
    name: 'confirmedMemberId',
    component: ReduxFormCleanInput,
  },
  lastName: {
    name: 'lastName',
    component: ReduxFormCleanInput,
  },
  memberContext: {
    name: 'memberContext',
    component: ReduxFormCleanInput,
  },
  memberId: {
    name: 'memberId',
    component: ReduxFormCleanInput,
  },
  memberIdPrefix: {
    name: 'memberIdPrefix',
    component: ReduxFormCleanInput,
  },
  middleName: {
    name: 'middleName',
    component: ReduxFormCleanInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  plan: {
    name: 'plan.description',
    component: ReduxFormCleanInput,
  },
  relation: {
    name: 'relation',
    component: ReduxFormCleanInput,
  },
  subscriberId: {
    name: 'subscriberId',
    component: ReduxFormCleanInput,
  },
};
