/**
 * Created by guyavarham on 24/12/2017.
 */

import _ from 'lodash';

/*
 * Using regex, find variables families in a template's content
 * A variable is marked between:  *|variableFamily__varName|*
*/
const variablesFinderRegex = /\*\|.*?\|\*/g;
const familySelectionRegex = /^\*\|(.*?)__.*?/;
export const findDataFamiliesInTemplate = template => {
  if (!_.get(template, 'content')) return [];
  const families = template.dataFamilies;
  let moreFamilies = template.content.match(variablesFinderRegex);
  moreFamilies = _.map(moreFamilies, family => _.last(family.match(familySelectionRegex)));
  return _.uniqBy(_.concat(families, moreFamilies));
};
export const getParamsFromTemplate = template => {
  let params = template.content.match(variablesFinderRegex) || [];
  params = params.map(param => param.substr(2).slice(0, -2));
  return _.uniqBy(params);
};
export const listTemplatesData = state => state.backoffice.communication.templates.data;
export const listTemplatesCount = state => state.backoffice.communication.templates.count;
export const getCurrTemplate = state => state.backoffice.communication.currTemplate;
export const getEnrichedCurrTemplate = state => {
  const currTemplate = getCurrTemplate(state);
  return _.assign({}, currTemplate, { dataFamilies: findDataFamiliesInTemplate(currTemplate) });
};
