import { compose } from 'redux';
import { withRouter } from 'react-router';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withHandlers, withPropsOnChange } from 'recompose';
import { defineMessages } from 'react-intl';
import { object, string } from 'yup';
import { Field, Form } from 'formik';
import { CleanFormikStatefulTypeahead } from 'Components/form/formik/cleanFormikStatefulTypeahead';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { withTranslatedMessages } from 'Utils/withTranslatedMessages';
import { BACK_OFFICE_CLINICS } from 'Model/enum/aclResourceType';
import { WRITE } from 'Model/enum/permissionLevel';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import {
  createToggleState,
  createToggleStatePropTypes,
} from 'Components/recompose/toggleStateComponent';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../api';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../store/notification/injectNotification';
import { healthSystemType as type } from '../permissions/permissionMapper';
import AclGuard from '../../../../../store/auth/aclGuard';
import AttachClinicsModal from './actions/attachClinics';

const messages = defineMessages({
  nameText: {
    defaultMessage: 'Health system name',
    id: 'clinics.healthSystem.nameText',
  },
  integrationTypeText: {
    defaultMessage: 'Integration Type',
    id: 'clinics.healthSystem.integrationTypeText',
  },
  namespaceText: {
    defaultMessage: 'Namespace',
    id: 'clinics.healthSystem.namespaceText',
  },
  partnersText: {
    defaultMessage: 'Health Systems Partners',
    id: 'clinics.healthSystem.partnersText',
  },
  submitText: {
    defaultMessage: 'Submit',
    id: 'clinics.healthSystem.submitText',
  },
  successText: {
    defaultMessage: 'Updated the health system succesfully!',
    id: 'clinics.healthSystem.successText',
  },
  errorText: {
    defaultMessage: 'Error occurred while updating the health system',
    id: 'clinics.healthSystem.errorText',
  },
});

const schema = object().shape({
  name: string()
    .required('Name is required')
    .label('Name'),
  ehrIntegrationId: string()
    .label('EHR integration id')
    .nullable(true),
  namespace: string()
    .required('Namespace is required')
    .label('Namespace')
});

const renderActions = ({
  goToClinics,
  attachClinicModalShow,
  goToHealthSystemPermissions,
  goToHealthSystemSearchPreferences,
  goToHealthSystemPlanMapping,
}) => (
  <div>
    <AclGuard type={BACK_OFFICE_CLINICS} permission={WRITE}>
      <div className="action-group">
        <div className="backoffice-action backoffice-extra-padding">
          <button
            onClick={goToClinics}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Clinics
          </button>
          <button
            onClick={attachClinicModalShow}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Attach Clinics
          </button>
          <button
            onClick={goToHealthSystemPermissions}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Permissions
          </button>
          <button
            onClick={goToHealthSystemSearchPreferences}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            search preferences
          </button>
          <button
            onClick={goToHealthSystemPlanMapping}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Plan Mapping
          </button>
        </div>
      </div>
    </AclGuard>
  </div>
);

const HealthSystemForm = ({
  isLoading,
  onSubmit,
  healthSystem,
  goToClinics,
  attachClinicModalShow,
  goToHealthSystemPermissions,
  goToHealthSystemSearchPreferences,
  goToHealthSystemPlanMapping,
  attachClinicModalHide,
  attachClinicModalOn,
  integrationTypeText,
  namespaceText,
  nameText,
  submitText,
  params,
  allNamespaces,
  partners,
  partnersText,
}) => (
  <div>
    {renderActions({
      goToClinics,
      attachClinicModalShow,
      goToHealthSystemPermissions,
      goToHealthSystemSearchPreferences,
      goToHealthSystemPlanMapping,
    })}
    <WrappedFormik
      initialValues={healthSystem.data}
      onSubmit={onSubmit}
      validationSchema={schema}
      render={data => (
        <Form>
          <div className="card margin-top-30">
            <div className="card-body">
              <div className="row">
                <div className="col-xs-12">
                  <Field name="name" component={CleanFormikInput} label={nameText} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="integrationType"
                    placeholder={integrationTypeText}
                    options={allNamespaces.healthSystemIntegrationTypes}
                    component={CleanFormikSelect}
                    hotOptions
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="namespace"
                    placeholder={namespaceText}
                    options={allNamespaces.healthSystemActualNamespaces}
                    component={CleanFormikSelect}
                    hotOptions
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="partners"
                    label={partnersText}
                    placeholderText={partnersText}
                    options={partners}
                    component={CleanFormikStatefulTypeahead}
                    idKey="value"
                    labelKey="label"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    id="isDisabled"
                    name="isDisabled"
                    label="Is disabled"
                    component={CleanFormikCheckbox}
                    hotOptions
                  />
                </div>
              </div>
              <div className="row margin-bottom-30">
                <div className="col-xs-12">
                  <SpinnerButton
                    className="btn btn-big bg-color-brand-button"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!data.isValid || isLoading}
                  >
                    {submitText}
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
    <AttachClinicsModal
      handleClose={attachClinicModalHide}
      isOpen={attachClinicModalOn}
      healthSystemId={params.id}
    />
  </div>
);

renderActions.propTypes = {
  goToClinics: PropTypes.func.isRequired,
  attachClinicModalShow: PropTypes.func.isRequired,
  goToHealthSystemPermissions: PropTypes.func.isRequired,
  goToHealthSystemSearchPreferences: PropTypes.func.isRequired,
  goToHealthSystemPlanMapping: PropTypes.func.isRequired,
};

HealthSystemForm.propTypes = {
  onSubmit: PropTypes.func,
  goToClinics: PropTypes.func.isRequired,
  attachClinicModalShow: PropTypes.func.isRequired,
  goToHealthSystemPermissions: PropTypes.func.isRequired,
  goToHealthSystemSearchPreferences: PropTypes.func.isRequired,
  goToHealthSystemPlanMapping: PropTypes.func.isRequired,
  partners: PropTypes.arrayOf(PropTypes.object),
  partnersText: PropTypes.string,
  ...createToggleStatePropTypes('attachClinicModal'),
};

HealthSystemForm.defaultProps = {
  onSubmit: _.noop,
  isLoading: false,
  partners: [],
  partnersText: '',
  namespaceText: '',
};

export default compose(
  withRouter,
  injectNotification,
  withTranslatedMessages(messages),
  createToggleState('attachClinicModal'),
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: ({ params }) => () => Api.clinicsApi.getHealthSystem(params.id),
      resultPropName: 'healthSystem',
      isReady: ({ healthSystem }) => !_.isEmpty(healthSystem),
    },
    getPartners: {
      handler: ({ params }) => async () => {
        const { data } = await Api.clinicsApi.getAllHealthSystems();
        return data
          .map(hs => ({
            value: hs.id,
            label: hs.name,
          }))
          .filter(hs => hs.value !== +params.id && hs.label !== 'Unassigned');
      },
      resultPropName: 'partners',
      isReady: ({ partners }) => !_.isEmpty(partners),
    },
    getAllNamespaces: {
      handler: () => async () => {
        const namespaces = await Api.namespaceManagerApi.getSingleTenantNamespaces();
        const virtualNamespaces = await Api.namespaceManagerApi.getVirtualNamespaces();

        let allVirtualNamespaces = [];
        Object.values(virtualNamespaces).forEach(domain => {
          allVirtualNamespaces = _.concat(allVirtualNamespaces, domain);
        });

        let multiTenantNamespaces = [];
        Object.keys(virtualNamespaces).forEach(tenant => {
          multiTenantNamespaces = _.concat(multiTenantNamespaces, tenant);
        });

        const formattedNamespaces = namespaces.map(ns =>
          ({ value: ns.name, label: ns.name }),
        );
        const formattedVirtualNamespaces = allVirtualNamespaces.map(virtualNs => ({
          value: virtualNs,
          label: virtualNs,
        }));
        const formattedMultiTenantNamespaces = multiTenantNamespaces.map(multiTenantNs => ({
          value: multiTenantNs,
          label: multiTenantNs,
        }));

        const healthSystemIntegrationTypes = _.concat(formattedNamespaces, formattedVirtualNamespaces);
        const healthSystemActualNamespaces =  _.concat(formattedMultiTenantNamespaces, formattedNamespaces);

        return {
          healthSystemIntegrationTypes,
          healthSystemActualNamespaces,
        }
      },
      resultPropName: 'allNamespaces',
      isReady: ({ allNamespaces }) => !_.isEmpty(allNamespaces),
    },
  }),
  withHandlers({
    attachClinicModalShow: ({ attachClinicModalShow }) => () => {
      attachClinicModalShow();
    },
    goToClinics: ({ router, params }) => () => {
      router.push(`/secure/healthSystems/${params.id}/clinics`);
    },
    goToHealthSystemPermissions: ({ router, params }) => () => {
      router.push(`/secure/healthSystems/permissions/${type}/${params.id}`);
    },
    goToHealthSystemSearchPreferences: ({ router, params }) => () => {
      router.push(`/secure/healthSystems/searchPreferences/${type}/${params.id}`);
    },
    goToHealthSystemPlanMapping: ({ router, params }) => () => {
      router.push(`/secure/healthSystems/${params.id}/planMapping`);
    },
  }),
  withFetchers({
    onSubmit: {
      handler: () => data => {
        const newData = {
          ...data,
          integrationType: _.get(data.integrationType, 'value', data.integrationType),
          namespace: _.get(data.namespace, 'value', data.namespace),
          partners: _.map(data.partners, 'value'),
        };

        return Api.clinicsApi.updateHealthSystem(newData);
      },
      onSuccess: ({ notification, successText }) => notification.success('', successText),
      onError: ({ notification, errorText }) => notification.error('', errorText),
    },
  }),
  withPropsOnChange(['onSubmitTracker'], ({ onSubmitTracker }) => ({
    isLoading: _.get(onSubmitTracker, 'inProgress', false),
  })),
)(HealthSystemForm);
