import React from 'react';
import PropTypes from 'prop-types';
import PlanMappingCreateModalButton from './CreatePlanMapping/PlanMappingCreateModalButton';

const PlanMappingActions = ({ onPlanMappingCreated, healthSystemId }) => (
  <div>
    <PlanMappingCreateModalButton
      onPlanMappingCreated={onPlanMappingCreated}
      healthSystemId={healthSystemId}
    />
  </div>
);

PlanMappingActions.propTypes = {
  healthSystemId: PropTypes.number.isRequired,
  onPlanMappingCreated: PropTypes.func.isRequired,
};

export default PlanMappingActions;
