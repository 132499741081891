/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by chenrozenes on 25/04/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);
import React from 'react';
import requireAccess from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PHI_ACCESS } from '../../../../model/enum/aclResourceType';
import CommunicationPage from './communicationPage';
import DataMapping from './dataMapping/dataMappingPage';
import EntityDataMapping from './dataMapping/entityDataMapping';
import HistoryList from './history/historyList';
import HistoryPage from './history/historyPage';
import ChatPage from './chat/chatPage';
import templatesRoutes from './templates/routes';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'communication',
  name: 'communication',
  childRoutes: [
    templatesRoutes,
    {
      path: 'mapping',
      name: 'mapping',
      component: DataMapping.default || DataMapping,
    },
    {
      path: 'mapping/:entity',
      name: 'mappingEntity',
      component: EntityDataMapping.default || EntityDataMapping,
    },
    {
      path: 'history',
      name: 'history',
      component: HistoryList.default || HistoryList,
    },
    {
      path: 'chat',
      name: 'chat',
      component: ChatPage.default || ChatPage,
    },
    {
      path: 'chat/:domainMemberId',
      name: 'chat',
      component: ChatPage.default || ChatPage,
    },
    {
      path: 'history/:historyId',
      name: 'historyRecord',
      component: HistoryPage.default || HistoryPage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAccess({ resourceType: BACK_OFFICE_PHI_ACCESS })(divComponent),

  indexRoute: {
    name: 'communicationPage',
    component: CommunicationPage.default || CommunicationPage,
  },
};
/* eslint-enable import/first, react/jsx-filename-extension */
