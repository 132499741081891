/* eslint-disable import/prefer-default-export */
import { compose, withProps } from 'recompose';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

export const withInfoAlert = infoComp =>
  compose(
    withProps(({ infos, ...props }) => {
      const jsxElement = infoComp(props);

      if (isNil(jsxElement)) return undefined;

      const comps = isArray(jsxElement)
        ? jsxElement.map(element => () => element)
        : [() => jsxElement];
      return {
        infos: [...(infos || []), ...comps],
      };
    }),
  );

export const withWarningAlert = warningComp =>
  compose(
    withProps(({ warnings, ...props }) => {
      const jsxElement = warningComp(props);

      if (isNil(jsxElement)) return undefined;

      const comps = isArray(jsxElement)
        ? jsxElement.map(element => () => element)
        : [() => jsxElement];
      return {
        warnings: [...(warnings || []), ...comps],
      };
    }),
  );

export const withErrorAlert = errorComp =>
  compose(
    withProps(({ errors, ...props }) => {
      const jsxElement = errorComp(props);

      if (isNil(jsxElement)) return undefined;

      const comps = isArray(jsxElement)
        ? jsxElement.map(element => () => element)
        : [() => jsxElement];
      return {
        errors: [...(errors || []), ...comps],
      };
    }),
  );
