import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import Action from '../../components/action';
import {
  name as formName,
  schema as providerVideoScheme,
  fields as providerVideoFields,
} from './providerVideoFormDefs';
import EntityForm from '../../components/entityForm/entityForm';

import {
  updateProviderVideo,
  UPDATE_PROVIDER_VIDEO,
} from '../../../../../store/backoffice/providers/actions';

@autobind
class ProviderVideoAction extends React.PureComponent {
  static propTypes = {
    updateProviderVideo: PropTypes.func.isRequired,
    videoObj: PropTypes.object.isRequired,
    npi: PropTypes.number.isRequired,
  };

  callAction(formData) {
    this.props.updateProviderVideo(this.props.npi, formData);
  }

  render() {
    const { videoObj, ...other } = this.props;
    return (
      <Action
        name="Provider Video"
        title="Update Provider Video"
        actionTrackerId={UPDATE_PROVIDER_VIDEO.SOURCE}
        formName={formName}
        {...other}
      >
        <EntityForm
          name={formName}
          schemaDef={providerVideoScheme}
          fieldsDef={providerVideoFields}
          title="Update Provider Video"
          entity={videoObj}
          showButtons={false}
          onSubmit={this.callAction}
        />
      </Action>
    );
  }
}

export default connect(
  null,
  { updateProviderVideo },
)(ProviderVideoAction);
