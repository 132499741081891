import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import CollapsibleCard from 'Components/ui/collapsibleCard/collapsibleCard';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { FormikDateTimeComponent } from 'Components/form/formik/formikDateTimeComponent';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import SpinnerButton from 'Components/ui/spinner/spinnerButton';

import PatientView from '../../patientsList/patientDetails/forCollapsible/index';

import './index.less';

export default function AppointmentActionDetails({
  appointmentAction,
  updateAppointmentAction,
  checkAvailabilityBeforeResend,
  title,
  submitButtonText,
  availabilityCheckButtonText,
  platformId,
  npi,
  patientId,
  appointmentId,
  appointmentType,
  status,
  ehrLocationId,
  ehrProviderId,
  actionType,
  startTime,
  endTime,
  createdAt,
  updatedAt,
  statusOptions,
}) {
  return (
    <WrappedFormik
      enableReinitialize
      initialValues={appointmentAction}
      render={({ values }) => (
        <Form
          className="namespace-details-card appointment-action-details col-sm-6 col-sm-offset-3 margin-bottom-clean-form"
          method="post"
        >
          <div className="row padding-h-20">
            <div className="row margin-bottom-30">
              <div className="col-xs-6 text-16 margin-top margin-bottom">
                <h4>{title}</h4>
              </div>
              <div className="col-xs-6">
                <SpinnerButton
                  className="btn btn-small bg-color-brand-main margin-top margin-bottom align-spinner-right"
                  type="button"
                  onClick={() => updateAppointmentAction(values)}
                >
                  {submitButtonText}
                </SpinnerButton>
                {!appointmentAction.appointmentId && (
                  <SpinnerButton
                    className="btn btn-small bg-color-brand-main margin-top margin-bottom align-spinner-right"
                    type="button"
                    onClick={checkAvailabilityBeforeResend}
                  >
                    {availabilityCheckButtonText}
                  </SpinnerButton>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <Field name="platformId" label={platformId} component={CleanFormikInput} />
              </div>
              <div className="col-xs-3">
                <Field name="npi" label={npi} component={CleanFormikInput} />
              </div>
              <div className="col-xs-3">
                <Field name="patientId" label={patientId} component={CleanFormikInput} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <Field
                  name="appointmentType"
                  label={appointmentType}
                  component={CleanFormikInput}
                />
              </div>
              <div className="col-xs-6">
                <Field name="actionType" label={actionType} component={CleanFormikInput} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <Field
                  name="status"
                  label={status}
                  component={CleanFormikSelect}
                  options={statusOptions}
                  clearable={false}
                />
              </div>
              <div className="col-xs-6">
                <Field
                  className="number-input-no-arrows"
                  name="appointmentId"
                  label={appointmentId}
                  type="number"
                  component={CleanFormikInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <Field name="ehrLocationId" label={ehrLocationId} component={CleanFormikInput} />
              </div>
              <div className="col-xs-3">
                <Field name="ehrProviderId" label={ehrProviderId} component={CleanFormikInput} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Field
                  name="startTime"
                  label={startTime}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                />
              </div>
              <div className="col-xs-12">
                <Field
                  name="endTime"
                  label={endTime}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                />
              </div>
              <div className="col-xs-12">
                <Field
                  name="createdAt"
                  label={createdAt}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                  disabled
                />
              </div>
              <div className="col-xs-12">
                <Field
                  name="updatedAt"
                  label={updatedAt}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                  disabled
                />
              </div>
            </div>
          </div>
          <CollapsibleCard
            title="Appointment's patient"
            id="backoffice-ns-patient-details-id"
            className="patient-details-card light-blue-title bg-grey-color"
          >
            <PatientView patient={values.patient} />
          </CollapsibleCard>
        </Form>
      )}
    />
  );
}

AppointmentActionDetails.propTypes = {
  appointmentAction: PropTypes.object.isRequired,
};
