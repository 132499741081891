import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { defineMessages } from 'react-intl';
import Formatter from '../../../../../../utils/formatter';
import commonFormats from '../../../../../../utils/formatter/commonFormats';
import CollapsibleCard from '../../../../../../components/ui/collapsibleCard/collapsibleCard';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import { withTranslatedMessages } from '../../../../../../utils/withTranslatedMessages';

const patientMessages = defineMessages({
  ehrPatientTitle: {
    defaultMessage: 'EHR patient details:',
    id: 'appointmentProductForm.ehrPatient.ehrPatientTitle',
  },
  namespacePatientTitle: {
    defaultMessage: 'Namespace patient details:',
    id: 'appointmentProductForm.ehrPatient.namespacePatientTitle',
  },
  goToEhrPatient: {
    defaultMessage: 'Go to EHR patient',
    id: 'appointmentProductForm.member.goToEhrPatient',
  },
  goToNamespacePatient: {
    defaultMessage: 'Go to namespace patient',
    id: 'appointmentProductForm.member.goToNamespacePatient',
  },
  ehrPatientIdLabel: {
    defaultMessage: 'Patient id:',
    id: 'appointmentProductForm.ehrPatient.ehrPatientIdLabel',
  },
  addressLabel: {
    defaultMessage: 'Address:',
    id: 'appointmentProductForm.ehrPatient.addressLabel',
  },
  dateOfBirthLabel: {
    defaultMessage: 'Date of birth:',
    id: 'appointmentProductForm.ehrPatient.dateOfBirthLabel',
  },
  fullNameLabel: {
    defaultMessage: 'Full name:',
    id: 'appointmentProductForm.ehrPatient.fullNameLabel',
  },
  planLabel: {
    defaultMessage: 'Plan:',
    id: 'appointmentProductForm.ehrPatient.planLabel',
  },
  phoneNumberLabel: {
    defaultMessage: 'Phone:',
    id: 'appointmentProductForm.ehrPatient.phoneNumberLabel',
  },
  ehrIdentifierLabel: {
    defaultMessage: 'EHR identifier:',
    id: 'appointmentProductForm.ehrPatient.ehrIdentifierLabel',
  },
  insurerLabel: {
    defaultMessage: 'Insurer:',
    id: 'appointmentProductForm.ehrPatient.insurerLabel',
  },
  sourceLabel: {
    defaultMessage: 'Source:',
    id: 'appointmentProductForm.ehrPatient.sourceLabel',
  },
  statusLabel: {
    defaultMessage: 'Status:',
    id: 'appointmentProductForm.ehrPatient.statusLabel',
  },
});

const fillIfEmpty = string => string || '---------';

@autobind
class CollapsibleEhrPatient extends React.Component {
  static propTypes = {
    ehrPatient: PropTypes.object.isRequired,
    className: PropTypes.string,
    goToHandler: PropTypes.func.isRequired,
    goToTitle: PropTypes.string.isRequired,
    ehrPatientIdLabel: PropTypes.string.isRequired,
    phoneNumberLabel: PropTypes.string.isRequired,
    ehrIdentifierLabel: PropTypes.string.isRequired,
    fullNameLabel: PropTypes.string.isRequired,
    dateOfBirthLabel: PropTypes.string.isRequired,
    insurerLabel: PropTypes.string.isRequired,
    addressLabel: PropTypes.string.isRequired,
    planLabel: PropTypes.string.isRequired,
    sourceLabel: PropTypes.string.isRequired,
    statusLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: 'patient-details-card light-blue-title bg-grey-color',
  };

  render() {
    const {
      ehrPatient,
      className,
      title,
      goToHandler,
      goToTitle,
      ehrPatientIdLabel,
      phoneNumberLabel,
      ehrIdentifierLabel,
      fullNameLabel,
      dateOfBirthLabel,
      insurerLabel,
      addressLabel,
      planLabel,
      sourceLabel,
      statusLabel,
    } = this.props;
    if (!ehrPatient) return null;
    return (
      <div>
        <CollapsibleCard title={title} id="patient-backoffice-details-id" className={className}>
          <div className="row padding-v-20 padding-h-20">
            <Button
              type="small"
              className="btn-blue text-semibold action-button"
              onClick={goToHandler}
              text={goToTitle}
            />
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{ehrPatientIdLabel}</h4>
              {fillIfEmpty(ehrPatient.id)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{phoneNumberLabel}</h4>
              {fillIfEmpty(
                Formatter.phoneNumberDefault(
                  ehrPatient.phoneNumber || ehrPatient.mobilePhoneNumber,
                ),
              )}
            </div>
          </div>
          {ehrPatient.ehrIdentifier ? (
            <div className="row">
              <div className="col-xs-12">
                <h4>{ehrIdentifierLabel}</h4>
                {ehrPatient.ehrIdentifier}
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-xs-12">
              <h4>{fullNameLabel}</h4>
              {Formatter.toFullName(
                ehrPatient.firstName,
                ehrPatient.middleName,
                ehrPatient.lastName,
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{dateOfBirthLabel}</h4>
              {fillIfEmpty(
                Formatter.dateUtc(ehrPatient.dateOfBirth, commonFormats.SHORT_MONTH_DAY_YEAR_DATE),
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{insurerLabel}</h4>
              {fillIfEmpty(ehrPatient.insurer)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{addressLabel}</h4>
              {fillIfEmpty(ehrPatient.address)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{planLabel}</h4>
              {fillIfEmpty(_.get(ehrPatient, 'plan.description', ehrPatient.plan))}
            </div>
          </div>
          {ehrPatient.source ? (
            <div className="row">
              <div className="col-xs-12">
                <h4>{sourceLabel}</h4>
                {ehrPatient.source}
              </div>
            </div>
          ) : null}
          {ehrPatient.status ? (
            <div className="row">
              <div className="col-xs-12">
                <h4>{statusLabel}</h4>
                {_.startCase(_.camelCase(ehrPatient.status))}
              </div>
            </div>
          ) : null}
        </CollapsibleCard>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withHandlers({
    goToHandler: props => e => {
      e.preventDefault();
      const { router, ehrPatient, healthSystem, isNamespacePatient } = props;
      return isNamespacePatient
        ? router.push(
            `/secure/namespaceManager/${healthSystem.integrationType}/patients/${ehrPatient.id}`,
          )
        : router.push(`/secure/ehrPatients/${ehrPatient.id}`);
    },
  }),
  withTranslatedMessages(patientMessages),
  withPropsOnChange(
    ['ehrPatient'],
    ({ isNamespacePatient, goToNamespacePatient, goToEhrPatient }) => ({
      title: isNamespacePatient
        ? patientMessages.namespacePatientTitle
        : patientMessages.ehrPatientTitle,
      goToTitle: isNamespacePatient ? goToNamespacePatient : goToEhrPatient,
    }),
  ),
)(CollapsibleEhrPatient);
