/**
 * created by moran on 10/31/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import { getProviderProfile } from '../../../../store/backoffice/providerProfile/selectors';
import { getProviderById } from '../../../../store/backoffice/providerProfile/actions';
import EntityForm from '../components/entityForm/entityForm';

@autobind
class ProviderProfilePage extends React.Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
  };

  render() {
    const { provider } = this.props;
    return (
      <div>
        <h1>{`Provider Profile: ${provider.id}`}</h1>
        <hr />
        <EntityForm
          name="provider-profile-form"
          title="Provider Profile:"
          entity={provider}
          readOnly
        />
      </div>
    );
  }
}

export default compose(
  fetchNotEmpty(({ params }) => getProviderById(parseInt(params.id, 10)), getProviderProfile, {
    renderAlways: false,
    loading: true,
  }),
  connect(state => ({
    provider: getProviderProfile(state),
  })),
)(ProviderProfilePage);
