import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import './filterByBundleAction.less';
import setInQueryString from '../../../../../../store/tools/queryString/setInQueryString';

const messages = defineMessages({
  filteredBy: {
    defaultMessage: 'Filtered by',
    id: 'components.communication.filterByBundle',
  },
});

@autobind
class FilterByBundleAction extends React.PureComponent {
  static propTypes = {
    queryParams: PropTypes.object.isRequired,
    setInQueryString: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = { bundleId: '' };
  }

  componentDidMount() {
    const { queryParams } = this.props;

    if (!_.isEmpty(queryParams.filter)) {
      this.setState({ bundleId: queryParams.filter.bundleId });
    }
  }

  callAction() {
    const { bundleId } = this.state;
    const { queryParams, setInQueryString } = this.props;

    const filter = {
      ...queryParams.filter,
      bundleId,
    };

    const params = {
      filter: { ..._.omitBy(filter, _.isEmpty) },
    };

    setInQueryString(params);
    return true;
  }

  clearBundleFilter() {
    this.setState({ bundleId: '' }, () => this.callAction());
  }

  renderFilteredState() {
    const { bundleId } = this.state;
    return (
      <div className="padding-v-20 filtered-state">
        <OverlayTrigger
          id="filter"
          placement="top"
          overlay={
            <Tooltip id="filter-bundle-tooltip">
              <FormattedMessage {...messages.filteredBy} />
            </Tooltip>
          }
        >
          <i className="icon-filter" />
        </OverlayTrigger>
        <div className="tag-wrapper">
          <span className="tag-text"> bundle id = {bundleId} </span>
          <button className="btn btn-round-icon btn-round-small" onClick={this.clearBundleFilter}>
            <i className="icon-x" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { bundleId } = this.state;
    return _.isEmpty(bundleId) ? <span /> : this.renderFilteredState();
  }
}

export default connect(
  () => ({}),
  { setInQueryString },
)(FilterByBundleAction);
