import PropTypes from 'prop-types';
import { branch, compose, pure, withProps, renderNothing } from 'recompose';
import _ from 'lodash';
import React from 'react';
import { getDomain } from '../../../../../utils/domain/storage';
import { BkmdDomains } from '../../../../../model/enum/bkmdDomain';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../api';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import CleanSwitch from '../../../../../components/form/cleanSwitch';

function PeakCareAcceptNewPatientsAction({ togglePeakcareAcceptNewPatients, value, isLoading }) {
  return (
    <div className="backoffice-action">
      <span>Peakcare Accept new patients: </span>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <CleanSwitch
          id="toggle-peakcare-accept-new-patients"
          onChange={togglePeakcareAcceptNewPatients}
          value={value}
        />
      )}
    </div>
  );
}

PeakCareAcceptNewPatientsAction.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  togglePeakcareAcceptNewPatients: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withProps({
    peakCareAcceptNewPatientsFeature: getDomain() === BkmdDomains.PREMERA_BLUE_CROSS_ALASKA,
  }),
  branch(
    ({ provider, peakCareAcceptNewPatientsFeature }) =>
      _.isEmpty(provider) || !peakCareAcceptNewPatientsFeature,
    renderNothing,
  ),
  withStateFetchersOnMount({
    getAcceptNewPatientsStatus: {
      handler: ({ provider: { npi } }) => () => Api.providerApi.getAcceptNewPatientsStatus(npi),
      resultPropName: 'value',
      defaultValue: null,
    },
  }),
  withFetchers({
    togglePeakcareAcceptNewPatients: {
      handler: ({ provider: { npi }, value }) => () =>
        Api.providerApi.setAcceptNewPatientsStatus(npi, !value),
      onSuccess: ({ getAcceptNewPatientsStatus }) => getAcceptNewPatientsStatus(),
    },
  }),
  withProps(({ getAcceptNewPatientsStatusTracker, togglePeakcareAcceptNewPatientsTracker }) => ({
    isLoading:
      getAcceptNewPatientsStatusTracker.inProgress ||
      togglePeakcareAcceptNewPatientsTracker.inProgress,
  })),
)(PeakCareAcceptNewPatientsAction);
