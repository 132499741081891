/**
 * Created by ofirHartuv on 30/10/17.
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';

import EntityForm from '../../components/entityForm/entityForm';
import * as ClinicFormDefs from './clinicFormDefs';
import {
  createClinic,
  CREATE_CLINIC,
  handleFacilityEntities,
} from '../../../../../store/backoffice/clinicManagement/clinics/actions';
import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import Api from '../../../../../api';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import injectNotification from '../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';

const clinicDefaults = {
  city: '',
  state: '',
  addressLine1: '',
  addressLine2: '',
  zip: '',
  npi: '',
  officeName: '',
  phone: null,
  fax: null,
  additionalFaxes: [],
  email: null,
  secondaryEmails: [],
  ehrLocationId: null,
  poolId: null,
  healthSystemId: undefined,
  profilePicture: '',
  payers: [],
  isLegalSignatureRequired: false,
  supportsFacilityBooking: false,
};

const messages = defineMessages({
  errorMassage: {
    defaultMessage: 'Error occurred when submitting form',
    id: 'backpack.error.submit',
  },
  facilityNoNpiError: {
    defaultMessage: 'NPI is needed for Facility entities creation',
    id: 'backpack.facilityCreation.submit',
  },
  facilityCreationError: {
    defaultMessage: 'Error creating facility entities for clinic',
    id: 'backpack.facilityEntitiesCreation.submit',
  },
});

@autobind
class CreateClinic extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    tracker: PropTypes.shape(actionTrackerShape).isRequired,
    router: PropTypes.object.isRequired,
    healthSystems: PropTypes.object.isRequired,
    handleFacilityEntities: PropTypes.func.isRequired, // from redux
    /* eslint-enable react/no-unused-prop-types */
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished && !nextProps.tracker.hasError) {
      nextProps.router.push('/secure/clinics');
    }
  }

  render() {
    const { router, onSubmit, healthSystems } = this.props;
    return (
      <div>
        <div className="backoffice-action">
          <button
            onClick={() => router.push('/secure/clinics/')}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Back To Clinics
          </button>
        </div>

        <EntityForm
          name={ClinicFormDefs.name}
          title="Create New Clinic:"
          submitText="Create"
          schemaDef={ClinicFormDefs.schema}
          fieldsDef={ClinicFormDefs.fields(healthSystems.data)}
          entity={clinicDefaults}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
}

export default compose(
  injectNotification,
  withRouter,
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: () => () => Api.clinicsApi.getAllHealthSystems(),
      resultPropName: 'healthSystems',
      isReady: ({ healthSystems }) => !_.isEmpty(healthSystems),
    },
  }),
  actionTracker({
    tracker: CREATE_CLINIC.SOURCE,
  }),
  connect(null, { createClinic, handleFacilityEntities }),
  withFetchers({
    onSubmit: {
      handler: ({ createClinic, handleFacilityEntities, notification }) => async clinic => {
        if (clinic.supportsFacilityBooking) {
          if (clinic.npi) {
            const createdClinic = await createClinic(_.omit(clinic, 'supportsFacilityBooking'));
            try {
              await handleFacilityEntities(createdClinic, true);
            } catch (error) {
              notification.error('', messages.facilityCreationError);
            }
          } else {
            notification.error('', messages.facilityNoNpiError);
          }
        } else {
          await createClinic(_.omit(clinic, 'supportsFacilityBooking'));
        }
      },
      onError: ({ notification }) => notification.error('', messages.errorMassage),
    },
  }),
)(CreateClinic);
