import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { string, object, lazy } from 'yup';
import { Field, Form } from 'formik';
import { defineMessages } from 'react-intl';
import { WrappedFormik } from '../../components/form/formik/wrappedFormik';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';

import Recaptcha from '../../components/verification/bkmdRecaptcha';
import SpinnerButton from '../../components/ui/spinner/spinnerButton';
import { CleanFormikInput } from '../../components/form/formik/cleanFormikInput';
import injectNotification from '../../store/notification/injectNotification';
import { resetRecaptch } from '../../store/recaptcha/actions';
import { withFetchers } from '../../api/injectApi/withFetchers';
import Api from '../../api';
import * as Errors from '../../api/errors';

const PhoneOrEmailSchema = object().shape({
  identifier: lazy(value => {
    if (_.includes(value, '@')) {
      return string()
        .email('Oopsy, please enter valid email or mobile number here.')
        .nullable(false)
        .required();
    }
    if (_.includes(value, ' ') || _.includes(value, '-')) {
      return string()
        .matches(
          /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
          'Oopsy, please enter valid email or mobile number here.',
        )
        .nullable(false)
        .required();
    }
    return string()
      .length(10)
      .nullable(false)
      .required();
  }),
});

export const messages = defineMessages({
  yourMobileOrEmail: {
    defaultMessage: 'Your mobile number or email',
    id: 'step.identification.yourMobileNumber',
  },
  yourEmail: {
    defaultMessage: 'Your email',
    id: 'step.identification.yourEmail',
  },
  primaryButtonText: {
    defaultMessage: 'Reset your password',
    id: 'step.identification.resetYourPassword',
  },
  maxAttemptsErrorMessage: {
    defaultMessage:
      "You've reached the maximum attempts for resetting your password, " +
      'please contact our customer service for unlocking your account.',
    id: 'step.identification.error.maxAttemptsErrorMessage',
  },
  userLockedErrorMessage: {
    defaultMessage:
      'Your account is locked, ' +
      'please contact our customer service for unlocking your account.',
    id: 'step.identification.error.userLockedErrorMessage',
  },
  serverErrorTitle: {
    defaultMessage: 'Sorry, an error has occurred',
    id: 'step.identification.error.serverErrorTitle',
  },
  serverNoAccountErrorMessage: {
    defaultMessage:
      'This email or phone is not associated with any account, ' +
      'please make sure you entered it correctly',
    id: 'step.identification.error.serverNoAccountErrorMessage',
  },
  recaptchaErrorMessage: {
    defaultMessage: "Please confirm you're not a robot",
    id: 'step.identification.error.recaptcha.message',
  },
});

function createIdentifier({ identifier }) {
  if (!_.includes(identifier, '@')) {
    const phone = `+1${identifier.replace('+1', '').replace(/\D/g, '')}`; // gets a clear number
    return { identifier: phone, type: 'phone' };
  }

  return { identifier, type: 'email' };
}

const IdentificationForm = ({
  resetPassword,
  message,
  resetPasswordTracker,
  primaryButtonText,
  showRecaptcha,
}) => {
  const comp = CleanFormikInput; // hack - this is to solve a weird problem with formik
  return (
    <div>
      <WrappedFormik
        onSubmit={resetPassword}
        validationSchema={PhoneOrEmailSchema}
        render={({ isValid }) => (
          <Form>
            <div className="row">
              <div className="col-xs-12 margin-top">
                <Field name="identifier" label={message} component={comp} autoFocus />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 text-center">
                {showRecaptcha && <Recaptcha /> }
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 margin-top-30">
                <SpinnerButton
                  className="btn btn-big bg-color-brand-button"
                  type="submit"
                  isLoading={resetPasswordTracker.inProgress}
                  disabled={!isValid}
                >
                  {primaryButtonText}
                </SpinnerButton>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

IdentificationForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetPasswordTracker: PropTypes.object.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  showRecaptcha: PropTypes.bool,
};

IdentificationForm.defaultProps = {
  showRecaptcha: true,
};

export default compose(
  connect(
    null,
    { resetRecaptch },
  ),
  injectNotification,
  withTranslatedMessages(messages),
  withFetchers({
    resetPassword: {
      handler: () => data => Api.authApi.resetPasswordRequest(createIdentifier(data)),
      onSuccess: ({ control }, result) =>
        control.next({
          payload: {
            identifier: _.get(result, 'data.identifier'),
            type: _.get(result, 'data.type'),
          },
        }),
      onError: ({ notification, resetRecaptch }, error) => {
        resetRecaptch();
        let errorMsg;
        const { responseMessage } = error;
        switch (responseMessage) {
          case Errors.MAX_RESET_PASSWORD_ATTEMPTS: {
            errorMsg = messages.maxAttemptsErrorMessage;
            break;
          }
          case Errors.USER_LOCKED: {
            errorMsg = messages.userLockedErrorMessage;
            break;
          }
          case Errors.RECAPTCHA_FAILED:
            errorMsg = messages.recaptchaErrorMessage;
            break;
          default: {
            errorMsg = messages.serverNoAccountErrorMessage;
            break;
          }
        }
        notification.error(messages.serverErrorTitle, errorMsg, { autoDismiss: 15 });
      },
    },
  }),
  withPropsOnChange(['byEmail', 'byPhone'], ({ byEmail, byPhone }) => {
    // decide which message to use
    let message;
    if (byPhone) {
      if (byEmail) {
        message = messages.yourMobileOrEmail;
      } else {
        message = messages.resetYourPassword;
      }
    } else {
      message = messages.yourEmail;
    }
    return { message };
  }),
)(IdentificationForm);
