/**
 * created by moran on 11/29/17
 */
import Joi from 'joi-browser';
import _ from 'lodash';
import {
  ReduxFormCleanInput,
  ReduxFormCleanSelect,
} from '../../../../../components/form/reduxForm/components';
import * as ServiceKeyType from '../../../../../model/enum/serviceKeyType';

export const name = 'backoffice-create-service-code';

export const fields = {
  key: {
    name: 'key',
    component: ReduxFormCleanInput,
  },
  type: {
    options: _.map(ServiceKeyType, (value, label) => ({ value, label })),
    name: 'type',
    component: ReduxFormCleanSelect,
  },
  x12ServiceType: {
    name: 'x12ServiceType',
    component: ReduxFormCleanInput,
  },
  commonName: {
    name: 'commonName',
    component: ReduxFormCleanInput,
  },
  medicalName: {
    name: 'medicalName',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  key: Joi.string().optional(),
  type: Joi.string(),
  x12ServiceType: Joi.string().optional(),
  commonName: Joi.string().optional(),
  medicalName: Joi.string().optional(),
};
