/**
 * Created by meirshalev 03/06/2018.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { autobind } from 'core-decorators';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CollapsibleCard from 'Components/ui/collapsibleCard/collapsibleCard';
import { ReduxFormCleanSwitch } from 'Components/form/reduxForm/components';
import { SpinnerButton } from 'Components/ui/spinner/index';
import { BkmdDomains } from 'Model/enum/bkmdDomain';

import './featureFlagsForm.less';

@autobind
class FeatureFlagsForm extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    features: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: undefined,
    isLoading: false,
  };

  createValuesDiv(rootPath, key) {
    const { features } = this.props;

    const path = `${rootPath}.${key}`;
    const pathWithoutDomain = _.chain(path)
      .split('.')
      .drop(1)
      .join('.')
      .value();
    const featureDescription = _.get(features, [pathWithoutDomain, 'description']);

    return (
      <div key={key} className="col-xs-12 col-sm-4 feature-flag-padding">
        <Field
          label={key}
          className="text-15 feature-flag-transform"
          name={path}
          id={path}
          component={ReduxFormCleanSwitch}
        />
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`${path}.tooltip`}>
              <span>{featureDescription}</span>
            </Tooltip>
          }
        >
          <i className="icon-help tooltip-icon" />
        </OverlayTrigger>
      </div>
    );
  }

  createCollapsibleCard(rootKey, partialJson) {
    const ownValues = {};
    const children = [];
    _.each(partialJson, (value, key) => {
      if (_.isBoolean(value)) {
        ownValues[key] = value;
      } else {
        children.push(this.createCollapsibleCard(`${rootKey}.${key}`, value));
      }
    });

    return (
      <CollapsibleCard
        key={rootKey}
        id={rootKey}
        title={_.split(rootKey, '.').pop()}
        className="light-blue-title bg-grey-color"
      >
        <div className="row">
          {_.isEmpty(ownValues)
            ? null
            : _.map(ownValues, (value, key) => this.createValuesDiv(rootKey, key))}
          <div className="col-xs-12">{children}</div>
        </div>
      </CollapsibleCard>
    );
  }

  render() {
    const { initialValues, handleSubmit, isLoading } = this.props;
    return (
      <form onSubmit={handleSubmit} method="post">
        {_.map(BkmdDomains, domainRaw => {
          const domain = _.camelCase(domainRaw);
          return this.createCollapsibleCard(domain, initialValues[domain]);
        })}
        <SpinnerButton
          className="btn btn-big complete-btn bg-color-brand-button"
          type="submit"
          isLoading={isLoading}
        >
          Update
        </SpinnerButton>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'featureFlags',
  }),
)(FeatureFlagsForm);
