import React from 'react';
import PropTypes from 'prop-types';
import QueryStringGrid from 'Components/grid/queryStringGrid';
import BkmdGridColumn from 'Components/grid/bkmdGridColumn';
import DeleteActionButtonCell from '../../../../../components/deleteActionButtonCell';

const PlanMappingGrid = ({ results, onQueryChange, onPlanMappingClick, onDeletePlanMapping }) => (
  <QueryStringGrid
    data={results.data}
    totalCount={results.count}
    onQueryChange={onQueryChange}
    onRowClick={onPlanMappingClick}
    classNames={{ Table: 'bkmd-table auto-layout health-systems-grid' }}
  >
    <BkmdGridColumn
      id="ehrExternalId"
      title="EHR External ID"
      headerCssClassName="bkmd-th name-col"
    />
    <BkmdGridColumn
      id="ehrDescription"
      title="EHR Description"
      headerCssClassName="bkmd-th name-col"
    />
    <BkmdGridColumn id="payerInsurer" title="Insurer" headerCssClassName="bkmd-th name-col" />
    <BkmdGridColumn
      id="payerDescription"
      title="Payer Roster Plan"
      headerCssClassName="bkmd-th name-col"
    />
    <BkmdGridColumn
      id="deleteAction"
      title="Delete"
      sortable={false}
      customComponent={DeleteActionButtonCell}
      onClick={onDeletePlanMapping}
      headerCssClassName="bkmd-th delete-col"
      cssClassName="bkmd-td delete-td text-center"
    />
  </QueryStringGrid>
);

PlanMappingGrid.propTypes = {
  results: PropTypes.object.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onPlanMappingClick: PropTypes.func.isRequired,
  onDeletePlanMapping: PropTypes.func.isRequired,
};

export default PlanMappingGrid;
