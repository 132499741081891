/**
 * created by moran on 11/28/17
 */
import PromiseActionType from '../../promiseActionType';

export const GET_MEDICAL_CODE_BY_ID = new PromiseActionType('GET_MEDICAL_CODE_BY_ID');
export const CREATE_MEDICAL_CODE = new PromiseActionType('CREATE_MEDICAL_CODE');
export const UPDATE_MEDICAL_CODE = new PromiseActionType('UPDATE_MEDICAL_CODE');
export const DELETE_MEDICAL_CODE = new PromiseActionType('DELETE_MEDICAL_CODE');
export const LIST_MEDICAL_SERVICES = new PromiseActionType('LIST_MEDICAL_SERVICES');

export function getMedicalCodeById(key, type) {
  return ({ bkmdApi: { medicalCodesApi } }) => ({
    type: GET_MEDICAL_CODE_BY_ID.SOURCE,
    payload: {
      promise: medicalCodesApi.getMedicalCodeById(key, type)
        .then(res => res.data)
    },
    meta: {
      tracker: GET_MEDICAL_CODE_BY_ID.SOURCE,
    },
  });
}

export function createMedicalCode(service) {
  return ({ bkmdApi: { medicalCodesApi } }) => ({
    type: CREATE_MEDICAL_CODE.SOURCE,
    payload: {
      promise: medicalCodesApi.createMedicalCode(service)
        .then(res => res.data)
    },
    meta: {
      tracker: CREATE_MEDICAL_CODE.SOURCE,
    },
  });
}

export function updateMedicalCode(service) {
  return ({ bkmdApi: { medicalCodesApi }, dispatch }) => ({
    type: UPDATE_MEDICAL_CODE.SOURCE,
    payload: {
      promise: medicalCodesApi.updateMedicalCode(service)
        .then(() => {
          dispatch(getMedicalCodeById(service.key, service.type));
        })
    },
    meta: {
      tracker: UPDATE_MEDICAL_CODE.SOURCE,
    },
  });
}

export function deleteMedicalCode(key, type) {
  return ({ bkmdApi: { medicalCodesApi } }) => ({
    type: DELETE_MEDICAL_CODE.SOURCE,
    payload: {
      promise: medicalCodesApi.deleteMedicalCode(key, type)
    },
    meta: {
      tracker: DELETE_MEDICAL_CODE.SOURCE,
    },
  });
}

export function listMedicalCodes(skip = 0, limit = 100, search, sort, sortAscending) {
  return ({ bkmdApi: { medicalCodesApi } }) => ({
    type: LIST_MEDICAL_SERVICES.SOURCE,
    payload: {
      promise: medicalCodesApi.listMedicalCodes(skip, limit, search, sort, sortAscending)
        .then(res => res.data)
    },
    meta: {
      tracker: LIST_MEDICAL_SERVICES.SOURCE,
    },
  });
}
