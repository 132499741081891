import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withState, withHandlers, pure, withPropsOnChange } from 'recompose';
import CleanTypeahead from '../../../../../components/form/cleanTypeahead';
import { getMatchingMembersByName } from '../../../../../store/backoffice/members/actions';

const MemberSearch = ({ onChange, onSelectionChange, options }) => (
  <div>
    <CleanTypeahead
      label="Member name"
      className="chat-search-input"
      onInputChange={onChange}
      onOptionSelection={onSelectionChange}
      options={options}
    />
  </div>
);

MemberSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const enhance = compose(
  connect(
    null,
    { getMatchingMembersByName },
  ),
  pure,
  withRouter,
  withState('options', 'setOptions', []),
  withHandlers({
    onChange: props => text => {
      const { setOptions, getMatchingMembersByName } = props;
      getMatchingMembersByName(text)
        .then(members => setOptions(members))
        .catch(() => setOptions([]));
    },
    onSelectionChange: props => selected => {
      const { router } = props;
      if (_.get(selected, 'id')) {
        router.push(`/secure/communication/chat/${selected.id}`);
      }
    },
  }),
  withPropsOnChange(['onChange'], ({ onChange }) => ({
    onChange: _.debounce(onChange, 80),
  })),
);

export default enhance(MemberSearch);
