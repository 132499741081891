/**
 * Created by meirshalev 31/05/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import injectNotification from 'Store/notification/injectNotification';

import fetchNotEmpty from 'Store/tools/fetchNotEmpty';
import actionTracker from 'Store/tools/actionTracker/actionTrackerComponent';
import {
  getAllFeatureFlags,
  getAllFeatures,
  UPDATE_FEATURE_FLAGS,
  updateFeatureFlags,
} from 'Store/backoffice/features/actions';
import * as selectors from 'Store/backoffice/features/selectors';
import FeatureFlagsForm from './featureFlagsForm';

const messages = defineMessages({
  errorTitle: {
    defaultMessage: 'Error while updating feature flags',
    id: 'backoffice.features.error.title',
  },
  errorMessage: {
    defaultMessage: 'Please refresh and try again',
    id: 'backoffice.features.error.message',
  },
  successTitle: {
    defaultMessage: 'Success',
    id: 'backoffice.features.success.title',
  },
  successMessage: {
    defaultMessage: 'Feature flags updated successfully',
    id: 'backoffice.features.success.message',
  },
});

@autobind
class FeatureFlagsPage extends React.Component {
  static propTypes = {
    featureFlags: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    updateFeatureFlags: PropTypes.func.isRequired,
    updateFeatureFlagsTracking: PropTypes.object.isRequired,
    /**
     * injected by the injectNotification HOC
     */
    notification: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { updateFeatureFlagsTracking, notification } = nextProps;

    if (updateFeatureFlagsTracking.finished) {
      if (updateFeatureFlagsTracking.hasError) {
        notification.error(messages.errorTitle, messages.errorMessage, {
          autoDismiss: 10,
        });
      } else {
        notification.success(messages.successTitle, messages.successMessage, {
          autoDismiss: 10,
        });
      }
    }
  }

  onSubmit(updatedFeatureFlags) {
    const { featureFlags, updateFeatureFlags } = this.props;

    updateFeatureFlags(updatedFeatureFlags, featureFlags);
  }

  render() {
    const { featureFlags, updateFeatureFlagsTracking, features } = this.props;
    return (
      <FeatureFlagsForm
        onSubmit={this.onSubmit}
        initialValues={featureFlags}
        features={features}
        isLoading={updateFeatureFlagsTracking.inProgress}
      />
    );
  }
}

export default compose(
  actionTracker({
    updateFeatureFlagsTracking: UPDATE_FEATURE_FLAGS.SOURCE,
  }),
  fetchNotEmpty(
    [getAllFeatureFlags, getAllFeatures],
    [selectors.featureFlags, selectors.features],
    { renderAlways: false, loading: true },
  ),
  connect(
    state => ({
      featureFlags: selectors.featureFlags(state),
      features: selectors.features(state),
    }),
    {
      updateFeatureFlags,
    },
  ),
  injectNotification,
)(FeatureFlagsPage);
