/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by meirshalev 03/04/2018.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PHI_ACCESS } from '../../../../model/enum/aclResourceType';
import DomainMemberList from './domainMemberList';
import DomainMemberPage from './domainMemberPage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'domainMember',
  name: 'domainMember',
  childRoutes: [
    {
      path: ':domainMemberId',
      name: 'domainMemberPage',
      component: DomainMemberPage.default || DomainMemberPage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(divComponent),

  indexRoute: {
    name: 'domainMemberList',
    component: DomainMemberList.default || DomainMemberList,
  },
};
/* eslint-enable import/first, react/jsx-filename-extension */
