/**
 * Created by chenrozenes on 14/11/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import EntityForm from './entityForm/entityForm';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../store/notification/injectNotification';
import { MessageShape } from '../../../../components/ui/intlString';

const messages = defineMessages({
  errorTitle: {
    defaultMessage: 'Error occurred',
    id: 'backoffice.action.errorTitle',
  },
  errorMessage: {
    defaultMessage: '{action} {name} failed. Refresh, or call the best team in the company',
    id: 'backoffice.action.errorMessage',
  },
  successTitle: {
    defaultMessage: 'Success!',
    id: 'backoffice.action.successTitle',
  },
  successMessage: {
    defaultMessage: '{action} {name} succeeded. Go and thank the dev team',
    id: 'backoffice.action.successMessage',
  },
});

/**
 * Wraps entityForm. Make sure to set mockForCreate in FormDefs.
 */
@autobind
class SimpleEntity extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    formDefs: PropTypes.object.isRequired,
    entity: PropTypes.object,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    isSubmitting: PropTypes.bool,
    notification: PropTypes.object.isRequired,
    errorTitle: MessageShape,
    errorMessage: MessageShape,
    successTitle: MessageShape,
    successMessage: MessageShape,
    actionTrackerId: PropTypes.string,
    onActionSuccess: PropTypes.func,
    onActionError: PropTypes.func,
    actionTracking: PropTypes.object.isRequired,
  };

  static defaultProps = {
    entity: {},
    onCreate: () => null,
    onUpdate: () => null,
    errorTitle: messages.errorTitle,
    errorMessage: messages.errorMessage,
    successTitle: messages.successTitle,
    successMessage: messages.successMessage,
    isSubmitting: false,
    actionTrackerId: undefined,
    onActionSuccess: undefined,
    onActionError: undefined,
  };

  componentWillReceiveProps(nextProps) {
    const { actionTracking, actionTrackerId } = nextProps;

    if (actionTrackerId && actionTracking.finished) this.handleActionResponse(actionTracking);
  }

  getActionText() {
    return this.isCreate() ? 'Create' : 'Update';
  }

  handleActionResponse(tracking) {
    const {
      notification,
      errorTitle,
      errorMessage,
      successTitle,
      successMessage,
      name: _name,
      onActionSuccess,
      onActionError,
    } = this.props;

    const name = _.startCase(_name);
    const action = this.getActionText();

    if (tracking.hasError) {
      notification.error(errorTitle, errorMessage, {
        messageParams: { name, action },
      });
      if (onActionError) onActionError();
    } else {
      notification.success(successTitle, successMessage, {
        messageParams: { name, action },
      });
      if (onActionSuccess) onActionSuccess();
    }
  }

  isCreate() {
    return _.isEmpty(this.props.entity);
  }

  render() {
    const {
      actionTracking,
      isSubmitting,
      name,
      formDefs,
      entity,
      onCreate,
      onUpdate,
      ...other
    } = this.props;

    const entityForForm = this.isCreate() ? formDefs.mockForCreate : entity;
    if (!entityForForm) {
      throw new Error('Provide SimpleEntity with entity or formDef.mockForCreate');
    }

    return (
      <EntityForm
        name={formDefs.name}
        title={`${this.getActionText()} ${name}`}
        submitText={this.getActionText()}
        schemaDef={formDefs.schema}
        fieldsDef={formDefs.fields}
        entity={entityForForm}
        onSubmit={this.isCreate() ? onCreate : onUpdate}
        isSubmitting={actionTracking ? actionTracking.inProgress : isSubmitting}
        {...other}
      />
    );
  }
}

export default compose(
  injectNotification,
  actionTracker({
    actionTracking: (state, props) => props.actionTrackerId,
  }),
)(SimpleEntity);
