import gql from 'graphql-tag';
import schedulingGraphqlFetcher from '../../schedulingGraphqlFetcher/fetcher';

export class SchedulingApi {
  async resendAppointmentRequestEmail(input) {
    const result = await schedulingGraphqlFetcher.queryOrMutate({
      query: gql`
        mutation resendAppointmentRequestEmail($input: ResendAppointmentRequestEmailInput!) {
          resendAppointmentRequestEmail(input: $input) {
            token
            success
            error
          }
        }
      `,
      operationName: 'resendAppointmentRequestEmail',
      variables: {
        input,
      },
    });

    return result.data.resendAppointmentRequestEmail;
  }

  async sendOnboardingMailToClinics(input) {
    const result = await schedulingGraphqlFetcher.queryOrMutate({
      query: gql`
        mutation sendOnboardingMailToClinics($input: SendOnboardingMailToClinicsInput!) {
          sendOnboardingMailToClinics(input: $input) {
            clinics {
              failed {
                clinicId
                reason
              }
              successful
            }
          }
        }
      `,
      operationName: 'sendOnboardingMailToClinics',
      variables: {
        input,
      },
    });

    return result.data.sendOnboardingMailToClinics;
  }
}
