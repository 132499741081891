import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { reduxForm } from 'redux-form';
import * as addCalendarFormDefs from './calendarFormDefs';
import { createValidator } from '../../../../../utils/joiValidator';
import FieldGenerator from '../../components/entityForm/fieldGenerator';

const calendarMock = {
  openHours: undefined,
  blockSize: 15,
  locations: [],
  timezone: '',
  integrationLevel: 'NONE',
};

@autobind
class AddCalendarForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="row">
          <FieldGenerator entity={calendarMock} fieldsDef={addCalendarFormDefs.fields} />
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    initialValues: calendarMock,
    form: addCalendarFormDefs.name,
    validate: createValidator(addCalendarFormDefs.schema),
  }),
)(AddCalendarForm);
