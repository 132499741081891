import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { compose, setDisplayName, pure, withProps } from 'recompose';
import _ from 'lodash';

import * as formDef from './addUserGroupFormDef';
import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import Apis from '../../../../../../api';
import BkmdModelButton from '../../../../../../components/bkmdModalButton/bkmdModalButton';

function AddUserToGroupFormView(props) {
  return (
    <div>
      <WrappedFormik
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validationSchema={formDef.formSchema}
        render={({ isValid, values }) => (
          <Form>
            <div className="row">
              <Field
                id="addByEmail"
                label="Add By"
                simpleSelect
                horizontal
                wrapClass="row tiny-gutter"
                buttonStyle="btn-pill"
                {...formDef.fields.addByEmail}
              />
            </div>
            <div className="row">
              <Field label={`User ${values.addByEmail ? 'Email' : 'Id'}`} {...formDef.fields.id} />
            </div>
            <div className="row">
              <SpinnerButton
                className="btn btn-big btn-min-100 btn-blue"
                disabled={!isValid}
                type="submit"
                isLoading={props.onSubmitTracker.inProgress}
              >
                Run Action
              </SpinnerButton>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

AddUserToGroupFormView.propTypes = {
  groupId: PropTypes.number.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  notification: PropTypes.object.isRequired,
};

AddUserToGroupFormView.defaultProps = {
  initialValues: formDef.initialValues,
  onSuccess: _.noop,
};

export const AddUserToGroupForm = compose(
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: ({ groupId }) => values => {
        const { usersApi } = Apis;
        return values.addByEmail
          ? usersApi.addUserToGroupByEmail(groupId, values.id)
          : usersApi.addUserToGroup(groupId, values.id);
      },
      onSuccess: ({ notification, closeModal, onSuccess }, result) => {
        notification.success('Add user', 'User added successfully');
        closeModal();
        onSuccess(result);
      },
      onError: ({ notification }) =>
        notification.error('Add User Failed', 'Error while adding user'),
    },
  }),
)(AddUserToGroupFormView);

export default compose(
  setDisplayName('AddUserToGroupAction'),
  pure,
  withProps({
    title: 'Add User to Group',
    buttonText: 'Add User',
    component: AddUserToGroupForm,
  }),
)(BkmdModelButton);
