export default {
  label: 'Cerner',
  schema: {
    properties: {
      cerner__base_url: {
        title: 'Base url',
      },
      cerner__patient_base_url: {
        title: 'Patient base url',
      },
      cerner__auth_base_url: {
        title: 'Auth base url',
      },
      cerner__clientId: {
        title: 'Client Id',
      },
      cerner__clientSecret: {
        title: 'Client Secret',
      },
      cerner__password: {
        title: 'Client Password',
      },
      cerner__assigner_organization: {
        title: 'Assigner organization id',
      },
    },
  },
};
