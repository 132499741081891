import _ from 'lodash';
import { analyzeClinicsCsvRows } from '../../../utils/clinicManagement/analyzeClinicCsvRows';
import Api from '../../../api';

export const name = 'newClinicsCsvType';

export const newClinicsCsvType = {
  name,
  displayName: 'Clinics (New only)',
  onFileLoaded: rows => {
    const { clinics, errors, errorColumnsOrder } = analyzeClinicsCsvRows(rows);
    return { clinics, errors, errorColumnsOrder };
  },
  handler: ({
    clinics, shouldSendLegalDocuments, clinicSource, errorColumnsOrder
  }) => {
    if (errorColumnsOrder) {
      return { data: { errorColumnsOrder } };
    }

    return clinics.map(clinic => {
      clinic.isLegalSignatureRequired = shouldSendLegalDocuments;
      clinic.source = clinicSource;
      return clinic;
    });
  },
  onSubmit: async ({ payer, clinics, errorColumnsOrder }) => {
    const payers = [];
    if (errorColumnsOrder) {
      return { data: { errorColumnsOrder } };
    }
    _.map(payer, data => {
      payers.push(data.value);
    });
    _.forEach(clinics, clinic => {
      clinic.payers = payers;
    });
    return Api.clinicsApi.createClinics(clinics);
  },
};

export function addType(csvType) {
  csvType.addType(name, newClinicsCsvType);
}
