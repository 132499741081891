import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isEmpty, get } from 'lodash';
import { createToggleState } from 'Components/recompose/toggleStateComponent';
import Api from '../../../../../../api';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import FlaggedRender from '../../../../../../utils/FlaggedRender';
import { servicesConfig } from '../../servicesConfig';
import { getPhiAccess } from '../../../../../../store/auth/selectors';
import './index.less';

const NamespaceDetails = ({ namespace, router, params, hasPhiAccess }) => {
  const services = get(namespace, 'services', []);

  return (
    <div className="namespace-details">
      <FlaggedRender
        shouldRender={
          services.includes(servicesConfig.appointmentsService.relatedService) && hasPhiAccess
        }
      >
        {() => (
          <div
            className="panel namespace-panel"
            onClick={() =>
              router.push(`/secure/namespaceManager/${params.namespaceName}/appointments`)
            }
          >
            <div className="panel-body">
              <i className="icon-calendar-stars panel-img" />
            </div>
            <div className="panel-footer">Appointments</div>
          </div>
        )}
      </FlaggedRender>
      <FlaggedRender
        shouldRender={
          services.includes(servicesConfig.appointmentRequestService.relatedService) && hasPhiAccess
        }
      >
        {() => (
          <div
            className="panel namespace-panel"
            onClick={() =>
              router.push(`/secure/namespaceManager/${params.namespaceName}/appointmentRequests`)
            }
          >
            <div className="panel-body">
              <i className="icon-calendar-stars panel-img" />
            </div>
            <div className="panel-footer">Appointment Requests</div>
          </div>
        )}
      </FlaggedRender>
      <FlaggedRender
        shouldRender={
          services.includes(servicesConfig.patientsService.relatedService) && hasPhiAccess
        }
      >
        {() => (
          <div
            className="panel namespace-panel"
            onClick={() => router.push(`/secure/namespaceManager/${params.namespaceName}/patients`)}
          >
            <div className="panel-body">
              <i className="icon-staff panel-img" />
            </div>
            <div className="panel-footer">Patients</div>
          </div>
        )}
      </FlaggedRender>
      <FlaggedRender
        shouldRender={
          services.includes(servicesConfig.appointmentActionsService.relatedService) && hasPhiAccess
        }
      >
        {() => (
          <div
            className="panel namespace-panel"
            onClick={() =>
              router.push(`/secure/namespaceManager/${params.namespaceName}/appointmentActions`)
            }
          >
            <div className="panel-body">
              <i className="icon-calendar-stars panel-img" />
            </div>
            <div className="panel-footer">Appointment Actions</div>
          </div>
        )}
      </FlaggedRender>
      <FlaggedRender
        shouldRender={services.includes(servicesConfig.puppeteerVsAdapter.relatedService)}
      >
        {() => (
          <div
            className="panel namespace-panel"
            onClick={() =>
              router.push(`/secure/namespaceManager/${params.namespaceName}/unlockEhrUser`)
            }
          >
            <div className="panel-body">
              <i className="icon-user-r-stars panel-img" />
            </div>
            <div className="panel-footer">Unlock EHR User</div>
          </div>
        )}
      </FlaggedRender>
    </div>
  );
};

NamespaceDetails.propTypes = {
  namespace: PropTypes.object,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  hasPhiAccess: PropTypes.bool.isRequired,
};

NamespaceDetails.defaultProps = {
  namespace: {},
};

export default compose(
  withRouter,
  withStateFetchersOnMount({
    getNamespaceDetails: {
      handler: ({ params }) => () => Api.namespaceManagerApi.getNamespace(params.namespaceName),
      resultPropName: 'namespace',
      isReady: ({ namespace }) => !isEmpty(namespace),
    },
  }),
  createToggleState('addServiceModal'),
  connect(state => ({
    hasPhiAccess: getPhiAccess(state),
  })),
)(NamespaceDetails);
