/**
 * Created by ofirHartuv on 02/11/17.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import * as selectors from '../../../../../store/backoffice/clinicManagement/appointment/selectors';
import { getAllAppointmentsForCalendar } from '../../../../../store/backoffice/clinicManagement/appointment/actions';

const onQueryChange = function onQueryChange() {};

@autobind
class AppointmentList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllAppointmentsForCalendar: PropTypes.func.isRequired, // from redux
    getAllAppointments: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    appointments: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.props.getAllAppointments();
  }

  onClick(rowData) {
    const {
      params: { id, providerId },
    } = this.props;
    const appointmentId = rowData.id;
    const { calendarId } = rowData;
    this.props.router.push(
      `/secure/clinics/${id}/providers/${providerId}/calendars/
      ${calendarId}/appointments/${appointmentId}`,
    );
  }

  renderActions() {
    const {
      params: { id, providerId, calendarId },
    } = this.props;
    return (
      <div className="backoffice-action">
        <button
          onClick={() =>
            this.props.router.push(
              `/secure/clinics/${id}/providers/${providerId}/calendars/${calendarId}/appointments/create`,
            )}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Create Appointment
        </button>
      </div>
    );
  }

  render() {
    const { appointments, count } = this.props;
    return (
      <div>
        {this.renderActions()}
        <hr />
        <QueryStringGrid
          data={appointments}
          totalCount={count}
          onQueryChange={onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="calendarId" title="Calendar Id" />
          <BkmdGridColumn id="domainMemberId" title="Domain Member Id" />
          <BkmdGridColumn id="startTime" title="Start time" />
          <BkmdGridColumn id="endTime" title="End time" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      appointments: selectors.getAppointmentsList(state),
      count: selectors.getAppointmentsListCount(state),
    }),
    dispatch => ({
      getAllAppointments() {
        // get calendars depends on the url params
        dispatch(this.getAllAppointmentsForCalendar(0, 10, this.params.calendarId));
      },
      getAllAppointmentsForCalendar,
    }),
  ),
)(AppointmentList);
