import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../components/action';
import AddCalendarForm from './addCalendarForm';
import { CREATE_CALENDAR } from '../../../../../store/backoffice/clinicManagement/calendar/actions';
import { name } from './calendarFormDefs';

@autobind
class AddCalendar extends React.PureComponent {
  static propTypes = {
    onCreateCalendar: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Action
        name="addCalendar"
        title="Add Calender"
        actionTrackerId={CREATE_CALENDAR.SOURCE}
        formName={name}
        onActionSuccess={this.props.onFinish}
        {...this.props}
      >
        <AddCalendarForm onSubmit={this.props.onCreateCalendar} />
      </Action>
    );
  }
}

export default AddCalendar;
