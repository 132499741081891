import { compose } from 'recompose';
import { isEmpty } from 'lodash';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { AppointmentDetails } from './view';
import { internalApi } from '../../../internal-api';

export default compose(
  withStateFetchersOnMount({
    getAppointment: {
      handler: ({ location: { query }, params }) => async () => {
        const nsApi = internalApi.namespace(params.namespaceName);
        const appointment = await nsApi.appointments.getFullAppointment({
          id: params.id,
          npi: query.npi,
        });
        const patient = await nsApi.patients.getPatient({ id: appointment.patientId });

        return {
          ...appointment,
          patient,
        };
      },

      resultPropName: 'appointment',
      defaultValue: {},
      isReady: ({ appointment }) => !isEmpty(appointment),
    },
  }),
)(AppointmentDetails);
