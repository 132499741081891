import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ChatMessageType from './chatMessageType';

const getDisplayTime = time => moment(time).calendar(null, { sameElse: 'L LT' });
const ChatMessage = ({ message: { text, isIncoming, sentAt } }) => {
  const placeholderComponent = <div className="chat-message-placeholder" key={1} />;
  const textComponent = (
    <div
      className={classnames('chat-message-text-container', {
        incoming: isIncoming,
      })}
      key={2}
    >
      {text}
    </div>
  );
  const timestampComponent = (
    <div className="chat-message-timestamp" key={3}>
      {getDisplayTime(sentAt)}
    </div>
  );

  return (
    <div className="chat-message-row">
      {isIncoming
        ? [textComponent, timestampComponent, placeholderComponent]
        : [placeholderComponent, timestampComponent, textComponent]}
    </div>
  );
};
ChatMessage.propTypes = {
  message: ChatMessageType.isRequired,
};

export default ChatMessage;
