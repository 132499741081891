import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '../../../../../../../../storybook/src/components/atoms/Button/Button';
import { withInfoAlert } from '../../investigationUtils/alerts';

const withClinicInfoAlert = withInfoAlert(({ providerCSI: { clinicMatchingData }, goToClinic }) => {
  const matchedClinic = clinicMatchingData.foundClinic;

  if (!isEmpty(matchedClinic))
    return (
      <div className="actionable-info">
        <div className="info-container">
          <h4>Matched clinic info</h4>
          <span>
            Name: <strong>{matchedClinic.officeName}</strong>
          </span>
          <span>
            Address: <strong>{matchedClinic.address}</strong>
          </span>
          <span>
            Id: <strong>{matchedClinic.id}</strong>
          </span>
        </div>
        <Button type="small" onClick={() => goToClinic(matchedClinic.id)}>
          Go to clinic
        </Button>
      </div>
    );
  return undefined;
});

export default withClinicInfoAlert;
