import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, setDisplayName, withStateHandlers } from 'recompose';
import { Field, Form } from 'formik';
import { object, string } from 'yup';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { csvTypes, fullStateClinicsCsvName } from './csvTypes/csvTypes';
import injectNotification from '../../store/notification/injectNotification';
import Payers from '../../model/enum/bkmdPayers';
import SpinnerButton from '../../components/ui/spinner/spinnerButton';
import { WrappedFormik } from '../../components/form/formik/wrappedFormik';
import { CleanFormikSelect } from '../../components/form/formik/cleanFormikSelect';
import CleanCheckbox from '../../components/form/cleanCheckbox';
import Api from '../../api';
import { withStateFetchers } from '../../api/injectApi/withStateFetchers';
import { withStateFetchersOnMount } from '../../api/injectApi/withStateFetchersOnMount';

function getValidationScheme(isProviderSurface, typeValue) {
  const payer = !isProviderSurface
    ? string()
        .required()
        .label('Payer')
    : null;

  const healthSystem =
    typeValue === fullStateClinicsCsvName
      ? string()
          .required()
          .label('Health System')
      : null;
  return object().shape({
    payer,
    healthSystem,
  });
}

const modalMessages = defineMessages({
  selectPayers: {
    defaultMessage: 'Select payers:',
    id: 'steps.selectPayer.selectPayer',
  },
  errorCreateClinicTitle: {
    defaultMessage: 'Clinic creation failed',
    id: 'clinic.loadClinicFromCSV.errorCreateClinicTitle',
  },
  errorCreateClinicMessage: {
    defaultMessage: 'Error while trying to create a clinic, please contact VIM',
    id: 'clinic.loadClinicFromCSV.errorCreateClinicMessage',
  },
});

const SelectPayers = ({
  onSubmit,
  intl,
  initialValues,
  multiSelect,
  isProviderSurface,
  onProviderSurfaceChange,
  healthSystems,
  typeValue,
}) => (
  <WrappedFormik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={getValidationScheme(isProviderSurface, typeValue)}
    render={({ isValid, isSubmitting, setFieldValue }) => (
      <Form>
        <div>
          <div className="text-16 text-semibold text-dark margin-bottom-clean-form">
            <FormattedMessage {...modalMessages.selectPayers} />
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                name="payer"
                label="Payers"
                component={CleanFormikSelect}
                options={Payers.toSelectOptions(intl)}
                multi={multiSelect}
                disabled={isProviderSurface}
              />
            </div>
            {typeValue === fullStateClinicsCsvName && (
              <div className="col-xs-12">
                <Field
                  name="healthSystem"
                  label="Health System"
                  component={CleanFormikSelect}
                  options={healthSystems}
                  multi={false}
                />
              </div>
            )}
            <div className="col-xs-12">
              <CleanCheckbox
                name="shouldSendLegalDocuments"
                className="left-checkbox-padding margin-top-10"
                id="should-send-legal-documents"
                value={isProviderSurface}
                label="Check the box if you're loading providers for use on a provider surface"
                onChange={() => {
                  onProviderSurfaceChange();
                  setFieldValue('payer', []);
                }}
              />
            </div>
          </div>

          <div className="row margin-bottom-clean-form">
            <div className="col-xs-12">
              <SpinnerButton
                className="btn btn-big btn-min-100 bg-color-brand-button"
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                Next
              </SpinnerButton>
            </div>
          </div>
        </div>
      </Form>
    )}
  />
);

SelectPayers.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  multiSelect: PropTypes.bool.isRequired,
  isProviderSurface: PropTypes.bool.isRequired,
  onProviderSurfaceChange: PropTypes.func.isRequired,
  healthSystems: PropTypes.array,
  typeValue: PropTypes.string.isRequired,
};

SelectPayers.defaultProps = {
  initialValues: null,
  multiSelect: false,
  healthSystems: [],
};

export default compose(
  setDisplayName('SelectPayer'),
  pure,
  injectIntl,
  injectNotification,
  withStateHandlers(
    {
      isProviderSurface: false,
    },
    {
      onProviderSurfaceChange: ({ isProviderSurface }) => () => ({
        isProviderSurface: !isProviderSurface,
        payer: [],
      }),
    },
  ),
  withStateFetchers({
    onSubmit: {
      handler: ({
        clinicIdArray,
        validProviders,
        typeValue,
        errorColumnsOrder,
        errors,
        clinics,
      }) => async ({ payer, healthSystem }) => {
        if (errorColumnsOrder) {
          return { errorColumnsOrder };
        }
        return csvTypes.getType(typeValue).onSubmit({
          payer,
          clinics,
          healthSystemId: healthSystem && healthSystem.value,
          clinicIdArray,
          validProviders,
          errorColumnsOrder,
          errors,
        });
      },
      onSuccess: (
        {
          control,
          validProviders,
          invalidProviders,
          typeValue,
          clinicIdArray,
          errors,
          clinicsCreated,
          errorColumnsOrder,
        },
        result,
      ) =>
        control.next({
          payload: {
            created: _.get(result.data, 'clinicsCreated'),
            updated: _.get(result.data, 'clinicsUpdated'),
            deleted: _.get(result.data, 'clinicsDisabled'),
            clinicIdArray,
            errorsClinics: _.get(result.data, 'errors'),
            errors,
            validProviders,
            invalidProviders,
            loadedClinicsProviders: result,
            typeValue,
            errorColumnsOrder,
          },
        }),
      onError: ({ notification }) =>
        notification.error(
          modalMessages.errorCreateClinicTitle,
          modalMessages.errorCreateClinicMessage,
        ),
    },
  }),
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: () => async () =>
        Api.clinicsApi
          .getAllHealthSystems()
          .then(results => _.get(results, 'data'))
          .map(hs => ({ value: hs.id, label: hs.name })),
      resultPropName: 'healthSystems',
      isReady: ({ healthSystems }) => !_.isEmpty(healthSystems),
    },
  }),
)(SelectPayers);
