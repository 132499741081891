/* eslint-disable import/first, react/jsx-filename-extension */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import appointmentRequestList from './appointmentRequestList';
import appointmentRequestPage from './appointmentRequestPage';
import AllowedProvidersList from './allowedProviders/allowedProvidersList';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PHI_ACCESS } from '../../../../model/enum/aclResourceType';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'appointmentRequests',
  name: 'appointmentRequests',
  childRoutes: [
    {
      path: 'allowedProviders/list',
      name: 'Allowed Providers List',
      component: AllowedProvidersList.default || AllowedProvidersList,
    },
    {
      path: ':id',
      name: 'appointmentRequestPage',
      component: appointmentRequestPage.default || appointmentRequestPage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(divComponent),

  indexRoute: {
    name: 'appointmentRequests',
    component: appointmentRequestList.default || appointmentRequestList,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
