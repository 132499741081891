/**
 * Created by chenrozenes on 03/01/2018.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import CleanInput from '../../../../../../components/form/cleanInput';
import {
  addClinicSubscriber,
  ADD_CLINIC_SUBSCRIBER,
} from '../../../../../../store/backoffice/clinicManagement/clinics/actions';

@autobind
class AddSubscriberAction extends React.Component {
  static propTypes = {
    clinicId: PropTypes.number.isRequired,
    addClinicSubscriber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      npi: null,
    };
  }

  onUserIdChange(e) {
    const val = e.target.value;
    const userId = _.isEmpty(val) ? null : val;
    this.setState({ userId });
  }

  onNpiChange(e) {
    const val = e.target.value;
    const npi = _.isEmpty(val) ? null : val;
    this.setState({ npi });
  }

  onSubmit() {
    this.props.addClinicSubscriber(this.props.clinicId, this.state.userId, this.state.npi);
    return true;
  }

  render() {
    return (
      <Action
        name="addClinicSubscriber"
        title="Add clinic subscriber"
        actionTrackerId={ADD_CLINIC_SUBSCRIBER.SOURCE}
        onSubmit={this.onSubmit}
      >
        <div className="row">
          <div className="col-xs-12">
            <CleanInput
              value={this.state.userId}
              inputClassName="text-left"
              label="User Id"
              onChange={this.onUserIdChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <CleanInput
              value={this.state.npi}
              inputClassName="text-left"
              label="Npi - for notifications on specific npi (optional)"
              onChange={this.onNpiChange}
            />
          </div>
        </div>
      </Action>
    );
  }
}

export default connect(
  null,
  { addClinicSubscriber },
)(AddSubscriberAction);
