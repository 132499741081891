/**
 * Created by chenrozenes on 25/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { getPermissions } from './selectors';
import { validatePermissions } from './util';
import { READ } from '../../model/enum/permissionLevel';
import AclGuard from './aclGuard';

/**
 * Component wrapper that verified that the user has acl permissions to access a page
 * In case access is disallowed, the component will navigate to the unauthorized page
 *
 * This wrapper assumes that your page is already wrapped in AuthenticatedComponent
 *
 * @param permission - permission level needed
 * @param resourceType - resource type that we wish to protect
 * @param unauthorizedPath - override default path to unauthorized page
 * @returns {WrapSubscribeComponent}
 */
export default function requireAccess({
  permission = READ,
  resourceType,
  unauthorizedPath = '/unauthorized'
}) {
  if (!resourceType) throw new Error('Must provide resourceType to LimitedAccessComponent');

  return function Wrap(Component) {
    @autobind
    class LimitedAccessComponent extends React.Component {
      static propTypes = {
        permissions: PropTypes.array,
        router: PropTypes.object.isRequired,
      };

      static defaultProps = {
        permissions: null
      };

      componentWillMount() {
        this.checkPermissions(this.props.permissions);
      }

      componentWillReceiveProps(nextProps) {
        this.checkPermissions(nextProps.permissions);
      }

      checkPermissions(permissions) {
        if (permissions && !validatePermissions(permissions, permission, resourceType)) {
          this.props.router.replace(unauthorizedPath);
        }
      }

      render() {
        return (
          <AclGuard permission={permission} type={resourceType}>
            <Component {..._.omit(this.props, 'permissions')} />
          </AclGuard>
        );
      }
    }

    return compose(
      withRouter,
      connect(state => ({
        permissions: getPermissions(state)
      }))
    )(LimitedAccessComponent);
  };
}
