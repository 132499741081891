/**
 * Created by guyavarham on 13/12/2017.
 */

import Joi from 'joi-browser';
import { formValueSelector } from 'redux-form';

import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';
import NpiTypeahead from '../../../../../components/npi/npiTypeaheadWrapper';
import FieldWrapper from '../../../../../components/form/reduxForm/fieldWrapper';
import LocationSelectByNpi from '../../components/npi/locationSelectByNpi';

export const name = 'bo-create-review';
export const npiSelector = formValueSelector(name);

export const fields = {
  providerNpi: {
    name: 'providerNpi',
    component: FieldWrapper(NpiTypeahead),
    className: 'margin-bottom-clean-form',
  },
  locationId: {
    name: 'locationId',
    component: LocationSelectByNpi,
    className: 'margin-bottom-clean-form',
    npiSelector: state => npiSelector(state, 'providerNpi'),
  },
  domainMemberId: {
    name: 'domainMemberId',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  providerNpi: Joi.string().required(),
  locationId: Joi.number().required(),
  domainMemberId: Joi.string().required(),
};

export const mockForCreate = {
  providerNpi: '',
  locationId: '',
  domainMemberId: '',
};
