/**
 * Created by asafdav on 08/2/2017
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { unlockUser } from '../../../../../store/backoffice/users/actions';

@autobind
class UnlockACtion extends React.PureComponent {
  static propTypes = {
    unlockUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: null,
  };

  callAction() {
    this.props.unlockUser(this.props.userId);
    return true;
  }

  render() {
    return (
      <button
        onClick={this.callAction}
        className="btn btn-small btn-blue text-semibold action-button"
      >
        Unlock
      </button>
    );
  }
}

export default connect(
  () => ({}),
  {
    unlockUser,
  },
)(UnlockACtion);
