import React from 'react';
import requireAutherization from 'Store/auth/limitedAccessComponent';
import { BACK_OFFICE_FEATURES } from 'Model/enum/aclResourceType';
import FeatureFlagsPage from './featureFlagsPage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'features',
  name: 'features',

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_FEATURES })(divComponent),

  indexRoute: {
    name: 'features',
    component: FeatureFlagsPage.default || FeatureFlagsPage,
  },
};
/* eslint-enable import/first, react/jsx-filename-extension */
