import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import { defineMessages } from 'react-intl';
import EntityForm from '../../components/entityForm/entityForm';
import * as ClinicFormDefs from './clinicFormDefs';
import {
  DELETE_CLINIC,
  deleteClinic,
  getClinicById,
  sendOnboardingMailToClinics,
  updateClinic,
} from '../../../../../store/backoffice/clinicManagement/clinics/actions';
import { getClinicMapping } from '../../../../../store/backoffice/clinicManagement/emr/actions';
import { getClinicMapping as selectClinicMapping } from '../../../../../store/backoffice/clinicManagement/emr/selectors';
import * as selectors from '../../../../../store/backoffice/clinicManagement/clinics/selectors';
import AclGuard from '../../../../../store/auth/aclGuard';
import { SUPER_ADMIN, WRITE } from '../../../../../model/enum/permissionLevel';
import {
  BACK_OFFICE_CLINICS,
  MICROSERVICE_ACCESS,
} from '../../../../../model/enum/aclResourceType';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { clinicType as type } from '../permissions/permissionMapper';
import ImportAppointmentsAction from './actions/importAppointmentsAction';
import DeleteClinicAction from './actions/deleteClinic';
import Api from '../../../../../api';

import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import FlaggedRender from '../../../../../utils/FlaggedRender';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';

const messages = defineMessages({
  facilityUpdateError: {
    defaultMessage: 'Error occurred updating facility entities',
    id: 'backpack.facilityUpdateError.submit',
  },
  facilityNoNpiError: {
    defaultMessage: 'NPI is needed for Facility entities creation',
    id: 'backpack.facilityUpdate.submit',
  },
  onboardingMailSuccess: {
    defaultMessage: 'Sent successfully',
    id: 'backpack.clinic.onboardingMailSuccess',
  },
  onboardingMailFail: {
    defaultMessage: 'Sending failed',
    id: 'backpack.clinic.onboardingMailFail',
  },
});

@autobind
class UpdateClinic extends React.Component {
  static propTypes = {
    deleteClinic: PropTypes.func.isRequired, // from router
    deleteTracker: PropTypes.object.isRequired, // from router
    params: PropTypes.object.isRequired, // from router
    updateClinic: PropTypes.func.isRequired, // from redux
    handleFacilityEntities: PropTypes.func.isRequired, // from redux
    getClinicById: PropTypes.func.isRequired, // from redux
    getClinicMapping: PropTypes.func.isRequired, // from redux
    sendOnboardingMail: PropTypes.func.isRequired, //
    mapping: PropTypes.object.isRequired,
    clinic: PropTypes.object.isRequired, // from redux
    router: PropTypes.object.isRequired,
    healthSystems: PropTypes.object.isRequired,
    allowDeleteClinic: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    if (this.props.params.id) {
      this.props.getClinicById(this.props.params.id);
      this.props.getClinicMapping(this.props.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.props.router.push('/secure/clinics/');
    }
  }

  deleteClinic() {
    const { clinic, deleteClinic } = this.props;
    deleteClinic(clinic.id);
  }

  updateIsDeletedClinic() {
    const { clinic, updateClinic } = this.props;
    updateClinic({
      ...clinic,
      isDeleted: !clinic.isDeleted,
    });
  }

  async goToClinicFacility() {
    const {
      clinic,
      router,
    } = this.props;

    if (clinic.facilityResource) {
      router.push(`/secure/clinics/${clinic.id}/providers/${clinic.facilityResource.id}`);
    }
  }

  goToEmrMapping() {
    const { clinic, router } = this.props;
    router.push(`/secure/clinics/${clinic.id}/emr`);
  }

  goToSearchPreferences() {
    const { clinic, router } = this.props;
    router.push(`/secure/healthSystems/searchPreferences/clinic/${clinic.id}`);
  }

  renderActions() {
    const { clinic, mapping, allowDeleteClinic, sendOnboardingMail } = this.props;
    const resourceId = clinic.id;
    return (
      <div>
        <AclGuard type={BACK_OFFICE_CLINICS} permission={WRITE}>
          <div className="action-group">
            <div className="backoffice-action backoffice-extra-padding">
              <button
                onClick={() => this.props.router.push('/secure/clinics/')}
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Back To Clinics
              </button>
              <button
                onClick={() => this.props.router.push(`/secure/clinics/${clinic.id}/providers`)}
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Show Clinic׳s Providers
              </button>
              {!!this.props.clinic.facilityResource && (
                <button
                  onClick={() => this.goToClinicFacility()}
                  className="btn btn-small btn-blue text-semibold action-button margin-13"
                >
                  Show Facility Resource
                </button>
              )}
              <button
                onClick={this.goToEmrMapping}
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Clinic׳s EMR mapping
              </button>
              <ImportAppointmentsAction mapping={mapping} clinicId={clinic.id} />
              <button
                onClick={() => this.props.router.push(`/secure/clinics/${clinic.id}/calendars`)}
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Show Clinic׳s Calendars
              </button>
              <button
                onClick={() =>
                  this.props.router.push(`/secure/clinics/permissions/${type}/${resourceId}`)
                }
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Clinic׳s Permissions
              </button>
              <button
                onClick={this.goToSearchPreferences}
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Search Preferences
              </button>

              <AclGuard type={MICROSERVICE_ACCESS} permission={WRITE} id="SCHEDULING">

              <button
                onClick={
                  sendOnboardingMail
                }
                className="btn btn-small btn-blue text-semibold action-button margin-13"
              >
                Send Onboarding mail
              </button>
              </AclGuard>
              <button
                onClick={this.updateIsDeletedClinic}
                className="btn btn-small btn-red text-semibold action-button margin-13"
              >
                {clinic.isDeleted ? 'Enable clinic' : 'Soft delete clinic'}
              </button>
              <AclGuard type={BACK_OFFICE_CLINICS} permission={SUPER_ADMIN}>
                <FlaggedRender shouldRender={allowDeleteClinic}>
                  {() => <DeleteClinicAction id={clinic.id} />}
                </FlaggedRender>
              </AclGuard>
            </div>
          </div>
        </AclGuard>
        <div className="backoffice-action">
          <button
            onClick={() => this.props.router.push(`/secure/clinics/${clinic.id}/notifications`)}
            className="btn btn-small btn-blue text-semibold action-button"
          >
            Notifications
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { clinic, updateClinic, healthSystems, notification,
    } = this.props;

    return (
      <div>
        {this.renderActions()}
        <hr />
        <EntityForm
          name={ClinicFormDefs.name}
          title="Update clinic:"
          submitText="Update"
          schemaDef={ClinicFormDefs.schema}
          fieldsDef={ClinicFormDefs.fields(healthSystems.data)}
          entity={clinic}
          onSubmit={data => {
            if (data.supportsFacilityBooking && !data.npi) {
              notification.error('', messages.facilityNoNpiError);
            } else {
              updateClinic(data);
            }
          }}
        />
      </div>
    );
  }
}

export default compose(
  withFeatureFlag({
    key: 'backpack.allowDeleteClinic',
    prop: 'allowDeleteClinic',
    defaultValue: false,
  }),
  withRouter,
  withStateFetchersOnMount({
    getHealthSystems: {
      handler: () => () => Api.clinicsApi.getAllHealthSystems(),
      resultPropName: 'healthSystems',
      isReady: ({ healthSystems }) => !_.isEmpty(healthSystems),
    },
  }),
  actionTracker({
    deleteTracker: DELETE_CLINIC.SOURCE,
  }),
  connect(
    state => ({
      clinic: selectors.getClinic(state),
      mapping: selectClinicMapping(state),
    }),
    { updateClinic, getClinicById, deleteClinic, getClinicMapping,
    },
  ),
  withFetchers({
    sendOnboardingMail: {
      handler: ({ clinic }) => ()=> sendOnboardingMailToClinics(clinic.id.toString()),
      onSuccess: ({ notification }) => {
        notification.success('', messages.onboardingMailSuccess);
      },
      onError: ({ notification }, err) => {
        notification.error('', `Error Sending Mail: ${err.message}`);
      },
    }}),
)(UpdateClinic);
