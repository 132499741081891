import _ from 'lodash';
import moment from 'moment';
import PromiseActionType from '../../promiseActionType';
import Formatter from '../../../utils/formatter';
import { displayProviderObj } from '../../../utils/displayProvider';
import commonFormats from '../../../utils/formatter/commonFormats';
import { getDomain } from '../../../utils/domain/storage';
import { internalApi } from '../../../apps/backpack/src/internal-api';

export const GET_APPOINTMENT_REQUEST_BY_ID = new PromiseActionType('GET_APPOINTMENT_REQUEST_BY_ID');
export const CREATE_APPOINTMENT_REQUEST = new PromiseActionType('CREATE_APPOINTMENT_REQUEST');
export const UPDATE_APPOINTMENT_REQUEST = new PromiseActionType('UPDATE_APPOINTMENT_REQUEST');
export const DELETE_APPOINTMENT_REQUEST = new PromiseActionType('DELETE_APPOINTMENT_REQUEST');
export const LIST_APPOINTMENT_REQUESTS = new PromiseActionType('LIST_APPOINTMENT_REQUESTS');
export const ATTACH_APPOINTMENT_REQUEST = new PromiseActionType('ATTACH_APPOINTMENT_REQUEST');
export const CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS = new PromiseActionType(
  'CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS',
);
export const RESEND_EMAIL_REQUEST = new PromiseActionType('RESEND_EMAIL_REQUEST');

export function getAppointmentRequestById(id) {
  return ({ bkmdApi: { appointmentRequestsApi } }) => ({
    type: GET_APPOINTMENT_REQUEST_BY_ID.SOURCE,
    payload: {
      promise: appointmentRequestsApi.getAppointmentRequestById(id).then(res => res.data),
    },
    meta: {
      tracker: GET_APPOINTMENT_REQUEST_BY_ID.SOURCE,
    },
  });
}
export function checkAndUpdateAppointmentRequestStatus(id) {
  return ({ bkmdApi: { appointmentRequestsApi }, dispatch }) => ({
    type: CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS.SOURCE,
    payload: {
      promise: appointmentRequestsApi
        .checkAndUpdateAppointmentRequestStatus(id)
        .then(() => dispatch(getAppointmentRequestById(id))),
    },
    meta: {
      tracker: CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS.SOURCE,
    },
  });
}
export function resendEmailRequest(id, domain) {
  return ({ bkmdApi: { appointmentRequestsApi } }) => ({
    type: RESEND_EMAIL_REQUEST.SOURCE,
    payload: {
      promise: appointmentRequestsApi.resendEmailRequest(id, domain).then(res => res.data),
    },
    meta: {
      tracker: RESEND_EMAIL_REQUEST.SOURCE,
    },
  });
}

export const resendAppointmentAction = async token => {
  const input = { token, subject: 'New Appointment Request from Vim!', source: 'BACKPACK' };
  const result = await internalApi.scheduling.resendAppointmentRequestEmail(input);

  if (result?.success) {
    return Promise.resolve();
  }
  return Promise
    .reject(new Error(result?.error ?? 'Error'));
};

export function updateAppointmentRequest(data) {
  return ({ bkmdApi: { appointmentRequestsApi }, dispatch }) => ({
    type: UPDATE_APPOINTMENT_REQUEST.SOURCE,
    payload: {
      promise: appointmentRequestsApi.updateAppointmentRequest(data).then(() => {
        dispatch(getAppointmentRequestById(data.id));
      }),
    },
    meta: {
      tracker: UPDATE_APPOINTMENT_REQUEST.SOURCE,
    },
  });
}

export function deleteAppointmentRequest(id) {
  return ({ bkmdApi: { appointmentRequestsApi }, dispatch }) => ({
    type: DELETE_APPOINTMENT_REQUEST.SOURCE,
    payload: {
      promise: appointmentRequestsApi.deleteAppointmentRequest(id).then(() => {
        dispatch(getAppointmentRequestById(id));
      }),
    },
    meta: {
      tracker: DELETE_APPOINTMENT_REQUEST.SOURCE,
    },
  });
}

function formatData({ data, count }) {
  const formattedData = _.map(data, appRequest => ({
    ...appRequest,
    memberName: appRequest.patientFullName || Formatter.destructFullName(appRequest.member),
    providerName: appRequest.provider && displayProviderObj(appRequest.provider),
    appointmentTime:
      appRequest.appointment
      && Formatter.date(
        appRequest.appointment.timeZone
          ? moment(appRequest.appointment.startTime).tz(appRequest.appointment.timeZone)
          : appRequest.appointment.startTime,
        commonFormats.DATE_AND_TIME,
      ),
  }));
  return { data: formattedData, count };
}

export function listAppointmentRequests(skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { appointmentRequestsApi } }) => ({
    type: LIST_APPOINTMENT_REQUESTS.SOURCE,
    payload: {
      promise: appointmentRequestsApi
        .getAllAppointmentRequests(getDomain(), skip, limit, search, sort, sortAscending)
        .then(res => formatData(res.data)),
    },
    meta: {
      tracker: LIST_APPOINTMENT_REQUESTS.SOURCE,
    },
  });
}

export function attachAppointmentToRequest(appointmentData) {
  return ({ bkmdApi: { appointmentRequestsApi }, dispatch }) => ({
    type: ATTACH_APPOINTMENT_REQUEST.SOURCE,
    payload: {
      promise: appointmentRequestsApi.attachAppointmentToRequest(appointmentData).then(() => {
        dispatch(getAppointmentRequestById(appointmentData.appointmentRequestId));
      }),
    },
    meta: {
      tracker: ATTACH_APPOINTMENT_REQUEST.SOURCE,
    },
  });
}
