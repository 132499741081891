/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by guyavraham on 15/06/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import List from './templateList';
import UpdateTemplate from './updateTemplate';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'templates',
  name: 'templates',
  childRoutes: [
    {
      path: ':templateId',
      name: 'templatePage',
      component: UpdateTemplate.default || UpdateTemplate,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: divComponent,

  indexRoute: {
    name: 'list',
    component: List.default || List,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
