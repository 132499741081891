/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn, Columns } from '../../../../components/grid';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';
import CreateUserAction from './actions/createUser/createUserAction';

import { getAllUsers } from '../../../../store/backoffice/users/actions';
import * as selectors from '../../../../store/backoffice/users/selectors';

const { ToString, ArrayColumn } = Columns;

@autobind
class UsersList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllUsers: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  onClick(rowData) {
    this.props.router.push(`/secure/users/${rowData.id}`);
  }

  onActionSucceed() {
    const { getAllUsers, skip, limit, search, sort, sortAscending } = this.props;
    getAllUsers(skip, limit, search, sort, sortAscending);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllUsers(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { users, count } = this.props;

    return (
      <div>
        <div className="action-group">
          <CreateUserAction onActionSuccess={this.onActionSucceed} />
        </div>
        <QueryStringGrid
          data={users}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="email" title="Email" />
          <BkmdGridColumn id="tags" title="Tags" customComponent={ArrayColumn} />
          <BkmdGridColumn id="locked" title="Locked" customComponent={ToString} />
          <BkmdGridColumn id="verified" title="Verified" customComponent={ToString} />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  fetchNotEmpty(
    ({ props }) =>
      getAllUsers(props.skip, props.limit, props.search, props.sort, props.sortAscending),
    selectors.getUsersList,
    { renderAlways: false, loading: true },
  ),
  connect(
    state => ({
      users: selectors.getUsersList(state),
      count: selectors.getUsersCount(state),
    }),
    { getAllUsers },
  ),
)(UsersList);
