/**
 * Created by galgoltzman on 27/07/2017.
 */
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import PhoneLink from '../../ui/links/phoneLink';
import commonMessages from '../../../store/intl/commonMessages';

import './blockedAccounts.less';

const messages = defineMessages({
  pageTitle: {
    defaultMessage: 'Your account is locked, please call us.',
    id: 'login.blockedAccount.pageTitle'
  },
  pageMainText: {
    defaultMessage: 'To keep your info safe, we’ve locked your account. There were too many ' +
    'attempts to log in. Please give us a buzz so that we can help.',
    id: 'login.blockedAccount.pageMainText'
  },
});

export default () => (
  <div className="blocked-account-bg">
    <div className="blocked-account-container">
      <div className="blocked-account-content">
        <div className="row">
          <div className="col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3">
            <img src={require('./images/blocked-lock-img.png')} className="top-img" alt="" />
            <div className="title-24 no-margin">
              <strong>
                <FormattedMessage {...messages.pageTitle} />
              </strong>
            </div>
            <div className="text-18 text-light margin-top-30">
              <FormattedMessage {...messages.pageMainText} />
            </div>
            <div className="text-18 margin-top-30">
              <strong>
                <PhoneLink phoneNumber={commonMessages.bookmdPhoneNumber} />
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
