/**
 * Created by igorg on 25/06/2021.
 */

import Immutable from 'seamless-immutable';
import { GET_ALL_ASO_GROUPS } from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
};

export default function reducer(currentState = initialState, action) {
  const state = Immutable(currentState);
  switch (action.type) {
    case GET_ALL_ASO_GROUPS.START:
      return state.set('list', initialState.list);
    case GET_ALL_ASO_GROUPS.SUCCESS:
      return state.set('list', action.payload);
  }

  return state;
}
