/**
 * Created by ofirHartuv on 01/11/17.
 */
import Joi from 'joi-browser';

export const name = 'backoffice-machine';

export const fields = {
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  clinicId: {
    name: 'clinicId',
    readOnly: true,
  },
  machineId: {
    name: 'machineId',
    readOnly: true,
  },
  resourceType: {
    name: 'resourceType',
    readOnly: true,
  },
  name: {
    name: 'name',
    readOnly: false,
  },
  machineType: {
    name: 'machineType',
    readOnly: false,
  },
  manufacturer: {
    name: 'manufacturer',
    readOnly: false,
  },
  model: {
    name: 'model',
    readOnly: false,
  },
  year: {
    name: 'year',
    readOnly: false,
  },
  strength: {
    name: 'strength',
    readOnly: false,
  },
  limitations: {
    name: 'limitations',
    readOnly: false,
  },
};

export const schema = {
  id: Joi.number(),
  clinicId: Joi.number().optional(),
  name: Joi.string().required(),
  machineType: Joi.string().optional(),
  manufacturer: Joi.string().optional(),
  model: Joi.string().optional(),
  year: Joi.string().optional(),
  strength: Joi.number().optional(),
  limitations: Joi.string().optional(),
};
