import React from 'react';
import { branch, renderComponent } from 'recompose';
import { getDomain } from '../../../../utils/domain/storage';

const withDomainRestriction = domainSelector =>
  branch(
    props => domainSelector(props) !== getDomain(true),
    renderComponent(props => (
      <div>
        Please change domain to {domainSelector(props)} from domain {getDomain(true)}
      </div>
    )),
  );

export default withDomainRestriction;
