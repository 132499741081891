/**
 * Created by guyavraham on 05/10/2017
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import Action from '../../components/action';
import { reactivateUser, REACTIVATE_USER } from '../../../../../store/backoffice/users/actions';

@autobind
class ReactivateAction extends React.PureComponent {
  static propTypes = {
    reactivateUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: null,
  };

  callAction() {
    this.props.reactivateUser(this.props.userId);
    return true;
  }

  render() {
    return (
      <Action
        name="reactivation"
        onSubmit={this.callAction}
        actionTrackerId={REACTIVATE_USER.SOURCE}
      >
        <div>
          <div>- Opts in communication</div>
          <div>- Unolcks the user</div>
        </div>
      </Action>
    );
  }
}

export default connect(
  () => ({}),
  {
    reactivateUser,
  },
)(ReactivateAction);
