import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import AddCSVAppointmentTypes from '../appointmentTypes/addCSVAppointmentsTypes/addCSVAppointmentTypes';
import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import AclGuard from '../../../../../store/auth/aclGuard';
import { WRITE } from '../../../../../model/enum/permissionLevel';
import { BACK_OFFICE_CLINICS } from '../../../../../model/enum/aclResourceType';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import LoadClinicFromCsvButton from './actions/loadClinicFromCsvButton';
import * as Columns from '../../../../../components/grid/columns';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../api';
import ToggleButton from '../../../../../storybook/src/components/atoms/ToggleButton/toggleButton';
import withEffects from '../../../../../components/recompose/withEffects';
import LoadTimeSlotsFromCsv from './actions/loadTimeSlotsFromCsv';
import FlaggedRender from '../../../../../utils/FlaggedRender';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';

@autobind
class ClinicsList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getClinics: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  };

  onClick(rowData) {
    this.props.redirectToClinic(rowData.id);
  }

  handleToggleButton(newValue) {
    this.props.setInQueryString({ showOnlyOnboarded: newValue }, true);
  }

  handleIncludeProviderResourcesToggleButton(newValue) {
    this.props.setInQueryString({ includeProviderResources: newValue }, true);
  }

  render() {
    const {
      results: {
        data: { data, count },
      },
      showOnlyOnboarded,
      includeProviderResources,
      allowManageAutoTimeSlots,
    } = this.props;
    return (
      <div>
        <AclGuard type={BACK_OFFICE_CLINICS} permission={WRITE}>
          <div className="backoffice-action">
            <button
              onClick={this.props.redirectToCreateClinic}
              className="btn btn-small btn-blue text-semibold action-button margin-13"
            >
              Create Clinic
            </button>
            <FlaggedRender shouldRender={allowManageAutoTimeSlots}>
              {() => (
                <button
                  onClick={this.props.redirectToManageAutoTimeSlots}
                  className="btn btn-small btn-red text-semibold action-button margin-13"
                >
                  Auto-Created Time Slots [DEV ONLY]
                </button>
              )}
            </FlaggedRender>
            <LoadClinicFromCsvButton onSuccess={this.props.getClinics} />
            <LoadTimeSlotsFromCsv onSuccess={this.props.getClinics} />
            <AddCSVAppointmentTypes />
          </div>
        </AclGuard>
        <hr />
        <div>
          <ToggleButton
            value={showOnlyOnboarded}
            onChange={this.handleToggleButton}
            rightLabel="Include automatically created clinics"
            leftLabel="Show only manually onboarded"
            rightIcon="fa-eye-slash"
            leftIcon="fa-eye"
          />
          <ToggleButton
            value={includeProviderResources}
            onChange={this.handleIncludeProviderResourcesToggleButton}
            rightLabel="Search by clinics' properties"
            leftLabel="Search by provider resources' properties"
            rightIcon="fa-eye-slash"
            leftIcon="fa-eye"
          />
        </div>
        <hr />
        <QueryStringGrid data={data} totalCount={count} onRowClick={this.onClick} allowSearch>
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn
            id="createdAt"
            title="Created At"
            sortField="createdAt"
            customComponent={Columns.DateColumn}
          />
          <BkmdGridColumn id="officeName" title="Office name" />
          <BkmdGridColumn id="state" title="State" />
          <BkmdGridColumn id="city" title="City" />
          <BkmdGridColumn id="addressLine1" title="Address line 1" />
          <BkmdGridColumn id="phone" title="Phone number" customComponent={Columns.PhoneNumber} />
          <BkmdGridColumn id="fax" title="Fax number" customComponent={Columns.PhoneNumber} />
          <BkmdGridColumn
            id="onboarded"
            title="Manually onboarded"
            customComponent={Columns.BooleanColumn}
          />
          <BkmdGridColumn
            id="isDisabled"
            title="Is disabled"
            customComponent={Columns.BooleanColumn}
          />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withFeatureFlag({
    key: 'backpack.allowManageAutoTimeSlots',
    prop: 'allowManageAutoTimeSlots',
    defaultValue: false,
  }),
  withRouter,
  withHandlers({
    redirectToManageAutoTimeSlots: ({ router }) => () =>
      router.push('/secure/clinics/manageAutoCreatedTimeSlots'),
    redirectToCreateClinic: ({ router }) => () => router.push('/secure/clinics/create'),
    redirectToClinic: ({ router }) => clinicId => router.push(`/secure/clinics/${clinicId}`),
  }),
  queryConnect(
    query => ({
      showOnlyOnboarded: query.showOnlyOnboarded === 'true',
      includeProviderResources: query.includeProviderResources === 'true',
      ...gridQueryParams(query),
    }),
    {
      limit: 10,
      skip: 0,
      sort: 'createdAt',
      sortAscending: 'false',
      showOnlyOnboarded: 'true',
      includeProviderResources: 'false',
    },
  ),
  withStateFetchersOnMount({
    getClinics: {
      handler: ({
        params,
        skip,
        limit,
        search,
        sort,
        sortAscending,
        showOnlyOnboarded,
        includeProviderResources,
      }) => () =>
        _.isEmpty(_.get(params, 'healthSystemId'))
          ? Api.clinicsApi.getClinicsPagination(
              skip,
              limit,
              search,
              sort,
              sortAscending,
              showOnlyOnboarded,
              includeProviderResources,
            )
          : Api.clinicsApi.getClinicsByHealthSystem(params.healthSystemId),
      resultPropName: 'results',
      isReady: ({ results }) => !_.isEmpty(results),
    },
  }),
  withEffects({
    fetchClinics: {
      action: props => {
        props.getClinics(props);
      },
      didMountPredicate: () => false,
      didUpdatePredicate: (props, prevProps) => {
        const searchFields = [
          'skip',
          'limit',
          'search',
          'sort',
          'sortAscending',
          'showOnlyOnboarded',
          'includeProviderResources',
        ];
        return !_.isEqual(_.pick(props, searchFields), _.pick(prevProps, searchFields));
      },
    },
  }),
)(ClinicsList);
