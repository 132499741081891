import React from 'react';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import withRowData from 'Components/withRowData/withRowData';
import ToggleButton from '../../../../storybook/src/components/atoms/ToggleButton/toggleButton';

const ToggleButtonCell = ({ onButtonClick, value }) => (
  <ToggleButton value={value} onChange={onButtonClick} />
);

ToggleButtonCell.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  cellProperties: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  value: PropTypes.bool.isRequired,
};

export default compose(
  withRowData,
  withHandlers({
    onButtonClick: ({ rowData, cellProperties: { onClick } }) => (value, e) => {
      e.stopPropagation();
      return onClick(value, rowData);
    },
  }),
)(ToggleButtonCell);
