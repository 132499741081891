import steps from './steps';
import providerInputStep from './steps/providerInputStep';
import investigationPickerStep from './steps/investigationPicker';
import rawDataStep from './steps/rawData';
import namespaceInvestigationStep from './steps/namespaceInvestigation';

export default {
  name: 'ProviderInvestigation',
  definitions: {
    [steps.PROVIDER_INPUT]: providerInputStep,
    [steps.INVESTIGATION_PICKER]: investigationPickerStep,
    [steps.RAW_DATA]: rawDataStep,
    [steps.NAMESPACE_INVESTIGATION]: namespaceInvestigationStep,
  },
};
