import _ from 'lodash';

function extractPrefixFromMemberId(memberId) {
  if (!memberId || memberId.length === 0) return '';

  memberId = _.toUpper(memberId);
  if (memberId.length > 2) {
    const first3 = memberId.substr(0, 3);
    if (/^[A-Z]*$/.test(first3)) return first3;
  }
  if (memberId[0] === 'R') return 'R';

  return '';
}

export default extractPrefixFromMemberId;
