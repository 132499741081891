import React from 'react';
import PropTypes from 'prop-types';
import SimpleNavigationLayout from './simpleNavigationLayout';
import IntlString from '../../../components/ui/intlString/intlString';

const NavigationLayoutWithHeader = props => {
  const {
    title,
    progress,
    img,
    caption,
    children,
    showLogos,
    externalPageLogo,
    showRightNavInMobile,
    onBack,
  } = props;
  return (
    <SimpleNavigationLayout
      className="progress-layout"
      contentClassName="content"
      title={title}
      progress={progress}
      showLogos={showLogos}
      externalPageLogo={externalPageLogo}
      showRightNavInMobile={showRightNavInMobile}
      onBack={onBack}
    >
      <div className="simple-layout-fixed-wrapper">
        <div className="simple-layout-content">
          <div className="top-section">
            <div className="row">
              <div className="col-xs-12 text-center">{img}</div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-center">
                <h1 className="title-24 md-margin title-brand-1">
                  <IntlString message={caption} />
                </h1>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </SimpleNavigationLayout>
  );
};

NavigationLayoutWithHeader.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.bool,
  img: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.element,
  showLogos: PropTypes.bool,
  externalPageLogo: PropTypes.string,
  showRightNavInMobile: PropTypes.bool,
  onBack: PropTypes.func,
};

NavigationLayoutWithHeader.defaultProps = {
  title: undefined,
  progress: false,
  img: undefined,
  caption: undefined,
  children: undefined,
  showLogos: false,
  externalPageLogo: undefined,
  showRightNavInMobile: true,
  onBack: undefined,
};

export default NavigationLayoutWithHeader;
