/**
 * Created by chenrozenes on 31/10/2017.
 */
import Joi from 'joi-browser';
import { PhoneNumberSchema } from '../../../../utils/commonSchema';

export const tokenizeFileSchema = Joi.array().items(
  Joi.object().keys({
    domainMemberId: Joi.string().required(),
  })
    .required()
    .unknown()
);

export const outreachFileSchema = Joi.array().items(
  Joi.object().keys({
    phoneNumber: PhoneNumberSchema,
    email: Joi.string().email(),
  })
    .or('phoneNumber', 'email')
    .required()
    .unknown()
);
