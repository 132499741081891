import * as providersType from './providers';
import * as newClinicsType from './newClinics';
import * as fullStateClinicsType from './fullStateClinics';

export { name as newClinicsCsvName } from './newClinics';
export { name as fullStateClinicsCsvName } from './fullStateClinics';

class CsvTypes {
  constructor() {
    this.csvTypes = {};
  }

  addType(key, typeDefinition) {
    this.csvTypes[key] = typeDefinition;
  }

  getType(key) {
    return this.csvTypes[key];
  }
}

export const csvTypes = new CsvTypes();

newClinicsType.addType(csvTypes);
fullStateClinicsType.addType(csvTypes);
providersType.addType(csvTypes);
