import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  createToggleState,
  createToggleStatePropTypes,
} from 'Components/recompose/toggleStateComponent';
import { withRouter } from 'react-router';
import { defineMessages } from 'react-intl';
import { withHandlers, withPropsOnChange } from 'recompose';
import gql from 'graphql-tag';
import { getDomain } from '../../../../../../utils/domain/storage';
import {
  CANCEL_APPOINTMENT,
  cancelAppointment,
  DELETE_APPOINTMENT,
  deleteAppointment,
  UPDATE_APPOINTMENT,
  updateAppointment,
} from '../../../../../../store/backoffice/clinicManagement/appointment/actions';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import AppointmentForm from './appointmentForm';
import DeleteAppointmentModal from './deleteAppointmentModal';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import { withTranslatedMessages } from '../../../../../../utils/withTranslatedMessages';
import { withFetchersOnMount } from '../../../../../../api/injectApi/withFetchersOnMount';
import Api from '../../../../../../api/index';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { namespaceGraphqlFetcher } from '../../../internal-api/graphql/fetcher';
import { internalApi } from '../../../internal-api';

const messages = defineMessages({
  goToNamespaceAppointment: {
    defaultMessage: 'Go to namespace appointment:',
    id: 'appointmentProductView.goToNamespaceAppointment',
  },
  errorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'appointmentProductView.title',
  },
  errorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'appointmentProductView.msg',
  },
  goToAppointmentActions: {
    defaultMessage: 'Go to appointment actions',
    id: 'appointmentProductView.goToAppointmentActions',
  },
  deleteAppointmentText: {
    defaultMessage: 'Delete Appointment',
    id: 'appointmentProductView.deleteAppointmentText',
  },
});

@autobind
class AppointmentProductView extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    updateAppointment: PropTypes.func.isRequired, // from redux
    deleteAppointment: PropTypes.func.isRequired, // from redux
    getAppointmentByIdWithHealthSystem: PropTypes.func.isRequired, // from redux
    appointmentWithHealthSystem: PropTypes.object.isRequired, // from redux
    updateAppointmentTracker: PropTypes.object.isRequired, // from redux
    ...createToggleStatePropTypes,
  };

  deleteAppointment() {
    const { appointmentWithHealthSystem, deleteAppointment } = this.props;
    const { appointment } = appointmentWithHealthSystem;

    deleteAppointment(appointment.id);
  }

  updateAppointment(updatedData) {
    const { appointmentWithHealthSystem, updateAppointment } = this.props;
    const { appointment } = appointmentWithHealthSystem;
    const updatedAppointment = { ...appointment, ...updatedData };
    updateAppointment(_.omit(updatedAppointment, ['appointmentType', 'provider', 'member']));
  }

  renderActions() {
    const {
      deleteAppointmentModalShow,
      goToNamespaceAppointmentHandler,
      goToNamespaceAppointmentActionsHandler,
      appointmentWithHealthSystem,
      goToNamespaceAppointment,
      goToAppointmentActions,
      deleteAppointmentText,
    } = this.props;
    const { appointment, healthSystem } = appointmentWithHealthSystem;
    const integrationType = _.get(healthSystem, 'integrationType');
    const isNamespaceAppointment = appointment.externalId && integrationType;

    return (
      <div>
        <div className="backoffice-action">
          <Button
            type="small"
            className="btn-blue text-semibold action-button margin-13"
            onClick={deleteAppointmentModalShow}
            text={deleteAppointmentText}
          />
          {isNamespaceAppointment && (
            <Button
              type="small"
              className="btn-blue text-semibold action-button margin-13"
              onClick={goToNamespaceAppointmentHandler}
              text={goToNamespaceAppointment}
            />
          )}
          {integrationType && (
            <Button
              type="small"
              className="btn-blue text-semibold action-button margin-13"
              onClick={goToNamespaceAppointmentActionsHandler}
              text={goToAppointmentActions}
            />
          )}
        </div>
        <hr />
      </div>
    );
  }

  render() {
    const { deleteAppointmentModalOn, deleteAppointmentModalHide, cancelAppointment } = this.props;
    const {
      appointmentWithHealthSystem: { appointment, healthSystem },
      updateAppointmentTracker,
    } = this.props;
    const isLoading = _.get(updateAppointmentTracker, 'inProgress', false);

    return (
      <div>
        {this.renderActions()}
        <hr />
        <div>
          <AppointmentForm
            appointment={appointment}
            healthSystem={healthSystem}
            onSubmit={this.updateAppointment}
            handleCancelAppointment={cancelAppointment}
            isLoading={isLoading}
          />
        </div>
        <DeleteAppointmentModal
          isOpen={deleteAppointmentModalOn}
          onClose={deleteAppointmentModalHide}
          onDeleteAppointment={this.deleteAppointment}
        />
      </div>
    );
  }
}

async function getPatientDataFromNsAppointment(appointment, integration) {
  try {
    const nsApi = internalApi.namespace(integration);
    const apptData = await nsApi.appointments.getFullAppointment({ id: appointment.externalId, npi: appointment.npi });
    return await nsApi.patients.getPatient({ id: apptData.patientId });
  } catch {
    return null;
  }
}

async function getPatientDataFromAppointmentActions(appointmentId, integration) {
  const actions = await namespaceGraphqlFetcher
    .queryOrMutate({
      query: gql`
        query getAppointmentAction($input: GetAppointmentActionInput!) {
          appointmentActions: getAppointmentAction(input: $input) {
            id
            appointmentId
            patientId
            actionType
            status
            npi
            appointmentType
            startTime
            endTime
            reasonForVisit
            ehrLocationId
            firstName
            middleName
            lastName
            dateOfBirth
            mobilePhoneNumber
            memberId
            plan
            insurer
          }
        }
      `,
      operationName: 'getAppointmentAction',
      variables: {
        input: { platformId: appointmentId.toString() },
      },
      context: {
        uri: `${integration}/graphql`,
      },
    })
    .catch(error => {
      return null;
    });

  return actions.length > 0
    ? _.pick(actions[0], [
        'firstName',
        'middleName',
        'lastName',
        'dateOfBirth',
        'mobilePhoneNumber',
        'memberId',
        'plan',
        'insurer',
        'address',
      ])
    : null;
}

async function getPatientData(calendar, healthSystem, appointment) {
  const integrationLevel = _.get(calendar, 'integrationLevel');
  if (integrationLevel === 'INSTANT') {
    return appointment.externalId
      ? getPatientDataFromNsAppointment(appointment, healthSystem.integrationType)
      : getPatientDataFromAppointmentActions(appointment.id, healthSystem.integrationType);
  }
  return null;
}

export default compose(
  injectNotification,
  withRouter,
  createToggleState('deleteAppointmentModal'),
  withTranslatedMessages(messages),
  actionTracker({
    deleteAppointmentTracker: DELETE_APPOINTMENT.SOURCE,
    updateAppointmentTracker: UPDATE_APPOINTMENT.SOURCE,
    cancelAppointmentTracker: CANCEL_APPOINTMENT.SOURCE,
  }),
  connect(() => ({}), { updateAppointment, deleteAppointment, cancelAppointment }),
  withFetchersOnMount(
    {
      getAppointmentByIdWithHealthSystem: {
        handler: ({ params }) => async () => {
          const { appointmentId } = params;
          const domain = getDomain();
          const { data: appointment } = await Api.providerAppointmentsApi.getAppointmentById(
            appointmentId,
            domain,
          );
          const result = { appointment };

          if (appointment.npi && appointment.address) {
            const { data: healthSystem } = await Api.clinicsApi.getHealthSystemByProvider(
              appointment.npi,
              appointment.address,
            );

            if (appointment.calendarId) {
              const { data: calendar } = await Api.calendarsApi.getCalendarById(
                appointment.calendarId,
              );

              if (calendar) {
                const patientData = await getPatientData(calendar, healthSystem, appointment);

                if (patientData) {
                  result.appointment.namespacePatient = patientData;
                }
              }
            }

            result.healthSystem = healthSystem;
          }

          return result;
        },
        resultPropName: 'appointmentWithHealthSystem',
        isReady: ({ appointmentWithHealthSystem }) => !_.isEmpty(appointmentWithHealthSystem),
        onError: ({ notification }) => {
          notification.error(messages.errorTitle, messages.errorMessage);
        },
      },
    },
    true,
  ),
  withHandlers({
    goToProductAppointments: props => () => {
      props.router.push(`/secure/productAppointments/`);
    },
    goToNamespaceAppointmentHandler: props => () => {
      const {
        router,
        appointmentWithHealthSystem: { appointment, healthSystem },
      } = props;

      router.push(
        `/secure/namespaceManager/${healthSystem.integrationType}/appointments/${appointment.externalId}`,
      );
    },
    goToNamespaceAppointmentActionsHandler: props => () => {
      const {
        router,
        appointmentWithHealthSystem: { appointment, healthSystem },
      } = props;

      router.push(
        `/secure/namespaceManager/${healthSystem.integrationType}/appointmentActions/?search=${appointment.id}`,
      );
    },
  }),
  withPropsOnChange(['cancelAppointmentTracker'], props => {
    const { cancelAppointmentTracker, getAppointmentByIdWithHealthSystem, params } = props;

    if (
      cancelAppointmentTracker &&
      cancelAppointmentTracker.finished &&
      !cancelAppointmentTracker.hasError
    ) {
      getAppointmentByIdWithHealthSystem(params.appointmentId);
    }
  }),
  withPropsOnChange(['deleteAppointmentTracker'], props => {
    const { goToProductAppointments, deleteAppointmentTracker } = props;

    if (
      deleteAppointmentTracker &&
      deleteAppointmentTracker.finished &&
      !deleteAppointmentTracker.hasError
    ) {
      goToProductAppointments();
    }
  }),
)(AppointmentProductView);
