/**
 * Created by anna on 14/06/2017.
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import React from 'react';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getProviderLocations } from '../../../../store/backoffice/providers/actions';
import { BkmdGridColumn, Columns, QueryStringGrid } from '../../../../components/grid';
import * as selectors from '../../../../store/backoffice/providers/selectors';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { gridQueryParams } from '../../../../components/grid/queryStringGrid';
import { getDomain } from '../../../../utils/domain/storage';
import { BkmdDomains } from '../../../../model/enum/bkmdDomain';

@autobind
class ProviderLocationsList extends React.Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    locationsCount: PropTypes.number.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    getProviderLocations: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
    sortAscending: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    this.getLocations();
  }

  onRowClick(rowData) {
    this.props.router.push(`/secure/providers/${this.props.params.npi}/locations/${rowData.id}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getProviderLocations(
      this.props.params.npi,
      skip,
      limit,
      search,
      sort,
      sortAscending,
    );
  }

  getLocations() {
    const {
      skip,
      limit,
      search,
      sort,
      sortAscending,
      getProviderLocations,
      params: { npi },
    } = this.props;
    getProviderLocations(npi, skip, limit, search, sort, sortAscending);
  }

  render() {
    const {
      locations,
      locationsCount,
      params: { npi },
    } = this.props;
    return (
      <div>
        <div className="action-group merge-office-group">
          {getDomain(true) === BkmdDomains.VIM ? (
            <Link to={`/secure/providers/${npi}/locations/create`} className="backoffice-action">
              <button className="btn btn-small btn-blue text-semibold small-margin action-button">
                Add Location
              </button>
            </Link>
          ) : null}
        </div>
        <QueryStringGrid
          data={locations}
          totalCount={locationsCount}
          onRowClick={this.onRowClick}
          title="Locations"
          onQueryChange={this.onQueryChange}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="isPrimary" title="Is Primary" />
          <BkmdGridColumn id="addressLine1" title="address Line" />
          <BkmdGridColumn id="city" title="City" />
          <BkmdGridColumn id="state" title="State" />
          <BkmdGridColumn id="zip" title="Zip" />
          <BkmdGridColumn id="phoneNumber" title="Phone number" />
          <BkmdGridColumn id="fax" title="Fax" />
          <BkmdGridColumn id="officeName" title="Office Name" />
          <BkmdGridColumn id="geo" title="geo" />
          <BkmdGridColumn id="hidden" title="hidden" customComponent={Columns.ToString} />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      locations: selectors.getProviderLocations(state),
      locationsCount: selectors.getProviderLocationsCount(state),
    }),
    { getProviderLocations },
  ),
)(ProviderLocationsList);
