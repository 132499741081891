/**
 * created by moran on 11/29/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import { reduxForm } from 'redux-form';
import * as CreateCodeFormDefs from './createCodeFormDefs';
import { createValidator } from '../../../../../utils/joiValidator';
import FieldGenerator from '../../components/entityForm/fieldGenerator';

@autobind
class CreateMedicalCodeForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="row">
          <FieldGenerator
            entity={CreateCodeFormDefs.fields}
            fieldsDef={CreateCodeFormDefs.fields}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: CreateCodeFormDefs.name,
  validate: createValidator(Joi.object().keys(CreateCodeFormDefs.schema)),
})(CreateMedicalCodeForm);
