import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import AclGuard from 'Store/auth/aclGuard';
import { BACK_OFFICE_CLINICS } from 'Model/enum/aclResourceType';
import { WRITE } from 'Model/enum/permissionLevel';
import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid from '../../../../../components/grid/queryStringGrid';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../api';
import LoadSearchPreferencesFromCsv from './actions/loadSearchPreferencesFromCsv';
import { clinicType, healthSystemType } from '../permissions/permissionMapper';

const typeToSetSearchPreferencesFetch = {
  [healthSystemType]: (healthSystemId, isWhitelist, data) =>
    Api.clinicsApi.setSearchPreferencesForHealthSystem(healthSystemId, isWhitelist, data),
  [clinicType]: (clinicId, isWhitelist, data) =>
    Api.clinicsApi.setSearchPreferencesForClinic(clinicId, isWhitelist, data),
};

const typeToGetSearchPreferences = {
  [healthSystemType]: healthSystemId =>
    Api.clinicsApi.getSearchPreferencesForHealthSystem(healthSystemId),
  [clinicType]: clinicId => Api.clinicsApi.getSearchPreferencesForClinic(clinicId),
};

const SearchPreferencesList = ({
  searchPreferences,
  getSearchPreferencesForHealthSystem,
  routeParams: { type, resourceId },
}) => (
  <div>
    <AclGuard type={BACK_OFFICE_CLINICS} permission={WRITE}>
      <div className="backoffice-action">
        <LoadSearchPreferencesFromCsv
          onSuccess={getSearchPreferencesForHealthSystem}
          resourceId={resourceId}
          setSearchPreferences={typeToSetSearchPreferencesFetch[type]}
        />
      </div>
    </AclGuard>
    <hr />
    <QueryStringGrid
      data={searchPreferences}
      totalCount={searchPreferences.length}
      classNames={{ Table: 'bkmd-table auto-layout health-systems-grid' }}
    >
      <BkmdGridColumn id="npi" title="npi" headerCssClassName="bkmd-th name-col" />
      <BkmdGridColumn
        id="whitelist"
        title="whitelist/blacklist"
        customComponent={({ value }) => <span>{value ? 'whitelist' : 'blacklist'}</span>}
        headerCssClassName="bkmd-th name-col"
      />
    </QueryStringGrid>
  </div>
);

SearchPreferencesList.propTypes = {
  searchPreferences: PropTypes.array.isRequired,
  getSearchPreferencesForHealthSystem: PropTypes.func.isRequired,
  routeParams: PropTypes.object.isRequired, // from router
};

export default compose(
  withRouter,
  withStateFetchersOnMount({
    getSearchPreferencesForHealthSystem: {
      handler: ({ routeParams: { type, resourceId } }) => () =>
        typeToGetSearchPreferences[type](resourceId),
      resultPropName: 'searchPreferences',
      defaultValue: [],
    },
  }),
)(SearchPreferencesList);
