import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, branch, setPropTypes, defaultProps, renderComponent } from 'recompose';
import { IntlString, MessageShape } from '../ui/intlString';

const TriggerButton = compose(
  setPropTypes({
    modalShow: PropTypes.func.isRequired,
    btnClassName: PropTypes.string,
    btnIcon: PropTypes.string,
    title: MessageShape,
    buttonText: MessageShape,
    customButton: PropTypes.func,
  }),
  defaultProps({
    customButton: undefined,
    buttonText: undefined,
    btnClassName: 'btn-small btn-blue text-semibold action-button',
    btnIcon: undefined,
    title: '',
  }),
  branch(
    ({ customButton }) => _.isFunction(customButton),
    renderComponent(({ customButton: CustomButton, modalShow }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={modalShow}>
        <CustomButton />
      </div>
    )),
  ),
)(({ btnClassName, modalShow, btnIcon, buttonText, title }) => (
  <button className={classNames('btn', btnClassName)} onClick={modalShow}>
    {btnIcon && (
      <span>
        <i className={btnIcon} />
        &nbsp;
      </span>
    )}
    <IntlString message={buttonText || title} />
  </button>
));

export default TriggerButton;
