import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { reduxForm, Field } from 'redux-form';
import * as CreateUserFormDefs from './createAsoGroupFormDefs';
import { createValidator } from '../../../../../utils/joiValidator';

function CreateAsoGroupForm({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit} method="post">
      <div className="row">
        <div className="col-xs-12">
          <Field label="Group Id" {...CreateUserFormDefs.fields.groupId} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label="Name" {...CreateUserFormDefs.fields.name} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label="Email" {...CreateUserFormDefs.fields.emailAddress} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label="Phone number" {...CreateUserFormDefs.fields.phoneNumber} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label="Website Link" {...CreateUserFormDefs.fields.websiteLink} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field label="Open Hours" {...CreateUserFormDefs.fields.openHours} />
        </div>
      </div>
    </form>
  );
}

CreateAsoGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // from redux-form
};

export default reduxForm({
  form: CreateUserFormDefs.name,
  validate: createValidator(Joi.object().keys(CreateUserFormDefs.schema)),
})(CreateAsoGroupForm);
