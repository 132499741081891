/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withHandlers } from 'recompose';

import FlaggedRender from 'Utils/FlaggedRender';
import { getUser, getAllUsers } from '../../../../store/backoffice/users/actions';
import * as selectors from '../../../../store/backoffice/users/selectors';

import EntityForm from '../components/entityForm/entityForm';
import { BACK_OFFICE_ACL } from '../../../../model/enum/aclResourceType';
import AclGuard from '../../../../store/auth/aclGuard';
import * as UserFormDefs from './userFormDefs';
import ChangePasswordAction from './actions/changePasswordAction';
import UpdateTagAction from './actions/updateTagAction';
import LockAction from './actions/lockAction';
import UnlockAction from './actions/unlockAction';
import DeactivateAction from './actions/deactivateAction';
import ExpirePasswordAction from './actions/expirePasswordAction';
import ReactivateAction from './actions/reactivateAction';
import DeleteUserAction from './actions/deleteUserAction';

@autobind
class UserPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    user: PropTypes.object.isRequired, // from redux
    getUser: PropTypes.func.isRequired, // from redux
    deleteUserFlag: PropTypes.bool.isRequired,
    onDeleteCompleted: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    this.props.getUser(parseInt(this.props.params.id, 10));
  }

  onTagsUpdated() {
    getUser(this.props.user.id);
  }

  render() {
    const { user, deleteUserFlag, onDeleteCompleted } = this.props;
    return (
      <div>
        <h1>{`User: ${user.email}`}</h1>
        <div className="action-group">
          <ChangePasswordAction userId={user.id} />
          <ExpirePasswordAction userId={user.id} />
          <UpdateTagAction userId={user.id} tags={user.tags} onChanged={this.onTagsUpdated} />
          <DeactivateAction userId={user.id} />
          <ReactivateAction userId={user.id} />
          {user.locked ? <UnlockAction userId={user.id} /> : <LockAction userId={user.id} />}
          <AclGuard type={BACK_OFFICE_ACL}>
            <Link to={`/secure/users/${user.id}/permissions`} className="backoffice-action">
              <button className="btn btn-small btn-blue text-semibold small-margin action-button">
                Permissions
              </button>
            </Link>
          </AclGuard>
          <FlaggedRender shouldRender={!_.isNil(user.domainMemberId)}>
            <Link
              to={`/secure/domainMember/${user.domainMemberId}?targetDomain=${user.domain}`}
              className="backoffice-action"
            >
              <button className="btn btn-small btn-blue text-semibold small-margin action-button">
                Domain Member
              </button>
            </Link>
          </FlaggedRender>
          {deleteUserFlag && <DeleteUserAction userId={user.id} onSuccess={onDeleteCompleted} />}
        </div>
        <hr />
        <EntityForm
          name={UserFormDefs.name}
          title="Data:"
          schemaDef={UserFormDefs.schema}
          fieldsDef={UserFormDefs.fields}
          entity={user}
          readOnly
        />
        <hr />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      user: selectors.getUser(state),
      deleteUserFlag: selectors.getDeleteUserFlag(state),
    }),
    { getUser, getAllUsers },
  ),
  withHandlers({
    onDeleteCompleted: ({ router, getAllUsers }) => {
      getAllUsers(0, 10);
      return router.push('/secure/users');
    },
  }),
)(UserPage);
