/**
 * created by moran on 10/31/17
 */
/* eslint-disable import/first, react/jsx-filename-extension */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PROVIDER_PROFILE } from '../../../../model/enum/aclResourceType';
import ProviderProfileList from './providerProfileList';
import ProviderProfilePage from './providerProfilePage';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'providerProfile',
  name: 'providerProfile',
  childRoutes: [
    {
      path: ':id',
      name: 'providerProfile',
      component: ProviderProfilePage.default || ProviderProfilePage,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({
    resourceType: BACK_OFFICE_PROVIDER_PROFILE,
  })(divComponent),

  indexRoute: {
    name: 'providerProfileList',
    component: ProviderProfileList.default || ProviderProfileList,
  },
};

/* eslint-enable import/first, react/jsx-filename-extension */
