import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withProps, withStateHandlers, compose } from 'recompose';
import MultiAutoCompleteSelect from '../molecules/MultiAutoCompleteSelect/MultiAutoCompleteSelect';
import ClickableI from '../clickableElement/clickableI';

import './statefulTypeahead.less';

function Item({ item, remove, labelKey }) {
  return (
    <div className="card icd-code-item">
      <div className="card-body">
        {item[labelKey]}
        <ClickableI className="icon-trash" clickHandler={remove} />
      </div>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
};

export default compose(
  withProps({
    itemComponent: Item,
  }),
  withStateHandlers(
    ({ options, value, idKey }) => {
      const selected = _.filter(options, currOption => _.includes(value, currOption[idKey]));
      return ({
        items: options,
        activeItems: selected,
        initialSelectedItems: selected,
      });
    },
    {
      onInputChange: (state, { options, labelKey }) => changed => {
        const optionsToShow = options.filter(opt =>
          opt[labelKey].toLowerCase().includes(changed.toLowerCase()),
        );
        return { items: optionsToShow };
      },
      onSelectionChange: (state, { onChange = _.noop, options }) => value => {
        onChange(value);
        return { items: options, activeItems: value };
      },
    },
  ),
)(MultiAutoCompleteSelect);
