/**
 * Created by asafdavid on 05/05/2016.
 */

import _ from 'lodash';
import { compose } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from 'react-breadcrumbs';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import Sidebar from './sidebar';
import Header from './header';
import { logout, loginExpired } from '../../../../store/auth/actions';
import { getUser } from '../../../../store/auth/selectors';
import withFeatureFlag from '../../../../utils/featureFlags/withFeatureFlag';
import { auth0Logout } from '../auth/auth0Client';

import './backoffice.less';

@autobind
class BackofficePage extends React.PureComponent {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    auth0LoginFeature: PropTypes.bool,
    samlLoginFeature: PropTypes.bool,
  };

  static defaultProps = {
    auth0LoginFeature: false,
    samlLoginFeature: false,
  };

  logout(e) {
    e.preventDefault();
    if (this.props.auth0LoginFeature) {
      loginExpired();
      auth0Logout();
    } else {
      this.props.logout();
    }
  }

  render() {
    const { children, user, routes, params } = this.props;
    const filteredRoutes = _.filter(routes, route => !_.isEmpty(route.name));
    return (
      <div className="backoffice-container">
        <div className="backoffice-main-layout">
          <Header onLogout={this.logout} user={user} />
          <div className="backoffice-layout-inner">
            <Breadcrumbs excludes={['secure']} routes={filteredRoutes} params={params} />
            <br />
            <Sidebar />
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      user: getUser(state),
    }),
    { logout },
  ),
  withFeatureFlag({
    key: 'vim.backpack.auth0_login_feature',
    prop: 'auth0LoginFeature',
    defaultValue: false,
  }),
  withFeatureFlag({
    key: 'vim.backpack.saml_login_feature',
    prop: 'samlLoginFeature',
    defaultValue: false,
  }),
)(BackofficePage);
