/**
 * created by moran on 11/29/17
 */
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import withRowData from 'Components/withRowData/withRowData';

const DeleteActionButtonCell = ({ rowData, cellProperties: { onClick } }) => (
  <span
    onClick={e => {
      e.stopPropagation();
      return onClick(rowData);
    }}
  >
    <i className="icon-trash i-va-fix-2" />
    &nbsp;
  </span>
);

DeleteActionButtonCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  cellProperties: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(withRowData)(DeleteActionButtonCell);
