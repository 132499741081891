import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanColorInput,
} from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-sdkTheme';

export const fields = {
  name: {
    name: 'name',
    component: ReduxFormCleanInput,
  },
  className: {
    name: 'className',
    component: ReduxFormCleanInput,
  },
  fullName: {
    name: 'fullName',
    component: ReduxFormCleanInput,
  },
  logo: {
    name: 'logo',
    component: ReduxFormCleanInput,
  },
  font: {
    name: 'font',
    component: ReduxFormCleanInput,
  },
  fontWeight: {
    name: 'fontWeight',
    component: ReduxFormCleanInput,
  },
  mainColor: {
    name: 'mainColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  secondaryColor: {
    name: 'secondaryColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  secondaryDarkColor: {
    name: 'secondaryDarkColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  secondaryColorLight: {
    name: 'secondaryColorLight',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  secondaryColorLighter: {
    name: 'secondaryColorLighter',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  buttonColor: {
    name: 'buttonColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  accentColor: {
    name: 'accentColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  linkColor: {
    name: 'linkColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  successColor: {
    name: 'successColor',
    component: ReduxFormCleanColorInput,
    fixedLabel: true,
  },
  buttonBorderRadius: {
    name: 'buttonBorderRadius',
    label: "Button border radius. Required field. '50px' - round buttons, '4px' - default buttons",
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  name: Joi.string().required(),
  className: Joi.string().optional(),
  fullName: Joi.string().optional(),
  logo: Joi.string().optional(),
  font: Joi.string().optional(),
  fontWeight: Joi.string().optional(),
  mainColor: Joi.string().optional(),
  secondaryColor: Joi.string().optional(),
  secondaryDarkColor: Joi.string().optional(),
  secondaryColorLight: Joi.string().optional(),
  secondaryColorLighter: Joi.string().optional(),
  buttonColor: Joi.string().optional(),
  accentColor: Joi.string().optional(),
  linkColor: Joi.string().optional(),
  successColor: Joi.string().optional(),
  buttonBorderRadius: Joi.string().required(),
};
