import { withRouter } from 'react-router';
import { compose, withPropsOnChange, withState } from 'recompose';
import { withTranslatedMessages } from 'Utils/withTranslatedMessages';
import _ from 'lodash';
import injectNotification from 'Store/notification/injectNotification';
import { withStateFetchersOnMount } from '../../../../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../../../../api/injectApi/withFetchers';
import Api from '../../../../../../../api';
import planMappingDetailsMessages from './PlanMappingDetailsMessages';
import PlanMappingForm from '../PlanMappingForm/PlanMappingForm';

const loadPlanMappingHandler = props => () =>
  Api.clinicsApi.findPlanMappingByEhrIdAnd({
    healthSystemId: +props.params.id,
    ehrId: +props.params.ehrId,
  });

const loadPlanMappingError = ({ notification, loadErrorText }) =>
  notification.error('', loadErrorText);

const isPlanMappingReady = ({ getPlanMappingTracker: { executed } }) => executed;

const updatePlanMappingHandler = () => data => {
  if (data.insurer.value) data.insurer = data.insurer.value;

  return Api.clinicsApi.updatePlanMapping({ ...data });
};

const updatePlanMappingSuccess = (
  { notification, updateSuccessText, setEhrPlanMappingData },
  result,
) => {
  notification.success('', updateSuccessText);
  setEhrPlanMappingData(result);
};

const updatePlanMappingError = ({ notification, updateErrorText }) =>
  notification.error('', updateErrorText);

const isUpdatePlanMappingLoading = ({ onSubmitTracker }) => ({
  isLoading: _.get(onSubmitTracker, 'inProgress', false),
});

const loadPlanMappingSuccessHandler = ({ setEhrPlanMappingData }, results) => {
  results.data.insurer = results.data.payerInsurer;
  setEhrPlanMappingData(results);
};

const PlanMappingDetailsContainer = compose(
  withRouter,
  injectNotification,
  withTranslatedMessages(planMappingDetailsMessages),
  withState('ehrPlanMappingData', 'setEhrPlanMappingData', { data: {} }),
  withStateFetchersOnMount({
    getPlanMapping: {
      handler: loadPlanMappingHandler,
      onSuccess: loadPlanMappingSuccessHandler,
      isReady: isPlanMappingReady,
      onError: loadPlanMappingError,
    },
    getInsurers: {
      handler: () => async () => (await Api.eligibilityApi.getInsurers()).data,
      resultPropName: 'insurers',
      isReady: ({ insurers }) => !_.isEmpty(insurers),
    },
  }),
  withFetchers({
    onSubmit: {
      handler: updatePlanMappingHandler,
      onSuccess: updatePlanMappingSuccess,
      onError: updatePlanMappingError,
    },
  }),
  withPropsOnChange(['onSubmitTracker'], isUpdatePlanMappingLoading),
)(PlanMappingForm);

export default PlanMappingDetailsContainer;
