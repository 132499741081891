import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withHandlers } from 'recompose';

import * as Columns from 'Components/grid/columns';
import { loadingUntil } from 'Components/recompose/loadingUntil';
import ThreeBounceSpinner from 'Components/ui/spinner/threeBounceSpinner';
import { BkmdGridColumn } from 'Components/grid';
import injectNotification from 'Store/notification/injectNotification';
import queryConnect from 'Store/tools/queryString/queryConnect';

import Apis from '../../../../api';
import { withStateFetchersOnMount } from '../../../../api/injectApi/withStateFetchersOnMount';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import ToggleButtonCell from '../components/toggleButtonCell';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';
import CreateAsoGroupAction from './createAsoGroup/createAsoGroupAction';

const asoGroups = props => {
  const {
    asoGroups: { data, count },
    onActionSucceed,
    onQueryChange,
    onClick,
    createAsoGroup,
    updateIsActivated,
    getAllAsoGroupsTracker,
  } = props;

  if (getAllAsoGroupsTracker.error)
    return (
      <div>
        <div>Something went wrong.</div>
        <div>Make sure you are in the correct domain.</div>
      </div>
    );

  return (
    <div>
      <div className="action-group">
        <CreateAsoGroupAction createAsoGroup={createAsoGroup} onActionSuccess={onActionSucceed} />
      </div>
      <QueryStringGrid
        data={data}
        totalCount={count}
        onQueryChange={onQueryChange}
        onRowClick={onClick}
        allowSearch
      >
        <BkmdGridColumn id="id" title="Id" />
        <BkmdGridColumn id="name" title="Name" />
        <BkmdGridColumn id="groupId" title="Group Id" />
        <BkmdGridColumn id="phoneNumber" title="Phone" customComponent={Columns.PhoneNumber} />
        <BkmdGridColumn id="emailAddress" title="Email" />
        <BkmdGridColumn id="isActivated" title="Activated" />
        <BkmdGridColumn
          id="isActivated"
          title="Activated"
          sortable={false}
          customComponent={ToggleButtonCell}
          value="isActivated"
          onClick={updateIsActivated}
        />
      </QueryStringGrid>
    </div>
  );
};

asoGroups.propTypes = {
  ...gridQueryParamsProps,
  getAllUsers: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  asoGroups: PropTypes.object,
  getAllAsoGroupsTracker: PropTypes.object.isRequired,
};

asoGroups.defaultProps = {
  asoGroups: {},
};

export default compose(
  withRouter,
  injectNotification,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withStateFetchersOnMount({
    getAllAsoGroups: {
      handler: ({ skip, limit, search, sort, sortAscending }) => () =>
        Apis.asoGroup.getAllAsoGroups(skip, limit, search, sort, sortAscending),
      resultPropName: 'asoGroups',
    },
  }),
  withFetchers({
    updateIsActivated: {
      handler: () => (isActivated, { id, groupId }) =>
        Apis.asoGroup.updateAsoGroup({ id, groupId, isActivated }),
      onSuccess: ({ getAllAsoGroups, notification }) => {
        notification.success('', 'Success updating aso group');
        getAllAsoGroups();
      },
      onError: ({ notification }) =>
        notification.error('', 'Error occurred while updating group activation state'),
    },
    createAsoGroup: {
      handler: () => data =>
        Apis.asoGroup.createAsoGroup({
          ...data,
          websiteLink: _.isEmpty(data.websiteLink) ? null : data.websiteLink,
          openHours: _.isEmpty(data.openHours) ? null : data.openHours,
        }),
      onSuccess: ({ notification, getAllAsoGroups }) => {
        notification.success('', 'Success creating aso group');
        getAllAsoGroups();
      },
      onError: ({ notification }) => notification.error('', 'Error occurred while creating group'),
    },
  }),
  loadingUntil(
    ({ getAllAsoGroupsTracker }) => getAllAsoGroupsTracker.inProgress === false,
    ThreeBounceSpinner,
  ),
  withHandlers({
    onClick: props => rowData => {
      props.router.push(`/secure/asoGroups/${rowData.id}`);
    },
    onActionSucceed: props => () => {
      const { getAllAsoGroups, skip, limit, search, sort, sortAscending } = props;
      getAllAsoGroups(skip, limit, search, sort, sortAscending);
    },
    onQueryChange: props => (skip, limit, search, sort, sortAscending) => {
      props.getAllAsoGroups(skip, limit, search, sort, sortAscending);
    },
  }),
)(asoGroups);
