import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withPropsOnChange, withHandlers } from 'recompose';
import { isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';

import injectNotification from 'Store/notification/injectNotification';
import { createSdkTheme, CREATE_SDK_THEME } from 'Store/backoffice/sdk/actions';
import actionTracker from 'Store/tools/actionTracker/actionTrackerComponent';
import EntityForm from '../../components/entityForm/entityForm';
import * as ApiThemeFormDefs from '../apiThemeFormDefs';

const messages = defineMessages({
  buttonBorderRadiusErrorTitle: {
    defaultMessage: 'Button border radius is required',
    id: 'backpack.sdk.updateTheme.buttomBorderRadiusErrorTitle',
  },
  buttonBorderRadiusError: {
    defaultMessage: 'Use 4px as default',
    id: 'backpack.sdk.updateTheme.buttomBorderRadiusError',
  },
  themeNameError: {
    defaultMessage: 'Name is required',
    id: 'backpack.sdk.updateTheme.themeNameError',
  },
});

const themeMock = {
  name: '',
  className: '',
  fullName: '',
  logo: '',
  font: '',
  fontWeight: '',
  mainColor: '',
  secondaryColor: '',
  secondaryDarkColor: '',
  secondaryColorLight: '',
  secondaryColorLighter: '',
  buttonColor: '',
  accentColor: '',
  linkColor: '',
  successColor: '',
  buttonBorderRadius: '4px',
};

const CreateApiTheme = ({ createSdkTheme }) => (
  <div>
    <EntityForm
      name={ApiThemeFormDefs.name}
      title="Create new API theme:"
      submitText="Create"
      schemaDef={ApiThemeFormDefs.schema}
      fieldsDef={ApiThemeFormDefs.fields}
      entity={themeMock}
      onSubmit={createSdkTheme}
    />
  </div>
);
CreateApiTheme.propTypes = {
  createSdkTheme: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  injectNotification,
  actionTracker({
    tracker: CREATE_SDK_THEME.SOURCE,
  }),
  withPropsOnChange(['tracker'], ({ router, tracker }) => {
    if (tracker.finished && !tracker.hasError) {
      router.push('/secure/apiKeys');
    }
  }),
  connect(
    null,
    { createSdkTheme },
  ),
  withHandlers({
    createSdkTheme: ({ createSdkTheme, notification }) => values => {
      if (isEmpty(values.name)) {
        notification.error('', messages.themeNameError);
      } else if (isEmpty(values.buttonBorderRadius)) {
        notification.error(messages.buttonBorderRadiusErrorTitle, messages.buttonBorderRadiusError);
      } else {
        createSdkTheme(values);
      }
    },
  }),
);

export default enhance(CreateApiTheme);
