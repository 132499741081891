import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../components/action';
import { name as formName } from './createAsoGroupFormDefs';
import CreateUserForm from './createAsoGroupForm';

@autobind
class CreateAsoGroupAction extends React.PureComponent {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Action name="createAsoGroup" title="Create Aso Group" formName={formName} {...this.props}>
        <CreateUserForm onSubmit={this.props.createAsoGroup} />
      </Action>
    );
  }
}

export default CreateAsoGroupAction;
