import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CollapsiblePatient from '../components/collapsiblePatient';
import CollapsibleProvider from '../components/collapsibleProvider';
import * as AppointmentFormDefs from './appointmentProductDef';
import { createValidator } from '../../../../../../utils/joiValidator';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import { displayProviderObj } from '../../../../../../utils/displayProvider';

import './appointmentForm.less';
import CollapsibleEhrPatient from '../components/collapsibleEhrPatient';
import * as AppointmentSource from '../../../../../../model/enum/appointmentSource';

export const messages = defineMessages({
  noEhrPatient: {
    defaultMessage: 'No valid EHR patient info attached to the appointment',
    id: 'collapsibleAppointment.appointment.noEhrPatient',
  },
  noPatient: {
    defaultMessage:
      'No valid patient info (domain member id: {domainMemberId}) attached to the appointment',
    id: 'collapsibleAppointment.appointment.noPatient',
  },
  noProvider: {
    defaultMessage: 'No valid provider info (npi: {npi}) attached to the appointment',
    id: 'collapsibleAppointment.appointment.noProvider',
  },
  appointmentDetails: {
    defaultMessage: 'Appointment details:',
    id: 'collapsibleAppointment.appointment.appointmentDetails',
  },
});

@autobind
class AppointmentForm extends React.Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    healthSystem: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancelAppointment: PropTypes.func.isRequired,
  };

  renderAppointment() {
    const { appointment } = this.props;
    const { appointmentMessages } = AppointmentFormDefs;
    const appointmentType = _.get(appointment, 'appointmentType', null);
    const originAppointmentId = _.get(appointment, 'originAppointmentId');
    const duration = appointmentType ? Math.floor(appointmentType.duration / 60000) : 30;
    return (
      <div className="row padding-h-20">
        <div className="row">
          <div className="col-xs-12 text-16 margin-top margin-bottom">
            <h4>
              <FormattedMessage {...messages.appointmentDetails} />
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <h4>
              <FormattedMessage {...appointmentMessages.id} />
            </h4>
            {appointment.id}
          </div>
          <div className="col-xs-6">
            <h4>
              <FormattedMessage {...appointmentMessages.externalId} />
            </h4>
            <Field
              {...AppointmentFormDefs.fields.externalId}
              labelClassName="margin-bottom-30 inline-block"
              placeholder="---"
            />
          </div>
          <div className="col-xs-6">
            <h4>
              <FormattedMessage {...appointmentMessages.source} />
            </h4>
            {appointment.source}
          </div>
          <div className="col-xs-6">
            <h4>
              <FormattedMessage {...appointmentMessages.memberDomain} />
            </h4>
            {appointment.memberDomain}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 margin-top">
            <h4>
              <FormattedMessage {...appointmentMessages.fullName} />
            </h4>
            {displayProviderObj(appointment.provider)}
          </div>
        </div>
        <br />

        {originAppointmentId && (
          <div className="row margin-bottom-clean-form">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...appointmentMessages.originAppointmentId} />
              </h4>
              {originAppointmentId}
            </div>
          </div>
        )}

        <div className="row margin-bottom-30">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...appointmentMessages.token} />
            </h4>
            {appointment.token}
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 margin-bottom-30">
            {appointment.lastEhrSync ? (
              <Field
                label={appointmentMessages.lastEhrSync}
                {...AppointmentFormDefs.fields.lastEhrSync}
                labelClassName="margin-bottom-30 inline-block"
                timeZone="UTC"
              />
            ) : (
              <FormattedMessage {...appointmentMessages.appointmentNotSynced} />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Field
              label={appointmentMessages.createdAt}
              {...AppointmentFormDefs.fields.createdAt}
              labelClassName="margin-bottom-30 inline-block"
              timeZone="UTC"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Field
              label={appointmentMessages.updatedAt}
              {...AppointmentFormDefs.fields.updatedAt}
              labelClassName="margin-bottom-30 inline-block"
              timeZone="UTC"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label={appointmentMessages.startTime}
              {...AppointmentFormDefs.fields.startTime}
              labelClassName="margin-bottom-30 inline-block"
              timeZone={appointment.timeZone}
              interval={duration}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label={appointmentMessages.endTime}
              {...AppointmentFormDefs.fields.endTime}
              labelClassName="margin-bottom-30 inline-block"
              timeZone={appointment.timeZone}
              interval={duration}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FormattedMessage {...appointmentMessages.status} />
            <Field placeholder={appointment.status} {...AppointmentFormDefs.fields.status} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FormattedMessage {...appointmentMessages.archiveReason} />
            <Field
              placeholder={appointment.archiveReason || ''}
              {...AppointmentFormDefs.fields.archiveReason}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FormattedMessage {...appointmentMessages.requestExpeditedAppointment} />
            <Field
              placeholder={appointment.requestExpeditedAppointment || ''}
              {...AppointmentFormDefs.fields.requestExpeditedAppointment}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <FormattedMessage {...appointmentMessages.originAppointmentId} />
            {appointment.originAppointmentId || ''}
          </div>
        </div>

        {appointmentType && (
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...appointmentMessages.appointmentType} />
              </h4>
              {`${appointmentType.commonName} (${duration} min)`}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderCancelAppointmentButton() {
    const { appointment, isLoading, handleCancelAppointment } = this.props;
    const isFromVim = appointment.source === AppointmentSource.VIM_SDK_BOOK;

    if (!isFromVim) return null;
    return (
      <SpinnerButton
        className="btn btn-big btn-block btn-red padding-h-30 "
        type="button"
        isLoading={isLoading}
        onClick={() => handleCancelAppointment(appointment)}
      >
        Cancel Appointment
      </SpinnerButton>
    );
  }

  render() {
    const { appointment, healthSystem, handleSubmit, isLoading } = this.props;
    const member = _.get(appointment, 'member', null);
    const namespacePatient = _.get(appointment, 'namespacePatient', null);
    const provider = _.get(appointment, 'provider', null);

    return (
      <div className="appointment-card col-sm-6 col-sm-offset-3 margin-bottom-clean-form">
        <form onSubmit={handleSubmit} method="post">
          <div>
            {this.renderAppointment()}
            {namespacePatient && (
              <CollapsibleEhrPatient
                ehrPatient={namespacePatient}
                healthSystem={healthSystem}
                isNamespacePatient
              />
            )}
            {!namespacePatient && (
              <div className="padding-v-10 text-center">
                <FormattedMessage {...messages.noEhrPatient} />
              </div>
            )}
            {member ? (
              <CollapsiblePatient member={member} />
            ) : (
              <div className="padding-v-10 text-center">
                <FormattedMessage
                  {...messages.noPatient}
                  values={{ domainMemberId: appointment.domainMemberId }}
                />
              </div>
            )}
            {provider ? (
              <CollapsibleProvider provider={provider} />
            ) : (
              <div className="padding-v-30 text-center">
                <FormattedMessage {...messages.noProvider} values={{ npi: appointment.npi }} />
              </div>
            )}
          </div>
          <div className="col-sm-6 col-sm-offset-3 margin-bottom-clean-form">
            <SpinnerButton
              className="btn btn-big btn-block padding-h-30 "
              type="submit"
              isLoading={isLoading}
            >
              Update
            </SpinnerButton>
            {this.renderCancelAppointmentButton()}
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  connect((state, props) => ({
    initialValues: props.appointment.asMutable // in case of using as readon
      ? props.appointment.asMutable({ deep: true })
      : props.appointment,
  })),
  reduxForm({
    form: AppointmentFormDefs.name,
    validate: createValidator(AppointmentFormDefs.schema),
  }),
)(AppointmentForm);
