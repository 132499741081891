/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import _ from 'lodash';
import { reduxForm, Field } from 'redux-form';
import * as AddPermissionFormDefs from './addPermissionFormDefs';
import * as PermissionLevel from '../../../../../../model/enum/permissionLevel';
import { createValidator } from '../../../../../../utils/joiValidator';
import { permissionMapper } from '../permissionMapper';

const permissionOptions = function permissionOptions() {
  return _.map(PermissionLevel, (value, label) => ({ value, label }));
};

@autobind
class AddPermissionForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
  };

  resourceTypeOptions() {
    return _.map(permissionMapper[this.props.entityType], value => ({
      value,
      label: value,
    }));
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <Field
          id="promoteOnly"
          label="Promote Only"
          {...AddPermissionFormDefs.fields.promoteOnly}
        />
        <br />
        <br />
        <span>
          When promote only is set, action will not downgrade any existing permissions - if the user
          already has a wider permission, it will not be changed
        </span>
        <hr />
        <div className="row">
          <div className="col-xs-6">
            <Field
              id="permission"
              placeholder="Permission"
              options={permissionOptions()}
              {...AddPermissionFormDefs.fields.permission}
            />
          </div>
          <div className="col-xs-6">
            <Field
              id="resourceType"
              options={this.resourceTypeOptions()}
              placeholder="Resource Type"
              {...AddPermissionFormDefs.fields.resourceType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            Resource Id:
            <Field
              id="resourceId"
              defaultValue={this.props.resourceId}
              placeholder="Resource Id (Required)"
              {...AddPermissionFormDefs.fields.resourceId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <Field
              id="userId"
              placeholder="Id (Required)"
              {...AddPermissionFormDefs.fields.userId}
            />
          </div>
          <div className="col-xs-6">
            <Field
              id="isGroup"
              defaultValue={false}
              simpleSelect
              wrapClass="two-lines-btn margin-bottom-clean-form'"
              btnStyle="btn btn-small btn-pill btn-outline text-semibold"
              horizontal={false}
              equalBtnSize
              marginBetweenOptions={1}
              {...AddPermissionFormDefs.fields.isGroup}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: AddPermissionFormDefs.name,
  validate: createValidator(Joi.object().keys(AddPermissionFormDefs.schema)),
})(AddPermissionForm);
