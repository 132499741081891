import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';

import CreateReviewAction from './actions/createReviewAction';
import * as selectors from '../../../../store/backoffice/reviews/selectors';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { BkmdGridColumn } from '../../../../components/grid';
import CleanSelect from '../../../../components/form/cleanSelect';
import setInQueryString from '../../../../store/tools/queryString/setInQueryString';
import { getAllReviews, GET_ALL_REVIEWS } from '../../../../store/backoffice/reviews/actions';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import * as AppointmentReviewStatus from '../../../../model/enum/appointmentReviewStatus';

const ALL = { label: 'ALL', value: 'ALL' };
const selectOptions = _.map(_.values({ ...AppointmentReviewStatus, ALL: ALL.value }), value => ({
  label: value,
  value,
}));

@autobind
class ReviewsList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    reviews: PropTypes.array.isRequired,
    router: PropTypes.object.isRequired,
    getReviewsTracker: PropTypes.object.isRequired,
    getAllReviews: PropTypes.func.isRequired,
    setInQueryString: PropTypes.func.isRequired,
    reviewsCount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStatus: ALL,
    };
  }

  componentWillMount() {
    const { skip, limit, search, sort, sortAscending } = this.props;
    this.props.getAllReviews(skip, limit, search, sort, sortAscending);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    let options = null;
    if (this.state.currentStatus.label !== ALL.label)
      options = { status: _.get(this.state.currentStatus, 'value', null) };
    this.props.getAllReviews(skip, limit, search, sort, sortAscending, options);
  }

  onClick(rowData) {
    this.props.router.push(`/secure/reviews/${rowData.token}`);
  }

  onShowSubmittedClick(newState) {
    this.setState({ currentStatus: newState });
    const { skip, limit, search, sort, sortAscending } = this.props;
    let options = null;
    if (newState.label !== ALL.label) options = { status: _.get(newState, 'value', null) };
    this.props.getAllReviews(skip, limit, search, sort, sortAscending, options);
  }

  render() {
    const { reviews, reviewsCount, getReviewsTracker } = this.props;
    const isLoading = _.get(getReviewsTracker, 'inProgress', false);
    if (!reviews) return <div />;
    return (
      <div>
        <div className="action-group">
          <CreateReviewAction />
          <br />
          <br />
          <CleanSelect
            id="reviewsCheckBox"
            onChanged={this.onShowSubmittedClick}
            onChange={this.onShowSubmittedClick}
            label="Status filter"
            options={selectOptions}
            value={this.state.currentStatus}
          />
          <span>You can filter by provider npi, full token and the review content</span>
        </div>
        {isLoading ? <ThreeBounceSpinner spinnerClass="va-mid-spinner" /> : null}
        <QueryStringGrid
          data={reviews}
          title="Reviews"
          totalCount={reviewsCount}
          onRowClick={this.onClick}
          onQueryChange={this.onQueryChange}
          allowSearch
        >
          <BkmdGridColumn id="createdAt" title="Created at" />
          <BkmdGridColumn id="updatedAt" title="Updated at" />
          <BkmdGridColumn id="providerNpi" title="Provider Npi" />
          <BkmdGridColumn id="providerName" title="Provider Name" />
          <BkmdGridColumn id="memberName" title="Member Name" />
          <BkmdGridColumn id="location" title="location" />
          <BkmdGridColumn id="rating" title="Rating" />
          <BkmdGridColumn id="review" title="Review" />
          <BkmdGridColumn id="reviewDate" title="Review Date" />
          <BkmdGridColumn id="token" title="Token" />
          <BkmdGridColumn id="status" title="Status" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, {
    limit: 10,
    skip: 0,
    search: '',
    sort: 'updatedAt',
    sortAscending: true,
  }),
  connect(
    state => ({
      reviews: selectors.getReviewsList(state),
      reviewsCount: selectors.getReviewsCount(state),
    }),
    { getAllReviews, setInQueryString },
  ),
  actionTracker({
    getReviewsTracker: GET_ALL_REVIEWS.SOURCE,
  }),
)(ReviewsList);
