/**
 * Created by anna on 26/07/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-reviews-details';

export const fields = {
  review: {
    name: 'review',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  review: Joi.string().allow('', null),
};
