import Joi from 'joi-browser';
import _ from 'lodash';
import PromiseActionType from '../../../promiseActionType';
import { loadCsv } from '../../../../utils/files';
import {
  processBulkAppointments,
  addInsurersToAppointments,
  splitAppointmentsToDomains,
  createRetrysFromReport,
} from '../../../../utils/bulkAppointments';
import { BkmdDomains } from '../../../../model/enum/bkmdDomain';
import { internalApi } from '../../../../apps/backpack/src/internal-api';

export const GET_ALL_CLINICS = new PromiseActionType('GET_ALL_CLINICS');
export const GET_ALL_CALENDARS = new PromiseActionType('GET_ALL_CALENDARS');
export const UPDATE_CLINIC = new PromiseActionType('UPDATE_CLINIC');
export const DELETE_CLINIC = new PromiseActionType('DELETE_CLINIC');
export const CREATE_CLINIC = new PromiseActionType('CREATE_CLINIC');
export const CREATE_CLINICS = new PromiseActionType('CREATE_CLINICS');
export const CLINICS_ON_BOARDING = new PromiseActionType('CLINICS_ON_BOARDING');
export const GET_CLINIC = new PromiseActionType('GET_CLINIC');
export const GET_FACILITY_RESOURCE_FOR_CLINIC = new PromiseActionType(
  'GET_FACILITY_RESOURCE_FOR_CLINIC',
);
export const HANDLE_FACILITY_ENTITIES = new PromiseActionType('HANDLE_FACILITY_ENTITIES');
export const LIST_CLINIC_NOTIFICATIONS = new PromiseActionType('LIST_CLINIC_NOTIFICATIONS');
export const ADD_CLINIC_SUBSCRIBER = new PromiseActionType('ADD_CLINIC_SUBSCRIBER');
export const REMOVE_CLINIC_SUBSCRIBER = new PromiseActionType('REMOVE_CLINIC_SUBSCRIBER');
export const LOAD_CLINIC_APPOINTMENTS_FILE = new PromiseActionType('LOAD_CLINIC_APPOINTMENTS_FILE');
export const CREATE_BULK_APPOINTMENTS = new PromiseActionType('CREATE_BULK_APPOINTMENTS');

export function getClinicsPagination(skip = 0, limit = 10, search, sort, sortAscending) {
  skip = skip || skip === 0 ? skip : 0;
  limit = limit || limit === 0 ? limit : 10;
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: GET_ALL_CLINICS.SOURCE,
    payload: {
      promise: clinicsApi
        .getClinicsPagination(skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export const sendOnboardingMailToClinics = async clinicId => {
  const input = { clinicIds: [clinicId], source: 'BACKPACK' };

  try {
    const result = await internalApi.scheduling.sendOnboardingMailToClinics(input);

    if (result?.clinics?.failed?.length === 0) {
      return Promise.resolve();
    }
    return Promise
      .reject(new Error(result?.clinics?.failed?.[0]?.reason ?? 'Error'));
  } catch (exception) {
    return Promise
      .reject(new Error(exception.errors?.[0]?.message));
  }
};

export async function getFacilityResourceForClinic(api, id) {
  try {
    return await api.getFacilityResourceByClinicId(id);
  } catch (error) {
    return undefined;
  }
}

export function getClinicById(id) {
  return ({ bkmdApi: { clinicsApi, facilityApi } }) => ({
    type: GET_CLINIC.SOURCE,
    payload: {
      promise: (async () => {
        const { data } = await clinicsApi.getClinicById(id);
        const facilityData = await getFacilityResourceForClinic(facilityApi, id);

        return {
          ...data,
          supportsFacilityBooking: !!facilityData,
          facilityResource: facilityData || undefined

        };
      })()
    },
  });
}

export async function handleFacilityEntities(api, clinic, supportsFacilityResource) {
  const res = await api
    .handleFacilityEntities({ clinic, supportsFacilityResource });
  return res.data;
}

export function getAllCalendars(clinicId) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: GET_ALL_CALENDARS.SOURCE,
    payload: {
      promise: clinicsApi.getAllCalendars(clinicId),
    },
  });
}

export function updateClinic(updateData) {
  return ({ bkmdApi: { clinicsApi, facilityApi } }) => ({
    type: UPDATE_CLINIC.SOURCE,
    payload: {
      promise: (async () => {

        const { data: updatedData } = await clinicsApi.updateClinic(_.omit(updateData, 'supportsFacilityBooking', 'facilityResource'));
        if (updateData.facilityResource || updateData.supportsFacilityBooking) {
          await handleFacilityEntities(facilityApi, updatedData, updateData.supportsFacilityBooking);
        }

        const facilityData = await getFacilityResourceForClinic(facilityApi, updatedData.id);
        const supportsFacilityBooking = !!facilityData;
        return {
          ...updatedData,
          facilityResource: facilityData,
          supportsFacilityBooking,

        };
      })(),
    },
  });
}

export function deleteClinic(id) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: DELETE_CLINIC.SOURCE,
    meta: {
      tracker: DELETE_CLINIC.SOURCE,
    },
    payload: {
      promise: clinicsApi.deleteClinic(id).then(res => res.data),
    },
  });
}

export function createClinic(data) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: CREATE_CLINIC.SOURCE,
    meta: {
      tracker: CREATE_CLINIC.SOURCE,
    },
    payload: {
      promise: clinicsApi.createClinic(data).then(res => res.data),
    },
  });
}

export function createClinics(data) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: CREATE_CLINICS.SOURCE,
    meta: {
      tracker: CREATE_CLINICS.SOURCE,
    },
    payload: {
      promise: clinicsApi.createClinics(data).then(res => res.data),
    },
  });
}

export function clinicsOnBoarding(data) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: CLINICS_ON_BOARDING.SOURCE,
    meta: {
      tracker: CLINICS_ON_BOARDING.SOURCE,
    },
    payload: {
      promise: clinicsApi.clinicsOnBoarding(data).then(res => res.data),
    },
  });
}

export function listClinicNotification(clinicId, queryParams = { skip: 0, limit: 10 }) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: LIST_CLINIC_NOTIFICATIONS.SOURCE,
    meta: {
      tracker: LIST_CLINIC_NOTIFICATIONS.SOURCE,
    },
    payload: {
      promise: clinicsApi.getClinicNotifications(clinicId, queryParams),
    },
  });
}

export function addClinicSubscriber(clinicId, userId, npi) {
  return ({ bkmdApi: { clinicsApi }, dispatch }) => ({
    type: ADD_CLINIC_SUBSCRIBER.SOURCE,
    meta: {
      tracker: ADD_CLINIC_SUBSCRIBER.SOURCE,
    },
    payload: {
      promise: clinicsApi.addClinicSubscriber(clinicId, userId, npi).then(() => {
        dispatch(listClinicNotification(clinicId));
      }),
    },
  });
}

export function removeClinicSubscriber(clinicId, userId) {
  return ({ bkmdApi: { clinicsApi }, dispatch }) => ({
    type: REMOVE_CLINIC_SUBSCRIBER.SOURCE,
    meta: {
      tracker: REMOVE_CLINIC_SUBSCRIBER.SOURCE,
    },
    payload: {
      promise: clinicsApi.removeClinicSubscriber(clinicId, userId).then(() => {
        dispatch(listClinicNotification(clinicId));
      }),
    },
  });
}

/**
 * Creates a Joi scheme of string keys based on key mapping object
 * @param {Object} keyMapping
 */
const createStringsScheme = function createScheme(keyMapping) {
  const definition = _.chain(keyMapping)
    .omitBy(_.isEmpty)
    .mapKeys(val => val)
    .mapValues(() => Joi.string().allow(''))
    .value();
  return Joi.array()
    .items(Joi.object().keys(definition))
    .options({ stripUnknown: true });
};

export function loadClinicAppointmentsFromFile(file, mapping) {
  const {
    properties,
    meta: { skip, defaultInsurer },
  } = mapping;
  const schema = createStringsScheme(properties);
  return () => ({
    type: LOAD_CLINIC_APPOINTMENTS_FILE.SOURCE,
    payload: {
      promise: loadCsv(file, schema, true, { from: 1, skip })
        .then(data => processBulkAppointments(data, mapping))
        .then(appointments => addInsurersToAppointments(
          {} /* note: placement for future implementation */,
          appointments,
          defaultInsurer,
        ))
        .then(appointments =>
          // note: we omit Vim here due to its "always add" nature.
          // we only use Vim as fallback.
          splitAppointmentsToDomains(_.omit(BkmdDomains, BkmdDomains.VIM), appointments)),
    },
    meta: {
      tracker: LOAD_CLINIC_APPOINTMENTS_FILE.SOURCE,
    },
  });
}

export function createBulkAppointments(clinicId, appointments) {
  return ({ bkmdApi: { clinicsApi }, getState }) => {
    const {
      config: { csvChunkSize },
    } = getState();
    return {
      type: CREATE_BULK_APPOINTMENTS.SOURCE,
      payload: {
        promise: clinicsApi
          .createBulkAppointments(clinicId, appointments, csvChunkSize)
          .then(async report => {
            const retrys = createRetrysFromReport(BkmdDomains.VIM, report);
            const retryReport = await clinicsApi.createBulkAppointments(
              clinicId,
              retrys,
              csvChunkSize,
            );
            return report.concat(retryReport);
          }),
      },
      meta: {
        tracker: CREATE_BULK_APPOINTMENTS.SOURCE,
      },
    };
  };
}
