/**
 * created by moran on 11/29/17
 */
import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-update-service-code';

export const fields = {
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  key: {
    name: 'key',
    component: ReduxFormCleanInput,
    readOnly: true,
  },
  type: {
    name: 'type',
    component: ReduxFormCleanInput,
    readOnly: true,
  },
  x12ServiceType: {
    name: 'x12ServiceType',
    component: ReduxFormCleanInput,
  },
  commonName: {
    name: 'commonName',
    component: ReduxFormCleanInput,
  },
  medicalName: {
    name: 'medicalName',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  id: Joi.number().optional(),
  createdAt: Joi.string().optional(),
  updatedAt: Joi.string().optional(),
  key: Joi.string().required(),
  type: Joi.string().required(),
  x12ServiceType: Joi.string()
    .allow('', null)
    .optional(),
  commonName: Joi.string().optional(),
  medicalName: Joi.string()
    .allow('', null)
    .optional(),
};
