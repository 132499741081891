/**
 * Created by chenrozenes on 26/04/2017.
 */

/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { compose } from 'redux';
import { Field } from 'redux-form';

import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import Action from '../../components/action';
import CleanPasswordInput from '../../../../../components/form/cleanPasswordInput';
import CleanCheckbox from '../../../../../components/form/cleanCheckbox';
import { changePassword, CHANGE_PASSWORD } from '../../../../../store/backoffice/users/actions';
import * as ChangePasswordForm from '../changePasswordForm';
import { withChangePasswordForm } from '../withChangePasswordForm';

const messages = defineMessages({
  title: {
    defaultMessage: 'Temporary password',
    id: 'backpack.actions.changePasswordAction.title',
  },
  password: {
    defaultMessage: 'Create password',
    id: 'backpack.actions.changePasswordAction.password',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'backpack.actions.changePasswordAction.repeatPassword',
  },
});

@autobind
class ChangePasswordAction extends React.Component {
  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    userId: PropTypes.number,
    newPasswordValidationFeature: PropTypes.bool,
  };

  static defaultProps = {
    userId: null,
    newPasswordValidationFeature: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isTemporaryPassword: false,
    };
  }

  onCheckChange() {
    this.setState(prevState => ({ isTemporaryPassword: !prevState.isTemporaryPassword }));
  }

  onPasswordChange(event) {
    const { value } = event.target;
    this.setState({ value });
  }

  onPasswordStrengthChanged(strength) {
    this.props.change(ChangePasswordForm.fields.passwordStrength.name, strength);
  }

  onSubmit() {
    const { newPasswordValidationFeature, valid } = this.props;

    if (newPasswordValidationFeature) {
      if (!valid) {
        this.form.dispatchEvent(new Event('submit'));
      } else {
        return this.changePassword();
      }
    } else {
      return this.changePassword();
    }
  }

  changePassword = () => {
    const { userId, changePassword } = this.props;
    const { value, isTemporaryPassword } = this.state;

    changePassword(userId, value, isTemporaryPassword);
    this.setState({ value: '' });
    return true;
  };

  render() {
    const { newPasswordValidationFeature, handleSubmit } = this.props;

    return (
      <Action
        name="changePassword"
        title="Change password"
        actionTrackerId={CHANGE_PASSWORD.SOURCE}
        onSubmit={this.onSubmit}
      >
        {!newPasswordValidationFeature ? (
          <CleanPasswordInput
            value={this.state.value}
            showSuggestions
            onChange={this.onPasswordChange}
            withFeatureFlag={newPasswordValidationFeature}
          />
        ) : (
          <form
            onSubmit={handleSubmit(() => {})}
            ref={ref => {
              this.form = ref;
            }}
          >
            <Field
              label={messages.password}
              className="password-input"
              showSuggestions
              onStrengthChanged={this.onPasswordStrengthChanged}
              onChange={this.onPasswordChange}
              withFeatureFlag={newPasswordValidationFeature}
              verifyPasswordField={
                <div>
                  <Field
                    label={messages.repeatPassword}
                    className="password-input"
                    {...ChangePasswordForm.fields.repeatPassword}
                  />
                </div>
              }
              {...ChangePasswordForm.fields.password}
            />
            <Field type="hidden" {...ChangePasswordForm.fields.passwordStrength} />
          </form>
        )}
        <CleanCheckbox
          id="isTemporaryPassword"
          label={messages.title}
          value={this.state.isTemporaryPassword}
          onChange={this.onCheckChange}
        />
      </Action>
    );
  }
}

const fieldNames = [
  ChangePasswordForm.fields.password.name,
  ChangePasswordForm.fields.passwordStrength.name,
  ChangePasswordForm.fields.repeatPassword.name,
];

export default compose(
  connect(null, { changePassword }),
  withChangePasswordForm(fieldNames, ChangePasswordForm.customValidator),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(ChangePasswordAction);
