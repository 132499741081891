import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { withHandlers } from 'recompose';
import _ from 'lodash';

import PaginatedGrid from 'Components/grid/paginatedGrid';
import { EnumComponent, BooleanColumn } from 'Components/grid/columns';
import * as PermissionLevel from '../../../../model/enum/permissionLevel';

import AddPermissionAction from './actions/addPermission/addPermissionAction';
import RemovePermissionAction from './actions/removePermission/removePermissionAction';

const columns = [
  { id: 'resourceType', title: 'Resource Type' },
  { id: 'resourceId', title: 'Resource Id' },
  {
    id: 'permission',
    title: 'Permission',
    customComponent: EnumComponent(_.invert(PermissionLevel)),
  },
  {
    id: 'isGroupPermission',
    title: 'Group Permission',
    customComponent: BooleanColumn,
    sortable: false,
  },
];

@autobind
class PermissionsPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    gridGetPermissions: PropTypes.func.isRequired,
    viewModal: PropTypes.shape({
      getPermissions: PropTypes.func.isRequired,
      clearPermissions: PropTypes.func.isRequired,
      permissionCountSelector: PropTypes.func.isRequired,
      permissionsSelector: PropTypes.func.isRequired,
      isGroup: PropTypes.bool,
    }).isRequired,
  };

  onActionsSuccess() {
    const {
      params: { id },
      viewModal: { getPermissions },
      dispatch,
    } = this.props;
    const userId = parseInt(id, 10);
    dispatch(getPermissions(userId));
  }

  render() {
    const { params, gridGetPermissions, viewModal } = this.props;
    const id = parseInt(params.id, 10);
    return (
      <div>
        <div className="action-group">
          <AddPermissionAction
            onActionSuccess={this.onActionsSuccess}
            id={id}
            isGroup={viewModal.isGroup}
          />
          <RemovePermissionAction
            onActionSuccess={this.onActionsSuccess}
            id={id}
            isGroup={viewModal.isGroup}
          />
        </div>
        <br />
        <PaginatedGrid
          actionCreator={gridGetPermissions}
          clearActionCreator={viewModal.clearPermissions}
          countSelector={viewModal.permissionCountSelector}
          dataSelector={viewModal.permissionsSelector}
          title="Permissions"
          columns={columns}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withHandlers({
    gridGetPermissions: props => (...args) =>
      props.viewModal.getPermissions(props.params.id, ...args),
  }),
)(PermissionsPage);
