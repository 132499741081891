import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router';
import AclGuard from 'Store/auth/aclGuard';
import QueryStringGrid, { gridQueryParams } from 'Components/grid/queryStringGrid';
import { BkmdGridColumn } from 'Components/grid/index';
import { WRITE } from 'Model/enum/permissionLevel';
import { BACK_OFFICE_SDK } from 'Model/enum/aclResourceType';
import { ToString } from 'Components/grid/columns';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './apiKeysPage.less';
import queryConnect from '../../../../../../store/tools/queryString/queryConnect';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../../api';
import ApiConfigurationModal from '../apiConfigurationModal/apiConfigurationModal';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';

const API_KEYS_TAB_INDEX = 0;
const THEMES_TAB_INDEX = 1;

const ApiKeysPage = ({
  apiKeys,
  onClick,
  onThemeClick,
  themes,
  onCreatePermissionClick,
  onCreateThemeClick,
  apiConfigurationModalHide,
  apiConfigurationModalOn,
  activeApiUserRecord,
  onApiKeysQueryChange,
  onThemesQueryChange,
  onTabSelect,
}) => (
  <Tabs onSelect={onTabSelect}>
    <TabList>
      <Tab>API Keys</Tab>
      <Tab>Themes</Tab>
    </TabList>

    <TabPanel>
      <div>
        <AclGuard type={BACK_OFFICE_SDK} permission={WRITE}>
          <button className="margin-bottom-20 margin-top-10" onClick={onCreatePermissionClick}>
            Create API Key
          </button>
        </AclGuard>
        <QueryStringGrid
          data={_.get(apiKeys, 'data.data')}
          totalCount={_.get(apiKeys, 'data.count')}
          onQueryChange={onApiKeysQueryChange}
          onRowClick={onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="ID" />
          <BkmdGridColumn id="apiKey" title="Client API key" />
          <BkmdGridColumn id="serverApiKey" title="Server API Key" />
          <BkmdGridColumn id="tenant" title="Tenant" />
          <BkmdGridColumn id="clientId" title="Client Id" />
          <BkmdGridColumn id="theme" title="Theme" />
        </QueryStringGrid>
        <ApiConfigurationModal
          handleClose={apiConfigurationModalHide}
          isOpen={apiConfigurationModalOn}
          apiUserRecordClientId={_.get(activeApiUserRecord, 'clientId')}
          configurationData={_.get(activeApiUserRecord, 'configuration')}
        />
      </div>
    </TabPanel>
    <TabPanel>
      <div>
        <AclGuard type={BACK_OFFICE_SDK} permission={WRITE}>
          <button className="margin-bottom-20 margin-top-10" onClick={onCreateThemeClick}>
            Create API Theme
          </button>
        </AclGuard>
        <QueryStringGrid
          data={_.get(themes, 'data.data')}
          totalCount={_.get(themes, 'data.count')}
          onQueryChange={onThemesQueryChange}
          onRowClick={onThemeClick}
          allowSearch
        >
          <BkmdGridColumn id="name" title="Name" />
          <BkmdGridColumn id="className" title="className" />
          <BkmdGridColumn id="logo" title="Logo" />
          <BkmdGridColumn id="font" title="Font" />
          <BkmdGridColumn id="fontWeight" title="Font Weight" />
          <BkmdGridColumn id="mainColor" title="Main Color" />
          <BkmdGridColumn id="secondaryColor" title="Secondary Color" />
          <BkmdGridColumn id="secondaryDarkColor" title="Secondary Dark Color" />
          <BkmdGridColumn id="secondaryColorLight" title="Secondary Light Color" />
          <BkmdGridColumn id="secondaryColorLighter" title="Secondary Lighter Color" />
          <BkmdGridColumn id="buttonColor" title="Button Color" />
          <BkmdGridColumn id="accentColor" title="Accent Color" />
          <BkmdGridColumn id="linkColor" title="Link Color" />
          <BkmdGridColumn id="successColor" title="Success Color" />
        </QueryStringGrid>
      </div>
    </TabPanel>
  </Tabs>
);

ApiKeysPage.propTypes = {
  apiKeys: PropTypes.object.isRequired,
  themes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onThemeClick: PropTypes.func.isRequired,
  onCreatePermissionClick: PropTypes.func.isRequired,
  onCreateThemeClick: PropTypes.func.isRequired,
  onApiKeysQueryChange: PropTypes.func.isRequired,
  onThemesQueryChange: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  activeApiUserRecord: PropTypes.object,
  ...createToggleStatePropTypes('apiConfigurationModal'),
};

ApiKeysPage.defaultProps = {
  activeApiUserRecord: undefined,
};

const enhance = compose(
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  createToggleState('apiConfigurationModal'),
  withState('apiKeys', 'setApiKeys', { count: 0, data: [] }),
  withState('themes', 'setThemes', { count: 0, data: [] }),
  withState('activeApiUserRecord', 'setActiveApiUserRecord', null),
  withStateHandlers(
    { apiKeysSkip: 0, themesSkip: 0 },
    {
      onTabSelect: ({ apiKeysSkip, themesSkip }, { skip, setInQueryString }) => index => {
        switch (index) {
          case API_KEYS_TAB_INDEX: {
            setInQueryString({ skip: apiKeysSkip }, false);
            return { themesSkip: skip };
          }
          case THEMES_TAB_INDEX: {
            setInQueryString({ skip: themesSkip }, false);
            return { apiKeysSkip: skip };
          }
          default: {
            return {};
          }
        }
      },
    },
  ),
  withStateFetchersOnMount({
    getAllApiKeys: {
      handler: props => query => {
        const { skip, limit, search, sort, sortAscending } = query || props;
        return Api.sdkApi.getAllSdkPermissions(skip, limit, search, sort, sortAscending);
      },
      onSuccess: ({ setApiKeys }, apiKeys) => setApiKeys(apiKeys),
    },
    getAllThemes: {
      handler: props => query => {
        const { themesSkip, limit, search, sort, sortAscending } = query || props;
        return Api.sdkApi.getAllSdkThemesWithPagination(
          themesSkip,
          limit,
          search,
          sort,
          sortAscending,
        );
      },
      onSuccess: ({ setThemes }, themes) => setThemes(themes),
    },
  }),
  withRouter,
  withHandlers({
    onClick: ({ router }) => permission => {
      router.push(`/secure/apiKeys/${permission.id}`);
    },
    onThemeClick: ({ router }) => theme => {
      router.push(`/secure/apiKeys/themes/${theme.name}`);
    },
    onCreateThemeClick: ({ router }) => () => {
      router.push('/secure/apiKeys/themes/create');
    },
    onCreatePermissionClick: ({ router }) => () => {
      router.push('/secure/apiKeys/create');
    },
    onApiKeysQueryChange: ({ getAllApiKeys }) => (skip, limit, search, sort, sortAscending) =>
      getAllApiKeys({ skip, limit, search, sort, sortAscending }),
    onThemesQueryChange: ({ getAllThemes }) => (skip, limit, search, sort, sortAscending) =>
      getAllThemes({ skip, limit, search, sort, sortAscending }),
    apiConfigurationModalShow: ({
      apiConfigurationModalShow,
      setActiveApiUserRecord,
    }) => rowData => {
      setActiveApiUserRecord(rowData);
      apiConfigurationModalShow();
    },
  }),
);

export default enhance(ApiKeysPage);
