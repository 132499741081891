import _ from 'lodash';

// Fields validators
const validators = {
  phoneNumber: {
    isValid: value => _.isEmpty(value) || value.trim().length === 10,
    getInvalidMessage: columnName => `${columnName} column, if exists, must be a 10 digit number`,
  },
  notEmpty: {
    isValid: value => !_.isEmpty(value),
    getInvalidMessage: columnName => `${columnName} column cannot be empty`,
  },
};

// Fields mappers
const mappers = {
  phoneNumber: number =>
    !_.isEmpty(number)
      ? {
          countryDialingCode: '+1',
          number,
        }
      : null,
};

// Column definition
const columns = {
  addressLine1: {
    name: 'Address Line 1',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  addressLine2: {
    name: 'Address Line 2',
    isRequired: true,
    validator: null,
    mapper: null,
  },
  city: {
    name: 'City',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  state: {
    name: 'State',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  zip: {
    name: 'Zip',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  officeName: {
    name: 'Office Name',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  phone: {
    name: 'Phone',
    isRequired: true,
    validator: validators.phoneNumber,
    mapper: mappers.phoneNumber,
  },
  fax: {
    name: 'Fax',
    isRequired: true,
    validator: validators.phoneNumber,
    mapper: mappers.phoneNumber,
  },
  email: {
    name: 'Email',
    isRequired: true,
    validator: null,
    mapper: null,
  },
  healthSystemId: {
    name: 'Health System ID',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
  ehrLocationId: {
    name: 'EHR Location ID',
    isRequired: true,
    validator: null,
    mapper: null,
  },
  npi: {
    name: 'NPI',
    isRequired: true,
    validator: validators.notEmpty,
    mapper: null,
  },
};

function isValidRow(clinicColumns, currentRow, columnToIgnore) {
  let clinic = {};

  for (const [key, column] of Object.entries(columns)) {
    const columnIndex = clinicColumns.findIndex(cc => cc === column.name);
    if (columnIndex > -1) {
      const value = currentRow[columnIndex];
      if (column.name !== columnToIgnore && column.validator && !column.validator.isValid(value)) {
        const errorMsg = column.validator.getInvalidMessage(column.name);
        const [officeNameIndex, addressIndex] = [
          columns.officeName,
          columns.addressLine1,
        ].map(col => clinicColumns.findIndex(cc => cc === col.name));

        const error = {
          errorMsg,
          officeName: currentRow[officeNameIndex],
          address: currentRow[addressIndex],
        };

        return { error };
      }
      clinic = { ...clinic, [key]: column.mapper ? column.mapper(value) : value };
    }
  }

  return { clinic };
}
function checkDuplicateRows(clinicColumns, clinicRows, i) {
  const sliceArray = _.slice(clinicRows, i + 1);
  if (_.some(sliceArray, slice => _.isEqual(slice, clinicRows[i]))) {
    const [officeNameIndex, addressIndex] = [columns.officeName, columns.addressLine1].map(col =>
      clinicColumns.findIndex(cc => cc === col.name),
    );
    return {
      errorMsg: `Duplicate rows in file , row number ${i}`,
      address: clinicRows[i][addressIndex],
      officeName: clinicRows[i][officeNameIndex],
    };
  }
  return null;
}
function isValidColumns(clinicColumns, columnToIgnore) {
  const requiredColumns = Object.values(columns).filter(
    col => col.isRequired && col.name !== columnToIgnore,
  );
  const missingColumns = requiredColumns.filter(rc => !clinicColumns.includes(rc.name));
  if (missingColumns.length) {
    return `Missing required columns: ${missingColumns.map(mc => mc.name).join(', ')}`;
  }
  return null;
}

export function analyzeClinicsCsvRows(rows, clinicsByNpi = false) {
  const clinics = [];
  const errors = [];
  let error = null;
  const [header, ...clinicsData] = rows;

  const columnToIgnore = clinicsByNpi ? columns.healthSystemId.name : columns.npi.name;

  const errorColumnsOrder = isValidColumns(header, columnToIgnore);
  if (errorColumnsOrder) {
    return { errorColumnsOrder };
  }
  for (let i = 0; i <= clinicsData.length - 1; i++) {
    error = checkDuplicateRows(header, clinicsData, i);
    if (error) {
      errors.push(error);
    } else {
      const clinicRow = clinicsData[i];
      const { clinic, error } = isValidRow(header, clinicRow, columnToIgnore);
      if (error) errors.push(error);
      if (clinic) clinics.push(clinic);
    }
  }
  return { clinics, errors };
}
