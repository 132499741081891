import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import * as selectors from '../../../../../store/backoffice/clinicManagement/providerResources/selectors';
import { getAllProviderResourcesByClinic } from '../../../../../store/backoffice/clinicManagement/providerResources/actions';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

@autobind
class ProviderResourceList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllProviderResourcesByClinic: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    providerResources: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.props.getAllProviderResourcesByClinic(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  onQueryChange() {
    this.props.getAllProviderResourcesByClinic(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  onClick(rowData) {
    const { params } = this.props;
    const providerId = rowData.id;
    this.props.router.push(`/secure/clinics/${params.id}/providers/${providerId}`);
  }

  render() {
    const { providerResources, count, params } = this.props;
    return (
      <div>
        <div className="backoffice-action">
          <button
            onClick={() => this.props.router.push(`/secure/clinics/${params.id}`)}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Back To Clinic
          </button>
          <button
            className="btn btn-small btn-blue text-semibold action-button margin-13"
            onClick={() => this.props.router.push(`/secure/clinics/${params.id}/providers/create`)}
          >
            Create Provider Resource
          </button>
        </div>

        <hr />
        <QueryStringGrid
          data={providerResources}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="clinicId" title="Clinic Id" />
          <BkmdGridColumn id="npi" title="npi" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      providerResources: selectors.getProviderResourcesByClinicList(state),
      count: selectors.getProviderResourcesByClinicCount(state),
    }),
    (dispatch, props) => ({
      getAllProviderResourcesByClinic() {
        dispatch(
          getAllProviderResourcesByClinic(
            props.skip,
            props.limit,
            props.params.id,
            props.sort,
            props.sortAscending,
          ),
        );
      },
    }),
  ),
)(ProviderResourceList);
