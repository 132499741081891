import React from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import NavigationLayout from '../../../components/templates/NavigationLayout/navigationLayout';
import IdentificationForm from '../identification';
import SvgKey from '../../../assets/svg/key';
import withGoBack from '../../../components/router/withGoBack';

const messages = defineMessages({
  forgotYourPassword: {
    defaultMessage: 'Forgot your password?',
    id: 'forgotYourPasswordFlow.forgotYourPassword',
  },
});

const NavigationLayoutWrappedIdentification = props => (
  <NavigationLayout
    withHeader
    onBack={props.onBack}
    caption={messages.forgotYourPassword}
    showLogos={false}
    progress={null}
    img={<SvgKey />}
    showRightNavInMobile
  >
    <IdentificationForm {...props} />
  </NavigationLayout>
);

NavigationLayoutWrappedIdentification.propTypes = {
  onBack: PropTypes.func,
};

NavigationLayoutWrappedIdentification.defaultProps = {
  onBack: undefined,
};

export default compose(
  withGoBack,
  withProps(({ control, goBackFunc }) => ({
    onBack: control.hasPrev() ? control.prev : goBackFunc,
  })),
)(NavigationLayoutWrappedIdentification);
