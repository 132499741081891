import _ from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router';

// this is a temporary component to bridge the fact Atlas has a different version of react-router
const withPush = withPropsOnChange(['router'], props => {
  if (_.isFunction(_.get(props, 'router.push'))) {
    return {
      pushFunc: props.router.push,
    };
  }
  if (_.isFunction(_.get(props, 'router.history.push'))) {
    return {
      pushFunc: props.router.history.push,
    };
  }
  if (_.isFunction(_.get(props, 'history.push'))) {
    return {
      pushFunc: props.history.push,
    };
  }
  return {
    pushFunc: props.push,
  };
});

export default compose(
  withRouter,
  withPush,
);
