/**
 * created by moran on 11/29/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../components/action';
import {
  CREATE_MEDICAL_CODE,
  createMedicalCode,
} from '../../../../../store/backoffice/medicalCodes/actions';
import CreateMedicalCodeForm from './createMedicalCodeForm';
import { name } from './createCodeFormDefs';

@autobind
class CreateProviderProfileAction extends React.PureComponent {
  static propTypes = {
    createMedicalCode: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  callAction(data) {
    this.props.createMedicalCode(data);
  }

  render() {
    return (
      <Action
        name="createMedicalCode"
        title="Create Medical Code"
        actionTrackerId={CREATE_MEDICAL_CODE.SOURCE}
        formName={name}
        onActionSuccess={this.props.onSuccess}
        {...this.props}
      >
        <CreateMedicalCodeForm onSubmit={this.callAction} />
      </Action>
    );
  }
}

export default connect(
  null,
  { createMedicalCode },
)(CreateProviderProfileAction);
