import { compose, withHandlers } from 'recompose';
import withFormikWrapper from '../formik/withFormikWrapper';
import CleanCheckbox from '../cleanCheckbox';

/**
 * CleanCheckbox and CleanSwitch act different because they get 'value' as input and pass it
 * as 'checked' property to the inside input element, but when it execute onChange it pass
 * the inside input onChange event which has the value as 'checked'
 */
export const CleanFormikCheckbox = compose(
  withFormikWrapper(),
  withHandlers({
    onChange: ({ onChange }) => event => {
      onChange(event.target.checked);
    },
  }),
)(CleanCheckbox);
