/**
 * Created by guyavarham on 21/12/2017.
 */

import _ from 'lodash';
import * as Channel from '../../../../../model/enum/communicationChannel';
import * as RecipientType from '../../../../../model/enum/recipientType';
import { dataMappingSelector } from '../../../../../store/backoffice/communication/selectors';
import { memoizedSelectorCreator } from '../../../../../utils/reselectUtils';
import { getEnrichedCurrTemplate } from '../../../../../store/backoffice/communication/templateSelectors';

/**
 * Find the parameter that is needed for a templates dataFamilies requirements.
 * Template.dataFamilies holds list of families.
 * DataMapping[family].params will supply the required param for each family.
 * @param template
 * @param dataMapping
 */
const extractParamsFromTemplate = (template, dataMapping) => {
  const upperCaseDataMapping = _.mapKeys(dataMapping, (val, key) => _.toUpper(key));
  const flattenParmas = _.flattenDeep(
    _.map(template.dataFamilies, family =>
      _.get(upperCaseDataMapping, `${_.toUpper(family)}.params`),
    ),
  );

  return _.filter(flattenParmas, _.isNill);
};

const userIdParamName = 'userId';
const emailParamName = 'email';
const phoneParamName = 'phoneNumber';
const domainParamName = 'domain';

/**
 * Add domain for emails.
 * Add userId For USER Recipient, and phone or email for INLINE
 * @param selectedRecipientType
 * @param selectedChannel
 */
const paramsForRecipientType = (selectedRecipientType, selectedChannel) => {
  const params = [];
  // emails always require domain param (for the colors schema)
  if (selectedChannel === Channel.EMAIL) params.push(domainParamName);

  if (selectedRecipientType === RecipientType.USER) params.push(userIdParamName);
  if (selectedRecipientType === RecipientType.INLINE) {
    if (selectedChannel === Channel.EMAIL) {
      params.push(emailParamName);
    } else {
      params.push(phoneParamName);
    }
  }

  return params;
};

export const selectParams = (state, ownProps) => {
  const templateParams = extractParamsFromTemplate(
    getEnrichedCurrTemplate(state),
    dataMappingSelector(state),
  );
  const paramsForRecipient = paramsForRecipientType(
    ownProps.recipient,
    ownProps.channel,
    templateParams,
  );

  return _.uniqBy(_.concat(templateParams, paramsForRecipient));
};

export const paramsReselector = memoizedSelectorCreator(selectParams, params => params);
