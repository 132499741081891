/**
 * created by moran on 11/29/17
 */
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import withRowData from 'Components/withRowData/withRowData';

const ActionButtonCell = ({ rowData, cellProperties: { onClick, buttonTitle } }) => (
  <button
    onClick={e => {
      e.stopPropagation();
      return onClick(rowData);
    }}
    className="btn btn-small btn-blue text-semibold action-button margin-13"
  >
    {buttonTitle}
  </button>
);

ActionButtonCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  cellProperties: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    buttonTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(withRowData)(ActionButtonCell);
