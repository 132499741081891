import gql from 'graphql-tag';
import { get } from 'lodash';
import { namespaceGraphqlFetcher } from '../graphql/fetcher';

export class AppointmentRequestActionsApi {
  constructor(namespace) {
    this.namespace = namespace;
    this.service = 'appointment-request-actions';
    this.uri = `${this.namespace}/${this.service}/graphql`;
  }

  async getAllAppointmentRequestAction(input = {}) {
    const result = await namespaceGraphqlFetcher.queryOrMutate({
      query: gql`
        query getAllAppointmentRequestAction($input: GetAllAppointmentRequestActionInput!) {
          getAllAppointmentRequestAction(input: $input) {
            results {
              id
              npi
              patientId
              platformId
              appointmentId
              phi {
                firstName
                lastName
              }
            }
            count
          }
        }
      `,
      variables: { input },
      context: {
        uri: this.uri,
      },
    });

    return {
      results: get(result, 'data.getAllAppointmentRequestAction.results', []),
      count: get(result, 'data.getAllAppointmentRequestAction.count', 0),
    };
  }
}
