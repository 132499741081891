import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import BkmdModal from '../../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import injectNotification from '../../../../../../store/notification/injectNotification';
import SvgNoResults from '../../../../../../assets/svg/noResults';
import './apiConfigurationModal.less';

const ApiConfigurationEditModal = ({
  handleClose,
  isOpen,
  apiUserRecordClientId,
  configuration,
}) => (
  <BkmdModal isOpen={isOpen} className="fixed-header overflow-visible" handleClose={handleClose}>
    <ModalHeader
      onClose={handleClose}
      title={`${apiUserRecordClientId ? `${apiUserRecordClientId} - Custom Configuration` : ''}`}
    />
    <div className="dialog-body api-configuration-modal">
      {configuration && !_.isEmpty(configuration) ? (
        <div>
          <span>Config!</span>
        </div>
      ) : (
        <div className="svg-72 text-center margin-top-30 no-configuration">
          <SvgNoResults />
          <span className="title-24 no-configuration-title">No custom configuration so far..</span>
        </div>
      )}
    </div>
  </BkmdModal>
);

ApiConfigurationEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  configuration: PropTypes.object,
  apiUserRecordClientId: PropTypes.string,
};

ApiConfigurationEditModal.defaultProps = {
  configuration: {},
  apiUserRecordClientId: undefined,
};

export default compose(
  withRouter,
  injectNotification,
)(ApiConfigurationEditModal);
