import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';
import LoadFromCSVFlow from '../../../../../../flows/loadClinicFromCSVFlow';
import Api from '../../../../../../api';

import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import injectNotification from '../../../../../../store/notification/injectNotification';

const messages = defineMessages({
  loadFromCsv: {
    defaultMessage: 'Load From Csv',
    id: 'clinic.loadFromCsvFlow.loadFromCsv',
  },
  noMedicalCodesTitle: {
    defaultMessage: 'No Medical codes',
    id: 'clinic.loadFromCsvFlow.noMedicalCodesTitle',
  },
  noMedicalCodesMessage: {
    defaultMessage: 'Cant load without medical codes',
    id: 'clinic.loadFromCsvFlow.noMedicalCodesMessage',
  },
});

const LoadClinicFromCsvButton = ({
  onSuccess,
  loadFlowModalShow,
  loadFlowModalHide,
  loadFlowModalOn,
  medicalCodes,
  providersOnly,
  clinicId,
}) => (
  <span>
    <button
      className="btn btn-small btn-min-100 pull-left bg-color-brand-button margin-13"
      onClick={loadFlowModalShow}
    >
      <FormattedMessage {...messages.loadFromCsv} />
    </button>
    <LoadFromCSVFlow
      isOpen={loadFlowModalOn}
      onClose={loadFlowModalHide}
      onSuccess={onSuccess}
      medicalCodes={medicalCodes}
      // relevant when in providersOnly mode
      providersOnly={providersOnly}
      clinicId={clinicId}
    />
  </span>
);

LoadClinicFromCsvButton.propTypes = {
  medicalCodes: PropTypes.array,
  clinicId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  providersOnly: PropTypes.bool,
  ...createToggleStatePropTypes,
};

LoadClinicFromCsvButton.defaultProps = {
  clinicId: '',
  medicalCodes: [],
  providersOnly: false,
};

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getMedicalCodes: {
      handler: () => () =>
        Api.medicalCodesApi.getAllMedicalCodes().then(results => _.get(results, 'data.data')),
      resultPropName: 'medicalCodes',
    },
  }),
  createToggleState('loadFlowModal'),
  withHandlers({
    loadFlowModalShow: ({ loadFlowModalShow, medicalCodes, notification }) => () => {
      if (_.isEmpty(medicalCodes)) {
        notification.error(messages.noMedicalCodesTitle, messages.noMedicalCodesMessage);
      } else {
        loadFlowModalShow();
      }
    },
    onSuccess: ({ onSuccess, loadFlowModalHide }) => () => {
      loadFlowModalHide();
      onSuccess();
    },
  }),
)(LoadClinicFromCsvButton);
