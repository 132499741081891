/**
 * Created by chenrozenes on 13/06/2017.
 */
import _ from 'lodash';
import Util from '../../../utils/util';

export const dataMappingSelector = state => state.backoffice.communication.dataMapping;
export const getEntityMapping = (state, props) =>
  state.backoffice.communication.dataMapping[props.params.entity];

export const getHistoryCount = state => state.backoffice.communication.history.count;
export const getHistoryData = state => state.backoffice.communication.history.data;
export const getHistoryRecord = state => state.backoffice.communication.historyRecord;
export const getChatHistory = state => state.backoffice.communication.chatHistory;
export const getMetadata = state => state.backoffice.communication.metadata;
export const getChannels = state => _.get(getMetadata(state), 'channels');
export const getNotificationTypes = state => _.get(getMetadata(state), 'notificationType');
export const getDomains = state => _.get(getMetadata(state), 'domains');
export const getApplications = state => _.get(getMetadata(state), 'applications');

export const getOutreachFile = state => state.backoffice.communication.outreach.file;
export const getCsvBeforeTokenFile = state => state.backoffice.communication.outreach.transformFile;
export const getTokenConfigs = state => state.config.backpack.communication.outreach.tokenConfigs;
export const getMlq = state => state.backoffice.communication.outreach.currentMlq;
export const getMlqResult = state => state.backoffice.communication.outreach.mlqResult;
export const getAllMlqs = state => state.backoffice.communication.outreach.mlqs.data;
export const getMlqIds = state => Util.arrayToPlainObject(getAllMlqs(state), 'name', 'id');

export const getMlqsCount = state => state.backoffice.communication.outreach.mlqs.count;

export const getStep = state => state.backoffice.communication.outreach.currentStep;
export const getStepResult = state => state.backoffice.communication.outreach.stepResult;
export const getAllSteps = state => state.backoffice.communication.outreach.steps.data;
export const getStepsCount = state => state.backoffice.communication.outreach.steps.count;

export const getAllRecipes = state => state.backoffice.communication.outreach.recipes.data;
export const getRecipesCount = state => state.backoffice.communication.outreach.recipes.count;
export const getCurrentRecipe = state => state.backoffice.communication.outreach.currentRecipe;
export const getRecipeHistoryData = state => state.backoffice.communication.outreach.history.data;
export const getRecipeHistoryCount = state => state.backoffice.communication.outreach.history.count;
export const getRecipeStatus = state => state.backoffice.communication.outreach.recipeStatus;

export const getOutreachMeta = state => state.backoffice.communication.outreach.metadata;
export const getOutreachMetadataMethods = state => _.get(getOutreachMeta(state), 'methods');
export const getOutreachDatabases = state => _.get(getOutreachMeta(state), 'databases', []);
