const ecw = 'eCW';
const athena = 'Athena';

export default {
  label: 'Virtual Scheduler Adapter',
  schema: {
    properties: {
      ehr__url: {
        title: 'EHR login / start page url',
      },
      ehr__username: {
        title: 'EHR username',
      },
      ehr__password: {
        title: 'EHR password credstash key',
        initialValue: 'credstash_<key>',
      },
    },
  },
  schemaByEhr: {
    [ecw]: {
      ehr__adapter_url: {
        title: 'EHR adapter url',
        hide: true,
        initialValue: 'http://workflow-integration/api/static/adapters/puppeteer-ecw.html',
      },
      organization__ehr_config__appointment__default_creation_status: {
        title: 'EHR Appointment creation status',
        initialValue: 'PEN',
      },
    },
    [athena]: {
      ehr__adapter_url: {
        title: 'EHR adapter url',
        hide: true,
        initialValue: 'http://workflow-integration/api/static/adapters/puppeteer-athena.html',
      },
      organization__ehr_config__appointment__cancellation_reason_id: {
        title: 'EHR Cancellation reason ID',
        optional: false,
      },
      organization__ehr_config__appointment__reason_for_visit_suffix: {
        title: 'Reason for visit suffix',
        initialValue: '',
        optional: true,
      },
    },
  },
};
