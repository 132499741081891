import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Flow from '../components/wizard/flow/Flow';
import FlowDefs from '../flows/definitions/resetPasswordFlowDef';
import withControl from '../components/wizard/flow/withControl';
import withResetPasswordConfig from '../components/withResetPasswordConfig/withResetPasswordConfig';

const ResetPasswordFlow = ({
  showRecaptcha,
  persist,
  onBack,
  resetPasswordConfig: { byEmail, byPhone },
}) => (
  <div>
    <Flow
      name={FlowDefs.name}
      definitions={FlowDefs.flow}
      initialStepName={FlowDefs.initialStepName}
      stepName={FlowDefs.initialStepName}
      persist={persist}
      initialValues={{}}
      flowProps={{
        byEmail,
        byPhone,
        onBack,
        showRecaptcha,
      }}
    />
  </div>
);

ResetPasswordFlow.propTypes = {
  persist: PropTypes.bool,
  onBack: PropTypes.func,
  resetPasswordConfig: PropTypes.object.isRequired,
  showRecaptcha: PropTypes.bool,
};

ResetPasswordFlow.defaultProps = {
  persist: false,
  onBack: undefined,
  showRecaptcha: true,
};

const enhance = compose(
  withResetPasswordConfig,
  withControl(FlowDefs.name)
);

export default enhance(ResetPasswordFlow);
