/**
 * Created by ofirHartuv on 30/10/17.
 */
import PromiseActionType from '../../../promiseActionType';
import { VIM } from '../../../../model/enum/bkmdDomain';
import { getDomain } from '../../../../utils/domain/storage';

export const GET_ALL_MACHINES = new PromiseActionType('GET_ALL_MACHINES');
export const UPDATE_MACHINE = new PromiseActionType('UPDATE_MACHINE');
export const DELETE_MACHINE = new PromiseActionType('DELETE_MACHINE');
export const CREATE_MACHINE = new PromiseActionType('CREATE_MACHINE');
export const GET_MACHINE = new PromiseActionType('GET_MACHINE');

/**
 * Make sure we care currently in Vim domain
 */
function ensureVimDomain() {
  if (getDomain() !== VIM) {
    return Promise.reject(new Error('Must be in domain VIM'));
  }
  return Promise.resolve();
}

export function getAllMachineResourcesByClinic(
  skip = 0, limit = 10, clinicId, sort, sortAscending) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_ALL_MACHINES.SOURCE,
    meta: {
      tracker: GET_ALL_MACHINES.SOURCE,
    },
    payload: {
      promise: resourceApi
        .getAllMachinesResourcesByClinic(skip, limit, clinicId, sort, sortAscending)
        .then(res => res.data)
    }
  });
}

export function getMachineResource(resourceId) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_MACHINE.SOURCE,
    meta: {
      tracker: GET_MACHINE.SOURCE,
    },
    payload: {
      promise: resourceApi.getResourceById(resourceId)
        .then(res => res.data)
    }
  });
}

export function updateMachineResource(data) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: UPDATE_MACHINE.SOURCE,
    meta: {
      tracker: UPDATE_MACHINE.SOURCE,
    },
    payload: {
      promise: resourceApi.updateResource(data)
        .then(res => res.data)
    }
  });
}

export function deleteMachineResource(resourceId) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: DELETE_MACHINE.SOURCE,
    meta: {
      tracker: DELETE_MACHINE.SOURCE,
    },
    payload: {
      promise: resourceApi.deleteMachineResource(resourceId)
        .then(res => res.data)
    }
  });
}

// for static machine
export function createMachineResource(data) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: CREATE_MACHINE.SOURCE,
    meta: {
      tracker: CREATE_MACHINE.SOURCE,
    },
    payload: {
      promise: ensureVimDomain()
        .then(() => resourceApi.createMachineResource(data))
        .then(res => res.data)
    },
  });
}
