/**
 * Created by chenrozenes on 12/11/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import Immutable from 'seamless-immutable';
import genericReducer from '../../../genericReducer';
import * as actions from './actions';

const initialState = {
  file: [],
  transformFile: [],
  metadata: {},
  mlqs: {
    count: 0,
    data: [],
  },
  currentMlq: {},
  mlqResult: [],
  steps: {
    count: 0,
    data: [],
  },
  currentStep: {},
  stepResult: [],
  recipes: [],
  currentRecipe: {},
  history: {
    count: 0,
    data: [],
  },
  recipeStatus: {},
};

export default combineReducers({
  file: genericReducer(actions.LOAD_OUTREACH_FILE, initialState.file),
  transformFile: genericReducer(actions.LOAD_TOKENIZE_FILE, initialState.transformFile),
  metadata: genericReducer(actions.GET_METADATA, initialState.metadata),
  mlqs: genericReducer(
    actions.LIST_MLQS,
    initialState.mlqs,
    null,
    { keepStateOnStart: true }
  ),
  currentMlq: genericReducer(
    actions.GET_MLQ,
    initialState.currentMlq,
    null,
    { keepStateOnStart: true }
  ),
  mlqResult: reduceReducers(
    genericReducer(actions.EXECUTE_MLQ, initialState.mlqResult),
    (state, action) => {
      state = Immutable(state);
      if (action.type === actions.CLEAR_MLQ_RESULTS) return initialState.mlqResult;
      return state;
    }
  ),
  steps: genericReducer(actions.LIST_STEPS, initialState.steps, null, { keepStateOnStart: true }),
  currentStep: genericReducer(actions.GET_STEP, initialState.currentStep),
  stepResult: reduceReducers(
    genericReducer(actions.EXECUTE_STEP, initialState.stepResult),
    (state, action) => {
      state = Immutable(state);
      if (action.type === actions.CLEAR_STEP_RESULTS) return initialState.stepResult;
      return state;
    }
  ),

  recipes: genericReducer(actions.LIST_RECIPES,
    initialState.recipes, null, { keepStateOnStart: true }),
  currentRecipe: genericReducer(actions.GET_RECIPE,
    initialState.currentRecipe),
  history: genericReducer(
    actions.GET_RECIPE_HISTORY,
    initialState.history,
    null,
    { keepStateOnStart: true }
  ),
  recipeStatus: genericReducer(
    actions.GET_RECIPE_STATUS,
    initialState.recipeStatus,
    null,
    { keepStateOnStart: true }
  ),
});
