import _ from 'lodash';

let currentVersion;
let backoff = 1;

const fetchVersion = function fetchVersion() {
  return fetch('/api/v1/version').then(response => {
    if (response.ok) {
      backoff = 1;
      return response.json();
    }
    backoff *= 2;
    return null;
  }).then(response => _.get(response, 'version'));
};

/**
 * This function is the foundation of remote setting syncronizations.
 * IT will try fetch the version from the API GW. If the version is not the same one,
 * we hard reload the page. In cases that the apigw isn't responding,
 * we try again but add a backoff to avoid ddosing ourselves :)
 * this is maxed at 10 minutes @moran
 * @param {number} interval
 */
export default function syncVersion(interval = 60000) {
  const versionInterval = setInterval(async () => {
    const version = await fetchVersion();
    if (!version) {
      clearInterval(versionInterval);
      syncVersion(interval);
    } else if (currentVersion && currentVersion !== version) {
      window.location.reload(true);
    } else {
      currentVersion = version;
    }
  }, Math.min(1200000, interval * backoff));
}
