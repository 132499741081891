import React from 'react';
import { withRouter } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState, withPropsOnChange, withStateHandlers } from 'recompose';
import { isNil, concat } from 'lodash';
import { createToggleState } from 'Components/recompose/toggleStateComponent';
import CleanCheckbox from '../../../../../components/form/cleanCheckbox';
import Api from '../../../../../api';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import CreateNamespaceModal from './createNamespaceModal';
import CreateVirtualNamespaceModal from './createVirtualNamespaceModal';
import EditNamespaceModal from './editNamespaceModal';

import './index.less';

const MULTI_TENANT_PHI = 'multi-tenant-phi';
const NAMESPACE = 'namespace';
const VIRTUAL_NAMESPACE = 'virtual-namespace';

const NamespaceList = ({
  namespaces,
  selectedNsName,
  router,
  createNsModalShow,
  createNsModalHide,
  createVirtualNsModalShow,
  createVirtualNsModalHide,
  createNsModalOn,
  createVirtualNsModalOn,
  editNsModalShow,
  editNsModalHide,
  editNsModalOn,
  getNamespaces,
  onShowNamespacesChange,
  onShowVirtualNamespacesChange,
  showNamespaces,
  showVirtualNamespaces,
}) => (
    <div>
      <div className="namespaces-list-actions">
        <div className="inline-block">
          <CleanCheckbox
            value={showNamespaces}
            label="Show namespaces"
            id="showNamespaces"
            onChange={onShowNamespacesChange}
          />
        </div>
        <div className="inline-block">
          <CleanCheckbox
            value={showVirtualNamespaces}
            label="Show virtual namespaces"
            id="showVirtualNamespaces"
            onChange={onShowVirtualNamespacesChange}
          />
        </div>
        <button
          className="btn btn-small btn-min-100 pull-right bg-color-brand-main margin-13"
          onClick={createVirtualNsModalShow}
        >
          Create new virtual namespace
        </button>
        <button
          className="btn btn-small btn-min-100 pull-right bg-color-brand-main margin-13"
          onClick={createNsModalShow}
        >
          Create new namespace
        </button>
      </div>
      <div className="namespaces-menu">
        {namespaces.map(({ name, status }) => (
            <div className="panel namespace-panel">
              <div className="clickable">
                <div className="panel-footer"
                     onClick={() => router.push(`/secure/namespaceManager/${name}`)}>
                  <div className="col-sm-2" />
                  <div className="col-sm-8">{name}</div>
                  <div className="col-sm-2">
                    <OverlayTrigger overlay={<Tooltip>{status}</Tooltip>}>
                      <i className={`fa ${status === 'ready' ? 'fa-check' : 'fa-spinner'}`} />
                    </OverlayTrigger>
                  </div>
                  <div className="col-sm-2" onClick={event => {
                    event.stopPropagation();
                    editNsModalShow(name);
                  }}>
                    <OverlayTrigger overlay={<Tooltip>edit</Tooltip>}>
                      <i role="button" className="fa fa-pencil-alt" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CreateNamespaceModal
        isOpen={createNsModalOn}
        onSuccess={() => getNamespaces()}
        handleClose={() => createNsModalHide()}
      />
      <CreateVirtualNamespaceModal
        isOpen={createVirtualNsModalOn}
        onSuccess={() => getNamespaces()}
        handleClose={() => createVirtualNsModalHide()}
      />
      <EditNamespaceModal
        isOpen={editNsModalOn}
        namespace={selectedNsName}
        handleClose={() => editNsModalHide()}
      />
    </div>
  );

NamespaceList.propTypes = {
  namespaces: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  getNamespaces: PropTypes.func.isRequired,
  createNsModalHide: PropTypes.func.isRequired,
  createNsModalShow: PropTypes.func.isRequired,
  createNsModalOn: PropTypes.bool.isRequired,
  editNsModalHide: PropTypes.func.isRequired,
  editNsModalShow: PropTypes.func.isRequired,
  editNsModalOn: PropTypes.bool.isRequired,
  selectedNsName: PropTypes.string.isRequired,
  onShowNamespacesChange: PropTypes.func.isRequired,
  onShowVirtualNamespacesChange: PropTypes.func.isRequired,
};

export default compose(
  withStateFetchersOnMount({
    getNamespaces: {
      handler: () => async () => {
        const namespacesRaw = await Api.namespaceManagerApi.getNamespaces();
        const virtualNamespacesRaw = await Api.namespaceManagerApi.getVirtualNamespaces();
        const namespaces = namespacesRaw.filter(ns => ns.name !== MULTI_TENANT_PHI).map(ns => ({
          name: ns.name,
          status: ns.status,
          type: NAMESPACE,
        }));
        const multiTenantPhiData = namespacesRaw.find(ns => ns.name === MULTI_TENANT_PHI);

        let virtualNamespaces = [];
        Object.values(virtualNamespacesRaw).forEach(domain => {
          virtualNamespaces = concat(virtualNamespaces, domain)
        });

        virtualNamespaces = virtualNamespaces.map(virtualNs => ({
          name: virtualNs,
          status: multiTenantPhiData.status,
          type: VIRTUAL_NAMESPACE,
        }));

        return concat(namespaces, virtualNamespaces);
      },
      resultPropName: 'rawNamespaces',
      defaultValue: null,
      isReady: ({ rawNamespaces }) => !isNil(rawNamespaces),
    },
  }),
  withRouter,
  withState('selectedNsName', 'selectNamespace', undefined),
  withStateHandlers(
    {
      showNamespaces: true,
      showVirtualNamespaces: false,
    },
    {
      onShowNamespacesChange: ({ showNamespaces }) => () => ({
        showNamespaces: !showNamespaces,
      }),
      onShowVirtualNamespacesChange: ({ showVirtualNamespaces }) => () => ({
        showVirtualNamespaces: !showVirtualNamespaces,
      }),
    },
  ),
  withPropsOnChange(
    ['rawNamespaces','showNamespaces', 'showVirtualNamespaces'],
    ({ rawNamespaces, showNamespaces, showVirtualNamespaces }) => {
      let namespaces = [];

      if (showNamespaces) {
        namespaces = concat(namespaces, rawNamespaces.filter(ns => ns.type === NAMESPACE));
      }

      if (showVirtualNamespaces) {
        namespaces = concat(namespaces, rawNamespaces.filter(ns => ns.type === VIRTUAL_NAMESPACE));
      }

      namespaces.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });

      return { namespaces };
    },
  ),
  createToggleState('createNsModal'),
  createToggleState('createVirtualNsModal'),
  createToggleState('editNsModal'),
  withHandlers({
    editNsModalShow: ({ editNsModalShow, selectNamespace }) => namespaceName => {
      selectNamespace(namespaceName);
      editNsModalShow();
    },
  }),
)(NamespaceList);
