import { object, string } from 'yup';

const planMappingFormSchema = object().shape({
  ehrExternalId: string()
    .label('EHR External id')
    .required(),
  ehrDescription: string()
    .label('Ehr Description')
    .required(),
  payer: string()
    .label('Payer')
    .required(),
  insurer: string()
    .label('Insurer')
    .required(),
  payerDescription: string()
    .label('Payer Description')
    .nullable(true),
});

export default planMappingFormSchema;
