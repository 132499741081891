import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { BkmdGridColumn } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import * as machineSelectors from '../../../../../store/backoffice/clinicManagement/machines/selectors';
import { getAllMachineResourcesByClinic } from '../../../../../store/backoffice/clinicManagement/machines/actions';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

@autobind
class MachineList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllMachineResourcesByClinic: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    machines: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.onQueryChange();
  }

  onQueryChange() {
    const { skip, limit, sort, sortAscending, params } = this.props;
    this.props.getAllMachineResourcesByClinic(skip, limit, params.id, sort, sortAscending);
  }

  onClick(rowData) {
    const { params, router } = this.props;
    router.push(`/secure/clinics/${params.id}/machines/${rowData.id}`);
  }

  onCreateMachineClicked() {
    const { params, router } = this.props;
    router.push(`/secure/clinics/${params.id}/machines/create`);
  }

  render() {
    const { machines, count } = this.props;
    return (
      <div>
        <div className="backoffice-action">
          <button
            className="btn btn-small btn-blue text-semibold action-button margin-13"
            onClick={this.onCreateMachineClicked}
          >
            Create Machine
          </button>
        </div>

        <hr />
        <QueryStringGrid
          data={machines}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="machineType" title="Machine Type" />
          <BkmdGridColumn id="manufacturer" title="Manufacturer" />
          <BkmdGridColumn id="model" title="Model" />
          <BkmdGridColumn id="year" title="year" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      machines: machineSelectors.getMachineList(state),
      count: machineSelectors.getMachineCount(state),
    }),
    { getAllMachineResourcesByClinic },
  ),
)(MachineList);
