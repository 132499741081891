/**
 * Created by Nico on 3/05/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import queryConnect from 'Store/tools/queryString/queryConnect';
import * as selectors from 'Store/backoffice/vimGuideProfile/selectors';
import { getAllVimGuideProfiles } from 'Store/backoffice/vimGuideProfile/actions';
import { getAsoGroupsList } from 'Store/backoffice/asoGroups/selectors';
import { BkmdGridColumn } from 'Components/grid';
import fetchNotEmpty from 'Store/tools/fetchNotEmpty';
import QueryStringGrid, { gridQueryParams } from 'Components/grid/queryStringGrid';
import CreateProfileAction from './actions/createVimGuideProfile';

@autobind
class VimGuideProfileList extends React.Component {
  static propTypes = {
    providers: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    router: PropTypes.object.isRequired,
    getAllVimGuideProfiles: PropTypes.func.isRequired,
  };

  onClick(rowData) {
    this.props.router.push(`/secure/vimGuideProfile/${rowData.id}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllVimGuideProfiles(skip, limit, search, sort, sortAscending);
  }

  providersWithAsoGroups() {
    const withAso = this.props.providers.map(profile => {
        if (profile.asoGroups === null)
          return profile;

        const asoGroups = profile.asoGroups.map(groupId => {
          const asoGroup = this.props.asoGroups.find(group => group.groupId === groupId);
          return (asoGroup !== undefined) ? `${asoGroup.name}:${asoGroup.groupId} ` : '';
        })
        
        return { ...profile, asoGroups }
    });
    
    return withAso;
  }

  render() {
    return (
      <div>
        <CreateProfileAction />
        <QueryStringGrid
          data={this.providersWithAsoGroups()}
          totalCount={this.props.count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="firstName" title="First Name" />
          <BkmdGridColumn id="middleName" title="Middle Name" />
          <BkmdGridColumn id="lastName" title="Last Name" />
          <BkmdGridColumn id="userId" title="User Id" />
          <BkmdGridColumn id="asoGroups" title="Aso Groups" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  fetchNotEmpty(
    ({ props: { skip, limit, search, sort, sortAscending } }) =>
      getAllVimGuideProfiles(skip, limit, search, sort, sortAscending),
    selectors.getVimGuideProfilesList,
    { renderAlways: true, loading: true },
  ),
  connect(
    state => ({
      providers: selectors.getVimGuideProfilesList(state),
      count: selectors.getVimGuideProfilesCount(state),
      asoGroups: getAsoGroupsList(state),
    }),
    { getAllVimGuideProfiles },
  ),
)(VimGuideProfileList);
