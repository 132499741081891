import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { pure, withHandlers, compose, withProps } from 'recompose';
import { BkmdGrid, BkmdGridColumn } from '../../components/grid';
import CollapsibleCard from '../../components/ui/collapsibleCard/collapsibleCard';
import './addCSVProviderResourcesResults.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Failed to attach location for those providers:',
    id: 'csvProvidersResources.showFailedResultsFromServer.title',
  },
  finish: {
    defaultMessage: 'Finish',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.finish',
  },
  createdClinics: {
    defaultMessage: 'Created Clinics',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.createdClinics',
  },
  updatedClinics: {
    defaultMessage: 'Updated Clinics',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.updatedClinics',
  },
  deletedClinics: {
    defaultMessage: 'Deleted Clinics',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.disabledClinics',
  },
  failTitleClinics: {
    defaultMessage: 'Failed Clinics',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.failTitleClinics',
  },
  failTitle: {
    defaultMessage: 'Failed to attach calendar location to those providers:',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.failTitle',
  },
  numberOfCreatedClinics: {
    defaultMessage: 'Clinics Created: {numberOfCreatedClinics}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfCreatedClinics',
  },
  numberOfUpdatedClinics: {
    defaultMessage: 'Clinics Updated: {numberOfUpdatedClinics}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfUpdatedClinics',
  },
  numberOfDisabledClinics: {
    defaultMessage: 'Clinics Deleted: {numberOfDisabledClinics}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfDisabledClinics',
  },
  numberOfCreatedResources: {
    defaultMessage: 'Providers Created: {numberOfCreatedResources}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfCreatedResources',
  },
  numberOfUpdatedResources: {
    defaultMessage: 'Providers Updated: {numberOfUpdatedResources}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfUpdatedResources',
  },
  numberOfDisabledResources: {
    defaultMessage: 'Providers Deleted: {numberOfDisabledResources}',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.numberOfDisabledResources',
  },
  createdResources: {
    defaultMessage: 'Created Providers',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.createdResources',
  },
  updatedResources: {
    defaultMessage: 'Updated Providers',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.updatedResources',
  },
  disabledResources: {
    defaultMessage: 'Deleted Providers',
    id: 'loadClinicFromCsv.showFailedResultsFromServer.disabledResources',
  },
});

function extractProvidersLoadByDomain(providerDomainsList) {
  return _.map(providerDomainsList, loadedProvidersByDomain => {
    const { providerList, clinicId } = loadedProvidersByDomain;
    const formattedErrors = _.compact(
      _.map(providerList, ({ location, domain }, npi) =>
        _.isEmpty(location) ? { domain, npi, status: 'ATTACH LOCATION FAILED' } : null,
      ),
    );

    return { errors: formattedErrors, clinicId };
  });
}

function renderFailedProviders(failedData) {
  return (
    <div>
      {failedData.errors.length > 0 ? (
        <div>
          <div className="text-18 text-semibold text-center text-dark padding-box-20">
            <FormattedMessage {...messages.failTitle} />
          </div>
          <CollapsibleCard
            title={`Clinic ID ${failedData.clinicId}`}
            className="csv-providers-clinic-card"
          >
            <BkmdGrid
              data={failedData.errors}
              totalCount={failedData.errors.length}
              limit={failedData.errors.length}
            >
              <BkmdGridColumn id="domain" title="Domain" />
              <BkmdGridColumn id="npi" title="NPI" />
              <BkmdGridColumn id="status" title="Status" />
            </BkmdGrid>
          </CollapsibleCard>
        </div>
      ) : null}
    </div>
  );
}

function renderProvidersError(errorsProviders) {
  return (
    <div>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        Providers Failed: {errorsProviders.length}
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Clinic ID</th>
            <th className="bkmd-th">Identifier</th>
            <th className="bkmd-th">Row Number</th>
            <th className="bkmd-th">Reason for failure</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {errorsProviders.map(data => (
            <tr className="bkmd-tr" key={data.identifier}>
              <td className="bkmd-td">{data.clinicId}</td>
              <td className="bkmd-td">{data.identifier}</td>
              <td className="bkmd-td">{data.rowNumber}</td>
              <td className="bkmd-td">{data.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function renderTableClinics(created, updated, deleted, errors) {
  return (
    <div>
      {created && (
        <div className="text-18 text-semibold text-center text-dark">
          <FormattedMessage
            {...messages.numberOfCreatedClinics}
            values={{ numberOfCreatedClinics: created.length }}
          />
        </div>
      )}
      {updated && (
        <div className="text-18 text-semibold text-center text-dark">
          <FormattedMessage
            {...messages.numberOfUpdatedClinics}
            values={{ numberOfUpdatedClinics: updated.length }}
          />
        </div>
      )}
      {deleted && (
        <div className="text-18 text-semibold text-center text-dark">
          <FormattedMessage
            {...messages.numberOfDisabledClinics}
            values={{ numberOfDisabledClinics: deleted.length }}
          />
        </div>
      )}
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.failTitleClinics} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Clinic office name</th>
            <th className="bkmd-th">Clinic address</th>
            <th className="bkmd-th">Reason for failure</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {_.map(errors, data => (
            <tr className="bkmd-tr" key={data.id}>
              <td className="bkmd-td">{data.officeName}</td>
              <td className="bkmd-td">{data.address}</td>
              <td className="bkmd-td">{data.errorMsg}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.createdClinics} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Office Name</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {_.map(created, data => (
            <tr className="bkmd-tr" key={data.id}>
              <td className="bkmd-td">{data.officeName}</td>
              <td className="bkmd-td">{data.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.updatedClinics} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Office Name</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {_.map(updated, data => (
            <tr className="bkmd-tr" key={data.id}>
              <td className="bkmd-td">{data.officeName}</td>
              <td className="bkmd-td">{data.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.deletedClinics} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Office Name</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {deleted &&
            _.map(deleted, data => (
              <tr className="bkmd-tr" key={data.id}>
                <td className="bkmd-td">{data.officeName}</td>
                <td className="bkmd-td">{data.id}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
function renderTableResources(created, updated, deleted) {
  return (
    <div>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.createdResources} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Resource Identifier</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {_.map(created, data => (
            <tr className="bkmd-tr" key={data.id}>
              <td className="bkmd-td">{data.identifier}</td>
              <td className="bkmd-td">{data.clinicId}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.updatedResources} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Resource Identifier</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {_.map(updated, data => (
            <tr className="bkmd-tr" key={data.id}>
              <td className="bkmd-td">{data.identifier}</td>
              <td className="bkmd-td">{data.clinicId}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-18 text-semibold text-center text-dark padding-box-20">
        <FormattedMessage {...messages.disabledResources} />
      </div>
      <table className="bkmd-table">
        <thead className="bkmd-thead">
          <tr>
            <th className="bkmd-th">Resource Identifier</th>
            <th className="bkmd-th">Clinic ID</th>
          </tr>
        </thead>
        <tbody className="bkmd-tbody">
          {deleted &&
            _.map(deleted, data => (
              <tr className="bkmd-tr" key={data.id}>
                <td className="bkmd-td">{data.identifier}</td>
                <td className="bkmd-td">{data.clinicId}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const showClinicsCSVLoadResultsView = ({
  failedProvidersByDomain,
  onNext,
  created,
  updated,
  deleted,
  errorColumnsOrder,
  errorUniqueClinicId,
  errors,
  providersErrors,
  createdResources,
  updatedResources,
  disabledResources,
  numberOfCreatedResources,
  numberOfUpdatedResources,
  numberOfDisabledResources,
}) => (
  <div>
    {numberOfCreatedResources > 0 && (
      <div className="text-18 text-semibold text-center text-dark">
        <FormattedMessage
          {...messages.numberOfCreatedResources}
          values={{ numberOfCreatedResources }}
        />
      </div>
    )}
    {numberOfUpdatedResources > 0 && (
      <div className="text-18 text-semibold text-center text-dark">
        <FormattedMessage
          {...messages.numberOfUpdatedResources}
          values={{ numberOfUpdatedResources }}
        />
      </div>
    )}
    {numberOfDisabledResources > 0 && (
      <div className="text-18 text-semibold text-center text-dark">
        <FormattedMessage
          {...messages.numberOfDisabledResources}
          values={{ numberOfDisabledResources }}
        />
      </div>
    )}
    <div className="text-18 text-semibold text-center text-dark padding-box-20">
      {errorColumnsOrder}
    </div>
    <div className="text-18 text-semibold text-center text-dark padding-box-20">
      {errorUniqueClinicId}
    </div>
    {providersErrors.length > 0 && <div>{renderProvidersError(providersErrors)}</div>}
    <div>
      {created || updated || deleted
        ? renderTableClinics(created, updated, deleted, errors)
        : _.map(failedProvidersByDomain, renderFailedProviders)}
    </div>
    <div>
      {createdResources || updatedResources || disabledResources
        ? renderTableResources(createdResources, updatedResources, disabledResources)
        : null}
    </div>
    <div className="row margin-top-30">
      <div className="col-xs-12 text-center">
        <button onClick={onNext} className="btn btn-small btn-outline btn-min-100">
          <FormattedMessage {...messages.finish} />
        </button>
      </div>
    </div>
  </div>
);

showClinicsCSVLoadResultsView.propTypes = {
  failedProvidersByDomain: PropTypes.array,
  onNext: PropTypes.func.isRequired,
  created: PropTypes.array,
  updated: PropTypes.array,
  deleted: PropTypes.array,
  errorColumnsOrder: PropTypes.string,
  errorUniqueClinicId: PropTypes.string,
  errors: PropTypes.array,
  providersErrors: PropTypes.array,
  createdResources: PropTypes.array,
  updatedResources: PropTypes.array,
  disabledResources: PropTypes.array,
  numberOfCreatedResources: PropTypes.number,
  numberOfUpdatedResources: PropTypes.number,
  numberOfDisabledResources: PropTypes.number,
};

const showClinicsCSVLoadResultsForm = compose(
  pure,
  withHandlers({
    onNext: ({ control, onSuccess }) => () => {
      onSuccess();
      control.next({});
    },
  }),
  withProps(
    ({
      loadedClinicsProviders,
      errorColumnsOrder,
      errorUniqueClinicId,
      errors,
      invalidProviders,
    }) => {
      const providersErrors = [];
      const failedProvidersByDomain = [];
      const clinicErrors = _.get(loadedClinicsProviders.data, 'errors');

      let createdResources = [];
      let updatedResources = [];
      let disabledResources = [];

      let numberOfCreatedResources = 0;
      let numberOfUpdatedResources = 0;
      let numberOfDisabledResources = 0;

      if (!errorColumnsOrder && !errorUniqueClinicId) {
        if (!_.isEmpty(loadedClinicsProviders)) {
          _.forEach(loadedClinicsProviders, domainLoadedData => {
            if (!domainLoadedData.error) {
              createdResources = createdResources.concat(domainLoadedData.createdResources);
              updatedResources = updatedResources.concat(domainLoadedData.updatedResources);
              disabledResources = disabledResources.concat(domainLoadedData.disabledResources);

              numberOfCreatedResources += domainLoadedData.numberOfCreatedResources;
              numberOfUpdatedResources += domainLoadedData.numberOfUpdatedResources;
              numberOfDisabledResources += domainLoadedData.numberOfDisabledResources;
            }

            if (domainLoadedData.error) {
              _.forEach(domainLoadedData.error.entitiesData, data => {
                const error = {
                  identifier: data.identifier,
                  rowNumber: data.rowNumber + 1,
                  clinicId: data.clinicId,
                  message: domainLoadedData.error.errorMsg,
                };

                providersErrors.push(error);
              });
            }

            failedProvidersByDomain.push(
              ...extractProvidersLoadByDomain(domainLoadedData.providerObj),
            );
          });
        }

        if (!_.isEmpty(clinicErrors)) {
          _.forEach(clinicErrors, error => errors.push(error));
        }

        if (!_.isEmpty(invalidProviders)) {
          _.forEach(invalidProviders, invalidProvider => {
            const error = {
              identifier: invalidProvider.npi,
              rowNumber: invalidProvider.rowNumber,
              clinicId: invalidProvider.clinicId,
              message: invalidProvider.error,
            };

            providersErrors.push(error);
          });
        }

        return {
          failedProvidersByDomain,
          createdResources,
          updatedResources,
          disabledResources,
          numberOfCreatedResources,
          numberOfUpdatedResources,
          numberOfDisabledResources,
          providersErrors,
        };
      }
      return {
        errorColumnsOrder,
        errorUniqueClinicId,
        failedProvidersByDomain,
        providersErrors,
      };
    },
  ),
)(showClinicsCSVLoadResultsView);

export default showClinicsCSVLoadResultsForm;
