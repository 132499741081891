import React from 'react';
import { Field, Form } from 'formik';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import SpinnerButton from 'Components/ui/spinner/spinnerButton';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import PropTypes from 'prop-types';
import _ from 'lodash';
import planMappingFormSchema from './PlanMappingFormSchema';

const PlanMappingForm = ({ ehrPlanMappingData, insurers, isLoading, onSubmit }) => (
  <div>
    <WrappedFormik
      enableReinitialize
      initialValues={ehrPlanMappingData.data}
      onSubmit={onSubmit}
      validationSchema={planMappingFormSchema}
      render={data => (
        <Form>
          <div className="card margin-top-30">
            <div className="card-body">
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="ehrExternalId"
                    component={CleanFormikInput}
                    label="EHR External ID"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="ehrDescription"
                    component={CleanFormikInput}
                    label="EHR Description"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="insurer"
                    label="Insurer"
                    component={CleanFormikSelect}
                    options={_.map(insurers, ({ displayName }) => ({
                      value: displayName,
                      label: displayName,
                    }))}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="payerDescription"
                    component={CleanFormikInput}
                    label="Payer Roster Plan"
                  />
                </div>
              </div>
              <div className="row margin-bottom-30">
                <div className="col-xs-12">
                  <SpinnerButton
                    className="btn btn-big bg-color-brand-button"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!data.isValid || isLoading}
                  >
                    Submit
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  </div>
);

PlanMappingForm.propTypes = {
  ehrPlanMappingData: PropTypes.object.isRequired,
  insurers: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

PlanMappingForm.defaultProps = {
  onSubmit: _.noop,
  isLoading: false,
};

export default PlanMappingForm;
