import React from 'react';
import { entries, forEach, get } from 'lodash';
import { compose, withHandlers, withProps } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field, Form } from 'formik';
import { object, string, boolean } from 'yup';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { back } from 'Components/wizard/flow/results';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import { ehrServices } from '../../ehrServices';
import steps from './index';

const messages = defineMessages({
  ehrConfig: {
    defaultMessage: 'Enter {ehr} EHR configurations',
    id: 'backpack.createNamespaceModal.ehrAdapter',
  },
});

const EHRForm = ({ ehr, fields, schema, initialValues, existingNs, nextStep }) => (
  <WrappedFormik
    enableReinitialize
    onSubmit={nextStep}
    validationSchema={schema}
    initialValues={initialValues}
    render={({ isValid }) => (
      <Form className="namespace-input-form">
        <div className="row margin-bottom-10">
          <div className="col-xs-12 text-16 text-center">
            <h4>
              <FormattedMessage {...messages.ehrConfig} values={{ ehr: ehrServices[ehr].label }} />
            </h4>
          </div>
        </div>
        {fields}
        <Button
          type="submit"
          className={`btn btn-big btn-min-100 btn-block ${
            isValid ? 'bg-color-brand-button' : 'bg-color-brand-accent'
          }`}
        >
          {existingNs ? 'Update' : 'Create'}
        </Button>
      </Form>
    )}
  />
);

const enhanced = compose(
  withProps(({ ehr, ehrName, ehrConfiguration }) => {
    const fields = [];
    const schemaProps = {};
    let initialValues = {};

    const ehrProperties = entries(
      ehrName
        ? {
            ...ehrServices[ehr].schema.properties,
            ...ehrServices[ehr].schemaByEhr[ehrName],
          }
        : ehrServices[ehr].schema.properties,
    );
    forEach(ehrProperties, ([key, value]) => {
      schemaProps[key] = string();
      if (value.flag) {
        schemaProps[key] = boolean();
      }
      if (!value.optional) {
        schemaProps[key] = schemaProps[key].required(`${value.title} is required`);
      }
      if (value.initialValue) {
        initialValues[key] = value.initialValue;
      }

      if (!value.hide) {
        fields.push(
          <div className="row namespace-name-input" key={key}>
            <Field
              id={key}
              name={key}
              label={value.title}
              component={value.flag ? CleanFormikCheckbox : CleanFormikInput}
            />
          </div>,
        );
      }

      if (ehrConfiguration) {
        initialValues = {
          ...initialValues,
          [key]: ehrConfiguration[key],
        };
      }
    });
    const schema = object().shape(schemaProps);

    return {
      fields,
      schema,
      initialValues,
    };
  }),
  withHandlers({
    nextStep: ({ control, ehr }) => values => {
      const safeValues = {};
      forEach(entries(values), ([key, value]) => {
        if (get(ehrServices[ehr].schema.properties, `${key}.flag`)) {
          safeValues[key] = `${value}`;
        } else {
          safeValues[key] = value;
        }
      });

      control.next({ payload: safeValues });
    },
  }),
)(EHRForm);

export default {
  component: enhanced,
  transitions: {
    NEXT: ({ data: { existingNs } }) =>
      existingNs ? steps.UPDATE_NAMESPACE : steps.CREATE_NAMESPACE,
    PREV: back,
  },
  mapFlowDataToProps: ({ ehr, ehrName, existingNs, ehrConfiguration }) => {
    if (existingNs) {
      return {
        existingNs,
        ehr,
        ehrName,
        ehrConfiguration,
      };
    }
    if (ehr && ehrName) {
      return { ehr, ehrName };
    }
    if (ehr) {
      return { ehr };
    }
    return {};
  },
  mapPayloadToFlowData: values => ({ ehrConfiguration: values }),
};
