export const REQUESTED = 'REQUESTED';
export const BOOKED = 'BOOKED';
export const UNABLE_TO_BOOK = 'UNABLE_TO_BOOK';
export const DELIVERING = 'DELIVERING';
export const DELIVERED = 'DELIVERED';
export const DELIVERED_BY_PORTAL = 'DELIVERED_BY_PORTAL';
export const OPENED = 'OPENED';
export const FAILED_TO_SEND = 'FAILED_TO_SEND';
export const FAILED_TO_DELIVER = 'FAILED_TO_DELIVER';
export const ARCHIVED = 'ARCHIVED';
