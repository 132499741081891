/**
 * Created by chenrozenes on 18/04/2017.
 */
import Joi from 'joi-browser';

import {
  ReduxFormCleanPhoneNumber,
  ReduxFormListDisplay,
  ReduxFormJSONInput,
} from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-provider';

export const fields = {
  phoneNumber: {
    component: ReduxFormCleanPhoneNumber,
  },
  fax: {
    component: ReduxFormCleanPhoneNumber,
  },
  level: {
    component: ReduxFormListDisplay,
  },
  taxonomies: {
    component: ReduxFormJSONInput,
  },
  locations: false,
};

export const schema = {
  gender: Joi.string().valid('MALE', 'FEMALE'),
};
