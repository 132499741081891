import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { reduxForm, Field } from 'redux-form';
import * as CreateAsoGroupTierFormDefs from './createAsoGroupTierFormDefs';
import { createValidator } from '../../../../../utils/joiValidator';

const CreateAsoGroupTierForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} method="post">
    <div className="row">
      <div className="col-xs-12">
        <Field label="Name" {...CreateAsoGroupTierFormDefs.fields.name} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <Field label="Title" {...CreateAsoGroupTierFormDefs.fields.title} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <Field label="Description" {...CreateAsoGroupTierFormDefs.fields.description} />
      </div>
    </div>
  </form>
);

CreateAsoGroupTierForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // from redux-form
};

export default reduxForm({
  form: CreateAsoGroupTierFormDefs.name,
  validate: createValidator(Joi.object().keys(CreateAsoGroupTierFormDefs.schema)),
})(CreateAsoGroupTierForm);
