/**
 * created by moran on 10/31/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import { reduxForm, Field } from 'redux-form';

import * as CreateProviderFormDef from './createProfieFormDefs';
import { createValidator } from '../../../../../utils/joiValidator';
import EntityForm from '../../components/entityForm/entityForm';

@autobind
class CreateProviderForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { onStrengthChanged, handleSubmit, newPasswordValidationFeature } = this.props;
    return (
      <EntityForm
        name={CreateProviderFormDef.name}
        entity={CreateProviderFormDef.defaultFields}
        fieldsDef={CreateProviderFormDef.fields}
        schemaDef={CreateProviderFormDef.schema}
        showButtons={false}
        onSubmit={handleSubmit}
        onStrengthChanged={onStrengthChanged}
        newPasswordValidationFeature={newPasswordValidationFeature}
        validate={createValidator(
          Joi.object().keys(CreateProviderFormDef.schema),
          CreateProviderFormDef.customValidator,
        )}
        verifyPasswordField={
          <div>
            <Field
              label="Re-enter password"
              className="password-input"
              {...CreateProviderFormDef.fields.repeatPassword}
            />
          </div>
        }
      />
    );
  }
}

export default reduxForm({
  form: CreateProviderFormDef.name,
})(CreateProviderForm);
