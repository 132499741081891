/**
 * Created by guyavarham on 19/12/2017.
 */

import { compose } from 'redux';
import { connect } from 'react-redux';

import { ReduxFormLocationSelectByNpi } from '../../../../../components/form/reduxForm/components';
import * as selectors from '../../../../../store/backoffice/providers/selectors';
import * as actions from '../../../../../store/backoffice/providers/actions';

export default compose(
  connect(
    (state, ownProps) => ({
      npi: ownProps.npiSelector(state),
      locations: selectors.getProviderLocations(state),
    }),
    dispatch => ({
      findLocations: npi => {
        if (npi) {
          dispatch(actions.getProviderLocations(npi));
        }
      },
    }),
  ),
)(ReduxFormLocationSelectByNpi);
