/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import _ from 'lodash';
import { reduxForm, Field } from 'redux-form';
import * as RemovePermissionFormDefs from './removePermissionFormDefs';
import { createValidator } from '../../../../../../utils/joiValidator';
import { permissionMapper } from '../permissionMapper';

@autobind
class RemovePermissionForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
  };

  resourceTypeOptions() {
    return _.map(permissionMapper[this.props.entityType], value => ({
      value,
      label: value,
    }));
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field
              options={this.resourceTypeOptions()}
              placeholder="Resource Type"
              {...RemovePermissionFormDefs.fields.resourceType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              id="resourceId"
              defaultValue={this.props.resourceId}
              placeholder="Resource Id (Required)"
              {...RemovePermissionFormDefs.fields.resourceId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <Field
              id="userId"
              placeholder="Id (Required)"
              {...RemovePermissionFormDefs.fields.userId}
            />
          </div>
          <div className="col-xs-6">
            <Field id="isGroup" defaultValue={false} {...RemovePermissionFormDefs.fields.isGroup} />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: RemovePermissionFormDefs.name,
  validate: createValidator(Joi.object().keys(RemovePermissionFormDefs.schema)),
})(RemovePermissionForm);
