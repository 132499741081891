/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter, Link } from 'react-router';
import { compose, withHandlers, withState, lifecycle } from 'recompose';

import { withFetchers } from '../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../store/notification/injectNotification';
import Apis from '../../../../api';
import EntityForm from '../components/entityForm/entityForm';
import AddUserAction from './actions/addUserToGroup/index';
import RemoveUserFromGroupAction from './actions/removeUserFromGroup';
import DeleteGroupAction from './actions/deleteGroup';
import RenameGroupAction from './actions/renameGroup';
import { BACK_OFFICE_ACL } from '../../../../model/enum/aclResourceType';
import AclGuard from '../../../../store/auth/aclGuard';
import * as UserGroupFormDefs from './userGroupFormDefs';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';

function UserGroupPage(props) {
  const { group } = props;
  return _.isEmpty(group) ? (
    <ThreeBounceSpinner />
  ) : (
    <div>
      <h1>{`Group: ${group.name}`}</h1>
      <div className="action-group">
        <RenameGroupAction
          groupId={group.id}
          currentName={group.name}
          onSuccess={props.onActionCompleted}
        />
        <AddUserAction groupId={group.id} onSuccess={props.onActionCompleted} />
        <RemoveUserFromGroupAction
          groupId={group.id}
          currentUsers={group.users}
          onSuccess={props.onActionCompleted}
        />
        <DeleteGroupAction groupId={group.id} onSuccess={props.onDeleteCompleted} />
        <AclGuard type={BACK_OFFICE_ACL}>
          <Link to={`/secure/userGroups/${group.id}/permissions`}>
            <button className="btn btn-small btn-blue text-semibold small-margin action-button">
              Permissions
            </button>
          </Link>
        </AclGuard>
      </div>
      <hr />
      <EntityForm
        name={UserGroupFormDefs.name}
        title="Group data:"
        schemaDef={UserGroupFormDefs.schema}
        fieldsDef={UserGroupFormDefs.fields}
        entity={group}
        readOnly
      />
      <hr />
    </div>
  );
}

UserGroupPage.propTypes = {
  group: PropTypes.object,
  onActionCompleted: PropTypes.func,
  onDeleteCompleted: PropTypes.func,
};

UserGroupPage.defaultProps = {
  group: null,
  onActionCompleted: _.noop,
  onDeleteCompleted: _.noop,
};

export default compose(
  withState('group', 'setGroup'),
  withRouter,
  injectNotification,
  withFetchers({
    getGroup: {
      handler: ({ setGroup, params }) => () => {
        setGroup(undefined);
        return Apis.usersApi.getGroup(parseInt(params.id, 10));
      },
      onSuccess: ({ setGroup }, result) => setGroup(result.data),
      onError: ({ notification, params }) =>
        notification.error('Group not found', `Could not fetch group ${params.id} from server`),
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getGroup();
    },
  }),
  withHandlers({
    onActionCompleted: ({ getGroup }) => getGroup(),
    onDeleteCompleted: ({ router }) => router.goBack(),
  }),
)(UserGroupPage);
