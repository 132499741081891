import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { BkmdGridColumn, Columns } from '../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';

import { listAppointmentRequests } from '../../../../store/backoffice/appointmentRequest/actions';
import * as selectors from '../../../../store/backoffice/appointmentRequest/selectors';

export const messages = defineMessages({
  searchExplanation: {
    defaultMessage:
      'You can search by appointment id, request type, npi, status, patient full name or appointment request token',
    id: 'appointmentRequestList.searchExplanation',
  },
});
@autobind
class AppointmentRequestList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    listAppointmentRequests: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    appointmentRequests: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.props.listAppointmentRequests(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  onClick(rowData) {
    this.props.router.push(`/secure/appointmentRequests/${rowData.id}/`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.listAppointmentRequests(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { appointmentRequests, count } = this.props;

    return (
      <div>
        <FormattedMessage {...messages.searchExplanation} />
        <hr />
        <QueryStringGrid
          data={appointmentRequests}
          totalCount={count}
          onRowClick={this.onClick}
          onQueryChange={this.onQueryChange}
          allowSearch
          classNames={{ Table: 'bkmd-table auto-layout' }}
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="appointmentRequestType" title="Request type" />
          <BkmdGridColumn id="status" title="Status" />
          <BkmdGridColumn id="providerName" title="Provider" sortable={false} />
          <BkmdGridColumn id="memberName" title="Patient Name" sortable={false} />
          <BkmdGridColumn id="isHybrid" title="Is Hybrid" customComponent={Columns.BooleanColumn} />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      appointmentRequests: selectors.getAppointmentRequestList(state),
      count: selectors.getAppointmentRequestListCount(state),
    }),
    { listAppointmentRequests },
  ),
)(AppointmentRequestList);
