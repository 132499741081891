import {
  SCHEDULE_CLINIC,
  SCHEDULE_PROVIDER_RESOURCE,
  SCHEDULE_CALENDAR,
  REFERRAL,
  SCHEDULE_HEALTH_SYSTEM,
} from '../../../../../model/enum/aclResourceType';

export const healthSystemType = 'healthSystem';
export const clinicType = 'clinic';
export const providerResourceType = 'providerResource';
export const calendarType = 'calendar';
export const appointmentType = 'appointment';
export const referralType = 'referral';

export const permissionMapper = {
  [healthSystemType]: [SCHEDULE_HEALTH_SYSTEM],
  [clinicType]: [SCHEDULE_CLINIC],
  [providerResourceType]: [SCHEDULE_PROVIDER_RESOURCE],
  [calendarType]: [SCHEDULE_CALENDAR],
  [referralType]: [REFERRAL],
  [appointmentType]: [SCHEDULE_CALENDAR],
};
