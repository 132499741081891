/**
 * Created by anna on 24/07/2017.
 */
import moment from 'moment';
import _ from 'lodash';

import PromiseActionType from '../../promiseActionType';
import commonFormats from '../../../utils/formatter/commonFormats';

export const GET_REVIEW = new PromiseActionType('GET_REVIEW');
export const GET_ALL_REVIEWS = new PromiseActionType('GET_ALL_REVIEWS');
export const HIDE_REVIEW = new PromiseActionType('HIDE_REVIEW');
export const EDIT_REVIEW = new PromiseActionType('EDIT_REVIEW');
export const CREATE_REVIEW = new PromiseActionType('CREATE_REVIEW');
export const UPDATE_REVIEW_STATUS_ACCEPTED = new PromiseActionType('UPDATE_REVIEW_STATUS_ACCEPTED');
export const UPDATE_REVIEW_STATUS_DECLINED = new PromiseActionType('UPDATE_REVIEW_STATUS_DECLINED');
export const UPDATE_REVIEW_STATUS_PENDING = new PromiseActionType('UPDATE_REVIEW_STATUS_PENDING');

function formatData(reviews) {
  const { count, data } = reviews;
  return {
    data: _.map(_.cloneDeep(data), review => Object.assign(review, {
      createdAt: moment(review.createdAt).format(commonFormats.DATE_AND_TIME),
      updatedAt: moment(review.updatedAt).format(commonFormats.DATE_AND_TIME),
      reviewDate: review.reviewDate &&
      moment(review.reviewDate).format(commonFormats.DATE_AND_TIME),
    })),
    count,
  };
}

export function getAllReviews(skip = 0, limit = 0, search, sort, sortAscending, options = null) {
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: GET_ALL_REVIEWS.SOURCE,
    payload: {
      promise: feedbackSurveyApi.getAllReviews(skip, limit, search, sort, sortAscending, options)
        .then(res => res.data)
        .then(reviews => formatData(reviews))
    },
    meta: {
      tracker: GET_ALL_REVIEWS.SOURCE,
    },
  });
}

export function getReview(token) {
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: GET_REVIEW.SOURCE,
    payload: {
      promise: feedbackSurveyApi.getReviewProcessByToken(token)
        .then(res => res.data)
    }
  });
}

export function createReview(appointment) {
  return ({ dispatch, bkmdApi: { feedbackSurveyApi } }) => ({
    type: CREATE_REVIEW.SOURCE,
    payload: {
      promise: feedbackSurveyApi.createReview(appointment)
        .then(() => dispatch(getAllReviews())),
    },
    meta: {
      tracker: CREATE_REVIEW.SOURCE,
    },
  });
}

export function updateStatus(token, status, actionType) {
  return ({ bkmdApi: { feedbackSurveyApi }, dispatch }) => ({
    type: actionType.SOURCE,
    payload: {
      promise: feedbackSurveyApi.updateStatus(token, status)
        .then(() => {
          dispatch(getReview(token));
        })
    },
    meta: {
      tracker: actionType.SOURCE
    },
  });
}

export function updateToAcceptedStatus(token, status) {
  return updateStatus(token, status, UPDATE_REVIEW_STATUS_ACCEPTED);
}

export function updateToPendingStatus(token, status) {
  return updateStatus(token, status, UPDATE_REVIEW_STATUS_PENDING);
}

export function updateToDeclinedStatus(token, status) {
  return updateStatus(token, status, UPDATE_REVIEW_STATUS_DECLINED);
}

export function saveReviewText({ token, reviewText }) {
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: EDIT_REVIEW.SOURCE,
    payload: {
      promise: feedbackSurveyApi.saveReviewText(token, reviewText)
        .then(res => res.data)
    },
    meta: {
      tracker: EDIT_REVIEW.SOURCE
    },
  });
}

