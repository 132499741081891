/**
 * Created by anna on 24/07/2017.
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import genericReducer from '../../genericReducer';
import { GET_ALL_REVIEWS, GET_REVIEW, HIDE_REVIEW, EDIT_REVIEW } from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  details: {},
};

export default combineReducers({
  list: genericReducer(GET_ALL_REVIEWS, initialState.list, null, { keepStateOnStart: true }),
  details: reduceReducers(
    genericReducer(GET_REVIEW, initialState.details),
    genericReducer(HIDE_REVIEW, initialState.details),
    genericReducer(EDIT_REVIEW, initialState.details),
  ),
});
