export default {
  label: 'Athena',
  schema: {
    properties: {
      athena__base_url: {
        title: 'Base url',
      },
      athena__auth_base_url: {
        title: 'Auth base url',
      },
      athena__username: {
        title: 'username',
      },
      athena__password: {
        title: 'password',
      },
    },
  },
};
