/**
 * created by moran on 11/28/17
 */
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import genericReducer from '../../genericReducer';

import {
  LIST_APPOINTMENT_REQUESTS,
  UPDATE_APPOINTMENT_REQUEST,
  CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS,
  GET_APPOINTMENT_REQUEST_BY_ID,
} from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
  details: {},
};

export default combineReducers({
  list: genericReducer(LIST_APPOINTMENT_REQUESTS, initialState.list),
  details: reduceReducers(
    genericReducer(GET_APPOINTMENT_REQUEST_BY_ID, initialState.details),
    genericReducer(UPDATE_APPOINTMENT_REQUEST, initialState.details),
    genericReducer(CHECK_AND_UPDATE_APPOINTMENT_REQUEST_STATUS, initialState.details),
  ),
});
