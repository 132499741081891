/**
 * created by moran on 10/31/17
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn } from '../../../../components/grid';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import QueryStringGrid, { gridQueryParams } from '../../../../components/grid/queryStringGrid';
import CreateProfileAction from './actions/createProviderProfile';

import { getAllProviderProfiles } from '../../../../store/backoffice/providerProfile/actions';
import * as selectors from '../../../../store/backoffice/providerProfile/selectors';

@autobind
class ProviderProfileList extends React.Component {
  static propTypes = {
    providers: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    router: PropTypes.object.isRequired,
    getAllProviderProfiles: PropTypes.func.isRequired,
  };

  componentWillMount() {}

  onClick(rowData) {
    this.props.router.push(`/secure/providerProfile/${rowData.id}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllProviderProfiles(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { providers, count } = this.props;

    return (
      <div>
        <CreateProfileAction />
        <QueryStringGrid
          data={providers}
          totalCount={count}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onClick}
          allowSearch
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="firstName" title="First Name" />
          <BkmdGridColumn id="middleName" title="Middle Name" />
          <BkmdGridColumn id="lastName" title="Last Name" />
          <BkmdGridColumn id="userId" title="User Id" />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  fetchNotEmpty(
    ({ props }) =>
      getAllProviderProfiles(
        props.skip,
        props.limit,
        props.search,
        props.sort,
        props.sortAscending,
      ),
    selectors.getProviderProfilesList,
    { renderAlways: true, loading: true },
  ),
  connect(
    state => ({
      providers: selectors.getProviderProfilesList(state),
      count: selectors.getProviderProfilesCount(state),
    }),
    { getAllProviderProfiles },
  ),
)(ProviderProfileList);
