import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { patientMessages } from './formDefs';
import PatientDataClean from './patientDataClean';

export default function PatientView({
  patient,
  title,
}) {
  return (
    <WrappedFormik
      enableReinitialize
      initialValues={patient}
      render={({ values }) => (
        <Form
          className="namespace-details-card col-sm-6 col-sm-offset-3 margin-bottom-clean-form"
          method="post"
        >
          <div className="row padding-h-20">
            <div className="row margin-bottom-30">
              <div className="col-xs-6 text-16 margin-top margin-bottom">
                <h4>{title}</h4>
              </div>
            </div>
            <PatientDataClean patient={values} />
          </div>
        </Form>
      )}
    />
  );
}

PatientView.propTypes = {
  patient: PropTypes.object.isRequired,
  title: PropTypes.string,
};

PatientView.defaultProps = {
  title: patientMessages.title.defaultMessage,
};
