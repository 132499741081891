import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStateHandlers, withPropsOnChange } from 'recompose';

import { BkmdGridColumn, Columns } from '../../../../../components/grid';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../../components/grid/queryStringGrid';
import ToggleButton from '../../../../../storybook/src/components/atoms/ToggleButton/toggleButton';
import * as selectors from '../../../../../store/backoffice/clinicManagement/appointmentTypes/selectors';
import AddAppointmentTypeAction from './addAppointmentType/addAppointmentType';
import {
  createAppointmentType,
  getAppointmentTypesList,
} from '../../../../../store/backoffice/clinicManagement/appointmentTypes/actions';
import { listMedicalCodes } from '../../../../../store/backoffice/medicalCodes/actions';
import { getMedicalCodeList } from '../../../../../store/backoffice/medicalCodes/selectors';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import routingSelectors from '../../../../../store/routing/selectors';

import './appointmentTypeList.less';

const onQueryChange = function onQueryChange() {};

@autobind
class AppointmentTypeList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAppointmentTypesList: PropTypes.func.isRequired, // from redux
    listMedicalCodes: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    medicalCodes: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
    dontShowSoftDeleted: PropTypes.bool.isRequired,
    onShowSoftDeletedChanged: PropTypes.func.isRequired,
    dataToShow: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.props.getAppointmentTypesList(this.props.params.resourceId, true);
    this.props.listMedicalCodes();
  }

  onClick(rowData) {
    const { baseUrl } = this.props;
    const { id: appointmentTypeId } = rowData;
    this.props.router.push(`${baseUrl}/${appointmentTypeId}`);
  }

  onCreateAppointmentType(data) {
    this.props.createAppointmentType(
      _.extend(
        {
          resourceId: this.props.params.resourceId,
        },
        data,
      ),
    );
  }

  render() {
    const {
      dataToShow,
      count,
      medicalCodes,
      dontShowSoftDeleted,
      onShowSoftDeletedChanged,
    } = this.props;
    return (
      <div>
        <div className="action-bar">
          <div className="backoffice-action inline-block-element">
            <AddAppointmentTypeAction
              onCreateAppointmentType={this.onCreateAppointmentType}
              medicalCodes={medicalCodes}
            />
          </div>
          <div className="inline-block-element float-element-right">
            <ToggleButton
              value={dontShowSoftDeleted}
              onChange={onShowSoftDeletedChanged}
              rightLabel="Show Deleted Types"
              leftLabel=""
              isOnOffMode
            />
          </div>
        </div>
        <hr />
        <QueryStringGrid
          data={dataToShow}
          totalCount={count}
          onQueryChange={onQueryChange}
          onRowClick={this.onClick}
        >
          <BkmdGridColumn id="id" title="Id" />
          <BkmdGridColumn id="commonName" title="Name" />
          <BkmdGridColumn id="ehrAppointmentType" title="EHR appointment type" />
          <BkmdGridColumn id="type" title="Type" />
          <BkmdGridColumn id="key" title="Key" />
          <BkmdGridColumn id="duration" title="Duration (in minutes)" />
          <BkmdGridColumn
            id="isTelemedicine"
            title="Is telemedicine"
            customComponent={Columns.BooleanColumn}
          />
          <BkmdGridColumn
            id="isDeleted"
            title="Is Deleted"
            customComponent={Columns.BooleanColumn}
          />
        </QueryStringGrid>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      services: selectors.appointmentTypesListSelector(state),
      count: selectors.getAppointmentTypesCount(state),
      medicalCodes: getMedicalCodeList(state),
      baseUrl: routingSelectors.getCurrentUrl(state),
    }),
    { getAppointmentTypesList, createAppointmentType, listMedicalCodes },
  ),
  withStateHandlers(
    {
      dontShowSoftDeleted: true,
    },
    {
      onShowSoftDeletedChanged: ({ dontShowSoftDeleted }) => () => ({
        dontShowSoftDeleted: !dontShowSoftDeleted,
      }),
    },
  ),
  withPropsOnChange(['services', 'dontShowSoftDeleted'], props => {
    const { services, dontShowSoftDeleted } = props;
    const dataToShow = dontShowSoftDeleted ? _.filter(services, type => !type.isDeleted) : services;

    return { dataToShow, count: dataToShow.length };
  }),
)(AppointmentTypeList);
