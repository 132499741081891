import _ from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router';

// this is a temporary component to bridge the fact Atlas has a different version of react-router
const withGoBack = withPropsOnChange(['router'], props => {
  if (_.isFunction(_.get(props, 'router.goBack'))) {
    return {
      goBackFunc: props.router.goBack,
    };
  }
  if (_.isFunction(_.get(props, 'router.history.goBack'))) {
    return {
      goBackFunc: props.router.history.goBack,
    };
  }
  if (_.isFunction(_.get(props, 'history.goBack'))) {
    return {
      goBackFunc: props.history.goBack,
    };
  }
  return {
    goBackFunc: props.goBack,
  };
});

export default compose(
  withRouter,
  withGoBack,
);
