/**
 * Created by meirshalev 31/05/2018.
 */
import { combineReducers } from 'redux';
import { GET_ALL_FEATURE_FLAGS, GET_ALL_FEATURES } from './actions';
import genericReducer from '../../genericReducer';

export default combineReducers({
  features: genericReducer(GET_ALL_FEATURES, {}, null, { keepStateOnStart: true }),
  featureFlags: genericReducer(GET_ALL_FEATURE_FLAGS, {}, null, { keepStateOnStart: true }),
});
