import React from 'react';
import CSVReader from 'react-csv-reader';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlString } from '../../components/ui/intlString';
import './cleanCSVReader.less';

const CleanCSVReader = ({ errorText, onChange = _.noop, ...props }) => (
  <div className="clean-csv-reader">
    <CSVReader onFileLoaded={onChange} {...props} />
    {errorText ? (
      <span className="validation-message">
        <IntlString message={errorText} />
      </span>
    ) : null}
  </div>
);

CleanCSVReader.propTypes = {
  errorText: intlShape,
  onChange: PropTypes.func,
};

CleanCSVReader.defaultProps = {
  errorText: undefined,
  onChange: _.noop,
};

export default CleanCSVReader;
