/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import { name as formName } from './addPermissionFormDefs';
import AddPermissionForm from './addPermissionForm';
import { addPermission, ADD_PERMISSION } from '../../../../../../store/backoffice/users/actions';
import { getPhiAccess } from '../../../../../../store/auth/selectors';

@autobind
class AddPermissionAction extends React.PureComponent {
  static propTypes = {
    addPermission: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isGroup: PropTypes.bool.isRequired,
    hasPhiAccess: PropTypes.bool.isRequired,
  };

  callAction(data) {
    this.props.addPermission(
      data.promoteOnly,
      this.props.id,
      data.permission,
      data.resourceType,
      data.resourceId,
      this.props.isGroup,
    );
  }

  render() {
    return (
      <Action
        name="addPermission"
        title="Add Permission"
        formName={formName}
        actionTrackerId={ADD_PERMISSION.SOURCE}
        {...this.props}
      >
        <AddPermissionForm onSubmit={this.callAction} hasPhiAccess={this.props.hasPhiAccess} />
      </Action>
    );
  }
}

export default connect(
  state => ({
    hasPhiAccess: getPhiAccess(state),
  }),
  { addPermission },
)(AddPermissionAction);
