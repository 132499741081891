/**
 * Created by ofirHartuv on 30/10/17.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { autobind } from 'core-decorators';

import EntityForm from '../../components/entityForm/entityForm';
import * as MachineFormDefs from './machineFormDefs';
import {
  createMachineResource,
  CREATE_MACHINE,
} from '../../../../../store/backoffice/clinicManagement/machines/actions';
import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';

const machineMock = {
  name: '',
  machineType: '',
  manufacturer: '',
  model: '',
  year: '',
  strength: '',
  limitations: '',
};

@autobind
class CreateMachine extends React.Component {
  static propTypes = {
    createMachineResource: PropTypes.func.isRequired, // from redux
    createMachineForClinicTracker: PropTypes.shape(actionTrackerShape).isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { createMachineForClinicTracker, params } = nextProps;
    if (createMachineForClinicTracker.finished && !createMachineForClinicTracker.hasError) {
      this.props.router.push(`/secure/clinics/${params.id}/machines`);
    }
  }

  create(data) {
    const { createMachineResource, params } = this.props;
    createMachineResource({ ...data, clinicId: params.id });
  }

  render() {
    return (
      <div>
        <Link to="/secure/machines/" className="btn btn-inline-link blue-link text14">
          Back To Machines
        </Link>
        <EntityForm
          name={MachineFormDefs.name}
          title="Create New Machine:"
          submitText="Create"
          schemaDef={MachineFormDefs.schema}
          fieldsDef={MachineFormDefs.fields}
          entity={machineMock}
          onSubmit={this.create}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker(
    {
      createMachineForClinicTracker: CREATE_MACHINE.SOURCE,
    },
    {
      createMachineForClinicTracker: {
        success: { title: '', text: 'Machine resource created for clinic' },
        error: {
          title: '',
          text: 'Make sure you are in Vim domain and machine exist',
        },
      },
    },
  ),
  connect(
    null,
    { createMachineResource },
  ),
)(CreateMachine);
