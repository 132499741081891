import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import CollapsibleCard from 'Components/ui/collapsibleCard/collapsibleCard';
import { WrappedFormik } from 'Components/form/formik/wrappedFormik';
import { CleanFormikInput } from 'Components/form/formik/cleanFormikInput';
import { FormikDateTimeComponent } from 'Components/form/formik/formikDateTimeComponent';
import * as AppointmentFormDefs from './formDefs';
import AppointmentActionsList from '../../appointmentActionsList/index';
import PatientView from '../../patientsList/patientDetails/forCollapsible/index';

import './index.less';

export const messages = defineMessages({
  appointmentDetails: {
    defaultMessage: 'Appointment details:',
    id: 'collapsibleAppointment.appointment.appointmentDetails',
  },
});

export function AppointmentDetails({ appointment, params }) {
  const { appointmentMessages } = AppointmentFormDefs;

  return (
    <WrappedFormik
      enableReinitialize
      initialValues={appointment}
      render={({ values }) => (
        <Form
          className="namespace-details-card col-sm-6 col-sm-offset-3 margin-bottom-clean-form appointment-details-form"
          method="post"
        >
          <div className="row padding-h-20">
            <div className="row margin-bottom-30">
              <div className="col-xs-12 text-16 margin-top margin-bottom">
                <h4>
                  <FormattedMessage {...messages.appointmentDetails} />
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <Field
                  name="id"
                  label={appointmentMessages.id}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
              <div className="col-xs-3">
                <Field
                  name="status"
                  label={appointmentMessages.status}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
              <div className="col-xs-6">
                <Field
                  name="appointmentType"
                  label={appointmentMessages.appointmentType}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <Field
                  name="ehrLocationId"
                  label={appointmentMessages.ehrLocationId}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
              <div className="col-xs-3">
                <Field
                  name="npi"
                  label={appointmentMessages.npi}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
              <div className="col-xs-3">
                <Field
                  name="platformId"
                  label={appointmentMessages.platformId}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <Field
                  name="reasonForVisit"
                  label={appointmentMessages.reasonForVisit}
                  component={CleanFormikInput}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Field
                  name="startTime"
                  label={appointmentMessages.startTime}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                  disabled
                />
              </div>
              <div className="col-xs-12">
                <Field
                  name="endTime"
                  label={appointmentMessages.endTime}
                  component={FormikDateTimeComponent}
                  clearable={false}
                  timeZone={values.timezone}
                  disabled
                />
              </div>
            </div>
          </div>
          <CollapsibleCard
            title="Appointment's patient"
            id="backoffice-ns-patient-details-id"
            className="patient-details-card light-blue-title bg-grey-color"
          >
            <PatientView patient={values.patient} />
          </CollapsibleCard>
          <CollapsibleCard
            title="Appointment's actions"
            id="backoffice-ns-appointment-actions-id"
            className="patient-details-card light-blue-title bg-grey-color"
          >
            <AppointmentActionsList
              filter={{ platformId: values.platformId }}
              params={{ namespaceName: params.namespaceName }}
              allowSearch={false}
              fieldsToShow={[
                {
                  id: 'status',
                  title: 'Status',
                },
                {
                  id: 'actionType',
                  title: 'Action Type',
                },
              ]}
            />
          </CollapsibleCard>
        </Form>
      )}
    />
  );
}

AppointmentDetails.propTypes = {
  appointment: PropTypes.object.isRequired,
};
