import { withRouter } from 'react-router';
import { compose, withPropsOnChange, withProps, setPropTypes } from 'recompose';
import { withTranslatedMessages } from 'Utils/withTranslatedMessages';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import queryConnect from 'Store/tools/queryString/queryConnect';
import _ from 'lodash';
import injectNotification from 'Store/notification/injectNotification';
import { getDomain } from 'Utils/domain/storage';
import PropTypes from 'prop-types';
import { withFetchers } from '../../../../../../../../../api/injectApi/withFetchers';
import Api from '../../../../../../../../../api';
import planMappingCreateMessages from './PlanMappingCreateMessages';
import { withStateFetchersOnMount } from '../../../../../../../../../api/injectApi/withStateFetchersOnMount';
import PlanMappingForm from '../../../PlanMappingForm/PlanMappingForm';

const createPlanMappingHandler = () => async data => {
  data.insurer = data.insurer.value;
  data.payerDescription = data.payerDescription || null;
  await Api.clinicsApi.createPlanMapping({ ...data });
};

const createPlanMappingSuccess = (
  { onPlanMappingCreated, closeModal, notification, createSuccessText },
  result,
) => {
  notification.success('', createSuccessText);
  onPlanMappingCreated(result);

  if (closeModal) {
    closeModal();
  }
};

const createPlanMappingError = ({ notification, createErrorText }) =>
  notification.error('', createErrorText);

const isCreatePlanMappingLoading = ({ onSubmitTracker }) => ({
  isLoading: _.get(onSubmitTracker, 'inProgress', false),
});

const getEhrPlanMappingInitialData = ({ healthSystemId }) => ({
  ehrPlanMappingData: {
    data: {
      ehrId: undefined,
      ehrExternalId: '',
      ehrDescription: '',
      payerId: undefined,
      healthSystemId,
      payer: getDomain(),
      payerDescription: '',
      insurer: '',
    },
  },
});

const PlanMappingCreateContainer = compose(
  withRouter,
  injectNotification,
  withTranslatedMessages(planMappingCreateMessages),
  setPropTypes({
    healthSystemId: PropTypes.number.isRequired,
    onPlanMappingCreated: PropTypes.func.isRequired,
  }),
  withProps(getEhrPlanMappingInitialData),
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  withStateFetchersOnMount({
    getInsurers: {
      handler: () => async () => (await Api.eligibilityApi.getInsurers()).data,
      resultPropName: 'insurers',
      isReady: ({ insurers }) => !_.isEmpty(insurers),
    },
  }),
  withFetchers({
    onSubmit: {
      handler: createPlanMappingHandler,
      onSuccess: createPlanMappingSuccess,
      onError: createPlanMappingError,
    },
  }),
  withPropsOnChange(['onSubmitTracker'], isCreatePlanMappingLoading),
)(PlanMappingForm);

export default PlanMappingCreateContainer;
