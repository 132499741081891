import _ from 'lodash';
import * as ServiceKeyType from '../../model/enum/serviceKeyType';

// Column definition
const NPI_COL = 0;
const BLOCK_SIZE_COL = 1;
const SHORT_BIO = 2;
const INTEGRATION_LEVEL = 3;
const EHR_PROVIDER_ID = 4;
const OH_SUNDAY = 5;
const OH_MONDAY = 6;
const OH_TUESDAY = 7;
const OH_WEDNESDAY = 8;
const OH_THURSDAY = 9;
const OH_FRIDAY = 10;
const OH_SATURDAY = 11;
export const CLINIC_ID = 12;
export const CLINIC_NPI = 13;

const colIndexDescriptionProviders = [
  'NPI',
  'BlockSize',
  'ShortBio',
  'IntegrationLevel',
  'EhrProviderID',
  'OH Sunday',
  'OH Monday',
  'OH Tuesday',
  'OH Wednesday',
  'OH Thursday',
  'OH Friday',
  'OH Saturday',
  'ClinicId',
  'ClinicNpi',
];

function getRelevantMedicalCodes(medicalCodes) {
  const vimMedicalCodes = _.filter(medicalCodes, { type: ServiceKeyType.VIM });
  return _.map(vimMedicalCodes, 'key');
}

function isValidRow(currentRow, rowNumber, medicalCodes, invalidProviders, validProviders) {
  const relativeRowNumber = rowNumber + 2;
  if (_.isEmpty(_.compact(currentRow))) {
    invalidProviders.push({
      rowNumber: relativeRowNumber,
      currentRow,
      error: 'Empty row',
    });
    return false;
  }

  // fields schema validation
  if (!currentRow[NPI_COL] || !_.toNumber(currentRow[NPI_COL])) {
    invalidProviders.push({
      rowNumber: relativeRowNumber,
      npi: currentRow[NPI_COL],
      currentRow,
      error: 'No Valid NPI Provided',
    });
    return false;
  }
  if (!currentRow[BLOCK_SIZE_COL] || !_.toNumber(currentRow[BLOCK_SIZE_COL])) {
    invalidProviders.push({
      rowNumber: relativeRowNumber,
      npi: currentRow[NPI_COL],
      currentRow,
      error: 'No Valid BlockSize Provided',
    });
    return false;
  }
  return true;
}

function checkOrderColumnsFileProviders(header) {
  const arrayToCheck = colIndexDescriptionProviders;
  for (let i = 0; i < header.length; i++) {
    if (header[i].trim() !== arrayToCheck[i].trim()) {
      return `ERROR - The order of columns in the file is incorrect ${arrayToCheck[i]}`;
    }
  }
  return null;
}

function getUniqueClinicId(rows) {
  const rowsWithClinicId = _.filter(rows, row => !_.isEmpty(row[CLINIC_ID]));
  const rowsWithClinicNPI = _.filter(rows, row => !_.isEmpty(row[CLINIC_NPI]));

  if (rows.length === rowsWithClinicId.length) {
    return CLINIC_ID;
  }
  if (rows.length === rowsWithClinicNPI.length) {
    return CLINIC_NPI;
  }

  return null;
}

function formatTime(oh) {
  oh.trim();
  const times = oh.split('-');
  const open = times[0];
  const close = times[1];
  return { open, close };
}

function getDayString(index) {
  switch (index) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
  }
  return '';
}

function formatOpenHours(
  OHSunday,
  OHMonday,
  OHTuesday,
  OHWednesday,
  OHThursday,
  OHFriday,
  OHSaturday,
) {
  const openHoursArray = [
    OHSunday,
    OHMonday,
    OHTuesday,
    OHWednesday,
    OHThursday,
    OHFriday,
    OHSaturday,
  ];
  const openHoursObject = {};
  _.map(openHoursArray, (oh, index) => {
    if (oh !== '') {
      const { open, close } = formatTime(oh);
      const dayString = getDayString(index);
      openHoursObject[dayString] = { isOpen: true, open, close };
    } else {
      const defaultOpenHours = { isOpen: false, open: '09:00', close: '17:00' };
      const dayString = getDayString(index);
      openHoursObject[dayString] = defaultOpenHours;
    }
  });
  return openHoursObject;
}

function checkDuplicateRows(rows, i) {
  const sliceArray = _.slice(rows, i + 1);
  if (_.some(sliceArray, slice => _.isEqual(slice, rows[i]))) {
    return {
      errorMsg: `Duplicate rows in file , row number${i}`,
      NPI: rows[i][NPI_COL],
      clinicId: rows[i][CLINIC_ID],
    };
  }
  return null;
}

export default function analyzeProvidersCSVRows(rows, medicalCodes) {
  const providersMedicalCodes = getRelevantMedicalCodes(medicalCodes);
  const clinicIdArray = [];
  const invalidProviders = [];
  const validProviders = [];
  const calendars = [];
  const errors = [];
  let error = null;

  const errorColumnsOrder = checkOrderColumnsFileProviders(rows[0]);

  if (errorColumnsOrder) {
    return { errorColumnsOrder };
  }

  const rowsWithoutHeaders = _.chain(rows)
    .slice(1)
    .filter(row => row !== [''])
    .value();
  const clinicUniqueId = getUniqueClinicId(rowsWithoutHeaders);
  if (!clinicUniqueId) {
    return { errorUniqueClinicId: 'ERROR - Must have ClinicId or ClinicNpi for all providers' };
  }

  for (let rowNumber = 0; rowNumber < rowsWithoutHeaders.length; ++rowNumber) {
    error = checkDuplicateRows(rowsWithoutHeaders, rowNumber + 1);

    if (error) {
      errors.push(error);
    }
    const currentRow = rowsWithoutHeaders[rowNumber];
    const clinicId = rowsWithoutHeaders[rowNumber][CLINIC_ID];

    if (
      isValidRow(currentRow, rowNumber, providersMedicalCodes, invalidProviders, validProviders)
    ) {
      const npi = currentRow[NPI_COL];
      const clinicId = currentRow[CLINIC_ID];
      const clinicNpi = currentRow[CLINIC_NPI];
      // from minutes to miliseconds
      const blockSize = currentRow[BLOCK_SIZE_COL] * 60 * 1000;
      const bio = currentRow[SHORT_BIO];
      const integrationLevel = currentRow[INTEGRATION_LEVEL];
      const ehrProviderId = currentRow[EHR_PROVIDER_ID];
      const OHSunday = currentRow[OH_SUNDAY];
      const OHMonday = currentRow[OH_MONDAY];
      const OHTuesday = currentRow[OH_TUESDAY];
      const OHWednesday = currentRow[OH_WEDNESDAY];
      const OHThursday = currentRow[OH_THURSDAY];
      const OHFriday = currentRow[OH_FRIDAY];
      const OHSaturday = currentRow[OH_SATURDAY];

      const openHours = formatOpenHours(
        OHSunday,
        OHMonday,
        OHTuesday,
        OHWednesday,
        OHThursday,
        OHFriday,
        OHSaturday,
      );
      validProviders.push({
        rowNumber,
        identifier: npi,
        blockSize,
        clinicId,
        clinicNpi,
        integrationLevel,
        ehrProviderId,
        openHours,
        bio,
      });
    }
    clinicIdArray.push(clinicId);
  }

  return { clinicUniqueId, validProviders, invalidProviders, clinicIdArray, errors, calendars };
}
