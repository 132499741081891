import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, Form } from 'formik';
import { object, array } from 'yup';
import { defineMessages } from 'react-intl';

import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../../../../api';
import { WrappedFormik } from '../../../../../../components/form/formik/wrappedFormik';
import injectNotification from '../../../../../../store/notification/injectNotification';
import BkmdModal from '../../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import FormActionButtons from '../../../../../../components/molecules/FormSubmissionButtons/FormActionButtons';
import { CleanFormikSelect } from '../../../../../../components/form/formik/cleanFormikSelect';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Select clinics to attach:',
    id: 'healthSystem.attachClinic.title',
  },
});

const schema = object().shape({
  clinics: array().required('Health system name required'),
});

const AttachClinicsView = ({ handleClose, isOpen, clinics, initialValues, attachClinics }) => (
  <BkmdModal isOpen={isOpen} className="fixed-header overflow-visible" handleClose={handleClose}>
    <ModalHeader onClose={handleClose} title={messages.title} />
    <div className="dialog-body">
      <WrappedFormik
        initialValues={initialValues}
        onSubmit={attachClinics}
        validationSchema={schema}
        render={data => (
          <Form>
            <div className="row">
              <div className="col-xs-12">
                <Field
                  name="clinics"
                  label="Clinics"
                  component={CleanFormikSelect}
                  options={clinics}
                  multi
                />
              </div>
            </div>
            <FormActionButtons primaryButtonText="Attach" primaryDisabled={!data.isValid} />
          </Form>
        )}
      />
    </div>
  </BkmdModal>
);

AttachClinicsView.propTypes = {
  attachClinics: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  clinics: PropTypes.array.isRequired,
};

AttachClinicsView.defaultProps = {
  initialValues: { clinics: [] },
};

const AttachClinicsForm = compose(
  injectNotification,
  withStateFetchersOnMount({
    getClinics: {
      handler: () => () =>
        Api.clinicsApi
          .getClinics()
          .then(result =>
            _.map(result.data, clinic => ({ value: clinic.id, label: clinic.officeName })),
          ),
      resultPropName: 'clinics',
      isReady: ({ clinics }) => !_.isEmpty(clinics),
    },
  }),
  withFetchers({
    attachClinics: {
      handler: ({ healthSystemId }) => ({ clinics }) =>
        Api.clinicsApi.attachClinicsToHealthSystem(healthSystemId, _.map(clinics, 'value')),
      onSuccess: ({ notification, handleClose }) => {
        notification.success('Attach Clinics', `Successfully attached clinics`);
        handleClose();
      },
      onError: ({ notification, handleClose }) => {
        notification.error('Attach Clinics', `Failed to attach clinics`);
        handleClose();
      },
    },
  }),
)(AttachClinicsView);

AttachClinicsForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  healthSystemId: PropTypes.number.isRequired,
};

export default AttachClinicsForm;
