import { compose, withProps, setPropTypes } from 'recompose';
import { PropTypes } from 'prop-types';
import BkmdModalButton from 'Components/bkmdModalButton/bkmdModalButton';
import PlanMappingCreateContainer from './PlanMappingCreateContainer';

const PlanMappingCreateModalButton = compose(
  setPropTypes({
    healthSystemId: PropTypes.number.isRequired,
    onPlanMappingCreated: PropTypes.func.isRequired,
  }),
  withProps({
    title: 'Create Plan Mapping',
    buttonText: 'Create',
    component: PlanMappingCreateContainer,
  }),
)(BkmdModalButton);

export default PlanMappingCreateModalButton;
