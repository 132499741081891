/* eslint-disable react/prop-types */
import React from 'react';
import ReactJson from 'react-json-view';
import steps from './index';

export default {
  component: ({ providerCSI }) => (
    <div>
      <ReactJson src={providerCSI} displayDataTypes={false} displayObjectSize={false} />
    </div>
  ),
  transitions: {
    NEXT: steps.INVESTIGATION_PICKER,
    PREV: steps.INVESTIGATION_PICKER,
  },
  mapFlowDataToProps: ({ providerCSI }) => ({ providerCSI }),
  mapPayloadToFlowData: () => ({}),
};
