// /**
//  * Created by kobimizrahi on 06/05/2019.
//  */
import React from 'react';
import _ from 'lodash';
import { compose, withHandlers, pure, setDisplayName, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import './toggleButton.less';
import ClickableDiv from '../../../../../components/clickableElement/clickableDiv';

function ToggleButton(props) {
  const {
    value,
    leftLabel,
    rightLabel,
    shouldUseIcons,
    leftIcon,
    rightIcon,
    onToggleChange,
    isOnOffMode,
    readOnly,
  } = props;

  return (
    <div className="toggle-button-component text-left">
      <div className={`left-label ${value ? 'selected' : ''}`}>{leftLabel}</div>
      <ClickableDiv
        className={`toggle-button-center ${value && isOnOffMode ? 'off' : 'on'}`}
        onClick={readOnly ? _.noop : onToggleChange}
      >
        <div className={`toggle-circle ${value ? 'left' : ''}`}>
          {shouldUseIcons && (
            <i
              className={`fas ${
                value ? `${leftIcon} left-icon` : `${rightIcon} right-icon`
              } toggle-icon`}
            />
          )}
        </div>
      </ClickableDiv>
      <div className={`right-label ${value ? '' : 'selected'}`}>{rightLabel}</div>
    </div>
  );
}

ToggleButton.propTypes = {
  value: PropTypes.bool,
  isOnOffMode: PropTypes.bool,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  shouldUseIcons: PropTypes.bool,
  rightIcon: PropTypes.string,
  leftIcon: PropTypes.string,
  readOnly: PropTypes.bool,
  onToggleChange: PropTypes.func.isRequired,
};

ToggleButton.defaultProps = {
  value: false,
  readOnly: false,
  isOnOffMode: false,
  leftLabel: 'On',
  rightLabel: 'Off',
  shouldUseIcons: false,
  rightIcon: '',
  leftIcon: '',
};

const enhance = compose(
  setDisplayName('ToggleButton'),
  setPropTypes({
    onChange: PropTypes.func.isRequired,
  }),
  pure,
  withHandlers({
    onToggleChange: props => e => {
      const { onChange, value } = props;
      const newValue = !value;
      onChange(newValue, e);
    },
  }),
);

export default enhance(ToggleButton);
