/**
 * Created by guyavarham on 20/12/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import FieldGenerator, {
  fieldDefinitionsFromEnum,
} from '../../components/entityForm/fieldGenerator';
import BookMdUtil from '../../../../../utils/util';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import {
  dataMappingSelector,
  getApplications,
  getDomains,
} from '../../../../../store/backoffice/communication/selectors';
import { paramsReselector } from './utils';
import {
  getDataMapping,
  getTemplateById,
} from '../../../../../store/backoffice/communication/actions';
import NpiTypeahead from '../../../../../components/npi/npiTypeaheadWrapper';
import {
  ReduxFormReduxEnumSelect,
  ReduxFormCleanPhoneNumber,
} from '../../../../../components/form/reduxForm/components';
import FieldWrapper from '../../../../../components/form/reduxForm/fieldWrapper';

const paramToComponent = {
  npi: {
    component: FieldWrapper(NpiTypeahead),
    className: 'margin-bottom-clean-form',
  },
  domain: {
    component: ReduxFormReduxEnumSelect,
    enumSelector: getDomains,
  },
  application: {
    component: ReduxFormReduxEnumSelect,
    enumSelector: getApplications,
  },
  phoneNumber: {
    component: ReduxFormCleanPhoneNumber,
  },
};

/**
 * Renders fields for the params, that are required for a certain template
 */
@autobind
class TemplateParams extends React.PureComponent {
  static propTypes = {
    getTemplateById: PropTypes.func.isRequired,
    params: PropTypes.array.isRequired,
    templateId: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    BookMdUtil.inspectChange(this.props, nextProps, [
      {
        uri: 'templateId',
        action: () => nextProps.getTemplateById(nextProps.templateId),
      },
    ]);
  }

  render() {
    const { params } = this.props;
    const paramsFormDefs = fieldDefinitionsFromEnum(params, paramToComponent);
    return <FieldGenerator entity={paramsFormDefs} fieldsDef={paramsFormDefs} prefix="params" />;
  }
}

export default compose(
  fetchNotEmpty(getDataMapping, dataMappingSelector, {
    renderAlways: false,
    loading: true,
  }),
  connect(
    (state, ownProps) => ({
      params: paramsReselector(state, ownProps),
    }),
    { getTemplateById },
  ),
)(TemplateParams);
