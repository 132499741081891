import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withStateHandlers } from 'recompose';
import _ from 'lodash';
import injectNotification from '../../../../../../../store/notification/injectNotification';
import BkmdModalButton from '../../../../../../../components/bkmdModalButton/bkmdModalButton';
import Api from '../../../../../../../api/index';
import SpinnerButton from '../../../../../../../components/ui/spinner/spinnerButton';
import { withFetchers } from '../../../../../../../api/injectApi/withFetchers';
import PicturesToggle from './picturesToggle';
import BucketDirSelector from './bucketDirSelector';

function ScanProfilePicsS3View({
  checkedProviders,
  onScanProfilePics,
  onSave,
  onScanProfilePicsTracker,
  onSaveTracker,
  picturesTimestamp,
}) {
  return (
    <div>
      <div>
        <BucketDirSelector
          onScanProfilePics={onScanProfilePics}
          scanned={!_.isNil(checkedProviders)}
          isLoading={onScanProfilePicsTracker.inProgress}
        />
      </div>
      {checkedProviders && (
        <div>
          <PicturesToggle
            data={checkedProviders}
            picturesTimestamp={picturesTimestamp}
          />
        </div>
      )}
    </div>
  );
}

ScanProfilePicsS3View.propTypes = {
  checkedProviders: PropTypes.object,
  picturesTimestamp: PropTypes.number.isRequired,
  onScanProfilePics: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onScanProfilePicsTracker: PropTypes.object.isRequired,
  onSaveTracker: PropTypes.object.isRequired,
};

const ScanProfilePicsS3Action = compose(
  injectNotification,
  withStateHandlers(
    {
      picturesTimestamp: Date.now(),
    },
    {
      resetProviders: () => results => ({
        checkedProviders: _.chain(results)
          .map(currRes => ({ ...currRes, checked: true }))
          .keyBy('npi')
          .value(),
        picturesTimestamp: Date.now(),
      }),
    },
  ),
  withFetchers({
    onScanProfilePics: {
      handler: () => ({ srcBucket, srcDir }) =>
        Api.onBoardingApi.scanProfilePicsS3Action({ srcBucket, srcDir }),
      onSuccess: ({ resetProviders }, result) => resetProviders(result),
    },
    onSave: {
      handler: ({ checkedProviders }) => () => {
        const npisToSave = _.chain(checkedProviders)
          .filter(({ checked }) => checked)
          .map('npi')
          .value();

        if (_.isEmpty(npisToSave)) return Promise.resolve({ isEmpty: true });
        return Api.providerApi.saveProfilePictures(npisToSave);
      },

      onSuccess: ({ notification, closeModal }, { isEmpty }) => {
        if (isEmpty) {
          notification.error('', 'You must choose at least 1 picture to save!');
        } else {
          notification.success('', 'Pictures were saved successfully to profiles');
          closeModal();
        }
      },
    },
  }),
)(ScanProfilePicsS3View);

export default withProps({
  component: ScanProfilePicsS3Action,
  title: 'Scan Profile Pics From S3',
  buttonText: 'Scan Profile Pics From S3',
})(BkmdModalButton);
