/**
 * Created by moran on 6/26/17.
 */
import Joi from 'joi-browser';
import {
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanInput,
  ReduxFormCleanGeoPoint,
} from '../../../../components/form/reduxForm/components';

const PhoneNumberSchema = Joi.object().keys({
  number: Joi.string()
    .length(10)
    .options({
      language: {
        string: { length: '!!Please enter a valid 10 digit phone number' },
        any: { required: '!!Please enter a valid 10 digit phone number' },
      },
    })
    .required(),
  countryDialingCode: Joi.string().required(),
});

const GeoPoint = Joi.object().keys({
  longitude: Joi.number().required(),
  latitude: Joi.number().required(),
});

export const schema = {
  id: Joi.number(),
  phoneNumber: PhoneNumberSchema,
  fax: PhoneNumberSchema,
  geo: GeoPoint,
  addressLine1: Joi.string(),
  city: Joi.string(),
  state: Joi.string(),
  zip: Joi.number(),
  officeName: Joi.number(),
  createdAt: Joi.date(),
  updatedAtAt: Joi.date(),
};

export const fields = {
  id: {
    name: 'id',
    component: ReduxFormCleanInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  fax: {
    name: 'fax',
    component: ReduxFormCleanPhoneNumber,
  },
  geo: {
    name: 'geo',
    component: ReduxFormCleanGeoPoint,
  },
  addressLine1: {
    name: 'addressLine1',
    component: ReduxFormCleanInput,
  },
  city: {
    name: 'city',
    component: ReduxFormCleanInput,
  },
  state: {
    name: 'state',
    component: ReduxFormCleanInput,
  },
  zip: {
    name: 'zip',
    component: ReduxFormCleanInput,
  },
  officeName: {
    name: 'officeName',
    component: ReduxFormCleanInput,
  },
  createdAt: {
    name: 'createdAt',
    component: ReduxFormCleanInput,
  },
  updatedAtAt: {
    name: 'updatedAt',
    component: ReduxFormCleanInput,
  },
  vimChoice: {
    name: 'vimChoice',
    component: ReduxFormCleanInput,
  },
};
