import { compose, withProps } from 'recompose';
import { withTranslatedMessages } from '../../../../../../../utils/withTranslatedMessages';
import PatientView from './view';
import { patientMessages } from '../formDefs';

const getAdaptedPatientData = patient => ({
    address: patient.address,
    dateOfBirth: patient.dateOfBirth,
    ehrInsuranceId: patient.ehrInsuranceId,
    email: patient.email,
    fullName: `${patient.firstName} ${patient.lastName}`,
    insurer: patient.insurer,
    memberId: patient.memberId,
    phoneNumber: patient.mobilePhoneNumber,
    plan: patient.plan,
});

export default compose(
  withProps(({ patient }) => ({
    patient: getAdaptedPatientData(patient),
  })),
  withTranslatedMessages(patientMessages),
)(PatientView);
