/**
 * Created by chenrozenes on 13/06/2017.
 */
import _ from 'lodash';
import PromiseActionType from '../../promiseActionType';
import { getDomain } from '../../../utils/domain/storage';

export const GET_DATA_MAPPING = new PromiseActionType('GET_DATA_MAPPING');
export const GET_HISTORY = new PromiseActionType('GET_HISTORY');
export const GET_HISTORY_RECORD = new PromiseActionType('GET_HISTORY_RECORD');
export const GET_CHAT_HISTORY = new PromiseActionType('GET_CHAT_HISTORY');
export const SEND_CHAT_SMS = new PromiseActionType('SEND_CHAT_SMS');
export const LIST_TEMPLATES = new PromiseActionType('LIST_TEMPLATES');
export const UPDATE_TEMPLATE = new PromiseActionType('UPDATE_TEMPLATE');
export const CREATE_TEMPLATE = new PromiseActionType('CREATE_TEMPLATE');
export const DELETE_TEMPLATE = new PromiseActionType('DELETE_TEMPLATE');
export const MANUAL_NOTIFY = new PromiseActionType('MANUAL_NOTIFY');
export const GET_METADATA = new PromiseActionType('GET_METADATA');
export const GET_BY_TEMPLATE_ID = new PromiseActionType('GET_BY_TEMPLATE_ID');

/**
 * Gets all the variable available for usage in BookMD in email and sms templates
 * @returns {function(): {type: (*|string), payload: {promise: Promise.<*>}}}
 */
export function getDataMapping() {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_DATA_MAPPING.SOURCE,
    payload: {
      promise: communicationApi.getDataMapping().then(res => res.data)
    }
  });
}

export function getHistory(queryParams) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_HISTORY.SOURCE,
    payload: {
      promise: communicationApi.getHistory(queryParams).then(res => res.data)
    }
  });
}

export function getHistoryRecord(id) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_HISTORY_RECORD.SOURCE,
    payload: {
      promise: communicationApi.getHistoryRecord(id)
    }
  });
}

export function getChatHistoryOfMember(domainMemberId) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_CHAT_HISTORY.SOURCE,
    payload: {
      promise: communicationApi.getChatHistoryOfMember({ domainMemberId }).then(res => res.data)
    }
  });
}

export function sendChatSmsToMember(domainMemberId, message) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: SEND_CHAT_SMS.SOURCE,
    payload: {
      promise: communicationApi.sendChatSmsToMember({ domainMemberId, message })
        .then(res => res.data)
    }
  });
}

export function listTemplates(queryParams = { skip: 0, limit: 0 }) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: LIST_TEMPLATES.SOURCE,
    payload: {
      promise: communicationApi.listTemplates(queryParams).then(res => res.data)
    }
  });
}

export function getTemplateById(templateId) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_BY_TEMPLATE_ID.SOURCE,
    payload: {
      promise: communicationApi.getTemplateById(templateId).then(res => res.data),
    },
    meta: {
      tracker: GET_BY_TEMPLATE_ID.SOURCE,
    },
  });
}

export function createTemplate(payload) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: CREATE_TEMPLATE.SOURCE,
    payload: {
      promise: communicationApi.createTemplate(payload)
        .then(res => res.data)
        .then(() => listTemplates()),
    },
    meta: {
      tracker: CREATE_TEMPLATE.SOURCE,
    },
  });
}

export function updateTemplate(payload) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: UPDATE_TEMPLATE.SOURCE,
    payload: {
      promise: communicationApi.updateTemplate(payload).then(res => res.data),
    },
    meta: {
      tracker: UPDATE_TEMPLATE.SOURCE,
    },
  });
}

export function deleteTemplate(payload) {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: DELETE_TEMPLATE.SOURCE,
    payload: {
      promise: communicationApi.deleteTemplate(payload).then(res => res.data)
    },
    meta: {
      tracker: DELETE_TEMPLATE.SOURCE,
    },
  });
}

export function manualNotify(payload) {
  return ({ bkmdApi: { communicationApi } }) => {
    const domain = getDomain();
    return {
      type: MANUAL_NOTIFY.SOURCE,
      payload: {
        promise: communicationApi.manualNotify(
          _.extend({}, payload, { options: { bypass: true } }), domain
        ).then(res => res.data)
      },
      meta: {
        tracker: MANUAL_NOTIFY.SOURCE,
      },
    };
  };
}

export function getMetadata() {
  return ({ bkmdApi: { communicationApi } }) => ({
    type: GET_METADATA.SOURCE,
    payload: {
      promise: communicationApi.getMetadata().then(res => res.data)
    },
  });
}
