/**
 * Created by ofirHartuv on 30/10/17.
 */
import Promise from 'bluebird';
import PromiseActionType from '../../../promiseActionType';
import { VIM } from '../../../../model/enum/bkmdDomain';
import { getDomain } from '../../../../utils/domain/storage';

export const GET_ALL_PROVIDER_RESOURCES_BY_CLINIC = new PromiseActionType('GET_ALL_PROVIDER_RESOURCES_BY_CLINIC');
export const GET_ALL_MACHINE_RESOURCES_BY_CLINIC = new PromiseActionType('GET_ALL_MACHINE_RESOURCES_BY_CLINIC');
export const UPDATE_PROVIDER_RESOURCE = new PromiseActionType('UPDATE_PROVIDER_RESOURCE');
export const DELETE_PROVIDER_RESOURCE = new PromiseActionType('DELETE_PROVIDER_RESOURCE');
export const CREATE_PROVIDER_RESOURCE = new PromiseActionType('CREATE_PROVIDER_RESOURCE');
export const GET_PROVIDER_RESOURCE = new PromiseActionType('GET_PROVIDER_RESOURCE');
export const CREATE_MACHINE_RESOURCE = new PromiseActionType('CREATE_MACHINE_RESOURCE');

/**
 * Make sure we care currently in Vim domain
 */
function ensureVimDomain() {
  if (getDomain() !== VIM) {
    return Promise.reject(new Error('Must be in domain VIM'));
  }
  return Promise.resolve();
}

function ensureProviderInVim(providerApi, npi) {
  return providerApi.byNpi(npi, VIM)
    .then(provider => {
      if (!provider) throw new Error(`Provider ${npi} must exist in Vim domain`);
      return true;
    });
}

export function getAllProviderResourcesByClinic(skip = 0, limit = 10, search, sort, sortAscending) {
  skip = skip || skip === 0 ? skip : 0;
  limit = limit || limit === 0 ? limit : 10;
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_ALL_PROVIDER_RESOURCES_BY_CLINIC.SOURCE,
    meta: {
      tracker: GET_ALL_PROVIDER_RESOURCES_BY_CLINIC.SOURCE,
    },
    payload: {
      promise: resourceApi
        .getAllProviderResourcesByClinic(skip, limit, search, sort, sortAscending)
        .then(res => res.data)
    }
  });
}

export function getProviderResourceByNpi(npi) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_PROVIDER_RESOURCE.SOURCE,
    meta: {
      tracker: GET_PROVIDER_RESOURCE.SOURCE,
    },
    payload: {
      promise: resourceApi.getProviderResourceByNpi(npi)
        .then(res => res.data)
    }
  });
}

export function getProviderResourceById(id) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: GET_PROVIDER_RESOURCE.SOURCE,
    meta: {
      tracker: GET_PROVIDER_RESOURCE.SOURCE,
    },
    payload: {
      promise: resourceApi.getResourceById(id)
        .then(res => res.data)
    }
  });
}

export function updateProviderResource(data) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: UPDATE_PROVIDER_RESOURCE.SOURCE,
    meta: {
      tracker: UPDATE_PROVIDER_RESOURCE.SOURCE,
    },
    payload: {
      promise: resourceApi.updateResource(data)
        .then(res => res.data)
    }
  });
}

export function deleteProviderResource(id) {
  return ({ bkmdApi: { resourceApi } }) => ({
    type: DELETE_PROVIDER_RESOURCE.SOURCE,
    meta: {
      tracker: DELETE_PROVIDER_RESOURCE.SOURCE,
    },
    payload: {
      promise: resourceApi.deleteProviderResource(id)
        .then(res => res.data)
    }
  });
}

export function createProviderResource(data) {
  return ({ bkmdApi: { resourceApi, providerApi } }) => ({
    type: CREATE_PROVIDER_RESOURCE.SOURCE,
    meta: {
      tracker: CREATE_PROVIDER_RESOURCE.SOURCE,
    },
    payload: {
      promise: ensureVimDomain()
        .then(() => ensureProviderInVim(providerApi, data.npi))
        .then(() => resourceApi.createProviderResource(data))
        .then(res => res.data)
    },
  });
}
