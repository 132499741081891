/**
 * Created by meirshalev 01/04/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

import { BkmdGridColumn } from '../../../../components/grid/index';
import QueryStringGrid, {
  gridQueryParams,
  gridQueryParamsProps,
} from '../../../../components/grid/queryStringGrid';

import { getAllRawMembers } from '../../../../store/backoffice/members/actions';
import * as selectors from '../../../../store/backoffice/members/selectors';
import * as Columns from '../../../../components/grid/columns';
import CommonFormats from '../../../../utils/formatter/commonFormats';
import { getDomain } from '../../../../utils/domain/storage';
import { BkmdDomains } from '../../../../model/enum/bkmdDomain';

@autobind
class DomainMemberList extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    getAllDomainMembers: PropTypes.func.isRequired, // from redux
    router: PropTypes.object.isRequired,
    domainMembers: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.getAllRawMembers(
      this.props.skip,
      this.props.limit,
      this.props.search,
      this.props.sort,
      this.props.sortAscending,
    );
  }

  onClick(rowData) {
    this.props.router.push(`/secure/domainMember/${rowData.id}`);
  }

  onQueryChange(skip, limit, search, sort, sortAscending) {
    this.props.getAllRawMembers(skip, limit, search, sort, sortAscending);
  }

  render() {
    const { domainMembers, count } = this.props;
    const domain = getDomain();

    return (
      <div>
        {/* <AclGuard type={BACK_OFFICE_MEMBERS} permission={SUPER_ADMIN}> */}
        {/*  <div className="action-group"> */}
        {/*    {domain === BkmdDomains.PREMERA_BLUE_CROSS_ALASKA && <LoadDummyDomainMembersAction />} */}
        {/*  </div> */}
        {/* </AclGuard> */}
        <div>
          <QueryStringGrid
            data={domainMembers}
            totalCount={count}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onClick}
            allowSearch
          >
            <BkmdGridColumn id="id" title="Id" />
            <BkmdGridColumn id="memberId" title="Member Id" />
            <BkmdGridColumn id="firstName" title="First Name" />
            <BkmdGridColumn id="lastName" title="Last Name" />
            <BkmdGridColumn
              id="dateOfBirth"
              title="Date Of Birth"
              format={CommonFormats.SHORT_MONTH_DAY_YEAR_DATE}
              customComponent={Columns.DateColumn}
            />
            {domain === BkmdDomains.VIM && (
              <BkmdGridColumn
                id="externalIdDomain"
                title="Payer Domain"
                customComponent={Columns.DomainColumn}
              />
            )}
          </QueryStringGrid>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, { limit: 10, skip: 0 }),
  connect(
    state => ({
      domainMembers: selectors.getRawMemberList(state),
      count: selectors.getRawMemberCount(state),
    }),
    { getAllRawMembers },
  ),
)(DomainMemberList);
