import { withProps } from 'recompose';
import { compose } from 'redux';
import withFeatureFlag from '../../../../utils/featureFlags/withFeatureFlag';

import Login from '../../../../components/pages/auth/login';
import svgLoginHeaderBackpack from '../../../../components/ui/loginBigHeader/svg/loginHeaderBackpack';
import config from '../config';

const LoginPage = compose(
  withProps({
    headerElement: svgLoginHeaderBackpack,
    config,
  }),
  withFeatureFlag({
    key: 'vim.backpack.auth0_login_feature',
    prop: 'auth0LoginFeature',
    defaultValue: false,
  }),
  withFeatureFlag({
    key: 'vim.backpack.saml_login_feature',
    prop: 'samlLoginFeature',
    defaultValue: false,
  }),
)(Login);

export default LoginPage;
