/**
 * Created by guy on 18/12/2017.
 */
import Joi from 'joi-browser';

import FieldWrapper from '../../../../../../components/form/reduxForm/fieldWrapper';
import DataFamiliesPicker from '../../paramsGeneration/dataFamiliesPicker';
import {
  ReduxFormCleanTextArea,
  ReduxFormCleanInput,
  ReduxFormReduxEnumSelect,
} from '../../../../../../components/form/reduxForm/components';
import {
  getChannels,
  getNotificationTypes,
} from '../../../../../../store/backoffice/communication/selectors';

export const name = 'backoffice-communication-templates';

export const fields = {
  templateId: {
    name: 'templateId',
    component: ReduxFormCleanInput,
  },
  alias: {
    name: 'alias',
    component: ReduxFormCleanInput,
  },
  channel: {
    name: 'channel',
    component: ReduxFormReduxEnumSelect,
    enumSelector: getChannels,
  },
  notificationType: {
    name: 'notificationType',
    component: ReduxFormReduxEnumSelect,
    enumSelector: getNotificationTypes,
  },
  content: {
    name: 'content',
    component: ReduxFormCleanTextArea,
  },
  dataFamilies: {
    name: 'dataFamilies',
    component: FieldWrapper(DataFamiliesPicker),
    label: 'Data Groups',
  },
  id: {
    readOnly: true,
  },
  createdAt: {
    readOnly: true,
  },
  updatedAt: {
    readOnly: true,
  },
};

export const schema = {
  id: Joi.number(),
  templateId: Joi.string().required(),
  alias: Joi.string().required(),
  notificationType: Joi.string().required(),
  channel: Joi.string().required(),
  content: Joi.string().required(),
  dataFamilies: Joi.array().required(),
};

export const mockForCreate = {
  templateId: '',
  alias: '',
  notificationType: '',
  channel: '',
  content: '',
  dataFamilies: [],
};
