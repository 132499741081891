/**
 * Created by guyavraham on 22/11/2017.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import Action from '../../../components/action';
import SimpleEntity from '../../../components/simpleEntity';
import * as formDef from './templateFormDef';
import {
  CREATE_TEMPLATE,
  createTemplate,
} from '../../../../../../store/backoffice/communication/actions';

@autobind
class CreateTemplateAction extends React.PureComponent {
  static propTypes = {
    createTemplate: PropTypes.func.isRequired,
    templates: PropTypes.array.isRequired,
  };

  callAction(data) {
    const { templates, createTemplate } = this.props;
    const existingTemplateId = _.find(templates, template =>
      _.isEqual(template.templateId, data.templateId),
    );
    if (!existingTemplateId) createTemplate(data);
  }

  render() {
    return (
      <Action
        name="Create Template"
        title="Create Template"
        actionTrackerId={CREATE_TEMPLATE.SOURCE}
        formName={formDef.name}
      >
        <SimpleEntity
          showButtons={false}
          name="Create Template"
          formDefs={formDef}
          onCreate={this.callAction}
        />
      </Action>
    );
  }
}

export default connect(
  null,
  { createTemplate },
)(CreateTemplateAction);
