import { getUserPermissions, clearPermissions } from '../../../../store/backoffice/users/actions';
import {
  getPermissionsCount,
  getPermissionWithGroupIndicator,
} from '../../../../store/backoffice/users/selectors';

export default {
  getPermissions: getUserPermissions,
  clearPermissions,
  permissionCountSelector: getPermissionsCount,
  permissionsSelector: getPermissionWithGroupIndicator,
  isGroup: false,
};
