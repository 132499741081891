import PromiseActionType from '../../../promiseActionType';
import { loadJson } from '../../../../utils/files';

export const UPDATE_CLINIC_MAPPING = new PromiseActionType('UPDATE_CLINIC_MAPPING');
export const GET_CLINIC_MAPPING = new PromiseActionType('GET_CLINIC_MAPPING');
export const LOAD_CLINIC_MAPPING_FILE = new PromiseActionType('LOAD_CLINIC_MAPPING_FILE');

export function loadClinicMappingFromFile(file) {
  return {
    type: LOAD_CLINIC_MAPPING_FILE.SOURCE,
    payload: {
      promise: loadJson(file),
    },
    meta: {
      tracker: LOAD_CLINIC_MAPPING_FILE.SOURCE,
    },
  };
}

export function getClinicMapping(clinicId) {
  return ({ bkmdApi: { clinicsApi } }) => ({
    type: GET_CLINIC_MAPPING.SOURCE,
    payload: {
      promise: clinicsApi.getClinicMapping(clinicId)
    },
    meta: {
      tracker: GET_CLINIC_MAPPING.SOURCE,
    },
  });
}

export function updateClinicMapping(clinicId, mapping) {
  return ({ bkmdApi: { clinicsApi }, dispatch }) => ({
    type: UPDATE_CLINIC_MAPPING.SOURCE,
    payload: {
      promise: clinicsApi.updateClinicMapping(clinicId, mapping).then(() => {
        dispatch(getClinicMapping(clinicId));
      })
    },
    meta: {
      tracker: UPDATE_CLINIC_MAPPING.SOURCE,
    },
  });
}

