export default {
  label: 'Epic adapter',
  schema: {
    properties: {
      epic__client_id: {
        title: 'Client ID',
      },
      epic__username: {
        title: 'Username',
      },
      epic__password: {
        title: 'Password',
      },
      epic__end_point: {
        title: 'Endpoint',
      },
      epic__department_id: {
        title: 'Department ID',
      },
      epic__default_ssn: {
        title: 'Default SSN',
      },
      epic__default_address: {
        title: 'Default patient address',
      },
      epic__sender_id: {
        title: 'Sender ID (For RTB operations)',
      },
      epic__rtb_message_type_id: {
        title: 'Message type (For RTB scheduling)',
      },
      epic__rtb_cancel_message_type_id: {
        title: 'Message type (For RTB canceling)',
      },
      epic__rtb_title_message: {
        title: 'Message title (For RTB scheduling)',
      },
      epic__rtb_cancel_title_message: {
        title: 'Message title (For RTB canceling)',
      },
    },
  },
};
