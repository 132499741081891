/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by ofirHartuv on 10/31/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import React from 'react';
import requireAutherization from '../../../../store/auth/limitedAccessComponent';
import {
  BACK_OFFICE_CLINICS,
  BACK_OFFICE_HEALTH_SYSTEM,
  BACK_OFFICE_PHI_ACCESS,
} from '../../../../model/enum/aclResourceType';

import HealthSystemList from './healthSystem/healthSystemList';

import ClinicList from './clinic/clinicList';
import CreateClinic from './clinic/createClinic';
import UpdateClinic from './clinic/updateClinic';
import HealthSystemPage from './healthSystem/healthSystemPage';
import ClinicNotificationList from './clinic/clinicNotificationList';

import ProviderResourceList from './providerResource/providerResourceList';
import CreateProviderResource from './providerResource/createProviderResource';
import UpdateProviderResource from './providerResource/updateProviderResource';

import CalendarList from './calendar/calendarList';
import UpdateCalendar from './calendar/updateCalendar';

import EMRMapping from './emr/mapping';

import AppointmentTypesList from './appointmentTypes/appointmentTypeList';
import UpdateAppointmentType from './appointmentTypes/updateAppointmentType/updateAppointmentType';

import AppointmentList from './appointment/appointmentList';
import CreateAppointment from './appointment/createAppointment';
import UpdateAppointment from './appointment/updateAppointment';

import ManageAutoCreatedTimeSlots from './clinic/actions/manageAutoCreatedTimeSlots';

import Permissions from './permissions/permissions';
import SearchPreferences from './searchPreferences/searchPreferencesList';
import PlanMappingRoute from './healthSystem/PlanMapping/PlanMappingRoute';

// Machine
import MachineList from './machines/machineList';
import CreateMachine from './machines/createMachine';
import UpdateMachine from './machines/updateMachine';

import SchedulingCSI from './schedulingCSI/providerInvestigationFlow';

const divComponent = props => <div>{props.children}</div>;

const appointments = {
  path: 'appointments',
  name: 'appointments',
  childRoutes: [
    {
      path: 'create',
      name: 'createAppointment',
      component: CreateAppointment.default || CreateAppointment,
    },
    {
      path: ':appointmentId',
      name: 'updateAppointment',
      component: UpdateAppointment.default || UpdateAppointment,
    },
  ],
  component: divComponent,
  indexRoute: {
    name: 'appointments',
    component: AppointmentList.default || AppointmentList,
  },
};

const singleCalendar = {
  path: ':calendarId',
  name: 'singleCalendar',
  childRoutes: [appointments],
  component: divComponent,
  indexRoute: {
    name: 'updateCalendar',
    component: UpdateCalendar.default || UpdateCalendar,
  },
};

const calendars = {
  path: 'calendars',
  name: 'calendars',
  childRoutes: [singleCalendar],
  component: divComponent,
  indexRoute: {
    name: 'Calendars',
    component: CalendarList.default || CalendarList,
  },
};

const singleAppointmentType = {
  path: ':appointmentTypeId',
  name: 'singleAppointmentType',
  component: divComponent,
  indexRoute: {
    name: 'updateAppointmentType',
    component: UpdateAppointmentType.default || UpdateAppointmentType,
  },
};

const appointmentTypes = {
  path: 'services',
  name: 'Appointment Types',
  childRoutes: [singleAppointmentType],
  component: divComponent,
  indexRoute: {
    name: 'Appointment Types List',
    component: AppointmentTypesList.default || AppointmentTypesList,
  },
};

const machineResource = {
  path: ':resourceId',
  name: 'machinePage',
  childRoutes: [appointmentTypes, calendars],
  component: divComponent,
  indexRoute: {
    name: 'machinePage',
    component: UpdateMachine.default || UpdateMachine,
  },
};

const MachineRoutes = {
  path: 'machines',
  name: 'machines',
  childRoutes: [
    {
      path: 'create',
      name: 'create',
      component: CreateMachine.default || CreateMachine,
    },
    machineResource,
  ],
  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_CLINICS })(divComponent),
  indexRoute: {
    name: 'machineList',
    component: MachineList.default || MachineList,
  },
};

const emrMapping = {
  path: 'emr',
  name: 'emr',
  childRoutes: [],
  component: divComponent,
  indexRoute: {
    name: 'emr',
    component: EMRMapping.default || EMRMapping,
  },
};

const providerResource = {
  path: ':resourceId',
  name: 'providerResource',
  childRoutes: [calendars, appointmentTypes],
  component: divComponent,
  indexRoute: {
    name: 'updateProviderResource',
    component: UpdateProviderResource.default || UpdateProviderResource,
  },
};

const providers = {
  path: 'providers',
  name: 'providers',
  childRoutes: [
    {
      path: 'create',
      name: 'createProviderResource',
      component: CreateProviderResource.default || CreateProviderResource,
    },
    providerResource,
  ],
  component: divComponent,
  indexRoute: {
    name: 'providers',
    component: ProviderResourceList.default || ProviderResourceList,
  },
};

const clinicPage = {
  path: ':id',
  name: 'clinicPage',
  childRoutes: [
    providers,
    MachineRoutes,
    emrMapping,
    calendars,
    {
      path: 'notifications',
      name: 'ClinicNotifications',
      component: ClinicNotificationList.default || ClinicNotificationList,
    },
  ],
  component: divComponent,
  indexRoute: {
    name: 'clinicPage',
    component: UpdateClinic.default || UpdateClinic,
  },
};

const clinics = {
  path: 'clinics',
  name: 'clinics',
  childRoutes: [
    {
      path: 'create',
      name: 'create',
      component: CreateClinic.default || CreateClinic,
    },
    {
      path: 'manageAutoCreatedTimeSlots',
      name: 'manageAutoCreatedTimeSlots',
      component: ManageAutoCreatedTimeSlots.default || ManageAutoCreatedTimeSlots,
    },
    clinicPage,
    {
      path: 'permissions/:type/:resourceId',
      name: 'permissionsPage',
      component: Permissions.default || Permissions,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_CLINICS })(divComponent),

  indexRoute: {
    name: 'clinicList',
    component: ClinicList.default || ClinicList,
  },
};

const healthSystemPage = {
  path: ':id',
  name: 'healthSystemPage',
  childRoutes: [PlanMappingRoute],
  component: divComponent,
  indexRoute: {
    name: 'healthSystemPage',
    component: HealthSystemPage.default || HealthSystemPage,
  },
};

export const healthSystem = {
  path: 'healthSystems',
  name: 'healthSystems',
  childRoutes: [
    { ...clinics, path: ':healthSystemId/clinics' },
    {
      path: 'permissions/:type/:resourceId',
      name: 'permissionsPage',
      component: Permissions.default || Permissions,
    },
    {
      path: 'searchPreferences/:type/:resourceId',
      name: 'searchPreferences',
      component: SearchPreferences.default || SearchPreferences,
    },
    healthSystemPage,
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_HEALTH_SYSTEM })(divComponent),

  indexRoute: {
    name: 'healthSystemsList',
    component: HealthSystemList.default || HealthSystemList,
  },
};

export const schedulingCSI = {
  path: 'schedulingCSI',
  name: 'schedulingCSI',

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAutherization({ resourceType: BACK_OFFICE_PHI_ACCESS })(divComponent),

  indexRoute: {
    name: 'schedulingCSI',
    component: SchedulingCSI.default || SchedulingCSI,
  },
};

export default clinics;

/* eslint-enable import/first, react/jsx-filename-extension */
