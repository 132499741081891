import _ from 'lodash';
import Joi from 'joi-browser';
import * as ServiceKeyType from '../../../../../../model/enum/serviceKeyType';
import {
  ReduxFormCleanSelect,
  ReduxFormCleanSwitch,
  ReduxFormCleanChipInput,
} from '../../../../../../components/form/reduxForm/components';
import { enumSchema } from '../../../../../../utils/commonSchema';

export const name = 'backoffice-update-appointment-type';

export const fields = {
  id: {
    name: 'id',
  },
  resourceId: {
    name: 'resourceId',
  },
  minTimeBeforeBooking: {
    label: 'Minimum time before booking (in minutes)',
    name: 'minTimeBeforeBooking',
  },
  isTelemedicine: {
    name: 'isTelemedicine',
    label: 'Is Telemedicine',
    id: 'isTelemedicine',
    component: ReduxFormCleanSwitch,
    readOnly: false,
  },
  ehrAppointmentType: {
    id: 'ehrAppointmentType',
    name: 'ehrAppointmentType',
    label: 'EHR appointment type',
  },
  interchangeableAppointmentTypes: {
    label: 'Interchangeable EHR appointment types',
    id: 'interchangeableAppointmentTypes',
    name: 'interchangeableAppointmentTypes',
    component: ReduxFormCleanChipInput,
    readOnly: false,
  },
  duration: {
    label: 'Duration (in minutes)',
    name: 'duration',
  },
  key: {
    name: 'key',
  },
  type: {
    options: _.map(ServiceKeyType, (value, label) => ({ value, label })),
    name: 'type',
    component: ReduxFormCleanSelect,
  },
  createdAt: {
    className: 'margin-top-50',
  },
};

export const schema = {
  id: Joi.number().required(),
  resourceId: Joi.number().required(),
  key: Joi.string().required(),
  minTimeBeforeBooking: Joi.number().optional(),
  isTelemedicine: Joi.boolean().default(false),
  type: enumSchema(ServiceKeyType).required(),
  ehrAppointmentType: Joi.string().optional(),
  interchangeableAppointmentTypes: Joi.array().optional(),
  duration: Joi.number().required(),
};
