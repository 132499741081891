/**
 * Created by guyavarham on 04/04/2017.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  invalidMemberForUserTitle: {
    defaultMessage: "We can't find your info",
    id: 'unauthorized.login.invalidMemberForUserTitle',
  },
  goBack: {
    defaultMessage: 'Go back home',
    id: 'unauthorized.login.goBack',
  },
  h1: {
    defaultMessage: 'Whoops!',
    id: 'unauthorized.login.h1',
  },
});
