import React from 'react';
import PropTypes from 'prop-types';
import PatientDataClean from '../patientDataClean';

export default function PatientView({
  patient,
}) {
  return (
    <div className="margin-top">
      <PatientDataClean patient={patient}/>
    </div>
  );
}

PatientView.propTypes = {
  patient: PropTypes.object.isRequired,
};
