import { defineMessages } from 'react-intl';

const planMappingListMessages = defineMessages({
  loadErrorText: {
    defaultMessage: 'Error occurred while loading plan mapping',
    id: 'clinics.healthSystem.planMapping.list.loadErrorText',
  },
  deleteSuccessText: {
    defaultMessage: 'Delete the plan mapping successfully!',
    id: 'clinics.healthSystem.planMapping.list.deleteSuccessText',
  },
  deleteErrorText: {
    defaultMessage: 'Error occurred while delete plan mapping',
    id: 'clinics.healthSystem.planMapping.list.deleteErrorText',
  },
});

export default planMappingListMessages;
