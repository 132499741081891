/**
 * Created by chenrozenes on 19/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Field } from 'redux-form';
import {
  ReduxFormCleanInput,
  ReduxFormCleanPhoneNumber,
} from '../../../../../components/form/reduxForm/components';
import { PhoneNumberSchema } from '../../../../../utils/commonSchema';

/**
 * FieldDefs from string array
 * Example which will produce field 'a' as CleanComp, and 'b' as the default CleanInput:
 * fieldDefinitionsFromEnum(['a', 'b'], { a: { component: CleanBoy }})
 * @param params - string array
 * @param specificComponentMap - does'nt have to cover all the params
 */
export const fieldDefinitionsFromEnum = (params, specificComponentMap) =>
  _.zipObject(
    params,
    _.map(params, paramName => ({ ...specificComponentMap[paramName] })),
  );

const fieldsDefObjectShape = PropTypes.shape({
  name: PropTypes.string,
  component: PropTypes.any,
  // ... other props for component
});

// fieldsDef should be an object with values of fieldsDefObjectShape or a "false" value to skip it
export const fieldsDefShape = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.bool, fieldsDefObjectShape]),
);

/**
 * Generates Field components (redux-forms) by an object (entity prop) and specification per
 * field that is not a string (fieldsDef prop)
 */
@autobind
class FieldGenerator extends React.Component {
  static propTypes = {
    fieldsDef: fieldsDefShape,
    entity: PropTypes.object,
    readOnly: PropTypes.bool,
    prefix: PropTypes.string,
    newPasswordValidationFeature: PropTypes.bool,
    verifyPasswordField: PropTypes.bool,
  };

  static defaultProps = {
    fieldsDef: {},
    entity: {},
    readOnly: false,
    prefix: undefined,
    newPasswordValidationFeature: false,
    verifyPasswordField: false,
  };

  getFieldProps(key, fieldsDef, prefix, value) {
    const {
      readOnly,
      newPasswordValidationFeature,
      verifyPasswordField,
      onStrengthChanged,
    } = this.props;
    const isPhoneNumber = !Joi.validate(value, PhoneNumberSchema).error;
    const component = isPhoneNumber ? ReduxFormCleanPhoneNumber : ReduxFormCleanInput;
    return _.extend(
      {},
      {
        className: '',
        name: prefix ? `${prefix}.${key}` : key,
        label: _.startCase(key),
        component,
        readOnly,
        withFeatureFlag: newPasswordValidationFeature,
        verifyPasswordField,
        onStrengthChanged,
      },
      fieldsDef[key],
    );
  }

  renderFields(entity = {}, fieldsDef = {}, prefix = '') {
    return _.map(entity, (value, key) => {
      if (fieldsDef[key] === false) return null;
      if (_.get(fieldsDef, `${key}.children`)) {
        return (
          <div className="card" key={key}>
            <div className="card-title">{_.startCase(key)}</div>
            <div className="card-body">
              {this.renderFields(entity[key], fieldsDef[key].children, `${prefix}${key}`)}
            </div>
          </div>
        );
      }

      return <Field {...this.getFieldProps(key, fieldsDef, prefix, value)} key={key} />;
    });
  }

  render() {
    return (
      <div>{this.renderFields(this.props.entity, this.props.fieldsDef, this.props.prefix)}</div>
    );
  }
}

export default FieldGenerator;
