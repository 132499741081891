import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../../store/notification/injectNotification';

import Action from '../../../components/action';
import { DELETE_CLINIC } from '../../../../../../store/backoffice/clinicManagement/clinics/actions';
import Api from '../../../../../../api';

const DeleteClinicAction = ({ id, deleteClinic }) => (
  <Action
    name="deleteClinic"
    btnColor="red"
    iconClassName="icon-trash"
    actionTrackerId={DELETE_CLINIC.SOURCE}
    onSubmit={() => deleteClinic(id)}
  >
    <div>
      <div>- deletes the clinic and all DB entities related to it</div>
    </div>
  </Action>
);

DeleteClinicAction.propTypes = {
  deleteClinic: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default compose(
  withRouter,
  injectNotification,
  withFetchers({
    deleteClinic: {
      handler: ({ id }) => () => Api.clinicsApi.deleteClinic(id),
      onSuccess: ({ notification, router }) => {
        notification.success('', 'Clinic deleted!');
        return router.push('/secure/clinics');
      },
      onError: ({ notification }) =>
        notification.error('', 'Something went wrong, talk to the dev team'),
    },
  }),
)(DeleteClinicAction);
