import React from 'react';
import PropTypes from 'prop-types';
import { BkmdGridColumn, Columns, QueryStringGrid } from 'Components/grid';

export default function AppointmentActionsList({
  appointmentActions,
  onAppointmentActionClick,
  totalCount,
  getAppointmentActions,
  allowSearch,
  fieldsToShow,
}) {
  return (
    <div>
      <QueryStringGrid
        data={appointmentActions}
        totalCount={totalCount}
        onQueryChange={(skip, limit, search, sort, sortAscending, filter) => {
          getAppointmentActions({ take: limit, skip, search, sort, sortAscending, filter });
        }}
        onRowClick={onAppointmentActionClick}
        allowSearch={allowSearch}
        searchLabel="Search by: Platform Id / NPI"
      >
        {fieldsToShow.map(field => (
          <BkmdGridColumn
            id={field.id}
            title={field.title}
            customComponent={field.customComponent}
          />
        ))}
      </QueryStringGrid>
    </div>
  );
}

AppointmentActionsList.propTypes = {
  appointmentActions: PropTypes.array.isRequired,
  onAppointmentActionClick: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  getAppointmentActions: PropTypes.func.isRequired,
  allowSearch: PropTypes.bool,
  fieldsToShow: PropTypes.array,
};

AppointmentActionsList.defaultProps = {
  allowSearch: true,
  fieldsToShow: [
    {
      id: 'platformId',
      title: 'Platform ID',
    },
    {
      id: 'actionType',
      title: 'Action Type',
    },
    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'firstName',
      title: 'First Name',
    },
    {
      id: 'lastName',
      title: 'Last Name',
    },
    {
      id: 'npi',
      title: 'NPI',
    },
    {
      id: 'startTime',
      title: 'Start Time',
      customComponent: Columns.DateColumn,
    },
  ],
};
