import React from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import NavigationLayout from '../../../components/templates/NavigationLayout/navigationLayout';
import PasswordForm from '../password';
import withGoBack from '../../../components/router/withGoBack';
import SvgKey from '../../../assets/svg/key';

const NavigationLayoutWrappedPassword = props => (
  <NavigationLayout {...props} img={<SvgKey />} withHeader>
    <PasswordForm {...props} />
  </NavigationLayout>
);

NavigationLayoutWrappedPassword.propTypes = {
  onBack: PropTypes.func.isRequired,
  withHeader: PropTypes.bool,
  caption: PropTypes.string,
  showLogos: PropTypes.bool,
  progress: PropTypes.bool,
  img: PropTypes.element,
  showRightNavInMobile: PropTypes.bool,
};

NavigationLayoutWrappedPassword.defaultProps = {
  withHeader: false,
  caption: undefined,
  showLogos: false,
  progress: null,
  img: undefined,
  showRightNavInMobile: false,
};

export default compose(
  withGoBack,
  withProps(({ control, goBackFunc }) => ({
    onBack: control.hasPrev() ? control.prev : goBackFunc,
  })),
)(NavigationLayoutWrappedPassword);
