/**
 * Created by rom on 11/08/2016.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';
import ModalHeader from '../../../../components/bkmdModal/modalHeader';
import ModalFooter from '../../../../components/bkmdModal/modalFooter';
import BkmdModal from '../../../../components/bkmdModal/bkmdModal';
import { MessageShape } from '../../../../components/ui/intlString';

const messages = defineMessages({
  modalHeader: {
    defaultMessage: 'Action',
    id: 'backoffice.action.modalHeader',
  },
  confirmButton: {
    defaultMessage: 'Run Action',
    id: 'backoffice.action.confirmButton',
  },
});

/**
 * Manage the modal for the backoffice actions
 * @type {*|Function}
 */
@autobind
class ActionModal extends React.Component {
  static propTypes = {
    /**
     * The name of the modal
     */
    name: PropTypes.string.isRequired,
    /**
     * Handle close for the modal
     */
    handleClose: PropTypes.func.isRequired,
    /**
     * Handle submit for the modal
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * True if action is in progress
     */
    loading: PropTypes.bool,
    /**
     * Text of the submit button of the action
     */
    submitText: MessageShape,
    /**
     * Title for the modal
     */
    title: MessageShape,
    /**
     * True to disable submit button
     */
    submitDisabled: PropTypes.bool,
    /**
     * True to hide the footer with the cancel and submit buttons
     */
    hideFooter: PropTypes.bool,
  };

  static defaultProps = {
    title: messages.modalHeader,
    submitText: messages.confirmButton,
    loading: false,
    submitDisabled: false,
    hideFooter: false,
  };

  render() {
    const {
      title,
      submitText,
      onSubmit,
      handleClose,
      submitDisabled,
      loading,
      children,
      hideFooter,
      ...other
    } = this.props;

    return (
      <BkmdModal handleClose={handleClose} {...other}>
        <ModalHeader title={title} onClose={handleClose} />
        <div className="dialog-body">
          <div className="container-fluid">
            <div className="row no-margin">
              <div className="col-xs-12">{children}</div>
            </div>
          </div>
        </div>
        {!hideFooter && (
          <ModalFooter
            cancelBtnClass="btn-grey"
            confirmBtnClass="btn-blue"
            confirmText={submitText}
            onCancel={handleClose}
            onConfirm={onSubmit}
            confirmDisabled={submitDisabled}
            loading={loading}
          />
        )}
      </BkmdModal>
    );
  }
}

export default ActionModal;
