/**
 * Created by meirshalev 04/04/2018.
 */
import _ from 'lodash';
import Joi from 'joi-browser';
import { BkmdDomains } from '../../../../model/enum/bkmdDomain';
import {
  ReduxFormCleanSelect,
  ReduxFormCleanInput,
} from '../../../../components/form/reduxForm/components/index';

export const name = 'backoffice-move-member-to-domain';

export const fields = {
  domain: {
    name: 'domain',
    component: ReduxFormCleanSelect,
    options: _.map(BkmdDomains, domain => ({
      value: domain,
      label: _.startCase(domain),
    })),
  },
  toDomainMemberId: {
    name: 'toDomainMemberId',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  domain: Joi.string().valid(_.keys(BkmdDomains)),
  toDomainMemberId: Joi.string(),
};
