import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-create-aso-group-tier';

export const fields = {
  name: {
    name: 'name',
    component: ReduxFormCleanInput,
  },
  title: {
    name: 'title',
    component: ReduxFormCleanInput,
  },
  description: {
    name: 'description',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  name: Joi.string().required(),
  title: Joi.string().required(),
  description: Joi.string().required(),
};
