import { defineMessages } from 'react-intl';

export const name = 'backoffice-namespace-appointment-action';

export const appointmentActionMessages = defineMessages({
  title: {
    defaultMessage: 'Appointment Action details:',
    id: 'namespaceAppointmentAction.appointmentAction.title',
  },
  submitButtonText: {
    defaultMessage: 'Update',
    id: 'namespaceAppointmentAction.appointmentAction.submit',
  },
  availabilityCheckButtonText: {
    defaultMessage: 'Is slot (still) free?',
    id: 'namespaceAppointmentAction.appointmentAction.availabilityCheck',
  },
  errorMessage: {
    defaultMessage: 'Error occurred while retrieving data',
    id: 'namespaceAppointmentAction.appointmentAction.errorMessage',
  },
  noChangesErrorMessage: {
    defaultMessage: 'No changes were made',
    id: 'namespaceAppointmentAction.appointmentAction.noChangesErrorMessage',
  },
  blankAppointmentIdErrorMessage: {
    defaultMessage: 'Please enter an Appointment Id',
    id: 'namespaceAppointmentAction.appointmentAction.blankAppointmentIdErrorMessage',
  },
  updateError: {
    defaultMessage: 'Error occurred while updating action',
    id: 'namespaceAppointmentAction.appointmentAction.updateError',
  },
  updateSucceeded: {
    defaultMessage: 'Appointment action successfully updated',
    id: 'namespaceAppointmentAction.appointmentAction.updateSucceeded',
  },
  availabilityCheckError: {
    defaultMessage: 'Error occurred while checking appointment time availability',
    id: 'namespaceAppointmentAction.appointmentAction.availabilityCheckError',
  },
  timeslotOccupied: {
    defaultMessage: 'Appointment action timeslot is occupied! :(',
    id: 'namespaceAppointmentAction.appointmentAction.timeslotOccupied',
  },
  timeslotFree: {
    defaultMessage: 'Appointmnet action timeslot is free :)',
    id: 'namespaceAppointmentAction.appointmentAction.timeslotFree',
  },
  resendError: {
    defaultMessage: 'Error occurred while resending action',
    id: 'namespaceAppointmentAction.appointmentAction.resendError',
  },
  resendSucceeded: {
    defaultMessage: 'Appointment action successfully resent (Please wait a minute or two and refresh to see updated status)',
    id: 'namespaceAppointmentAction.appointmentAction.resendSucceeded',
  },
  platformId: {
    defaultMessage: 'Platform Id',
    id: 'namespaceAppointmentAction.appointmentAction.platformId',
  },
  npi: {
    defaultMessage: 'NPI',
    id: 'namespaceAppointmentAction.appointmentAction.npi',
  },
  patientId: {
    defaultMessage: 'Patient Id',
    id: 'namespaceAppointmentAction.appointmentAction.patientId',
  },
  appointmentId: {
    defaultMessage: 'Appointment Id',
    id: 'namespaceAppointmentAction.appointmentAction.appointmentId',
  },
  actionType: {
    defaultMessage: 'Action Type',
    id: 'namespaceAppointmentAction.appointmentAction.actionType',
  },
  status: {
    defaultMessage: 'Status',
    id: 'namespaceAppointmentAction.appointmentAction.status',
  },
  appointmentType: {
    defaultMessage: 'Appointment type',
    id: 'namespaceAppointmentAction.appointmentAction.appointmentType',
  },
  ehrLocationId: {
    defaultMessage: 'Ehr Location Id',
    id: 'namespaceAppointmentAction.appointmentAction.ehrLocationId',
  },
  ehrProviderId: {
    defaultMessage: 'Ehr Provider Id',
    id: 'namespaceAppointmentAction.appointmentAction.ehrProviderId',
  },
  timezone: {
    defaultMessage: 'Time Zone',
    id: 'namespaceAppointmentAction.appointmentAction.timezone',
  },
  startTime: {
    defaultMessage: 'Start Time',
    id: 'namespaceAppointmentAction.appointmentAction.startTime',
  },
  endTime: {
    defaultMessage: 'End Time',
    id: 'namespaceAppointmentAction.appointmentAction.endTime',
  },
  createdAt: {
    defaultMessage: 'Created At',
    id: 'namespaceAppointmentAction.appointmentAction.createdAt',
  },
  updatedAt: {
    defaultMessage: 'Updated At',
    id: 'namespaceAppointmentAction.appointmentAction.updatedAt',
  },
});
