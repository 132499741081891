/**
 * Created by ofirHartuv on 30/10/17.
 */
import PromiseActionType from '../../../promiseActionType';
import { getDomain } from '../../../../utils/domain/storage';

export const GET_ALL_APPOINTMENTS_BY_CALENDAR = new PromiseActionType(
  'GET_ALL_APPOINTMENTS_BY_CALENDAR',
);
export const UPDATE_APPOINTMENT = new PromiseActionType('UPDATE_APPOINTMENT');
export const CANCEL_APPOINTMENT = new PromiseActionType('CANCEL_APPOINTMENT');
export const DELETE_APPOINTMENT = new PromiseActionType('DELETE_APPOINTMENT');
export const CREATE_APPOINTMENT = new PromiseActionType('CREATE_APPOINTMENT');
export const GET_APPOINTMENT = new PromiseActionType('GET_APPOINTMENT');

export function getAllAppointmentsForCalendar(skip = 0, limit = 10, search, sort, sortAscending) {
  skip = skip || skip === 0 ? skip : 0;
  limit = limit || limit === 0 ? limit : 10;
  return ({ bkmdApi: { providerAppointmentsApi } }) => {
    const domain = getDomain();
    return {
      type: GET_ALL_APPOINTMENTS_BY_CALENDAR.SOURCE,
      meta: {
        tracker: GET_ALL_APPOINTMENTS_BY_CALENDAR.SOURCE,
      },
      payload: {
        promise: providerAppointmentsApi
          .getAllAppointmentsForCalendar(skip, limit, search, sort, sortAscending, domain)
          .then(res => res.data),
      },
    };
  };
}

export function getAppointmentById(id) {
  return ({ bkmdApi: { providerAppointmentsApi } }) => {
    const domain = getDomain();
    return {
      type: GET_APPOINTMENT.SOURCE,
      meta: {
        tracker: GET_APPOINTMENT.SOURCE,
      },
      payload: {
        promise: providerAppointmentsApi.getAppointmentById(id, domain).then(res => res.data),
      },
    };
  };
}

export function updateAppointment(data) {
  return ({ bkmdApi: { providerAppointmentsApi } }) => {
    const domain = getDomain();
    return {
      type: UPDATE_APPOINTMENT.SOURCE,
      meta: {
        tracker: UPDATE_APPOINTMENT.SOURCE,
      },
      payload: {
        promise: providerAppointmentsApi.updateAppointment(data, domain).then(res => res.data),
      },
    };
  };
}

export function cancelAppointment(data) {
  return ({ bkmdApi: { providerAppointmentsApi } }) => {
    const domain = getDomain();
    return {
      type: CANCEL_APPOINTMENT.SOURCE,
      meta: {
        tracker: CANCEL_APPOINTMENT.SOURCE,
      },
      payload: {
        promise: providerAppointmentsApi.cancelAppointment(data, domain).then(res => res.data),
      },
    };
  };
}

export function deleteAppointment(id) {
  return ({ bkmdApi: { providerAppointmentsApi } }) => ({
    type: DELETE_APPOINTMENT.SOURCE,
    meta: {
      tracker: DELETE_APPOINTMENT.SOURCE,
    },
    payload: {
      promise: providerAppointmentsApi.deleteAppointment(id).then(res => res.data),
    },
  });
}

export function createAppointment(data) {
  return ({ bkmdApi: { providerAppointmentsApi } }) => ({
    type: CREATE_APPOINTMENT.SOURCE,
    meta: {
      tracker: CREATE_APPOINTMENT.SOURCE,
    },
    payload: {
      promise: providerAppointmentsApi.createAppointment(data).then(res => res.data),
    },
  });
}
