import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import EntityForm from '../../components/entityForm/entityForm';
import * as ProviderResourceFormDefs from './providerResourceFormDefs';
import {
  updateProviderResource,
  getProviderResourceById,
  deleteProviderResource,
  DELETE_PROVIDER_RESOURCE,
} from '../../../../../store/backoffice/clinicManagement/providerResources/actions';
import * as selectors from '../../../../../store/backoffice/clinicManagement/providerResources/selectors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { providerResourceType as type } from '../permissions/permissionMapper';

@autobind
class UpdateProviderResource extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired, // from router
    updateProviderResource: PropTypes.func.isRequired, // from redux
    getProviderResourceById: PropTypes.func.isRequired, // from redux
    deleteProviderResource: PropTypes.func.isRequired, // from redux
    providerResource: PropTypes.object.isRequired, // from redux
    deleteTracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    if (this.props.params.resourceId) {
      this.props.getProviderResourceById(this.props.params.resourceId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deleteTracker.finished && !nextProps.deleteTracker.hasError) {
      this.goToProviders();
    }
  }

  goToProviders() {
    const { id } = this.props.params;
    this.props.router.push(`/secure/clinics/${id}/providers`);
  }

  deleteProviderResource() {
    const { providerResource, deleteProviderResource } = this.props;
    deleteProviderResource(providerResource.id);
  }

  updateIsDeletedProviderResource() {
    const { providerResource, updateProviderResource } = this.props;
    updateProviderResource({
      ...providerResource,
      isDeleted: !providerResource.isDeleted,
    });
  }

  renderActions() {
    const {
      params: { id, resourceId },
    } = this.props;

    return (
      <div className="backoffice-action">
        <button
          onClick={this.goToProviders}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Back To Providers
        </button>
        <button
          onClick={() =>
            this.props.router.push(`/secure/clinics/${id}/providers/${resourceId}/calendars`)}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Show Provider׳s Calendars
        </button>
        <button
          onClick={() =>
            this.props.router.push(`/secure/clinics/permissions/${type}/${resourceId}`)}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Provider׳s Permissions
        </button>
        <button
          onClick={() =>
            this.props.router.push(`/secure/clinics/${id}/providers/${resourceId}/services`)}
          className="btn btn-small btn-blue text-semibold action-button margin-13"
        >
          Show Providers Appointment Types
        </button>
        <button
          className="btn btn-small btn-blue text-semibold action-button margin-13"
          onClick={this.updateIsDeletedProviderResource}
        >
          {this.props.providerResource.isDeleted
            ? 'Enable Provider Resource'
            : 'Soft Delete Provider Resource'}
        </button>
      </div>
    );
  }

  render() {
    const { providerResource } = this.props;
    return (
      <div>
        {this.renderActions()}
        <hr />
        <EntityForm
          name={ProviderResourceFormDefs.name}
          title="Update Provider resource:"
          submitText="Update"
          schemaDef={ProviderResourceFormDefs.schema}
          fieldsDef={ProviderResourceFormDefs.fields}
          entity={providerResource}
          onSubmit={this.props.updateProviderResource}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker({
    deleteTracker: DELETE_PROVIDER_RESOURCE.SOURCE,
  }),
  connect(
    state => ({
      providerResource: selectors.getProviderResource(state),
    }),
    { updateProviderResource, getProviderResourceById, deleteProviderResource },
  ),
)(UpdateProviderResource);
