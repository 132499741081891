import { compose, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import AppointmentsList from './view';
import { internalApi } from '../../internal-api';

export default compose(
  queryConnect(gridQueryParams, { limit: 10, skip: 0, sort: 'createdAt', sortAscending: 'false' }),
  withStateFetchersOnMount({
    getAppointments: {
      handler: ({ params }) => (input = {}) =>
        internalApi.namespace(params.namespaceName).appointments.getAllAppointments(input),
      resultPropName: 'appointmentsQueryResult',
      defaultValue: [],
      isReady: ({ appointmentsQueryResult }) => !isEmpty(appointmentsQueryResult),
    },
  }),
  withProps(({ appointmentsQueryResult: { results: appointments, count: totalCount } }) => ({
    appointments,
    totalCount,
  })),
  withRouter,
  withHandlers({
    onAppointmentClick: ({ router, params }) => appointment => {
      router.push(
        `/secure/namespaceManager/${params.namespaceName}/appointments/${appointment.id}?npi=${appointment.npi}`,
      );
    },
  }),
)(AppointmentsList);
