import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Action from '../../components/action';
import { name as formName } from './createAsoGroupTierFormDefs';
import CreateAsoGroupTierForm from './createAsoGroupTierForm';

@autobind
class CreateAsoGroupTierAction extends React.PureComponent {
  static propTypes = {
    createAsoGroupTier: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Action name="createAsoGroupTier" title="Create New Tier" formName={formName} {...this.props}>
        <CreateAsoGroupTierForm onSubmit={this.props.createAsoGroupTier} />
      </Action>
    );
  }
}

export default CreateAsoGroupTierAction;
