import React from 'react';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { gridQueryParams } from 'Components/grid/queryStringGrid';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import { BkmdGridColumn, QueryStringGrid } from '../../../../../components/grid';
import { internalApi } from '../../internal-api';

function AppointmentRequestList({
  appointmentRequestsQueryResult,
  onAppointmentRequestClick,
  getAppointmentRequests,
}) {
  return (
    <div>
      <QueryStringGrid
        data={appointmentRequestsQueryResult.results}
        totalCount={appointmentRequestsQueryResult.count}
        onQueryChange={(skip, limit, search, sort, sortAscending) => {
          getAppointmentRequests({ take: limit, skip, search, sort, sortAscending });
        }}
        onRowClick={onAppointmentRequestClick}
        allowSearch
        searchLabel="Search by: Appointment Request Id / First Name / Last Name / NPI / Patient Id"
      >
        <BkmdGridColumn id="id" title="Id" />
        <BkmdGridColumn id="npi" title="NPI" />
        <BkmdGridColumn id="patientId" title="Patient Id" />
        <BkmdGridColumn id="platformId" title="Platform Id" />
        <BkmdGridColumn id="appointmentId" title="Appointment Id" />
        <BkmdGridColumn id="firstName" title="First Name" />
        <BkmdGridColumn id="lastName" title="Last Name" />
      </QueryStringGrid>
    </div>
  );
}

AppointmentRequestList.propTypes = {
  appointmentRequestsQueryResult: PropTypes.object.isRequired,
  onAppointmentRequestClick: PropTypes.func.isRequired,
  getAppointmentRequests: PropTypes.func.isRequired,
};

export default compose(
  queryConnect(gridQueryParams, { limit: 10, skip: 0, sort: 'createdAt', sortAscending: 'false' }),
  withStateFetchersOnMount({
    getAppointmentRequests: {
      handler: ({ params }) => (input = {}) =>
        internalApi
          .namespace(params.namespaceName)
          .appointmentRequestActions.getAllAppointmentRequestAction(input),
      resultPropName: 'appointmentRequestsQueryResult',
      isReady: ({ appointmentRequestsQueryResult, getAppointmentRequestsTracker }) =>
        !isEmpty(appointmentRequestsQueryResult) && !getAppointmentRequestsTracker.inProgress,
    },
  }),
  withRouter,
  withHandlers({
    onAppointmentRequestClick: ({ router, params }) => request => {
      router.push(
        `/secure/namespaceManager/${params.namespaceName}/appointmentRequests/${request.id}`,
      );
    },
  }),
)(AppointmentRequestList);
