/**
 * Created by chenrozenes on 03/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../../components/action';
import { name as formName } from './removePermissionFormDefs';
import RemovePermissionForm from './removePermissionForm';
import {
  removePermission,
  REMOVE_PERMISSION,
} from '../../../../../../store/backoffice/users/actions';
import { getPhiAccess } from '../../../../../../store/auth/selectors';

@autobind
class RemovePermissionAction extends React.PureComponent {
  static propTypes = {
    removePermission: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isGroup: PropTypes.bool.isRequired,
    hasPhiAccess: PropTypes.bool.isRequired,
  };

  callAction(data) {
    this.props.removePermission(
      this.props.id,
      data.resourceType,
      data.resourceId,
      this.props.isGroup,
    );
  }

  render() {
    return (
      <Action
        name="removePermission"
        title="Remove Permission"
        actionTrackerId={REMOVE_PERMISSION.SOURCE}
        formName={formName}
        {...this.props}
      >
        <RemovePermissionForm onSubmit={this.callAction} hasPhiAccess={this.props.hasPhiAccess} />
      </Action>
    );
  }
}

export default connect(
  state => ({
    hasPhiAccess: getPhiAccess(state),
  }),
  { removePermission },
)(RemovePermissionAction);
