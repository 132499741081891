import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import moment from 'moment-timezone';
import Button from '../../../../../../storybook/src/components/atoms/Button/Button';
import CollapsibleCard from '../../../../../../components/ui/collapsibleCard/collapsibleCard';
import Formatter from '../../../../../../utils/formatter';
import commonFormats from '../../../../../../utils/formatter/commonFormats';
import { withTranslatedMessages } from '../../../../../../utils/withTranslatedMessages';

const DATE_FORMAT = commonFormats.MONTH_DAY_YEAR;
const TIME_FORMAT = commonFormats.HOURS_24_TIME;

// ** Appointment Section ** //
const appointmentMessages = defineMessages({
  title: {
    defaultMessage: 'Appointment details:',
    id: 'appointmentProductForm.collapsible.appointment.title',
  },
  id: {
    defaultMessage: 'Id',
    id: 'appointmentProductForm.collapsible.appointment.id',
  },
  startTime: {
    defaultMessage: 'Start time',
    id: 'appointmentProductForm.collapsible.appointment.startTime',
  },
  endTime: {
    defaultMessage: 'End time',
    id: 'appointmentProductForm.collapsible.appointment.endTime',
  },
  status: {
    defaultMessage: 'Status',
    id: 'appointmentProductForm.collapsible.appointment.status',
  },
  appointmentType: {
    defaultMessage: 'Appointment type',
    id: 'appointmentProductForm.collapsible.appointment.appointmentType',
  },
  goToAppointmentText: {
    defaultMessage: 'Go To Appointment',
    id: 'appointmentProductForm.collapsible.appointment.goToAppointment',
  },
});

@autobind
class CollapsibleAppointment extends React.Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    title: PropTypes.object,
  };

  static defaultProps = {
    title: undefined,
  };

  render() {
    const { appointment, title, goToAppointmentText, goToAppointment } = this.props;
    if (!appointment) return null;
    const appointmentType = _.get(appointment, 'appointmentType', null);
    const duration = appointmentType ? Math.floor(appointmentType.duration / 60000) : 30;
    const startTimeValue = moment.tz(appointment.startTime, appointment.timeZone);
    const endTimeValue =
      appointment.endTime && moment.tz(appointment.endTime, appointment.timeZone);
    return (
      <CollapsibleCard
        title={title || appointmentMessages.title}
        id={`appointment-backoffice-details-id-${appointment.startTime}`}
        className="patient-details-card light-blue-title bg-grey-color"
      >
        <div className="row padding-v-20 padding-h-20">
          <Button
            type="small"
            className="btn-blue text-semibold action-button"
            onClick={goToAppointment}
            text={goToAppointmentText}
          />
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...appointmentMessages.id} />
            </h4>
            {appointment.id}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...appointmentMessages.startTime} />
            </h4>
            {`${Formatter.date(startTimeValue, DATE_FORMAT)} ${Formatter.time(
              startTimeValue,
              TIME_FORMAT,
            )}`}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...appointmentMessages.endTime} />
            </h4>
            {`${Formatter.date(endTimeValue, DATE_FORMAT)} ${Formatter.time(
              endTimeValue,
              TIME_FORMAT,
            )}`}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...appointmentMessages.status} />
            </h4>
            {appointment.status}
          </div>
        </div>
        {appointmentType && (
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...appointmentMessages.appointmentType} />
              </h4>
              {`${appointmentType.commonName} (${duration} min)`}
            </div>
          </div>
        )}
      </CollapsibleCard>
    );
  }
}

export default compose(
  withRouter,
  withHandlers({
    goToAppointment: props => e => {
      e.preventDefault();
      const { router, appointment } = props;
      return router.push(`/secure/productAppointments/${appointment.id}`);
    },
  }),
  withTranslatedMessages(appointmentMessages),
)(CollapsibleAppointment);
