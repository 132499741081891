import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { Field } from 'formik';
import withFormikWrapper from 'Components/form/formik/withFormikWrapper';
import { CleanFormikCheckbox } from 'Components/form/formik/cleanFormikCheckbox';
import { CleanFormikSelect } from 'Components/form/formik/cleanFormikSelect';
import _ from 'lodash';
import classNames from 'classnames';

const hvnsFieldName = 'hvns';
const networkStatusFieldName = 'networkStatus';
const allowedHealthSystemIdsFieldName = 'allowedHealthSystemIds';

export const searchFields = [hvnsFieldName, networkStatusFieldName];

function SearchConfigurationView({ healthSystems }) {
  return (
    <div>
      <h3 className="config-section-title">Search Configurations</h3>
      <Field
        id="search-conf-hvns"
        name={hvnsFieldName}
        label="High Value"
        className="margin-top-10"
        component={CleanFormikCheckbox}
      />
      <Field
        id="search-conf-net"
        name={networkStatusFieldName}
        label="Network Status"
        className="margin-top-10"
        component={CleanFormikCheckbox}
      />
      <Field
        id={allowedHealthSystemIdsFieldName}
        name={allowedHealthSystemIdsFieldName}
        label="Allowed Health Systems"
        component={CleanFormikSelect}
        className={classNames('margin-top-30', 'health-systems-ids-field')}
        options={_.map(healthSystems, ({ id, name }) => ({
          value: id,
          label: name,
        }))}
        multi
      />
    </div>
  );
}

SearchConfigurationView.propTypes = {
  value: PropTypes.object.isRequired,
};

const SearchConfiguration = compose(
  withFormikWrapper(),
  setPropTypes({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      hvns: PropTypes.bool,
      networkStatus: PropTypes.bool,
    }),
  }),
)(SearchConfigurationView);

export default SearchConfiguration;
