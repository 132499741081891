import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mapProps } from 'recompose';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages, injectIntl, intlShape } from 'react-intl';
import auth0 from 'auth0-js';

import { goToNext } from '../../../utils/reRouting';
import { login, loadFromStorage, auth0Login } from '../../../store/auth/actions';
import SpinnerButton from '../../ui/spinner/spinnerButton';
import ThreeBounceSpinner from '../../ui/spinner/threeBounceSpinner';
import CleanInput from '../../form/cleanInput';
import loginErrorMessages from './loginErrors';
import Recaptcha from '../../../components/verification/bkmdRecaptcha';
import { TosLink, PrivacyPolicyLink } from '../../ui/links/index';
import LoginBigHeader from '../../ui/loginBigHeader/loginBigHeader';
import FeatureFlagged from '../../features/featureFlagged';
import './login.less';
import { pathsWithQuery } from '../../../utils/pathsWithQuery';

const messages = defineMessages({
  title: {
    defaultMessage: 'You’re totally winning today!',
    id: 'providers.login.title',
  },
  login: {
    defaultMessage: 'Sign in',
    id: 'providers.login.login',
  },
  forgotPassword: {
    defaultMessage: 'I forgot my password',
    id: 'providers.login.forgotPassword',
  },
  usernameEmail: {
    defaultMessage: 'Your mobile number or email address',
    id: 'providers.login.usernameEmail',
  },
  password: {
    defaultMessage: 'Password',
    id: 'providers.login.password',
  },
  byClickingLogin: {
    defaultMessage: "By signing in, you agree to Vim's {space} {terms} and {privacy}",
    id: 'providers.login.byClickingLogin',
  },
});

@autobind
class Login extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    login: PropTypes.func.isRequired,
    loadFromStorage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    headerElement: PropTypes.element.isRequired,
    defaultRedirect: PropTypes.string,
    onLoginSuccess: PropTypes.func,
    onLoginStart: PropTypes.func,
    onLoginFailed: PropTypes.func,
    onLoginRenewPassword: PropTypes.func,
    auth0LoginFeature: PropTypes.bool,
    samlLoginFeature: PropTypes.bool,
    auth0Login: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultRedirect: '/secure',
    onLoginSuccess: undefined,
    onLoginStart: undefined,
    onLoginFailed: undefined,
    onLoginRenewPassword: undefined,
    auth0LoginFeature: false,
    samlLoginFeature: false,
    config: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      password: undefined,
    };
  }

  componentDidMount() {
    this.props.loadFromStorage();
    if (this.props.auth0LoginFeature) {
      this.auth0Login();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      auth,
      router,
      defaultRedirect,
      onLoginSuccess,
      onLoginRenewPassword,
      onLoginFailed,
    } = this.props;
    // Go to the next page only in case the user was verified
    if (auth.accessToken && !auth.loading && prevProps.auth.loading) {
      const {
        router,
        location: { query },
      } = prevProps;
      if (onLoginSuccess) {
        onLoginSuccess(this.props);
      } else {
        goToNext(router, query, defaultRedirect);
      }
    }

    if (!prevProps.auth.expiredToken && auth.expiredToken && !auth.loading) {
      const {
        router,
        location: { query },
      } = prevProps;

      if (onLoginRenewPassword) onLoginRenewPassword(this.props);
      goToNext(router, query, '/renewExpiredPassword');
    }

    if (auth.hasError && auth.error && !auth.loading && prevProps.auth.loading) {
      if (onLoginFailed) onLoginFailed(this.props, _.get(auth, 'error'));
      const error = loginErrorMessages.findByStatus(_.get(auth, 'error'));
      switch (error) {
        case loginErrorMessages.LOGIN_FAILED_PASSWORD_EXPIRED:
          router.push(`/changePassword?username=${this.state.username}`);
          break;
        case loginErrorMessages.ACCOUNT_LOCKED:
          router.push('blockedAccount');
          break;
      }
    }
  }

  onUsernameChange(e) {
    const username = e.target.value;
    this.setState({ username });
  }

  onPasswordChange(e) {
    const password = e.target.value;
    this.setState({ password });
  }

  gotoForgotPassword() {
    this.props.router.push(pathsWithQuery('/resetPassword', this.props.location.query));
  }

  auth0Login() {
    const { auth, config, samlLoginFeature } = this.props;

    this.auth0 = new auth0.WebAuth({
      domain: config.AUTH0_DOMAIN,
      clientID: config.AUTH0_CLIENT_ID,
    });

    if (!auth.accessToken && !auth.loading) {
      this.auth0.parseHash((err, authResult) => {
        if (!err && !authResult)
          return this.auth0.authorize({
            redirectUri: window.location.href,
            responseType: 'token',
            connection: samlLoginFeature ? config.BACKPACK_CONNECTION : undefined // TODO: move to NC
          });

        if (authResult && authResult.accessToken) {
          this.props.auth0Login(authResult.accessToken, window.location.href);
        }
      });
    }
  }

  login(e) {
    const { onLoginStart } = this.props;
    if (onLoginStart) onLoginStart(this.props);
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  }

  render() {
    const { intl, auth, headerElement, auth0LoginFeature } = this.props;
    const errorType = loginErrorMessages.findByStatus(_.get(this, 'props.auth.error'));
    const loginError =
      auth.hasError || auth.openIdError ? intl.formatMessage(errorType.message) : null;

    const usernameLabel = intl.formatMessage(messages.usernameEmail);
    const passwordLabel = intl.formatMessage(messages.password);

    const captchaRow = this.props.auth.requireCaptcha ? (
      <div className="row captcha">
        <div className="col-md-12">
          <Recaptcha />
        </div>
      </div>
    ) : null;

    return (
      <div className="login-wrap">
        <LoginBigHeader svgImg={headerElement} />
        {!auth0LoginFeature ? (
          <form>
            <div className="container container-1040">
              <div className="row no-margin">
                <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4 no-padding">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xs-12 text-center">
                          <h1>
                            <FormattedMessage {...messages.title} />
                          </h1>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <CleanInput
                            value={this.state.username}
                            onChange={this.onUsernameChange}
                            label={usernameLabel}
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoFocus
                          />
                        </div>
                        <div className="col-md-12">
                          <CleanInput
                            type="password"
                            value={this.state.password}
                            onChange={this.onPasswordChange}
                            errorText={loginError}
                            label={passwordLabel}
                            autoCapitalize="off"
                            autoCorrect="off"
                          />
                        </div>
                      </div>

                      {captchaRow}

                      <div className="row">
                        <div className="col-xs-12">
                          <SpinnerButton
                            className="btn btn-big btn-sm-block bg-color-brand-button"
                            onClick={this.login}
                            isLoading={auth.loading}
                          >
                            <FormattedMessage {...messages.login} />
                          </SpinnerButton>
                        </div>
                      </div>
                      <div className="row signin-help-footer margin-top-20">
                        <div className="col-xs-12 text-center">
                          <button
                            className="btn btn-inline-link font-color-brand-link"
                            onClick={this.gotoForgotPassword}
                          >
                            <FormattedMessage {...messages.forgotPassword} />
                          </button>
                        </div>
                      </div>
                      <FeatureFlagged uri="layout.showTermsOfService">
                        <div className="row margin-top-20">
                          <div className="col-xs-12 text-center text-14">
                            <FormattedMessage
                              {...messages.byClickingLogin}
                              values={{
                                terms: <TosLink className="underline-link" />,
                                privacy: <PrivacyPolicyLink className="underline-link" />,
                                space: (
                                  <span>
                                    <br />
                                  </span>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </FeatureFlagged>
                    </div>
                    <div className="card-footer text-center margin-bottom-clean-form" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <ThreeBounceSpinner />
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      auth: state.auth,
      appName: state.config.appName,
    }),
    { loadFromStorage, login, auth0Login },
  ),
  injectIntl,
  // routeParams caused prop update and component re-render every time router.push/replace was
  // called and thereof made infinite loop because router.push is called in componentDidUpdate
  mapProps(props => _.omit(props, ['routeParams'])),
)(Login);
