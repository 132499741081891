/* eslint-disable import/first, react/jsx-filename-extension */
/**
 * Created by chenrozenes on 25/04/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);
import React from 'react';
import requireAccess from '../../../../store/auth/limitedAccessComponent';
import { BACK_OFFICE_PROVIDERS } from '../../../../model/enum/aclResourceType';
import ProvidersList from './providersList';
import ProviderPage from './providerPage';
import providerLocationsList from './providerLocationsList';
import providerLocation from './providerLocation';

const divComponent = props => <div>{props.children}</div>;

export default {
  path: 'providers',
  name: 'providers',
  childRoutes: [
    {
      path: ':npi/locations',
      name: 'providerLocations',
      component: providerLocationsList.default || providerLocationsList,
    },
    {
      path: ':npi',
      name: 'providerPage',
      component: ProviderPage.default || ProviderPage,
    },
    {
      path: ':npi/locations/:id',
      name: 'locationPage',
      component: providerLocation.default || providerLocation,
    },
  ],

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: requireAccess({ resourceType: BACK_OFFICE_PROVIDERS })(divComponent),

  indexRoute: {
    name: 'providersList',
    component: ProvidersList.default || ProvidersList,
  },
};
/* eslint-enable import/first, react/jsx-filename-extension */
