/**
 * Created by ofirHartuv on 30/10/17.
 */
import PromiseActionType from '../../../promiseActionType';
import Formatter from '../../../../utils/formatter';

export const GET_ALL_CALENDARS_BY_CLINIC = new PromiseActionType('GET_ALL_CALENDARS_BY_CLINIC');
export const GET_ALL_CALENDARS_BY_PROVIDER = new PromiseActionType('GET_ALL_CALENDARS_BY_PROVIDER');
export const UPDATE_CALENDAR = new PromiseActionType('UPDATE_CALENDAR');
export const DELETE_CALENDAR = new PromiseActionType('DELETE_CALENDAR');
export const CREATE_CALENDAR = new PromiseActionType('CREATE_CALENDAR');
export const GET_CALENDAR = new PromiseActionType('GET_CALENDAR');

export function getAllCalendarsForClinic(skip = 0, limit = 10, search, sort, sortAscending) {
  skip = skip || skip === 0 ? skip : 0;
  limit = limit || limit === 0 ? limit : 10;
  return ({ bkmdApi: { calendarsApi } }) => ({
    type: GET_ALL_CALENDARS_BY_CLINIC.SOURCE,
    meta: {
      tracker: GET_ALL_CALENDARS_BY_CLINIC.SOURCE,
    },
    payload: {
      promise: calendarsApi
        .getAllCalendarsForClinic(skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export function getAllCalendarsForProvider(skip = 0, limit = 10, search, sort, sortAscending) {
  skip = skip || skip === 0 ? skip : 0;
  limit = limit || limit === 0 ? limit : 10;
  return ({ bkmdApi: { calendarsApi } }) => ({
    type: GET_ALL_CALENDARS_BY_PROVIDER.SOURCE,
    meta: {
      tracker: GET_ALL_CALENDARS_BY_PROVIDER.SOURCE,
    },
    payload: {
      promise: calendarsApi
        .getAllCalendarsForProvider(skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export function getCalendarById(id) {
  return ({ bkmdApi: { calendarsApi } }) => ({
    type: GET_CALENDAR.SOURCE,
    meta: {
      tracker: GET_CALENDAR.SOURCE,
    },
    payload: {
      promise: calendarsApi.getCalendarById(id).then(res => {
        // converting the duration to minutes for display
        res.data.blockSize = Formatter.msToMinutes(res.data.blockSize);
        return res.data;
      }),
    },
  });
}

export function updateCalendar(data) {
  // converting the duration to ms because we display it as minutes
  data.blockSize = Formatter.minutesToMs(data.blockSize);

  return ({ bkmdApi: { calendarsApi } }) => ({
    type: UPDATE_CALENDAR.SOURCE,
    meta: {
      tracker: UPDATE_CALENDAR.SOURCE,
    },
    payload: {
      promise: calendarsApi.updateCalendar(data).then(res => {
        // converting the duration to minutes for display
        res.data.blockSize = Formatter.msToMinutes(res.data.blockSize);
        return res.data;
      }),
    },
  });
}

export function deleteCalendar(id) {
  return ({ bkmdApi: { calendarsApi } }) => ({
    type: DELETE_CALENDAR.SOURCE,
    meta: {
      tracker: DELETE_CALENDAR.SOURCE,
    },
    payload: {
      promise: calendarsApi.deleteCalendar(id).then(res => res.data),
    },
  });
}

export function createCalendar(data) {
  // converting the duration to ms because we display it as minutes
  data.blockSize = Formatter.minutesToMs(data.blockSize);
  return ({ bkmdApi: { calendarsApi } }) => ({
    type: CREATE_CALENDAR.SOURCE,
    meta: {
      tracker: CREATE_CALENDAR.SOURCE,
    },
    payload: {
      promise: calendarsApi.createCalendar(data).then(res => res.data),
    },
  });
}
