import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isNil } from 'lodash';
import { withStateFetchersOnMount } from '../../../../../api/injectApi/withStateFetchersOnMount';
import EntityForm from '../../components/entityForm/entityForm';
import { entityFieldsDefGroups } from './referralFormDefs';
import { internalApi } from '../../internal-api';

const getReferralDetailsByGroup = (referralDetails, fieldsDef) => {
  const partialReferralDetails = {};

  for (const key in fieldsDef) {
    if (Object.hasOwnProperty.call(fieldsDef, key)) {
      const fieldName = fieldsDef[key].name;
      if(fieldName) {
        partialReferralDetails[fieldName] = referralDetails[fieldName];
      } 
    }
  }

  return partialReferralDetails;
}

const prepareDataToDisplay = (referralDetails) => {
  const data = {...referralDetails};

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      if(typeof data[key] === 'boolean') {
        data[key] = data[key] ? 'True' : 'False';
      } else if(!data[key]) {
        data[key] = 'No Data Available'
      }
    }
  }

  return data;
}

const ReferralDetails = ({ referralDetails }) => {
  return (
    <div>
      {entityFieldsDefGroups.map(item => {
        return (
        <EntityForm
          name={item.title}
          title={item.title}
          fieldsDef={item.fieldsDef}
          entity={getReferralDetailsByGroup(referralDetails, item.fieldsDef)}
          readOnly
        />
        )
      })}
      </div>
)};

ReferralDetails.propTypes = {
  router: PropTypes.object.isRequired,
};

export default compose(
  withStateFetchersOnMount({
    getReferralDetails: {
      handler: ({ params }) => async () =>
        prepareDataToDisplay(await internalApi.referralsAggregator.getReferralById(params.id)),
      resultPropName: 'referralDetails',
      defaultValue: null,
      isReady: ({ referralDetails }) => !isNil(referralDetails),
    },
  }),
  withRouter,
)(ReferralDetails);
