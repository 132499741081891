import _ from 'lodash';

import LoadFromCsvStep from '../steps/loadClinicFromCSV';
import SelectPayers from '../steps/selectPayers';
import showClinicsCSVLoadResultsForm from '../steps/showClinicsCSVLoadResults';

const name = 'loadFromCsv';
const steps = {
  LOAD_FROM_CSV: 'loadFromCsv',
  SELECT_PAYERS: 'selectPayers',
  FINAL_RESULTS: 'finalResults',
};

const flow = {
  [steps.LOAD_FROM_CSV]: {
    component: LoadFromCsvStep,
    transitions: {
      NEXT: ({ data: { clinics } }) => _.isEmpty(clinics) ? steps.FINAL_RESULTS : steps.SELECT_PAYERS,
    },
    mapFlowDataToProps: ({ medicalCodes }) => ({
      medicalCodes,
    }),
    mapPayloadToFlowData: ({
      clinics,
      clinic,
      clinicUniqueId,
      clinicIdArray,
      validProviders,
      invalidProviders,
      loadedClinicsProviders,
      typeValue,
      errors,
      errorsClinics,
      errorColumnsOrder,
      errorUniqueClinicId,
    }) => ({
      clinics,
      clinic,
      clinicUniqueId,
      clinicIdArray,
      clinicId: _.get(clinic, 'id'),
      validProviders,
      invalidProviders,
      typeValue,
      loadedClinicsProviders,
      errors,
      errorsClinics,
      errorColumnsOrder,
      errorUniqueClinicId,
    }),
  },
  [steps.SELECT_PAYERS]: {
    component: SelectPayers,
    transitions: {
      NEXT: steps.FINAL_RESULTS,
    },
    mapFlowDataToProps: flowData => {
      const {
        clinics,
        validProviders,
        clinicIdArray,
        typeValue,
        loadedClinicsProviders,
        payer,
        clinicId,
        errors,
        errorColumnsOrder,
      } = flowData;
      return {
        clinics,
        multiSelect: true,
        typeValue,
        clinicIdArray,
        validProviders,
        loadedClinicsProviders,
        payer,
        errors,
        clinicId,
        errorColumnsOrder,
      };
    },
    mapPayloadToFlowData: ({
      payer,
      loadedClinicsProviders,
      errorColumnsOrder,
      errors,
      created,
      updated,
      deleted,
    }) => ({
      selectedPayers: _.clone(payer),
      loadedClinicsProviders,
      errorColumnsOrder,
      errors,
      created,
      updated,
      deleted,
    }),
    evaluate: ({ data: { errorColumnsOrder } }) => !errorColumnsOrder,
  },
  [steps.FINAL_RESULTS]: {
    component: showClinicsCSVLoadResultsForm,
    mapFlowDataToProps: ({
      loadedClinicsProviders,
      onSuccess,
      created,
      updated,
      deleted,
      payer,
      errors,
      errorsClinics,
      validProviders,
      invalidProviders,
      errorColumnsOrder,
      errorUniqueClinicId,
    }) => ({
      loadedClinicsProviders,
      onSuccess,
      created,
      updated,
      deleted,
      payer,
      errors,
      errorsClinics,
      validProviders,
      invalidProviders,
      errorColumnsOrder,
      errorUniqueClinicId,
    }),
    transitions: {},
  },
};

const initialStepName = steps.LOAD_FROM_CSV;

export default {
  name, steps, flow, initialStepName
};
