import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from './messages';
import CoOpLogo from '../../branding/co_opLogo';
import Header from '../../ui/header/header';
import './unauthorized.less';

import { isDirectoryAllowed } from '../../../store/ui/selectors';

function GoBack({ shouldRender, goHome }) {
  return (
    shouldRender && (
      <div>
        <button className="btn btn-big bg-color-brand-button" onClick={goHome}>
          <FormattedMessage {...messages.goBack} />
        </button>
      </div>
    )
  );
}

GoBack.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
  goHome: PropTypes.func.isRequired,
};

const EnhancedGoBack = connect(
  state => ({
    shouldRender: isDirectoryAllowed(state),
  }),
  { goHome: () => push('/') },
)(GoBack);

function UnauthorizedPage() {
  return (
    <div className="unauthorized">
      <Header>
        <CoOpLogo className="pull-left" externalPageLogo />
      </Header>
      <div className="unauthorized-content text-center">
        <h1 className="title-36 text-strong md-margin font-color-brand-main title-brand-1">
          <FormattedMessage {...messages.h1} />
        </h1>
        <div className="error-text font-color-brand-main">
          <FormattedMessage {...messages.invalidMemberForUserTitle} />
        </div>
        <EnhancedGoBack />
      </div>
    </div>
  );
}

UnauthorizedPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(UnauthorizedPage);
