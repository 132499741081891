/**
 * Created by Nico on 3/05/2018.
 */

import _ from 'lodash';
import Joi from 'joi-browser';
import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';
import { BkmdDomains } from 'Model/enum/bkmdDomain';
import BookMdUtil from 'Utils/util';

import {
  ReduxFormCleanInput,
  ReduxFormCleanInputWithPicture,
  ReduxFormCleanPasswordInput,
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanSelect,
  ReduxFormCleanCheckbox,
  ReduxFormCleanRepeatPasswordInput,
} from '../../../../../components/form/reduxForm/components';
import { PasswordSchema } from '../../../../../utils/commonSchema';
import reduxStatefulTypeahead from '../../../../../components/form/reduxForm/components/reduxStatefulTypeahead';

const MINIMAL_PASSWORD_STRENGTH = 2;
export const name = 'backoffice-create-vim-guide-profile';

export const fields = (asoGroups = []) => ({
  firstName: {
    name: 'firstName',
    component: ReduxFormCleanInput,
  },
  middleName: {
    name: 'middleName',
    component: ReduxFormCleanInput,
  },
  lastName: {
    name: 'lastName',
    component: ReduxFormCleanInput,
  },
  externalId: {
    name: 'externalId',
    component: ReduxFormCleanInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  email: {
    name: 'email',
    component: ReduxFormCleanInput,
  },
  password: {
    name: 'password',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  domains: {
    name: 'domains',
    component: ReduxFormCleanSelect,
    options: BookMdUtil.toSelectOptions(BkmdDomains),
    clearable: true,
  },
  asoGroups: {
    placeholderText: 'Aso Groups',
    id: 'asoGroups',
    name: 'asoGroups',
    label: 'asoGroups',
    component: reduxStatefulTypeahead,
    options: asoGroups.map(value => ({ value: value.groupId, label: value.name })),
    parse: res => _.map(res, 'value'),
    idKey: 'value',
    labelKey: 'label',
  },
  profilePicture: {
    name: 'profilePicture',
    component: ReduxFormCleanInputWithPicture,
    height: 120,
    width: 120,
  },
  'csr-admin': {
    name: 'csr-admin',
    id: 'csr-admin',
    label: 'Administrator (can associate users to ASOs)',
    component: ReduxFormCleanCheckbox,
    parse: value => !!value,
  },
  isTemporaryPassword: {
    name: 'isTemporaryPassword',
    id: 'isTemporaryPassword',
    component: ReduxFormCleanCheckbox,
    // Bug in ReduxForm - Unchecked checkbox is empty string instead of false
    // https://github.com/erikras/redux-form/issues/3162
    parse: value => !!value,
  },
  isVimInternal: {
    name: 'isVimInternal',
    id: 'isVimInternal',
    component: ReduxFormCleanCheckbox,
    // Bug in ReduxForm - Unchecked checkbox is empty string instead of false
    // https://github.com/erikras/redux-form/issues/3162
    parse: value => !!value,
  },
  /**
   * This field is a computed field that we never render (hidden field)
   * We use this field for validating the password field
   * (must be stronger than the minimal strength).
   */
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
});

export const schema = {
  firstName: Joi.string().required(),
  middleName: Joi.string()
    .allow('', null)
    .optional(),
  lastName: Joi.string().required(),
  externalId: Joi.string()
    .allow('', null)
    .optional(),
  password: Joi.string().required(),
  email: Joi.string().required(),
  phoneNumber: Joi.object()
    .keys({
      number: Joi.string().optional(),
      countryDialingCode: Joi.string().optional(),
    })
    .required(),
  domains: Joi.string()
    .valid(_.values(BkmdDomains))
    .required(),
  asoGroups: Joi.array()
  .items(Joi.string())
  .allow([], null)
  .optional(),
  profilePicture: Joi.string().optional(),
  roles: Joi.array()
  .items(Joi.string())
  .allow([], null)
  .optional(),
  isTemporaryPassword: Joi.boolean().required(),
  isVimInternal: Joi.boolean().optional(),
  repeatPassword: PasswordSchema.required(),
  passwordStrength: Joi.object().keys({
    score: Joi.number(),
    deprecatedCharacters: Joi.number(),
  }),
};

export const customValidator = () => {
  const { password, passwordStrength, repeatPassword } = fields();

  return {
    [password.name]: (value, values) => {
      if (!values[passwordStrength.name]) return undefined;

      const { deprecatedCharacters } = values[passwordStrength.name];
      const passwordStrengthScore = values[passwordStrength.name].score;

      if (deprecatedCharacters) {
        return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
      }
      if (!passwordStrengthScore || passwordStrengthScore < MINIMAL_PASSWORD_STRENGTH) {
        return 'Password is too weak';
      }
      return undefined;
    },
    [repeatPassword.name]: (value, values) => {
      if (values[password.name] !== values[repeatPassword.name]) {
        return 'Passwords are not identical';
      }
      return undefined;
    },
  }
};
