import { ReduxFormCleanPhoneNumber } from '../../../../components/form/reduxForm/components';
import { PhoneNumberSchema } from '../../../../utils/commonSchema';

export const name = 'backoffice-invite-member';

export const fields = {
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
};

export const schema = {
  phoneNumber: PhoneNumberSchema.required(),
};
