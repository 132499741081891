import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanSelect,
  ReduxFormListDisplay,
} from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-user-group';

export const fields = {
  name: {
    name: 'name',
    component: ReduxFormCleanInput,
  },
  ownerType: {
    name: 'ownerType',
    component: ReduxFormCleanSelect,
  },
  ownerId: {
    name: 'ownerId',
    component: ReduxFormCleanInput,
  },
  users: {
    name: 'users',
    component: ReduxFormListDisplay,
  },
};

export const schema = {
  id: Joi.number().required(),
  name: Joi.string().required(),
  ownerType: Joi.string(),
  ownerId: Joi.number(),
  users: Joi.array().items(Joi.string()),
};
