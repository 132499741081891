/**
 * Created by galgoltzman on 07/05/2018.
 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './loginBigHeader.less';

class LoginBigHeader extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    svgImg: PropTypes.object,
  };

  static defaultProps = {
    className: undefined,
    svgImg: undefined,
  };

  render() {
    const { svgImg, className } = this.props;
    return (
      <div className={classNames('login-big-header', className)}>
        {svgImg}
      </div>
    );
  }
}

export default LoginBigHeader;
