export const entityFieldsDefGroups = [
  {
    title: 'General',
    fieldsDef: {
      referralId: {
        name: 'referralId',
        label: 'Referral Id',
        readOnly: true,
      },
      createdAt: {
        name: 'createdAt',
        label: 'Created At',
        readOnly: true,
      },
      updatedAt: {
        name: 'updatedAt',
        label: 'Last Updated',
        readOnly: true,
      },
      organizationName: {
        name: 'organizationName',
        label: 'Referring Clinic',
        readOnly: true,
      },
      organizationId: {
        name: 'organizationId',
        label: 'Organization ID',
        readOnly: true,
      },
    },
  },
  {
    title: 'Referring Provider',
    fieldsDef: {
      referringProviderNpi: {
        name: 'referringProviderNpi',
        label: 'NPI',
        readOnly: true,
      },
      referringProviderEhrId: {
        name: 'referringProviderEhrId',
        label: 'EHR ID',
        readOnly: true,
      },
    },
  },
  {
    title: 'Referral Details',
    fieldsDef: {
      specialty: {
        name: 'specialty',
        label: 'Specialty',
        readOnly: true,
      },
      taxonomies: {
        name: 'taxonomies',
        label: 'Taxonomies',
        readOnly: true,
      },
      icd: {
        name: 'icd',
        label: 'ICD',
        readOnly: true,
      },
      cpt: {
        name: 'cpt',
        label: 'CPT',
        readOnly: true,
      },
      status: {
        name: 'status',
        label: 'Status',
        readOnly: true,
      },
      startDate: {
        name: 'startDate',
        label: 'Start Date',
        readOnly: true,
      },
      endDate: {
        name: 'endDate',
        label: 'End Date',
        readOnly: true,
      },
    },
  },
  {
    title: 'Target Provider',
    fieldsDef: {
      targetProviderNpi: {
        name: 'targetProviderNpi',
        label: 'NPI',
        readOnly: true,
      },
      targetProviderName: {
        name: 'targetProviderName',
        label: 'Name',
        readOnly: true,
      },
      targetProviderAddress: {
        name: 'targetProviderAddress',
        label: 'Address',
        readOnly: true,
      },
      targetProviderEhrId: {
        name: 'targetProviderEhrId',
        label: 'EHR ID',
        readOnly: true,
      },
    },
  },
  {
    title: 'Patient',
    fieldsDef: {
      insurer: {
        name: 'insurer',
        label: 'Insurer',
        readOnly: true,
      },
      ehrInsurer: {
        name: 'ehrInsurer',
        label: 'EHR Insurer',
        readOnly: true,
      },
      domainMemberId: {
        name: 'domainMemberId',
        label: 'Domain Member ID',
        readOnly: true,
      },
    },
  },
];
