/* eslint-disable no-return-await */
import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import injectNotification from 'Store/notification/injectNotification';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import { internalApi } from '../../internal-api';

function EhrUser({ unlockEhrUserClick }) {
  return (
    <div>
      <button className="btn btn-small btn-blue text-semibold small-margin action-button" onClick={unlockEhrUserClick}>Unlock EHR User</button>
    </div>
  );
}

EhrUser.propTypes = {
  unlockEhrUserClick: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  injectNotification,
  withFetchers({
    unlockEhrUserClick: {
      handler: ({ params }) => async () => (await internalApi.namespace(params.namespaceName).puppeteerVsAdapter.unlockEhrUser()),
      onSuccess({ notification }) {
        return notification.success('Success!', 'EHR user unlocked!');
      },
      onError({ notification }, error) {
        console.error(`Failed to unlock EHR user!`, { error });

        return notification.error('Oops', 'Failed to unlock EHR user');
      },
    },
  }),
)(EhrUser);