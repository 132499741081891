import _ from 'lodash';
import moment from 'moment-timezone';
import extractPrefix from './extractPrefixFromMemberId';
import { MEMBER_NOT_FOUND } from '../api/errors';

const DEFAULT_DELTA = 0;
const groupByPatient = function groupByPatient(appointments) {
  return _.groupBy(appointments, value => `${value.firstName} ${value.lastName}`); // todo: mapping already exists
};

const groupByProvider = function groupByProvider(appointments) {
  return _.map(appointments, appointments => _.groupBy(appointments, value => value.npi));
};

/**
 * Create an array of insurance ids from exisiting array and new insuranceId
 */
const mergeMemberIds = function mergeMemberIds(acc, insuranceId) {
  const clearMemberId = _.castArray(insuranceId);
  return _.uniq(_.filter(_.concat(acc, clearMemberId)));
};

/**
 * Greedy algorithm to consectutive appointments.
 * (appointment start time + delta = previous appointment end time).
 */
const merge = function merge(appointments, options) {
  const { timeDelta = DEFAULT_DELTA } = options;
  const sortedAppointments = _.sortBy(appointments, ['startTime']);
  const mergedAppointments = _.reduce(
    sortedAppointments,
    (acc, appointment) => {
      let toAdd = appointment;
      let rest = acc;
      const lastMergedAppointment = _.last(acc);
      const lastEndTime = _.get(lastMergedAppointment, 'endTime', -1);
      const difference = Math.abs(new Date(lastEndTime) - new Date(appointment.startTime));
      if (difference <= timeDelta) {
        toAdd = {
          ...lastMergedAppointment,
          endTime: appointment.endTime,
          insuranceId: mergeMemberIds(lastMergedAppointment.insuranceId, appointment.insuranceId)
        };
        rest = _.take(acc, acc.length - 1);
      }
      return rest.concat(toAdd);
    },
    []
  );

  return mergedAppointments;
};

/**
 * Handles appointments grouped by npi and send them for their actuall merge.
 */
const mergeAppointments = function mergeAppointments(options, groupedAppointments) {
  return _.map(groupedAppointments, appointments =>
    _.flatten(_.map(appointments, toMergeAppointments => merge(toMergeAppointments, options)))
  );
};

/**
 * Flow to merge consecutive appointments.
 */
const mergeConsecutive = function mergeConsecutive(options, appointments) {
  const mergeFlow = _.flow([
    groupByPatient,
    groupByProvider,
    _.partial(mergeAppointments, options),
    _.flatten
  ]);
  return mergeFlow(appointments);
};

const removeEmpty = function removeEmpty(data) {
  return _.filter(data, _.some);
};

const createAppointmentTime = function createAppointmentTime(
  timezone,
  date,
  time,
  dateFormat = 'M/D/YYYY',
  timeFormat = 'h:mm:ss A'
) {
  return (
    timezone &&
    date &&
    time &&
    moment.tz(`${date} ${time}`, `${dateFormat} ${timeFormat}`, timezone)
  );
};
/**
 * Map each raw appointmnet to real appointment, using provided mapping object.
 * @param {Object} mapping
 * @param {Array} data
 */
const mapDataToScheme = function mapDataToScheme(mapping, data) {
  return _.map(data, val => {
    const { properties, meta, providers, statuses } = mapping;
    const {
      startTime,
      endTime,
      startDate,
      endDate,
      firstName,
      duration,
      lastName,
      dateOfBirth,
      insurer,
      insuranceId,
      phoneNumber,
      providerName,
      status,
      reasonForVisit,
      email,
      type
    } = properties;
    const {
      startTimeFormat,
      startDateFormat,
      endTimeFormat,
      endDateFormat,
      dateOfBirthFormat,
      timezone,
      defaultDuration
    } = meta;
    const formattedNumber = val[phoneNumber].replace(/\D/g, '');
    const startValue = createAppointmentTime(
      timezone,
      val[startDate],
      val[startTime],
      startDateFormat,
      startTimeFormat
    );
    const endValue = createAppointmentTime(
      timezone,
      val[endDate],
      val[endTime],
      endDateFormat,
      endTimeFormat
    );
    const durationToAdd = (duration && val[duration]) || defaultDuration;
    const mapped = {
      startTime: startValue.utc().toISOString(),
      endTime:
        endValue ||
        startValue
          .add(durationToAdd, 'minutes')
          .utc()
          .toISOString(),
      npi: _.findKey(providers, provider => provider === val[providerName]),
      firstName: val[firstName],
      lastName: val[lastName],
      insurer: val[insurer],
      status: _.findKey(statuses, statusDefs => _.includes(statusDefs, val[status])),
      reasonForVisit: val[reasonForVisit],
      dateOfBirth: moment.utc(val[dateOfBirth], dateOfBirthFormat).format('MM/DD/YYYY'),
      insuranceId: _.castArray(val[insuranceId]),
      phoneNumber: formattedNumber,
      email: val[email],
      type: val[type]
    };
    return _.pickBy(mapped);
  });
};

/**
 * comparator function that also merge.
 * note that this method should be replaced with a pure one that.
 */
const compareAndMerge = function compareAndMerge(first, second) {
  const keys = _.keys(_.omit(first, ['insuranceId', 'packageName']));
  const isEqual = _.every(keys, key => _.isEqual(first[key], second[key]));
  // @mrsufgi: break immutability
  if (isEqual) {
    const newMemberId = mergeMemberIds(first.insuranceId, second.insuranceId);
    first.insuranceId = newMemberId;
    second.insuranceId = newMemberId;
  }
  return isEqual;
};

const unifyIdentical = function unifyIdentical(data) {
  return _.uniqWith(data, compareAndMerge);
};

/**
 * Helper function that uses its api to retrieve insurer data based on insuranceId prefix
 * note that this logic should migrate to 'domainless' backend envoirnment
 * TODO:// @mrsufgi  - migrate to backend - unneccesary roundtrips on client.
 * TODO:// @mrsufgi - we keep it here for future referance, this logic might play an important
 * part in future tasks and it would be pretty impossible to dig throughout git history for it.
 * @param {Object} api
 * @param {Array} insuranceIds
 * @returns {Promise}
 */
const getInsurers = function getInsurers(api, insuranceIds, defaultInsurer = {}) { // eslint-disable-line
  return Promise.all(
    _.map(insuranceIds, async insuranceId => {
      const prefix = extractPrefix(insuranceId);
      let res = {};
      if (!_.isEmpty(prefix)) {
        const req = await api.getInsurerByPrefix(_.toUpper(prefix));
        res = { ...req.data, insuranceId };
      } else {
        res = { ...defaultInsurer, insuranceId };
      }
      return res;
    })
  ).then(insurers => _.reject(insurers, _.isEmpty));
};

const getInsurerForInsuranceIds = function getInsurerForInsuranceIds(
  insurers,
  insuranceIds,
  defaultInsurer = {}
) {
  return _.map(insuranceIds, insuranceId => ({ ...defaultInsurer, insuranceId }));
};

const filterStatuses = function filterNonActiveStatuses({ statuses }, appointments) {
  return _.isNil(statuses)
    ? appointments
    : _.filter(appointments, ({ status }) => status === 'ACCEPTED');
};

/**
 * filter failed appointments with MEMBER_NOT_FOUND and fallbackToVim flag on. ready for retry!
 * @param {Array} appointments
 */
const createRetrys = function createRetrys(appointments) {
  return _.map(
    appointments,
    ({ appointment, code }) =>
      code === MEMBER_NOT_FOUND &&
      appointment.fallbackToVim &&
      _.omit(appointment, 'fallbackToVim', 'insurerId')
  );
};

/**
 * Prossess raw data of appointments by mapping using the mapping object
 * remove empties and duplications. merge consecutives appointments.
 * @param {Array} data
 * @param {Object} mapping
 * @param {Object} options
 */
export function processBulkAppointments(data, mapping = {}, options = {}) {
  const process = _.flow([
    removeEmpty,
    _.partial(mapDataToScheme, mapping),
    _.partial(filterStatuses, mapping),
    unifyIdentical,
    _.partial(mergeConsecutive, options)
  ]);

  return data && process(data);
}

/**
 * Creating a map of appointments for domains,
 * note that appointments are duplicated for each different insurance id
 * @param {Array} domains
 * @param {Array} appointments
 */
export function splitAppointmentsToDomains(domains, appointments) {
  return _.mapValues(domains, domain =>
    _.flatten(
      _.map(appointments, appointment => {
        const { insurances, ...rest } = appointment;
        const filtered = _.filter(insurances, insurance => insurance.domain === domain);
        const merged = _.uniqWith(filtered, compareAndMerge);
        return _.map(merged, insurance => {
          const { insuranceId, insurerId, fallbackToVim } = insurance;
          // @mrsufgi: Note that we're creating a shallow copy here!
          return _.assign({}, rest, {
            insurerId,
            insuranceIds: _.castArray(insuranceId),
            fallbackToVim
          });
        });
      })
    )
  );
}

/**
 * Extend appointments with insurer data values based on it's insurnaceIds
 * @param {Object} insurers
 * @param {Array} appointments
 * @param {Object} defaultInsurer
 * @returns {Promise}
 */
export function addInsurersToAppointments(insurers, appointments, defaultInsurer) {
  return _.map(appointments, appointment => {
    const { insuranceId, ...rest } = appointment;
    const insurances = getInsurerForInsuranceIds(insurers, insuranceId, defaultInsurer);
    return _.assign(rest, { insurances });
  });
}

/**
 * create retrys for csv upload report. only for domains different than the one you fallback too.
 * @param {String} fallbackDomain
 * @param {Object} report
 */
export function createRetrysFromReport(fallbackDomain, report) {
  const retrys = _.map(
    report,
    ({ domain, fail }) => domain !== fallbackDomain && createRetrys(fail)
  );
  const filtered = _.reject(_.flatten(retrys), _.isEmpty);
  return { [fallbackDomain]: filtered };
}
