import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import moment from 'moment';
import {
  createAppointment,
  CREATE_APPOINTMENT,
} from 'Store/backoffice/clinicManagement/appointment/actions';
import actionTracker from 'Store/tools/actionTracker/actionTrackerComponent';
import { REQUESTED } from 'Model/enum/appointmentStatus';
import { MANUAL } from 'Model/enum/appointmentSource';
import EntityForm from '../../components/entityForm/entityForm';
import * as AppointmentFormDefs from './appointmentFormDefs';

const appointmentMock = {
  calendarId: undefined,
  domainMemberId: null,
  status: REQUESTED,
  source: MANUAL,
  npi: null,
  locationId: null,
  isReviewRequired: true,
  appointmentTypeId: null,
  startTime: moment
    .utc()
    .startOf('day')
    .format('MM/DD/YYYY HH:mm'),
  endTime: moment
    .utc()
    .startOf('day')
    .add(30, 'm')
    .format('MM/DD/YYYY HH:mm'),
  patientFullName: '',
  memberDomain: 'PREMERA_BLUE_CROSS_ALASKA',
};

@autobind
class CreateAppointment extends React.Component {
  static propTypes = {
    createAppointment: PropTypes.func.isRequired, // from redux
    tracker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    appointmentMock.calendarId = props.params.calendarId;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished && !nextProps.tracker.hasError) {
      this.goToAppointments();
    }
  }

  goToAppointments() {
    const { id, providerId, calendarId } = this.props.params;
    this.props.router.push(
      `/secure/clinics/${id}/providers/${providerId}/calendars/${calendarId}/appointments`,
    );
  }

  render() {
    return (
      <div>
        <div className="backoffice-action">
          <button
            onClick={this.goToAppointments}
            className="btn btn-small btn-blue text-semibold action-button margin-13"
          >
            Back To Calendar׳s Appointments
          </button>
        </div>
        <EntityForm
          name={AppointmentFormDefs.name}
          title="Create New Appointment:"
          submitText="Create"
          schemaDef={AppointmentFormDefs.schema}
          fieldsDef={AppointmentFormDefs.fields}
          entity={appointmentMock}
          onSubmit={this.props.createAppointment}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  actionTracker({
    tracker: CREATE_APPOINTMENT.SOURCE,
  }),
  connect(
    null,
    { createAppointment },
  ),
)(CreateAppointment);
