/**
 * Created by chenrozenes on 31/10/2017.
 */

import Promise from 'bluebird';
import csv from 'csv';
import { unflatten, flatten } from 'flat';
import Joi from 'joi-browser';
import _ from 'lodash';

Promise.promisifyAll(csv);

const loadFile = function loadFile(file, format) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = upload => {
      resolve(upload.target.result);
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsText(file, format);
  });
};

const clearLines = function clearLinesFromCSVText(text, count = 0) {
  const lines = text.split('\n');
  lines.splice(0, count);
  return lines.join('\n');
};

const numberToCapitalLetter = num => String.fromCharCode(65 + num);

export function calculateCSVFileOptionsFields(hasHeaders, csvRows = []) {
  let options = [];
  if (csvRows.length) {
    options = hasHeaders
      ? _.map(csvRows[0], (header, index) => ({ label: header, value: index }))
      : _.chain(csvRows[0].length)
          // If importer csv is without headers, create a list of
          // columns indicated by letters: 'Column A', 'Column B', etc...
          .range()
          .map(fieldNum => ({
            label: `Column ${numberToCapitalLetter(fieldNum)}`,
            value: fieldNum,
          }))
          .value();
  }
  return options;
}

export function loadJson(file) {
  return loadFile(file, 'json').then(text => JSON.parse(text));
}

export function loadCsv(file, schema, _unflatten = true, options = {}) {
  return loadFile(file, 'csv')
    .then(csvText => {
      const textWithoutHeaders = clearLines(csvText, options.skip);
      return csv.parseAsync(textWithoutHeaders, { from: options.from, columns: true });
    })
    .then(content => {
      if (_unflatten) content = _.map(content, item => unflatten(item));
      if (!schema) return content;
      const validation = Joi.validate(content, schema);
      if (validation.error) {
        throw new Error(validation.error);
      }
      return content;
    });
}

export function listToCsv(list) {
  const flattenList = list.map(flatten);
  const columnHeaders = _.keys(_.head(flattenList));
  const recordValues = _.map(flattenList, _.values);
  const csv = [columnHeaders, ...recordValues].map(lineValues => lineValues.join(',')).join('\n');
  return `data:text/csv;charset=utf-8,${csv}`;
}
