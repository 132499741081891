import { compose, withState, withHandlers } from 'recompose';
import { defineMessages } from 'react-intl';
import { back } from '../../../../../../components/wizard/flow/results';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import Api from '../../../../../../api/index';
import ProviderInput from '../../../../../../storybook/src/components/organisms/ProviderInput/ProviderInput';
import steps from './index';

const messages = defineMessages({
  error: {
    defaultMessage: 'Error occurred',
    id: 'backpack.schedulingCSI.error',
  },
});

const component = compose(
  injectNotification,
  withState('providerInfo', 'setProviderInfo', ({ providerInfo }) => providerInfo),
  withFetchers({
    onSubmit: {
      handler: () => ({ npi, address }) => Api.schedulingApi.providerCSI(npi, address),
      onError: ({ notification }) => notification.error('', messages.error),
      onSuccess: ({ control, providerInfo }, { data }) =>
        control.next({ payload: { data, providerInfo } }),
    },
  }),
  withHandlers({
    onSubmit: ({ setProviderInfo, onSubmit }) => ({ npi, address }) => {
      setProviderInfo({ npi, address });
      onSubmit({ npi, address });
    },
  }),
)(ProviderInput);

export default {
  component,
  transitions: {
    NEXT: steps.INVESTIGATION_PICKER,
    PREV: back,
  },
  mapFlowDataToProps: ({ providerInfo }) => ({ providerInfo }),
  mapPayloadToFlowData: ({ data, providerInfo }) => ({ providerCSI: data, providerInfo }),
};
