/**
 * Created by meirshalev 31/05/2018.
 */
import Promise from 'bluebird';
import _ from 'lodash';
import { flatten } from 'flat';
import PromiseActionType from '../../promiseActionType';

export const GET_ALL_FEATURE_FLAGS = new PromiseActionType('GET_ALL_FEATURE_FLAGS');
export const GET_ALL_FEATURES = new PromiseActionType('GET_ALL_FEATURES');
export const UPDATE_FEATURE_FLAGS = new PromiseActionType('UPDATE_FEATURE_FLAGS');

export function getAllFeatures() {
  return ({ bkmdApi: { metaApi } }) => ({
    type: GET_ALL_FEATURES.SOURCE,
    payload: {
      promise: metaApi.getAllFeatures()
        .then(res => res.data)
    }
  });
}

export function getAllFeatureFlags() {
  return ({ bkmdApi: { metaApi } }) => ({
    type: GET_ALL_FEATURE_FLAGS.SOURCE,
    payload: {
      promise: metaApi.getAllFeatureFlags()
        .then(res => res.data)
    }
  });
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ?
          changes(value, base[key])
          :
          value;
      }
    });
  }
  return changes(object, base);
}

export function updateFeatureFlags(updatedFeatureFlags, initialFeatureFlags) {
  return ({ bkmdApi: { metaApi } }) => {
    const updateFeatureFlag = (domain, feature, value) =>
      metaApi.updateFeatureFlag(domain, feature, value)
      .then(res => res.data);

    const promises = [];
    const diff = difference(updatedFeatureFlags, initialFeatureFlags);
    const flatDiff = flatten(diff);
    _.each(flatDiff, (value, feature) => {
      const splittedPath = _.split(feature, '.');
      const domain = splittedPath[0];
      const path = _.join(_.drop(splittedPath, 1), '.');
      promises.push(updateFeatureFlag(domain, path, value));
    });

    return {
      type: UPDATE_FEATURE_FLAGS.SOURCE,
      payload: {
        promise: Promise.all(promises)
          .then(() => metaApi.getAllFeatureFlags()
          .then(res => res.data)),
      },
      meta: {
        tracker: UPDATE_FEATURE_FLAGS.SOURCE,
      }
    };
  };
}
