/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Joi from 'joi-browser';
import _ from 'lodash';
import { reduxForm, Field } from 'redux-form';
import * as AddPermissionFormDefs from './addPermissionFormDefs';
import * as PermissionLevel from '../../../../../../model/enum/permissionLevel';
import * as AclResourceType from '../../../../../../model/enum/aclResourceType';
import { createValidator } from '../../../../../../utils/joiValidator';

const permissionOptions = function permissionOptions() {
  return _.map(PermissionLevel, (value, label) => ({ value, label }));
};

const resourceTypeOptions = function resourceTypeOptions(hasPhiAccess) {
  return _.map(AclResourceType, (value, label) => ({ value, label })).filter(resource =>
    hasPhiAccess ? resource : resource.value !== AclResourceType.BACK_OFFICE_PHI_ACCESS,
  );
};

@autobind
class AddPermissionForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    hasPhiAccess: PropTypes.bool.isRequired,
  };

  render() {
    const { hasPhiAccess, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} method="post">
        <Field
          id="promoteOnly"
          label="Promote Only"
          {...AddPermissionFormDefs.fields.promoteOnly}
        />
        <br />
        <br />
        <span>
          When promote only is set, action will not downgrade any existing permissions - if the user
          already has a wider permission, it will not be changed
        </span>
        <hr />
        <div className="row">
          <div className="col-xs-6">
            <Field
              placeholder="Permission"
              options={permissionOptions()}
              {...AddPermissionFormDefs.fields.permission}
            />
          </div>
          <div className="col-xs-6">
            <Field
              options={resourceTypeOptions(hasPhiAccess)}
              placeholder="Resource Type"
              {...AddPermissionFormDefs.fields.resourceType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <Field label="Resource Id" {...AddPermissionFormDefs.fields.resourceId} />
          </div>
          <div className="col-xs-8">
            <Field
              id="wideAccess"
              label="Grant all resources access"
              {...AddPermissionFormDefs.fields.wideAccess}
            />
          </div>
        </div>
      </form>
    );
  }
}

const joiValidator = createValidator(Joi.object().keys(AddPermissionFormDefs.schema));
function formValidator(value, props) {
  const validation = joiValidator(value, props);
  if (_.isNil(value.resourceId) !== _.defaultTo(value.wideAccess, false)) {
    // a.k.a logical XOR
    validation.resourceId = 'Either provide resource ID or approve general access';
  }
  return validation;
}
export default reduxForm({
  form: AddPermissionFormDefs.name,
  validate: formValidator,
})(AddPermissionForm);
