import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages } from 'react-intl';
import MemberGoTo from './memberGoTo';
import Formatter from '../../../../../../utils/formatter';
import commonFormats from '../../../../../../utils/formatter/commonFormats';
import CollapsibleCard from '../../../../../../components/ui/collapsibleCard/collapsibleCard';

const patientMessages = defineMessages({
  title: {
    defaultMessage: 'Patient details:',
    id: 'appointmentProductForm.member.title',
  },
  memberId: {
    defaultMessage: 'Member id:',
    id: 'appointmentProductForm.member.memberId',
  },
  fullName: {
    defaultMessage: 'Full name:',
    id: 'appointmentProductForm.member.fullName',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth:',
    id: 'appointmentProductForm.member.dateOfBirth',
  },
  address: {
    defaultMessage: 'Address:',
    id: 'appointmentProductForm.member.address',
  },
  plan: {
    defaultMessage: 'Plan:',
    id: 'appointmentProductForm.member.plan',
  },
  phoneNumber: {
    defaultMessage: 'Phone:',
    id: 'appointmentProductForm.member.phoneNumber',
  },
  email: {
    defaultMessage: 'Email:',
    id: 'appointmentProductForm.member.email',
  },
  ehrIdentifier: {
    defaultMessage: 'EHR identifier:',
    id: 'appointmentProductForm.member.ehrIdentifier',
  },
  insurer: {
    defaultMessage: 'Insurer:',
    id: 'appointmentProductForm.member.insurer',
  },
  isVerified: {
    defaultMessage: 'Is verified:',
    id: 'appointmentProductForm.member.isVerified',
  },
});

@autobind
class CollapsiblePatient extends React.Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    className: PropTypes.string,
    renderAdditional: PropTypes.func,
  };

  static defaultProps = {
    className: 'patient-details-card light-blue-title bg-grey-color',
    renderAdditional: () => null,
  };

  render() {
    const { member, className, renderAdditional } = this.props;

    if (!member) return null;
    return (
      <div>
        <CollapsibleCard
          title={patientMessages.title}
          id="patient-backoffice-details-id"
          className={className}
        >
          <div className="row padding-h-20">
            <MemberGoTo member={member} />
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.phoneNumber} />
              </h4>
              {Formatter.phoneNumberDefault(member.phoneNumber)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.memberId} />
              </h4>
              {member.memberIdWithPrefix}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.fullName} />
              </h4>
              {`${member.firstName} ${member.lastName}`}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.dateOfBirth} />
              </h4>
              {Formatter.dateUtc(member.dateOfBirth, commonFormats.SHORT_MONTH_DAY_YEAR_DATE)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.insurer} />
              </h4>
              {member.insurer}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.address} />
              </h4>
              {member.address}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.plan} />
              </h4>
              {_.get(member, 'plan.description')}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.email} />
              </h4>
              {member.email}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>
                <FormattedMessage {...patientMessages.isVerified} />
              </h4>
              {_.invoke(member.verified, 'toString')}
            </div>
          </div>
          {member.ehrIdentifier && (
            <div className="row">
              <div className="col-xs-12">
                <h4>
                  <FormattedMessage {...patientMessages.ehrIdentifier} />
                </h4>
                {member.ehrIdentifier}
              </div>
            </div>
          )}
          {renderAdditional()}
        </CollapsibleCard>
      </div>
    );
  }
}

export default compose(
  withPropsOnChange(['member'], ({ member }) => {
    if (!member) return { member: undefined };
    const memberObj = {
      ...member,
      memberIdWithPrefix: member.memberIdPrefix
        ? `${member.memberIdPrefix}${member.memberId}`
        : `${member.memberId}`,
    };

    return { member: memberObj };
  }),
)(CollapsiblePatient);
