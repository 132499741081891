import { compose, withProps, withPropsOnChange } from 'recompose';
import gql from 'graphql-tag';
import { isEqual, isEmpty, get, pickBy, identity, omit, values } from 'lodash';
import { withFetchers } from '../../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../../store/notification/injectNotification';
import { withTranslatedMessages } from '../../../../../../utils/withTranslatedMessages';
import { withStateFetchersOnMount } from '../../../../../../api/injectApi/withStateFetchersOnMount';
import { namespaceGraphqlFetcher } from '../../../internal-api/graphql/fetcher';
import { fullAppointmentActionFragment } from '../../../internal-api/graphql/fragments';
import AppointmentActionDetails from './view';
import * as AppointmentActionFormDefs from './formDefs';
import AppointmentStatusIntl from '../../../../../../model/enum/appointmentStatusIntlEnum';

const { appointmentActionMessages } = AppointmentActionFormDefs;
const errorMessages = {
  noChanges: 'noChangesErrorMessage',
  blankAppointment: 'blankAppointmentIdErrorMessage',
};

const extractAppointmentActionData = queryResult => {
  const appointmentAction = get(queryResult, 'data.getAppointmentAction[0]', {});
  const {
    platformId,
    npi,
    patientId,
    appointmentId,
    appointmentType,
    interchangeableAppointmentTypes,
    timezone,
    status,
    ehrLocationId,
    ehrProviderId,
    actionType,
    startTime,
    endTime,
    createdAt,
    updatedAt,
  } = appointmentAction;

  return {
    platformId,
    npi,
    patientId,
    appointmentId,
    appointmentType,
    interchangeableAppointmentTypes,
    timezone,
    status,
    ehrLocationId,
    ehrProviderId,
    actionType,
    startTime,
    endTime,
    createdAt,
    updatedAt,
  };
};

const extractPatientData = queryResult => {
  const appointmentAction = get(queryResult, 'data.getAppointmentAction[0]', {});
  const phi = get(appointmentAction, 'phi', {});

  const { plan, ehrInsuranceId, insurer } = appointmentAction;
  const { memberId, firstName, lastName, dateOfBirth, phone, patientAddress: address, email } = phi;
  const mobilePhoneNumber = get(phi, 'phoneNumbers[0].number');

  return {
    plan,
    ehrInsuranceId,
    insurer,
    memberId,
    firstName,
    lastName,
    dateOfBirth,
    phone,
    address,
    email,
    mobilePhoneNumber,
  };
};

const throwErrorIfConditionIsNotSatisfied = (condition, errorMessage) => {
  if (condition) {
    throw new Error(errorMessage);
  }
};

const getFieldsForUpdate = (originalAppointmentAction, newData) => {
  const fields = newData;

  if (fields.status.value) fields.status = fields.status.value;

  throwErrorIfConditionIsNotSatisfied(!fields.appointmentId, errorMessages.blankAppointment);

  throwErrorIfConditionIsNotSatisfied(
    isEqual(originalAppointmentAction, fields),
    errorMessages.noChanges,
  );

  return omit(fields, ['patient', 'ehrProviderId', 'createdAt', 'updatedAt']);
};

const checkAvailabilityBeforeResend = async (
  {
    ehrLocationId,
    ehrProviderId,
    appointmentType,
    interchangeableAppointmentTypes,
    startTime,
    endTime,
    timezone,
    patient,
  },
  { namespaceName },
  notification,
) => {
  const insurer = patient?.insurer;
  const {
    data: {
      canBeBooked: { isSlotFree },
    },
  } = await namespaceGraphqlFetcher.queryOrMutate({
    query: gql`
      query canBeBooked($input: BookSlotInput!) {
        canBeBooked(input: $input) {
          isSlotFree
        }
      }
    `,
    variables: {
      input: {
        ehrLocationId,
        ehrProviderId,
        ehrAppointmentType: appointmentType,
        interchangeableAppointmentTypes,
        startTime,
        insurer,
        endTime,
        timeZone: timezone,
      },
    },
    context: {
      uri: `${namespaceName}/graphql`,
    },
  });

  if (isSlotFree) {
    notification.success('', appointmentActionMessages.timeslotFree);
  } else {
    notification.error('', appointmentActionMessages.timeslotOccupied);
  }
};

const updateAppointmentActionHandler = (appointmentAction, data, { id, namespaceName }) => {
  const updateFields = getFieldsForUpdate(appointmentAction, data);
  return namespaceGraphqlFetcher.queryOrMutate({
    query: gql`
      mutation updateAppointmentAction($id: ID!, $updateFields: AppointmentActionInput!) {
        updateAppointmentAction(id: $id, updateFields: $updateFields)
      }
    `,
    variables: {
      id,
      updateFields,
    },
    context: {
      uri: `${namespaceName}/graphql`,
    },
  });
};

const getAppointmentActionHandler = ({ query }, { id, namespaceName }) => {
  const where = pickBy({ id, npi: query.npi }, identity);

  return namespaceGraphqlFetcher.queryOrMutate({
    query: gql`
      ${fullAppointmentActionFragment}

      query getFullAppointmentAction($input: GetAppointmentActionInput!) {
        getAppointmentAction(input: $input) {
          ...fullAppointmentAction
        }
      }
    `,
    variables: {
      input: where,
    },
    context: {
      uri: `${namespaceName}/graphql`,
    },
  });
};

export default compose(
  injectNotification,
  withStateFetchersOnMount({
    getAppointmentAction: {
      handler: ({ location, params }) => () => getAppointmentActionHandler(location, params),
      resultPropName: 'appointmentActionQueryResult',
      defaultValue: {},
      isReady: ({ appointmentActionQueryResult }) => !isEmpty(appointmentActionQueryResult),
      onError: ({ notification }) => notification.error('', appointmentActionMessages.errorMessage),
    },
  }),
  withProps(({ intl }) => ({
    statusOptions: AppointmentStatusIntl.toSelectOptions(intl),
  })),
  withPropsOnChange(['appointmentActionQueryResult'], ({ appointmentActionQueryResult }) => ({
    appointmentAction: {
      ...extractAppointmentActionData(appointmentActionQueryResult),
      patient: {
        ...extractPatientData(appointmentActionQueryResult),
      },
    },
  })),
  withFetchers({
    updateAppointmentAction: {
      handler: ({ params, appointmentAction }) => data =>
        updateAppointmentActionHandler(appointmentAction, data, params),
      resultPropName: 'updatedAppointmentActionId',
      defaultValue: '',
      isReady: ({ updatedAppointmentActionId }) => !isEmpty(updatedAppointmentActionId),
      onSuccess: ({ notification }) =>
        notification.success('', appointmentActionMessages.updateSucceeded),
      onError: ({ notification }, err) => {
        const errorMessage = values(errorMessages).includes(err.message)
          ? appointmentActionMessages[err.message]
          : appointmentActionMessages.updateError;

        notification.error('', errorMessage);
      },
    },
    checkAvailabilityBeforeResend: {
      handler: ({ params, appointmentAction, notification }) => () =>
        checkAvailabilityBeforeResend(appointmentAction, params, notification),
      resultPropName: 'availabilityCheck',
      defaultValue: '',
      isReady: ({ availabilityCheck }) => !isEmpty(availabilityCheck),
      onError: ({ notification }) =>
        notification.error('', appointmentActionMessages.availabilityCheckError),
    },
  }),
  withTranslatedMessages(appointmentActionMessages),
)(AppointmentActionDetails);
