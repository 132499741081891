/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withPropsOnChange, branch } from 'recompose';
import { autobind } from 'core-decorators';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { defineMessages } from 'react-intl';
import Action from '../components/action';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import injectNotification from '../../../../store/notification/injectNotification';
import {
  getFullMember,
  importMemberToDomain,
  INVITE_MEMBER,
  DEACTIVATE_MEMBER,
  IMPORT_MEMBER_TO_DOMAIN,
} from '../../../../store/backoffice/members/actions';
import * as selectors from '../../../../store/backoffice/members/selectors';
import Formatter from '../../../../utils/formatter';
import commonFormats from '../../../../utils/formatter/commonFormats';

import EntityForm from '../components/entityForm/entityForm';
import MoveMemberToDomainForm from './moveMemberToDomainForm';
import MemberInvitationForm from './memberInvitationForm';
import { name as MoveMemberToDomainFormName } from './moveMemberToDomainFormDefs';
import { name as MemberInvitationFormName } from './memberInvitationFormDefs';
import * as DomainMemberPageDefs from './DomainMemberPageDefs';
import { getDomain } from '../../../../utils/domain/storage';
import withDomainRestriction from '../components/withDomainRestrication';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import Api from '../../../../api';
import AttachPastActivity from './attachPastActivity';
import { TOO_YOUNG, USER_ALREADY_EXIST } from '../../../../api/errors';

const messages = defineMessages({
  genericSuccessTitle: {
    defaultMessage: 'Success!',
    id: 'backpack.domainMember.activation.genericSuccessTitle',
  },
  genericErrorTitle: {
    defaultMessage: 'Sorry, an error has occurred',
    id: 'backpack.domainMember.activation.genericErrorTitle',
  },
  genericErrorMessage: {
    defaultMessage: 'Process failed, contact dev team',
    id: 'backpack.domainMember.activation.genericErrorMessage',
  },
  activationSuccessMessage: {
    defaultMessage: 'Member successfully activated',
    id: 'backpack.domainMember.activation.activationSuccessMessage',
  },
  alreadyExistsErrorMessage: {
    defaultMessage: 'Member with this phone number already exists',
    id: 'backpack.domainMember.activation.alreadyExistsErrorMessage',
  },
  tooYoungErrorMessage: {
    defaultMessage: 'Member too young for activation',
    id: 'backpack.domainMember.activation.toYoungErrorMessage',
  },
  deactivationSuccessMessage: {
    defaultMessage: 'Successfully cleared member associated entities',
    id: 'backpack.domainMember.activation.deactivationSuccessMessage',
  },
  deactivationErrorMessage: {
    defaultMessage: "Couldn't clear domainMember's",
    id: 'backpack.domainMember.activation.deactivationErrorMessage',
  },
});

const withReformatDates = withPropsOnChange(['domainMember'], props => {
  let { domainMember } = props;
  if (domainMember) {
    domainMember = domainMember.asMutable();
    domainMember.dateOfBirth = Formatter.dateUtc(
      domainMember.dateOfBirth,
      commonFormats.MONTH_DAY_YEAR,
    );
    domainMember.coverageEffectiveDate = Formatter.dateUtc(
      domainMember.coverageEffectiveDate,
      commonFormats.MONTH_DAY_YEAR,
    );
    domainMember.coverageTerminationDate = Formatter.dateUtc(
      domainMember.coverageTerminationDate,
      commonFormats.MONTH_DAY_YEAR,
    );

    // I know its a hack but the check box components are
    // buggy and our network ops wants to see this field ASAP
    domainMember = _.mapValues(domainMember, val => (_.isBoolean(val) ? _.toString(val) : val));
  }

  return { domainMember };
});

@autobind
class DomainMemberPage extends React.Component {
  static propTypes = {
    domainMember: PropTypes.object.isRequired,
    getFullMember: PropTypes.func.isRequired,
    importMemberToDomain: PropTypes.func.isRequired,
    deactivateMember: PropTypes.func.isRequired,
    inviteMember: PropTypes.func.isRequired,
    hasAssociatedData: PropTypes.bool.isRequired,
    allowDeactivateFlag: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    currentDomain: PropTypes.string.isRequired,
  };


  constructor() {
    super();
    this.state = {};
  }

  moveMemberToDomain({ domain, toDomainMemberId }) {
    const { domainMember, importMemberToDomain, currentDomain } = this.props;
    importMemberToDomain(currentDomain, domainMember.id, toDomainMemberId, domain);
  }

  inviteMember({ phoneNumber }) {
    const { inviteMember } = this.props;
    inviteMember(phoneNumber);
  }

  goToUser() {
    const {
      domainMember: { userId },
      router,
    } = this.props;
    router.push(`/secure/users/${userId}`);
  }

  renderActivation() {
    const { allowDeactivateFlag, hasAssociatedData } = this.props;

    if (hasAssociatedData) {
      return allowDeactivateFlag ? this.renderDeactivateMember() : null;
    }

    return this.renderInviteMember();
  }

  renderInviteMember() {
    const { domainMember } = this.props;

    return (
      <Action
        name="Activate member"
        title="Activate member"
        actionTrackerId={INVITE_MEMBER.SOURCE}
        btnColor="green"
        formName={MemberInvitationFormName}
      >
        <MemberInvitationForm onSubmit={this.inviteMember} domainMemberId={domainMember.id} />
      </Action>
    );
  }

  renderDeactivateMember() {
    return (
      <Action
        name="Clear associated data"
        title="Clear associated data"
        label="Clear associated data [DEV ONLY]"
        actionTrackerId={DEACTIVATE_MEMBER.SOURCE}
        btnColor="red"
        onSubmit={this.props.deactivateMember}
      >
        <div className="row">Please note that this action is risky and irreversible.</div>
        <div className="row">
          Domain Member deactivation is designed for development / demo environments.
        </div>
        <div className="row">Are you sure you want to proceed?</div>
      </Action>
    );
  }

  renderGoToUser() {
    const {
      domainMember: { userId },
    } = this.props;

    if (!userId) {
      return null;
    }

    return (
      <div className="backoffice-action">
        <button
          className="btn btn-small btn-blue text-semibold action-button"
          name="Go to user"
          onClick={this.goToUser}
        >
          Go to user
        </button>
      </div>
    );
  }

  renderAttachPastActivity() {
    const { domainMember } = this.props;
    return <AttachPastActivity domainMember={domainMember} />;
  }

  render() {
    const { domainMember } = this.props;
    return (
      <div>
        <h1>{`Domain Member: ${domainMember.id}`}</h1>
        <div className="backoffice-action action-group">
          <Action
            name="Move member to another domain"
            title="Move member to another domain"
            actionTrackerId={IMPORT_MEMBER_TO_DOMAIN.SOURCE}
            formName={MoveMemberToDomainFormName}
          >
            <MoveMemberToDomainForm
              onSubmit={this.moveMemberToDomain}
              domainMemberId={domainMember.id}
            />
          </Action>
          {this.renderGoToUser()}
          {this.renderAttachPastActivity()}
          {this.renderActivation()}
        </div>
        <EntityForm
          name="domain-member-form"
          title="Domain Member:"
          fieldsDef={DomainMemberPageDefs.fields}
          entity={domainMember}
          readOnly
        />
        <hr />
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(query => ({ targetDomain: query.targetDomain })),
  branch(
    ({ targetDomain }) => !_.isEmpty(targetDomain),
    withDomainRestriction(({ targetDomain }) => targetDomain),
  ),
  injectNotification,
  fetchNotEmpty(
    ({ props }) => {
      const domainMemberId = _.get(props, 'routeParams.domainMemberId');
      return getFullMember(domainMemberId);
    },
    (state, props) => {
      const fullMember = _.get(state, 'backoffice.members.full');
      const domainMemberId = _.get(props, 'routeParams.domainMemberId');
      return _.get(fullMember, 'id') === domainMemberId ? fullMember : undefined;
    },
    { renderAlways: false, loading: true },
    selectors.getDomainMemberId,
    {
      renderAlways: false,
      loading: true,
      predicate: (memberId, { routeParams: { domainMemberId } }) => memberId === domainMemberId,
    },
  ),
  connect(
    (state, ownProps) => ({
      domainMember: selectors.getFullMember(state, ownProps),
      hasAssociatedData: selectors.hasAssociatedData(state),
      allowDeactivateFlag: selectors.getAllowDeactivateFlag(state),
      currentDomain: getDomain(),
    }),
    {
      getFullMember,
      importMemberToDomain,
    },
  ),
  withFetchers({
    inviteMember: {
      handler: ({ domainMember: { id } }) => phoneNumber =>
        Api.activationApi.inviteMemberByBackpacker(id, phoneNumber),
      onSuccess: ({ notification }) => {
        notification.success(messages.genericSuccessTitle, messages.activationSuccessMessage);
      },
      onError: ({ notification }, error) => {
        let errorMessage;

        switch (error.responseMessage) {
          case TOO_YOUNG:
            errorMessage = messages.tooYoungErrorMessage;
            break;
          case USER_ALREADY_EXIST:
            errorMessage = messages.alreadyExistsErrorMessage;
            break;
          default:
            errorMessage = messages.genericErrorMessage;
        }

        notification.error(messages.genericErrorTitle, errorMessage);
      },
    },
    deactivateMember: {
      handler: ({ domainMember }) => () => Api.membersApi.deactivateMember(domainMember.id),
      onSuccess: ({ getFullMember, notification, domainMember }) => {
        getFullMember(domainMember.id);
        notification.success(
          messages.genericSuccessTitle,
          messages.deactivationSuccessMessage,
        );
      },
      onError: ({ notification }, error) => {
        const errorForLog = _.get(error, 'responseMessage', JSON.stringify(error));
        notification.error(
          messages.genericErrorTitle,
          `${messages.deactivationErrorMessage} ${errorForLog}`,
        );
      },
    },
  }),
  withReformatDates,
)(DomainMemberPage);
