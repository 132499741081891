import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages } from 'react-intl';

import Formatter from '../../../../../../utils/formatter';
import displayProvider from '../../../../../../utils/displayProvider';
import CollapsibleCard from '../../../../../../components/ui/collapsibleCard/collapsibleCard';
import LocationVerification from '../../../../../../components/molecules/LocationVerification/LocationVerification';

const providerMessages = defineMessages({
  title: {
    defaultMessage: 'Provider details:',
    id: 'appointmentProductForm.provider.title',
  },
  npi: {
    defaultMessage: 'Npi:',
    id: 'appointmentProductForm.provider.npi',
  },
  fullName: {
    defaultMessage: 'Full name:',
    id: 'appointmentProductForm.provider.fullName',
  },
  offices: {
    defaultMessage: 'Offices:',
    id: 'appointmentProductForm.provider.offices',
  },
  office: {
    defaultMessage: 'Office:',
    id: 'appointmentProductForm.provider.office',
  },
  address: {
    defaultMessage: 'Address:',
    id: 'appointmentProductForm.provider.address',
  },
  phoneNumber: {
    defaultMessage: 'Phone:',
    id: 'appointmentProductForm.provider.phoneNumber',
  },
  fax: {
    defaultMessage: 'Fax:',
    id: 'appointmentProductForm.provider.fax',
  },
});

const renderProviderOffice = function renderProviderOffice(provider, location, boProvider) {
  return (
    <div>
      <CollapsibleCard
        title={Formatter.address(location.officeName) || `location ${location.id}:`}
        id={location.id}
        className="patient-details-card light-blue-title bg-grey-color"
      >
        {!_.isEmpty(boProvider) ? (
          <div className="row">
            <div className="col-xs-12">
              <LocationVerification isVerified={location.isVerified} />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...providerMessages.address} />
            </h4>
            {Formatter.addressFormat(location)}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...providerMessages.phoneNumber} />
            </h4>
            {Formatter.phoneNumberDefault(location.phoneNumber)}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h4>
              <FormattedMessage {...providerMessages.fax} />
            </h4>
            {Formatter.phoneNumberDefault(location.fax)}
          </div>
        </div>
      </CollapsibleCard>
    </div>
  );
};

@autobind
class CollapsibleProvider extends React.Component {
  static propTypes = {
    locationId: PropTypes.number,
    provider: PropTypes.object.isRequired,
    titleMessage: PropTypes.object,
    boProvider: PropTypes.object,
  };

  static defaultProps = {
    locationId: undefined,
    titleMessage: providerMessages.title,
    boProvider: undefined,
  };

  render() {
    const { provider, titleMessage, locationId, boProvider } = this.props;
    if (!provider) return null;

    const locations = locationId
      ? _.reject(provider.locations, curr => curr.id !== locationId)
      : provider.locations;
    const officeMessage = locationId ? providerMessages.office : providerMessages.offices;

    return (
      <div>
        {provider && (
          <CollapsibleCard
            title={titleMessage}
            id={`provider-backoffice-details-id-${provider.npi}`}
            className="patient-details-card light-blue-title bg-grey-color"
          >
            <div className="row">
              <div className="col-xs-12">
                <h4>
                  <FormattedMessage {...providerMessages.npi} />
                </h4>
                {provider && provider.npi}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <h4>
                  <FormattedMessage {...providerMessages.fullName} />
                </h4>
                {provider &&
                  displayProvider(provider.firstName, provider.lastName, provider.suffix)}
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <h3>
                  <FormattedMessage {...officeMessage} />
                </h3>
                {_.get(provider, 'locations', null) &&
                  _.map(locations, loc => renderProviderOffice(provider, loc, boProvider))}
              </div>
            </div>
          </CollapsibleCard>
        )}
      </div>
    );
  }
}

export default CollapsibleProvider;
