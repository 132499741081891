import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Field } from 'formik';
import { object, string } from 'yup';
import { withHandlers } from 'recompose';
import { WrappedFormik } from '../../../../../../../components/form/formik/wrappedFormik';
import { CleanFormikInput } from '../../../../../../../components/form/formik/cleanFormikInput';
import SpinnerButton from '../../../../../../../components/ui/spinner/spinnerButton';

const initialValues = {
  srcBucket: '',
  srcDir: '',
};

export const validationSchema = object().shape({
  srcBucket: string().required('You must enter bucket name!'),
  srcDir: string().required('You must enter dir name!'),
});

function BucketDirSelectorView({ onScanProfilePics, scanned, isLoading }) {
  const bucketLabel = "type src bucket ('bookmd-public')";
  const dirLabel = "type src directory ('profilePics/byNPI_10062017/')";
  return (
    <WrappedFormik
      initialValues={initialValues}
      onSubmit={onScanProfilePics}
      validationSchema={validationSchema}
      render={() => (
        <Form>
          <Field component={CleanFormikInput} name="srcBucket" label={bucketLabel} />
          <Field component={CleanFormikInput} name="srcDir" label={dirLabel} />
          <SpinnerButton className="btn btn-big btn-min-100" isLoading={isLoading} type="submit">
            <i className="fa fa-image text-22 i-va-fix-2" />
            &nbsp;&nbsp;
            {scanned ? 'Rescan' : 'Scan'}
          </SpinnerButton>
        </Form>
      )}
    />
  );
}

BucketDirSelectorView.propTypes = {
  scanned: PropTypes.bool,
  isLoading: PropTypes.bool,
  onScanProfilePics: PropTypes.func.isRequired,
};

BucketDirSelectorView.defaultProps = {
  scanned: false,
  isLoading: false,
};

const BucketDirSelector = withHandlers({
  onScanProfilePics: ({ onScanProfilePics = _.noop }) => ({ srcBucket, srcDir }) =>
    onScanProfilePics({
      srcBucket,
      srcDir,
    }),
})(BucketDirSelectorView);

export default BucketDirSelector;
