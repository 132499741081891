/**
 * Created by anna on 26/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Action from '../../components/action';
import { EDIT_REVIEW, saveReviewText } from '../../../../../store/backoffice/reviews/actions';
import EditReviewForm from './editReviewForm';
import { name as formName } from './editReviewFormDef';

@autobind
class EditRevieAction extends React.PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired,
    saveReviewText: PropTypes.func.isRequired,
  };

  callAction(data) {
    this.props.saveReviewText({
      token: this.props.review.token,
      reviewText: data.review,
    });
  }

  render() {
    const { review } = this.props;
    return (
      <Action
        name="EditReview"
        title="Edit Review"
        actionTrackerId={EDIT_REVIEW.SOURCE}
        formName={formName}
        {...this.props}
      >
        <EditReviewForm review={review.review} onSubmit={this.callAction} />
      </Action>
    );
  }
}

export default connect(
  null,
  { saveReviewText },
)(EditRevieAction);
