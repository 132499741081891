import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys, compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { sendChatSmsToMember } from '../../../../../store/backoffice/communication/actions';
import CleanInput from '../../../../../components/form/cleanInput';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import ChatMessageType from './chatMessageType';
import injectNotification from '../../../../../store/notification/injectNotification';
import ChatMessage from './chatMessage';

class Chat extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    isSending: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(ChatMessageType.isRequired).isRequired,
    onMessageChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onInputKeyPress: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (_.get(this, '_chatContainer.scrollIntoView')) {
      this._chatContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { messages, isSending, message, onMessageChange, onSubmit, onInputKeyPress } = this.props;
    // TODO: test chat after changing ref={c=> ( to {}
    return (
      <div>
        <div className="chat-list">
          {messages.map(msg => (
            <ChatMessage key={msg.id} message={msg} />
          ))}
        </div>
        <div
          className="chat-input-container"
          ref={c => {
            this._chatContainer = c;
          }}
        >
          <CleanInput
            className="chat-input"
            onChange={onMessageChange}
            value={message}
            readOnly={isSending}
            onKeyPress={onInputKeyPress}
            label="Sms Message"
          />
          <SpinnerButton
            isLoading={isSending}
            className="btn btn-green btn-small"
            onClick={onSubmit}
          >
            Send
          </SpinnerButton>
        </div>
      </div>
    );
  }
}

const withSubmitHandler = withHandlers({
  onSubmit: props => () => {
    const {
      sendChatSmsToMember,
      domainMemberId,
      message,
      setIsSending,
      setMessage,
      notification,
      reloadMessages,
    } = props;

    setIsSending(true);
    sendChatSmsToMember(domainMemberId, message)
      .then(() => {
        setMessage('');
        notification.success('SMS message sent', 'Reloading messages...');
        reloadMessages();
      })
      .catch(() => notification.error('SMS message failed to send', ''))
      .finally(() => setIsSending(false));
  },
});

const enhance = compose(
  connect(
    null,
    { sendChatSmsToMember },
  ),
  injectNotification,

  onlyUpdateForKeys(['messages']),
  withState('message', 'setMessage', ''),
  withState('isSending', 'setIsSending', false),
  withSubmitHandler,
  withHandlers({
    onMessageChange: ({ setMessage }) => e => setMessage(e.target.value),
    onInputKeyPress: ({ onSubmit }) => e => {
      if (e.key === 'Enter') {
        onSubmit();
      }
    },
  }),
);

export default enhance(Chat);
