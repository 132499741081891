/* eslint-disable class-methods-use-this */
/**
 * Created by Nico on 3/05/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'redux';
import { Field } from 'redux-form';
import Joi from 'joi-browser';

import { createValidator } from '../../../../../utils/joiValidator';
import {
  CREATE_VIM_GUIDE_PROFILE,
  createVimGuideProfile,
} from 'Store/backoffice/vimGuideProfile/actions';
import Action from '../../components/action';
import EntityForm from '../../components/entityForm/entityForm';
import * as CreateVimGuideFormDefs from './createVimGuideFormDefs';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import { getAsoGroupsList } from 'Store/backoffice/asoGroups/selectors';
import fetchNotEmpty from 'Store/tools/fetchNotEmpty';
import queryConnect from 'Store/tools/queryString/queryConnect';
import { gridQueryParams } from '../../../../../components/grid/queryStringGrid';
import { getAllAsoGroups } from 'Store/backoffice/asoGroups/actions';

const vimGuideMock = {
  firstName: '',
  middleName: '',
  lastName: '',
  externalId: '',
  password: '',
  email: '',
  phoneNumber: null,
  domains: '',
  asoGroups: [],
  profilePicture: 'https://s3-us-west-1.amazonaws.com/bookmd-public/images/Call_center_agent.png',
  isTemporaryPassword: true,
  isVimInternal: false,
};

@autobind
class CreateVimGuideProfileAction extends React.PureComponent {
  static propTypes = {
    createVimGuideProfile: PropTypes.func.isRequired,
  };

  onPasswordStrengthChanged(fn, value) {
    fn(CreateVimGuideFormDefs.fields().passwordStrength.name, value);
  }

  callAction(data) {
    this.props.createVimGuideProfile(
      _.omit(data, 'domains', 'repeatPassword', 'passwordStrength'),
      data.domains,
    );
  }

  render() {
    const { newPasswordValidationFeature } = this.props;
    const { repeatPassword } = CreateVimGuideFormDefs.fields();
    return (
      <Action
        name="createProfile"
        title="Create Profile"
        actionTrackerId={CREATE_VIM_GUIDE_PROFILE.SOURCE}
        formName={CreateVimGuideFormDefs.name}
        {...this.props}
      >
        <EntityForm
          name={CreateVimGuideFormDefs.name}
          title="Create Vim Guide profile:"
          submitText="Create"
          schemaDef={CreateVimGuideFormDefs.schema}
          fieldsDef={CreateVimGuideFormDefs.fields(this.props.asoGroups)}
          entity={vimGuideMock}
          onSubmit={this.callAction}
          showButtons={false}
          newPasswordValidationFeature={newPasswordValidationFeature}
          verifyPasswordField={
            <div>
              <Field
                label="Re-enter password"
                className="password-input"
                {...repeatPassword}
              />
            </div>
          }
          onStrengthChanged={this.onPasswordStrengthChanged}
          validate={createValidator(
            Joi.object().keys(CreateVimGuideFormDefs.schema),
            CreateVimGuideFormDefs.customValidator(),
          )}
        />
      </Action>
    );
  }
}

export default compose(
  fetchNotEmpty(
    () => getAllAsoGroups(),
    getAsoGroupsList,
    { renderAlways: true, loading: true },
  ),
  connect(state => ({
    asoGroups: getAsoGroupsList(state),
  }), { createVimGuideProfile }),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(CreateVimGuideProfileAction);
/* eslint-enable class-methods-use-this */
