/**
 * Created by chenrozenes on 16/04/2017.
 */
import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { responsiveStateReducer } from 'redux-responsive';
import * as commonReducers from '../../../../store/app/commonReducers';

import backoffice from '../../../../store/backoffice/reducer';
import recaptcha from '../../../../store/recaptcha/reducer';
import files from '../../../../store/file/reducer';
import insurers from '../../../../store/insurers/reducer';
import directory from '../../../../store/directory/reducer';
import help from '../../../../store/help/reducer';
import flows from '../../../../components/wizard/flow/reducer';

export default combineReducers({
  ...commonReducers,
  intl: intlReducer,
  browser: responsiveStateReducer,
  backoffice,
  files,
  recaptcha,
  insurers,
  directory,
  flows,
  help,
});
