/**
 * Created by guyavarham on 13/12/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Action from '../../components/action';
import SimpleEntity from '../../components/simpleEntity';
import * as formDefs from './createReviewFormDefs';
import { CREATE_REVIEW, createReview } from '../../../../../store/backoffice/reviews/actions';

@autobind
class CreateReviewAction extends React.PureComponent {
  static propTypes = {
    createReview: PropTypes.func.isRequired,
  };

  createReview(data) {
    this.props.createReview({
      providerNpi: data.providerNpi,
      locationId: data.locationId,
      domainMemberId: data.domainMemberId,
    });
  }

  render() {
    return (
      <Action
        name="CreateReview"
        title="Create Review"
        actionTrackerId={CREATE_REVIEW.SOURCE}
        formName={formDefs.name}
      >
        <SimpleEntity
          name="Review"
          formDefs={formDefs}
          onCreate={this.createReview}
          showButtons={false}
        />
      </Action>
    );
  }
}

export default compose(
  connect(
    null,
    { createReview },
  ),
)(CreateReviewAction);
