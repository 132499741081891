import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../../components/form/reduxForm/components';

export const name = 'backoffice-update-provider-video';

export const fields = {
  videoUrl: {
    name: 'videoUrl',
    label: 'url',
    component: ReduxFormCleanInput,
  },
  videoPreviewImg: {
    name: 'videoPreviewImg',
    label: 'preview image',
    component: ReduxFormCleanInput,
  },
  videoQuote: {
    name: 'videoQuote',
    label: 'quote',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  videoUrl: Joi.string().allow('', null),
  videoPreviewImg: Joi.string().allow('', null),
  videoQuote: Joi.string().allow('', null),
};
