/**
 * Created by chenrozenes on 13/06/2017.
 */
import { combineReducers } from 'redux';

import genericReducer from '../../genericReducer';
import outreach from './outreach/reducer';

import {
  GET_DATA_MAPPING,
  GET_HISTORY,
  GET_HISTORY_RECORD,
  GET_CHAT_HISTORY,
  LIST_TEMPLATES,
  GET_METADATA,
  GET_BY_TEMPLATE_ID,
} from './actions';

const initialState = {
  dataMapping: {},
  history: {
    data: [],
    count: 0,
  },
  historyRecord: {},
  chatHistory: null,
  templates: {
    data: [],
    count: 0,
  },
  currTemplate: {},
  metadata: {},
  outreach: {}
};

export default combineReducers({
  dataMapping: genericReducer(GET_DATA_MAPPING, initialState.dataMapping),
  history: genericReducer(GET_HISTORY, initialState.history, null, { keepStateOnStart: true }),
  historyRecord: genericReducer(GET_HISTORY_RECORD, initialState.historyRecord),
  chatHistory: genericReducer(GET_CHAT_HISTORY, initialState.chatHistory),
  templates:
    genericReducer(LIST_TEMPLATES, initialState.templates, null, { keepStateOnStart: true }),
  currTemplate: genericReducer(GET_BY_TEMPLATE_ID, initialState.currTemplate),
  metadata: genericReducer(GET_METADATA, initialState.metadata),
  outreach,
});
