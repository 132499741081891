/**
 * Created by ofirHartuv on 01/11/17.
 */
import Joi from 'joi-browser';
import { values, map } from 'lodash';
import {
  ReduxFormOpenHours,
  ReduxFormItemList,
  ReduxFormCleanTimezoneSelect,
  ReduxFormCleanSelect,
} from '../../../../../components/form/reduxForm/components';
import * as IntegrationLevel from '../../../../../model/enum/integrationLevel';
import { enumSchema } from '../../../../../utils/commonSchema';
import CalendarLocationItem from './calendarLocationItem';

const minApptLengthInMins = 15;

export const name = 'backoffice-calendar';

export const fields = {
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    className: 'margin-top-30',
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  locations: {
    name: 'locations',
    component: ReduxFormItemList,
    RowType: CalendarLocationItem,
  },
  resourceId: {
    name: 'resourceId',
    readOnly: true,
  },
  openHours: {
    name: 'openHours',
    component: ReduxFormOpenHours,
  },
  blockSize: {
    label: 'Min. Appt. Length (in minutes)',
    name: 'blockSize',
  },
  timezone: {
    name: 'timezone',
    component: ReduxFormCleanTimezoneSelect,
  },
  integrationLevel: {
    name: 'integrationLevel',
    id: 'integrationLevel',
    component: ReduxFormCleanSelect,
    options: map(values(IntegrationLevel), name => ({
      value: name,
      label: name,
    })),
  },
};

export const schema = Joi.object().keys({
  id: Joi.number(),
  openHours: Joi.object()
    .allow('', null)
    .optional(),
  locations: Joi.array()
    .items(
      Joi.object().keys({
        locationId: Joi.number().required(),
        domain: Joi.string().required(),
      }),
    )
    .optional(),
  blockSize: Joi.number()
    .required()
    .min(minApptLengthInMins)
    .options({
      language: {
        any: {
          required: `Please enter a min. appt. length greater than ${minApptLengthInMins} minutes`,
        },
      },
    }),
  timezone: Joi.string().required(),
  resourceId: Joi.number().required(),
  integrationLevel: enumSchema(IntegrationLevel).default(IntegrationLevel.NONE),
});
