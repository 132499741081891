/**
 * Created by Nico on 3/05/2018.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { GET_ALL_VIM_GUIDES_PROFILES, GET_VIM_GUIDE_BY_ID } from './actions';

const initialState = {
  list: {
    count: 0,
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_ALL_VIM_GUIDES_PROFILES.START:
      return state.set('list', initialState.list);
    case GET_ALL_VIM_GUIDES_PROFILES.SUCCESS:
      return state.set('list', action.payload);
    case GET_VIM_GUIDE_BY_ID.SUCCESS: {
      const idOfMember = action.payload.id;
      const indexInList = _.findIndex(state.list.data, ['id', idOfMember]);
      let newList;
      if (indexInList < 0) {
        newList = [action.payload];
      } else {
        newList = _.cloneDeep(state.list.data);
        newList[indexInList] = action.payload;
      }
      return state.merge({ list: { data: newList } }, { deep: true });
    }
  }

  return state;
}
