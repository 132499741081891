import Joi from 'joi-browser';
import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';

import {
  ReduxFormCleanPasswordInput,
  ReduxFormCleanRepeatPasswordInput,
  ReduxFormCleanAlphabet,
} from '../../../../components/form/reduxForm/components';

import { PasswordSchema } from '../../../../utils/commonSchema';

const MINIMAL_PASSWORD_STRENGTH = 2;

export const name = 'changePassword';

export const fields = {
  name: {
    name: 'name',
    component: ReduxFormCleanAlphabet,
  },
  password: {
    name: 'password',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  /**
   * This field is a computed field that we never render (hidden field)
   * We use this field for validating the password field
   * (must be stronger than the minimal strength).
   */
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
};

export const schema = {
  password: PasswordSchema.required(),

  repeatPassword: PasswordSchema.required(),

  passwordStrength: Joi.object().keys({
    score: Joi.number(),
    deprecatedCharacters: Joi.number(),
  }),
};

/**
 * Custom validator for the password strength, uses the hidden field "passwordStrength".
 * @type {String}
 */
export const customValidator = {
  [fields.password.name]: (value, values) => {
    if (!values[fields.passwordStrength.name]) return undefined;

    const { deprecatedCharacters } = values[fields.passwordStrength.name];
    const passwordStrength = values[fields.passwordStrength.name].score;

    if (deprecatedCharacters) {
      return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
    }

    if (!passwordStrength || passwordStrength < MINIMAL_PASSWORD_STRENGTH) {
      return 'Password is too weak';
    }
    return undefined;
  },
  [fields.repeatPassword.name]: (value, values) => {
    if (values[fields.password.name] !== values[fields.repeatPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
};
