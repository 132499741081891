import _ from 'lodash';
import Joi from 'joi-browser';
import { PhoneNumberSchema } from '../../../../utils/commonSchema';
import {
  ReduxFormCleanCheckbox,
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanSelect,
} from '../../../../components/form/reduxForm/components';

export const name = 'backoffice-aso-group';

export const fields = tiers => ({
  id: {
    name: 'id',
    readOnly: true,
  },
  createdAt: {
    className: 'margin-top-30',
    name: 'createdAt',
    readOnly: true,
  },
  updatedAt: {
    name: 'updatedAt',
    readOnly: true,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  outOfCountryPhone: {
    name: 'outOfCountryPhone',
    component: ReduxFormCleanPhoneNumber,
  },
  defaultTierId: {
    className: 'margin-top-30',
    name: 'defaultTierId',
    options: _.map(tiers, tier => ({
      value: tier.id,
      label: tier.name,
    })),
    component: ReduxFormCleanSelect,
  },
  isActivated: {
    name: 'isActivated',
    id: 'isActivated',
    component: ReduxFormCleanCheckbox,
    parse: value => !!value,
  },
});

export const schema = {
  id: Joi.number().required(),
  groupId: Joi.string().required(),
  emailAddress: Joi.string()
    .email()
    .required(),
  websiteLink: Joi.string(),
  openHours: Joi.string(),
  phoneNumber: PhoneNumberSchema.required(),
  isActivated: Joi.boolean().required(),
  outOfCountryPhone: PhoneNumberSchema.required(),
};
