import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  compose,
  withState,
  withHandlers,
  pure,
  setDisplayName,
  withProps,
  mapProps,
} from 'recompose';

import CleanSelect from '../../../../../components/form/cleanSelect';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import injectNotification from '../../../../../store/notification/injectNotification';
import Apis from '../../../../../api';
import BkmdModelButton from '../../../../../components/bkmdModalButton/bkmdModalButton';

function RemoveUserFromGroupFromView(props) {
  return (
    <div>
      <div className="col-xs-12">
        <CleanSelect
          label="Select User"
          options={props.usersList}
          value={props.selectedUser}
          onChange={props.onSelectionChanged}
        />
      </div>
      <div className="col-xs-12">
        <SpinnerButton
          className="btn btn-big btn-min-100 btn-blue margin-top-20"
          onClick={props.onSubmit}
          isLoading={props.onSubmitTracker.inProgress}
          disabled={_.isNil(props.selectedUser)}
        >
          Remove User
        </SpinnerButton>
      </div>
    </div>
  );
}

RemoveUserFromGroupFromView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  usersList: PropTypes.array.isRequired,
  selectedUser: PropTypes.number,
  onSelectionChanged: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
};

RemoveUserFromGroupFromView.defaultProps = { selectedUser: null };

const transformUsersToSelect = createSelector(_.identity, users =>
  _.map(users, user => ({
    label: `${user.email}  (${user.id})`,
    value: user.id,
  })),
);
export const RemoveUserFromGroupForm = compose(
  mapProps(({ currentUsers, ...otherProps }) => ({
    usersList: transformUsersToSelect(currentUsers),
    ...otherProps,
  })),
  withState('selectedUser', 'setSelectedUser', null),
  withHandlers({
    onSelectionChanged: ({ setSelectedUser }) => event => setSelectedUser(_.get(event, 'value')),
  }),
  injectNotification,
  withFetchers({
    onSubmit: {
      handler: ({ groupId, selectedUser }) => () =>
        Apis.usersApi.removeUserFromGroup(groupId, selectedUser),
      onSuccess: ({ notification, closeModal, onSuccess }, result) => {
        notification.success('Remove User', 'User successfully removed from group');
        closeModal();
        onSuccess(result);
      },
      onError: ({ notification }) =>
        notification.error('Remove User', 'Remove user has failed duo to an unexpected reason'),
    },
  }),
)(RemoveUserFromGroupFromView);

RemoveUserFromGroupForm.propTypes = {
  groupId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  currentUsers: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
};

RemoveUserFromGroupForm.defaultProps = { onSuccess: _.noop };

export default compose(
  setDisplayName('Remove User'),
  pure,
  withProps({
    title: 'Remove User From Group',
    buttonText: 'Remove User',
    component: RemoveUserFromGroupForm,
  }),
)(BkmdModelButton);
