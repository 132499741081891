import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanPhoneNumber,
} from '../../../../../components/form/reduxForm/components';
import { PhoneNumberSchema } from '../../../../../utils/commonSchema';

export const name = 'backoffice-create-aso-group';

export const fields = {
  groupId: {
    name: 'groupId',
    component: ReduxFormCleanInput,
  },
  name: {
    name: 'name',
    component: ReduxFormCleanInput,
  },
  emailAddress: {
    name: 'emailAddress',
    component: ReduxFormCleanInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  websiteLink: {
    name: 'websiteLink',
    component: ReduxFormCleanInput,
  },
  openHours: {
    name: 'openHours',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  groupId: Joi.string().required(),
  name: Joi.string().required(),
  email: Joi.string()
    .email()
    .required(),
  phoneNumber: PhoneNumberSchema.required(),
  websiteLink: Joi.string(),
  openHours: Joi.string(),
};
