/**
 * Created by chenrozenes on 13/06/2017.
 */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import { getDataMapping } from '../../../../../store/backoffice/communication/actions';
import * as selectors from '../../../../../store/backoffice/communication/selectors';
import { BkmdGrid, BkmdGridColumn, Columns } from '../../../../../components/grid';
import dataMappingType from './dataMappingType';

const { ArrayColumn } = Columns;

function EntityDataMapping({ entityDataMapping }) {
  const data = _.values(entityDataMapping.fields);

  return (
    <div>
      <BkmdGrid
        title={`${entityDataMapping.title} Fields`}
        data={data}
        totalCount={data.length}
        local
      >
        <BkmdGridColumn id="syntax" title="Syntax" />
        <BkmdGridColumn id="description" title="Description" />
        <BkmdGridColumn id="channels" title="Available on channels" customComponent={ArrayColumn} />
        <BkmdGridColumn id="defaultValue" title="Default Value" />
      </BkmdGrid>
    </div>
  );
}

EntityDataMapping.prototype.propTypes = {
  entityDataMapping: dataMappingType.isRequired,
};

export default compose(
  withRouter,
  fetchNotEmpty(getDataMapping, selectors.getEntityMapping, {
    renderAlways: false,
    loading: true,
  }),
  connect((state, props) => ({
    entityDataMapping: selectors.getEntityMapping(state, props),
  })),
)(EntityDataMapping);
